import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    getTranscriptNoInsights,
    getTranscriptWithUserColors,
} from '@/utils/recordings/getTranscript';
import { TRANSCRIPT } from '@/redux/actions/conversation/transcript';
import {
    getTranscriptBulkConfirmed,
    getTranscriptWithConfirmStatus,
    getTranscriptWithPinnedQuote,
} from '@/redux/reducers/conversation/transcriptReducer/transcriptReducerQuoteStatusUpdates';
import {
    getTranscriptWithGemsUpdated,
    getTranscriptWithTagsUpdated,
} from '@/redux/reducers/conversation/transcriptReducer/transcriptReducerQuoteTagsUpdates';
import {
    getTranscriptWithAssigneeUpdate,
    getTranscriptWithDueDateUpdate,
    getTranscriptWithIsDoneUpdate,
} from '@/redux/reducers/conversation/transcriptReducer/transcriptReducerQuoteUpdates';

export const initialState = {
    data: {
        transcript: {
            content: null,
            speakers: [],
            initialSpeakers: [],
        },
        sentiment: null,
        entitySentiment: null,
        media: {
            type: null,
            src: null,
            mediaUrl: {
                loading: false,
                url: null,
                error: null,
            },
        },
    },
    hiddenSpeakers: [],
    status: FETCH_STATUS.IDLE,
    error: null,
};

function transcriptReducer(state = initialState, action) {
    switch (action.type) {
        case TRANSCRIPT.GUEST_FETCH_START:
        case TRANSCRIPT.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case TRANSCRIPT.GUEST_FETCH_SUCCESS: {
            const { data } = action.payload;

            const parsedTs = getTranscriptNoInsights(data.transcript);

            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADED,
                data: {
                    transcript: {
                        content: parsedTs.content,
                        speakers: parsedTs.speakers,
                        initialSpeakers: parsedTs.initialSpeakers,
                    },
                    sentiment: data?.sentiment,
                    entitySentiment: data?.entitySentiment?.entities,
                    media: {
                        type: data.contentType,
                        src: data.mediaUrl,
                    },
                },
                error: null,
            };
        }
        case TRANSCRIPT.FETCH_SUCCESS: {
            const { data } = action.payload;

            const parsedTs = getTranscriptWithUserColors(data.transcript);

            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADED,
                data: {
                    transcript: {
                        content: parsedTs.content,
                        speakers: parsedTs.speakers,
                        initialSpeakers: parsedTs.initialSpeakers,
                    },
                    sentiment: data?.sentiment,
                    entitySentiment: data?.entitySentiment?.entities,
                    media: {
                        type: data.contentType,
                        src: data.mediaUrl,
                    },
                },
                error: null,
            };
        }
        case TRANSCRIPT.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case TRANSCRIPT.UPDATE_CONTENT_ITEM.START:
        case TRANSCRIPT.RESET_SPEAKERS.START:
        case TRANSCRIPT.UPDATE_ATTENDEE.START:
        case TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
            };
        }
        case TRANSCRIPT.UPDATE_CONTENT_ITEM.FAILURE:
        case TRANSCRIPT.RESET_SPEAKERS.FAILURE:
        case TRANSCRIPT.UPDATE_ATTENDEE.FAILURE:
        case TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
            };
        }
        case TRANSCRIPT.UPDATE_CONTENT_ITEM.SUCCESS: {
            const newItem = action.payload.item;
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                data: {
                    ...state.data,
                    transcript: {
                        ...state.data.transcript,
                        content: state.data.transcript.content.map(
                            (contentItem) =>
                                contentItem.id === newItem.id
                                    ? newItem
                                    : contentItem,
                        ),
                    },
                },
            };
        }
        case TRANSCRIPT.UPDATE_ATTENDEE.SUCCESS:
        case TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.SUCCESS:
        case TRANSCRIPT.UPDATE_SUCCESS:
        case TRANSCRIPT.RESET_SPEAKERS.SUCCESS: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                data: {
                    ...state.data,
                    transcript: {
                        ...state.data.transcript,
                        ...action.payload.transcript,
                    },
                },
            };
        }
        case TRANSCRIPT.UPDATE_MEDIA_URL.START: {
            return {
                ...state,
                data: {
                    ...state.data,
                    media: {
                        ...state.data.media,
                        mediaUrl: {
                            url: null,
                            loading: true,
                        },
                    },
                },
            };
        }
        case TRANSCRIPT.UPDATE_MEDIA_URL.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    media: {
                        ...state.data.media,
                        mediaUrl: {
                            url: action.payload.mediaUrl,
                            loading: false,
                        },
                    },
                },
            };
        }
        case TRANSCRIPT.UPDATE_MEDIA_URL.FAILURE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    media: {
                        ...state.data.media,
                        mediaUrl: {
                            url: null,
                            loading: false,
                            error: action.payload.error,
                        },
                    },
                },
            };
        }
        case TRANSCRIPT.PIN_QUOTE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithPinnedQuote(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.BULK_CONFIRM.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptBulkConfirmed(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.CONFIRM_QUOTE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithConfirmStatus(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.UPDATE_GEM: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithGemsUpdated(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.UPDATE_TAG: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithTagsUpdated(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.UPDATE_ASSIGNEE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithAssigneeUpdate(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.UPDATE_DUE_DATE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithDueDateUpdate(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.UPDATE_IS_DONE: {
            return {
                ...state,
                data: {
                    ...state.data,
                    transcript: getTranscriptWithIsDoneUpdate(
                        state.data.transcript,
                        action,
                    ),
                },
            };
        }
        case TRANSCRIPT.UPDATE_HIDDEN_SPEAKERS: {
            return {
                ...state,
                hiddenSpeakers: action.payload.hiddenSpeakers,
            };
        }
        default:
            return state;
    }
}

export default transcriptReducer;
