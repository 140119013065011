import 'regenerator-runtime/runtime';

import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import { createInjectorsEnhancer } from 'redux-injectors';
import createSagaMiddleware from 'redux-saga';
import storePersistMiddleware from '@/redux/middlewares/storePersistMiddleware';

const createStoreForInjectors = (sagaOptions = {}, initialReducers = {}) => {
    const sagaMiddleware = createSagaMiddleware({
        ...sagaOptions,
    });
    const createReducer = (injectedReducers = {}) => {
        const appReducer = combineReducers({
            ...initialReducers,
            ...injectedReducers,
            nonInjected: (s = {}) => s,
        });

        const rootReducer = (state, action) => {
            if (action.type === 'AUTH/USER_LOG_OUT') {
                return appReducer(undefined, action);
            }
            return appReducer(state, action);
        };

        return rootReducer;
    };

    const runSaga = sagaMiddleware.run;
    const middlewares = [sagaMiddleware];

    const enhancers = [
        applyMiddleware(...middlewares, storePersistMiddleware),
        createInjectorsEnhancer({ createReducer, runSaga }),
    ];

    const composeEnhancers =
        process.env.NEXT_PUBLIC_ENV !== 'production' &&
        typeof window !== 'undefined' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
            ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
            : compose;

    return createStore(
        createReducer(),
        initialReducers,
        composeEnhancers(...enhancers),
    );
};

export default createStoreForInjectors;
