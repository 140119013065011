import {
    COMMENTS_VIEW_KEY,
    CONVERSATION_QUESTIONS_KEY,
    CONVERSATION_RESULT_KEY,
    CONVERSATION_RESULT_VIEW_KEY,
    INSIGHTS_VIEW_KEY,
    TRANSCRIPT_SEARCH_KEY,
} from '@/constants/reduxKeys';
import {
    CONVERSATION_RESULT,
    fetchConversationResultFailure,
    fetchConversationResultSuccess,
} from '@/redux/actions/conversation/conversationResult';
import { all, put, select, takeLatest } from 'redux-saga/effects';
import {
    getConversation,
    getGuestConversation,
} from '@/redux/sagas/conversationResult/subSagas/conversationSubSaga';
import {
    getGuestTranscript,
    getTranscript,
} from '@/redux/sagas/conversationResult/subSagas/transcriptSubSaga';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';

import { CONVERSATION_RESULT_STATE } from '@/constants/conversations/conversationResultsState';
import commentsViewReducer from '@/redux/reducers/conversation/commentsViewReducer';
import conversationQuestionsReducer from '@/redux/reducers/conversation/conversationQuestionsReducer';
import conversationQuestionsSaga from '@/redux/sagas/conversation/questionSaga';
import conversationResultReducer from '@/redux/reducers/conversation/conversationResultReducer';
import conversationResultViewReducer from '@/redux/reducers/conversation/conversationResultViewReducer';
import { fetchAssemblyTranscriptSuccess } from '@/redux/actions/assemblyTranscriptAction';
import { getAssemblyAi } from '@/redux/sagas/conversationResult/subSagas/assemblyAiSubSaga';
import { getConversationIdSelector } from '@/redux/selectors/conversation/conversationSelector';
import { getMeetingId } from '@/redux/sagas/conversationResult/subSagas/meetingIdSubSaga';
import { getQuestions } from '@/redux/sagas/conversationResult/subSagas/questionsSubSaga';
import insightsViewReducer from '@/redux/reducers/conversation/insightsViewReducer';
import insightsViewSaga from '@/redux/sagas/conversation/insightsViewSaga';
import searchReducer from '@/redux/reducers/conversation/searchReducer';
import searchSaga from '@/redux/sagas/conversation/searchSaga';
import useConversationInjectors from '@/redux/sagas/conversation/useConversationInjectors';
import useMeetingInjectors from '@/redux/sagas/meeting/useMeetingInjectors';
import useTranscriptInjectors from '@/redux/sagas/transcript/useTranscriptInjectors';

const isConversationReady = (conversationData) =>
    conversationData.transcription_status === 'completed' &&
    conversationData.question_model_status;

function* getConversationWithAssembly(conversationId) {
    const conversationData = yield getConversation(conversationId);

    if (!isConversationReady(conversationData)) {
        // noinspection ExceptionCaughtLocallyJS
        yield put(
            fetchConversationResultFailure(
                CONVERSATION_RESULT_STATE.ERRORS.CONVERSATION_NOT_READY,
            ),
        );
    } else if (conversationData.assembly_transcript) {
        yield put(
            fetchAssemblyTranscriptSuccess(
                conversationData.assembly_transcript,
            ),
        );
    } else {
        yield getAssemblyAi(conversationData.transcript_id);
    }
}

export function* getConversationResult(action) {
    try {
        let conversationId = action.payload.data.id;
        // Refetch when id is not provided

        if (!conversationId) {
            conversationId = yield select(getConversationIdSelector);
        }

        yield all([
            getMeetingId(conversationId),
            getConversationWithAssembly(conversationId),
            getTranscript(conversationId),
            getQuestions(conversationId),
        ]);

        yield put(fetchConversationResultSuccess());
    } catch (err) {
        yield put(fetchConversationResultFailure(err.toString()));
    }
}

export function* getGuestConversationResult(action) {
    try {
        let conversationId = action.payload.data.id;
        // Refetch when id is not provided

        if (!conversationId) {
            conversationId = yield select(getConversationIdSelector);
        }

        const fetchingResults = yield all([
            getGuestTranscript(conversationId),
            getGuestConversation(conversationId),
        ]);
        const [, conversationData] = fetchingResults;

        if (!isConversationReady(conversationData)) {
            // noinspection ExceptionCaughtLocallyJS
            yield put(
                fetchConversationResultFailure(
                    CONVERSATION_RESULT_STATE.ERRORS.CONVERSATION_NOT_READY,
                ),
            );
        } else {
            yield getAssemblyAi(conversationData.transcript_id);
            yield put(fetchConversationResultSuccess());
        }
    } catch (err) {
        yield put(fetchConversationResultFailure(err.toString()));
    }
}

export default function* conversationResultSaga() {
    yield takeLatest(CONVERSATION_RESULT.FETCH_START, getConversationResult);
    yield takeLatest(
        CONVERSATION_RESULT.GUEST_FETCH_START,
        getGuestConversationResult,
    );
}

export function useInjectDependencies() {
    useInjectSaga({
        key: CONVERSATION_RESULT_KEY,
        saga: conversationResultSaga,
    });

    useInjectReducer({
        key: CONVERSATION_RESULT_KEY,
        reducer: conversationResultReducer,
    });
    useInjectSaga({
        key: CONVERSATION_QUESTIONS_KEY,
        saga: conversationQuestionsSaga,
    });

    useInjectReducer({
        key: CONVERSATION_QUESTIONS_KEY,
        reducer: conversationQuestionsReducer,
    });

    useInjectReducer({ key: INSIGHTS_VIEW_KEY, reducer: insightsViewReducer });
    useInjectSaga({ key: INSIGHTS_VIEW_KEY, saga: insightsViewSaga });
    useInjectReducer({ key: COMMENTS_VIEW_KEY, reducer: commentsViewReducer });
    useInjectReducer({
        key: CONVERSATION_RESULT_VIEW_KEY,
        reducer: conversationResultViewReducer,
    });
    useInjectReducer({
        key: TRANSCRIPT_SEARCH_KEY,
        reducer: searchReducer,
    });
    useInjectSaga({
        key: TRANSCRIPT_SEARCH_KEY,
        saga: searchSaga,
    });

    useConversationInjectors();
    useTranscriptInjectors();
    useMeetingInjectors();
}
