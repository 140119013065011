import {
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';

export const SUBSCRIPTION_PLANS_FETCH_START = 'SUBSCRIPTION_PLANS/FETCH_START';
export const SUBSCRIPTION_PLANS_FETCH_SUCCESS =
    'SUBSCRIPTION_PLANS/FETCH_SUCCESS';
export const SUBSCRIPTION_PLANS_FETCH_FAILURE =
    'SUBSCRIPTION_PLANS/FETCH_FAILURE';

export const fetchSubscriptionPlans = () =>
    fetchData(SUBSCRIPTION_PLANS_FETCH_START);
export const fetchSubscriptionPlansSuccess = (data) =>
    fetchSuccess(SUBSCRIPTION_PLANS_FETCH_SUCCESS, data);
export const fetchSubscriptionPlansFailure = (error) =>
    fetchFailure(SUBSCRIPTION_PLANS_FETCH_FAILURE, error);
