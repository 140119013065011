import { UserTypes } from '@/constants/conversations/userTypes';

export const PAGINATION_DEFAULTS = {
    current: 1,
    pageSize: 10,
};

export const SORT_FILTER_VARIANTS = {
    time: 'time',
    pinned: 'pinned',
    comments: 'comments',
};

export const SPEAKERS_FILTER_VARIANTS = {
    everyone: UserTypes.everyone,
    contacts: UserTypes.contacts,
    team: UserTypes.owners,
};

export const INSIGHT_FILTERS_DATA = {
    sortBy: {
        name: 'sortBy',
        options: [
            { title: 'Time', value: SORT_FILTER_VARIANTS.time },
            { title: 'Pinned', value: SORT_FILTER_VARIANTS.pinned },
            { title: 'Comments', value: SORT_FILTER_VARIANTS.comments },
        ],
        outerLabel: 'Sort by:',
    },
    speakers: {
        name: 'speakers',
        options: [
            { title: 'Everyone', value: SPEAKERS_FILTER_VARIANTS.everyone },
            {
                title: 'Contacts',
                value: SPEAKERS_FILTER_VARIANTS.contacts,
            },
            {
                title: 'Team Members',
                value: SPEAKERS_FILTER_VARIANTS.team,
            },
        ],
        outerLabel: 'Speakers:',
    },
};
