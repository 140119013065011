import React, { useRef, useState, useEffect } from 'react';
import { func } from 'prop-types';
import { AlertMessage, ReloadButton } from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import {
    useConversationContext,
    useMeetingContext,
} from '@/components/conversations/conversations/context/context';
import Attendees from '@/components/conversations/conversations/headerActionsButton/attendees/attendees';
import { RecordingContextProvider } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/recordingContext';
import { MediaRecorderProvider } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';
import { useMediaRecovery } from '@/utils/recording/recovery/useMediaRecovery';
import ActionsPlaceholder from '@/components/conversations/conversations/headerActionsButton/actions/actionsPlaceholder';
import ViewResults from '@/components/conversations/conversations/headerActionsButton/actions/viewResults';
import RecordingActions from '@/components/conversations/conversations/headerActionsButton/actions/recordingActions';
import { clearCurrentConvAndUserTime } from '@/components/conversations/conversations/headerActionsButton/utils';
import useHandleTimerOnlyMode from '@/components/conversations/conversations/headerActionsButton/hooks/useHandleTimerOnlyMode';
import useStatusPoll from '@/components/conversations/conversations/headerActionsButton/hooks/useStatusPoll';
import { POLL_STATE } from '@/constants/poll/pollState';
import UploadProgress from '@/components/insights/recordings/fields/upload/uploadProgress';
import { useSelector, useDispatch } from 'react-redux';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import useHandleStartQuery from '@/components/conversations/conversations/headerActionsButton/hooks/useHandleStartQuery';
import RecoveryModal from '@/components/conversations/conversations/headerActionsButton/recoveryModal/recoveryModal';
import { settingsRecordingsMicRecordingProgress, settingsRecordingsMicRecordingStatus } from '@/redux/actions/settings/recordingsAction';
import { getMicRecordingStatus } from '@/redux/selectors/settings/settingsRecordingsSelector';
import UploadFileModal from './uploadFileModal/uploadFileModal';

const uploadingStatuses = [
    POLL_STATE.CLASSIFIERS_FETCHING,
    POLL_STATE.TS_FETCHING,
    POLL_STATE.TS_PRE_PROCESSING,
    POLL_STATE.UPLOADING,
    POLL_STATE.INITIAL,
    POLL_STATE.CONVERSATION_REFETCH,
    ...POLL_STATE.ERRORS_ARRAY(),
];

/** There is an error which may happen for users who just tackled error during uploading his file
 *  - after reloading page user should see the state of the recording like it was before upload crash
 *  - after pressing "Analyze Button", given states will be shown: [Error analyzing file - 1s, Uploading - 2s (or more), Error analyzing file - 1s, Transcribing ...and so on]
 *  - I don't want to add more state management to handle this situation because refactor of context state management should be done first - see conversationContext.jsx
 * */

function HeaderActionButtons({reloadComponent}) {
    const dispatch = useDispatch();
    const { meetingExternalId } = useMeetingContext();
    const userId = useSelector(getUserIdSelector);
    const micRecordingStatus = useSelector(getMicRecordingStatus);
    const { title, conversationId, conversationDate, conversationData } =
        useConversationContext();

    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [isRecoveryModalVisible, setRecoveryModalVisible] = useState(false);
    const onRecoveryModalFinish = useRef(null);

    const hasRestoredTimerState = useHandleTimerOnlyMode();

    const {
        isAutoRecoveryPending,
        recoveryData,
        clearAllRecoveryData,
        clearTempRecoveryData,
    } = useMediaRecovery(conversationId, userId, meetingExternalId, title);

    const { upload, percentage, status, isError, polledConversation } =
        useStatusPoll(clearTempRecoveryData);

    useHandleStartQuery(recoveryData);

    const recoveryDataForCurrentConversationLoaded =
        !!recoveryData.conversationId &&
        recoveryData.conversationId === conversationId;

    const viewLoading =
        !hasRestoredTimerState ||
        !conversationId ||
        !userId ||
        !conversationDate ||
        isAutoRecoveryPending;

    const resetMicRecording = () => {
        dispatch(settingsRecordingsMicRecordingProgress(0));
        reloadComponent();
    }

    useEffect(() => {
        if (percentage) {
            dispatch(settingsRecordingsMicRecordingProgress(parseInt(percentage, 10)));
        }
        if (status) {
            dispatch(settingsRecordingsMicRecordingStatus(status));
        }
        if (status === POLL_STATE.COMPLETED) {
            dispatch(settingsRecordingsMicRecordingProgress(100));
        }
    }, [percentage, status, dispatch]);

    return (
        <>
            <div>
                {viewLoading ? <span /> : <Attendees />}
                {(() => {
                    if (viewLoading)
                        return (
                            <ActionsPlaceholder
                                text={
                                    isAutoRecoveryPending
                                        ? "We're trying to prepare your recording."
                                        : undefined
                                }
                            />
                        );

                    if (status === POLL_STATE.COMPLETED)
                        return <>
                            <ViewResults conversationId={conversationId} />
                            <ReloadButton type="link" onClick={() => resetMicRecording()}>Start New Mic Recording</ReloadButton>
                        </>;

                    // Handles situation when conversation processing failed but there are still some data which may let user recover it
                    const shouldRecoveryOnErrorStatus =
                        recoveryDataForCurrentConversationLoaded &&
                        POLL_STATE.ERRORS_ARRAY().includes(status);

                    if (!shouldRecoveryOnErrorStatus) {
                        if (uploadingStatuses.includes(status))
                            return (
                                <>
                                    <UploadProgress
                                        isError={isError}
                                        status={status}
                                        percentage={percentage}
                                        uploadPercentage={percentage}
                                        conversation={
                                            polledConversation ?? conversationData
                                        }
                                        reloadComponent={reloadComponent}
                                    />
                                    {status === POLL_STATE.CLASSIFIERS_FETCHING || micRecordingStatus === 'POLLING_ERROR' ? <ReloadButton type="link" onClick={() => resetMicRecording()}>Start New Mic Recording</ReloadButton> : ''}
                                </>
                            );
                    }

                    return (
                        <div>
                            {isRecoveryModalVisible && (
                                <RecoveryModal
                                    onClear={() => {
                                        clearAllRecoveryData();
                                        if (
                                            typeof onRecoveryModalFinish.current ===
                                            'function'
                                        ) {
                                            onRecoveryModalFinish.current();
                                            onRecoveryModalFinish.current = false;
                                        }
                                    }}
                                    hide={() => {
                                        setRecoveryModalVisible(false);
                                    }}
                                    processedData={recoveryData}
                                />
                            )}
                            <RecordingActions
                                setUploadModalVisible={setUploadModalVisible}
                                clearCurrentConvAndUserTime={
                                    clearCurrentConvAndUserTime
                                }
                                clearAllRecoveryData={clearAllRecoveryData}
                                // only match when it's not recovery data for current conversation
                                recoveryDataFound={
                                    !!recoveryData.conversationId &&
                                    recoveryData.conversationId !== conversationId
                                }
                                isAutoRecoveryPending={isAutoRecoveryPending}
                                launchExternalRecoveryModal={(onClear) => {
                                    setRecoveryModalVisible(true);
                                    onRecoveryModalFinish.current = onClear;
                                }}
                            />
                        </div>
                    );
                })()}
                <UploadFileModal
                    isModalVisible={uploadModalVisible}
                    setIsModalVisible={setUploadModalVisible}
                    onUpload={(file) => {
                        setUploadModalVisible(false);
                        upload(file);
                    }}
                />
            </div>
            {uploadingStatuses.includes(status) && !POLL_STATE.ERRORS_ARRAY().includes(status) && status !== POLL_STATE.COMPLETED && status !== POLL_STATE.CLASSIFIERS_FETCHING  ? 
                <AlertMessage message="Please don't exit the app while the recording is processing." type="warning" showIcon />                    
                : 
                ''}
        </>
    );
}

HeaderActionButtons.propTypes = {
    reloadComponent: func,
};

HeaderActionButtons.defaultProps = {
    reloadComponent: () => {},
};

export default function HeaderActionButtonsWithContext({reloadComponent}) {
    return (
        <MediaRecorderProvider>
            <RecordingContextProvider>
                <HeaderActionButtons reloadComponent={reloadComponent} />
            </RecordingContextProvider>
        </MediaRecorderProvider>
    );
}

HeaderActionButtonsWithContext.propTypes = {
    reloadComponent: func,
};

HeaderActionButtonsWithContext.defaultProps = {
    reloadComponent: () => {},
};
