import { POLL_STATE, TS_ERRORS } from '@/constants/poll/pollState';

export const isTsCompleted = (conversation) =>
    conversation?.transcription_status === 'completed';

export default function usePollStatus(conversation, status, emptyAsPending) {
    const conv = conversation;
    const tsStatus = conv?.transcription_status;
    const questionStatus = conv?.question_model_status;

    if (POLL_STATE.LOADING_STATES().includes(status)) {
        if (isTsCompleted(conv) && questionStatus)
            return POLL_STATE.COMPLETED;
        if (isTsCompleted(conv) && (!questionStatus))
            return POLL_STATE.CLASSIFIERS_FETCHING;
        if (tsStatus === 'queued') return POLL_STATE.TS_FETCHING;
        if (emptyAsPending && (!tsStatus || tsStatus === 'pending')) {
            return POLL_STATE.TS_PRE_PROCESSING;
        }
        if (tsStatus === TS_ERRORS.NO_AUDIO) return POLL_STATE.ERRORS.NO_AUDIO;
        if (tsStatus === TS_ERRORS.DEFAULT) return POLL_STATE.ERRORS.TS_DEFAULT;
        if (tsStatus === 'pending') return POLL_STATE.TS_PRE_PROCESSING;
        if (status) return POLL_STATE.TS_PRE_PROCESSING;
    }
    return status;
}
