import { useEffect } from 'react';
import { all } from 'redux-saga/effects';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { combineReducers } from 'redux';
import { SETTINGS_KEY } from '@/constants/reduxKeys';
import settingsRecordingsReducer from '@/redux/reducers/settings/settingsRecordingsReducer';
import settingsRecordingsSaga from '@/redux/sagas/settings/settingsRecordingsSaga';
import { fetchSettingsRecordings } from '@/redux/actions/settings/recordingsAction';
import settingsCalendarReducer from '@/redux/reducers/settings/settingsCalendarReducer';
import settingsCalendarSaga from '@/redux/sagas/settings/settingsCalendarSaga';
import { fetchSettingsCalendar } from '@/redux/actions/settings/calendarAction';
import topicTrackerReducer from '@/redux/reducers/settings/settingsTopicTrackerReducer';
import settingsTopicTrackerSaga from '@/redux/sagas/settings/settingsTopicTrackerSaga';
import { fetchSettingsTopicTracker } from '@/redux/actions/settings/topicTrackerAction';
import notificationsReducer from '@/redux/reducers/settings/notificationsReducer';
import gemSettingsSaga from '@/redux/sagas/settings/gemSettingsSaga';
import settingsNotificationsSaga from '@/redux/sagas/settings/settingsNotificationsSaga';
import { fetchGemSettings } from '@/redux/actions/settings/gemSettingsAction';
import gemSettingsReducer from '@/redux/reducers/settings/gemSettingsReducer';
import { getCompanyIsActiveSubscription } from '@/redux/selectors/companySelector';

function* combinedSaga() {
    yield all([
        settingsRecordingsSaga(),
        settingsCalendarSaga(),
        settingsTopicTrackerSaga(),
        settingsNotificationsSaga(),
        gemSettingsSaga(),
    ]);
}

export default function SettingsInjector() {
    useInjectReducer({
        key: SETTINGS_KEY,
        reducer: combineReducers({
            recordings: settingsRecordingsReducer,
            calendar: settingsCalendarReducer,
            topicTracker: topicTrackerReducer,
            notifications: notificationsReducer,
            gemSettings: gemSettingsReducer,
        }),
    });
    useInjectSaga({
        key: SETTINGS_KEY,
        saga: combinedSaga,
    });

    const dispatch = useDispatch();

    const isSubscribed = useSelector(getCompanyIsActiveSubscription);

    useEffect(() => {
        if (isSubscribed) {
            dispatch(fetchSettingsRecordings());
            dispatch(fetchSettingsCalendar());
            dispatch(fetchSettingsTopicTracker());
            dispatch(fetchGemSettings());
        }
    }, [dispatch, isSubscribed]);

    return null;
}
