import {
    deleteFailure,
    deleteStart,
    deleteSuccess,
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';

export const TEAM_MEMBERS_FETCH_START = 'TEAM_MEMBERS/FETCH_START';
export const TEAM_MEMBERS_FETCH_SUCCESS = 'TEAM_MEMBERS/FETCH_SUCCESS';
export const TEAM_MEMBERS_FETCH_FAILURE = 'TEAM_MEMBERS/FETCH_FAILURE';

export const TEAM_MEMBER_UPDATE_START = 'TEAM_MEMBERS/UPDATE_START';
export const TEAM_MEMBER_UPDATE_SUCCESS = 'TEAM_MEMBERS/UPDATE_SUCCESS';
export const TEAM_MEMBER_UPDATE_FAILURE = 'TEAM_MEMBERS/UPDATE_FAILURE';

export const TEAM_MEMBER_DELETE_START = 'TEAM_MEMBERS/DELETE_START';
export const TEAM_MEMBER_DELETE_SUCCESS = 'TEAM_MEMBERS/DELETE_SUCCESS';
export const TEAM_MEMBER_DELETE_FAILURE = 'TEAM_MEMBERS/DELETE_FAILURE';

export const TEAM_MEMBERS_FETCH_METRICS_START = 'TEAM_MEMBERS/FETCH_METRICS_START';
export const TEAM_MEMBERS_FETCH_METRICS_SUCCESS = 'TEAM_MEMBERS/FETCH_METRICS_SUCCESS';

export const TEAM_MEMBERS_TOGGLE_ARCHIVED = 'TEAM_MEMBERS/TOGGLE_ARCHIVED';

export const fetchTeamMembers = () => fetchData(TEAM_MEMBERS_FETCH_START);
export const fetchTeamMembersSuccess = (data) =>
    fetchSuccess(TEAM_MEMBERS_FETCH_SUCCESS, data);
export const fetchTeamMembersFailure = (error) =>
    fetchFailure(TEAM_MEMBERS_FETCH_FAILURE, error);

export const teamMemberUpdateStart = () =>
    updateStart(TEAM_MEMBER_UPDATE_START);
export const teamMemberUpdateFailure = (error) =>
    updateFailure(TEAM_MEMBER_UPDATE_FAILURE, error);
export const teamMemberUpdateSuccess = (data) =>
    updateSuccess(TEAM_MEMBER_UPDATE_SUCCESS, data);

export const teamMemberDeleteStart = () =>
    deleteStart(TEAM_MEMBER_DELETE_START);
export const teamMemberDeleteFailure = (id, error) =>
    deleteFailure(TEAM_MEMBER_DELETE_FAILURE, error, id);
export const teamMemberDeleteSuccess = (id) =>
    deleteSuccess(TEAM_MEMBER_DELETE_SUCCESS, id);
    
export const fetchTeamMembersMetrics = (id) => fetchData(TEAM_MEMBERS_FETCH_METRICS_START, id);
export const fetchTeamMembersMetricsSuccess = (data) =>
    fetchSuccess(TEAM_MEMBERS_FETCH_METRICS_SUCCESS, data);

export const toggleToggleArchivedTeamMembers = () => ({
    type: TEAM_MEMBERS_TOGGLE_ARCHIVED,
});