import { fork, put, take, takeEvery, call } from 'redux-saga/effects';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchTeamMembersFailure,
    fetchTeamMembersSuccess,
    TEAM_MEMBERS_FETCH_START,
    teamMemberDeleteFailure,
    teamMemberDeleteStart,
    teamMemberDeleteSuccess,
    teamMemberUpdateFailure,
    teamMemberUpdateStart,
    teamMemberUpdateSuccess,
    TEAM_MEMBERS_FETCH_METRICS_START,
    fetchTeamMembersMetricsSuccess,
} from '@/redux/actions/teamMembersAction';
import {
    USER_ARCHIVE_FAILURE,
    USER_ARCHIVE_START,
    USER_ARCHIVE_SUCCESS,
    USER_DELETE_FAILURE,
    USER_DELETE_START,
    USER_DELETE_SUCCESS,
    USER_REACTIVATE_FAILURE,
    USER_REACTIVATE_START,
    USER_REACTIVATE_SUCCESS,
    USER_UPDATE_FAILURE,
    USER_UPDATE_START,
    USER_UPDATE_SUCCESS,
} from '@/redux/actions/usersAction';

function* getTeamMembersSaga() {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.TEAM_MEMBERS);
        yield put(fetchTeamMembersSuccess(data));
    } catch (err) {
        yield put(fetchTeamMembersFailure(err));
    }
}

function* getTeamMembersMetricsSaga(action) {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.SPEAKER_CONVERSATION_METRICS(action.payload.data));
        yield put(fetchTeamMembersMetricsSuccess(data));
    } catch (err) {
        yield put(fetchTeamMembersMetricsSuccess(err));
        console.log(err);
    }
}

function* teamMemberUpdateSaga() {
    while (true) {
        yield take(USER_UPDATE_START);
        yield put(teamMemberUpdateStart());

        const yieldAction = yield take([
            USER_UPDATE_SUCCESS,
            USER_UPDATE_FAILURE,
        ]);
        if (yieldAction.type === USER_UPDATE_SUCCESS) {
            yield put(teamMemberUpdateSuccess(yieldAction.payload.data));
        } else {
            yield put(teamMemberUpdateFailure(yieldAction.payload.error));
        }
    }
}

function* teamMemberDeleteSaga() {
    while (true) {
        yield take(USER_DELETE_START);
        yield put(teamMemberDeleteStart());

        const yieldAction = yield take([
            USER_DELETE_SUCCESS,
            USER_DELETE_FAILURE,
        ]);

        if (yieldAction.type === USER_DELETE_SUCCESS) {
            const id = yieldAction.payload.data;
            yield put(teamMemberDeleteSuccess(id));
        } else {
            yield put(
                teamMemberDeleteFailure(
                    yieldAction.payload.id,
                    yieldAction.payload.error,
                ),
            );
        }
    }
}

function* teamMemberArchiveSaga() {
    while (true) {
        yield take(USER_ARCHIVE_START);
        yield put(teamMemberUpdateStart());

        const yieldAction = yield take([
            USER_ARCHIVE_SUCCESS,
            USER_ARCHIVE_FAILURE,
        ]);

        if (yieldAction.type === USER_ARCHIVE_SUCCESS) {
            const { id } = yieldAction.payload;
            yield put(
                teamMemberUpdateSuccess({
                    id,
                    deleted_at: new Date().toISOString(),
                }),
            );
        } else {
            yield put(teamMemberUpdateFailure(yieldAction.payload.error));
        }
    }
}

function* teamMemberReactiveSaga() {
    while (true) {
        yield take(USER_REACTIVATE_START);
        yield put(teamMemberUpdateStart());

        const yieldAction = yield take([
            USER_REACTIVATE_SUCCESS,
            USER_REACTIVATE_FAILURE,
        ]);

        if (yieldAction.type === USER_REACTIVATE_SUCCESS) {
            const { id } = yieldAction.payload;
            yield put(teamMemberUpdateSuccess({ id, deleted_at: null }));
        } else {
            yield put(teamMemberUpdateFailure(yieldAction.payload.error));
        }
    }
}

export default function* teamMembersSaga() {
    yield takeEvery(TEAM_MEMBERS_FETCH_START, getTeamMembersSaga);
    yield takeEvery(TEAM_MEMBERS_FETCH_METRICS_START, getTeamMembersMetricsSaga);
    yield fork(teamMemberDeleteSaga);
    yield fork(teamMemberUpdateSaga);
    yield fork(teamMemberArchiveSaga);
    yield fork(teamMemberReactiveSaga);
}
