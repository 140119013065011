import { FETCH_STATUS } from '@/constants/fetchStatus';
import { createSelector } from 'reselect';

export const getIsLoadingSelector = (state) =>
    state?.company?.status === FETCH_STATUS.INITIAL_LOADING ||
    state?.company?.status === FETCH_STATUS.UPDATE_LOADING ||
    state?.company?.status === FETCH_STATUS.CREATE_LOADING ||
    state?.company?.status === FETCH_STATUS.DELETE_LOADING;

export const getCompanyDataSelector = createSelector(
    [(state) => state?.company?.data],
    (companyData) => companyData,
);

export const getCompanyInitialLoadedSelector = (state) =>
    state.company.status === FETCH_STATUS.INITIAL_LOADED;

export const getCompanyNameSelector = (state) => state.company?.data?.name;
export const getCompanyIdSelector = (state) => state.company.data.id;
export const getCompanyPhotoSelector = (state) => state.company?.data?.photo;
export const getCompanySubscriptionId = (state) =>
    state.company.data.subscription_id;
export const getCompanyIsActiveSubscription = (state) =>
    state.company?.data?.is_subscription_active;
