import {
    RECORDINGS_RECOVERY_NO_DATA,
    RECORDINGS_RECOVERY_READ_FAILURE,
    RECORDINGS_RECOVERY_READ_START,
    RECORDINGS_RECOVERY_READ_SUCCESS,
} from '@/redux/actions/recordings/recordingRecoveryActions';

export const initialState = {
    data: null,
    loading: false,
    error: null,
};

function recordingsRecoveryReducer(state = initialState, action) {
    switch (action.type) {
        case RECORDINGS_RECOVERY_READ_START: {
            return {
                ...state,
                loading: true,
            };
        }
        case RECORDINGS_RECOVERY_READ_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                loading: false,
                error: null,
            };
        }
        case RECORDINGS_RECOVERY_READ_FAILURE: {
            return {
                ...state,
                data: null,
                loading: false,
                error: action.payload.error,
            };
        }
        case RECORDINGS_RECOVERY_NO_DATA: {
            return {
                ...state,
                data: null,
                loading: false,
                error: null,
            };
        }
        default:
            return state;
    }
}

export default recordingsRecoveryReducer;
