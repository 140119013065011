export const analyticsItems = {
    sentiment: {
        title: 'Sentiment',
        key: 'sentiment',
    },
    keywords: {
        key: 'keywords',
        title: 'Keywords',
    },
    longestMonologue: {
        key: 'longestMonologue',
        title: 'Longest monologue',
    },
    talkListenRatio: {
        key: 'talkToListenRatio',
        title: 'Talk Percentage',
    },
    comments: {
        key: 'comments',
        title: 'Comments',
    },
    questions: {
        key: 'questions',
        title: 'Questions',
    },
    topics: {
        key: 'topics',
        title: 'Topics',
    },
};
