export const speakersColorPalette = [
    '#faad14',
    '#1890ff',
    '#52c41a',
    '#13c2c2',
    '#f5222d',
    '#fadb14',
    '#722ed1',
    '#eb2f96',
    '#2f54eb',
    '#a0d911',
    '#fa8c16',
    '#fa541c',
];
