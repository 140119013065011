import { ATTENDEES } from '@/redux/actions/_exports';

export const initialState = {
    loading: false,
    error: null,
    isIdle: true,
    attendees: [],
    attendeesDetails: {attendees: [], total: ""},
    total: 0,
    attendee: {
        attendee: {},
        loading: false,
        error: null,
    },
};

function recordsAttendeesReducer(state = initialState, action) {
    switch (action.type) {
        case ATTENDEES.FETCH_START: {
            return {
                ...state,
                loading: true,
                error: false,
                isIdle: false,
            };
        }
        case ATTENDEES.FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                total: action.payload.attendees.total,
                attendees: action.payload.attendees.attendees,
            };
        }
        case ATTENDEES.FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case ATTENDEES.DETAILS_FETCH.START: {
            return {
                ...state,
                loading: true,
                error: false,
                isIdle: false,
            };
        }
        case ATTENDEES.DETAILS_FETCH.SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                attendeesDetails: action.payload.attendees,
            };
        }
        case ATTENDEES.DETAILS_FETCH.FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case ATTENDEES.ATTENDEE_FETCH.START: {
            return {
                ...state,
                attendee: {
                    ...state.attendee,
                    loading: true,
                },
            };
        }
        case ATTENDEES.ATTENDEE_FETCH.SUCCESS: {
            return {
                ...state,
                attendee: {
                    loading: false,
                    error: false,
                    attendee: action.payload.attendee,
                },
            };
        }
        case ATTENDEES.ATTENDEE_FETCH.FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                attendee: {
                    ...state.attendee,
                    loading: false,
                    error: true,
                },
            };
        }
        case ATTENDEES.ATTENDEE_ADD.START: {
            return {
                ...state,
                attendee: {
                    ...state.attendee,
                    loading: true,
                },
            };
        }
        case ATTENDEES.ATTENDEE_ADD.SUCCESS: {
            return {
                ...state,
                attendees: [...state.attendees, action.payload.attendee],
                attendee: {
                    loading: false,
                    error: false,
                    attendee: action.payload.attendee,
                },
            };
        }
        case ATTENDEES.ATTENDEE_ADD.FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                attendee: {
                    ...state.attendee,
                    loading: false,
                    error: true,
                },
            };
        }
        case ATTENDEES.ATTENDEE_REMOVE.START: {
            return {
                ...state,
                loading: true,
            };
        }
        case ATTENDEES.ATTENDEE_REMOVE.SUCCESS: {
            return {
                ...state,
                loading: false,
                attendees: state.attendees.filter(
                    ({ id }) => id !== action.payload.id,
                ),
                total: state.total - 1,
            };
        }
        case ATTENDEES.ATTENDEE_REMOVE.FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default recordsAttendeesReducer;
