import {
    CUSTOM_TAGS_FETCH_START,
    CUSTOM_TAGS_FETCH_SUCCESS,
    CUSTOM_TAGS_FETCH_FAILURE,
    CUSTOM_TAGS_UPDATE_START,
    CUSTOM_TAGS_UPDATE_SUCCESS,
    CUSTOM_TAGS_UPDATE_FAILURE,
    CUSTOM_TAGS_CREATE_START,
    CUSTOM_TAGS_CREATE_SUCCESS,
    CUSTOM_TAGS_CREATE_FAILURE,
    CUSTOM_TAGS_DELETE_START,
    CUSTOM_TAGS_DELETE_SUCCESS,
    CUSTOM_TAGS_DELETE_FAILURE,
    CUSTOM_TAGS_RESET_STATUS,
} from '@/redux/actions/customTagsAction';
import { FETCH_STATUS } from '@/constants/fetchStatus';

export const initialState = {
    data: [],
    status: FETCH_STATUS.IDLE,
    error: null,
};

function customTagsReducer(state = initialState, action) {
    switch (action.type) {
        case CUSTOM_TAGS_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case CUSTOM_TAGS_FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case CUSTOM_TAGS_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case CUSTOM_TAGS_UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case CUSTOM_TAGS_UPDATE_SUCCESS: {
            const { id } = action.payload.data;
            return {
                ...state,
                data: state.data.map((topic) => {
                    if (topic.id === id) {
                        return { ...topic, ...action.payload.data };
                    }
                    return topic;
                }),
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case CUSTOM_TAGS_UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case CUSTOM_TAGS_CREATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.CREATE_LOADING,
                error: null,
                lastCreatedTag: null,
            };
        }
        case CUSTOM_TAGS_CREATE_SUCCESS: {
            return {
                ...state,
                data: [...state.data, action.payload.data],
                status: FETCH_STATUS.CREATE_SUCCESS,
                error: null,
            };
        }
        case CUSTOM_TAGS_CREATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.CREATE_ERROR,
                error: action.payload.error,
            };
        }
        case CUSTOM_TAGS_DELETE_START: {
            return {
                ...state,
                status: FETCH_STATUS.DELETE_LOADING,
                error: null,
            };
        }
        case CUSTOM_TAGS_DELETE_SUCCESS: {
            const { id } = action.payload.data;
            return {
                data: state.data.filter((topic) => topic.id !== id),
                status: FETCH_STATUS.DELETE_SUCCESS,
                error: null,
            };
        }
        case CUSTOM_TAGS_DELETE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.DELETE_ERROR,
                error: action.payload.error,
            };
        }
        case CUSTOM_TAGS_RESET_STATUS: {
            return {
                ...state,
                status: FETCH_STATUS.IDLE,
            };
        }
        default:
            return state;
    }
}

export default customTagsReducer;
