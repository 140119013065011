export const RECORDS_CONTACTS_FETCH_START = 'RECORDS/CONTACTS_FETCH_START';
export const RECORDS_CONTACTS_FETCH_SUCCESS = 'RECORDS/CONTACTS_FETCH_SUCCESS';
export const RECORDS_CONTACTS_FETCH_FAILURE = 'RECORDS/CONTACTS_FETCH_FAILURE';
export const RECORDS_CONTACT_FETCH_START = 'RECORDS/CONTACT_FETCH_START';
export const RECORDS_CONTACT_FETCH_SUCCESS = 'RECORDS/CONTACT_FETCH_SUCCESS';
export const RECORDS_CONTACT_FETCH_FAILURE = 'RECORDS/CONTACT_FETCH_FAILURE';
export const RECORDS_CONTACT_EDIT_START = 'RECORDS/CONTACT_EDIT_START';
export const RECORDS_CONTACT_EDIT_SUCCESS = 'RECORDS/CONTACT_EDIT_SUCCESS';
export const RECORDS_CONTACT_EDIT_FAILURE = 'RECORDS/CONTACT_EDIT_FAILURE';
export const RECORDS_CONTACT_ADD_START = 'RECORDS/CONTACTS_ADD_START';
export const RECORDS_CONTACT_ADD_SUCCESS = 'RECORDS/CONTACTS_ADD_SUCCESS';
export const RECORDS_CONTACT_ADD_FAILURE = 'RECORDS/CONTACTS_ADD_FAILURE';
export const RECORDS_ASSIGNEE_ADD_START = 'RECORDS/ASSIGNEES_ADD_START';
export const RECORDS_ASSIGNEE_ADD_SUCCESS = 'RECORDS/ASSIGNEES_ADD_SUCCESS';
export const RECORDS_ASSIGNEE_ADD_FAILURE = 'RECORDS/ASSIGNEES_ADD_FAILURE';
export const RECORDS_ASSIGNEES_FETCH_START = 'RECORDS/ASSIGNEES_FETCH_START';
export const RECORDS_ASSIGNEES_FETCH_SUCCESS = 'RECORDS/ASSIGNEES_FETCH_SUCCESS';
export const RECORDS_ASSIGNEES_FETCH_FAILURE = 'RECORDS/ASSIGNEES_FETCH_FAILURE';

export const fetchContactsStart = (
    limit,
    offset,
    search,
    isSpeaker = false,
) => ({
    type: RECORDS_CONTACTS_FETCH_START,
    payload: {
        limit,
        offset,
        search,
        isSpeaker,
    },
});

export const fetchContactsSuccess = (contacts) => ({
    type: RECORDS_CONTACTS_FETCH_SUCCESS,
    payload: {
        contacts,
    },
});

export const fetchContactsFailure = () => ({
    type: RECORDS_CONTACTS_FETCH_FAILURE,
});

export const fetchContactStart = (id) => ({
    type: RECORDS_CONTACT_FETCH_START,
    payload: {
        id,
    },
});

export const fetchContactSuccess = (contact) => ({
    type: RECORDS_CONTACT_FETCH_SUCCESS,
    payload: {
        contact,
    },
});

export const fetchContactFailure = () => ({
    type: RECORDS_CONTACT_FETCH_FAILURE,
});

export const editContactStart = (crmId, id, type, value) => ({
    type: RECORDS_CONTACT_EDIT_START,
    payload: {
        crmId,
        id,
        type,
        value,
    },
});

export const editContactSuccess = (contacts) => ({
    type: RECORDS_CONTACT_EDIT_SUCCESS,
    payload: {
        contacts,
    },
});

export const editContactFailure = () => ({
    type: RECORDS_CONTACT_EDIT_FAILURE,
});

export const addContactStart = (data) => ({
    type: RECORDS_CONTACT_ADD_START,
    payload: {
        data,
    },
});

export const addContactSuccess = (createdResource) => ({
    type: RECORDS_CONTACT_ADD_SUCCESS,
    payload: {
        data: createdResource,
    },
});

export const addContactFailure = (error, contact) => ({
    type: RECORDS_CONTACT_ADD_FAILURE,
    payload: {
        error,
        data: contact,
    },
});

export const addAssigneeStart = (data) => ({
    type: RECORDS_ASSIGNEE_ADD_START,
    payload: {
        data,
    },
});

export const addAssigneeSuccess = (createdResource) => ({
    type: RECORDS_ASSIGNEE_ADD_SUCCESS,
    payload: {
        data: createdResource,
    },
});

export const addAssigneeFailure = (error, assignee) => ({
    type: RECORDS_ASSIGNEE_ADD_FAILURE,
    payload: {
        error,
        data: assignee,
    },
});

export const fetchAssigneesStart = () => ({
    type: RECORDS_ASSIGNEES_FETCH_START,
});

export const fetchAssigneesSuccess = (contacts) => ({
    type: RECORDS_ASSIGNEES_FETCH_SUCCESS,
    payload: {
        contacts,
    },
});

export const fetchAssigneesFailure = () => ({
    type: RECORDS_ASSIGNEES_FETCH_FAILURE,
});
