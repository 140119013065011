const GUIDE_BASE = 'SETTINGS_INTEGRATION_INTEGRATION_APP';

// BASIC META
export const INTEGRATION_APP_FETCH_START = `${GUIDE_BASE}/FETCH_START`;
export const INTEGRATION_APP_FETCH_SUCCESS = `${GUIDE_BASE}/FETCH_SUCCESS`;
export const INTEGRATION_APP_FETCH_FAILURE = `${GUIDE_BASE}/FETCH_FAILURE`;
export const INTEGRATION_APP_UPDATE_STEP = `${GUIDE_BASE}/UPDATE_STEP`;

// STEP 1 RELATED REDUCER ACTIONS
export const INTEGRATION_APP_CONNECTION_FETCH_START = `${GUIDE_BASE}/CONNECTION_FETCH_START`;
export const INTEGRATION_APP_CONNECTION_FETCH_SUCCESS = `${GUIDE_BASE}/CONNECTION_FETCH_SUCCESS`;
export const INTEGRATION_APP_CONNECTION_FETCH_FAILURE = `${GUIDE_BASE}/CONNECTION_FETCH_FAILURE`;

// STEP 2 RELATED REDUCER ACTIONS
export const INTEGRATION_APP_UPDATE_CONTACTS_MAPPING = `${GUIDE_BASE}/UPDATE_CONTACTS_MAPPING`;

// STEP 3 RELATED REDUCER ACTIONS
export const INTEGRATION_APP_UPDATE_LEADS_MAPPING = `${GUIDE_BASE}/UPDATE_LEADS_MAPPING`;

// STEP 4 RELATED REDUCER ACTIONS
export const INTEGRATION_APP_UPDATE_ACCOUNTS_MAPPING = `${GUIDE_BASE}/UPDATE_ACCOUNTS_MAPPING`;

// STEP 5 RELATED REDUCER ACTIONS
export const INTEGRATION_APP_MIGRATION_START = `${GUIDE_BASE}/MIGRATION_START`;
export const INTEGRATION_APP_MIGRATION_SUCCESS = `${GUIDE_BASE}/MIGRATION_SUCCESS`;
export const INTEGRATION_APP_MIGRATION_FAILURE = `${GUIDE_BASE}/MIGRATION_FAILURE`;
export const INTEGRATION_APP_MIGRATION_COMPLETE = `${GUIDE_BASE}/MIGRATION_COMPLETE`;

// #region BASIC META
export const integrationAppUpdateStep = (step) => ({
    type: INTEGRATION_APP_UPDATE_STEP,
    payload: { step },
});

export const fetchIntegrationAppStart = (name) => ({
    type: INTEGRATION_APP_FETCH_START,
    payload: {
        name,
    },
});

export const fetchIntegrationAppSuccess = (integrationApp) => ({
    type: INTEGRATION_APP_FETCH_SUCCESS,
    payload: {
        integrationApp,
    },
});

export const fetchIntegrationAppFailure = () => ({
    type: INTEGRATION_APP_FETCH_FAILURE,
});
// #endregion
// #region STEP 1
export const fetchIntegrationAppConnectionStart = (id) => ({
    type: INTEGRATION_APP_CONNECTION_FETCH_START,
    payload: {
        id,
    },
});

export const fetchIntegrationAppConnectionSuccess = (
    connectionInformation,
    mappings,
) => ({
    type: INTEGRATION_APP_CONNECTION_FETCH_SUCCESS,
    payload: {
        connectionInformation,
        mappings,
    },
});

export const fetchIntegrationAppConnectionFailure = () => ({
    type: INTEGRATION_APP_CONNECTION_FETCH_FAILURE,
});
// #endregion
// #region STEP 2
export const integrationAppUpdateContactsMapping = (key, val) => ({
    type: INTEGRATION_APP_UPDATE_CONTACTS_MAPPING,
    payload: {
        key,
        val,
    },
});
// #endregion
// #region STEP 3
export const integrationAppUpdateLeadsMapping = (key, val) => ({
    type: INTEGRATION_APP_UPDATE_LEADS_MAPPING,
    payload: {
        key,
        val,
    },
});
// #endregion
// #region STEP 4
export const integrationAppUpdateAccountsMapping = (key, val) => ({
    type: INTEGRATION_APP_UPDATE_ACCOUNTS_MAPPING,
    payload: {
        key,
        val,
    },
});
// #endregion
// #region STEP 5
export const integrationAppMigrationStart = (name, data, existingContacts) => ({
    type: INTEGRATION_APP_MIGRATION_START,
    payload: {
        name,
        data,
        existingContacts,
    },
});

export const integrationAppMigrationSuccess = () => ({
    type: INTEGRATION_APP_MIGRATION_SUCCESS,
});

export const integrationAppMigrationFailure = () => ({
    type: INTEGRATION_APP_MIGRATION_FAILURE,
});

export const integrationAppMigrationComplete = () => ({
    type: INTEGRATION_APP_MIGRATION_COMPLETE,
});
// #endregion
