export const getTranscriptWithGemsUpdated = (prevTranscript, action) => {
    const {
        payload: { contentIndex, quoteIndex, gemKey, name },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (quoteIndex !== qI) return quote;
                    const oldGemIdx = quote.gems.findIndex(
                        (g) => g.description === gemKey,
                    );
                    if (oldGemIdx !== -1)
                        return {
                            ...quote,
                            gems: quote.gems.filter(
                                (gem, gemIndex) => gemIndex !== oldGemIdx,
                            ),
                            confirmed: true,
                        };

                    return {
                        ...quote,
                        gems: [
                            ...quote.gems,
                            {
                                name,
                                description: gemKey,
                            },
                        ],
                        confirmed: true,
                    };
                }),
            };
        }),
    };
};

export const getTranscriptWithTagsUpdated = (prevTranscript, action) => {
    const {
        payload: { contentIndex, quoteIndex, tagKey, name },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (quoteIndex !== qI) return quote;
                    const oldTagIdx = quote.customTags.findIndex(
                        (g) => g.description === tagKey,
                    );
                    if (oldTagIdx !== -1)
                        return {
                            ...quote,
                            customTags: quote.customTags.filter(
                                (tag, tagIndex) => tagIndex !== oldTagIdx,
                            ),
                            confirmed: true,
                        };

                    return {
                        ...quote,
                        customTags: [
                            ...quote.customTags,
                            {
                                name,
                                description: tagKey,
                            },
                        ],
                        confirmed: true,
                    };
                }),
            };
        }),
    };
};
