import { AUTH_PROVIDERS } from '@/constants/authProviders';
import {
    CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN,
    CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_FAILED,
    CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_SUCCESSFUL,
    CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER,
    CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER_FAILED,
    CHANGE_SIGN_IN_PROCESS_FIX_TYPO,
    CHANGE_SIGN_IN_PROCESS_GET_PROVIDER_INFORMATION,
    CHANGE_SIGN_IN_PROCESS_GOOGLE_CHOSEN,
    CHANGE_SIGN_IN_PROCESS_GOOGLE_SUCCESSFUL,
    CHANGE_SIGN_IN_PROCESS_RESET_TO_CHOOSE_PROVIDER,
    CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL,
    CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_EXIST,
    CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST,
} from '@/redux/actions/changeSignInProcessAction';

export const initialState = {
    type: 'CHOOSE_PROVIDER',
    provider: null,
    error: null,
    loading: false,
};

function changeSignInProcessReducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case CHANGE_SIGN_IN_PROCESS_RESET_TO_CHOOSE_PROVIDER:
            return initialState;

        case CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER_FAILED:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'CHOOSE_PROVIDER_FAILED',
                },
            };
        case CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST:
            const {
                payload: { email },
            } = action;
            return {
                ...state,
                loading: false,
                type: 'ENTER_PASSWORD',
                provider: {
                    name: AUTH_PROVIDERS.VERSATIONAL,
                    email,
                },
            };
        case CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_EXIST:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'EMAIL_DOES_EXIST',
                },
            };
        case CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_SUCCESSFUL:
            return initialState;
        case CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_FAILED:
            return {
                ...state,
                loading: false,
                // eslint-disable-next-line react/destructuring-assignment
                error: action.payload,
            };
        case CHANGE_SIGN_IN_PROCESS_GET_PROVIDER_INFORMATION:
            const { payload } = action;
            return {
                ...state,
                provider: payload,
                loading: true,
                error: null,
            };

        case CHANGE_SIGN_IN_PROCESS_GOOGLE_CHOSEN:
            return {
                ...state,
                provider: {
                    name: AUTH_PROVIDERS.GOOGLE,
                },
                loading: true,
                error: null,
            };
        case CHANGE_SIGN_IN_PROCESS_GOOGLE_SUCCESSFUL:
            const { payload: payloadGoogleSuccessful } = action;
            return {
                ...state,
                provider: {
                    ...state.provider,
                    ...payloadGoogleSuccessful,
                },
                error: null,
            };

        case CHANGE_SIGN_IN_PROCESS_FIX_TYPO:
            return initialState;

        default:
            return state;
    }
}

export default changeSignInProcessReducer;
