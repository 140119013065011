import { useEffect } from 'react';
import dayjs from 'dayjs';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';

export default function useDatePatch(conversationId, conversationDate) {
    useEffect(() => {
        let dateToUse;
        
        if (conversationDate && dayjs(conversationDate).isValid()) {
            dateToUse = dayjs(conversationDate);
        } else {
            let currentDate = dayjs();
            // Round current date to the nearest 15 minutes
            const minutes = currentDate.minute();
            const remainder = 15 - (minutes % 15);
            if (remainder < 8) currentDate = currentDate.add(remainder, 'minute');
            else currentDate = currentDate.subtract((15 - remainder), 'minute');
            dateToUse = currentDate.startOf('minute');
        }

        if (conversationId) {
            axios.patch(NEXT_ROUTES.CONVERSATIONS_ID(conversationId), {
                date: dateToUse.toISOString(),
            });
        }
    }, [conversationDate, conversationId]);
}
