import { FETCH_STATUS } from '@/constants/fetchStatus';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { createSelector } from 'reselect';

const LOW_MINUTES_VALUE = 180;

export const getTeamMembers = (state) => state.teamMembers?.data ?? [];
export const getTeamMembersStatus = (state) => state.teamMembers?.status;

export const getTeamMembersWithYouAsCurrentUser = (state) => {
    const teamMembersData = state.teamMembers?.data;
    const userId = getUserIdSelector(state);

    return (
        teamMembersData?.map((teamMember) => {
            if (teamMember.id === userId) {
                return {
                    ...teamMember,
                    lastName: `${teamMember.lastName} (you)`,
                };
            }
            return teamMember;
        }) ?? teamMembersData
    );
};

export const getTeamMembersMetrics = (state) => state.teamMembers?.metrics;

export const getIsTeamMembersInIdle = (state) =>
    getTeamMembersStatus(state) === FETCH_STATUS.IDLE;

export const getTeamMembersFetchLoading = (state) =>
    getTeamMembersStatus(state) === FETCH_STATUS.INITIAL_LOADING;

export const getTeamMembersInitialLoading = (state) =>
    getTeamMembersStatus(state) === FETCH_STATUS.INITIAL_LOADING;

export const getTeamMembersInitialRefreshLoading = (state) => {
    const status = getTeamMembersStatus(state);

    return (
        status === FETCH_STATUS.INITIAL_LOADING ||
        (status === FETCH_STATUS.REFETCH_LOADING &&
            getTeamMembers(state) === null)
    );
};

export const getTeamMembersRefreshLoading = (state) =>
    getTeamMembersInitialRefreshLoading(state) ||
    getTeamMembersStatus(state) === FETCH_STATUS.UPDATE_LOADING;

export const getTeamMembersUpdateLoading = (state) => {
    const status = getTeamMembersStatus(state);
    return status === FETCH_STATUS.UPDATE_LOADING;
};

export const getTeamMembersInitialOrUpdateLoading = (state) =>
    getTeamMembersInitialLoading(state) || getTeamMembersUpdateLoading(state);

export const getTeamMembersLoadingError = (state) => state.teamMembers?.error;

export const getTeamMembersLoading = (state) =>
    [FETCH_STATUS.UPDATE_LOADING, FETCH_STATUS.INITIAL_LOADING].includes(
        getTeamMembersStatus(state),
    );

export const getActiveTeamMembers = createSelector(
    [getTeamMembers],
    (teamMembers) => teamMembers.filter((teamMember) => !teamMember.deleted_at),
);

export const getActiveTeamMembersByName = createSelector(
    [getActiveTeamMembers],
    (teamMembers) =>
        teamMembers?.map(
            (teamMember) => `${teamMember.firstName} ${teamMember.lastName}`,
        ) ?? [],
);

export const getNumberOfActiveTeamMembers = createSelector(
    [getActiveTeamMembers],
    (activeTeamMembers) => activeTeamMembers.length ?? 0,
);

export const getLowOnMinutesTeamMembers = (state) =>
    state.teamMembers?.data?.reduce((acc, curr) => {
        if (curr.max_minutes - curr.used_minutes < LOW_MINUTES_VALUE)
            return [...acc, curr];
        return acc;
    }, []) ?? [];

export const getHideArchivedTeamMembers = (state) => state?.teamMembers?.hideArchivedTeamMembers ?? true;
