import { put, takeEvery, call } from 'redux-saga/effects';
import { message, notification } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    SETTINGS_TOPIC_TRACKER_FETCH_START,
    SETTINGS_TOPIC_TRACKER_CREATE_START,
    SETTINGS_TOPIC_TRACKER_UPDATE_START,
    SETTINGS_TOPIC_TRACKER_DELETE_START,
    fetchSettingsTopicTrackerSuccess,
    fetchSettingsTopicTrackerFailure,
    settingsTopicTrackerCreateSuccess,
    settingsTopicTrackerCreateFailure,
    settingsTopicTrackerUpdateSuccess,
    settingsTopicTrackerUpdateFailure,
    settingsTopicTrackerDeleteSuccess,
    settingsTopicTrackerDeleteFailure,
} from '@/redux/actions/settings/topicTrackerAction';

export function* getTopicTrackers() {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.SETTINGS_TOPIC_TRACKERS);
        yield put(fetchSettingsTopicTrackerSuccess(data));
    } catch (err) {
        yield put(fetchSettingsTopicTrackerFailure(err));
    }
}

export function* createTopicTracker(action) {
    try {
        const { data, onSuccess } = action.payload;
        const { data: createdTopicTracker } = yield call(
            axios.post,
            NEXT_ROUTES.SETTINGS_TOPIC_TRACKERS,
            data,
        );
        const createdTopicTrackerName = createdTopicTracker.gem
            ? createdTopicTracker.gem.name
            : createdTopicTracker.customTag.name;
        yield call(notification.success, {
            message: 'Success',
            description: `"${createdTopicTrackerName}" is now being tracked. Insights for this topic will show in the unconfirmed section on new recordings.`,
            placement: 'bottomRight',
        });
        yield put(settingsTopicTrackerCreateSuccess(createdTopicTracker));
        yield call(onSuccess);
    } catch (e) {
        message.error('Unable to track this topic');
        yield put(settingsTopicTrackerCreateFailure(e));
    }
}

export function* updateTopicTracker(action) {
    try {
        const { data } = action.payload;
        const { data: updatedTopicTracker } = yield call(
            axios.patch,
            NEXT_ROUTES.SETTINGS_TOPIC_TRACKER_ID(data.id),
            data,
        );
        yield call(notification.success, {
            message: 'Success',
            description: 'Topic tracker has been updated successfully',
            placement: 'bottomRight',
        });
        yield put(settingsTopicTrackerUpdateSuccess(updatedTopicTracker));
    } catch (e) {
        message.error('Unable to update this topic tracker');
        yield put(settingsTopicTrackerUpdateFailure(e));
    }
}

export function* deleteTopicTracker(action) {
    try {
        const { data } = action.payload;
        yield call(
            axios.delete,
            NEXT_ROUTES.SETTINGS_TOPIC_TRACKER_ID(data.id),
        );
        yield call(notification.success, {
            message: 'Success',
            description: 'Topic is no longer being tracked.',
            placement: 'bottomRight',
        });
        yield put(settingsTopicTrackerDeleteSuccess({ id: data.id }));
    } catch (e) {
        message.error('Unable to remove tracking of this topic');
        yield put(settingsTopicTrackerDeleteFailure(e));
    }
}

export default function* settingsTopicTrackerSaga() {
    yield takeEvery(SETTINGS_TOPIC_TRACKER_FETCH_START, getTopicTrackers);
    yield takeEvery(SETTINGS_TOPIC_TRACKER_CREATE_START, createTopicTracker);
    yield takeEvery(SETTINGS_TOPIC_TRACKER_UPDATE_START, updateTopicTracker);
    yield takeEvery(SETTINGS_TOPIC_TRACKER_DELETE_START, deleteTopicTracker);
}
