/* eslint-disable no-unused-vars */
import { Modal, Popconfirm } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import Logo from '@/public/logos/Versational-Logo-R.svg';
import LogoWrapper from '@/components/logoWrapper';

const { confirm } = Modal;

export default function ConfirmDelete(callbackFunc) {
    confirm({
        icon: null,
        content: (
            <div>
                <div style={{ width: '30%', margin: 'auto' }}>
                    <LogoWrapper>
                        <Logo />
                    </LogoWrapper>
                </div>
                <div
                    style={{
                        backgroundColor: '#FFFBE6',
                        padding: '3%',
                        display: 'flex',
                        border: '1px solid #FFE58F',
                    }}
                >
                    <div>
                        <ExclamationCircleOutlined
                            style={{
                                marginRight: '17.5px',
                                color: '#FAAD14',
                                fontSize: '22px',
                            }}
                        />
                    </div>
                    <p>
                        You retain the right to remove all of your recordings
                        automatically imported from Zoom. If you would like to
                        remove only some recordings, easily delete them from the
                        recordings table. If you’d like to permanently delete
                        all of the recordings obtained from Zoom through your
                        integration, click the button below.
                    </p>
                </div>
            </div>
        ),
        okText: (
            <Popconfirm
                placement="top"
                title="Are you sure? This action cannot be undone."
                onConfirm={() =>
                    new Promise(() => {
                        callbackFunc().then(() => {
                            Modal.destroyAll();
                        });
                    })
                }
                okText="Yes"
                cancelText="No"
            >
                Confirm
            </Popconfirm>
        ),
        width: 800,
        onOk(_e) {},
        onCancel() {},
        maskClosable: true,
    });
}
