import { fetchData, fetchFailure, fetchSuccess } from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('TRANSCRIPT');

export const TRANSCRIPT = {
    ...creator.fetch(),
    ...creator.update(),
    GUEST_FETCH_START: creator.rawCustom('GUEST_FETCH_START'),
    GUEST_FETCH_SUCCESS: creator.rawCustom('GUEST_FETCH_SUCCESS'),
    UPDATE_CONTENT_ITEM: creator.custom('UPDATE_CONTENT_ITEM'),
    UPDATE_ATTENDEE: creator.custom('UPDATE_ATTENDEE'),
    UPDATE_SINGLE_ATTENDEE: creator.custom('UPDATE_SINGLE_ATTENDEE'),
    RESET_SPEAKERS: creator.custom('RESET_SPEAKERS'),
    COMMENT_LIKE: creator.custom('COMMENT_LIKE'),
    ADD_COMMENT: creator.custom('ADD_COMMENT'),
    UPDATE_QUOTE: creator.custom('UPDATE_QUOTE'),
    CREATE_QUOTE: creator.custom('CREATE_QUOTE'),
    DELETE_QUOTE: creator.custom('DELETE_QUOTE'),
    DELETE_BATCH_QUOTES: creator.custom('DELETE_BATCH_QUOTES'),
    UPDATE_MEDIA_URL: creator.custom('UPDATE_MEDIA_URL'),
    BULK_CONFIRM: creator.custom('BULK_CONFIRM'),
    PIN_QUOTE: creator.rawCustom('PIN_QUOTE'),
    CONFIRM_QUOTE: creator.rawCustom('CONFIRM_QUOTE'),
    UPDATE_GEM: creator.rawCustom('UPDATE_GEM'),
    UPDATE_TAG: creator.rawCustom('UPDATE_TAG'),
    UPDATE_ASSIGNEE: creator.rawCustom('UPDATE_ASSIGNEE'),
    UPDATE_DUE_DATE: creator.rawCustom('UPDATE_DUE_DATE'),
    UPDATE_IS_DONE: creator.rawCustom('UPDATE_IS_DONE'),
    UPDATE_HIDDEN_SPEAKERS: creator.rawCustom('UPDATE_HIDDEN_SPEAKERS'),
};

export const fetchTranscript = (id) =>
    fetchData(TRANSCRIPT.FETCH_START, { id });
export const fetchGuestTranscript = (id) =>
    fetchData(TRANSCRIPT.GUEST_FETCH_START, { id });
export const fetchTranscriptSuccess = (data) =>
    fetchSuccess(TRANSCRIPT.FETCH_SUCCESS, { ...data });
export const fetchGuestTranscriptSuccess = (data) =>
    fetchSuccess(TRANSCRIPT.GUEST_FETCH_SUCCESS, { ...data });
export const fetchTranscriptFailure = (error) =>
    fetchFailure(TRANSCRIPT.FETCH_FAILURE, error);

export const updateTranscriptContentItem = (item) => ({
    type: TRANSCRIPT.UPDATE_CONTENT_ITEM.START,
    payload: {
        item,
    },
});

export const updateTranscriptContentItemSuccess = (item) => ({
    type: TRANSCRIPT.UPDATE_CONTENT_ITEM.SUCCESS,
    payload: {
        item,
    },
});

export const updateTranscriptContentItemFailure = (error, contentId) => ({
    type: TRANSCRIPT.UPDATE_CONTENT_ITEM.FAILURE,
    payload: {
        error,
        contentId,
    },
});

export const updateTranscriptAttendee = (
    toReplaceSpeakerId,
    userId,
    name,
    attendeeType,
) => ({
    type: TRANSCRIPT.UPDATE_ATTENDEE.START,
    payload: {
        attendee: {
            toReplaceSpeakerId,
            userId,
            name,
            attendeeType,
        },
    },
});

export const updateTranscriptAttendeeSuccess = (transcript) => ({
    type: TRANSCRIPT.UPDATE_ATTENDEE.SUCCESS,
    payload: {
        transcript,
    },
});

export const updateTranscriptAttendeeFailure = (error) => ({
    type: TRANSCRIPT.UPDATE_ATTENDEE.FAILURE,
    payload: {
        error,
    },
});

export const updateTranscriptSingleAttendee = (
    speakerId,
    userId,
    contentId,
) => ({
    type: TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.START,
    payload: {
        attendee: {
            speakerId,
            userId,
            contentId,
        },
    },
});

export const updateTranscriptSingleAttendeeSuccess = (transcript) => ({
    type: TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.SUCCESS,
    payload: {
        transcript,
    },
});

export const updateTranscriptSingleAttendeeFailure = (error) => ({
    type: TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.FAILURE,
    payload: {
        error,
    },
});

export const resetTranscriptSpeakers = () => ({
    type: TRANSCRIPT.RESET_SPEAKERS.START,
});

export const resetTranscriptSpeakersSuccess = (transcript) => ({
    type: TRANSCRIPT.RESET_SPEAKERS.SUCCESS,
    payload: {
        transcript,
    },
});

export const resetTranscriptSpeakersFailure = () => ({
    type: TRANSCRIPT.RESET_SPEAKERS.FAILURE,
});

export const updateTranscript = (updater) => ({
    type: TRANSCRIPT.UPDATE_START,
    payload: {
        updater,
    },
});

export const updateTranscriptSuccess = (transcript) => ({
    type: TRANSCRIPT.UPDATE_SUCCESS,
    payload: {
        transcript,
    },
});

export const updateTranscriptFailure = (error) => ({
    type: TRANSCRIPT.UPDATE_FAILURE,
    payload: {
        error,
    },
});

export const commentLike = (id, userId) => ({
    type: TRANSCRIPT.COMMENT_LIKE.START,
    payload: { id, userId },
});

export const addComment = (id, comment) => ({
    type: TRANSCRIPT.ADD_COMMENT.START,
    payload: { id, comment },
});

export const addCommentFailure = (error, id) => ({
    type: TRANSCRIPT.ADD_COMMENT.FAILURE,
    payload: {
        error,
        id,
    },
});

export const addCommentSuccess = (id) => ({
    type: TRANSCRIPT.ADD_COMMENT.SUCCESS,
    payload: {
        id,
    },
});

export const updateQuote = ({ id, gem, customTag, content, confirm, pin }) => ({
    type: TRANSCRIPT.UPDATE_QUOTE.START,
    payload: {
        id,
        gem,
        customTag,
        content,
        confirm,
        pin,
    },
});

export const updateQuoteFailure = (error, id) => ({
    type: TRANSCRIPT.UPDATE_QUOTE.FAILURE,
    payload: {
        error,
        id,
    },
});

export const updateQuoteSuccess = (id) => ({
    type: TRANSCRIPT.UPDATE_QUOTE.SUCCESS,
    payload: {
        id,
    },
});

export const updateMediaUrl = () => ({
    type: TRANSCRIPT.UPDATE_MEDIA_URL.START,
});

export const updateMediaUrlSuccess = (mediaUrl) => ({
    type: TRANSCRIPT.UPDATE_MEDIA_URL.SUCCESS,
    payload: {
        mediaUrl,
    },
});

export const updateMediaUrlFailure = (error) => ({
    type: TRANSCRIPT.UPDATE_MEDIA_URL.FAILURE,
    payload: {
        error,
    },
});

export const createQuote = (contentId, startsAt, endsAt, onSuccess) => ({
    type: TRANSCRIPT.CREATE_QUOTE.START,
    payload: {
        contentId,
        startsAt,
        endsAt,
        onSuccess,
    },
});

export const createQuoteFailure = (error, contentId) => ({
    type: TRANSCRIPT.CREATE_QUOTE.FAILURE,
    payload: {
        error,
        contentId,
    },
});
export const bulkConfirmQuotes = (quotes, onSuccess) => ({
    type: TRANSCRIPT.BULK_CONFIRM.START,
    payload: {
        quotes,
        onSuccess,
    },
});

export const bulkConfirmQuotesFailure = (error, quotes) => ({
    type: TRANSCRIPT.BULK_CONFIRM.FAILURE,
    payload: {
        error,
        quotes,
    },
});

export const bulkConfirmQuotesSuccess = (quotes) => ({
    type: TRANSCRIPT.BULK_CONFIRM.SUCCESS,
    payload: {
        quotes,
    },
});

export const deleteBatchQuotes = (quotes, onSuccess) => ({
    type: TRANSCRIPT.DELETE_BATCH_QUOTES.START,
    payload: {
        quotes,
        onSuccess,
    },
});

export const deleteBatchQuotesFailure = (error, quotes) => ({
    type: TRANSCRIPT.DELETE_BATCH_QUOTES.FAILURE,
    payload: {
        error,
        quotes,
    },
});
export const deleteQuote = (id, contentId) => ({
    type: TRANSCRIPT.DELETE_QUOTE.START,
    payload: {
        id,
        contentId,
    },
});

export const deleteQuoteFailure = (error, contentId, id) => ({
    type: TRANSCRIPT.DELETE_QUOTE.FAILURE,
    payload: {
        error,
        contentId,
        id,
    },
});

export const deleteQuoteSuccess = (id) => ({
    type: TRANSCRIPT.DELETE_QUOTE.SUCCESS,
    payload: {
        id,
    },
});

export const pinQuoteAction = (contentIndex, quoteIndex, status) => ({
    type: TRANSCRIPT.PIN_QUOTE,
    payload: {
        contentIndex,
        quoteIndex,
        status,
    },
});

export const confirmQuoteAction = (contentIndex, quoteIndex, status) => ({
    type: TRANSCRIPT.CONFIRM_QUOTE,
    payload: {
        contentIndex,
        quoteIndex,
        status,
    },
});

export const updateGemAction = (contentIndex, quoteIndex, gemKey, name) => ({
    type: TRANSCRIPT.UPDATE_GEM,
    payload: {
        contentIndex,
        quoteIndex,
        gemKey,
        name,
    },
});

export const updateTagAction = (contentIndex, quoteIndex, tagKey, name) => ({
    type: TRANSCRIPT.UPDATE_TAG,
    payload: {
        contentIndex,
        quoteIndex,
        tagKey,
        name,
    },
});

export const updateAssignee = (contentIndex, quoteIndex, assigneeId) => ({
    type: TRANSCRIPT.UPDATE_ASSIGNEE,
    payload: {
        contentIndex,
        quoteIndex,
        assigneeId,
    },
});

export const updateDueDate = (contentIndex, quoteIndex, dueDate) => ({
    type: TRANSCRIPT.UPDATE_DUE_DATE,
    payload: {
        contentIndex,
        quoteIndex,
        dueDate,
    },
});

export const updateIsDone = (contentIndex, quoteIndex, isDone) => ({
    type: TRANSCRIPT.UPDATE_IS_DONE,
    payload: {
        contentIndex,
        quoteIndex,
        isDone,
    },
});

export const updateHiddenSpeakers = (hiddenSpeakers) => ({
    type: TRANSCRIPT.UPDATE_HIDDEN_SPEAKERS,
    payload: {
        hiddenSpeakers,
    },
});
