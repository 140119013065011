// Could be change to fetching data from list of meetings
import { put, take } from 'redux-saga/effects';
import { fetchMeetingId, MEETING } from '@/redux/actions/conversation/meeting';

export function* getMeetingId(id) {
    yield put(fetchMeetingId(id));
    const meetingFetchAction = yield take([
        MEETING.FETCH_SUCCESS,
        MEETING.FETCH_FAILURE,
    ]);

    if (meetingFetchAction.type === MEETING.FETCH_FAILURE)
        throw new Error(
            'Conversation results fetch failed during meeting fetching',
        );

    return meetingFetchAction.payload.data;
}
