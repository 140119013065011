import {
    call,
    delay,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import axios from '@/utils/api/axios';

import {
    CALENDAR_EVENTS_FETCH_FAILURE,
    CALENDAR_EVENTS_FETCH_START,
    CALENDAR_EVENTS_FETCH_SUCCESS,
    CALENDAR_EVENTS_ID_FETCH_FAILURE,
    CALENDAR_EVENTS_ID_FETCH_START,
    CALENDAR_EVENTS_ID_FETCH_SUCCESS,
} from '@/redux/actions/_exports';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    getCalendarProviderSelector,
    getCalendarProviderStatusSelector,
    getIdSelector,
} from '@/redux/selectors/calendarSelector';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { message } from 'antd';
import { utcToIsoDayjs } from '@/utils/dates/dayj';
import { FETCH_STATUS } from '@/constants/fetchStatus';

const parseOutlookEventToIsoDates = (outlookEvent) => ({
    ...outlookEvent,
    start: {
        dateTime: utcToIsoDayjs(outlookEvent.start.dateTime).toISOString(),
    },
    end: { dateTime: utcToIsoDayjs(outlookEvent.end.dateTime).toISOString() },
});

function* getEvents(startISO, endISO) {
    let guard = true;
    while (guard) {
        const status = yield select(getCalendarProviderStatusSelector);
        if (
            [
                FETCH_STATUS.INITIAL_LOADED,
                FETCH_STATUS.INITIAL_ERROR,
                FETCH_STATUS.UPDATE_SUCCESS,
            ].includes(status)
        ) {
            guard = false;
        }
        yield delay(100);
    }
    const provider = yield select(getCalendarProviderSelector);

    if (provider === 'google') {
        try {
            return yield call(
                axios,
                NEXT_ROUTES.GOOGLE_EVENTS(startISO, endISO),
            );
        } catch (e) {
            message.error('Fetching google calendar events failed.');
        }
    }

    if (provider === 'outlook') {
        try {
            const { data } = yield call(
                axios,
                NEXT_ROUTES.OUTLOOK_EVENTS(startISO, endISO),
            );
            return { data: data.map(parseOutlookEventToIsoDates) };
        } catch (e) {
            message.error('Fetching outlook calendar events failed.');
        }
    }

    return { data: [] };
}

function* getConversationEvents(startISO, endISO) {
    return yield call(
        axios,
        `${NEXT_ROUTES.CONVERSATION_WITH_MEETING}?start=${startISO}&end=${endISO}`,
    );
}

const parseConversationEventToFormat = (conversationEvent) => ({
    subject: conversationEvent.title,
    attendees: [
        ...conversationEvent.meeting.users.map((user) => ({
            ...user,
            emailAddress: {
                address: user.email,
                name: `${user.firstName} ${user.lastName}`,
            },
        })),
        ...conversationEvent.meeting.contacts.map((contact) => ({
            ...contact,
            firstName: contact.first_name,
            lastName: contact.last_name,
            emailAddress: {
                address: contact?.email ?? '',
                name: `${contact.first_name} ${contact.last_name}`,
            },
        })),
        ...conversationEvent.meeting.attendees.map((attendee) => ({
            ...attendee,
            firstName: attendee.attendee_name.split(' ')[0],
            lastName: attendee.attendee_name.split(' ')[1],
            emailAddress: {
                name: attendee.attendee_name,
            },
        })),
        ...conversationEvent.meeting.guests.map((guest) => ({
            ...guest,
            emailAddress: { name: guest.name },
        })),
    ],
    // for conversation meetings it's random uuid, for outlook meetings it is event id (outlook event identifier)
    id: conversationEvent.meeting.external_id,
    start: { dateTime: conversationEvent.date },
    end: { dateTime: conversationEvent.date },
    meetingLink: conversationEvent.meeting.url,
    fromConversation: true,
    conversationId: conversationEvent.id,
});

const apiGetConversationMeeting = async (event) =>
    axios.get(NEXT_ROUTES.CONVERSATIONS_MEETING_ID(event.id));

const apiSyncMeetingWithDb = async (event) =>
    axios.post(NEXT_ROUTES.CONVERSATIONS, {
        meeting_id: event.id,
        title: event.subject,
    });

// TODO Provide better way of determination if given meeting has it's db reference
export function* synchronizeConversationAndEvents(events) {
    yield Promise.all(
        events.map(async (event) => {
            let isSynced;
            try {
                const { data } = await apiGetConversationMeeting(event);
                if (data) {
                    isSynced = true;
                } else {
                    // noinspection ExceptionCaughtLocallyJS
                    throw new Error('Not synchronized');
                }
            } catch (e) {
                isSynced = false;
            }
            if (!isSynced) await apiSyncMeetingWithDb(event);
        }),
    );
}

export function* getCalendarEvents(data) {
    try {
        const { dateStart, dateEnd } = data.payload;
        const userId = yield select(getUserIdSelector);
        const provider = yield select(getCalendarProviderSelector);

        let { data: events } = yield getEvents(dateStart, dateEnd);

        if (provider === 'outlook') {
            events = events.map((event) => ({
                ...event,
                meetingLink: event?.location?.displayName,
            }));
        }

        const {
            data: { conversations: conversationsData },
        } = yield getConversationEvents(dateStart, dateEnd);

        yield synchronizeConversationAndEvents(events);

        // Requirement treats outlook events as masters - it means data fetch from outlook should override it's references in our db
        // Comparement in filter function is done because outlook events always has uppercase id - this may be changed in the future so we should not rely on it
        // TODO Provide filtering by flag in db as described above

        const containsAttendee = (meeting) => {
            if (meeting.contacts.find(({ id }) => id === userId)) return true;
            if (meeting.guests.find(({ id }) => id === userId)) return true;
            if (meeting.attendees.find(({ id }) => id === userId)) return true;
            return !!meeting.users.find(({ id }) => id === userId);
        };

        const conversationEventsWithoutCalendarEvents =
            conversationsData.filter((conversation) => {
                const { meeting } = conversation;

                const isCalendarMeeting =
                    meeting?.external_id === meeting?.external_id.toLowerCase();
                const isCurrentUserAttendee = containsAttendee(meeting);

                return isCalendarMeeting || isCurrentUserAttendee;
            });
        
        const eventsFromConversations =
            conversationEventsWithoutCalendarEvents.map(
                parseConversationEventToFormat,
            );

        const convoIds = eventsFromConversations.map(c => c.id)
        const currentEvents = eventsFromConversations;
        for (let i = 0; i < events.length; i += 1) {
            const event = events[i];
            if (convoIds.indexOf(event.id) === -1) {
                currentEvents.push(event)
            }
        }

        for (let i = 0; i < currentEvents.length; i += 1) {
            const event = currentEvents[i];
            if (event.attendees.length === 0) {
                const matchingEvent = events.find((e) => e.id === event.id);
                if (matchingEvent) {
                    currentEvents[i].attendees = matchingEvent.attendees;
                }
            }
        }

        yield put({
            type: CALENDAR_EVENTS_FETCH_SUCCESS,
            payload: {
                events: currentEvents,
            },
        });
    } catch (error) {
        yield put({ type: CALENDAR_EVENTS_FETCH_FAILURE });
    }
}

export function* getCalendarEventsId() {
    try {
        const id = yield select((state) => getIdSelector(state));
        const provider = yield select(getCalendarProviderSelector);

        let returnData;
        if (provider === 'google') {
            returnData = yield call(axios, NEXT_ROUTES.GOOGLE_EVENTS_ID(id));
        }

        if (provider === 'outlook') {
            returnData = yield call(axios, NEXT_ROUTES.OUTLOOK_EVENTS_ID(id));
        }

        yield put({
            type: CALENDAR_EVENTS_ID_FETCH_SUCCESS,
            payload: { event: returnData.data },
        });
    } catch (error) {
        yield put({ type: CALENDAR_EVENTS_ID_FETCH_FAILURE });
    }
}

export default function* calendarsEventsSaga() {
    yield takeEvery(CALENDAR_EVENTS_ID_FETCH_START, getCalendarEventsId);
    yield takeLatest(CALENDAR_EVENTS_FETCH_START, getCalendarEvents);
}
