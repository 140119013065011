// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
    dsn: SENTRY_DSN || 'https://83ca3e44772b4848813c07b7f1173723@o1081483.ingest.sentry.io/6088890',
    tracesSampleRate: process.env.NEXT_PUBLIC_SENTRY_ENV === 'production' ? 0.05 : 0.0,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
    sampleRate: 0.1,
});
