import styled from 'styled-components';
import {
    CloseOutlined,
} from '@ant-design/icons';
import { VERSATIONAL } from '@/constants/colors';

const MicRecorderPanel = styled.div`
    background-color: ${VERSATIONAL};
    padding: 40px 20px 20px 20px;
    position: fixed;
    bottom: -100%;
    left: 50%;
    transform: translateX(-50%);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.25);
    border-radius: 5px 5px 0 0;
    transition: all .3s;
    z-index: 2;
    
    span {
        color: #fff;
    }

    path {
        fill: #fff;
    }

    input {
        color: #fff;
    }

    .ant-picker input {
        color: #eee;
    }

    @media (max-width: 768px) {
        padding-bottom: 55px;
    }

    div[data-testid="start-record-icon"] {
        background: #fff;
    }

    div[data-testid="analyze-button"] {
        border-color: #E0E0E0;

        span {
            color: #fff;
        }
    }
`;

const MicRecordingClose = styled(CloseOutlined)`
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 20px;
    color: #fff;
    cursor: pointer;
`;

export { MicRecorderPanel, MicRecordingClose };
