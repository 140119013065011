import {
    SET_COMMENTS_QUERY,
    SET_SELECTED_GEM_TOPIC,
    SET_SELECTED_QUOTE_COMMENT_INDEX,
    SET_SELECTED_TOPIC,
} from '@/redux/actions/conversation/commentsViewAction';
import { COMMENTS_FILTER_DATA } from '@/components/conversations/results/analytics/summaries/comments/constants/commentsFilter';

const {
    sort: { options: sortOptions },
} = COMMENTS_FILTER_DATA;

export const initialState = {
    selectedQuoteCommentId: null,
    selectedQuoteCommentIndex: 0,
    commentsQuery: {
        sortBy: sortOptions[0].value,
    },
    selectedGemTopic: null,
    selectedTopic: null,
};

function commentsViewReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SELECTED_QUOTE_COMMENT_INDEX: {
            return {
                ...state,
                selectedQuoteCommentIndex: action.payload.data,
            };
        }
        case SET_COMMENTS_QUERY: {
            return {
                ...state,
                commentsQuery: {
                    ...state.commentsQuery,
                    ...action.payload.data,
                },
                selectedQuoteCommentIndex: 0,
            };
        }
        case SET_SELECTED_GEM_TOPIC: {
            if (action.payload.data === state.selectedGemTopic) {
                return {
                    ...state,
                    selectedGemTopic: null,
                    selectedQuoteCommentIndex: 0,
                };
            }
            return {
                ...state,
                selectedGemTopic: action.payload.data,
                selectedQuoteCommentIndex: 0,
            };
        }
        case SET_SELECTED_TOPIC: {
            if (action.payload.data === state.selectedTopic) {
                return {
                    ...state,
                    selectedTopic: null,
                    selectedQuoteCommentIndex: 0,
                };
            }
            return {
                ...state,
                selectedTopic: action.payload.data,
                selectedQuoteCommentIndex: 0,
            };
        }

        default:
            return state;
    }
}

export default commentsViewReducer;
