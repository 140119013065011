import { all, call, put, takeLatest } from 'redux-saga/effects';
import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';
import { errorToString } from '@/utils/error/errorToString';
import {
    commentOnQuestionFailure,
    commentOnQuestionSuccess,
    CONVERSATION_QUESTIONS,
    fetchConversationQuestionsFailure,
    fetchConversationQuestionsSuccess,
    likeQuestionFailure,
    likeQuestionSuccess,
    pinQuestionFailure,
    pinQuestionSuccess,
    removeQuestionFailure,
    removeQuestionSuccess,
} from '@/redux/actions/conversation/questionsAction';
import { notification } from 'antd';

function* fetchQuestionsByConversationId(id) {
    return yield call(axios, NEXT_ROUTES.CONVERSATIONS_ID_QUESTIONS(id));
}
function* fetchQuestionsGeneralInformationByConversationId(id) {
    return yield call(
        axios,
        NEXT_ROUTES.CONVERSATIONS_ID_QUESTIONS_GENERAL(id),
    );
}

export function* getQuestions(action) {
    try {
        const { id } = action.payload.data;
        const [{ data: questions }, { data: generalInformation }] = yield all([
            fetchQuestionsByConversationId(id),
            fetchQuestionsGeneralInformationByConversationId(id),
        ]);

        yield put(
            fetchConversationQuestionsSuccess({
                questions,
                generalInformation,
            }),
        );
    } catch (err) {
        yield put(fetchConversationQuestionsFailure(errorToString(err)));
    }
}

function* updateQuestion(id, data) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.CONVERSATIONS_QUESTIONS_ID(id),
        data,
    );
}

function* removeQuestion(id) {
    return yield call(axios.delete, NEXT_ROUTES.CONVERSATIONS_QUESTIONS_ID(id));
}

function* commentOnQuestion(id, payload) {
    return yield call(
        axios.post,
        NEXT_ROUTES.CONVERSATIONS_QUESTIONS_ID_COMMENTS(id),
        payload,
    );
}

export function* likeQuestionSaga(action) {
    const { id, liked } = action.payload.data;
    try {
        const { data } = yield updateQuestion(id, { liked });
        yield put(likeQuestionSuccess(data));
    } catch (err) {
        notification.error({
            message: 'Error',
            description:
                'There was error during liking the question. Please try again later.',
            placement: 'bottomRight',
        });
        yield put(likeQuestionFailure(id, errorToString(err)));
    }
}
export function* pinQuestionSaga(action) {
    const { id, pinned } = action.payload.data;
    try {
        const { data } = yield updateQuestion(id, { pinned });
        yield put(pinQuestionSuccess(data));
    } catch (err) {
        notification.error({
            message: 'Error',
            description:
                'There was error during pinning the question. Please try again later.',
            placement: 'bottomRight',
        });
        yield put(pinQuestionFailure(id, errorToString(err)));
    }
}
export function* removeQuestionSaga(action) {
    const { id } = action.payload.data;
    try {
        yield removeQuestion(id);
        yield put(removeQuestionSuccess({ id }));
    } catch (err) {
        notification.error({
            message: 'Error',
            description:
                'There was error during removing the question. Please try again later.',
            placement: 'bottomRight',
        });
        yield put(removeQuestionFailure(id, errorToString(err)));
    }
}
export function* commentOnQuestionSaga(action) {
    const { id, value } = action.payload.data;
    try {
        const { data } = yield commentOnQuestion(id, { value });
        yield put(commentOnQuestionSuccess(data));
    } catch (err) {
        notification.error({
            message: 'Error',
            description:
                'There was error during commenting on the question. Please try again later.',
            placement: 'bottomRight',
        });
        yield put(commentOnQuestionFailure(id, errorToString(err)));
    }
}

export default function* conversationQuestionsSaga() {
    yield takeLatest(CONVERSATION_QUESTIONS.FETCH_START, getQuestions);
    yield takeLatest(
        CONVERSATION_QUESTIONS.LIKE_QUESTION.START,
        likeQuestionSaga,
    );
    yield takeLatest(
        CONVERSATION_QUESTIONS.PIN_QUESTION.START,
        pinQuestionSaga,
    );
    yield takeLatest(
        CONVERSATION_QUESTIONS.REMOVE_QUESTION.START,
        removeQuestionSaga,
    );
    yield takeLatest(
        CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.START,
        commentOnQuestionSaga,
    );
}
