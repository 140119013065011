export const getLongestMonologueTime = (content = [], speakerIds) => {
    const monologuesTimes = content
        .reduce((acc, curr) => {
            const tempAcc = [...acc];

            const seconds = (curr.endTimeMs - curr.startTimeMs) / 1000;

            const lastIndex = tempAcc.length - 1;
            const lastItem = tempAcc[lastIndex];

            if (lastItem?.speakerId === curr.speakerId) {
                tempAcc[lastIndex] = {
                    speakerId: curr.speakerId,
                    seconds: lastItem.seconds + seconds,
                };
                return tempAcc;
            }

            return [...tempAcc, { speakerId: curr.speakerId, seconds }];
        }, [])
        .filter(({ speakerId }) => speakerIds.includes(speakerId))
        .map(({ seconds }) => Math.round(seconds));

    return Math.max(...monologuesTimes, 0);
};
