import { node } from 'prop-types';
import { useRouter } from 'next/router';
import { useState } from 'react';
import usePageTitle from '@/components/conversations/conversations/hooks/usePageTitle';
import useEventId from '@/components/conversations/conversations/hooks/useEventId';
import useDatePatch from '@/components/conversations/conversations/hooks/useDatePatch';
import useMeetingData from '@/components/conversations/conversations/hooks/useMeetingData';
import useMeetingInformation from '@/components/conversations/conversations/hooks/useMeetingInformation';
import useCompleteMemo from '@/utils/react/useCompleteMemo';
import {
    AttendeesContext,
    ConversationsContext,
    MeetingContext,
    TranscriptionStatusContext,
} from '@/components/conversations/conversations/context/context';
import useUploadPoll from '@/components/insights/recordings/upload/useUploadPoll';
import usePollStatus from '@/components/insights/recordings/upload/usePollStatus';
import { isError } from '@/components/insights/recordings/upload/utils';

export function ConversationContextProvider({ children }) {
    const { id: eventId, date: queryDate } = useRouter().query;

    console.log(eventId, queryDate)
    // Used for outlook events
    const { loading, event: inputEvent } = useEventId(eventId);
    // Somehow event could be null
    const event = inputEvent?.length === 0 ? null : inputEvent;

    const [conversationTitle, setConversationTitle] = useState(
        event?.subject ?? 'New conversation',
    );

    const isOutlook = eventId && eventId.toLowerCase() !== eventId;

    const [conversationId, conversationData, resetConversationDataError] =
        useMeetingData(eventId, conversationTitle, isOutlook);

    const {
        meetingId,
        meetingLink,
        meetingExternalId,
        setMeetingLink,
        conversationDate,
        setConversationDate,
        contacts,
        setContacts,
        attendees,
        setAttendees,
        selectedTeamMembers,
        setSelectedTeamMembers,
        guests,
        setGuests,
        storeConversationTitle,
    } = useMeetingInformation(
        conversationId,
        conversationData,
        setConversationTitle,
        conversationTitle,
        event,
        queryDate,
    );

    const pageTitle = usePageTitle(
        loading,
        conversationTitle,
        conversationDate,
    );

    const {
        startPolling,
        stopPolling,
        status,
        percentage,
        conversation: polledConversation,
        resetPollingState,
    } = useUploadPoll();

    const mergedPollStatus = usePollStatus(conversationData, status);

    const finalStatus = conversationData ? mergedPollStatus : null;
    const error = isError(status, conversationData?.transcription_status);

    const mediaUrl =
        conversationData?.media_url ?? polledConversation?.media_url;

    useDatePatch(conversationId, conversationDate);

    const attendeesContextValue = useCompleteMemo({
        attendees,
        setAttendees,
        contacts,
        setContacts,
        selectedTeamMembers,
        setSelectedTeamMembers,
        guests,
        setGuests,
    });

    const transcriptionContextValue = useCompleteMemo({
        startPolling,
        stopPolling,
        originalPollStatus: status,
        status: finalStatus,
        polledConversation,
        isError: error,
        percentage,
        mediaUrl,
    });

    const meetingContextValue = useCompleteMemo({
        meetingId,
        setMeetingLink,
        meetingLink,
        meetingExternalId: meetingExternalId ?? (isOutlook && eventId),
    });

    const conversationContextValue = useCompleteMemo({
        conversationId,
        conversationData,
        title: conversationTitle,
        setConversationTitle,
        pageTitle,
        updateConversationDate: setConversationDate,
        conversationDate,
        storeConversationTitle,
        resetConversationDataError,
        resetPollingState,
    });

    return (
        <ConversationsContext.Provider value={conversationContextValue}>
            <MeetingContext.Provider value={meetingContextValue}>
                <AttendeesContext.Provider value={attendeesContextValue}>
                    <TranscriptionStatusContext.Provider
                        value={transcriptionContextValue}
                    >
                        {children}
                    </TranscriptionStatusContext.Provider>
                </AttendeesContext.Provider>
            </MeetingContext.Provider>
        </ConversationsContext.Provider>
    );
}

ConversationContextProvider.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: node.isRequired,
};
