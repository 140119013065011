import { Fragment } from 'react';
import Link from 'next/link';
import { arrayOf, string, shape } from 'prop-types';
import { Space, Typography, Popover } from 'antd';
import {
    HoverWrapper,
    StyledA,
    StyledB,
} from '@/components/avatars/emails/emails.style';

const { Paragraph } = Typography;

function Emails({ users }) {
    const getName = (attendee) =>
        attendee.firstName && attendee.lastName
            ? `${attendee.firstName} ${attendee.lastName}`
            : attendee.name;
    const hover = (
        <HoverWrapper>
            {users.map((attendee) => (
                <p key={attendee.email + attendee.name}>
                    {getName(attendee)}
                    {attendee.email && (
                        <Fragment>
                            -{' '}
                            <a href={`mailto:${attendee.email}`}>
                                {attendee.email}
                            </a>
                        </Fragment>
                    )}
                </p>
            ))}
        </HoverWrapper>
    );

    return (
        <Space>
            <Paragraph>
                <Popover content={hover}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Link href={`mailto:${users[0].email}`}>
                            <StyledA>{users[0].email}</StyledA>
                        </Link>{' '}
                        <StyledB>
                            {(() => {
                                const usersWithMail = users.filter(
                                    ({ email }) => email,
                                );
                                if (usersWithMail.length > 1)
                                    return `+${users.length - 1}`;
                                return null;
                            })()}
                        </StyledB>
                    </div>
                </Popover>
            </Paragraph>
        </Space>
    );
}

Emails.propTypes = {
    users: arrayOf(
        shape({
            src: string,
            email: string,
            firstName: string,
            lastName: string,
        }),
    ).isRequired,
};

export default Emails;
