import SelectableItem from '@/components/conversations/attendeesChooser/selectableItem/selectableItem';
import { arrayOf, func, shape } from 'prop-types';

function Contacts({ contacts, onSelect }) {
    return (
        <>
            {contacts.map(
                ({
                    first_name: firstName,
                    last_name: lastName,
                    email,
                    title,
                    id: userId,
                }) => (
                    <SelectableItem
                        name={`${firstName} ${lastName}`}
                        userId={userId}
                        key={userId}
                        email={email}
                        title={title}
                        isClickable
                        onSelect={(name) => {
                            onSelect(userId, name);
                        }}
                    />
                ),
            )}
        </>
    );
}

Contacts.propTypes = {
    contacts: arrayOf(shape({})).isRequired,
    onSelect: func.isRequired,
};

export default Contacts;
