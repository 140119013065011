import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchUserStart } from '@/redux/actions/authAction';

export default function AuthInjector() {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchUserStart());
    }, [dispatch]);

    return null;
}
