import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';
import {
    CONVERSATION,
    deleteConversationSharingForUserFailure,
    deleteConversationSharingForUserSuccess,
    fetchConversationFailure,
    fetchConversationSuccess,
    updateConversationIsDownloadableFailure,
    updateConversationIsDownloadableSuccess,
    updateConversationPasswordFailure,
    updateConversationPasswordSuccess,
    updateConversationTitleFailure,
    updateConversationTitleSuccess,
    updateSharedToFailure,
    updateSharedToSuccess,
    generateSummarizationSuccess,
    generateSummarizationFailure,
} from '@/redux/actions/conversation/conversation';
import { updateRecordingSharedTo, updateRecordingRemoveSharedTo } from '@/redux/actions/recordings/recordingAction';
import {
    getConversationIdSelector,
    getConversationTitleSelector,
} from '@/redux/selectors/conversation/conversationSelector';
import { errorToString } from '@/utils/error/errorToString';
import { GENERIC_FETCH_STATE } from '@/constants/conversations/genericState';

function* fetchConversationById(id) {
    return yield call(axios, NEXT_ROUTES.CONVERSATION_ID_VIEW(id));
}

function* fetchGuestConversationById(id) {
    return yield call(axios, NEXT_ROUTES.GUEST_CONVERSATION_ID(id));
}

export function* getConversation(action) {
    try {
        const { id } = action.payload.data;
        const { data } = yield fetchConversationById(id);

        yield put(fetchConversationSuccess(data));
    } catch (error) {
        const resultError =
            error?.response?.status === 403
                ? GENERIC_FETCH_STATE.ERROR.FORBIDDEN
                : error;
        yield put(fetchConversationFailure(resultError));
    }
}

export function* getGuestConversation(action) {
    try {
        const { id } = action.payload.data;
        const { data } = yield fetchGuestConversationById(id);

        yield put(fetchConversationSuccess(data));
    } catch (error) {
        const resultError =
            error?.response?.status === 403
                ? GENERIC_FETCH_STATE.ERROR.FORBIDDEN
                : error;
        yield put(fetchConversationFailure(resultError));
    }
}

function* updateConversationTitle(conversationId, title) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.CONVERSATIONS_ID(conversationId),
        { title },
    );
}

export function* getUpdateConversationTitle(action) {
    try {
        const { title, noUpdate } = action.payload;
        let newTitle = title;
        const conversationId = yield select(getConversationIdSelector);
        if (!noUpdate) {
            yield updateConversationTitle(conversationId, title);
        }
        if (!title) {
            newTitle = yield select(getConversationTitleSelector);
        }
        yield put(updateConversationTitleSuccess(newTitle));
    } catch (err) {
        yield put(updateConversationTitleFailure(errorToString(err)));
    }
}

function* updatePassword(conversationId, password) {
    return yield call(
        axios.post,
        NEXT_ROUTES.CONVERSATIONS_ID_PASS(conversationId),
        { password },
    );
}

function* generateSummarization(conversationId) {
    return yield call(
        axios.post,
        NEXT_ROUTES.CONVERSATIONS_ID_SUMMARIZATION(conversationId),
    );
}

export function* getUpdatePassword(action) {
    const { password, onSuccess, onFailure, onFinally } = action.payload;

    try {
        const conversationId = yield select(getConversationIdSelector);
        yield updatePassword(conversationId, password);
        yield put(updateConversationPasswordSuccess(password));
        if (typeof onSuccess === 'function') {
            onSuccess();
        }
    } catch (err) {
        if (typeof onFailure === 'function') {
            onFailure();
        }
        yield put(updateConversationPasswordFailure(err));
    } finally {
        if (typeof onFinally === 'function') {
            onFinally();
        }
    }
}

export function* getGenerateSummarization(action) {
    const { onSuccess, onFailure, onFinally } = action.payload;

    try {
        const conversationId = yield select(getConversationIdSelector);
        const summarization = yield generateSummarization(conversationId);
        yield put(generateSummarizationSuccess(summarization.data));
        if (typeof onSuccess === 'function') {
            onSuccess();
        }
    } catch (err) {
        if (typeof onFailure === 'function') {
            onFailure();
        }
        yield put(generateSummarizationFailure(err));
    } finally {
        if (typeof onFinally === 'function') {
            onFinally();
        }
    }
}

function* updateConversationIsDownloadable(conversationId, isDownloadable) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.CONVERSATIONS_ID(conversationId),
        { is_downloadable: isDownloadable },
    );
}

export function* getUpdateIsDownloadable(action) {
    const { isDownloadable, onSuccess, onFailure, onFinally } = action.payload;

    try {
        const conversationId = yield select(getConversationIdSelector);
        yield updateConversationIsDownloadable(conversationId, isDownloadable);
        yield put(updateConversationIsDownloadableSuccess(isDownloadable));
        if (typeof onSuccess === 'function') {
            onSuccess();
        }
    } catch (err) {
        if (typeof onFailure === 'function') {
            onFailure();
        }
        yield put(updateConversationIsDownloadableFailure(err));
    } finally {
        if (typeof onFinally === 'function') {
            onFinally();
        }
    }
}

function* updateSharedTo(conversationId, userIds, message) {
    let conversationIds = conversationId;
    let bulkShare = false;
    
    if (Array.isArray(conversationId)) {
        conversationIds = conversationId.toString();
        bulkShare = true;
    }

    return yield call(
        axios.post,
        NEXT_ROUTES.CONVERSATIONS_ID_SHARE(conversationIds),
        {
            ids: userIds,
            message,
            bulkShare,
        },
    );
}

export function* getUpdateSharedTo(action) {
    const { conversationId, userIds, message, onSuccess, onFailure, teamMembers } =
        action.payload;

    try {
        yield updateSharedTo(conversationId, userIds, message);
        yield put(updateSharedToSuccess(userIds));
        onSuccess();
        yield put(updateRecordingSharedTo(conversationId, teamMembers));
    } catch (e) {
        onFailure();
        yield put(
            updateSharedToFailure(
                errorToString(e, 'Sharing conversation failed'),
            ),
        );
    }
}

function* unShareConversationFromUser(conversationId, id) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.CONVERSATIONS_ID_SHARE(conversationId),
        { id },
    );
}

export function* getUnshareConversationFromUser(action) {
    const { conversationId, userId, onSuccess, onFailure } = action.payload;

    try {
        yield unShareConversationFromUser(conversationId, userId);
        yield put(deleteConversationSharingForUserSuccess(userId));
        onSuccess();
        yield put(updateRecordingRemoveSharedTo(conversationId, userId));
    } catch (e) {
        console.log(e)
        onFailure();
        yield put(deleteConversationSharingForUserFailure(e));
    }
}

export default function* conversationsSaga() {
    yield takeLatest(CONVERSATION.FETCH_START, getConversation);
    yield takeLatest(CONVERSATION.GUEST_FETCH_START, getGuestConversation);
    yield takeLatest(
        CONVERSATION.UPDATE_TITLE.START,
        getUpdateConversationTitle,
    );
    yield takeLatest(CONVERSATION.UPDATE_PASSWORD.START, getUpdatePassword);
    yield takeLatest(CONVERSATION.GENERATE_SUMMARIZATION.START, getGenerateSummarization);
    yield takeLatest(
        CONVERSATION.UPDATE_IS_DOWNLOADABLE.START,
        getUpdateIsDownloadable,
    );
    yield takeEvery(CONVERSATION.UPDATE_SHARED_TO.START, getUpdateSharedTo);
    yield takeEvery(
        CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.START,
        getUnshareConversationFromUser,
    );
}
