import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import { notification } from 'antd';
import Router from 'next/router';
import axios from '@/utils/api/axios';
import {
    changeSignInFailed,
    changeSignInSuccessful,
    CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN,
    CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER,
    CHANGE_SIGN_IN_PROCESS_GET_PROVIDER_INFORMATION,
    CHANGE_SIGN_IN_PROCESS_GOOGLE_SUCCESSFUL,
    CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL,
    chooseProviderFailed,
    throughEmailDoesExist,
    throughEmailDoesNotExist,
} from '@/redux/actions/changeSignInProcessAction';
import { NEXT_ROUTES, ROUTES } from '@/constants/routes';
import { AUTH_PROVIDERS } from '@/constants/authProviders';
import { getUserSelector } from '@/redux/selectors/authSelector';
import { USER_ALREADY_EXISTS } from '@/constants/errors/auth/auth';

export function* checkIfUserExists(action) {
    try {
        const user = yield select(getUserSelector);
        if (user.email !== action.payload.email) {
            yield call(
                axios,
                NEXT_ROUTES.SIGNUP_USER_EXIST(action.payload.email),
            );
        }
        yield put(throughEmailDoesNotExist(action.payload.email));
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(throughEmailDoesExist());
            yield call(action.payload.onError);
        } else {
            yield put(throughEmailDoesExist());
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during login process. Please try again.',
            });
        }
    }
}

export function* changeSignIn(action) {
    try {
        const email = yield select(
            (state) => state.changeSignIn.provider.email,
        );

        yield call(axios.post, NEXT_ROUTES.CHANGE_SIGN_IN, {
            email,
            password: action.payload.password,
            reCaptchaToken: action.payload.reCaptchaToken,
        });
        yield call(Router.replace, ROUTES.SETTINGS);
        yield call(notification.success, {
            message: 'Success',
            description:
                'Your connected account has been updated. Please sign in with this account in the future.',
        });
        yield put(changeSignInSuccessful());
    } catch (error) {
        if (error?.response?.data === USER_ALREADY_EXISTS) {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'This account has already been connected to another Versational user. Please use other account.',
            });
        }
        if (error?.response?.status === 403) {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'ReCAPTCHA could not verify you. Please refresh and try again. If problem persists, try another browser or contact support.',
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during changing your sign in provider.',
            });
        }
        yield put(changeSignInFailed(error));
    }
}

export function* changeSignInWithProvider(action) {
    try {
        const { data: redirectionUrl } = yield call(
            axios,
            NEXT_ROUTES.CHANGE_SIGN_IN_PROVIDER(action.payload.provider),
        );
        yield call([window.location, window.location.assign], redirectionUrl);
    } catch (error) {
        yield put(chooseProviderFailed());
        yield call(notification.error, {
            message: 'Error',
            description: 'Whoops! Could not connect to the identity provider!',
        });
    }
}

export function* getOnChangeSignInExternal() {
    const provider = yield select((state) => state.changeSignIn.provider);
    try {
        switch (provider.name) {
            case AUTH_PROVIDERS.GOOGLE:
                yield call(
                    axios.post,
                    NEXT_ROUTES.CHANGE_SIGN_IN_EXTERNAL_PROVIDER(
                        AUTH_PROVIDERS.GOOGLE,
                    ),
                    {
                        code: provider.response.code,
                    },
                );
                break;
            case AUTH_PROVIDERS.MICROSOFT:
                yield call(
                    axios.post,
                    NEXT_ROUTES.CHANGE_SIGN_IN_EXTERNAL_PROVIDER(
                        AUTH_PROVIDERS.MICROSOFT,
                    ),
                    { ...provider },
                );
                break;
            default:
                break;
        }
        yield call(Router.replace, ROUTES.SETTINGS);
        yield call(notification.success, {
            message: 'Success',
            description:
                'Your connected account has been updated. Please sign in with this account in the future.',
        });
        yield put(changeSignInSuccessful());
    } catch (error) {
        if (error?.response?.data === USER_ALREADY_EXISTS) {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'This account has already been connected to another Versational user. Please use other account.',
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description: 'There was error during sign in process. ',
            });
        }

        yield put(changeSignInFailed(error));
    }
}

export default function* changeSignInProcessSaga() {
    yield takeLatest(
        CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER,
        changeSignInWithProvider,
    );
    yield takeEvery(CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL, checkIfUserExists);
    yield takeEvery(CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN, changeSignIn);
    yield takeEvery(
        CHANGE_SIGN_IN_PROCESS_GET_PROVIDER_INFORMATION,
        getOnChangeSignInExternal,
    );
    yield takeEvery(
        CHANGE_SIGN_IN_PROCESS_GOOGLE_SUCCESSFUL,
        getOnChangeSignInExternal,
    );
}
