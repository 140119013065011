import { createContext, useContext } from 'react';
import { node } from 'prop-types';
import useMediaRecorder from '@/utils/recording/useMediaRecorder';

const MediaRecorderContext = createContext();
export const useMediaRecorderContext = () => useContext(MediaRecorderContext);

export function MediaRecorderProvider({ children }) {
    const mediaRecorder = useMediaRecorder({ audio: true });

    return (
        <MediaRecorderContext.Provider value={mediaRecorder}>
            {children}
        </MediaRecorderContext.Provider>
    );
}

MediaRecorderProvider.propTypes = {
    children: node.isRequired,
};
