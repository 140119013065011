import { useEffect, useState } from 'react';
import {
    loadCurrentConvAndUserTime,
    saveCurrentConvAndUserTime,
} from '@/components/conversations/conversations/headerActionsButton/utils';
import { TimerState } from '@/components/timer/useTimer';
import {
    useTimeContext,
    useTimerContext,
} from '@/components/timer/timerContext';
import { useSelector } from 'react-redux';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { useConversationContext } from '@/components/conversations/conversations/context/context';
import { useRecordingContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/recordingContext';

export default function useHandleTimerOnlyMode() {
    const { timerOnlyMode, setTimerOnlyMode } = useRecordingContext();

    const [hasRestoredTimerState, setHasRestoredTimerState] = useState(false);

    const { updateState } = useTimerContext();
    const seconds = useTimeContext();

    const userId = useSelector(getUserIdSelector);
    const { conversationId } = useConversationContext();

    useEffect(() => {
        if (seconds % 2 && conversationId && userId && timerOnlyMode) {
            saveCurrentConvAndUserTime(userId, conversationId, seconds);
        }
    }, [conversationId, seconds, timerOnlyMode, userId]);

    useEffect(() => {
        if (userId && conversationId && !hasRestoredTimerState) {
            setHasRestoredTimerState(true);
            const savedTime = loadCurrentConvAndUserTime(
                userId,
                conversationId,
            );
            if (savedTime) {
                updateState(TimerState.paused, +savedTime);
                setTimerOnlyMode(true);
            }
        }
    }, [
        userId,
        conversationId,
        updateState,
        setTimerOnlyMode,
        hasRestoredTimerState,
    ]);

    return hasRestoredTimerState;
}
