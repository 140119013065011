import { v4 as uuidv4 } from 'uuid';
import { speakersColorPalette } from '@/utils/recordings/speakersColorPalette';
import {
    getTalkToListenRatio,
    getTalkTime,
} from '@/utils/conversations/analytics/talkToListen/talkToListenRatio';
import { findLastIndex } from 'lodash';

export const getTranscriptByParagraphs = (paragraphs) =>
    paragraphs.reduce(
        (acc, paragraph) => {
            const { words } = paragraph;
            const addedSpeakers = [];

            // How many contents are inside this paragraph (as more than one content can be in one paragraph depending on the speaker)
            const countedContents = words.reduce((contents, word) => {
                const { start, end, text, speaker } = word;
                const speakerName = `Speaker ${speaker}`;
                const existingSpeaker =
                    acc.speakers.find(({ name }) => name === speakerName) ||
                    addedSpeakers.find(({ name }) => name === speakerName);

                if (!existingSpeaker) {
                    const newSpeaker = {
                        id: uuidv4(),
                        name: speakerName,
                    };
                    addedSpeakers.push(newSpeaker);
                    return [
                        ...contents,
                        {
                            id: uuidv4(),
                            speakerId: newSpeaker.id,
                            initial_speaker_id: newSpeaker.id,
                            startTimeMs: start,
                            endTimeMs: end,
                            words: text,
                        },
                    ];
                }
                if (!contents.length) {
                    return [
                        ...contents,
                        {
                            id: uuidv4(),
                            speakerId: existingSpeaker.id,
                            initial_speaker_id: existingSpeaker.id,
                            startTimeMs: start,
                            endTimeMs: end,
                            words: text,
                        },
                    ];
                }

                if (
                    findLastIndex(
                        contents,
                        ({ speakerId }) => speakerId === existingSpeaker.id,
                    ) ===
                    contents.length - 1
                ) {
                    const lastContent = [...contents].pop();
                    const modifiedContent = {
                        ...lastContent,
                        endTimeMs: end,
                        words: lastContent?.words
                            ? `${lastContent.words} ${text}`
                            : text,
                    };
                    return [
                        ...contents.filter((_, i) => i !== contents.length - 1),
                        modifiedContent,
                    ];
                }

                return [
                    ...contents,
                    {
                        id: uuidv4(),
                        speakerId: existingSpeaker.id,
                        initial_speaker_id: existingSpeaker.id,
                        startTimeMs: start,
                        endTimeMs: end,
                        words: text,
                    },
                ];
            }, []);

            return {
                speakers: [...acc.speakers, ...addedSpeakers],
                content: [...acc.content, ...countedContents],
            };
        },
        {
            speakers: [],
            content: [],
        },
    );

const requirements = (paragraph) => {
    const { words } = paragraph;
    const { length } = words;

    const re = /[.!?]/;
    const numOfSentences = words
        .map((word) => word.text)
        .join(' ')
        .split(re);

    return length >= 10 && numOfSentences.length > 2;
};

const isSatisfying = (paragraphs) => paragraphs.every(requirements);

// If a paragraph is only one sentence OR less than 10 words, add it to the bottom of the paragraph above or top of paragraph below depending on which paragraph is shorter. Or if there is no paragraph above, put it below and vice versa.
const satisfyRequirements = (paragraphs) => {
    const candidateParagraphs = [...paragraphs];
    while (!isSatisfying(candidateParagraphs)) {
        const notSatisfyingIndex = candidateParagraphs.findIndex(
            (paragraph) => !requirements(paragraph),
        );

        const paragraphAbove = candidateParagraphs[notSatisfyingIndex - 1];
        const paragraphBelow = candidateParagraphs[notSatisfyingIndex + 1];

        let aboveLength = 0;
        let belowLength = 0;

        if (paragraphAbove) {
            const { words: aboveWords } = paragraphAbove;
            aboveLength = aboveWords.length;
        }

        if (paragraphBelow) {
            const { words: belowWords } = paragraphBelow;
            belowLength = belowWords.length;
        }

        if (!paragraphAbove && !paragraphBelow) {
            return candidateParagraphs;
        }

        if (
            (aboveLength <= belowLength || belowLength === 0) &&
            paragraphAbove
        ) {
            paragraphAbove.words.push(
                ...candidateParagraphs[notSatisfyingIndex].words,
            ); // add words to above paragraph
            candidateParagraphs.splice(notSatisfyingIndex, 1); // remove old paragraph
        } else if (
            (aboveLength > belowLength || aboveLength === 0) &&
            paragraphBelow
        ) {
            candidateParagraphs[notSatisfyingIndex].words.push(
                ...paragraphBelow.words,
            ); // add words to below paragraph
            candidateParagraphs.splice(notSatisfyingIndex + 1, 1); // remove old below paragraph
        }
    }
    return candidateParagraphs;
};

export const getTranscript = (utterances, paragraphs) => {
    const speakers = [];

    const content = utterances.map((utterance) => {
        const { speaker, start, end } = utterance;
        const speakerName = `Speaker ${speaker}`;
        const newSpeaker = speakers.find(({ name }) => name === speakerName);

        let id;

        if (!newSpeaker) {
            id = uuidv4();

            speakers.push({
                id,
                name: speakerName,
            });
        }

        // Count text based on assembly AI paragraphs
        const paragraphsWithinUtterance = paragraphs
            .filter(
                (paragraph) => paragraph.start <= end && paragraph.end >= start,
            )
            .map((paragraph) => ({
                ...paragraph,
                words: paragraph.words.filter(
                    (word) => word.start <= end && word.end >= start,
                ),
            }));

        const paragraphsSatisfyingRequirements = satisfyRequirements(
            paragraphsWithinUtterance,
        );

        const words = paragraphsSatisfyingRequirements
            .map((paragraph) =>
                paragraph.words.map((word) => word.text).join(' '),
            )
            .join('\n\n');

        return {
            id: uuidv4(),
            startTimeMs: start,
            words,
            endTimeMs: end,
            speakerId: newSpeaker ? newSpeaker.id : id,
            initial_speaker_id: newSpeaker ? newSpeaker.id : id,
        };
    });

    return {
        speakers,
        content,
    };
};

export const getTranscriptWithUserColors = (transcript) => {
    const { speakers } = transcript;
    const newSpeakers = speakers.map((speaker, index) => ({
        color: speakersColorPalette[index % speakers.length],
        talkRatio: getTalkToListenRatio(
            transcript.content,
            transcript.speakers,
            [speaker],
        ),
        talkTime: getTalkTime(transcript.content, transcript.speakers, [
            speaker,
        ]),
        ...speaker,
    }));

    return {
        ...transcript,
        speakers: newSpeakers,
    };
};

export const getTranscriptNoInsights = (transcript) => {
    const { speakers } = transcript;
    const newSpeakers = speakers.map((speaker, index) => ({
        color: speakersColorPalette[index % speakers.length],
        talkRatio: getTalkToListenRatio(
            transcript.content,
            transcript.speakers,
            [speaker],
        ),
        talkTime: getTalkTime(transcript.content, transcript.speakers, [
            speaker,
        ]),
        ...speaker,
    }));

    const { content } = transcript;

    const newContent = content.map((con) => {
        const tempContent = { ...con };

        tempContent.quotes = [];
        return tempContent;
    });

    return {
        ...transcript,
        speakers: newSpeakers,
        content: newContent,
    };
};
