const INSIGHTS_BASE = 'INSIGHTS_VIEW';

export const INSIGHTS_SET_QUOTE_ACTIVE = `${INSIGHTS_BASE}/SET_QUOTE_ACTIVE`;
export const INSIGHTS_RESET_QUOTE_ACTIVE = `${INSIGHTS_BASE}/RESET_QUOTE_ACTIVE`;
export const INSIGHTS_SET_INSIGHTS_TAB = `${INSIGHTS_BASE}/SET_INSIGHTS_TAB`;
export const INSIGHTS_SET_INSIGHTS_FILTERS = `${INSIGHTS_BASE}/SET_INSIGHTS_FILTERS`;
export const INSIGHTS_SET_EXCLUDED_GEMS = `${INSIGHTS_BASE}/SET_EXCLUDED_GEMS`;
export const INSIGHTS_SET_EXCLUDED_GEMS_RESET = `${INSIGHTS_BASE}/SET_EXCLUDED_GEMS_RESET`;
export const INSIGHTS_SET_EXCLUDED_TOPICS = `${INSIGHTS_BASE}/SET_EXCLUDED_TOPICS`;
export const INSIGHTS_SET_EXCLUDED_TOPICS_RESET = `${INSIGHTS_BASE}/SET_EXCLUDED_TOPICS_RESET`;
export const INSIGHTS_OPEN_ADD_EVENT_MODAL = `${INSIGHTS_BASE}/OPEN_ADD_EVENT_MODAL`;
export const INSIGHTS_CLOSE_ADD_EVENT_MODAL = `${INSIGHTS_BASE}/CLOSE_ADD_EVENT_MODAL`;
export const INSIGHTS_OPEN_ADD_TASK_MODAL = `${INSIGHTS_BASE}/OPEN_ADD_TASK_MODAL`;
export const INSIGHTS_CLOSE_ADD_TASK_MODAL = `${INSIGHTS_BASE}/CLOSE_ADD_TASK_MODAL`;

export const setQuoteActive = (
    quoteId,
    isImmediate = false,
    currentSearchedComment,
) => ({
    type: INSIGHTS_SET_QUOTE_ACTIVE,
    payload: { data: quoteId, isImmediate, currentSearchedComment },
});

export const resetQuoteActive = () => ({
    type: INSIGHTS_RESET_QUOTE_ACTIVE,
});

export const setInsightsTab = (insightsTab) => ({
    type: INSIGHTS_SET_INSIGHTS_TAB,
    payload: { data: insightsTab },
});

export const setInsightsFilters = (insightsFilter) => ({
    type: INSIGHTS_SET_INSIGHTS_FILTERS,
    payload: { data: insightsFilter },
});

export const setExcludedGems = (excludedGems, activeInsightTab) => ({
    type: INSIGHTS_SET_EXCLUDED_GEMS,
    payload: { data: excludedGems, activeInsightTab },
});

export const resetExcludedGems = () => ({
    type: INSIGHTS_SET_EXCLUDED_GEMS_RESET,
});

export const setExcludedTopics = (excludedTopics, activeInsightTab) => ({
    type: INSIGHTS_SET_EXCLUDED_TOPICS,
    payload: { data: excludedTopics, activeInsightTab },
});

export const resetExcludedTopics = () => ({
    type: INSIGHTS_SET_EXCLUDED_TOPICS_RESET,
});

export const openAddEventModal = (quoteId, speakerId) => ({
    type: INSIGHTS_OPEN_ADD_EVENT_MODAL,
    payload: {
        data: {
            quoteId,
            speakerId,
        },
    },
});
export const closeAddEventModal = () => ({
    type: INSIGHTS_CLOSE_ADD_EVENT_MODAL,
});
export const openAddTaskModal = (quoteId, speakerId) => ({
    type: INSIGHTS_OPEN_ADD_TASK_MODAL,
    payload: {
        data: {
            quoteId,
            speakerId,
        },
    },
});
export const closeAddTaskModal = () => ({
    type: INSIGHTS_CLOSE_ADD_TASK_MODAL,
});

// QUOTE CHECKING LOGIC

export const INSIGHTS_CHECK_QUOTE = `${INSIGHTS_BASE}/CHECK_QUOTE`;
export const INSIGHTS_UNCHECK_QUOTE = `${INSIGHTS_BASE}/UNCHECK_QUOTE`;
export const INSIGHTS_BULK_CHECK_QUOTE = `${INSIGHTS_BASE}/BULK_CHECK_QUOTE`;
export const INSIGHTS_BULK_UNCHECK_QUOTE = `${INSIGHTS_BASE}/BULK_UNCHECK_QUOTE`;
export const INSIGHTS_UNCHECK_ALL_QUOTES = `${INSIGHTS_BASE}/UNCHECK_ALL_QUOTES`;

export const checkQuote = (id) => ({
    type: INSIGHTS_CHECK_QUOTE,
    payload: {
        id,
    },
});
export const bulkCheckQuote = (ids) => ({
    type: INSIGHTS_BULK_CHECK_QUOTE,
    payload: {
        ids,
    },
});
export const uncheckQuote = (id) => ({
    type: INSIGHTS_UNCHECK_QUOTE,
    payload: {
        id,
    },
});
export const bulkUncheckQuote = (ids) => ({
    type: INSIGHTS_BULK_UNCHECK_QUOTE,
    payload: {
        ids,
    },
});
export const uncheckAllQuotes = () => ({
    type: INSIGHTS_UNCHECK_ALL_QUOTES,
});

// DERIVED FROM STATE - SAGAS
export const INSIGHTS_CHECK_ALL_QUOTES = `${INSIGHTS_BASE}/CHECK_ALL_QUOTES`;

export const checkAllQuotes = () => ({
    type: INSIGHTS_CHECK_ALL_QUOTES,
});