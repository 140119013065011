import {
    RECORDS_CONTACTS_FETCH_START,
    RECORDS_CONTACTS_FETCH_SUCCESS,
    RECORDS_CONTACTS_FETCH_FAILURE,
    RECORDS_CONTACT_EDIT_START,
    RECORDS_CONTACT_ADD_FAILURE,
    RECORDS_CONTACT_ADD_SUCCESS,
    RECORDS_CONTACT_FETCH_START,
    RECORDS_CONTACT_FETCH_SUCCESS,
    RECORDS_CONTACT_FETCH_FAILURE,
    RECORDS_ASSIGNEE_ADD_FAILURE,
    RECORDS_ASSIGNEE_ADD_SUCCESS,
    RECORDS_ASSIGNEES_FETCH_START,
    RECORDS_ASSIGNEES_FETCH_SUCCESS,
    RECORDS_ASSIGNEES_FETCH_FAILURE,
} from '@/redux/actions/_exports';

export const initialState = {
    loading: false,
    error: null,
    isIdle: true,
    contacts: [],
    total: 0,
    contact: {
        contact: {},
        loading: false,
        error: null,
    },
    assignees: {
        assignees: {},
        loading: false,
        error: null,
    },
};

function recordsContactsReducer(state = initialState, action) {
    const { contacts } = state;

    switch (action.type) {
        case RECORDS_CONTACTS_FETCH_START: {
            return {
                ...state,
                loading: true,
                error: false,
                isIdle: false,
            };
        }
        case RECORDS_CONTACTS_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                total: action.payload.contacts.total ?? 0,
                contacts: action.payload.contacts.contacts ?? [],
            };
        }
        case RECORDS_CONTACTS_FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case RECORDS_CONTACT_FETCH_START: {
            return {
                ...state,
                contact: {
                    ...state.contact,
                    loading: true,
                },
            };
        }
        case RECORDS_CONTACT_FETCH_SUCCESS: {
            return {
                ...state,
                contact: {
                    loading: false,
                    error: false,
                    contact: action.payload.contact,
                },
            };
        }
        case RECORDS_CONTACT_FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
                contact: {
                    ...state.contact,
                    loading: false,
                    error: true,
                },
            };
        }
        case RECORDS_CONTACT_EDIT_START: {
            const { id, type, value } = action.payload;

            const newContacts = contacts.map((item) => {
                if (item.record_id === id) {
                    if (type === 'first_name') {
                        return {
                            ...item,
                            [type]: value,
                            contact_name: `${value} ${item.last_name}`,
                        };
                    }
                    if (type === 'last_name') {
                        return {
                            ...item,
                            [type]: value,
                            contact_name: `${item.first_name} ${value}`,
                        };
                    }
                    return {
                        ...item,
                        [type]: value,
                    };
                }
                return item;
            });

            return {
                ...state,
                error: false,
                contacts: newContacts,
            };
        }
        case RECORDS_CONTACT_ADD_SUCCESS: {
            return {
                ...state,
                error: false,
                contact: {
                    contact: action.payload.contact,
                    loading: false,
                    error: false,
                },
            };
        }
        case RECORDS_CONTACT_ADD_FAILURE : {
            return {
                ...state,
                error: {
                    message: action.payload.error,
                    contact : action.payload.data,
                },
            }
        }
        case RECORDS_ASSIGNEE_ADD_SUCCESS: {
            return {
                ...state,
                error: false,
                assignees: {
                    assignees: action.payload.assignees,
                    loading: false,
                    error: false,
                },
            };
        }
        case RECORDS_ASSIGNEE_ADD_FAILURE : {
            return {
                ...state,
                error: {
                    message: action.payload.error,
                    assignees : action.payload.data,
                },
            }
        }
        case RECORDS_ASSIGNEES_FETCH_START: {
            return {
                ...state,
                loading: true,
                error: false,
                isIdle: false,
            };
        }
        case RECORDS_ASSIGNEES_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                assignees: action.payload.assignees ?? [],
            };
        }
        case RECORDS_ASSIGNEES_FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        default:
            return state;
    }
}

export default recordsContactsReducer;
