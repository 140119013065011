import { Dropdown, Menu, Popconfirm } from 'antd';
import { bool, func, node } from 'prop-types';

function TeamMemberTableMenu({ onConfirm, active, disabled, children }) {
    const title = active
        ? "Are you sure you want to archive this user? User's existing data will remain."
        : 'Are you sure you want to invite this user back to your team?';

    const menu = (
        <Menu>
            <Popconfirm
                destroyTooltipOnHide
                title={title}
                onConfirm={onConfirm}
                okText="Yes"
                cancelText="No"
            >
                <Menu.Item disabled={disabled}>
                    {active ? 'Archive user' : 'Reactivate user'}
                </Menu.Item>
            </Popconfirm>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger="click" placement="bottomRight">
            {children}
        </Dropdown>
    );
}

TeamMemberTableMenu.propTypes = {
    active: bool.isRequired,
    disabled: bool.isRequired,
    children: node.isRequired,
    onConfirm: func.isRequired,
};

export default TeamMemberTableMenu;
