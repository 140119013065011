export const getDateSelector = (state) => state.events.date;
export const getIdSelector = (state) => state.events.id;
export const getCalendarSettingsDataSelector = (state) =>
    state.settings.calendar?.data;
export const getZoneSelector = (state) => state.date?.zone;
export const getCalendarProviderSelector = (state) =>
    state.calendarProvider?.provider;
export const getCalendarProviderLoadingSelector = (state) =>
    state.calendarProvider?.loading;

export const getCalendarProviderStatusSelector = (state) =>
    state.calendarProvider?.status;
