/* eslint-disable indent */
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { msToHoursMinutesSeconds } from '@/utils/time/msToHoursMinutesSeconds';

export const startTimeCounter = (words, quote) => {
    let letters = 0;
    const word = words?.find((w) => {
        if (letters === quote.startsAt) {
            return true;
        }
        letters += w.text.length + 1;
        if (letters > quote.startsAt) return true;
        return false;
    });

    return word?.start;
};

export const getQuotesFromTranscriptInDisplayFormat = (
    transcript,
    transcriptWords,
    hiddenSpeakers,
) => {
    const filteredQuotes = ((transcript ?? {})?.content ?? [])
        .map((c, i) => {
            const tmpC = c;
            tmpC.index = i;
            return tmpC;
        })
        .filter((c) => c.quotes.length !== 0);

    return filteredQuotes.filter((v) => !hiddenSpeakers.includes(v.speakerId)).flatMap((c) =>
        c.quotes.map((q) => {
            const quoteWords = transcriptWords.filter(
                ({ startIndex, endIndex, contentId }) =>
                    startIndex >= q.startsAt &&
                    endIndex <= q.endsAt &&
                    contentId === q.content_id,
            );

            const quoteStartTimeMs = quoteWords[0]?.startInMs ?? c.startTimeMs;

            return {
                id: q.id,
                fromMl: q.from_ml,
                confirmed: q.confirmed,
                authorId: c.speakerId,
                text: c.words.slice(q.startsAt, q.endsAt),
                words: quoteWords,
                customTags: q.customTags,
                gems: q.gems,
                highlight_phrases: q.highlight_phrases,
                assignee: q.assignee,
                due_date: q.due_date,
                is_done: q.is_done,
                events: null,
                tasks: null,
                notes: false,
                time: msToHoursMinutesSeconds(quoteStartTimeMs),
                timeInMs: quoteStartTimeMs,
                pinned: q.pinned,
                checked: false,
                comments: q.comments,
                contentIndex: c.index,
                startsAt: q.startsAt,
                endsAt: q.endsAt,
                contentId: c.id,
                smart_todos: q.smart_todos,
            };
        }),
    );
};

export const deleteQuote = async (id, contentIndex, transcript) => {
    // delete quote
    const { quotes } = transcript.content[contentIndex];
    const indexToRemove = quotes.findIndex((quote) => quote.id === id);
    // We have to check if index to Remove exists in quotes Array, otherwise it will remove even those that has content inside
    if (indexToRemove !== -1) {
        quotes.splice(indexToRemove, 1);
        await axios.delete(NEXT_ROUTES.QUOTE_ID(id));
    }
    return { ...transcript };
};

export const deleteBatchQuotes = async (ids, contentIndex, transcript) => {
    await Promise.all(ids.map((id) => axios.delete(NEXT_ROUTES.QUOTE_ID(id))));

    return {
        ...transcript,
        content: transcript.content.map((contentItem, index) =>
            index !== contentIndex
                ? contentItem
                : {
                      ...contentItem,
                      quotes: contentItem.quotes.filter(
                          ({ id }) => !ids.some((v) => v === id),
                      ),
                  },
        ),
    };
};

export const getNewQuote = async (contentId, startsAt, endsAt) => {
    const { data } = await axios.post(
        NEXT_ROUTES.CONVERSATIONS_CONTENT_QUOTE(contentId),
        {
            startsAt,
            endsAt,
            confirmed: false,
        },
    );

    data.gems = [];
    data.customTags = [];

    return data;
};

export const getIndexToAddQuote = (newQuote, quotes) => {
    let indexToAdd = 0;
    quotes.forEach((quote, index) => {
        const prevQuote = quotes[index - 1];
        if (index === 0) {
            if (newQuote.endsAt < quote.startsAt) {
                indexToAdd = 0;
            }
        }
        if (prevQuote) {
            if (newQuote.startsAt > prevQuote.endsAt) {
                indexToAdd = index;
            }
        }
        if (index === quotes.length - 1) {
            if (newQuote.startsAt > quote.endsAt) {
                indexToAdd = index + 1;
            }
        }
    });
    return indexToAdd;
};

export const hasContent = (comments, customTags, gems) =>
    comments?.length ||
    Object.keys(customTags).some((key) => customTags[key]) ||
    Object.keys(gems).some((key) => gems[key]);

export const shouldBeRemoved = ({
    comments,
    customTags,
    gems,
    pinned,
    confirmed,
}) => !(hasContent(comments, customTags, gems) || pinned || confirmed);

export const addQuote = (contentItem, newQuote) => {
    const newContentItem = {
        ...contentItem,
    };
    const newQuotes = newContentItem.quotes ? [...newContentItem.quotes] : [];
    const indexToAdd = getIndexToAddQuote(newQuote, newQuotes);
    newQuotes.splice(indexToAdd, 0, newQuote);
    newContentItem.quotes = [...newQuotes];
    return newContentItem;
};

export const removeQuotes = (contentItem, quoteIds) => ({
    ...contentItem,
    quotes: contentItem.quotes.filter(
        ({ id }) => !quoteIds.some((v) => v === id),
    ),
});

export const bulkRemoveQuotes = (transcript, quotes) => {
    const contentIdsRemovedFrom = quotes.map(({ contentId }) => contentId);
    return {
        ...transcript,
        content: transcript.content.map((contentItem) => {
            if (contentIdsRemovedFrom.includes(contentItem.id)) {
                return removeQuotes(
                    contentItem,
                    quotes.map(({ id }) => id),
                );
            }
            return contentItem;
        }),
    };
};
