import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { ASSEMBLY_TRANSCRIPT } from '@/constants/reduxKeys';
import assemblyTranscriptReducer from '@/redux/reducers/assemblyTranscriptReducer';
import assemblyTranscriptSaga from '@/redux/sagas/assemblyTranscriptSaga';

export default function AssemblyTranscriptSagaInjector() {
    useInjectReducer({
        key: ASSEMBLY_TRANSCRIPT,
        reducer: assemblyTranscriptReducer,
    });
    useInjectSaga({ key: ASSEMBLY_TRANSCRIPT, saga: assemblyTranscriptSaga });
    return null;
}
