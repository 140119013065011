import { useCallback, useEffect, useMemo, useState } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Tooltip, Typography, Tabs, Select } from 'antd';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { debounce } from 'lodash';

import {
    CleanDivider,
    ColoredDivHeaderWrapper,
    ColoredDivWrapper,
    RedoIcon,
    SearchContainer,
    SearchMembersTitle,
    TooltipContainer,
    InfoWrapper,
    SingleEditParapgrah,
} from '@/components/conversations/attendeesChooser/attendeesChooser.styles';
import Search from '@/components/conversations/attendeesChooser/search/search';
import SelectableItem from '@/components/conversations/attendeesChooser/selectableItem/selectableItem';
import MainPanel from '@/components/conversations/attendeesChooser/mainPanel/mainPanel';
import useAttendees from '@/components/conversations/results/attendees/hooks/useAttendees';
import useTeamMembers from '@/components/conversations/results/attendees/hooks/useOwners';
import { AttendeeTypes } from '@/constants/conversations/attendeeTypes';
import {
    getIsCrmActive,
    getIsGuestAuthorized,
} from '@/redux/selectors/authSelector';
import {
    getCrmContactsData,
    getCrmContactsError,
} from '@/redux/selectors/crmRecordsSelector';
import { getTranscriptSpeakersSelector } from '@/redux/selectors/transcript/transcriptSelector';
import { fetchContactsStart } from '@/redux/actions/recordsContactsAction';
import useResize from '@/components/conversations/attendeesChooser/hooks/useResize';
import { filter as membersFilter } from '@/components/conversations/attendeesChooser/utils/filter';
import { resetTranscriptSpeakers } from '@/redux/actions/conversation/transcript';
import TeamMembers from '@/components/conversations/attendeesChooser/panels/teamMembers';
import Contacts from '@/components/conversations/attendeesChooser/panels/contacts';
import NotCrmAttendees from '@/components/conversations/attendeesChooser/panels/attendees';
import { VERSATIONAL } from '@/constants/colors';
import InformationTooltip from '@/components/informationTooltip/informationTooltip';

const { Paragraph } = Typography;

function MainPanelIcon({ visible, onRedoClick }) {
    if (!visible) return null;
    return (
        <Tooltip overlay="Reset speaker assignments">
            <RedoIcon onClick={onRedoClick} data-testid="redoIcon" />
        </Tooltip>
    );
}

MainPanelIcon.propTypes = {
    visible: bool.isRequired,
    onRedoClick: func.isRequired,
};

function SearchWithError({ isError, searchValue, onSearch }) {
    if (isError)
        return (
            <Paragraph>
                <CloseCircleOutlined
                    style={{
                        color: 'red',
                        marginRight: '0.5rem',
                    }}
                />
                Users fetch failed
            </Paragraph>
        );

    return (
        <ColoredDivHeaderWrapper>
            <Search searchValue={searchValue} setSearchValue={onSearch} />
        </ColoredDivHeaderWrapper>
    );
}

SearchWithError.propTypes = {
    isError: bool.isRequired,
    searchValue: string.isRequired,
    onSearch: func.isRequired,
};

function AttendeesChooser({
    hidden,
    onResetEnd,
    updateAttendee,
    updateSingleAttendee,
    showResetButton = false,
    selectedUsers = [],
    singleEditMode,
}) {
    const dispatch = useDispatch();

    const {
        data: attendees,
        error: attendeesError,
        addAttendee,
    } = useAttendees();

    const { data: teamMembers } = useTeamMembers();
    const height = useResize();

    const [searchValue, setSearchValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [resetModalVisible, setResetModalVisible] = useState(false);

    const contacts = useSelector(getCrmContactsData);
    const contactsError = useSelector(getCrmContactsError);
    const guestAuthorized = useSelector(getIsGuestAuthorized);
    const isCRMAvailable = useSelector(getIsCrmActive);
    const transcriptSpeakers = useSelector(getTranscriptSpeakersSelector);

    const handleChange = (value) => {
        updateSingleAttendee(
            value,
        );
    };

    useEffect(() => {
        if (!hidden) {
            setSearchValue('');
            setInputValue('');
        }
    }, [hidden]);

    useEffect(() => {
        if (isCRMAvailable && searchValue !== '') {
            dispatch(fetchContactsStart(10, 1, searchValue.trim(), true));
        }
    }, [dispatch, isCRMAvailable, searchValue]);

    const filteredTeamMembers = useMemo(
        () => teamMembers
            .filter((member) => member.deleted_at === null)
            .filter(membersFilter(selectedUsers, searchValue)),
        [searchValue, selectedUsers, teamMembers],
    );

    const filteredAttendees = useMemo(() => {
        const matchSearch = (item) =>
            item.attendee_name
                .toLowerCase()
                .includes(searchValue.trim().toLowerCase());
        return attendees.filter(matchSearch);
    }, [attendees, searchValue]);

    const isAddNewButtonShown = isCRMAvailable
        ? contacts.length === 0 && filteredTeamMembers.length === 0
        : filteredAttendees.length === 0 && filteredTeamMembers.length === 0;

    const debouncedSearchInput = debounce(
        (value) => setSearchValue(value),
        700,
    );

    const handleInputChange = (value) => {
        setInputValue(value);
        debouncedSearchInput(value);
    };

    const onReset = useCallback(() => {
        dispatch(resetTranscriptSpeakers());
        onResetEnd();
    }, [dispatch, onResetEnd]);

    const onStorageUpdate = (e) => {
        const { key } = e;
        if (key === "contactName") {
            if (localStorage.getItem("contactName").length > 0) {
                if (isCRMAvailable && searchValue !== '') {
                    dispatch(fetchContactsStart(10, 1, localStorage.getItem("contactName").trim(), true));
                }
                localStorage.setItem("contactName", '');
            }
        }
    };

    useEffect(() => {
        window.addEventListener("storage", onStorageUpdate);
        return () => {
            window.removeEventListener("storage", onStorageUpdate);
        };
    }, []);

    return (
        <ColoredDivWrapper style={{ maxHeight: height }}>
            <Tabs defaultActiveKey="1">
                <Tabs.TabPane tab="Assign all" key="1">
                    <MainPanel
                        title={isCRMAvailable ? 'Assign Speaker' : 'Search'}
                        icon={
                            <MainPanelIcon
                                visible={showResetButton}
                                onRedoClick={() => {
                                    setResetModalVisible(true);
                                }}
                            />
                        }
                    >
                        <Modal
                            zIndex={1300}
                            visible={resetModalVisible}
                            closable={false}
                            onCancel={() => {
                                setResetModalVisible(false);
                            }}
                            onOk={() => {
                                onReset();
                                setResetModalVisible(false);
                            }}
                        >
                            Are you sure you want to reset the selected attendee names
                            for this conversation?
                        </Modal>
                        <SearchWithError
                            isError={contactsError || attendeesError}
                            onSearch={handleInputChange}
                            searchValue={inputValue}
                        />
                    </MainPanel>
                    <div>
                        <CleanDivider />
                    </div>
                    {searchValue && (
                        <SearchContainer>
                            <SearchMembersTitle>Team Members</SearchMembersTitle>
                            <TeamMembers
                                teamMembers={filteredTeamMembers}
                                onSelect={(userId, name) => {
                                    updateAttendee(
                                        userId,
                                        name,
                                        AttendeeTypes.teamMember,
                                    );
                                }}
                            />
                            <CleanDivider />
                            {isCRMAvailable ? (
                                <>
                                    <SearchMembersTitle>Contacts</SearchMembersTitle>
                                    <Contacts
                                        contacts={contacts}
                                        onSelect={(userId, name) => {
                                            updateAttendee(
                                                userId,
                                                name,
                                                AttendeeTypes.contact,
                                            );
                                        }}
                                    />
                                </>
                            ) : (
                                <>
                                    <SearchMembersTitle>
                                        Other Attendees
                                    </SearchMembersTitle>
                                    <NotCrmAttendees
                                        attendees={filteredAttendees}
                                        onSelect={(userId, name) => {
                                            updateAttendee(
                                                userId,
                                                name,
                                                AttendeeTypes.attendee,
                                            );
                                        }}
                                    />
                                </>
                            )}
                            {isAddNewButtonShown && (
                                <SelectableItem
                                    style={{ width: 100 }}
                                    name={`${searchValue}`}
                                    isNew
                                    toCrmNew={isCRMAvailable}
                                    onSelect={() => {
                                        if (!guestAuthorized) {
                                            addAttendee(searchValue, (newAttendee) => {
                                                if (!isCRMAvailable) {
                                                    updateAttendee(
                                                        newAttendee.id,
                                                        searchValue,
                                                        AttendeeTypes.attendee,
                                                    );
                                                }
                                            });
                                        }
                                    }}
                                />
                            )}
                        </SearchContainer>
                    )}
                </Tabs.TabPane>
                
                {singleEditMode && <Tabs.TabPane tab="Assign single" key="2">
                    <SingleEditParapgrah>Attendee</SingleEditParapgrah>
                    <Select
                        style={{
                            width: '100%',
                            fontSize: '13px',
                        }}
                        onChange={handleChange}
                        placeholder="Choose attendee"
                        options={transcriptSpeakers.map((speaker) => ({
                            value: speaker.id,
                            label: speaker.name,
                        }))}
                    />
                </Tabs.TabPane>}
            </Tabs>
            <InfoWrapper>
                <InformationTooltip
                    color={VERSATIONAL}
                    text={
                        <TooltipContainer>
                            <p>
                                Assign all - assign user for each paragraph assigned to this speaker.
                            </p>
                            {singleEditMode && <>
                                <br />
                                <p>
                                    Assign single - assign user to this specific paragraph.
                                </p>
                            </>}
                        </TooltipContainer>
                    }
                />
            </InfoWrapper>
        </ColoredDivWrapper>
    );
}

AttendeesChooser.defaultProps = {
    onResetEnd: undefined,
    showResetButton: false,
    selectedUsers: [],
    updateSingleAttendee: () => {},
    singleEditMode: false,
};

AttendeesChooser.propTypes = {
    hidden: bool.isRequired,
    onResetEnd: func,
    updateAttendee: func.isRequired,
    updateSingleAttendee: func,
    showResetButton: bool,
    selectedUsers: arrayOf(shape({ userId: string })),
    singleEditMode: bool,
};

export default AttendeesChooser;
