export const CHANGE_SIGN_IN_PROCESS_RESET_TO_CHOOSE_PROVIDER =
    'CHANGE_SIGN_IN_PROCESS/RESET_TO_CHOOSE_PROVIDER';
export const CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER =
    'CHANGE_SIGN_IN_PROCESS/CHOOSE_PROVIDER';
export const CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER_FAILED =
    'CHANGE_SIGN_IN_PROCESS/CHOOSE_PROVIDER_FAILED';
export const CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL =
    'CHANGE_SIGN_IN_PROCESS/THROUGH_EMAIL';
export const CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_EXIST =
    'CHANGE_SIGN_IN_PROCESS/THROUGH_EMAIL_DOES_EXIST';
export const CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST =
    'CHANGE_SIGN_IN_PROCESS/THROUGH_EMAIL_DOES_NOT_EXIST';
export const CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN =
    'CHANGE_SIGN_IN_PROCESS/CHANGE_SIGN_IN';
export const CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_FAILED =
    'CHANGE_SIGN_IN_PROCESS/CHANGE_SIGN_IN_FAILED';
export const CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_SUCCESSFUL =
    'CHANGE_SIGN_IN_PROCESS/CHANGE_SIGN_IN_SUCCESSFUL';
export const CHANGE_SIGN_IN_PROCESS_GET_PROVIDER_INFORMATION =
    'CHANGE_SIGN_IN_PROCESS/GET_PROVIDER_INFORMATION';

export const CHANGE_SIGN_IN_PROCESS_GOOGLE_CHOSEN =
    'CHANGE_SIGN_IN_PROCESS/GOOGLE_CHOSEN';
export const CHANGE_SIGN_IN_PROCESS_GOOGLE_SUCCESSFUL =
    'CHANGE_SIGN_IN_PROCESS/GOOGLE_SUCCESSFUL';
export const CHANGE_SIGN_IN_PROCESS_FIX_TYPO =
    'CHANGE_SIGN_IN_PROCESS/FIX_TYPO';

export function resetToChangeSignInPage() {
    return {
        type: CHANGE_SIGN_IN_PROCESS_RESET_TO_CHOOSE_PROVIDER,
    };
}

export function chooseProvider(provider) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER,
        payload: {
            provider,
        },
    };
}

export function chooseProviderFailed() {
    return {
        type: CHANGE_SIGN_IN_PROCESS_CHOOSE_PROVIDER_FAILED,
    };
}

export function throughEmail(email, onError) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL,
        payload: {
            email,
            onError,
        },
    };
}

export function throughEmailDoesExist() {
    return {
        type: CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_EXIST,
    };
}

export function throughEmailDoesNotExist(email) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST,
        payload: { email },
    };
}

export function googleChosen() {
    return {
        type: CHANGE_SIGN_IN_PROCESS_GOOGLE_CHOSEN,
    };
}

export function googleSuccessful(googleResponse) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_GOOGLE_SUCCESSFUL,
        payload: {
            response: googleResponse,
        },
    };
}

export function getProviderInformation(
    provider,
    email,
    accessToken,
    refreshToken,
) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_GET_PROVIDER_INFORMATION,
        payload: {
            name: provider,
            email,
            accessToken,
            refreshToken,
        },
    };
}

export function changeSignIn(password, reCaptchaToken) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN,
        payload: {
            password,
            reCaptchaToken,
        },
    };
}

export function changeSignInFailed(error) {
    return {
        type: CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_FAILED,
        payload: error,
    };
}

export function changeSignInSuccessful() {
    return {
        type: CHANGE_SIGN_IN_PROCESS_CHANGE_SIGN_IN_SUCCESSFUL,
    };
}
export function fixTypo() {
    return {
        type: CHANGE_SIGN_IN_PROCESS_FIX_TYPO,
    };
}
