import { useCallback, useState } from 'react';
import {
    emptyRecoveryData,
    getAudioRecoveryData,
} from '@/utils/recording/recovery/readUtils';

export function useRestoreRecoveryData(userId) {
    const [recoveryData, setRecoveryData] = useState(emptyRecoveryData);

    const restoreRecoveryData = useCallback(async () => {
        const { conversationId, date, title, meetingExternalId, chunks } =
            await getAudioRecoveryData(userId);

        const data = {
            conversationId,
            title,
            date,
            meetingExternalId,
            chunks,
        };

        setRecoveryData(data);
    }, [userId]);

    return [recoveryData, setRecoveryData, restoreRecoveryData];
}
