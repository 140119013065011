export const fileToRecording = (file, owner) => ({
    duration: file.duration,
    account: '-',
    opportunity: '-',
    stage: '-',
    percent: file.percent,
    action_items: 0,
    insights: 0,
    topics_covered: 0,
    questions: 0,
    buyer_questions: 0,
    comments: 0,
    is_versational: false,
    is_result: file.status === 'completed',
    file_type: file.type,
    is_from_zoom_integration: file.IsFromIntegration,
    created_at: new Date(),
    updated_at: new Date(),
    date: new Date(),
    conversation_id: file?.response?.conversationID || file?.conversationID,
    conversation: {
        id: file?.response?.conversationID || file?.conversationID,
        title: file?.name?.replace(/.[^.]+$/, ''),
        transcription_status: file.status,
    },
    attendees: [],
    owner,
    tags: [],
    recentlyUploaded: true,
});
