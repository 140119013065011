import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { bool } from 'prop-types';
import { StyledFontAwesomeIcon } from '@/components/settings/tabs/expandIcon.styles';

function ExpandIcon({ isActive }) {
    return <StyledFontAwesomeIcon icon={faChevronRight} $isActive={isActive} />;
}
ExpandIcon.propTypes = {
    isActive: bool.isRequired,
};

export default ExpandIcon;
