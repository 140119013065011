import { put, take } from 'redux-saga/effects';
import {
    CONVERSATION,
    fetchConversation,
    fetchGuestConversation,
} from '@/redux/actions/conversation/conversation';

export function* getConversation(id) {
    yield put(fetchConversation(id));
    const conversationFetchAction = yield take([
        CONVERSATION.FETCH_SUCCESS,
        CONVERSATION.FETCH_FAILURE,
    ]);
    if (conversationFetchAction.type === CONVERSATION.FETCH_FAILURE)
        throw new Error('Conversation result failed on conversation fetch');

    return conversationFetchAction.payload.data;
}

export function* getGuestConversation(id) {
    yield put(fetchGuestConversation(id));
    const conversationFetchAction = yield take([
        CONVERSATION.FETCH_SUCCESS,
        CONVERSATION.FETCH_FAILURE,
    ]);
    if (conversationFetchAction.type === CONVERSATION.FETCH_FAILURE)
        throw new Error('Conversation result failed on conversation fetch');

    return conversationFetchAction.payload.data;
}
