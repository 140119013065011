import { createSelector } from 'reselect';
import { GENERIC_FETCH_STATE } from '@/constants/conversations/genericState';
import { FETCH_STATUS } from '@/constants/fetchStatus';
import { getUserIdSelector, getUserCompanyId } from '@/redux/selectors/authSelector';

export const getConversationCompletionSelector = (state) => {
    const { conversation } = state;
    return (
        conversation.transcriptionStatus === 'completed' &&
        conversation.gemClassifierStatus
    );
};

export const getConversationCreationSelector = (state) =>
    state.conversation?.data?.createdDate;

export const getConversationTitleSelector = (state) =>
    state.conversation?.data?.title;

export const getConversationIdSelector = (state) =>
    state.conversation?.data?.id;

export const getConversationTakeawaysSelector = (state) =>
    state.conversation?.data?.takeaways ?? [];

export const getConversationPasswordSelector = (state) =>
    state.conversation?.data?.sharePassword;
export const getConversationIsDownloadableSelector = (state) =>
    state.conversation?.data?.isDownloadable;

export const getTranscriptIdSelector = (state) =>
    state.conversation?.data?.transcriptId;

export const getIsUnauthorizedConversationSelector = (state) =>
    state.conversation?.error === GENERIC_FETCH_STATE.ERROR.FORBIDDEN;

export const getSharedViewsCountSelector = (state) =>
    state.conversation?.data.sharedViewsCount;

export const getConversationInitialLoadingSelector = (state) =>
    state.conversation?.status === FETCH_STATUS.INITIAL_LOADING;

export const getConversationOwnerSelector = (state) =>
    state.conversation?.data?.owner;

export const getConversationUpdateLoadingSelector = (state) =>
    state.conversation?.status === FETCH_STATUS.UPDATE_LOADING;

export const getSharedToSelector = (state) =>
    state.conversation?.data?.sharedTo;

// filter out null values

export const getSharedToAllSelector = (state) =>
    state.conversation?.data?.sharedToAll.filter((item) => item) || [];

export const getHasAccessToRecordingSharingSelector = (state) => {
    const userId = getUserIdSelector(state);
    const loading = getConversationInitialLoadingSelector(state);
    const conversationOwner = getConversationOwnerSelector(state);

    return !loading && userId === conversationOwner?.id;
};

export const getSummarizationSelector = (state) =>
    state.conversation?.data?.summarization;

export const getShortSummarizationSelector = (state) =>
    state.conversation?.data?.shortSummarization;

export const getIsCompanyOwnerOfConversation = createSelector(
    [getConversationOwnerSelector, getUserCompanyId],
    (owner, companyId) => {
        if (!owner || !companyId) return true;

        return owner?.company_id === companyId;
    }
);

export const getConversationMeetingNote = (state) =>
    state.conversation?.data?.meetingNote;

export const getConversationIsModalVisible = (state) =>
    state.conversation?.data?.isModalVisible;
