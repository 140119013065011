import { useTeamContext } from '@/components/settings/team/teamContext';
import { PlusOutlined } from '@ant-design/icons';
import { ColoredTableActionButton } from '@/components/settings/team/allTeamMembers/allTeamMembers.style';

// Extracted component because it has been showing rule of hooks error
export function TableButton() {
    const { onUserAdd } = useTeamContext();
    return (
        <ColoredTableActionButton
            shape="circle"
            aria-label="Add new user"
            icon={<PlusOutlined />}
            onClick={onUserAdd}
        />
    );
}
