import { string } from 'prop-types';
import InformationTooltip from '@/components/informationTooltip/informationTooltip';
import {
    ColoredInformationIconWrapper,
    ColoredTitleContainer,
} from '@/components/settings/tabs/panelHeader/panelHeader.styles';
import { GRAY_4 } from '@/constants/colors';

function PanelHeader({ title, tooltip }) {
    return (
        <ColoredTitleContainer>
            <span>{title}</span>
            {tooltip && (
                <ColoredInformationIconWrapper>
                    <InformationTooltip text={tooltip} color={GRAY_4} />
                </ColoredInformationIconWrapper>
            )}
        </ColoredTitleContainer>
    );
}

PanelHeader.defaultProps = { tooltip: '' };

PanelHeader.propTypes = {
    title: string.isRequired,
    tooltip: string,
};

export default PanelHeader;
