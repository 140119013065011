export const filter = (selectedUsers, searchValue) => (item) => {
    const excludeCurrentUser =
        selectedUsers.length > 0 &&
        selectedUsers.some((user) => item.id && user?.userId === item.id);

    if (excludeCurrentUser) return false;

    if (item.firstName && item.lastName) {
        return `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(searchValue.toLowerCase().trim());
    }

    return false;
};
