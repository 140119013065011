export const DATE_ZONE_EVENT_FETCH_START = 'DATE_EVENTS/FETCH_START';
export const DATE_ZONE_EVENT_FETCH_FAILURE = 'DATE_EVENTS/FETCH_FAILURE';
export const DATE_ZONE_EVENT_FETCH_SUCCESS = 'DATE_EVENTS/FETCH_SUCCESS';

export const DATE_ZONE_UPDATE_START = 'DATE_ZONE_UPDATE_START';
export const DATE_ZONE_UPDATE_FAILURE = 'DATE_ZONE_UPDATE_FAILURE';
export const DATE_ZONE_UPDATE_SUCCESS = 'DATE_ZONE_UPDATE_SUCCESS';

export const fetchDateZoneStart = () => ({
    type: DATE_ZONE_EVENT_FETCH_START,
});

export const fetchDateZoneSuccess = (zone) => ({
    type: DATE_ZONE_EVENT_FETCH_SUCCESS,
    payload: {
        zone,
    },
});

export const fetchDateZoneFailure = (err, defaultZone) => ({
    type: DATE_ZONE_EVENT_FETCH_FAILURE,
    payload: {
        error: err,
        zone: defaultZone,
    },
});

export const updateDateZoneStart = (zone) => ({
    type: DATE_ZONE_UPDATE_START,
    payload: {
        zone,
    },
});

export const updateDateZoneFailure = (err) => ({
    type: DATE_ZONE_UPDATE_FAILURE,
    payload: {
        error: err,
    },
});

export const updateDateZoneSuccess = (zone) => ({
    type: DATE_ZONE_UPDATE_SUCCESS,
    payload: {
        zone,
    },
});
