import React from 'react';

function InfoIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="none"
            viewBox="0 0 12 12"
        >
            <rect width="12" height="12" fill="#4F4F4F" rx="6" />
            <path
                fill="#fff"
                d="M6 4.25c.547 0 1-.438 1-1 0-.547-.453-1-1-1-.563 0-1 .453-1 1 0 .563.438 1 1 1zm.75 4.266v-2.75a.75.75 0 00-.75-.75L5.25 5a.75.75 0 00-.75.75.727.727 0 00.734.75l.016 2.016a.74.74 0 00-.75.75c0 .406.328.734.75.734h1.5c.406 0 .75-.328.75-.734a.75.75 0 00-.75-.75z"
            />
        </svg>
    );
}

export default InfoIcon;
