export const COMMENTS_SORT_VARIANTS = {
    commentsAmount: 'commentsAmount',
    topics: 'topics',
    speakers: 'speakers',
    time: 'time',
};

export const COMMENTS_FILTER_DATA = {
    sort: {
        name: 'sortBy',
        options: [
            {
                title: 'Time',
                value: COMMENTS_SORT_VARIANTS.time,
            },
            {
                title: 'Number of Comments',
                value: COMMENTS_SORT_VARIANTS.commentsAmount,
            },
            { title: 'Number of Topics', value: COMMENTS_SORT_VARIANTS.topics },
            { title: 'Speaker', value: COMMENTS_SORT_VARIANTS.speakers },
        ],
        outerLabel: 'Sort by:',
    },
};
