import { mean } from '@/utils/math/mean';
import { getSentencesSentiments } from '@/utils/conversations/analytics/sentiment/sentiment';

const groupEverySecondValue = (array) =>
    array.reduce(
        (acc, current, index) =>
            index % 2 === 0
                ? [...acc, [current]]
                : acc.map((v, i) =>
                    i !== acc.length - 1 ? v : [...v, current],
                ),
        [],
    );

const groupEverySecondExceptLast3 = (array) =>
    array.reduce(
        (acc, current, index) =>
            index % 2 === 0 && index - 1 < array.length - 3
                ? [...acc, [current]]
                : acc.map((v, i) =>
                    i !== acc.length - 1 ? v : [...v, current],
                ),
        [],
    );

/**
 x = the number of sentences in a given statement
 If x = 1, just take the data point for the sentiment for that sentence
 If x = 2, average the sentiments for the two sentences
 If x = 3, average the sentiment for the three sentences
 If x > 3:
 If x % 2 = 0, take a data point every two sentences by averaging the two sentences together
 If x % 2 = 1, take a data point every two sentences except for the last three sentences of the statement, which will be averaged together as one
 * * */

export const getSentimentScoreArray = (words, sentimentData) => {
    const sentences = words
        .split('.')
        .filter((sentence) => sentence.length !== 0);
    const sentencesWithSentiment = getSentencesSentiments(
        sentences,
        sentimentData,
    );
    const sentimentScoreArray = sentencesWithSentiment.map(
        ({ sentiment }) => sentiment.score,
    );

    let sentimentScoreResult = [];
    const { length } = sentimentScoreArray;

    if (length === 0) {
        sentimentScoreResult = [];
    } else if (length <= 3) {
        sentimentScoreResult = [mean(sentimentScoreArray)];
    } else if (length % 2 === 0) {
        // Average every second value
        const groupedResults = groupEverySecondValue(sentimentScoreArray);
        sentimentScoreResult = groupedResults.reduce(
            (acc, current) => [...acc, mean(current)],
            [],
        );
    } else {
        const groupedResults = groupEverySecondExceptLast3(sentimentScoreArray);
        sentimentScoreResult = groupedResults.reduce(
            (acc, current) => [...acc, mean(current)],
            [],
        );
    }

    return sentimentScoreResult;
};
