import { FETCH_STATUS } from '@/constants/fetchStatus';
import { CONVERSATION } from '@/redux/actions/conversation/conversation';

export const initialState = {
    data: {
        id: null,
        title: null,
        transcriptId: null,
        transcriptionStatus: null,
        gemClassifierStatus: null,
        createdDate: null,
        takeaways: null,
        sharePassword: null,
        sharedViewsCount: 0,
        sharedTo: [],
        sharedToAll: [],
        owner: null,
        summarization: null,
        shortSummarization: null,
        meetingNote: null,
        isModalVisible: false,
    },
    status: FETCH_STATUS.IDLE,
    error: null,
};

function conversationReducer(state = initialState, action) {
    switch (action.type) {
        case CONVERSATION.GUEST_FETCH_START:
        case CONVERSATION.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case CONVERSATION.FETCH_SUCCESS: {
            const { data } = action.payload;

            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADED,
                data: {
                    id: data.id,
                    title: data.title,
                    transcriptId: data.transcript_id,
                    transcriptionStatus: data.transcription_status,
                    gemClassifierStatus: data.gem_classifier_status,
                    createdDate: data.created_at,
                    takeaways: data.takeaways,
                    sharePassword: data.share_password,
                    isDownloadable: data.is_downloadable,
                    sharedViewsCount: data.share_views,
                    sharedTo: data.shared_to?.map(({ id }) => id) || [],
                    sharedToAll: data.shared_to || [],
                    owner: data.owner,
                    summarization: data.summarization,
                    shortSummarization: data.short_summarization,
                    meetingNote: data.meeting_note,
                },
                error: null,
            };
        }
        case CONVERSATION.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case CONVERSATION.UPDATE_SHARED_TO.START:
        case CONVERSATION.UPDATE_IS_DOWNLOADABLE.START:
        case CONVERSATION.UPDATE_PASSWORD.START:
        case CONVERSATION.GENERATE_SUMMARIZATION.START:
        case CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.START:
        case CONVERSATION.UPDATE_TITLE.START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
            };
        }
        case CONVERSATION.UPDATE_IS_DOWNLOADABLE.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    isDownloadable: action.payload.isDownloadable,
                },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case CONVERSATION.UPDATE_TITLE.SUCCESS: {
            return {
                ...state,
                data: { ...state.data, title: action.payload.title },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case CONVERSATION.UPDATE_PASSWORD.SUCCESS: {
            return {
                ...state,
                data: { ...state.data, sharePassword: action.payload.password },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case CONVERSATION.GENERATE_SUMMARIZATION.SUCCESS: {
            return {
                ...state,
                data: { ...state.data, summarization: action.payload.summarization.summarization, shortSummarization: action.payload.summarization.short_summarization },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case CONVERSATION.UPDATE_SHARED_TO.SUCCESS: {
            return {
                ...state,
                data: { ...state.data, sharedTo: action.payload.ids },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case CONVERSATION.UPDATE_SHARED_TO_ALL.SUCCESS: {
            return {
                ...state,
                data: { ...state.data, sharedToAll: [
                    ...state.data.sharedToAll,
                    ...action.payload.ids,
                ] },
            };
        }
        case CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.SUCCESS: {
            return {
                ...state,
                data: {
                    ...state.data,
                    sharedTo: state.data.sharedTo.filter(
                        (id) => id !== action.payload.id,
                    ),
                    sharedToAll: state.data.sharedToAll.filter(
                        (item) => (item && item.id !== undefined) ? item.id !== action.payload.id : true,
                    ),
                },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case CONVERSATION.UPDATE_PASSWORD.FAILURE:
        case CONVERSATION.UPDATE_IS_DOWNLOADABLE.FAILURE:
        case CONVERSATION.UPDATE_SHARED_TO.FAILURE:
        case CONVERSATION.GENERATE_SUMMARIZATION.FAILURE:
        case CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.FAILURE:
        case CONVERSATION.UPDATE_TITLE.FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
            };
        }
        case CONVERSATION.UPDATE_MEEETING_NOTE: {
            return {
                ...state,
                data: { ...state.data, meetingNote: action.payload.meetingNote },
            };
        }
        case CONVERSATION.UPDATE_IS_MODAL_VISIBLE: {
            return {
                ...state,
                data: { ...state.data, isModalVisible: action.payload.isModalVisible },
            };
        }
        default:
            return state;
    }
}

export default conversationReducer;
