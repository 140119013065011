import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { TEAM_MEMBERS_KEY, USERS_KEY } from '@/constants/reduxKeys';
import teamMembersReducer from '@/redux/reducers/teamMembersReducer';
import teamMembersSaga from '@/redux/sagas/teamMembersSaga';
import { fetchTeamMembersSuccess } from '@/redux/actions/teamMembersAction';
import usersSaga from '@/redux/sagas/usersSaga';

// eslint-disable-next-line react/prop-types
export default function TeamMembersInjector({ teamMembers }) {
    useInjectReducer({ key: TEAM_MEMBERS_KEY, reducer: teamMembersReducer });
    useInjectSaga({ key: TEAM_MEMBERS_KEY, saga: teamMembersSaga });
    useInjectSaga({ key: USERS_KEY, saga: usersSaga });

    const callRef = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (teamMembers && !callRef.current) {
            dispatch(fetchTeamMembersSuccess(teamMembers));
            callRef.current = true;
        }
    }, [dispatch, teamMembers]);

    return null;
}
