import { SUBSCRIPTION_STATUS } from '@/constants/subscriptionStatus';
import {
    AUTH_GET_SUBSCRIPTION,
    AUTH_SUBSCRIPTION_FETCH_FAILURE,
} from '@/redux/actions/_exports';
import {
    SUBSCRIPTION_GET_SUBSCRIPTION_FAILED,
    SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_START,
    SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_SUCCESSFUL,
    SUBSCRIPTION_RESET_SCHEDULED_CHANGES,
    SUBSCRIPTION_UPDATE_PAYMENT_START,
    SUBSCRIPTION_UPDATE_PAYMENT_FAILED,
    SUBSCRIPTION_RENEW_SUBSCRIPTION_START,
    SUBSCRIPTION_RENEW_SUBSCRIPTION_FAILED,
    SUBSCRIPTION_BUY_SUBSCRIPTION_START,
    SUBSCRIPTION_BUY_SUBSCRIPTION_FAILED,
    SUBSCRIPTION_GOT_HOSTED_PAGE,
    SUBSCRIPTION_CANCEL_HOSTED_PAGE,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_START,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_FAILED,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_CANCELLED_SUBSCRIPTION,
    SUBSCRIPTION_BUY_ADDON_START,
    SUBSCRIPTION_BUY_ADDON_FAILED,
    SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY,
    SUBSCRIPTION_PAYMENT_FAILURE,
    SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_START,
    SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_FAILURE,
    SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_SUCCESSFULLY,
    SUBSCRIPTION_ADD_SEATS_PROCESS_SET_SEATS,
    SUBSCRIPTION_COMPUTE_PRICE_START,
    SUBSCRIPTION_COMPUTED_PRICE,
    SUBSCRIPTION_COMPUTE_PRICE_FAILURE,
    SUBSCRIPTION_ADD_SEATS_RESET_PROCESS,
    SUBSCRIPTION_CANCEL_SUBSCRIPTION_START,
    SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILURE,
    SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESSFULLY,
    SUBSCRIPTION_CHANGE_PLAN_START,
    SUBSCRIPTION_CHANGE_PLAN_FAILED,
    SUBSCRIPTION_CHANGE_PLAN_SUCCESSFULLY,
} from '@/redux/actions/subscriptionAction';

export const initialState = {
    status: SUBSCRIPTION_STATUS.IDLE,
    error: false,
    subscription: null,
    hostedPage: null,
    scheduledChanges: null,
    addSeatsProcess: {
        additionalSeats: 1,
    },
    computedPrice: {
        initial_charge_amount: 0,
        initial_charge_total: 0,
        recurring_charge_amount: 0,
        credits_total: 0,
    },
};

function subscriptionReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_GET_SUBSCRIPTION: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                subscription: action.payload.data,
            };
        }
        case AUTH_SUBSCRIPTION_FETCH_FAILURE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_SCHEDULED_CHANGES_LOADING,
            };
        }
        case SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_SUCCESSFUL: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                scheduledChanges: action.payload.data,
            };
        }
        case SUBSCRIPTION_RESET_SCHEDULED_CHANGES: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                scheduledChanges: null,
            };
        }
        case SUBSCRIPTION_GET_SUBSCRIPTION_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_UPDATE_PAYMENT_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.UPDATE_PAYMENT_START,
            };
        }
        case SUBSCRIPTION_RENEW_SUBSCRIPTION_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.RENEW_SUBSCRIPTION_START,
            };
        }
        case SUBSCRIPTION_BUY_SUBSCRIPTION_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.BUY_SUBSCRIPTION_START,
            };
        }
        case SUBSCRIPTION_CANCEL_HOSTED_PAGE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                hostedPage: initialState.hostedPage,
            };
        }
        case SUBSCRIPTION_GOT_HOSTED_PAGE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.GOT_HOSTED_PAGE,
                hostedPage: action.payload,
            };
        }
        case SUBSCRIPTION_UPDATE_PAYMENT_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_RENEW_SUBSCRIPTION_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_BUY_SUBSCRIPTION_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.UPDATE_SUBSCRIPTION_SEATS_START,
            };
        }
        case SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                subscription: action.payload.data,
                addSeatsProcess: initialState.addSeatsProcess,
            };
        }
        case SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_CANCELLED_SUBSCRIPTION: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.UPDATE_SEATS_FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_BUY_ADDON_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.BUY_ADDON_START,
            };
        }
        case SUBSCRIPTION_BUY_ADDON_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
            };
        }
        case SUBSCRIPTION_CHANGE_PLAN_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.CHANGE_PLAN_START,
            };
        }
        case SUBSCRIPTION_CHANGE_PLAN_FAILED: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.CHANGE_PLAN_FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_CHANGE_PLAN_SUCCESSFULLY: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                subscription: action.payload.data,
            };
        }
        case SUBSCRIPTION_CANCEL_SUBSCRIPTION_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.CANCEL_SUBSCRIPTION_START,
            };
        }
        case SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.CANCEL_SUBSCRIPTION_FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESSFULLY: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                subscription: action.payload.data,
            };
        }
        case SUBSCRIPTION_PAYMENT_FAILURE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.PAYMENT_FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.REACTIVATE_SUBSCRIPTION_START,
            };
        }
        case SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_FAILURE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_SUCCESSFULLY: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                subscription: action.payload.data,
            };
        }
        case SUBSCRIPTION_ADD_SEATS_PROCESS_SET_SEATS: {
            return {
                ...state,
                addSeatsProcess: {
                    ...state.addSeatsProcess,
                    additionalSeats: action.payload.data,
                },
            };
        }
        case SUBSCRIPTION_COMPUTE_PRICE_START: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.COMPUTE_PRICE_START,
            };
        }
        case SUBSCRIPTION_COMPUTED_PRICE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.SUBSCRIPTION_INFO_LOADED,
                computedPrice: action.payload.data,
            };
        }
        case SUBSCRIPTION_COMPUTE_PRICE_FAILURE: {
            return {
                ...state,
                status: SUBSCRIPTION_STATUS.COMPUTE_FAILURE,
                error: true,
            };
        }
        case SUBSCRIPTION_ADD_SEATS_RESET_PROCESS: {
            return {
                ...state,
                addSeatsProcess: initialState.addSeatsProcess,
                computedPrice: initialState.computedPrice,
            };
        }

        default:
            return state;
    }
}

export default subscriptionReducer;
