const getWordsInDisplayFormatOfContentItem = (
    contentItem,
    assemblyAiWords,
    wordIndexInitial,
) => {
    const { startTimeMs, endTimeMs, id } = contentItem;

    const contentWords = contentItem.words.split(/(\s+)/);

    const filterWords = assemblyAiWords.filter(
        (assemblyAiWord) =>
            assemblyAiWord.start >= startTimeMs &&
            assemblyAiWord.end <= endTimeMs,
    );

    const result = contentWords.reduce(
        ({ words, index }, contentWord, indexOfArray) => {
            const word = filterWords.find(
                (assemblyAiWord) =>
                    assemblyAiWord.text === contentWord &&
                    (words[words.length - 1]?.startInMs
                        ? words[words.length - 1].startInMs <
                          assemblyAiWord.start
                        : startTimeMs === assemblyAiWord.start),
            );
            if (word) {
                return {
                    words: [
                        ...words,
                        {
                            text: word.text,
                            startInMs: word.start,
                            endInMs: word.end,
                            startIndex: index + 1,
                            endIndex: index + word.text.length,
                            wordIndex: wordIndexInitial + indexOfArray + 1,
                            contentId: id,
                        },
                    ],
                    index: index + word.text.length,
                };
            }
            return {
                words: [
                    ...words,
                    {
                        text: contentWord,
                        startInMs:
                            words[words.length - 1]?.startInMs ?? startTimeMs,
                        endInMs:
                            words[words.length - 1]?.endInMs ??
                            filterWords[0]?.end,
                        startIndex: index + 1,
                        endIndex: index + contentWord.length,
                        wordIndex: wordIndexInitial + indexOfArray + 1,
                        contentId: id,
                    },
                ],
                index: index + contentWord.length,
            };
        },
        {
            words: [],
            index: -1,
        },
    ).words;
    return result;
};

export const getWordsInDisplayFormat = (transcriptContent, assemblyAiWord) =>
    transcriptContent.reduce(
        (words, contentItem) => [
            ...words,
            ...getWordsInDisplayFormatOfContentItem(
                contentItem,
                assemblyAiWord,
                words.length - 1,
            ),
        ],
        [],
    );
