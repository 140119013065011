import styled from 'styled-components';
import { Modal, Button } from 'antd';
import { GRAY_2, VERSATIONAL_3, VERSATIONAL_2 } from '@/constants/colors';
import SimpleBar from 'simplebar-react';

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    height: 100%;
`;

const ModalDescriptor = styled.div`
    display: flex;
    gap: 1rem;
    font-size: 1rem;
    line-height: 1.25rem;
    color: ${GRAY_2};
    background-color: ${VERSATIONAL_3};
    border: 1px solid ${VERSATIONAL_2};
    width: 100%;
    padding: 1rem;

    > p {
        margin: 0;
    }
`;

const ViewButton = styled(Button)`
    width: 100%;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 500;
`;

export const ColoredSimpleBarReact = styled(SimpleBar)`
    max-height: 85vh;
    padding: 1rem 2rem;
`;

const StyledModal = styled(Modal)`
    .ant-modal-body {
        padding: 0 !important;
    }
`;

const FooterFlex = styled.div`
    display: flex;
    align-items: center;
`;

export { ModalContainer, ModalDescriptor, ViewButton, StyledModal, FooterFlex };
