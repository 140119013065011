export const CALENDAR_EVENTS_FETCH_START = 'CALENDAR_EVENTS/FETCH_START';
export const CALENDAR_EVENTS_FETCH_SUCCESS =
    'CALENDAR_EVENTS/FETCH_START_SUCCESS';
export const CALENDAR_EVENTS_FETCH_FAILURE =
    'CALENDAR_EVENTS/FETCH_START_FAILURE';

export const CALENDAR_EVENTS_ID_FETCH_START = 'CALENDAR_EVENTS_ID/FETCH_START';
export const CALENDAR_EVENTS_ID_FETCH_SUCCESS =
    'CALENDAR_EVENTS_ID/FETCH_START_SUCCESS';
export const CALENDAR_EVENTS_ID_FETCH_FAILURE =
    'CALENDAR_EVENTS_ID/FETCH_START_FAILURE';
export const CALENDAR_EVENTS_CLEAR_EVENT_DATA =
    'CALENDAR_EVENTS_ID/CLEAR_EVENT_DATA';

export function fetchEventsStart(dateStart, dateEnd) {
    return {
        type: CALENDAR_EVENTS_FETCH_START,
        payload: {
            dateStart,
            dateEnd,
        },
    };
}

export function fetchEventsSuccess(events) {
    return {
        type: CALENDAR_EVENTS_FETCH_SUCCESS,
        payload: {
            events,
        },
    };
}

export function fetchEventsFailure() {
    return {
        type: CALENDAR_EVENTS_FETCH_FAILURE,
    };
}

export function fetchEventsIdStart(id) {
    return {
        type: CALENDAR_EVENTS_ID_FETCH_START,
        payload: {
            id,
        },
    };
}

export function clearEventData() {
    return {
        type: CALENDAR_EVENTS_CLEAR_EVENT_DATA,
    };
}

export function fetchEventsIdSuccess(event) {
    return {
        type: CALENDAR_EVENTS_ID_FETCH_SUCCESS,
        payload: {
            event,
        },
    };
}

export function fetchEventsIdFailure() {
    return {
        type: CALENDAR_EVENTS_ID_FETCH_FAILURE,
    };
}
