import { bool } from 'prop-types';
import { DatePicker } from 'antd';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';
import dayjs from 'dayjs';
import objectSupport from 'dayjs/plugin/objectSupport';
import { useSelector } from 'react-redux';
import {
    BackButton,
    EditableContainer,
    HeaderTime,
    PencilIcon,
    TitleContainer,
    TitleWrapper,
} from '@/components/conversations/conversations/hooks/pageTitle.styles';
import { ROUTES } from '@/constants/routes';
import { dayjsToMoment, momentToDayjs } from '@/utils/dates/dayj';
import { useConversationContext } from '@/components/conversations/conversations/context/context';

dayjs.extend(objectSupport);

export default function PageTitle({ minimal }) {
    const router = useRouter();
    const {
        title,
        setConversationTitle,
        conversationDate,
        updateConversationDate,
        storeConversationTitle,
    } = useConversationContext();

    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [isLaunchedFromSameDomain, setLaunchedFromSameDomain] =
        useState(false);
    const titleInputRef = useRef(null);
    const { zone } = useSelector((state) => state.date || {});

    useEffect(() => {
        setLaunchedFromSameDomain(window?.history?.state?.idx > 0);
    }, []);

    const checkAndRoundDate = (date) => {
        if (dayjs(date).isValid()) return dayjs(date);

        let currentDate = dayjs();
        // Round current date to the nearest 15 minutes
        const minutes = currentDate.minute();
        const remainder = 15 - (minutes % 15);
        if (remainder < 8) currentDate = currentDate.add(remainder, 'minute');
        else currentDate = currentDate.subtract((15 - remainder), 'minute');
        return currentDate.startOf('minute');
    }

    return (
        <TitleContainer>
            {!minimal ? <BackButton
                onClick={() => {
                    if (isLaunchedFromSameDomain) {
                        router.back();
                    } else {
                        router.push(
                            `${
                                ROUTES.CALENDAR_DASHBOARD
                            }?date=${conversationDate?.format('YYYY-MM-DD')}`,
                        );
                    }
                }}
            /> : ''}

            <div>
                <EditableContainer>
                    <TitleWrapper
                        ref={titleInputRef}
                        value={title}
                        placeholder="Enter title"
                        onChange={(e) => setConversationTitle(e.target.value)}
                        onBlur={() => {
                            storeConversationTitle();
                        }}
                        size={title?.length < 50 ? title?.length : 50}
                    />
                    <PencilIcon
                        onClick={() => {
                            titleInputRef.current.focus();
                        }}
                    />
                </EditableContainer>
                <EditableContainer>
                    <HeaderTime>
                        <DatePicker
                            placeholder="Enter date"
                            showTime
                            format="dddd, MMMM Do h:mm A"
                            open={datePickerOpen}
                            onOpenChange={(openState) =>
                                setDatePickerOpen(openState)
                            }
                            value={
                                conversationDate
                                    ? dayjsToMoment(checkAndRoundDate(conversationDate))
                                    : null
                            }
                            onChange={(value) => {
                                const withoutTzDayjs = momentToDayjs(value);
                                updateConversationDate(
                                    withoutTzDayjs.tz(zone, true),
                                );
                            }}
                        />
                    </HeaderTime>
                    <PencilIcon
                        onClick={() => {
                            setDatePickerOpen(true);
                        }}
                    />
                </EditableContainer>
            </div>
        </TitleContainer>
    );
}

PageTitle.propTypes = {
    minimal: bool,
};

PageTitle.defaultProps = {
    minimal: false,
};
