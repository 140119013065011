export const getContentWithLikeModified = (
    content,
    contentIndex,
    quoteIndex,
    likeId,
    userId,
) => {
    const newContentQuote = [...content];
    const newContentItem = {
        ...newContentQuote[contentIndex],
    };
    const newQuotes = [...newContentItem.quotes];
    const newQuote = {
        ...newQuotes[quoteIndex],
    };
    const newComments = [...newQuote.comments];
    const newCommentIndex = newComments.findIndex(
        ({ id: commentId }) => commentId === likeId,
    );

    const newComment = {
        ...newComments[newCommentIndex],
    };

    const userLikeIndex = newComment.likes.findIndex((c) => c.id === userId);

    if (userLikeIndex >= 0) {
        newComment.likes = newComment.likes.filter(
            (like) => like.id !== userId,
        );
        newComment.votes -= 1;
    } else {
        newComment.likes.push({ id: userId });
        newComment.votes += 1;
    }

    newComment.voted = newComment.votes > 0;
    newComments[newCommentIndex] = newComment;
    newQuotes[quoteIndex].comments = newComments;
    newContentItem.quotes = newQuotes;
    newContentQuote[contentIndex] = newContentItem;

    return newContentQuote;
};
