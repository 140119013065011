import {
    deleteFailure,
    deleteStart,
    deleteSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';

export const USER_UPDATE_START = 'USERS/UPDATE_START';
export const USER_UPDATE_SUCCESS = 'USERS/UPDATE_SUCCESS';
export const USER_UPDATE_FAILURE = 'USERS/UPDATE_FAILURE';

export const USER_DELETE_START = 'USERS/DELETE_START';
export const USER_DELETE_SUCCESS = 'USERS/DELETE_SUCCESS';
export const USER_DELETE_FAILURE = 'USERS/DELETE_FAILURE';

export const USER_ARCHIVE_START = 'USERS/ARCHIVE_START';
export const USER_ARCHIVE_SUCCESS = 'USERS/ARCHIVE_SUCCESS';
export const USER_ARCHIVE_FAILURE = 'USERS/ARCHIVE_FAILURE';

export const USER_REACTIVATE_START = 'USERS/REACTIVATE_START';
export const USER_REACTIVATE_SUCCESS = 'USERS/REACTIVATE_SUCCESS';
export const USER_REACTIVATE_FAILURE = 'USERS/REACTIVATE_FAILURE';

export const userUpdateStart = (data) => updateStart(USER_UPDATE_START, data);
export const userUpdateFailure = (error) =>
    updateFailure(USER_UPDATE_FAILURE, error);
export const userUpdateSuccess = (data) =>
    updateSuccess(USER_UPDATE_SUCCESS, data);

export const userDeleteStart = (id) => deleteStart(USER_DELETE_START, id);
export const userDeleteSuccess = (id) => deleteSuccess(USER_DELETE_SUCCESS, id);
export const userDeleteFailure = (id, error) =>
    deleteFailure(USER_DELETE_FAILURE, id, error);

export const userArchiveStart = (id) => ({
    type: USER_ARCHIVE_START,
    payload: { id },
});

export const userArchiveSuccess = (id) => ({
    type: USER_ARCHIVE_SUCCESS,
    payload: { id },
});

export const userArchiveFailure = (id, error) => ({
    type: USER_ARCHIVE_FAILURE,
    payload: { id, error },
});

export const userReactivateStart = (id) => ({
    type: USER_REACTIVATE_START,
    payload: { id },
});

export const userReactivateSuccess = (id) => ({
    type: USER_REACTIVATE_SUCCESS,
    payload: { id },
});

export const userReactivateFailure = (id, error) => ({
    type: USER_REACTIVATE_FAILURE,
    payload: { id, error },
});
