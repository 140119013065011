export const getAlreadyUsedBrowserDbStoragePercentage = async () => {
    if (navigator.storage && navigator.storage.estimate) {
        const quota = await navigator.storage.estimate();
        return (quota.usage / quota.quota) * 100;
    }
    throw new Error('Storage estimation not supported');
};

export const enablePersistence = async (onError) => {
    if (navigator.storage && navigator.storage.persist) {
        navigator.storage.persist().then((persistent) => {
            if (persistent) {
                // eslint-disable-next-line no-console
                console.warn(
                    'Storage will not be cleared except by explicit user action',
                );
            } else {
                onError();
            }
        });
    } else {
        onError();
    }
};

export const isPersisted = async () => {
    if (navigator.storage && navigator.storage.persist)
        return navigator.storage.persisted();
    return false;
};
