import styled from 'styled-components';

const ColoredInformationIconWrapper = styled.span`
    display: flex;
    align-items: center;

    & > * {
        height: 14px;
        width: 14px;
    }
`;

const ColoredTitleContainer = styled.span`
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    font-size: 1rem;
`;

export { ColoredInformationIconWrapper, ColoredTitleContainer };
