import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    TEAM_MEMBER_DELETE_FAILURE,
    TEAM_MEMBER_DELETE_START,
    TEAM_MEMBER_DELETE_SUCCESS,
    TEAM_MEMBER_UPDATE_FAILURE,
    TEAM_MEMBER_UPDATE_START,
    TEAM_MEMBER_UPDATE_SUCCESS,
    TEAM_MEMBERS_FETCH_FAILURE,
    TEAM_MEMBERS_FETCH_START,
    TEAM_MEMBERS_FETCH_SUCCESS,
    TEAM_MEMBERS_FETCH_METRICS_START,
    TEAM_MEMBERS_FETCH_METRICS_SUCCESS,
    TEAM_MEMBERS_TOGGLE_ARCHIVED,
} from '@/redux/actions/teamMembersAction';

export const initialState = {
    data: null,
    metrics: null,
    status: FETCH_STATUS.IDLE,
    error: null,
    hideArchivedTeamMembers: true,
};

function teamMembersReducer(state = initialState, action) {
    switch (action.type) {
        case TEAM_MEMBERS_FETCH_START: {
            const status =
                state.status === FETCH_STATUS.IDLE
                    ? FETCH_STATUS.INITIAL_LOADING
                    : FETCH_STATUS.REFETCH_LOADING;

            return {
                ...state,
                error: null,
                status,
            };
        }
        case TEAM_MEMBERS_FETCH_SUCCESS: {
            const status =
                state.status === FETCH_STATUS.INITIAL_LOADING
                    ? FETCH_STATUS.INITIAL_LOADED
                    : FETCH_STATUS.REFETCH_SUCCESS;

            return {
                ...state,
                data: action.payload.data,
                status,
                error: null,
            };
        }
        case TEAM_MEMBERS_FETCH_FAILURE: {
            const status =
                state.status === FETCH_STATUS.INITIAL_LOADING
                    ? FETCH_STATUS.INITIAL_ERROR
                    : FETCH_STATUS.REFETCH_ERROR;

            return {
                ...state,
                status,
                error: action.payload.error,
            };
        }
        case TEAM_MEMBER_UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case TEAM_MEMBER_UPDATE_SUCCESS: {
            const updatedMember = action.payload.data;
            return {
                ...state,
                data: state.data.map((member) => {
                    if (member.id === updatedMember.id)
                        return { ...member, ...updatedMember };
                    return member;
                }),
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case TEAM_MEMBER_UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case TEAM_MEMBER_DELETE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
            };
        }
        case TEAM_MEMBER_DELETE_SUCCESS: {
            const deletedMemberId = action.payload.data;

            return {
                ...state,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                data: state.data.filter((v) => v.id !== deletedMemberId),
            };
        }
        case TEAM_MEMBER_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
                status: FETCH_STATUS.UPDATE_ERROR,
            };
        }
        case TEAM_MEMBERS_FETCH_METRICS_START: {
            return {
                ...state,
            };
        }
        case TEAM_MEMBERS_FETCH_METRICS_SUCCESS: {
            return {
                ...state,
                metrics: action.payload.data,
            };
        }
        case TEAM_MEMBERS_TOGGLE_ARCHIVED: {
            return {
                ...state,
                hideArchivedTeamMembers: !state.hideArchivedTeamMembers,
            };
        }
        default:
            return state;
        
    }
}

export default teamMembersReducer;
