import styled from 'styled-components';
import { GRAY_1 } from '@/constants/colors';

const ProfileContainer = styled.div`
    display: flex;
    gap: 1.5rem;
    align-items: center;
`;

const InfoContainer = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 1rem;
    color: ${GRAY_1};
    width: 144px;
`;
const Name = styled.p`
    margin: 0;
    font-size: 1rem;
    font-weight: bold;
    text-overflow: ellipsis;
    overflow: hidden;
`;

const Position = styled.p`
    font-size: 0.75rem;
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
`;

export { ProfileContainer, InfoContainer, Name, Position };
