export const MEDIA_STATUS = Object.freeze({
    IDLE: 'idle',
    ACQUIRING_MEDIA: 'acquiring_media',
    RECORDING: 'recording',
    PAUSED: 'paused',
    STOPPED: 'stopped',
    STOPPING: '"stopping"',
});

export const ERROR_STATUS = Object.freeze({
    NONE: '',
    NO_RECORDER: 'NO_RECORDER',
});
