import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
    getHasSubscription,
    getIsAuthorized,
} from '@/redux/selectors/authSelector';
import { getIsInitialStep } from '@/redux/selectors/signupProcessSelector';
import {
    getIsCancelled,
    getSubscriptionErrorStatus,
} from '@/redux/selectors/subscriptionSelector';

export const useShowNoSubscriptionModal = () => {
    const hasSubscription = useSelector(getHasSubscription);
    const isError = useSelector(getSubscriptionErrorStatus);
    const isNotDuringSignupProcess = useSelector(getIsInitialStep);
    const isAuthorized = useSelector(getIsAuthorized);
    const isCancelled = useSelector(getIsCancelled);

    const showNoSubscriptionModal = useMemo(
        () =>
            ((!hasSubscription && isNotDuringSignupProcess) ||
                isError ||
                isCancelled) &&
            isAuthorized,
        [
            hasSubscription,
            isAuthorized,
            isCancelled,
            isError,
            isNotDuringSignupProcess,
        ],
    );

    return showNoSubscriptionModal;
};
