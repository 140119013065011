import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';

export const SETTINGS_RECORDINGS_FETCH_START =
    'SETTINGS_RECORDINGS/FETCH_START';
export const SETTINGS_RECORDINGS_FETCH_SUCCESS =
    'SETTINGS_RECORDINGS/FETCH_SUCCESS';
export const SETTINGS_RECORDINGS_FETCH_FAILURE =
    'SETTINGS_RECORDINGS/FETCH_FAILURE';

export const SETTINGS_RECORDINGS_UPDATE_START =
    'SETTINGS_RECORDINGS/UPDATE_START';
export const SETTINGS_RECORDINGS_UPDATE_SUCCESS =
    'SETTINGS_RECORDINGS/UPDATE_SUCCESS';
export const SETTINGS_RECORDINGS_UPDATE_FAILURE =
    'SETTINGS_RECORDINGS/UPDATE_FAILURE';
export const SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_TOGGLE =
    'SETTINGS_RECORDINGS/MIC_RECORDING_PANEL_VISIBLE_TOGGLE';
export const SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_ACTIVE =
    'SETTINGS_RECORDINGS/MIC_RECORDING_PANEL_VISIBLE_ACTIVE';
export const SETTINGS_RECORDINGS_IS_MIC_CURRENTLY_RECORDING =
    'SETTINGS_RECORDINGS/IS_MIC_CURRENTLY_RECORDING';
export const SETTINGS_RECORDINGS_MIC_RECORDING_PROGRESS =
    'SETTINGS_RECORDINGS/MIC_RECORDING_PROGRESS';
export const SETTINGS_RECORDINGS_MIC_RECORDING_STATUS =
    'SETTINGS_RECORDINGS/MIC_RECORDING_STATUS';

export const fetchSettingsRecordings = () =>
    fetchData(SETTINGS_RECORDINGS_FETCH_START);
export const fetchSettingsRecordingsSuccess = (data) =>
    fetchSuccess(SETTINGS_RECORDINGS_FETCH_SUCCESS, data);
export const fetchSettingsRecordingsFailure = (error) =>
    fetchFailure(SETTINGS_RECORDINGS_FETCH_FAILURE, error);
export const settingsRecordingsUpdateStart = (data) =>
    updateStart(SETTINGS_RECORDINGS_UPDATE_START, data);
export const settingsRecordingsUpdateFailure = (error) =>
    updateFailure(SETTINGS_RECORDINGS_UPDATE_FAILURE, error);
export const settingsRecordingsUpdateSuccess = (data) =>
    updateSuccess(SETTINGS_RECORDINGS_UPDATE_SUCCESS, data);
export const settingsRecordingsMicRecordingPanelVisibleToggle = () => ({
    type: SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_TOGGLE,
});
export const settingsRecordingsMicRecordingPanelActive = () => ({
    type: SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_ACTIVE,
});
export const settingsRecordingsIsMicCurrentlyRecording = (data) => ({
    type: SETTINGS_RECORDINGS_IS_MIC_CURRENTLY_RECORDING,
    payload: {
        data,
    },
});
export const settingsRecordingsMicRecordingProgress = (data) => ({
    type: SETTINGS_RECORDINGS_MIC_RECORDING_PROGRESS,
    payload: {
        data,
    },
});
export const settingsRecordingsMicRecordingStatus = (data) => ({
    type: SETTINGS_RECORDINGS_MIC_RECORDING_STATUS,
    payload: {
        data,
    },
});
