import {
    MEETING,
    fetchMeetingIdFailure,
    fetchMeetingIdSuccess,
} from '@/redux/actions/conversation/meeting';
import { call, put, takeLatest } from 'redux-saga/effects';

import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';

function* fetchMeetingById(id) {
    return yield call(axios, NEXT_ROUTES.CONVERSATIONS_ID_MEETING(id));
}

export function* getMeetingId(action) {
    try {
        const { id } = action.payload.data;
        const { data } = yield fetchMeetingById(id);
        yield put(fetchMeetingIdSuccess(data));
    } catch (error) {
        yield put(fetchMeetingIdFailure(error.toString()));
    }
}

export default function* meetingIdSaga() {
    yield takeLatest(MEETING.FETCH_START, getMeetingId);
}
