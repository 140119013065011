import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMemo, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Spin } from 'antd';
import VersationalIconWithName from '@/svg/versationalIconWithName/versationalIconWithName';
import {
    ColoredSimpleBarReact,
    FooterFlex,
    ModalContainer,
    ModalDescriptor,
    StyledModal,
} from '@/components/noSubscriptionModal/noSubscriptionModal.styles';
import { useShowNoSubscriptionModal } from '@/components/noSubscriptionModal/hooks/useShowNoSubscriptionModal';
import {
    getIsAdminSelector,
} from '@/redux/selectors/authSelector';
import {
    getIsCancelled,
    getSubscriptionErrorStatus,
    getSubscriptionPlanCode,
} from '@/redux/selectors/subscriptionSelector';
import { logOut } from '@/redux/actions/authAction';
import { VIVIDORANGE } from '@/constants/colors';
import { ProfilePhoto } from '@/components/settings/layout/profile';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

const RenewPlanWithStateDynamic = dynamic(
    () => import('@/components/noSubscriptionModal/renewPlanWithState'),
    {
        // eslint-disable-next-line react/display-name
        loading: () => <Spin />,
        ssr: false,
    },
);

RenewPlanWithStateDynamic.displayName = 'RenewPlanWithStateDynamic';

function NoSubscriptionModal() {
    const dispatch = useDispatch();

    const isError = useSelector(getSubscriptionErrorStatus);
    const isCancelled = useSelector(getIsCancelled);
    const isAdmin = useSelector(getIsAdminSelector);
    const planCode = useSelector(getSubscriptionPlanCode);

    const router = useRouter();

    const [shouldShowModal, setShouldShowModal] = useState(true);

    const showNoSubscriptionModal = useShowNoSubscriptionModal();

    const errorMessage = useMemo(() => {
        if (isError) {
            return 'There was error during fetching your subscription data. Please refresh the page. If you have already done contact support.';
        }
        if (isAdmin && isCancelled) {
            return 'Your subscription plan needs to be updated to keep using Versational. Please select a plan and renew or modify your subscription details below.';
        }
        if (isAdmin) {
            return 'Your subscription plan needs to be updated to keep using Versational. Please select a plan and start or modify your subscription details below.';
        }
        return 'Your organization has no active subscription. Please contact the administrator of your organization.';
    }, [isAdmin, isCancelled, isError, planCode]);

    const logoutClick = () => {
        dispatch(logOut());
    };

    useEffect(() => {
        function checkURL() {
            if (router?.asPath?.includes('settings?teamSettings')) {
                setShouldShowModal(false);
            } else {
                setShouldShowModal(true);
            }
        }
    
        checkURL();
    
        const handleRouteChange = () => {
            checkURL();
        };
    
        router?.events?.on('routeChangeComplete', handleRouteChange);
    
        return () => {
            router?.events?.off('routeChangeComplete', handleRouteChange);
        };
    }, [router]);

    if (!shouldShowModal) {
        return null;
    }

    return (
        <StyledModal
            visible={showNoSubscriptionModal}
            closable={false}
            maskClosable={false}
            keyboard={false}
            footer={null}
            width={1100}
            destroyOnClose
        >
            <ColoredSimpleBarReact>
                <ModalContainer>
                    <VersationalIconWithName />
                    <ModalDescriptor>
                        <ExclamationCircleOutlined
                            style={{ color: VIVIDORANGE, fontSize: '21px' }}
                        />
                        <p>{errorMessage}</p>
                    </ModalDescriptor>
                    <RenewPlanWithStateDynamic />
                    <FooterFlex>
                        <ProfilePhoto size={32} />
                        <Button type="link" role="button" onClick={logoutClick}>
                            Sign in with a different account
                        </Button>
                    </FooterFlex>
                </ModalContainer>
            </ColoredSimpleBarReact>
        </StyledModal>
    );
}

export default NoSubscriptionModal;
