import { call, put, takeEvery, select } from 'redux-saga/effects';
import { message } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchCustomTagsFailure,
    fetchCustomTagsSuccess,
    CUSTOM_TAGS_FETCH_START,
    CUSTOM_TAGS_CREATE_START,
    CUSTOM_TAGS_UPDATE_START,
    CUSTOM_TAGS_DELETE_START,
    customTagsCreateFailure,
    customTagsCreateSuccess,
    customTagsUpdateSuccess,
    customTagsUpdateFailure,
    customTagsDeleteSuccess,
    customTagsDeleteFailure,
} from '@/redux/actions/customTagsAction';
import { getCustomTags } from '@/redux/selectors/customTagsSelector';

async function fetchCustomTags() {
    return axios.get(NEXT_ROUTES.CUSTOM_TAGS_GET_OWNED);
}

function* getCustomTagsSaga() {
    try {
        const { data } = yield fetchCustomTags();
        yield put(fetchCustomTagsSuccess(data));
    } catch (err) {
        yield put(fetchCustomTagsFailure(err));
    }
}

function* createCustomTag(action) {
    const data = {
        ...action.payload.data,
    };

    try {
        const customTags = yield select(getCustomTags);
        if (customTags.find((tag) => tag.name === data.name)) {
            throw new Error('Custom tag already exist');
        }
        try {
            const { data: createData } = yield call(
                axios.post,
                NEXT_ROUTES.CUSTOM_TAGS,
                data,
            );
            message.success('Successfully added new topic');
            yield put(customTagsCreateSuccess(createData));
            yield call(action.payload.onSuccess, createData);
        } catch (e) {
            message.error('Adding new topic failed');
            yield put(customTagsCreateFailure(e));
        }
    } catch (e) {
        message.error('Topic already exist');
        yield put(customTagsCreateFailure(e));
    }
}

function* updateCustomTag(action) {
    const { id, name, description } = action.payload.data;

    try {
        const customTags = yield select(getCustomTags);
        if (customTags.find((tag) => tag.name === name)) {
            throw new Error('Custom tag already exist');
        }
        try {
            const { data } = yield call(
                axios.patch,
                NEXT_ROUTES.CUSTOM_TAGS_OWNED_ID(id),
                {
                    name,
                    description,
                },
            );
            message.success('Successfully edited topic');
            yield put(customTagsUpdateSuccess(data));
            yield call(action.payload.onSuccess, data);
        } catch (e) {
            message.error('Editing topic failed');
            yield put(customTagsUpdateFailure(e));
        }
    } catch (e) {
        message.error('Topic already exist');
        yield put(customTagsUpdateFailure(e));
    }
}

function* deleteCustomTag(action) {
    const { id } = action.payload.data;

    try {
        yield call(axios.delete, NEXT_ROUTES.CUSTOM_TAGS_OWNED_ID(id));
        message.success('Successfully deleted topic');
        yield put(customTagsDeleteSuccess({ id }));
    } catch (e) {
        message.error('Delete topic failed');
        yield put(customTagsDeleteFailure(e));
    }
}

export default function* customTagsSaga() {
    yield takeEvery(CUSTOM_TAGS_FETCH_START, getCustomTagsSaga);
    yield takeEvery(CUSTOM_TAGS_CREATE_START, createCustomTag);
    yield takeEvery(CUSTOM_TAGS_UPDATE_START, updateCustomTag);
    yield takeEvery(CUSTOM_TAGS_DELETE_START, deleteCustomTag);
}
