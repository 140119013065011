import { func, node } from 'prop-types';
import { createContext, useContext, useMemo } from 'react';

const TeamContext = createContext({});
export const useTeamContext = () => useContext(TeamContext);

export function TeamContextProvider({ onUserAdd, children }) {
    const value = useMemo(() => ({ onUserAdd }), [onUserAdd]);
    return (
        <TeamContext.Provider value={value}>{children}</TeamContext.Provider>
    );
}

TeamContextProvider.propTypes = {
    onUserAdd: func.isRequired,
    children: node.isRequired,
};
