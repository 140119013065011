import { CONVERSATION_RESULT_VIEW_KEY } from '@/constants/reduxKeys';
import { analyticsItems } from '@/components/conversations/results/analytics/analyticsItems';
import { createSelector } from 'reselect';
import { CONVERSATIONS_TABS } from '@/constants/conversations/conversationsTabs';
import { filterSpeakersByType } from '@/utils/conversations/speakers/filterSpeakers';

const getLeftTabPanelKeyInternal = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.leftTabPanelKey;

export const getRightTabPanelKey = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.rightTabPanelKey;

export const getSelectedAnalyticsTile = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.selectedAnalyticsTile;

export const getColumnView = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.columnView;

export const getIsCommentAnalyticsTile = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.selectedAnalyticsTile ===
    analyticsItems.comments.key;

export const getIsQuestionsAnalyticsTile = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.selectedAnalyticsTile ===
    analyticsItems.questions.key;

export const getLeftTabPanelKey = createSelector(
    [getLeftTabPanelKeyInternal],
    (leftTabPanelKey) => {
        if (!leftTabPanelKey) {
            return CONVERSATIONS_TABS.VIDEO;
        }
        return leftTabPanelKey;
    },
);

export const getViewMode = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.viewMode;

export const getSelectedUsers = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.selectedUsers;

export const getTranscriptSelectedUsers = (state) =>
    state?.[CONVERSATION_RESULT_VIEW_KEY]?.transcriptSelectedUsers;

const filterSpeakers = (speakersRange, selectedSpeakers) =>
    Array.isArray(selectedSpeakers)
        ? speakersRange.filter(({ id }) => selectedSpeakers.includes(id))
        : filterSpeakersByType(selectedSpeakers, speakersRange);

const emptyArray = [];

// Webpack is going crazy when I import it from other file
const getTranscriptSpeakersSelector = (state) =>
    state.transcript?.data?.transcript?.speakers || emptyArray;

export const getSelectedSpeakersSelector = createSelector(
    [getTranscriptSpeakersSelector, getSelectedUsers],
    filterSpeakers,
);

export const getTranscriptSelectedSpeakersSelector = createSelector(
    [getTranscriptSpeakersSelector, getTranscriptSelectedUsers],
    filterSpeakers,
);
