import { select, take } from 'redux-saga/effects';

export default function* waitFor(selector) {
    if (yield select(selector)) return; // (1)

    while (true) {
        yield take('*'); // (1a)
        if (yield select(selector)) return; // (1b)
    }
}
