import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    genericAction,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('SETTINGS/GEM_SETTINGS');

export const GEM_SETTINGS = {
    ...creator.fetch(),
    ...creator.update(),
    BULK_ENABLE: creator.custom('BULK_ENABLE'),
    BULK_DISABLE: creator.custom('BULK_DISABLE'),
    SET_SELECTED_TAB: creator.rawCustom('SET_SELECTED_TAB'),
};

export const fetchGemSettings = () => fetchData(GEM_SETTINGS.FETCH_START);
export const fetchGemSettingsSuccess = (data) =>
    fetchSuccess(GEM_SETTINGS.FETCH_SUCCESS, data);
export const fetchGemSettingsFailure = (error) =>
    fetchFailure(GEM_SETTINGS.FETCH_FAILURE, error);

export const updateGemSetting = (data) =>
    updateStart(GEM_SETTINGS.UPDATE_START, data);
export const updateGemSettingSuccess = (data) =>
    updateSuccess(GEM_SETTINGS.UPDATE_SUCCESS, data);
export const updateGemSettingFailure = (error) =>
    updateFailure(GEM_SETTINGS.UPDATE_FAILURE, error);

export const bulkEnableGemSettings = (data) =>
    updateStart(GEM_SETTINGS.BULK_ENABLE.START, data);
export const bulkEnableGemSettingsSuccess = () =>
    updateSuccess(GEM_SETTINGS.BULK_ENABLE.SUCCESS);
export const bulkEnableGemSettingsFailure = (error) =>
    updateFailure(GEM_SETTINGS.BULK_ENABLE.FAILURE, error);

export const bulkDisableGemSettings = (data) =>
    updateStart(GEM_SETTINGS.BULK_DISABLE.START, data);
export const bulkDisableGemSettingsSuccess = () =>
    updateSuccess(GEM_SETTINGS.BULK_DISABLE.SUCCESS);
export const bulkDisableGemSettingsFailure = (error) =>
    updateFailure(GEM_SETTINGS.BULK_DISABLE.FAILURE, error);

export const setSelectedTab = (data) =>
    genericAction(GEM_SETTINGS.SET_SELECTED_TAB, data);
