export const UPDATE_FOLDERS_ACTION = 'PLAYLISTS/UPDATE_FOLDERS';
export const UPDATE_FOLDER_ACTION = 'PLAYLISTS/UPDATE_FOLDER';
export const ADD_PLAYLIST_ACTION = 'PLAYLISTS/ADD_PLAYLIST';
export const MOVE_PLAYLIST_ACTION = 'PLAYLISTS/MOVE_PLAYLIST';
export const ADD_FOLDER_ACTION = 'PLAYLISTS/ADD_FOLDER';
export const ARCHIVE_FOLDER_ACTION = 'PLAYLISTS/ARCHIVE_FOLDER';
export const SET_ERROR_ACTION = 'PLAYLISTS/SET_ERROR';
export const SET_LOADING_ACTION = 'PLAYLISTS/SET_LOADING';
export const REMOVE_PLAYLIST = 'PLAYLISTS/REMOVE_PLAYLIST';
export const EDIT_PLAYLIST = 'PLAYLISTS/EDIT_PLAYLIST';
export const EDIT_QUOTE = 'PLAYLISTS/EDIT_QUOTE';
export const DELETE_QUOTE = 'PLAYLISTS/DELETE_QUOTE';
export const EDIT_QUOTE_GEM = 'PLAYLISTS/EDIT_QUOTE_GEM';
export const ADD_COMMENT = 'PLAYLISTS/ADD_COMMENT';
export const ADD_CUSTOM_TAG = 'PLAYLISTS/ADD_CUSTOM_TAG';
export const TOGGLE_PLAYLIST_PER_QUOTE = 'PLAYLISTS/TOGGLE_PLAYLIST_PER_QUOTE';
export const UPDATE_PLAYLIST_ACTION = 'PLAYLISTS/UPDATE_PLAYLIST';

export const updatePlaylist = (playlist) => ({
    type: UPDATE_PLAYLIST_ACTION,
    payload: playlist,
});

export const updateFolders = (folders) => ({
    type: UPDATE_FOLDERS_ACTION,
    payload: folders,
});

export const updateFolder = (folder) => ({
    type: UPDATE_FOLDER_ACTION,
    payload: folder,
});

export const addPlaylist = (folderId, playlist) => ({
    type: ADD_PLAYLIST_ACTION,
    payload: {
        folderId,
        playlist,
    },
});

export const movePlaylist = (sourceFolder, destinationFolder) => ({
    type: MOVE_PLAYLIST_ACTION,
    payload: {
        sourceFolder,
        destinationFolder,
    },
});

export const addFolder = (folder) => ({
    type: ADD_FOLDER_ACTION,
    payload: folder,
});

export const removePlaylist = (moveParams) => ({
    type: REMOVE_PLAYLIST,
    payload: moveParams,
});

export const editPlaylist = (editConfig) => ({
    type: EDIT_PLAYLIST,
    payload: editConfig,
});

export const editQuote = (editConfig) => ({
    type: EDIT_QUOTE,
    payload: editConfig,
});

export const deleteQuote = (quoteId) => ({
    type: DELETE_QUOTE,
    payload: quoteId,
});

export const editQuoteGemAction = (quoteId, gem) => ({
    type: EDIT_QUOTE_GEM,
    payload: {
        quoteId,
        gem,
    },
});

export const addCommentAction = (quoteId, comment) => ({
    type: ADD_COMMENT,
    payload: {
        quoteId,
        comment,
    },
});

export const addCustomTagAction = (quoteId, customTag) => ({
    type: ADD_CUSTOM_TAG,
    payload: {
        quoteId,
        customTag,
    },
});

export const archiveFolder = (folder) => ({
    type: ARCHIVE_FOLDER_ACTION,
    payload: folder,
});

export const setLoadingState = (loading) => ({
    type: SET_LOADING_ACTION,
    payload: loading,
});

export const togglePlaylistPerQuote = (playlistId, quoteId) => ({
    type: TOGGLE_PLAYLIST_PER_QUOTE,
    payload: {
        playlistId,
        quoteId,
    },
});

export const setError = (error) => ({ type: SET_ERROR_ACTION, payload: error });
