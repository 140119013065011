import { getSpeakingTime } from '@/utils/recordings/getSpeakingTime';

export const getLongestMonologues = (
    transcriptContent,
    quantity = 5,
    speakers,
) =>
    [...transcriptContent]
        .filter(({ speakerId }) => speakers.some(({ id }) => id === speakerId))
        .sort(
            (prevContent, currentContent) =>
                getSpeakingTime(currentContent) - getSpeakingTime(prevContent),
        )
        .slice(0, quantity);
