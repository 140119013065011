import { createContext, useContext, useMemo, useState } from 'react';
import { node } from 'prop-types';

const RecordingContext = createContext({});
export const useRecordingContext = () => useContext(RecordingContext);

export function RecordingContextProvider({ children }) {
    const [timerOnlyMode, setTimerOnlyMode] = useState(false);

    const contextValue = useMemo(
        () => ({ timerOnlyMode, setTimerOnlyMode }),
        [timerOnlyMode],
    );

    return (
        <RecordingContext.Provider value={contextValue}>
            {children}
        </RecordingContext.Provider>
    );
}

RecordingContextProvider.propTypes = {
    children: node.isRequired,
};
