import { AUTH_STATUS } from '@/constants/authStatus';
import {
    AUTH,
    AUTH_GET_SUBSCRIPTION,
    AUTH_GUEST_AUTHORIZED,
    AUTH_NO_ACTIVE_SUBSCRIPTION,
    AUTH_SUBSCRIPTION_FETCH_FAILURE,
    AUTH_USER_ADD_CRM,
    AUTH_USER_DISCONNECT_CRM,
    AUTH_USER_FETCH_FAILURE,
    AUTH_USER_FETCH_START,
    AUTH_USER_FETCH_SUCCESS,
    AUTH_USER_LOG_IN,
    AUTH_USER_LOG_OUT,
    AUTH_USER_PENDING_CRM,
    AUTH_USER_REMOVE_CRM,
    USER_SURVEY_COMPLETE,
    USER_PM_ALLY_DISMISSED,
    USER_ONBOARDING_UPDATE,
    USER_SET_USER_META,
} from '@/redux/actions/_exports';
import { SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY } from '@/redux/actions/subscriptionAction';

export const initialState = {
    isAuthorized: false,
    isGuestAuthorized: false,
    status: AUTH_STATUS.IDLE,
    error: false,
    user: {
        id: null,
        firstName: null,
        lastName: null,
        email: null,
        title: null,
        gender: null,
        role: null,
        company_id: null,
        photo: null,
        phone: null,
        permission_role: null,
        crm_name: null,
        crm_active: null,
        password_changed: null,
        onboarding: {
            calendar: true,
            vc_tool: true,
            team_members: true,
        },
        user_meta: null,
    },
    pm_ally_dismissed: false,
};

function authReducer(state = initialState, action) {
    switch (action.type) {
        case AUTH_USER_LOG_IN: {
            return {
                ...state,
                isAuthorized: true,
                isGuestAuthorized: false,
            };
        }
        case AUTH_USER_LOG_OUT: {
            return initialState;
        }
        case AUTH_GUEST_AUTHORIZED: {
            return {
                ...state,
                isGuestAuthorized: true,
            };
        }
        case AUTH_USER_FETCH_START: {
            return {
                ...state,
                status: AUTH_STATUS.USER_INFO_LOADING,
                error: false,
            };
        }
        case AUTH_USER_FETCH_SUCCESS: {
            return {
                ...state,
                status: AUTH_STATUS.USER_INFO_LOADED,
                user: {
                    ...action.payload,
                    lastName: action.payload.lastName.replace(/[0-9#]/g, ''),
                    fullName: `${action.payload.firstName} ${action.payload.lastName.replace(/[0-9#]/g, '')}`,
                },
            };
        }
        case AUTH_USER_FETCH_FAILURE: {
            return {
                ...state,
                status: AUTH_STATUS.USER_INFO_ERROR,
                error: true,
            };
        }
        case AUTH_GET_SUBSCRIPTION: {
            return {
                ...state,
                status: AUTH_STATUS.SUBSCRIPTION_INFO_LOADED,
            };
        }
        case AUTH_NO_ACTIVE_SUBSCRIPTION: {
            return {
                ...state,
                status: AUTH_STATUS.NO_SUBSCRIPTION,
            };
        }
        case AUTH_SUBSCRIPTION_FETCH_FAILURE: {
            return { ...state, status: AUTH_STATUS.SUBSCRIPTION_INFO_FAILURE };
        }
        case SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY: {
            return {
                ...state,
                status: AUTH_STATUS.USER_INFO_LOADING,
                error: false,
            };
        }
        case AUTH_USER_REMOVE_CRM: {
            return {
                ...state,
                user: {
                    ...state.user,
                    crm_mapped: false,
                    crm_active: false,
                    crm_name: null,
                },
            };
        }
        case AUTH_USER_PENDING_CRM: {
            return {
                ...state,
                user: {
                    ...state.user,
                    crm_pending: true,
                },
            };
        }
        case AUTH_USER_DISCONNECT_CRM: {
            return {
                ...state,
                user: {
                    ...state.user,
                    crm_mapped: false,
                    crm_active: false,
                },
            };
        }
        case AUTH_USER_ADD_CRM: {
            return {
                ...state,
                user: {
                    ...state.user,
                    crm_active: true,
                    crm_name: action.payload,
                },
            };
        }
        case AUTH.EMAIL_CHANGE.START: {
            return {
                ...state,
                emailChangeLoading: true,
            };
        }
        case AUTH.EMAIL_CHANGE.SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    email: action.payload.data.email,
                },
                emailChangeLoading: false,
            };
        }
        case AUTH.EMAIL_CHANGE.FAILURE: {
            return {
                ...state,
                emailChangeLoading: false,
            };
        }
        case AUTH.PASSWORD_CHANGE.START: {
            return {
                ...state,
                passwordChangeLoading: true,
            };
        }
        case AUTH.PASSWORD_CHANGE.SUCCESS: {
            return {
                ...state,
                user: {
                    ...state.user,
                    password_changed: true,
                },
                passwordChangeLoading: false,
            };
        }
        case AUTH.PASSWORD_CHANGE.FAILURE: {
            return {
                ...state,
                passwordChangeLoading: false,
            };
        }
        case USER_SURVEY_COMPLETE: {
            return {
                ...state,
                user: {
                    ...state.user,
                    survey_completed: true,
                },
            };
        }
        case USER_PM_ALLY_DISMISSED: {
            localStorage.setItem('PM_ALLY_DISMISSED', 'true');
            return {
                ...state,
                pm_ally_dismissed: true,
            };
        }
        case USER_ONBOARDING_UPDATE: {
            return {
                ...state,
                user: {
                    ...state.user,
                    onboarding: {
                        ...action.payload
                    },
                },
            };
        }
        case USER_SET_USER_META: {
            return {
                ...state,
                user: {
                    ...state.user,
                    user_meta: action.payload,
                },
            };
        }
        default:
            return state;
    }
}

export default authReducer;
