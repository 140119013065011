import * as Sentry from '@sentry/nextjs';

import {
    AUTH,
    AUTH_USER_FETCH_FAILURE,
    AUTH_USER_FETCH_START,
    AUTH_USER_FETCH_SUCCESS,
    AUTH_USER_LOG_OUT,
    changeEmailFailure,
    changeEmailSuccess,
    changePasswordFailure,
    changePasswordSuccess,
    getSubscription,
    noActiveSubscription,
    subscriptionFetchFailure,
} from '@/redux/actions/authAction';
import { NEXT_ROUTES, ROUTES } from '@/constants/routes';
import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
    getCompanyInitialLoadedSelector,
    getCompanySubscriptionId,
} from '@/redux/selectors/companySelector';

import Router from 'next/router';
import { SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY } from '@/redux/actions/subscriptionAction';
import axios from '@/utils/api/axios';
import { notification } from 'antd';
import waitFor from '@/utils/waitForStateGenerator/waitFor';
import { getUserEmail } from '@/redux/selectors/authSelector';
import { EMAIL_EXIST, PASSWORD_MUST_MATCH } from '@/constants/errors/auth/auth';

function* getUserSubscription() {
    yield call(waitFor, getCompanyInitialLoadedSelector);
    const subscriptionId = yield select(getCompanySubscriptionId);

    if (subscriptionId) {
        try {
            const {
                data: {
                    data: { subscription },
                },
            } = yield call(axios, NEXT_ROUTES.SUBSCRIPTION_ID(subscriptionId));
            yield put(getSubscription(subscription));
        } catch (error) {
            yield put(subscriptionFetchFailure());
        }
    } else {
        yield put(noActiveSubscription());
    }
}

export function* getAuthData() {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.ME);

        Sentry.setUser({
            email: data.email,
            username: `${data.firstName} ${data.lastName}`,
            id: data.id,
        });

        try {
            const { data: hubspotData } = yield call(
                axios,
                NEXT_ROUTES.HUBSPOT_AUTH,
            );

            const { data: salesforceData } = yield call(
                axios,
                NEXT_ROUTES.SALESFORCE_AUTH,
            );

            if (hubspotData || salesforceData) {
                data.crm_active = true;
                data.crm_name = hubspotData ? 'hubspot' : 'salesforce';
            } else {
                data.crm_active = false;
            }
        } catch (e) {
            data.crm_active = false;
        }

        yield put({
            type: AUTH_USER_FETCH_SUCCESS,
            payload: data,
        });

        yield call(getUserSubscription);
    } catch (error) {
        yield put({ type: AUTH_USER_FETCH_FAILURE });
    }
}

export function* reFetchUserData() {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.ME);

        yield put({
            type: AUTH_USER_FETCH_SUCCESS,
            payload: data,
        });
    } catch (error) {
        yield put({ type: AUTH_USER_FETCH_FAILURE });
    }
}

export function* logOut(action) {
    try {
        yield call(axios.post, NEXT_ROUTES.LOGOUT);
        yield call(notification.success, {
            message: 'Success',
            description: 'Successfully logged out',
        });

        Sentry.setUser(null);

        yield call(
            Router.replace,
            action?.payload?.destination ?? ROUTES.LOGIN,
        );
    } catch (error) {
        yield call(notification.error, {
            message: 'Error',
            description: 'There was error during logging out',
        });
    }
}

export function* changeEmail(action) {
    const { onFailure, email: newEmail, onSuccess } = action.payload;

    try {
        yield call(axios.post, NEXT_ROUTES.CHANGE_EMAIL, {
            email: newEmail,
            password: action.payload.password,
        });
        yield put(changeEmailSuccess(newEmail));
        onSuccess();
    } catch (e) {
        onFailure(e?.response?.data === EMAIL_EXIST);
        yield put(changeEmailFailure());
    }
}

export function* changePassword(action) {
    const {
        onFailure,
        password: newPassword,
        currentPassword,
        onSuccess,
    } = action.payload;

    try {
        const email = yield select(getUserEmail);

        yield call(axios.post, NEXT_ROUTES.CHANGE_PASSWORD, {
            currentPassword,
            email,
            password: newPassword,
        });

        yield put(changePasswordSuccess());
        onSuccess();
    } catch (e) {
        onFailure(e?.response?.data === PASSWORD_MUST_MATCH);
        yield put(changePasswordFailure());
    }
}

export default function* authSaga() {
    yield takeEvery(AUTH_USER_FETCH_START, getAuthData);
    yield takeEvery(SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY, reFetchUserData);
    yield takeEvery(AUTH_USER_LOG_OUT, logOut);
    yield takeEvery(AUTH.EMAIL_CHANGE.START, changeEmail);
    yield takeEvery(AUTH.PASSWORD_CHANGE.START, changePassword);
}
