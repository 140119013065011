import { call, put, takeEvery, select } from 'redux-saga/effects';
import { notification } from 'antd';
import Router from 'next/router';
import axios from '@/utils/api/axios';
import {
    LOGIN_PROCESS_THROUGH_EMAIL,
    LOGIN_PROCESS_SIGN_IN,
    LOGIN_PROCESS_FORGOT_SEND_LINK,
    LOGIN_PROCESS_VERIFY_RESET_TOKEN,
    LOGIN_PROCESS_RESET_PASSWORD,
    throughEmailDoesNotExist,
    throughEmailDoesExist,
    signInSuccessful,
    signInFailed,
    sendLinkSuccess,
    sendLinkFailed,
    verifiedResetToken,
    resetTokenNotVerified,
    resetPasswordSuccessful,
    resetPasswordFailed,
    LOGIN_PROCESS_CHOOSE_PROVIDER,
    chooseProviderFailed,
    LOGIN_PROCESS_GET_PROVIDER_INFORMATION,
    LOGIN_PROCESS_GOOGLE_SUCCESSFUL,
} from '@/redux/actions/loginProcessAction';
import { logIn } from '@/redux/actions/authAction';
import { NEXT_ROUTES, ROUTES } from '@/constants/routes';
import { AUTH_PROVIDERS } from '@/constants/authProviders';

export function* checkIfUserExists(action) {
    try {
        yield call(axios, NEXT_ROUTES.LOGIN_EXIST(action.payload.email));
        yield put(throughEmailDoesExist(action.payload.email));
    } catch (error) {
        if (error?.response?.status === 403) {
            yield call(action.payload.onError);
        } else if (error?.response?.status === 401) {
            const currentProvider = (() => {
                switch (error?.response?.data?.current_provider) {
                    case AUTH_PROVIDERS.GOOGLE:
                        return 'Google';
                    case AUTH_PROVIDERS.MICROSOFT:
                        return 'Microsoft';
                    case AUTH_PROVIDERS.VERSATIONAL:
                        return 'Versational';
                    default:
                        break;
                }
            })();

            yield call(notification.error, {
                message: 'Error',
                description: `Please use your ${currentProvider} account to sign in.`,
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during login process. Please try again.',
            });
        }
        yield put(throughEmailDoesNotExist());
    }
}

export function* signIn(action) {
    try {
        const email = yield select((state) => state.login.provider.email);
        yield call(axios.post, NEXT_ROUTES.LOGIN, {
            email,
            password: action.payload.password,
            reCaptchaToken: action.payload.reCaptchaToken,
            conversationId: action.payload.conversationId,
        });
        yield put(logIn());

        if (action.payload.conversationId) {
            yield call(
                Router.replace,
                ROUTES.CONVERSATIONS_RESULTS(action.payload.conversationId),
            );
        } else {
            yield call(Router.replace, action.payload.origin ?? ROUTES.HOME);
        }

        yield put(signInSuccessful());
    } catch (error) {
        if (error?.response?.status === 403) {
            if (error?.response?.data?.detail === 'WRONG_PROVIDER') {
                const currentProvider = (() => {
                    switch (error?.response?.data?.current_provider) {
                        case AUTH_PROVIDERS.GOOGLE:
                            return 'Google';
                        case AUTH_PROVIDERS.MICROSOFT:
                            return 'Microsoft';
                        default:
                            break;
                    }
                })();

                yield call(notification.error, {
                    message: 'Error',
                    description: `Please use your ${currentProvider} account to sign in.`,
                });
            } else {
                yield call(notification.error, {
                    message: 'Error',
                    description:
                        'ReCAPTCHA could not verify you. Please refresh and try again. If problem persists, try another browser or contact support.',
                });
            }
        } else {
            yield call(action.payload.onError, error);
        }
        yield put(signInFailed());
    }
}

export function* sendLink(action) {
    try {
        yield call(axios, NEXT_ROUTES.LOGIN_EXIST(action.payload.email));
        try {
            yield call(axios.post, NEXT_ROUTES.LOGIN_RESET_PASSWORD_TOKEN, {
                email: action.payload.email,
                reCaptchaToken: action.payload.reCaptchaToken,
            });
            yield put(sendLinkSuccess());
        } catch (error) {
            if (error?.response?.data === 'WRONG_PROVIDER') {
                yield call(notification.error, {
                    message: 'Error',
                    description:
                        'Could not reset the password since you are using a different provider. Please use your Google or Microsoft account to sign in.',
                });
            } else if (error?.response?.status === 403) {
                yield call(notification.error, {
                    message: 'Error',
                    description:
                        'ReCAPTCHA could not verify you. Please refresh and try again. If problem persists, try another browser or contact support.',
                });
            } else {
                yield call(notification.error, {
                    message: 'Error',
                    description: 'Could not send link',
                });
            }
            yield put(sendLinkFailed());
        }
    } catch (error) {
        if (error?.response?.status === 400) {
            yield call(action.payload.onError);
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during resetting password process. Please try again.',
            });
        }
        yield put(sendLinkFailed());
    }
}

export function* verifyResetPasswordToken(action) {
    try {
        const { email, tokenId } = action.payload;
        yield call(
            axios,
            NEXT_ROUTES.LOGIN_VERIFY_RESET_PASSWORD(email, tokenId),
        );
        yield put(verifiedResetToken(tokenId, email));
    } catch (error) {
        if (error?.response?.status === 400) {
            yield put(resetTokenNotVerified());
            yield call(notification.error, {
                message: 'Error',
                description: 'Incorrect or outdated link',
            });
        } else {
            yield put(resetTokenNotVerified());
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during resetting password process. Please try again.',
            });
        }
    }
}

export function* resetPassword(action) {
    try {
        const email = yield select((state) => state.login.provider.email);
        const token = yield select((state) => state.login.provider.tokenId);
        yield call(axios.post, NEXT_ROUTES.LOGIN_RESET_PASSWORD, {
            email,
            token,
            password: action.payload.password,
        });
        yield put(resetPasswordSuccessful());
    } catch (error) {
        yield put(resetPasswordFailed());
        yield call(notification.error, {
            message: 'Error',
            description: 'Unable to reset password',
        });
    }
}

export function* loginWithProvider(action) {
    try {
        const { data: redirectionUrl } = yield call(
            axios,
            NEXT_ROUTES.LOGIN_PROVIDER(action.payload.provider),
        );
        yield call([window.location, window.location.assign], redirectionUrl);
    } catch (error) {
        yield put(chooseProviderFailed());
        yield call(notification.error, {
            message: 'Error',
            description: 'Whoops! Could not connect to the identity provider!',
        });
    }
}

export function* getOnLoginExternal(action) {
    const provider = yield select((state) => state.login.provider);

    try {
        switch (provider.name) {
            case AUTH_PROVIDERS.GOOGLE:
                yield call(
                    axios.post,
                    NEXT_ROUTES.LOGIN_EXTERNAL_PROVIDER(AUTH_PROVIDERS.GOOGLE),
                    {
                        code: provider.response.code,
                        conversationId: action.payload.conversationId || null,
                    },
                );
                break;
            case AUTH_PROVIDERS.MICROSOFT:
                yield call(
                    axios.post,
                    NEXT_ROUTES.LOGIN_EXTERNAL_PROVIDER(
                        AUTH_PROVIDERS.MICROSOFT,
                    ),
                    { 
                        ...provider,
                        conversationId: action.payload.conversationId || null,
                    },
                );
                break;
            default:
                break;
        }
        yield put(logIn());

        if (action.payload.conversationId) {
            yield call(
                Router.replace,
                ROUTES.CONVERSATIONS_RESULTS(action.payload.conversationId),
            );
        } else {
            yield call(Router.replace, action.payload.origin ?? ROUTES.HOME);
        }
        yield put(signInSuccessful());
    } catch (error) {
        if (error?.response?.status === 418) {
            yield call(notification.error, {
                message: 'Error',
                description: 'Account has been deactivated.',
            });
        } else if (error?.response?.data === 'USER_DOES_NOT_EXIST') {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'This email is not connected to any account. Please sign up first.',
            });
        } else if (error?.response?.data === 'WRONG_ACCOUNT') {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'External provider account identifier has changed. Please contact support.',
            });
        } else if (error?.response?.data?.detail === 'WRONG_PROVIDER') {
            const currentProvider = (() => {
                switch (error?.response?.data?.current_provider) {
                    case AUTH_PROVIDERS.GOOGLE:
                        return 'Google';
                    case AUTH_PROVIDERS.MICROSOFT:
                        return 'Microsoft';
                    case AUTH_PROVIDERS.VERSATIONAL:
                        return 'Versational';
                    default:
                        break;
                }
            })();

            yield call(notification.error, {
                message: 'Error',
                description: `Please use your ${currentProvider} account to sign in.`,
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description: 'There was error during sign in process. ',
            });
        }

        yield put(signInFailed());
    }
}

export default function* loginProcessSaga() {
    yield takeEvery(LOGIN_PROCESS_CHOOSE_PROVIDER, loginWithProvider);
    yield takeEvery(LOGIN_PROCESS_THROUGH_EMAIL, checkIfUserExists);
    yield takeEvery(LOGIN_PROCESS_SIGN_IN, signIn);
    yield takeEvery(LOGIN_PROCESS_FORGOT_SEND_LINK, sendLink);
    yield takeEvery(LOGIN_PROCESS_VERIFY_RESET_TOKEN, verifyResetPasswordToken);
    yield takeEvery(LOGIN_PROCESS_RESET_PASSWORD, resetPassword);
    yield takeEvery(LOGIN_PROCESS_GET_PROVIDER_INFORMATION, getOnLoginExternal);
    yield takeEvery(LOGIN_PROCESS_GOOGLE_SUCCESSFUL, getOnLoginExternal);
}
