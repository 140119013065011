import { call, put, select, takeEvery } from 'redux-saga/effects';
import dayjs from 'dayjs';
import { message } from 'antd';
import {
    DATE_ZONE_EVENT_FETCH_START,
    DATE_ZONE_UPDATE_START,
    fetchDateZoneFailure,
    fetchDateZoneSuccess,
    updateDateZoneFailure,
    updateDateZoneSuccess,
} from '@/redux/actions/dateActions';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import waitFor from '@/utils/waitForStateGenerator/waitFor';
import {
    getUserIdSelector,
    getUserTimezone,
} from '@/redux/selectors/authSelector';

const getDefaultZone = () => dayjs.tz.guess();

async function updateZoneCall(zone) {
    const body = { timezone: zone };
    await axios.patch(NEXT_ROUTES.USERS_ME, body);
    return zone;
}

export function configZone(zone) {
    dayjs.tz.setDefault(zone);
}

export function* getZone() {
    try {
        yield call(waitFor, getUserIdSelector);
        const userZone = yield select(getUserTimezone);
        const zone = userZone || getDefaultZone();
        configZone(zone);
        yield put(fetchDateZoneSuccess(zone));
    } catch (error) {
        yield put(fetchDateZoneFailure(error, getDefaultZone()));
    }
}

export function* updateZone(action) {
    const { zone } = action.payload;

    try {
        yield updateZoneCall(zone);
        configZone(zone);
        yield put(updateDateZoneSuccess(zone));
    } catch (e) {
        message.error('Time zone update failed');
        yield put(updateDateZoneFailure());
    }
}

export default function* datesSaga() {
    yield takeEvery(DATE_ZONE_EVENT_FETCH_START, getZone);
    yield takeEvery(DATE_ZONE_UPDATE_START, updateZone);
}
