import {
    deleteFailure,
    deleteStart,
    deleteSuccess,
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';

export const INVITES_FETCH_START = 'INVITES/FETCH_START';
export const INVITES_FETCH_SUCCESS = 'INVITES/FETCH_SUCCESS';
export const INVITES_FETCH_FAILURE = 'INVITES/FETCH_FAILURE';

export const INVITE_RESEND = 'INVITE/RESEND';
export const INVITES_SEND_BULK = 'INVITES/SEND_BULK';

export const INVITE_DELETE_START = 'INVITE/DELETE_START';
export const INVITE_DELETE_SUCCESS = 'INVITE/DELETE_SUCCESS';
export const INVITE_DELETE_FAILURE = 'INVITE/DELETE_FAILURE';

export const fetchInvites = () => fetchData(INVITES_FETCH_START);
export const fetchInvitesSuccess = (data) =>
    fetchSuccess(INVITES_FETCH_SUCCESS, data);
export const fetchInvitesFailure = (error) =>
    fetchFailure(INVITES_FETCH_FAILURE, error);

export const inviteResend = (id) => ({
    type: INVITE_RESEND,
    payload: { data: id },
});

export const invitesSend = (data, callbacks) => ({
    type: INVITES_SEND_BULK,
    payload: { data, callbacks },
});

export const inviteDeleteStart = (id) => deleteStart(INVITE_DELETE_START, id);
export const inviteDeleteFailure = (id, error) =>
    deleteFailure(INVITE_DELETE_FAILURE, error, id);
export const inviteDeleteSuccess = (id) =>
    deleteSuccess(INVITE_DELETE_SUCCESS, id);
