import { useEffect, useState } from 'react';
import { getUserSelector } from '@/redux/selectors/authSelector';
import { useSelector } from 'react-redux';
import {
    getIsCancelled,
    getSubscriptionStatusSelector,
} from '@/redux/selectors/subscriptionSelector';
import { getCalendarProviderSelector } from '@/redux/selectors/calendarSelector';

function UserGuideUserIdentification() {
    const user = useSelector(getUserSelector);
    const subscriptionStatus = useSelector(getSubscriptionStatusSelector);
    const isSubscriptionCancelled = useSelector(getIsCancelled);
    const calendarProviderSelector = useSelector(getCalendarProviderSelector);
    const [isUserGuidingAvailable, setIsUserGuidingAvailable] = useState(false);

    useEffect(() => {
        const intervalId = setInterval(() => {
            if (window.userGuiding) {
                setIsUserGuidingAvailable(true);
                clearInterval(intervalId);
            }
        }, 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, []);

    useEffect(() => {
        if (user && isUserGuidingAvailable && subscriptionStatus && window.userGuiding !== undefined) {
            window.userGuiding.identify(user.id, {
                email: user?.email,
                name: user?.fullName,
                is_subscribed: user?.is_subscribed,
                company_id: user?.company_id,
                customer_id: user?.customer_id,
                title: user?.title,
                deleted_at: user?.deleted_at,
                permission_role: user?.permission_role,
                role: user?.role,
                timezone: user?.timezone,
                used_minutes: user?.used_minutes,
                max_minutes: user?.max_minutes,
                used_recording_time: user?.used_recording_time,
                max_recording_time: user?.max_recording_time,
                created_at: user?.created_at,
                subscription_status: subscriptionStatus,
                is_subscription_cancelled: `${isSubscriptionCancelled}`,
                calendar_connected: calendarProviderSelector?.length > 0 ? 'yes' : 'no',
            });
        }
    }, [
        user,
        isUserGuidingAvailable,
        subscriptionStatus,
        isSubscriptionCancelled,
    ]);

    return null;
}

export default UserGuideUserIdentification;
