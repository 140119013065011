import { createSelector } from 'reselect';
import {
    getEntitySentimentDataSelector,
    getSentimentDataSelector,
    getTranscriptContentSelector,
    getTranscriptSpeakersSelector,
} from '@/redux/selectors/transcript/transcriptSelector';
import { getLongestMonologues as getLongestMonologuesUtil } from '@/utils/recordings/getLongesMonologues';
import { getSelectedEntities } from '@/redux/selectors/transcript/helpers';
import { getSelectedSpeakersSelector } from '@/redux/selectors/conversation/conversationResultViewSelector';
import { getAvgTranscriptSentimentForSelectedSpeakers } from '@/utils/conversations/analytics/sentiment/sentimentAvg';
import { getLongestMonologueTime } from '@/utils/conversations/analytics/longestMonologues/longestMonologues';
import { AttendeeTypes } from '@/constants/conversations/attendeeTypes';
import { getTopSpeaker } from '@/utils/conversations/analytics/talkToListen/talkToListenRatio';
import capitalize from 'lodash/capitalize';

export const getSelectedEntitiesSelector = createSelector(
    [
        getTranscriptContentSelector,
        getEntitySentimentDataSelector,
        getSelectedSpeakersSelector,
    ],
    (content, sentimentEntities, speakers) =>
        getSelectedEntities(content, speakers, sentimentEntities),
);

export const getLongestMonologues = createSelector(
    [getTranscriptContentSelector, getSelectedSpeakersSelector],
    (content, speakers) => {
        if (!content || !speakers) return [];
        return getLongestMonologuesUtil(content, 5, speakers);
    },
);

export const getAllSelectedEntitiesSelector = createSelector(
    [
        getTranscriptContentSelector,
        getEntitySentimentDataSelector,
        getTranscriptSpeakersSelector,
    ],
    (content, sentimentEntities, speakers) =>
        getSelectedEntities(content, speakers, sentimentEntities).map(
            ({ name }) => capitalize(name),
        ),
);

export const getCalculatedTranscriptSentimentForSpeakers = createSelector(
    [
        getTranscriptContentSelector,
        getSentimentDataSelector,
        getSelectedSpeakersSelector,
    ],
    (transcriptContent, sentimentData, speakers) =>
        getAvgTranscriptSentimentForSelectedSpeakers(
            speakers,
            transcriptContent,
            sentimentData,
        ),
);

export const getLongesMonologueTime = createSelector(
    [getTranscriptContentSelector, getSelectedSpeakersSelector],
    (content, speakers) =>
        getLongestMonologueTime(
            content,
            speakers.map(({ id }) => id),
        ),
);

export const getTalkToListenRatioTopSpeakerSelector = createSelector(
    [getTranscriptSpeakersSelector],
    (speakers) => getTopSpeaker(speakers),
);

export const getAreContactsAndOwnersNotSpecified = createSelector(
    [getTranscriptSpeakersSelector],
    (speakers) => {
        const containContacts = speakers.some(
            ({ type }) => type === AttendeeTypes.contact,
        );
        const containOwners = speakers.some(
            ({ type }) => type === AttendeeTypes.teamMember,
        );
        return !containContacts && !containOwners;
    },
);
