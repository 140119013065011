import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { CHANGE_SIGN_IN_PROCESS_KEY } from '@/constants/reduxKeys';
import changeSignInProcessReducer from '@/redux/reducers/changeSignInProcessReducer';
import { resetToChangeSignInPage } from '@/redux/actions/changeSignInProcessAction';
import changeSignInProcessSaga from '@/redux/sagas/changeSignInProcessSaga';

// eslint-disable-next-line react/prop-types
export default function ChangeSignInProcessInjector({ isAuthorized }) {
    useInjectReducer({
        key: CHANGE_SIGN_IN_PROCESS_KEY,
        reducer: changeSignInProcessReducer,
    });
    useInjectSaga({
        key: CHANGE_SIGN_IN_PROCESS_KEY,
        saga: changeSignInProcessSaga,
    });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(resetToChangeSignInPage());
        }
    }, [dispatch, isAuthorized]);

    return null;
}
