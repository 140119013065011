import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';
import { getTimeZones } from '@vvo/tzdb';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(advancedFormat);
dayjs.extend(timezone);

export const formatWithZone = (isoString, zone, format) => {
    const obj = zone ? dayjs(isoString).tz(zone) : dayjs(isoString).tz();
    return obj.format(format);
};

export const getFormattedTimeZones = () =>
    getTimeZones()
        .sort((a, b) => a.rawOffsetInMinutes - b.rawOffsetInMinutes)
        .map(
            ({
                alternativeName,
                rawOffsetInMinutes,
                mainCities,
                abbreviation,
                currentTimeOffsetInMinutes,
                name,
            }) => ({
                alternativeName,
                rawOffset: rawOffsetInMinutes,
                mainCity: mainCities[0],
                abbreviation,
                currentTimeOffsetInMinutes,
                name,
            }),
        )
        .map((v) => {
            const hoursText = String(Math.floor(v.rawOffset / 60)).padStart(
                2,
                '0',
            );
            const minutes = String(
                Math.floor(Math.floor(v.rawOffset % 60)),
            ).padStart(2, '0');
            const sign =
                // eslint-disable-next-line no-nested-ternary
                v.rawOffset === 0 ? ' ' : v.rawOffset < 0 ? '-' : '+';

            return {
                ...v,
                prettyName: `UTC ${sign}${hoursText}:${minutes} (${v.alternativeName}) - ${v.mainCity}`,
            };
        });
