import { createSelector } from 'reselect';
import {
    getQuotesInDisplayFormat,
    getTranscriptSelector,
} from '@/redux/selectors/transcript/transcriptSelector';
import {
    getConfirmedQuotes,
    getQuotesToGemCount,
    getQuotesToTagCount,
    getUnconfirmedQuotes,
} from '@/components/conversations/results/hooks/useInsightsUtils';
import { INSIGHTS_TABS } from '@/constants/conversations/insights';
import {
    filterQuotesByGivenSpeakersRange,
    filterSpeakers,
} from '@/redux/selectors/conversation/utils';
import { SORT_FILTER_VARIANTS } from '@/components/conversations/results/insights/insightsFilter/insightsFiltersData';
import isEqual from 'lodash/isEqual';

export const getActiveQuoteId = (state) => state.insightsView?.quoteActiveId;

export const getQuoteActiveIdCandidate = (state) => state.insightsView?.quoteActiveIdCandidate;

export const getCurrentSearchedComment = (state) =>
    state.insightsView?.currentSearchedComment;

export const getIsLoadingQuoteSelector = (state, id) =>
    state.insightsView?.quotesIdLoading?.includes(id);

export const getActiveInsightTab = (state) => state.insightsView?.insightsTab;

export const isActiveConfirmedTab = (state) =>
    state.insightsView?.insightsTab === INSIGHTS_TABS.CONFIRMED;

export const getIsActiveQuoteLoading = (state) =>
    getIsLoadingQuoteSelector(state, getActiveQuoteId(state));

export const getQuoteActive = createSelector(
    [getQuotesInDisplayFormat, getActiveQuoteId],
    (quotes, id) => {
        if (!quotes) return null;

        return quotes.find((q) => q.id === id);
    },
);

export const getConfirmedQuotesSelector = createSelector(
    [getQuotesInDisplayFormat],
    (quotes) => getConfirmedQuotes(quotes),
);

export const getUnconfirmedQuotesSelector = createSelector(
    [getQuotesInDisplayFormat],
    (quotes) => getUnconfirmedQuotes(quotes),
);

export const getNumberOfUnconfirmedQuotes = createSelector(
    [getUnconfirmedQuotesSelector],
    (quotes) => quotes.length,
);

// CRM MODALS
const getAddEventQuoteId = (state) =>
    state.insightsView?.addEventModal?.quoteId;

export const getAddEventQuote = createSelector(
    [getQuotesInDisplayFormat, getAddEventQuoteId],
    (quotes, quoteId) => quotes.find((q) => q.id === quoteId),
);

export const getAddEventSpeakerId = (state) =>
    state.insightsView?.addEventModal?.speakerId;

const getAddTaskQuoteId = (state) => state.insightsView?.addTaskModal?.quoteId;

export const getAddTaskQuote = createSelector(
    [getQuotesInDisplayFormat, getAddTaskQuoteId],
    (quotes, quoteId) => quotes.find((q) => q.id === quoteId),
);

export const getAddTaskSpeakerId = (state) =>
    state.insightsView?.addTaskModal?.speakerId;

// INSIGHTS FILTERS

export const getInsightsFilter = (state) => state.insightsView?.insightsFilters;

export const getExcludedGems = (state) => state.insightsView?.excludedGems;

export const getExcludedTopics = (state) => state.insightsView?.excludedTopics;

export const getSelectedTopic = createSelector(
    [getInsightsFilter],
    (filters) => filters?.selectedTopic,
);

const getSortBy = createSelector(
    [getInsightsFilter],
    (filters) => filters?.sortBy,
);

export const getSelectedGem = createSelector(
    [getInsightsFilter],
    (filters) => filters?.selectedGem,
);

const emptyArray = [];
const getTsSpeakers = createSelector(
    [getTranscriptSelector],
    (ts) => ts?.speakers ?? emptyArray,
);

const getSelectedSpeakersIds = createSelector(
    [getInsightsFilter, getTsSpeakers],
    (insightsFilter, tsSpeakers) => filterSpeakers(insightsFilter, tsSpeakers),
);

export const getConfirmedFilteredQuotesSelector = createSelector(
    [
        getConfirmedQuotesSelector,
        getTsSpeakers,
        getSelectedSpeakersIds,
        getSortBy,
        getSelectedGem,
        getSelectedTopic,
        getExcludedGems,
        getExcludedTopics,
    ],
    (
        confirmedQuotes,
        tsSpeakers,
        selectedSpeakersIds,
        sortBy,
        selectedGem,
        selectedTopic,
        excludedGems,
        excludedTopics,
    ) => {
        let filteredArr = confirmedQuotes.filter((quote) =>
            filterQuotesByGivenSpeakersRange(
                quote,
                tsSpeakers,
                selectedSpeakersIds,
            ),
        );

        switch (sortBy) {
            case SORT_FILTER_VARIANTS.comments:
                filteredArr = filteredArr.sort(
                    (a, b) => b.comments.length - a.comments.length,
                );
                break;
            case SORT_FILTER_VARIANTS.pinned:
                filteredArr = filteredArr.sort((a, b) => b.pinned - a.pinned);
                break;
            case SORT_FILTER_VARIANTS.time:
                filteredArr = filteredArr.sort(
                    (a, b) => a.timeInMs - b.timeInMs,
                );
                break;
            default:
                break;
        }

        if (selectedGem?.confirmed) {
            filteredArr = filteredArr.filter((item) =>
                item.gems.map((i) => i.name).includes(selectedGem?.confirmed),
            );
        }

        if (selectedTopic?.confirmed) {
            filteredArr = filteredArr.filter((item) =>
                item.customTags.map((i) => i.name).includes(selectedTopic?.confirmed),
            );
        }

        if (excludedGems?.confirmed?.length) {
            filteredArr = filteredArr.filter((item) => !excludedGems?.confirmed?.includes(item.gems[0]?.name));
        }

        
        if (excludedTopics?.confirmed?.length) {
            filteredArr = filteredArr.filter((item) => !excludedTopics?.confirmed?.includes(item.customTags[0]?.name));
        }

        return filteredArr;
    },
);

export const getUnconfirmedFilteredQuotesSelector = createSelector(
    [
        getUnconfirmedQuotesSelector,
        getTsSpeakers,
        getSelectedSpeakersIds,
        getSortBy,
        getSelectedGem,
        getSelectedTopic,
        getExcludedGems,
        getExcludedTopics,
    ],
    (
        unconfirmedQuotes,
        tsSpeakers,
        selectedSpeakersIds,
        sortBy,
        selectedGem,
        selectedTopic,
        excludedGems,
        excludedTopics,
    ) => {
        let filteredArr = unconfirmedQuotes.filter((quote) =>
            filterQuotesByGivenSpeakersRange(
                quote,
                tsSpeakers,
                selectedSpeakersIds,
            ),
        );

        switch (sortBy) {
            case SORT_FILTER_VARIANTS.comments:
                filteredArr = filteredArr.sort(
                    (a, b) => b.comments.length - a.comments.length,
                );
                break;
            case SORT_FILTER_VARIANTS.pinned:
                filteredArr = filteredArr.sort((a, b) => b.pinned - a.pinned);
                break;
            case SORT_FILTER_VARIANTS.time:
                filteredArr = filteredArr.sort(
                    (a, b) => a.timeInMs - b.timeInMs,
                );
                break;
            default:
                break;
        }

        if (selectedGem?.unconfirmed) {
            filteredArr = filteredArr.filter((item) =>
                item.gems.map((i) => i.name).includes(selectedGem?.unconfirmed),
            );
        }

        
        if (selectedTopic?.unconfirmed) {
            filteredArr = filteredArr.filter((item) =>
                item.customTags.map((i) => i.name).includes(selectedTopic?.unconfirmed),
            );
        }

        if (excludedGems?.unconfirmed?.length) {
            filteredArr = filteredArr.filter((item) => !excludedGems?.unconfirmed?.includes(item.gems[0]?.name));
        }

        
        if (excludedTopics?.unconfirmed?.length) {
            filteredArr = filteredArr.filter((item) => !excludedTopics?.unconfirmed?.includes(item.customTags[0]?.name));
        }

        return filteredArr;
    },
);

export const getConfirmedQuotesToGemCountSelector = createSelector(
    [getConfirmedQuotesSelector],
    (quotes) => getQuotesToGemCount(quotes),
);
export const getUnconfirmedQuotesToGemCountSelector = createSelector(
    [getUnconfirmedQuotesSelector],
    (quotes) => getQuotesToGemCount(quotes),
);
export const getConfirmedQuotesToTagCountSelector = createSelector(
    [getConfirmedQuotesSelector],
    (quotes) => getQuotesToTagCount(quotes),
);
export const getUnconfirmedQuotesToTagCountSelector = createSelector(
    [getUnconfirmedQuotesSelector],
    (quotes) => getQuotesToTagCount(quotes),
);

export const getCheckedQuoteIds = (state) =>
    state.insightsView?.quotesIdChecked;

export const getMultipleQuotesChecked = (state) =>
    state.insightsView?.quotesIdChecked.length > 0;

const getAreAllConfirmedQuotesChecked = createSelector(
    [getConfirmedFilteredQuotesSelector, getCheckedQuoteIds],
    (confirmedQuotes, checkedQuoteIds) =>
        confirmedQuotes.length > 0 &&
        isEqual(
            confirmedQuotes.map((q) => q.id).sort(),
            checkedQuoteIds.sort(),
        ),
);
const getAreAllUnConfirmedQuotesChecked = createSelector(
    [getUnconfirmedFilteredQuotesSelector, getCheckedQuoteIds],
    (unconfirmedQuotes, checkedQuoteIds) =>
        unconfirmedQuotes.length > 0 &&
        isEqual(
            unconfirmedQuotes.map((q) => q.id).sort(),
            checkedQuoteIds.sort(),
        ),
);

export const getAreAllQuotesChecked = createSelector(
    [
        isActiveConfirmedTab,
        getAreAllConfirmedQuotesChecked,
        getAreAllUnConfirmedQuotesChecked,
    ],
    (
        isConfirmedTabActive,
        areAllConfirmedQuotesChecked,
        areAllUnConfirmedQuotesChecked,
    ) => {
        if (isConfirmedTabActive) return areAllConfirmedQuotesChecked;
        return areAllUnConfirmedQuotesChecked;
    },
);

export const getActionItems = createSelector(
    [
        getConfirmedQuotesSelector,
    ],
    (
        confirmedQuotes,
    ) => {
        let filteredArr = confirmedQuotes;

        filteredArr = filteredArr.filter((item) =>
            item.gems.map((i) => i.name).includes("Action Item"),
        );

        return filteredArr;
    },
);
