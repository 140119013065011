import { Button } from 'antd';
import { node, object } from 'prop-types';
import { useRouter } from 'next/router';
import { fileTypeIcon } from '@/utils/recordings/fileTypeIcon';
import { ROUTES } from '@/constants/routes';
import {
    FileName,
    IconContainer,
    ListItemContainer,
} from './uploadingItem.styles';

export function UploadListItem({ originNode, file }) {
    const { push } = useRouter();
    return (
        <ListItemContainer>
            <IconContainer>{fileTypeIcon(file.type)}</IconContainer>
            <FileName>{originNode}</FileName>
            <Button
                size="large"
                onClick={() =>
                    push(ROUTES.CONVERSATIONS_RESULTS(file.conversationID))
                }
            >
                Review
            </Button>
        </ListItemContainer>
    );
}

UploadListItem.propTypes = {
    originNode: node.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    file: object.isRequired,
};
