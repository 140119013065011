export const CALENDAR_PROVIDER_FETCH_START = 'CALENDAR_PROVIDER/FETCH_START';
export const CALENDAR_PROVIDER_FETCH_SUCCESS =
    'CALENDAR_PROVIDER/FETCH_START_SUCCESS';
export const CALENDAR_PROVIDER_FETCH_FAILURE =
    'CALENDAR_PROVIDER/FETCH_START_FAILURE';

export const CALENDAR_PROVIDER_UPDATE = 'CALENDAR_PROVIDER/UPDATE';

export function fetchCalenderProvider() {
    return {
        type: CALENDAR_PROVIDER_FETCH_START,
    };
}

export function fetchProviderSuccess(provider) {
    return {
        type: CALENDAR_PROVIDER_FETCH_SUCCESS,
        payload: {
            provider,
        },
    };
}

export function fetchProviderFailure() {
    return {
        type: CALENDAR_PROVIDER_FETCH_FAILURE,
    };
}

export function providerUpdate(provider) {
    return {
        type: CALENDAR_PROVIDER_UPDATE,
        payload: {
            provider,
        }
    };
}
    