import React, { useEffect, useState } from 'react';
import {
    ProgressTextWrapper,
    StyledLoadingInfo,
} from '@/components/table/tableCell/tableCell.styles';
import UploadProgressBar from '@/components/uploadProgressBar/uploadProgressBar';
import { arrayOf, number, string } from 'prop-types';
import { POLL_MESSAGES } from '@/constants/poll/pollState';
import { VIVIDORANGE } from '@/constants/colors';

export function ProgressIndicator({
    text,
    messages,
    interval,
    maxTime,
    ...rest
}) {
    const [message, setMessage] = useState({
        occurrence: null,
        text: null,
    });
    const [longerThanExpected, setLongerThenExpected] = useState(false);

    useEffect(() => {
        if (messages.length) {
            const intervalId = setInterval(() => {
                setMessage(({ occurrence }) => {
                    const nextOcc = occurrence !== null ? occurrence + 1 : 0;
                    const nextMessageId = nextOcc % messages.length;
                    return {
                        occurrence: nextOcc,
                        text: messages[nextMessageId],
                    };
                });
            }, interval);

            return () => {
                clearInterval(intervalId);
            };
        }
    }, [messages, interval]);

    useEffect(() => {
        if (maxTime) {
            const timeoutId = setTimeout(() => {
                setLongerThenExpected(true);
            }, maxTime);
            return () => {
                clearTimeout(timeoutId);
            };
        }
    }, [maxTime]);

    return (
        <ProgressTextWrapper>
            <StyledLoadingInfo>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {text} {message.text && <span>{message.text}.</span>}
                </div>
                <UploadProgressBar height={0.25} transition={0} {...rest} />
                {longerThanExpected && (
                    <div>{POLL_MESSAGES.EXCEEDING_TIME}.</div>
                )}
            </StyledLoadingInfo>
        </ProgressTextWrapper>
    );
}

ProgressIndicator.defaultProps = {
    messages: [],
    interval: 20 * 1000,
    maxTime: undefined,
    color: VIVIDORANGE,
};

ProgressIndicator.propTypes = {
    text: string.isRequired,
    messages: arrayOf(string),
    interval: number,
    maxTime: number,
    color: string,
};
