import { ROUTES } from '@/constants/routes';
import {
    ButtonsContainer,
    ResultsText,
} from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import React from 'react';
import { string } from 'prop-types';

function ViewResults({ conversationId }) {
    return (
        <ButtonsContainer
            result
            onClick={() => {
                window.location.href = ROUTES.CONVERSATIONS_RESULTS(conversationId);
            }}
        >
            <ResultsText>View results!</ResultsText>
        </ButtonsContainer>
    );
}

ViewResults.propTypes = {
    conversationId: string.isRequired,
};

export default ViewResults;
