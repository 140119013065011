import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('ATTENDEES');

export const ATTENDEES = {
    ...creator.fetch(),
    ATTENDEE_FETCH: creator.custom('ATTENDEE_FETCH'),
    ATTENDEE_ADD: creator.custom('ATTENDEE_ADD'),
    ATTENDEE_REMOVE: creator.custom('ATTENDEE_REMOVE'),
    DETAILS_FETCH: creator.custom('DETAILS_FETCH'),
};

export const fetchAttendeesStart = (limit, offset, search) => ({
    type: ATTENDEES.FETCH_START,
    payload: {
        limit,
        offset,
        search,
    },
});

export const fetchAttendeesSuccess = (attendees) => ({
    type: ATTENDEES.FETCH_SUCCESS,
    payload: {
        attendees,
    },
});

export const fetchAttendeesFailure = (error) => ({
    type: ATTENDEES.FETCH_FAILURE,
    payload: {
        error,
    },
});

export const fetchAttendeesDetailsStart = () => ({
    type: ATTENDEES.DETAILS_FETCH.START,
    payload: {}
});

export const fetchAttendeesDetailsSuccess = (attendees) => ({
    type: ATTENDEES.DETAILS_FETCH.SUCCESS,
    payload: {
        attendees,
    },
});

export const fetchAttendeesDetailsFailure = (error) => ({
    type: ATTENDEES.DETAILS_FETCH.FAILURE,
    payload: {
        error,
    },
});

export const fetchAttendeeStart = (id) => ({
    type: ATTENDEES.ATTENDEE_FETCH.START,
    payload: {
        id,
    },
});

export const fetchAttendeeSuccess = (attendee) => ({
    type: ATTENDEES.ATTENDEE_FETCH.SUCCESS,
    payload: {
        attendee,
    },
});

export const fetchAttendeeFailure = (error) => ({
    type: ATTENDEES.ATTENDEE_FETCH.FAILURE,
    payload: {
        error,
    },
});

export const addAttendeeStart = (data, onFinish) => ({
    type: ATTENDEES.ATTENDEE_ADD.START,
    payload: {
        data,
        onFinish,
    },
});

export const addAttendeeSuccess = (createdResource) => ({
    type: ATTENDEES.ATTENDEE_ADD.SUCCESS,
    payload: {
        attendee: createdResource,
    },
});

export const addAttendeeFailure = (error) => ({
    type: ATTENDEES.ATTENDEE_ADD.FAILURE,
    payload: {
        error,
    },
});

export const removeAttendeeStart = (id) => ({
    type: ATTENDEES.ATTENDEE_REMOVE.START,
    payload: {
        id,
    },
});

export const removeAttendeeSuccess = (id) => ({
    type: ATTENDEES.ATTENDEE_REMOVE.SUCCESS,
    payload: {
        id,
    },
});

export const removeAttendeeFailure = (error) => ({
    type: ATTENDEES.ATTENDEE_REMOVE.FAILURE,
    payload: {
        error,
    },
});
