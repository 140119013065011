import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('CONVERSATION_RESULT_VIEW');

export const CONVERSATION_RESULT_VIEW = {
    SET_LEFT_TAB_PANEL_KEY: creator.rawCustom('SET_LEFT_TAB_PANEL_KEY'),
    SET_RIGHT_TAB_PANEL_KEY: creator.rawCustom('SET_RIGHT_TAB_PANEL_KEY'),
    SET_SELECTED_ANALYTICS_TILE: creator.rawCustom(
        'SET_SELECTED_ANALYTICS_TILE',
    ),
    SWITCH_TO_SINGLE_COLUMN: creator.rawCustom('SWITCH_TO_SINGLE_COLUMN'),
    SWITCH_TO_TWO_COLUMNS: creator.rawCustom('SWITCH_TO_TWO_COLUMNS'),
    SET_VIEW_MODE: creator.rawCustom('SET_VIEW_MODE'),
    SET_USERS_TYPE: creator.rawCustom('SET_USERS_TYPE'),
    SET_TRANSCRIPT_USERS_TYPE: creator.rawCustom('SET_TRANSCRIPT_USERS_TYPE'),
    RESET_CONVERSATION_RESULT_VIEW_STATE: creator.rawCustom('RESET_VIEW_STATE'),
};

export const resetConversationResultViewState = () => ({
    type: CONVERSATION_RESULT_VIEW.RESET_CONVERSATION_RESULT_VIEW_STATE,
});

export const setLeftTabPanelKey = (leftTabPanelKey) => ({
    type: CONVERSATION_RESULT_VIEW.SET_LEFT_TAB_PANEL_KEY,
    payload: { data: leftTabPanelKey },
});

export const setRightTabPanelKey = (rightTabPanelKey) => ({
    type: CONVERSATION_RESULT_VIEW.SET_RIGHT_TAB_PANEL_KEY,
    payload: { data: rightTabPanelKey },
});

export const setSelectedAnalyticsTile = (selectedAnalyticsTile) => ({
    type: CONVERSATION_RESULT_VIEW.SET_SELECTED_ANALYTICS_TILE,
    payload: { data: selectedAnalyticsTile },
});

export const switchToSingleColumn = () => ({
    type: CONVERSATION_RESULT_VIEW.SWITCH_TO_SINGLE_COLUMN,
});

export const switchToTwoColumns = (isGuest) => ({
    type: CONVERSATION_RESULT_VIEW.SWITCH_TO_TWO_COLUMNS,
    payload: { isGuest },
});

export const setViewMode = (viewMode) => ({
    type: CONVERSATION_RESULT_VIEW.SET_VIEW_MODE,
    payload: { viewMode },
});

export const setSelectedUsers = (selectedUsers) => ({
    type: CONVERSATION_RESULT_VIEW.SET_USERS_TYPE,
    payload: { selectedUsers },
});

export const setTranscriptSelectedUsers = (selectedUsers) => ({
    type: CONVERSATION_RESULT_VIEW.SET_TRANSCRIPT_USERS_TYPE,
    payload: { selectedUsers },
});
