import {
    CALENDAR_EVENTS_FETCH_START,
    CALENDAR_EVENTS_FETCH_SUCCESS,
    CALENDAR_EVENTS_FETCH_FAILURE,
    CALENDAR_EVENTS_ID_FETCH_START,
    CALENDAR_EVENTS_ID_FETCH_SUCCESS,
    CALENDAR_EVENTS_ID_FETCH_FAILURE,
    CALENDAR_EVENTS_CLEAR_EVENT_DATA,
} from '@/redux/actions/_exports';

export const initialState = {
    loading: false,
    error: true,
    events: [],
    id: null,
    event: null,
};

function calendarEventsReducer(state = initialState, action) {
    switch (action.type) {
        case CALENDAR_EVENTS_FETCH_START: {
            return {
                ...state,
                dateStart: action.payload.dateStart,
                dateEnd: action.payload.dateEnd,
                loading: true,
                error: false,
            };
        }
        case CALENDAR_EVENTS_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                events: action.payload.events,
            };
        }
        case CALENDAR_EVENTS_FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case CALENDAR_EVENTS_ID_FETCH_START: {
            return {
                ...state,
                id: action.payload.id,
                loading: true,
                error: false,
            };
        }
        case CALENDAR_EVENTS_ID_FETCH_SUCCESS: {
            return {
                ...state,
                loading: false,
                error: false,
                event: action.payload.event,
            };
        }
        case CALENDAR_EVENTS_ID_FETCH_FAILURE: {
            return {
                ...state,
                loading: false,
                error: true,
            };
        }
        case CALENDAR_EVENTS_CLEAR_EVENT_DATA: {
            return {
                ...state,
                id: null,
                event: null,
            };
        }
        default:
            return state;
    }
}

export default calendarEventsReducer;
