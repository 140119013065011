const SEARCH_BASE = 'TRANSCRIPT_SEARCH';

export const SET_SEARCH_VALUE = `${SEARCH_BASE}/SET_SEARCH_VALUE`;
export const SET_IS_OPEN = `${SEARCH_BASE}/SET_IS_OPEN`;
export const SET_IS_AUTOCOMPLETE_OPEN = `${SEARCH_BASE}/SET_IS_AUTOCOMPLETE_OPEN`;
export const RESET_SEARCH = `${SEARCH_BASE}/RESET_SEARCH`;
export const SET_OCCURRENCE = `${SEARCH_BASE}/SET_OCCURRENCE`;
export const PREV_OCCURRENCE = `${SEARCH_BASE}/PREV_OCCURRENCE`;
export const NEXT_OCCURRENCE = `${SEARCH_BASE}/NEXT_OCCURRENCE`;

export const resetSearch = () => ({
    type: RESET_SEARCH,
});

export const setSearchValue = (value, topics = false) => ({
    type: SET_SEARCH_VALUE,
    payload: { data: { value, topics } },
});

export const setIsOpen = (isOpen) => ({
    type: SET_IS_OPEN,
    payload: { data: isOpen },
});

export const setIsAutoCompleteOpen = (isOpen) => ({
    type: SET_IS_AUTOCOMPLETE_OPEN,
    payload: { data: isOpen },
});
export const setOccurrence = (occurrence) => ({
    type: SET_OCCURRENCE,
    payload: { data: occurrence },
});

export const prevOccurrence = () => ({
    type: PREV_OCCURRENCE,
});
export const nextOccurrence = () => ({
    type: NEXT_OCCURRENCE,
});
