import { useInjectDependencies } from '@/redux/sagas/conversationResult/conversationResultSaga';
import { useEffect } from 'react';
import useInjectRedux from '@/utils/saga/useInjectRedux';
import { useDispatch } from 'react-redux';
import recordingReducer from '@/redux/reducers/recordings/recordingReducer';
import recordingSaga from '@/redux/sagas/recordings/recordingSaga';
import { RECORDINGS_KEY } from '@/constants/reduxKeys';
import { updatePagination } from '@/redux/actions/recordings/recordingAction';

export default function RecordingsInjector() {
    useInjectDependencies();
    const dispatch = useDispatch();
    useInjectRedux(RECORDINGS_KEY, recordingReducer, recordingSaga);

    useEffect(() => {
        dispatch(updatePagination());
    }, [dispatch]);

    return null;
}
