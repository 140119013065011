import {
    GEMS_INSIGHTS_FETCH_START,
    GEMS_INSIGHTS_FETCH_SUCCESS,
    GEMS_INSIGHTS_FETCH_FAILURE,
} from '@/redux/actions/gemsInsightsAction';
import { FETCH_STATUS } from '@/constants/fetchStatus';

export const initialState = {
    data: [],
    status: FETCH_STATUS.IDLE,
    error: null,
};

function gemsInsightsReducer(state = initialState, action) {
    switch (action.type) {
        case GEMS_INSIGHTS_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case GEMS_INSIGHTS_FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case GEMS_INSIGHTS_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default gemsInsightsReducer;
