export const getActionCreator = (key) => ({
    rawFetch: () => `${key}/FETCH_START`,
    rawSuccess: () => `${key}/FETCH_SUCCESS`,
    rawFailure: () => `${key}/FETCH_FAILURE`,
    rawCustom: (action) => `${key}/${action}`,
    custom: (action) => ({
        START: `${key}/${action}/START`,
        SUCCESS: `${key}/${action}/SUCCESS`,
        FAILURE: `${key}/${action}/FAILURE`,
    }),
    fetch: () => ({
        FETCH_START: `${key}/FETCH_START`,
        FETCH_SUCCESS: `${key}/FETCH_SUCCESS`,
        FETCH_FAILURE: `${key}/FETCH_FAILURE`,
    }),
    update: () => ({
        UPDATE_START: `${key}/UPDATE_START`,
        UPDATE_SUCCESS: `${key}/UPDATE_SUCCESS`,
        UPDATE_FAILURE: `${key}/UPDATE_FAILURE`,
    }),
});
