import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';

export const SETTINGS_CALENDAR_FETCH_START = 'SETTINGS_CALENDAR/FETCH_START';
export const SETTINGS_CALENDAR_FETCH_SUCCESS =
    'SETTINGS_CALENDAR/FETCH_SUCCESS';
export const SETTINGS_CALENDAR_FETCH_FAILURE =
    'SETTINGS_CALENDAR/FETCH_FAILURE';

export const SETTINGS_CALENDAR_UPDATE_START = 'SETTINGS_CALENDAR/UPDATE_START';
export const SETTINGS_CALENDAR_UPDATE_SUCCESS =
    'SETTINGS_CALENDAR/UPDATE_SUCCESS';
export const SETTINGS_CALENDAR_UPDATE_FAILURE =
    'SETTINGS_CALENDAR/UPDATE_FAILURE';

export const fetchSettingsCalendar = () =>
    fetchData(SETTINGS_CALENDAR_FETCH_START);
export const fetchSettingsCalendarSuccess = (data) =>
    fetchSuccess(SETTINGS_CALENDAR_FETCH_SUCCESS, data);
export const fetchSettingsCalendarFailure = (error) =>
    fetchFailure(SETTINGS_CALENDAR_FETCH_FAILURE, error);
export const settingsCalendarUpdateStart = (data) =>
    updateStart(SETTINGS_CALENDAR_UPDATE_START, data);
export const settingsCalendarUpdateFailure = (error) =>
    updateFailure(SETTINGS_CALENDAR_UPDATE_FAILURE, error);
export const settingsCalendarUpdateSuccess = (data) =>
    updateSuccess(SETTINGS_CALENDAR_UPDATE_SUCCESS, data);
