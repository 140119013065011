import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeOpenText } from '@fortawesome/pro-regular-svg-icons';
import { bool, string } from 'prop-types';

export function AvatarChildren({ isInvite, firstName, lastName }) {
    if (isInvite) {
        return <FontAwesomeIcon icon={faEnvelopeOpenText} />;
    }
    if (firstName && lastName) return firstName[0] + lastName[0];
    return '';
}

AvatarChildren.defaultProps = {
    isInvite: false,
    firstName: '',
    lastName: '',
};

AvatarChildren.propTypes = {
    isInvite: bool,
    firstName: string,
    lastName: string,
};
