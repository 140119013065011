import { put, select, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    COMPANY,
    companyUpdateFailure,
    companyUpdateSuccess,
    companyUploadPhotoSuccess,
    fetchCompanyFailure,
    fetchCompanySuccess,
} from '@/redux/actions/companyAction';
import { errorToString } from '@/utils/error/errorToString';
import {
    getCompanyIdSelector,
    getCompanyPhotoSelector,
} from '@/redux/selectors/companySelector';

async function fechCompany() {
    return axios.get(NEXT_ROUTES.COMPANY);
}

async function updateCompany(data, id) {
    return axios.patch(NEXT_ROUTES.COMPANY_ID(id), data);
}

async function uploadPhoto(companyId, fileObj) {
    const formData = new FormData();
    formData.append('file', fileObj);

    return axios.post(NEXT_ROUTES.COMPANY_ID_PHOTO(companyId, null), formData, {
        headers: {
            'content-type': 'multipart/form-data',
        },
    });
}

async function deletePhoto(companyId, companyPhoto) {
    const lastIndex = companyPhoto.lastIndexOf('/');
    const key = companyPhoto.substr(lastIndex + 1);
    return axios.delete(NEXT_ROUTES.COMPANY_ID_PHOTO(companyId, key));
}

function* getCompanySaga() {
    try {
        const { data: companyData } = yield fechCompany();
        yield put(fetchCompanySuccess(companyData));
    } catch (err) {
        yield put(fetchCompanyFailure(errorToString(err)));
    }
}

function* updateCompanySaga(action) {
    const { data } = action.payload;

    try {
        const id = yield select(getCompanyIdSelector);
        yield updateCompany(data, id);
        yield put(companyUpdateSuccess(data));
    } catch (e) {
        message.error('Company update failed');
        yield put(companyUpdateFailure(errorToString(e)));
    }
}

function* updateCompanyPhoto(action) {
    const { fileObj } = action.payload;

    try {
        const id = yield select(getCompanyIdSelector);
        let photoUrl;
        if (fileObj) {
            const {
                data: { url },
            } = yield uploadPhoto(id, fileObj);
            photoUrl = url;
        } else {
            const currentPhoto = yield select(getCompanyPhotoSelector);
            yield deletePhoto(id, currentPhoto);
        }

        yield put(companyUploadPhotoSuccess(photoUrl));
    } catch (e) {
        message.error('Photo upload failed');
        yield put(companyUpdateFailure(errorToString(e)));
    }
}

export default function* companySaga() {
    yield takeEvery(COMPANY.FETCH_START, getCompanySaga);
    yield takeEvery(COMPANY.UPDATE_START, updateCompanySaga);
    yield takeEvery(COMPANY.UPDATE_PHOTO.START, updateCompanyPhoto);
}
