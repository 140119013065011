import { Fragment } from 'react';
import { node, string } from 'prop-types';
import { Row } from 'antd';
import {
    CleanDivider,
    ColoredSpanTitleWrapper,
} from '@/components/conversations/attendeesChooser/attendeesChooser.styles';

function MainPanel({ title, children, icon }) {
    return (
        <Fragment>
            <Row justify="space-between">
                <ColoredSpanTitleWrapper>{title}</ColoredSpanTitleWrapper>
                {icon}
            </Row>
            <CleanDivider />
            {children}
        </Fragment>
    );
}

MainPanel.defaultProps = {
    icon: null,
};

MainPanel.propTypes = {
    title: string.isRequired,
    children: node.isRequired,
    icon: node,
};

export default MainPanel;
