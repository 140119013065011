import React, { useMemo } from 'react';
import { useRecordingContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/recordingContext';
import { bool, func } from 'prop-types';
import DefaultActions from '@/components/conversations/conversations/headerActionsButton/actions/defaultActions';
import TimerActions from '@/components/conversations/conversations/headerActionsButton/actions/timerActions';
import RecordingDropdown from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/recordingDropdown';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { useConversationContext } from '@/components/conversations/conversations/context/context';
import { useSelector } from 'react-redux';

function RecordingActions({
    setUploadModalVisible,
    clearCurrentConvAndUserTime,
    recoveryDataFound,
    isAutoRecoveryPending,
    launchExternalRecoveryModal,
    clearAllRecoveryData,
}) {
    const userId = useSelector(getUserIdSelector);
    const { conversationId } = useConversationContext();
    const { timerOnlyMode } = useRecordingContext();

    const recordingDropdown = useMemo(
        () => (
            <RecordingDropdown
                onReset={async () => {
                    clearCurrentConvAndUserTime(userId, conversationId);
                    await clearAllRecoveryData();
                }}
            />
        ),
        [
            clearAllRecoveryData,
            clearCurrentConvAndUserTime,
            conversationId,
            userId,
        ],
    );

    if (!timerOnlyMode)
        return (
            <DefaultActions
                setUploadModalVisible={setUploadModalVisible}
                clearCurrentConvAndUserTime={clearCurrentConvAndUserTime}
                isAutoRecoveryPending={isAutoRecoveryPending}
                recoveryDataFound={recoveryDataFound}
                recordingDropdown={recordingDropdown}
                launchExternalRecoveryModal={launchExternalRecoveryModal}
            />
        );

    return (
        <TimerActions
            setUploadModalVisible={setUploadModalVisible}
            recordingDropdown={recordingDropdown}
        />
    );
}

RecordingActions.propTypes = {
    setUploadModalVisible: func.isRequired,
    clearCurrentConvAndUserTime: func.isRequired,
    recoveryDataFound: bool.isRequired,
    isAutoRecoveryPending: bool.isRequired,
    launchExternalRecoveryModal: func.isRequired,
    clearAllRecoveryData: func.isRequired,
};

export default RecordingActions;
