import { getGemSettingsSelector } from '@/redux/selectors/settings/gemSettingsSelector';
import { createSelector } from 'reselect';

export const getTopicTrackerStatus = (state) =>
    state.settings.topicTracker.status;

export const getTopicTracked = (state) =>
    state.settings.topicTracker.data ?? [];

export const gemSettingsTrackedSelector = createSelector(
    [getTopicTracked, getGemSettingsSelector],
    (trackedTopics, gemSettings) =>
        gemSettings.reduce((acc, gemSetting) => {
            if (trackedTopics.some((t) => t.gem_id === gemSetting.gem.id)) {
                return [...acc, gemSetting];
            }
            return acc;
        }, []),
);

export const isGemTrackedSelector = createSelector(
    [getTopicTracked, (state, gemId) => gemId],
    (trackedTopics, gemId) => trackedTopics.some((t) => t.gem_id === gemId),
);
