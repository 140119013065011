export const POLL_STATE = {
    INITIAL: 'INITIAL',
    CONVERSATION_REFETCH: 'REFETCH_CONVERSATION',
    ERRORS: {
        TS_DEFAULT: 'STATUS_ERROR',
        NO_AUDIO: 'STATUS_NO_AUDIO',
        INVALID_DATA: 'STATUS_INVALID_DATA',
        POLLING: 'POLLING_ERROR',
    },
    UPLOADING: 'UPLOADING',
    TS_PRE_PROCESSING: 'TS_PRE_PROCESSING',
    TS_FETCHING: 'TS_FETCHING',
    CLASSIFIERS_FETCHING: 'CLASSIFIERS_FETCHING',
    COMPLETED: 'COMPLETED',
    ERRORS_ARRAY: () => Object.values(POLL_STATE.ERRORS),
    LOADING_STATES: () => [
        POLL_STATE.INITIAL,
        POLL_STATE.CONVERSATION_REFETCH,
        null,
    ],
};

export const TS_ERRORS = {
    DEFAULT: 'error',
    NO_AUDIO: 'no audio',
    ERRORS_ARRAY: () => [TS_ERRORS.DEFAULT, TS_ERRORS.NO_AUDIO],
};

export const POLL_MESSAGES = {
    UPLOAD: {
        PENDING: 'Uploading',
        STATES: ['This may take a few minutes', "We're getting there"],
    },
    TRANSCRIBING: {
        PENDING: 'Transcribing',
        STATES: ['This may take a few minutes', 'Almost done'],
    },
    ANALYZING: {
        PENDING: 'Analyzing',
        STATES: [
            'Finding topics',
            'This may take a few minutes',
            "We're getting there",
            'Almost done',
            'Finding gems',
            'Finding more gems',
            'Polishing gems',
            'Polishing more gems',
            'Getting ready',
            'Perfecting gems',
            'Organizing gems',
            'Almost done',
        ],
    },
    EXCEEDING_TIME:
        'It’s taking longer than expected but nothing to worry about',
};
