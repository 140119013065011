/* eslint-disable indent */
import { getLongestMonologues } from '@/utils/recordings/getLongesMonologues';
import { longestMonologueItems } from '@/constants/conversations/longestMonologueItems';
import { filterSpeakersByType } from '@/utils/conversations/speakers/filterSpeakers';
import { UserTypes } from '@/constants/conversations/userTypes';
import { escapeRegExp } from '@/utils/regExp/escapeRegExp';

export function calculateLongestMonologuesSearchPattern(
    transcript,
    searchValue,
) {
    const { speakers, content } = transcript || { speakers: [] };

    let shouldCalculate = searchValue.length > 0;
    if (shouldCalculate) {
        shouldCalculate = Object.keys(longestMonologueItems).some(
            (v) => longestMonologueItems[v] === searchValue,
        );
    }

    if (!shouldCalculate) return null;

    let longestMonologues;

    if (shouldCalculate) {
        let selectedSpeakers = [];

        switch (searchValue) {
            case longestMonologueItems.top5:
                selectedSpeakers = filterSpeakersByType(
                    UserTypes.everyone,
                    speakers,
                );
                break;
            case longestMonologueItems.top5Contacts:
                selectedSpeakers = filterSpeakersByType(
                    UserTypes.contacts,
                    speakers,
                );
                break;
            case longestMonologueItems.top5Owners:
                selectedSpeakers = filterSpeakersByType(
                    UserTypes.owners,
                    speakers,
                );
                break;
            default:
                selectedSpeakers = [];
                break;
        }

        longestMonologues =
            selectedSpeakers.length === 0
                ? null
                : getLongestMonologues(content, 5, selectedSpeakers);
    }

    return !longestMonologues
        ? null
        : longestMonologues
              .map(({ words }) => words)
              .map((text) => escapeRegExp(text))
              .join('|');
}
