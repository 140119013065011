import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { SIGNUP_PROCESS_KEY } from '@/constants/reduxKeys';
import signUpProcessReducer from '@/redux/reducers/signupProcessReducer';
import signUpProcessSaga from '@/redux/sagas/signupProcessSaga';
import { resetToSignUpPage } from '@/redux/actions/signupProcessAction';

// eslint-disable-next-line react/prop-types
export default function SignUpProcessInjector({ isAuthorized }) {
    useInjectReducer({
        key: SIGNUP_PROCESS_KEY,
        reducer: signUpProcessReducer,
    });
    useInjectSaga({ key: SIGNUP_PROCESS_KEY, saga: signUpProcessSaga });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(resetToSignUpPage());
        }
    }, [dispatch, isAuthorized]);

    return null;
}
