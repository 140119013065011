/* eslint-disable camelcase */
import { QUESTIONS_FILTER_DATA } from '@/components/conversations/results/analytics/summaries/questions/constants/questionsFilter';
import { FETCH_STATUS } from '@/constants/fetchStatus';
import { CONVERSATION_QUESTIONS } from '@/redux/actions/conversation/questionsAction';

const {
    sort: { options: sortOptions },
} = QUESTIONS_FILTER_DATA;

export const initialState = {
    questions: [],
    generalInformation: null,
    questionsQuery: {
        sortBy: sortOptions[0].value,
    },
    questionsIdChecked: [],
    questionIdsLoading: [],
    selectedQuestionIndex: 0,
    status: FETCH_STATUS.IDLE,
    error: null,
};

function conversationQuestionsReducer(state = initialState, action) {
    switch (action.type) {
        case CONVERSATION_QUESTIONS.SET_SELECTED_QUESTION_INDEX: {
            return {
                ...state,
                selectedQuestionIndex: action.payload.data,
            };
        }
        case CONVERSATION_QUESTIONS.SET_QUESTIONS_QUERY: {
            return {
                ...state,
                questionsQuery: {
                    ...state.questionsQuery,
                    ...action.payload.data,
                },
                selectedQuestionIndex: 0,
            };
        }
        case CONVERSATION_QUESTIONS.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case CONVERSATION_QUESTIONS.FETCH_SUCCESS: {
            return {
                ...state,
                ...action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case CONVERSATION_QUESTIONS.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case CONVERSATION_QUESTIONS.LIKE_QUESTION.START:
        case CONVERSATION_QUESTIONS.REMOVE_QUESTION.START:
        case CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.START:
        case CONVERSATION_QUESTIONS.PIN_QUESTION.START: {
            const { id } = action.payload.data;
            return {
                ...state,
                questionIdsLoading: state.questionIdsLoading.includes(id)
                    ? state.questionIdsLoading
                    : [...state.questionIdsLoading, id],
            };
        }
        case CONVERSATION_QUESTIONS.LIKE_QUESTION.SUCCESS:
        case CONVERSATION_QUESTIONS.PIN_QUESTION.SUCCESS: {
            const { id } = action.payload.data;
            return {
                ...state,
                questions: state.questions.map((question) => {
                    if (question.id === id) {
                        return {
                            ...question,
                            ...action.payload.data,
                        };
                    }
                    return question;
                }),
                questionIdsLoading: state.questionIdsLoading.filter(
                    (idLoading) => idLoading !== id,
                ),
            };
        }
        case CONVERSATION_QUESTIONS.REMOVE_QUESTION.SUCCESS: {
            const { id } = action.payload.data;
            return {
                ...state,
                questions: state.questions.filter((q) => q.id !== id),
                questionIdsLoading: state.questionIdsLoading.filter(
                    (idLoading) => idLoading !== id,
                ),
            };
        }
        case CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.SUCCESS: {
            const { question_id } = action.payload.data;
            return {
                ...state,
                questions: state.questions.map((question) => {
                    if (question.id === question_id) {
                        return {
                            ...question,
                            comments: [
                                ...question.comments,
                                action.payload.data,
                            ],
                        };
                    }
                    return question;
                }),
                questionIdsLoading: state.questionIdsLoading.filter(
                    (idLoading) => idLoading !== question_id,
                ),
            };
        }
        case CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.FAILURE:
        case CONVERSATION_QUESTIONS.PIN_QUESTION.FAILURE:
        case CONVERSATION_QUESTIONS.REMOVE_QUESTION.FAILURE:
        case CONVERSATION_QUESTIONS.LIKE_QUESTION.FAILURE: {
            return {
                ...state,
                questionIdsLoading: state.questionIdsLoading.filter(
                    (id) => id !== action.payload.data.id,
                ),
            };
        }
        case CONVERSATION_QUESTIONS.CHECK_QUESTION: {
            if (state.questionsIdChecked.includes(action.payload.data.id)) {
                return state;
            }
            return {
                ...state,
                questionsIdChecked: [...state.questionsIdChecked, action.payload.data.id],
            };
        }
        case CONVERSATION_QUESTIONS.CHECK_ALL_QUESTIONS: {
            return {
                ...state,
                questionsIdChecked: state.questions.map(q => q.id),
            };
        }
        case CONVERSATION_QUESTIONS.UNCHECK_ALL_QUESTIONS: {
            return {
                ...state,
                questionsIdChecked: [],
            };
        }
        case CONVERSATION_QUESTIONS.UNCHECK_QUESTION: {
            if (state.questionsIdChecked.includes(action.payload.data.id)) {
                return {
                    ...state,
                    questionsIdChecked: state.questionsIdChecked.filter(
                        (id) => id !== action.payload.data.id,
                    ),
                };
            }
            return state;
        }
        case CONVERSATION_QUESTIONS.SET_QUESTIONS_FILTERS: {
            return {
                ...state,
                questionsFilter: {
                    ...state.questionsFilter,
                    ...action.payload.data,
                },
                questionsIdChecked: [],
            }
        }
        default:
            return state;
    }
}

export default conversationQuestionsReducer;
