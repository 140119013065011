// import Script from 'next/script';

function SalesIQ() {
    return (
        // FIXME: WORK ON FIX OF SALESIQ SCRIPT
        // <Script
        //     type="text/javascript"
        //     src="https://desk.zoho.com/portal/api/web/inapp/346308000004603005?orgId=680014010"
        //     strategy="beforeInteractive"
        // />
        null
    );
}

export default SalesIQ;
