import styled from 'styled-components';
import { Modal, Upload } from 'antd';
import { GRAY_1, GRAY_3, GRAY_4, VIVIDORANGE } from '@/constants/colors';

const { Dragger } = Upload;

const StyledModal = styled(Modal)`
    .ant-upload-text-icon {
        display: none;
    }

    .ant-upload-list-item-name {
        display: flex;
        align-items: center;

        svg {
            width: 20px !important;
            height: 20px !important;
            color: ${GRAY_4};
        }

        button {
            border-color: ${VIVIDORANGE};
        }
    }
`;

const StyledDragger = styled(Dragger)`
    background: white !important;
    border-radius: 9px !important;
    margin-bottom: 1rem;

    .ant-upload {
        padding: 2.125rem 0 1.75rem 0 !important;
    }

    .ant-upload-text {
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        color: ${GRAY_1};

        span {
            color: ${VIVIDORANGE};
        }
    }

    .ant-upload-hint {
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.25rem;
        color: ${GRAY_3};
    }
`;

const UploadListContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

const StyledDivider = styled.span`
    display: flex;
    width: 100%;
    border-top: 1px solid ${GRAY_4};
`;

const ModalContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 1rem;
`;

export {
    StyledModal,
    StyledDragger,
    UploadListContainer,
    StyledDivider,
    ModalContainer,
};
