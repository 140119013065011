import styled from 'styled-components';
import { GRAY_1, GRAY_4 } from '@/constants/colors';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
`;

const FileNameContainer = styled.div`
    display: grid;
    grid-template-columns: 1rem 1fr auto;
    column-gap: 1rem;
    align-items: center;
`;

const FileName = styled.p`
    margin: 0;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${GRAY_1};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .ant-upload-list-item {
        margin-top: 0 !important;
    }
    .ant-upload-list-item-name {
        display: block !important;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
`;

const IconContainer = styled.span`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    color: ${GRAY_4};
`;

const ListItemContainer = styled.div`
    display: grid;
    grid-template-columns: 1rem 1fr auto;
    column-gap: 1rem;
    align-items: center;
    padding: 0.25rem 0;
`;

const ProgressBarWithMessage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        margin-left: 0.5rem;
    }
`;

export {
    Container,
    FileNameContainer,
    FileName,
    IconContainer,
    ListItemContainer,
    ProgressBarWithMessage,
};
