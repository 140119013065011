export const VIVIDORANGE = '#F8B200';
export const VIVIDORANGECREAM = '#FFFCE6';
export const VIVIDORANGECREAMDARK = '#f3f0db';
export const VARDEN = '#FFF6DE';
export const ORANGE = '#fef1c7';
export const PALEYELLOW = '#FEFCE6';
export const GRAY = '#CDCFD3';
export const MEDIUM_GRAY = '#E9EBEF';
export const LIGHTGRAY = '#F7F7F7';
export const PINBALL = `#D3D3D3`;
export const PALEGRAY = '#F0F0F0';
export const WHITE = '#FFFFFF';
export const BLACK = '#000000';
export const DAYBREAKBLUE4 = '#69c0ff';
export const ATHENSGRAY = '#F0F2F5';
export const PALERED = '#DA9B9A';
export const PALEPINK = '#ECD1E5';
export const CYAN = '#DFFEF8';
export const PALEPURPLE = '#C4C9F9';
export const VERYLIGHTGRAY = '#F0F0F0';
export const DARKGRAY = '#505050';
export const DODGERBLUE = '#1890ff';
export const SILVER = '#c7c7c7';
export const DUSTYGRAY = '#989898';
// todo replace with real pallette
export const SECONDARY_COLOR = '#8C8C8C';
export const CHECK_CIRCLE_TWO_TONE_COLOR = '#52c41a';
export const BABY_BLUE = '#E0F0FF';
export const YELLOW_5 = '#F8B200';
export const TRANSPARENT_VIVID_ORANGE = 'rgba(255,202,36,0.75)';
export const TRANSPARENT_DODGER_BLUE = 'rgba(24,144,255, 0.75)';
export const RED = '#EB5757';
export const BACKGROUND_GRAY = '#fafafa';
export const KEYWORD_BLUE = 'rgba(79,68,255,0.2)';
export const KEYWORD_BLUE_ACTIVE = 'rgba(79,68,255,0.5)';
export const LIGHT_YELLOW = 'rgba(255,246,222,0.4)';
export const DISABLED_TS_COLOR = 'rgba(0,0,0,0.08)';

export const BACKGROUND_COLOR = '#FAFAFA';

// NEW COLOR PALLETE
export const GRAY_0 = '#E0E0E0';
export const GRAY_1 = '#333333';
export const GRAY_2 = '#4F4F4F';
export const GRAY_3 = '#828282';
export const GRAY_4 = '#BDBDBD';
export const GRAY_5 = '#E0E0E0';
export const GRAY_6 = '#F2F2F2';
export const GRAY_7 = '#8C8C8C';
export const GRAY_8 = '#595959';
export const GRAY_9 = '#262626';
export const GRAY_10 = '#000000';

export const GRAY_25 = '#717171';

export const VERSATIONAL = '#F8B200';
export const VERSATIONAL_2 = '#FEF4C6';
export const VERSATIONAL_3 = '#FFFCF0';

export const GREEN_2 = '#27AE60';
export const BLUE_2 = '#2D9CDB';

export const SENTIMENT = {
    '-100': '#FF595D',
    '-90': '#FF7578',
    '-80': '#FF8E8E',
    '-70': '#FF9F9F',
    '-60': '#FFADAF',
    '-50': '#FFC3C5',
    '-40': '#FFCED0',
    '-30': '#FFDCDD',
    '-20': '#FFE7E7',
    '-10': '#FBEDEC',
    0: '#E3E3E3',
    10: '#CDE5CD',
    20: '#BCE3BC',
    30: '#ABDFAB',
    40: '#94D894',
    50: '#81CF81',
    60: '#69C869',
    70: '#5FC15F',
    80: '#37B637',
    90: '#28A528',
    100: '#289728',
};

export const BLUE_1 = '#2F80ED';

export const GOLD_1 = '#FFFBE6';
export const GOLD_3 = '#FFE58F';
export const GOLD_6 = '#FAAD14';
