import React, { useMemo } from 'react';
import Link from 'next/link';
import { Skeleton } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarWeek } from '@fortawesome/free-solid-svg-icons';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import durationPlugin from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import {
    BackButton,
    HeaderTime,
    HeaderTitle,
    TitleContainer,
} from '@/components/conversations/conversations/hooks/pageTitle.styles';
import { ROUTES } from '@/constants/routes';
import { GRAY_3 } from '@/constants/colors';
import fromNow from '@/utils/dates/fromNow';
import { string } from 'prop-types';

dayjs.extend(relativeTime);
dayjs.extend(durationPlugin);
dayjs.extend(advancedFormat);

function DateWithDiff({ conversationDate }) {
    if (!conversationDate) return null;
    const formattedDate = conversationDate.format(`dddd, MMMM Do h:mm A`);
    const fromNowValue = fromNow(conversationDate.toISOString());
    const fromNowString = fromNowValue ? ` ( ${fromNowValue} )` : '';
    return `${formattedDate}${fromNowString}`;
}

DateWithDiff.defaultProps = {
    conversationDate: null,
};

DateWithDiff.propTypes = {
    conversationDate: string,
};

function usePageTitle(loading, conversationTitle, conversationDate) {
    return useMemo(
        () => (
            <TitleContainer>
                <Link
                    href={`${
                        ROUTES.CALENDAR_DASHBOARD
                    }?date=${conversationDate?.format('YYYY-MM-DD')}`}
                    passHref
                >
                    <BackButton />
                </Link>
                {loading ? (
                    <Skeleton paragraph={{ rows: 1 }} title={{ width: 200 }} />
                ) : (
                    <div>
                        <HeaderTitle>{conversationTitle}</HeaderTitle>
                        <HeaderTime>
                            <FontAwesomeIcon
                                color={GRAY_3}
                                icon={faCalendarWeek}
                            />
                            &nbsp;
                            <DateWithDiff conversationDate={conversationDate} />
                        </HeaderTime>
                    </div>
                )}
            </TitleContainer>
        ),
        [conversationTitle, conversationDate, loading],
    );
}

export default usePageTitle;
