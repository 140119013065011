import axios, { CancelToken } from 'axios';
import { CANCEL } from 'redux-saga';

const instance = axios.create({
    headers: {
        apikey:
            process.env.INTELIGEMS_API_PUBLIC_KEY ||
            process.env.NEXT_PUBLIC_INTELIGEMS_API_PUBLIC_KEY,
    },
    withCredentials: true,
});

instance?.interceptors.request.use((config) => {
    const newConfig = { ...config };
    newConfig.errorContext = new Error('Thrown at:');
    return newConfig;
});

instance?.interceptors.response.use(undefined, async (error) => {
    const newErr = { ...error };
    const apiData = JSON.stringify(error.response?.data);
    if (apiData) {
        newErr.message = apiData;
    }

    throw newErr;
});

export function fetchAPISaga(url, params = {}) {
    const source = CancelToken.source();
    const request = instance.get(url, { cancelToken: source.token, ...params });
    request[CANCEL] = () => source.cancel();
    return request;
}

export default instance;
