import {
    forwardRef,
    Fragment,
    useEffect,
    useImperativeHandle,
    useReducer,
    useRef,
} from 'react';
import { Button, Form, Input, Spin } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    ColoredCard,
    ColoredDivColumn,
    ColoredDivFormRow,
} from '@/components/settings/team/inviteTeamMembers/inviteTeamMembers.styles';
import { USER_ROLES } from '@/constants/userRoles';
import {
    changeRole,
    clean,
    teamMembersReducer,
    valueChange,
} from '@/components/settings/team/inviteTeamMembers/localTeamMemberReducer';
import UserRoleSelect from '@/components/settings/team/userRoleSelect/userRoleSelect';
import { getUserSelector } from '@/redux/selectors/authSelector';
import { invitesSend } from '@/redux/actions/invitesAction';
import { getInvitesUpdateLoading } from '@/redux/selectors/invitesSelector';
import { arrayOf, string, func } from 'prop-types';


const getTransformedFormValues = (formData) => {
    const groupedValues = Object.entries(formData.users).reduce((acc, curr) => {
        const [key, id] = curr[0].split(':');

        return {
            ...acc,
            [id]: { ...acc[id], [key]: curr[1] },
        };
    }, {});

    // Ant d form somehow recreates items in form
    return Object.values(groupedValues)
        .filter((v) => v.email)
        .reduce((acc, curr) => {
            const containsMail = acc.some(({ email }) => email === curr.email);
            return containsMail
                ? acc.map((v) => (v.email === curr.email ? curr : v))
                : [...acc, curr];
        }, []);
};

// eslint-disable-next-line react/display-name
const InviteTeamMembers = forwardRef(({prepopulatedEmails, setTeamMembersInvited}, ref) => {
    const user = useSelector(getUserSelector);
    const formRef = useRef();
    const [form] = Form.useForm();
    const lastItemRef = useRef();
    const itemsLengthRef = useRef(1);

    const initialTeamMembers = prepopulatedEmails && prepopulatedEmails.length
        ? prepopulatedEmails.map((email, index) => ({
            id: index + 1,
            value: email,
            role: USER_ROLES.USER,
        }))
        : [{ id: 1, value: '', role: USER_ROLES.USER }];

    const [teamMembersToInvite, dispatchLocal] = useReducer(
        teamMembersReducer,
        initialTeamMembers,
    );

    const dispatch = useDispatch();
    const loading = useSelector(getInvitesUpdateLoading);

    useImperativeHandle(ref, () => ({
        focus: () => {
            lastItemRef?.current?.focus();
        },
    }));

    useEffect(() => {
        if (teamMembersToInvite.length < itemsLengthRef.current) {
            lastItemRef?.current?.focus();
        }
        itemsLengthRef.current = teamMembersToInvite.length;
    }, [teamMembersToInvite, prepopulatedEmails]);

    useEffect(() => {
        dispatchLocal({ type: 'SET_INITIAL_TEAM_MEMBERS', payload: initialTeamMembers });
    }, [prepopulatedEmails]);

    const onSuccess = () => {
        dispatchLocal(clean());
        form.resetFields();
        setTeamMembersInvited(true);
    };

    return (
        <ColoredDivColumn>
            <Spin spinning={loading}>
                <ColoredCard>
                    <Form
                        form={form}
                        ref={formRef}
                        validateTrigger="onSubmit"
                        onFinish={async (e) => {
                            const data = getTransformedFormValues(e);

                            if (data.length !== 0) {
                                const dto = data.map(({ role, email, id }) => ({
                                    email,
                                    permission_role: role,
                                    id,
                                }));
                                dispatch(invitesSend(dto, { onSuccess }));
                            }
                        }}
                    >
                        <Form.List name="users">
                            {() => (
                                <>
                                    {teamMembersToInvite.map(
                                        ({ id, value, role }) => (
                                            <Fragment key={id}>
                                                <ColoredDivFormRow>
                                                    <Form.Item
                                                        name={`email:${id}`}
                                                        initialValue={value || ''}
                                                        rules={[
                                                            {
                                                                type: 'email',
                                                                message: 'Invalid email value',
                                                            },
                                                            {
                                                                validator: (_role, val) =>
                                                                    val !== user.email ? Promise.resolve() : Promise.reject(new Error("You can't invite yourself.")),
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            value={value}
                                                            ref={lastItemRef}
                                                            disabled={loading}
                                                            placeholder="Email"
                                                            onChange={(e) =>
                                                                dispatchLocal(
                                                                    valueChange(
                                                                        id,
                                                                        e.target
                                                                            .value,
                                                                    ),
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                    <Form.Item
                                                        name={`role:${id}`}
                                                        initialValue={role}
                                                    >
                                                        <UserRoleSelect
                                                            disabled={loading}
                                                            currentUserRole={
                                                                user.permission_role
                                                            }
                                                            value={role}
                                                            onChange={(_role) =>
                                                                dispatchLocal(
                                                                    changeRole(
                                                                        id,
                                                                        _role,
                                                                    ),
                                                                )
                                                            }
                                                        />
                                                    </Form.Item>
                                                </ColoredDivFormRow>
                                            </Fragment>
                                        ),
                                    )}
                                </>
                            )}
                        </Form.List>
                        <Button
                            htmlType="submit"
                            aria-label="Invite"
                            disabled={loading}
                            type="primary"
                        >
                            Invite
                        </Button>
                    </Form>
                </ColoredCard>
            </Spin>
        </ColoredDivColumn>
    );
});

InviteTeamMembers.propTypes = {
    prepopulatedEmails: arrayOf(string),
    setTeamMembersInvited: func,
};

InviteTeamMembers.defaultProps = {
    prepopulatedEmails: [],
    setTeamMembersInvited: () => {}
};

export default InviteTeamMembers;
