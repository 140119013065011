import { useEffect, useRef } from 'react';
import { POLL_STATE } from '@/constants/poll/pollState';
import {
    showError,
    showSuccess,
} from '@/components/conversations/conversations/headerActionsButton/utils';
import ConversationButtonLink from '@/components/insights/recordings/fields/conversationButtonLink';
import { notification } from 'antd';

const conversationReadyMessage = (conversationId, title) => ({
    message: 'Your conversation is ready',
    description: (
        <ConversationButtonLink conversationId={conversationId} title={title} />
    ),
    duration: 10,
    placement: 'bottomRight',
});

const showConversationReady = (conversationId, title) => {
    notification.success(conversationReadyMessage(conversationId, title));
};

export default function useHandleStatusChange(
    pollStatus,
    initialConversationData,
    polledConversationData,
    onCompletion,
) {
    const uploadMessageShownRef = useRef(false);
    const errorMessageShownRef = useRef(false);
    const completedMessageShownRef = useRef(false);

    useEffect(() => {
        if (
            initialConversationData &&
            typeof initialConversationData !== 'string'
        ) {
            const notProcessedInitially =
                initialConversationData.transcription_status === null;
            if (
                notProcessedInitially &&
                pollStatus === POLL_STATE.TS_FETCHING &&
                !uploadMessageShownRef.current
            ) {
                showSuccess(
                    `File uploaded successfully. You will be notified when it is processed.`,
                );
                uploadMessageShownRef.current = true;
            }

            if (
                POLL_STATE.ERRORS_ARRAY().includes(pollStatus) &&
                !errorMessageShownRef.current &&
                pollStatus !== POLL_STATE.ERRORS.POLLING
            ) {
                if (pollStatus === POLL_STATE.ERRORS.NO_AUDIO) {
                    showError(`Error analyzing file, no audio detected.`);
                } else if (pollStatus === POLL_STATE.ERRORS.INVALID_DATA) {
                    showError(
                        `Error uploading file, your file may be contaminated. Please try a different file.`,
                    );
                } else {
                    showError(`Error analyzing file.`);
                }
                errorMessageShownRef.current = true;
            }

            if (
                pollStatus === POLL_STATE.COMPLETED &&
                !completedMessageShownRef.current
            ) {
                showConversationReady(
                    polledConversationData.id,
                    polledConversationData.title,
                );

                completedMessageShownRef.current = true;

                if (onCompletion) {
                    onCompletion();
                }
            }
        }
    }, [
        initialConversationData,
        onCompletion,
        pollStatus,
        polledConversationData?.id,
        polledConversationData?.title,
    ]);
}
