import { useRouter } from 'next/router';
import { useEffect, useRef } from 'react';
import throttle from 'lodash/throttle';
import { useSelector } from 'react-redux';
import { useInterval } from '@/utils/useInterval/useInterval';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { getIsAuthorized } from '@/redux/selectors/authSelector';

const SESSION_ITEM_KEY = 'session';
const SESSION_FAIL_REFRESH_INTERVAL = 20 * 1000;
const USER_INACTIVITY_THRESHOLD = 3 * 60 * 1000;
const SESSION_VALIDITY_THRESHOLD = 30 * 1000;
const SESSION_REFRESH_INTERVAL = 10 * 1000;

const getSessionItem = () => {
    const sessionItem = window.localStorage.getItem(SESSION_ITEM_KEY);
    return sessionItem ? JSON.parse(sessionItem) : null;
};

const saveSessionItem = (id) =>
    window.localStorage.setItem(
        SESSION_ITEM_KEY,
        JSON.stringify({
            id,
            timestamp: Date.now(),
        }),
    );

const createNewSession = async () => {
    try {
        const {
            data: { id },
        } = await axios.post(NEXT_ROUTES.SESSION);
        return id;
    } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('Session creation failed');
        setTimeout(createNewSession, SESSION_FAIL_REFRESH_INTERVAL);
    }
    return null;
};

const refreshSession = async (id, viewPath, isUserActive) => {
    try {
        await axios.post(NEXT_ROUTES.SESSION_ID(id), {
            context: viewPath,
            event: isUserActive ? 'USER_ACTIVE' : 'USER_IDLE',
            type: 'POINT',
        });
    } catch (err) {
        // do nth
    }
};

function ActivityTracker() {
    const router = useRouter();
    const sessionRef = useRef();
    const activityRef = useRef();

    const currentSessionId = sessionRef.current;
    const setCurrentSessionId = (id) => {
        sessionRef.current = id;
    };

    useEffect(() => {
        const lastActivityTimeListener = () => {
            activityRef.current = Date.now();
        };

        const throttleDelay = 1000;
        const mouseEventListener = throttle(
            lastActivityTimeListener,
            throttleDelay,
        );
        const keydownEventListener = throttle(
            lastActivityTimeListener,
            throttleDelay,
        );

        window.addEventListener('mousemove', mouseEventListener);
        window.addEventListener('keydown', keydownEventListener);
        return () => {
            window.removeEventListener('mousemove', mouseEventListener);
            window.removeEventListener('keydown', keydownEventListener);
        };
    }, []);

    useEffect(() => {
        (async () => {
            const storageSessionItem = getSessionItem();

            // Used in order to prevent new session creation on new tab opening
            const timeSinceLastSessionUpdate = storageSessionItem
                ? Date.now() - storageSessionItem.timestamp
                : Number.MAX_VALUE;

            if (timeSinceLastSessionUpdate < SESSION_VALIDITY_THRESHOLD) {
                setCurrentSessionId(storageSessionItem.id);
            } else {
                const id = await createNewSession();
                if (id) {
                    saveSessionItem(id);
                    setCurrentSessionId(id);
                }
            }
        })();
    }, []);

    useInterval(() => {
        const isSessionCreated = !!currentSessionId;
        const isTabActive = !document.hidden;

        if (isSessionCreated && isTabActive) {
            (async () => {
                const timeSinceLastUserActivity =
                    Date.now() - activityRef.current;
                const isUserActive =
                    timeSinceLastUserActivity < USER_INACTIVITY_THRESHOLD;

                saveSessionItem(currentSessionId);
                await refreshSession(
                    currentSessionId,
                    router?.asPath,
                    isUserActive,
                );
            })();
        }
    }, SESSION_REFRESH_INTERVAL);

    return null;
}

export default function AuthorizedActivityTracker() {
    const isAuthorized = useSelector(getIsAuthorized);
    if (!isAuthorized) return null;
    return <ActivityTracker />;
}
