import { Dropdown, Menu, Modal, Space } from 'antd';
import React, { Fragment } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { func } from 'prop-types';
import { TimerState } from '@/components/timer/useTimer';
import {
    ChevronDownIcon,
    RecordIcon,
    ResetTimerIcon,
    StopTimerIcon,
    TimerIcon,
} from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import {
    useTimeContext,
    useTimerContext,
} from '@/components/timer/timerContext';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';
import { useRecordingContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/recordingContext';

const { confirm } = Modal;

function RecordingDropdown({ onReset }) {
    const { timerOnlyMode, setTimerOnlyMode } = useRecordingContext();

    const { timerState, start, pause, updateState } = useTimerContext();

    const seconds = useTimeContext();

    const { stopRecording, pauseRecording, resumeRecording } =
        useMediaRecorderContext();

    const showResetConfirm = () => {
        confirm({
            title: 'Are you sure you want to delete your recording and start a new one?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                setTimerOnlyMode(false);
                updateState(TimerState.idle, 0);
                stopRecording();
                onReset();
            },
        });
    };

    const showResetTimerConfirm = () => {
        confirm({
            title: 'Are you sure you want to reset the time to 0?',
            icon: <ExclamationCircleOutlined />,
            okText: 'Yes',
            okType: 'danger',
            cancelText: 'No',
            onOk() {
                updateState(TimerState.idle, 0);
                setTimerOnlyMode(false);
                onReset();
            },
        });
    };

    return (
        <span
            role="none"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <Dropdown
                trigger="click"
                overlay={
                    <Menu
                        onClick={({ key }) => {
                            switch (key) {
                                case 'reset':
                                    if (!timerOnlyMode) {
                                        showResetConfirm();
                                    } else {
                                        showResetTimerConfirm();
                                    }
                                    break;
                                case 'stop':
                                    pauseRecording();
                                    pause();
                                    break;
                                case 'resume':
                                    resumeRecording();
                                    start();
                                    break;
                                case 'startTimer':
                                    setTimerOnlyMode(true);
                                    start();
                                    break;
                                default:
                                    break;
                            }
                        }}
                    >
                        {seconds ? (
                            <Fragment>
                                {timerState === TimerState.running ? (
                                    <Menu.Item key="stop">
                                        <StopTimerIcon data-testid="stop-timer-icon" />
                                        {timerOnlyMode
                                            ? 'Stop Timer'
                                            : 'Stop Recording'}
                                    </Menu.Item>
                                ) : (
                                    <Menu.Item key="resume">
                                        <Space size={8}>
                                            <RecordIcon small />
                                            Resume
                                        </Space>
                                    </Menu.Item>
                                )}
                                <Menu.Item key="reset">
                                    <ResetTimerIcon data-testid="reset-timer-icon" />
                                    Reset
                                </Menu.Item>
                            </Fragment>
                        ) : (
                            <Menu.Item key="startTimer">
                                <TimerIcon />
                                Start Timer Only
                            </Menu.Item>
                        )}
                    </Menu>
                }
                placement="bottomCenter"
                arrow
            >
                <ChevronDownIcon data-testid="recording-dropdown-chevron" />
            </Dropdown>
        </span>
    );
}

RecordingDropdown.defaultProps = {
    onReset: () => {},
};

RecordingDropdown.propTypes = {
    onReset: func,
};

export default RecordingDropdown;
