import Script from 'next/script';
import { useSelector } from 'react-redux';
import {
    getIsTrial,
    getIsLive,
} from '@/redux/selectors/subscriptionSelector';

function UserGuide() {
    const isTrial = useSelector(getIsTrial);
    const isLive = useSelector(getIsLive);

    if (isTrial || isLive) {
        return <Script type="text/javascript" src="/vendor/userGuide.js" />;
    }

    return <></>;
}

export default UserGuide;
