export const QUESTIONS_SORT_VARIANTS = {
    insightsGenerated: 'insightsGenerated',
    time: 'time',
};

export const QUESTIONS_FILTER_DATA = {
    sort: {
        name: 'sortBy',
        options: [
            {
                title: 'Time',
                value: QUESTIONS_SORT_VARIANTS.time,
            },
            {
                title: 'Insights Generated',
                value: QUESTIONS_SORT_VARIANTS.insightsGenerated,
            },
        ],
        outerLabel: 'Sort by:',
    },
};
