import { put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchSettingsRecordingsFailure,
    fetchSettingsRecordingsSuccess,
    SETTINGS_RECORDINGS_FETCH_START,
    SETTINGS_RECORDINGS_UPDATE_START,
    settingsRecordingsUpdateFailure,
    settingsRecordingsUpdateSuccess,
} from '@/redux/actions/settings/recordingsAction';

async function fetchRecordingsSettings() {
    return axios.get(NEXT_ROUTES.SETTINGS_RECORDINGS);
}

async function updateRecordingsSetting(data) {
    return axios.patch(NEXT_ROUTES.SETTINGS_RECORDINGS, data);
}

function* getRecordingsSettings() {
    try {
        const { data } = yield fetchRecordingsSettings();
        yield put(fetchSettingsRecordingsSuccess(data));
    } catch (err) {
        yield put(fetchSettingsRecordingsFailure(err));
    }
}

function* updateRecordingsSettings(action) {
    const data = {
        ...action.payload.data,
    };

    try {
        const { data: updateData } = yield updateRecordingsSetting(data);
        yield put(settingsRecordingsUpdateSuccess(updateData));
    } catch (e) {
        message.error('Settings update failed');
        yield put(settingsRecordingsUpdateFailure(e));
    }
}

export default function* settingsRecordingsSaga() {
    yield takeEvery(SETTINGS_RECORDINGS_FETCH_START, getRecordingsSettings);
    yield takeEvery(SETTINGS_RECORDINGS_UPDATE_START, updateRecordingsSettings);
}
