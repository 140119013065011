import {
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('MEETING_ID');

export const MEETING = {
    ...creator.fetch(),
};

export const fetchMeetingId = (id) => fetchData(MEETING.FETCH_START, { id });
export const fetchMeetingIdSuccess = (data) =>
    fetchSuccess(MEETING.FETCH_SUCCESS, data);
export const fetchMeetingIdFailure = (error) =>
    fetchFailure(MEETING.FETCH_FAILURE, error);
