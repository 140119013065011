import { put, take } from 'redux-saga/effects';

import {
    ASSEMBLY_TRANSCRIPT_FETCH_FAILURE,
    ASSEMBLY_TRANSCRIPT_FETCH_SUCCESS,
    fetchAssemblyTranscript,
} from '@/redux/actions/assemblyTranscriptAction';

export function* getAssemblyAi(id) {
    yield put(fetchAssemblyTranscript(id));
    const assemblyFetchAction = yield take([
        ASSEMBLY_TRANSCRIPT_FETCH_SUCCESS,
        ASSEMBLY_TRANSCRIPT_FETCH_FAILURE,
    ]);
    if (assemblyFetchAction.type === ASSEMBLY_TRANSCRIPT_FETCH_FAILURE)
        throw new Error(
            'Conversation result failed on assembly transcript fetch',
        );

    return assemblyFetchAction.payload.data;
}
