import styled from 'styled-components';

export const ChatTrigger = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999999;
    cursor: pointer;
    border-radius: 50%;
`;
