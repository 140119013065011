import {
    CALENDAR_PROVIDER_FETCH_FAILURE,
    CALENDAR_PROVIDER_FETCH_START,
    CALENDAR_PROVIDER_FETCH_SUCCESS,
    CALENDAR_PROVIDER_UPDATE,
} from '@/redux/actions/_exports';
import { FETCH_STATUS } from '@/constants/fetchStatus';

export const initialState = {
    status: FETCH_STATUS.IDLE,
    error: true,
    provider: null,
};

function calendarProviderReducer(state = initialState, action) {
    switch (action.type) {
        case CALENDAR_PROVIDER_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: false,
            };
        }
        case CALENDAR_PROVIDER_FETCH_SUCCESS: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: false,
                provider: action.payload.provider,
            };
        }
        case CALENDAR_PROVIDER_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                loading: false,
                error: true,
            };
        }
        case CALENDAR_PROVIDER_UPDATE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: false,
                provider: action.payload.provider,
            };
        }
        default:
            return state;
    }
}

export default calendarProviderReducer;
