/* eslint-disable react/prop-types,indent */
import React from 'react';
import {
    StyledPLoadingInfo,
    StyledUploadWrapper,
    SuccessTextWrapper,
} from '@/components/table/tableCell/tableCell.styles';
import {
    POLL_MESSAGES,
    POLL_STATE,
    TS_ERRORS,
} from '@/constants/poll/pollState';
import { BLUE_1 } from '@/constants/colors';
import { ProgressIndicator } from '@/components/insights/recordings/fields/upload/progressIndicator';
import SuccessResult from '@/components/insights/recordings/fields/upload/successResult';

// eslint-disable-next-line react/prop-types
function UploadProgress({
    status,
    isError,
    percentage,
    conversation,
    uploadPercentage,
    reloadComponent,
}) {
    const isCompleted = status === POLL_STATE.COMPLETED;
    const percentageString = `${percentage || '0'}%`;

    if (status === POLL_STATE.UPLOADING) {
        return (
            <StyledUploadWrapper>
                <ProgressIndicator
                    text={`Uploading... ${
                        uploadPercentage ?? percentage ?? 0
                    }%`}
                    percentage={uploadPercentage}
                    messages={POLL_MESSAGES.UPLOAD.STATES}
                    color={BLUE_1}
                    maxTime={10 * 1000}
                />
            </StyledUploadWrapper>
        );
    }

    if (isError && status !== POLL_STATE.ERRORS.POLLING) {
        if (reloadComponent) {
            reloadComponent();
        }
        return (
            <StyledUploadWrapper>
                <SuccessTextWrapper>
                    <StyledPLoadingInfo $error>
                        {conversation?.transcription_status ===
                        TS_ERRORS.NO_AUDIO
                            ? 'No audio detected'
                            : 'Error during analysis'}
                    </StyledPLoadingInfo>
                </SuccessTextWrapper>
            </StyledUploadWrapper>
        );
    }

    if (
        status === POLL_STATE.INITIAL ||
        status === POLL_STATE.CONVERSATION_REFETCH
    ) {
        return (
            <StyledUploadWrapper>
                <ProgressIndicator text="Preparing..." color="lightgray" />
            </StyledUploadWrapper>
        );
    }

    if (status === POLL_STATE.TS_FETCHING) {
        return (
            <StyledUploadWrapper>
                <ProgressIndicator
                    text={`${POLL_MESSAGES.TRANSCRIBING.PENDING}... ${percentageString}`}
                    messages={POLL_MESSAGES.TRANSCRIBING.STATES}
                    percentage={percentage}
                    maxTime={60 * 1000}
                />
            </StyledUploadWrapper>
        );
    }

    if (status === POLL_STATE.CLASSIFIERS_FETCHING) {
        return (
            <StyledUploadWrapper>
                <ProgressIndicator
                    text={`${POLL_MESSAGES.ANALYZING.PENDING}... ${percentageString}`}
                    messages={POLL_MESSAGES.ANALYZING.STATES}
                    percentage={percentage}
                    maxTime={2 * 60 * 1000}
                />
            </StyledUploadWrapper>
        );
    }

    if (!isCompleted) {
        return (
            <StyledUploadWrapper>
                <ProgressIndicator
                    text={`Processing... ${percentageString}`}
                    percentage={percentage}
                    color={BLUE_1}
                />
            </StyledUploadWrapper>
        );
    }

    return (
        <StyledUploadWrapper>
            <SuccessResult insightCount={conversation?.unconfirmed_insights} summarization={conversation.short_summarization} />
        </StyledUploadWrapper>
    );
}

export default React.memo(UploadProgress);
