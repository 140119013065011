import { INSIGHTS_TABS } from '@/constants/conversations/insights';
import { TRANSCRIPT } from '@/redux/actions/conversation/transcript';
import {
    INSIGHTS_BULK_CHECK_QUOTE,
    INSIGHTS_BULK_UNCHECK_QUOTE,
    INSIGHTS_CHECK_QUOTE,
    INSIGHTS_CLOSE_ADD_EVENT_MODAL,
    INSIGHTS_CLOSE_ADD_TASK_MODAL,
    INSIGHTS_OPEN_ADD_EVENT_MODAL,
    INSIGHTS_OPEN_ADD_TASK_MODAL,
    INSIGHTS_RESET_QUOTE_ACTIVE,
    INSIGHTS_SET_INSIGHTS_FILTERS,
    INSIGHTS_SET_EXCLUDED_GEMS,
    INSIGHTS_SET_EXCLUDED_GEMS_RESET,
    INSIGHTS_SET_EXCLUDED_TOPICS,
    INSIGHTS_SET_EXCLUDED_TOPICS_RESET,
    INSIGHTS_SET_INSIGHTS_TAB,
    INSIGHTS_SET_QUOTE_ACTIVE,
    INSIGHTS_UNCHECK_ALL_QUOTES,
    INSIGHTS_UNCHECK_QUOTE,
} from '@/redux/actions/conversation/insightsViewAction';
import {
    SORT_FILTER_VARIANTS,
    SPEAKERS_FILTER_VARIANTS,
} from '@/components/conversations/results/insights/insightsFilter/insightsFiltersData';
import { CONVERSATION_RESULT } from '@/redux/actions/conversation/conversationResult';
import uniq from 'lodash/uniq';

export const initialState = {
    quoteActiveIdCandidate: null,
    quoteActiveId: null,
    currentSearchedComment: null,
    quotesIdLoading: [],
    insightsTab: INSIGHTS_TABS.CONFIRMED,
    quotesIdChecked: [],
    insightsFilters: {
        sortBy: SORT_FILTER_VARIANTS.pinned,
        speakers: SPEAKERS_FILTER_VARIANTS.everyone,
        selectedTopic: {
            confirmed: null,
            unconfirmed: null,
        },
        selectedGem: {
            confirmed: null,
            unconfirmed: null,
        },
    },
    excludedGems: {
        confirmed: [],
        unconfirmed: [],
    },
    excludedTopics: {
        confirmed: [],
        unconfirmed: [],
    },
    addEventModal: null,
    addTaskModal: null,
};

function insightsViewReducer(state = initialState, action) {
    switch (action.type) {
        case CONVERSATION_RESULT.FETCH_START: {
            return initialState;
        }
        case INSIGHTS_SET_INSIGHTS_TAB: {
            return {
                ...state,
                insightsTab: action.payload.data,
                quotesIdChecked: [],
            };
        }
        case INSIGHTS_OPEN_ADD_EVENT_MODAL: {
            return {
                ...state,
                addEventModal: action.payload.data,
            };
        }
        case INSIGHTS_CLOSE_ADD_EVENT_MODAL: {
            return {
                ...state,
                addEventModal: null,
            };
        }
        case INSIGHTS_OPEN_ADD_TASK_MODAL: {
            return {
                ...state,
                addTaskModal: action.payload.data,
            };
        }
        case INSIGHTS_CLOSE_ADD_TASK_MODAL: {
            return {
                ...state,
                addTaskModal: null,
            };
        }
        case INSIGHTS_SET_INSIGHTS_FILTERS: {
            return {
                ...state,
                insightsFilters: {
                    ...state.insightsFilters,
                    ...action.payload.data,
                },
                quotesIdChecked: [],
            };
        }

        case INSIGHTS_SET_EXCLUDED_GEMS: {
            let editExcludedGems = state.excludedGems;

            if (action.payload.activeInsightTab === 'confirmed') {
                editExcludedGems = {
                    ...state.excludedGems,
                    confirmed: [
                        ...state.excludedGems.confirmed,
                        action.payload.data,
                    ],
                };
            } else {
                editExcludedGems = {
                    ...state.excludedGems,
                    unconfirmed: [
                        ...state.excludedGems.unconfirmed,
                        action.payload.data,
                    ],
                };
            }

            return {
                ...state,
                excludedGems: editExcludedGems,
            };
        }

        case INSIGHTS_SET_EXCLUDED_GEMS_RESET: {
            return {
                ...state,
                excludedGems: {
                    confirmed: [],
                    unconfirmed: [],
                }
            };
        }

        case INSIGHTS_SET_EXCLUDED_TOPICS: {
            let editExcludedTopics = state.excludedTopics;

            if (action.payload.activeInsightTab === 'confirmed') {
                editExcludedTopics = {
                    ...state.excludedTopics,
                    confirmed: [
                        ...state.excludedTopics.confirmed,
                        action.payload.data,
                    ],
                };
            } else {
                editExcludedTopics = {
                    ...state.excludedTopics,
                    unconfirmed: [
                        ...state.excludedTopics.unconfirmed,
                        action.payload.data,
                    ],
                };
            }

            return {
                ...state,
                excludedTopics: editExcludedTopics,
            };
        }

        case INSIGHTS_SET_EXCLUDED_TOPICS_RESET: {
            return {
                ...state,
                excludedTopics: {
                    confirmed: [],
                    unconfirmed: [],
                }
            };
        }

        case INSIGHTS_SET_QUOTE_ACTIVE: {
            if (
                state.quoteActiveIdCandidate === action.payload.data ||
                action.payload.isImmediate
            )
                return {
                    ...state,
                    quoteActiveId: action.payload.data,
                    currentSearchedComment:
                        action.payload.currentSearchedComment,
                };
            return {
                ...state,
                quoteActiveIdCandidate: action.payload.data,
                currentSearchedComment: null,
            };
        }
        case INSIGHTS_RESET_QUOTE_ACTIVE: {
            if (state.quoteActiveId) {
                return {
                    ...state,
                    quoteActiveId: null,
                    currentSearchedComment: null,
                };
            }
            return {
                ...state,
                quoteActiveIdCandidate: null,
                currentSearchedComment: null,
            };
        }
        case INSIGHTS_CHECK_QUOTE: {
            if (state.quotesIdChecked.includes(action.payload.id)) {
                return state;
            }
            return {
                ...state,
                quotesIdChecked: [...state.quotesIdChecked, action.payload.id],
            };
        }
        case INSIGHTS_UNCHECK_QUOTE: {
            if (state.quotesIdChecked.includes(action.payload.id)) {
                return {
                    ...state,
                    quotesIdChecked: state.quotesIdChecked.filter(
                        (id) => id !== action.payload.id,
                    ),
                };
            }
            return state;
        }
        case INSIGHTS_BULK_CHECK_QUOTE: {
            return {
                ...state,
                quotesIdChecked: uniq([
                    ...state.quotesIdChecked,
                    ...action.payload.ids,
                ]),
            };
        }
        case INSIGHTS_BULK_UNCHECK_QUOTE: {
            return {
                ...state,
                quotesIdChecked: state.quotesIdChecked.filter(
                    (id) => !action.payload.ids.includes(id),
                ),
            };
        }
        case INSIGHTS_UNCHECK_ALL_QUOTES: {
            return {
                ...state,
                quotesIdChecked: [],
            };
        }
        case TRANSCRIPT.DELETE_QUOTE.SUCCESS: {
            if (action.payload.id === state.quoteActiveId) {
                return {
                    ...state,
                    quoteActiveIdCandidate: null,
                    quoteActiveId: null,
                    currentSearchedComment: null,
                    quotesIdLoading: state.quotesIdLoading.filter(
                        (id) => id !== action.payload.id,
                    ),
                };
            }
            return {
                ...state,
                quotesIdLoading: state.quotesIdLoading.filter(
                    (id) => id !== action.payload.id,
                ),
            };
        }
        case TRANSCRIPT.DELETE_QUOTE.START:
        case TRANSCRIPT.UPDATE_QUOTE.START:
        case TRANSCRIPT.ADD_COMMENT.START: {
            const { id } = action.payload;
            return {
                ...state,
                quotesIdLoading: state.quotesIdLoading.includes(id)
                    ? state.quotesIdLoading
                    : [...state.quotesIdLoading, id],
            };
        }
        case TRANSCRIPT.BULK_CONFIRM.START:
        case TRANSCRIPT.DELETE_BATCH_QUOTES.START: {
            const ids = action.payload.quotes.map((q) => q.id);
            return {
                ...state,
                quotesIdLoading: uniq([...state.quotesIdLoading, ...ids]),
            };
        }
        case TRANSCRIPT.BULK_CONFIRM.FAILURE:
        case TRANSCRIPT.BULK_CONFIRM.SUCCESS:
        case TRANSCRIPT.DELETE_BATCH_QUOTES.FAILURE: {
            const ids = action.payload.quotes.map((q) => q.id);
            return {
                ...state,
                quotesIdLoading: state.quotesIdLoading.filter(
                    (id) => !ids.includes(id),
                ),
                quotesIdChecked: [],
            };
        }
        case TRANSCRIPT.UPDATE_SUCCESS: {
            return {
                ...state,
                quotesIdLoading: [],
                quotesIdChecked: [],
            };
        }
        case TRANSCRIPT.ADD_COMMENT.SUCCESS:
        case TRANSCRIPT.UPDATE_QUOTE.SUCCESS:
        case TRANSCRIPT.DELETE_QUOTE.FAILURE:
        case TRANSCRIPT.ADD_COMMENT.FAILURE:
        case TRANSCRIPT.UPDATE_QUOTE.FAILURE: {
            return {
                ...state,
                quotesIdLoading: state.quotesIdLoading.filter(
                    (id) => id !== action.payload.id,
                ),
            };
        }

        default:
            return state;
    }
}

export default insightsViewReducer;