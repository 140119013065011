import { groupBy } from 'lodash';
import { message } from 'antd';
import { audioRecoveryStorage } from '@/utils/recording/recovery/chunksUtils';
import {
    getMetaDataKey,
    META_DATA_POSTFIX,
} from '@/utils/recording/recovery/saveUtils';
import { identity } from '@/utils/fp/fp';
import { arrayBufferToBlob } from '@/utils/storage/blob';
import isSafari from '@/utils/deviceDetect/isSafari';

export const getAlreadyStoredConversationId = async (userId) => {
    const allKeys = await audioRecoveryStorage.keys();
    if (!allKeys) return null;

    const metaKey = allKeys.find(
        (v) => v.includes(META_DATA_POSTFIX) && v.includes(userId),
    );
    return metaKey ? metaKey.split('||')[1] : null;
};

const getProcessedMetaData = async (userId, conversationId) =>
    JSON.parse(
        await audioRecoveryStorage.getItem(
            getMetaDataKey(userId, conversationId),
        ),
    );

const getAllDataKeys = async (userId) => {
    const keys = await audioRecoveryStorage.keys();
    if (!keys.length) return [];
    return keys.filter(
        (v) => !v.includes(META_DATA_POSTFIX) && v.includes(userId),
    );
};

const arrBufferChunkToBlobChunk = (chunk) => ({
    ...chunk,
    data: arrayBufferToBlob(chunk.data, chunk.type),
});

export const emptyRecoveryData = {
    title: null,
    date: null,
    conversationId: null,
    meetingExternalId: null,
    chunks: [],
};

export const getAudioRecoveryData = async (userId) => {
    try {
        const conversationId = await getAlreadyStoredConversationId(userId);
        if (!conversationId) return emptyRecoveryData;

        const { title, date, meetingExternalId } = await getProcessedMetaData(
            userId,
            conversationId,
        );

        const dataKeys = await getAllDataKeys(userId);
        const chunksGroups = await Promise.all(
            dataKeys.map((key) => audioRecoveryStorage.getItem(key)),
        );

        const isSafariBrowser = isSafari();

        const chunks = chunksGroups
            .flatMap(identity)
            .sort((a, b) => a.timeStamp - b.timeStamp)
            .map(!isSafariBrowser ? identity : arrBufferChunkToBlobChunk);

        return chunks?.length
            ? { title, date, chunks, meetingExternalId, conversationId }
            : emptyRecoveryData;
    } catch (e) {
        message.error('Audio recovery process failed');
        return emptyRecoveryData;
    }
};

const getChunkGroupDuration = (chunksGroupArr) => {
    if (chunksGroupArr.length < 2) return 0;
    return (
        chunksGroupArr[chunksGroupArr.length - 1].timeStamp -
        chunksGroupArr[0].timeStamp
    );
};

export const getRecordedChunksMeta = (chunks) => {
    const groupedById = groupBy(chunks, 'id');
    const duration = Object.values(groupedById).reduce((prev, curr) => {
        const groupedByGroup = groupBy(curr, 'group');
        const groupsDurationSum = Object.values(groupedByGroup)
            .map(getChunkGroupDuration)
            .reduce((_prev, _curr) => _prev + _curr, 0);
        return prev + groupsDurationSum;
    }, 0);

    return [duration, Object.values(groupedById).length];
};

export const isDataForCurrentUserPresent = async (userId, conversationId) => {
    const allKeys = await audioRecoveryStorage.keys();
    if (!allKeys) return false;
    const match = conversationId
        ? (v) => v.includes(userId) && v.includes(conversationId)
        : (v) => v.includes(userId);
    return allKeys.some(match);
};
