import { NEXT_ROUTES, ROUTES } from '@/constants/routes';
import {
    SUBSCRIPTION_BUY_ADDON_START,
    SUBSCRIPTION_BUY_SUBSCRIPTION_START,
    SUBSCRIPTION_CANCEL_SUBSCRIPTION_START,
    SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESSFULLY,
    SUBSCRIPTION_CHANGE_PLAN_START,
    SUBSCRIPTION_CHANGE_PLAN_SUCCESSFULLY,
    SUBSCRIPTION_COMPUTE_PRICE_START,
    SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_START,
    SUBSCRIPTION_RENEW_SUBSCRIPTION_START,
    SUBSCRIPTION_UPDATE_PAYMENT_START,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_START,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY,
    buyAddonFailed,
    buyAddonSuccessfully,
    buySubscriptionFailure,
    cancelSubscriptionFailure,
    cancelSubscriptionSuccessfully,
    changePlanFailure,
    changePlanSuccessfully,
    computePriceFailure,
    computedPrice,
    getSubscriptionFailed,
    getSubscriptionScheduledChangesStart,
    getSubscriptionScheduledChangesSuccessful,
    gotHostedPage,
    paymentFailure,
    reactivateSubscriptionFailure,
    reactivateSubscriptionSuccessfully,
    renewSubscriptionFailure,
    resetSubscriptionScheduledChanges,
    updatePaymentFailure,
    updateSubscriptionSeatsCancelledSubscription,
    updateSubscriptionSeatsFailed,
    updateSubscriptionSeatsSuccessful,
} from '@/redux/actions/subscriptionAction';
import {
    all,
    call,
    put,
    select,
    takeEvery,
    takeLatest,
} from 'redux-saga/effects';
import {
    getIsNonRenewing,
    getIsTrial,
    getRemainingTrialDays,
    getScheduledPlanCode,
    getSubscriptionId,
    getSubscriptionPaymentCardId,
    getSubscriptionPlanCode,
    getSubscriptionSeats,
} from '@/redux/selectors/subscriptionSelector';

import { AUTH_GET_SUBSCRIPTION } from '@/redux/actions/authAction';
import { PAYMENT_FAILURE } from '@/constants/subscriptionStatus';
import axios from '@/utils/api/axios';
import { fetchTeamMembers } from '@/redux/actions/teamMembersAction';
import { getCompanyIdSelector } from '@/redux/selectors/companySelector';
import { notification } from 'antd';

function* paymentError() {
    yield call(notification.error, {
        message: 'Error',
        description: 'The payment has been declined',
        placement: 'bottomRight',
    });
    yield put(paymentFailure());
}

export function* updatePayment() {
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_ID_UPDATE_CARD(subscriptionId),
        );

        yield put(gotHostedPage(data.hostedPage));
    } catch (error) {
        yield put(updatePaymentFailure());
    }
}

export function* renewSubscription(action) {
    try {
        const subscriptionId = yield select(getSubscriptionId);

        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_ID_RENEW_SUBSCRIPTION(subscriptionId),
            {
                plan: {
                    plan_code: action.payload.data.planCode,
                    quantity: action.payload.data.numberOfSeats,
                    trial_days: 0,
                },
            },
        );

        if (action.payload?.callbacks?.onSuccess) {
            yield call(action.payload.callbacks.onSuccess, data);
        }
        yield put(gotHostedPage(data.hostedPage));
    } catch (error) {
        yield put(renewSubscriptionFailure());
    }
}

export function* buySubscription(action) {
    try {
        const companyId = yield select(getCompanyIdSelector);
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_BUY_SUBSCRIPTION,
            {
                redirect_url: `${process.env.NEXT_PUBLIC_INTELIGEMS_BASE_URL}${ROUTES.HOME}`,
                reference_id: companyId,
                plan: {
                    plan_code: action.payload.data.planCode,
                    quantity: action.payload.data.numberOfSeats,
                },
            },
        );

        yield put(gotHostedPage(data.hostedPage));
    } catch (error) {
        yield put(buySubscriptionFailure());
    }
}

export function* updateSubscriptionSeats(action) {
    const quantity = action.payload.data.numberOfSeats;
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const cardId = yield select(getSubscriptionPaymentCardId);
        const planCode = yield select(getSubscriptionPlanCode);
        const isTrial = yield select(getIsTrial);
        const remainingTrial = yield select(getRemainingTrialDays);
        const scheduledPlanCode = yield select(getScheduledPlanCode);

        const { data } = yield call(
            axios.put,
            NEXT_ROUTES.SUBSCRIPTION_ID(subscriptionId),
            {
                card_id: cardId,
                plan: {
                    plan_code:
                        action.payload.data.endOfTerm && scheduledPlanCode
                            ? scheduledPlanCode
                            : planCode,
                    quantity,
                    trial_days: isTrial ? remainingTrial : 0,
                },
                end_of_term: action.payload.data.endOfTerm,
            },
        );

        yield call(action.payload.callbacks.onSuccess);
        yield call(notification.success, {
            message: 'Success',
            description: 'Successfully modified subscription',
            placement: 'bottomRight',
        });
        yield put(updateSubscriptionSeatsSuccessful(data.data.subscription));
    } catch (error) {
        const isNonRenewing = yield select(getIsNonRenewing);
        const seats = yield select(getSubscriptionSeats);

        if (error?.response?.data === PAYMENT_FAILURE) {
            yield call(paymentError);
        } else if (isNonRenewing && quantity > seats) {
            yield call(notification.error, {
                message: 'Adding Seats Blocked',
                description:
                    'You cannot add seats with a cancelled subscription. Please reactivate your subscription to add seats.',
                placement: 'bottomRight',
            });
            yield put(updateSubscriptionSeatsCancelledSubscription(error));
        } else {
            yield call(notification.error, {
                message: 'Error',
                description: 'There was error during updating number of seats.',
                placement: 'bottomRight',
            });
            yield put(updateSubscriptionSeatsFailed(error));
        }
    }
}

export function* cancelSubscription() {
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_ID_CANCEL(subscriptionId),
        );

        yield call(notification.success, {
            message: 'Success',
            description: 'Successfully cancelled subscription',
            placement: 'bottomRight',
        });
        yield put(cancelSubscriptionSuccessfully(data.subscription));
    } catch (error) {
        yield call(notification.error, {
            message: 'Error',
            description:
                'There was error during cancelling subscription. Please try again',
            placement: 'bottomRight',
        });
        yield put(cancelSubscriptionFailure(error));
    }
}

export function* reactivateSubscription() {
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_ID_REACTIVATE(subscriptionId),
            {},
        );

        yield call(notification.success, {
            message: 'Success',
            description: 'Successfully reactivated subscription',
            placement: 'bottomRight',
        });
        yield put(reactivateSubscriptionSuccessfully(data.subscription));
    } catch (error) {
        if (error?.response?.data === PAYMENT_FAILURE) {
            yield call(paymentError);
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during reactivating subscription.',
                placement: 'bottomRight',
            });
            yield put(reactivateSubscriptionFailure(error));
        }
    }
}

export function* getEndOfTermChanges(action) {
    try {
        if (action.payload.data.end_of_term) {
            yield put(getSubscriptionScheduledChangesStart());
            const { data } = yield call(
                axios.get,
                NEXT_ROUTES.SUBSCRIPTION_ID_SCHEDULED_CHANGES(
                    action.payload.data.subscription_id,
                ),
            );
            yield put(
                getSubscriptionScheduledChangesSuccessful(
                    data.data.subscription,
                ),
            );
        } else {
            yield put(resetSubscriptionScheduledChanges());
        }
    } catch (error) {
        yield put(getSubscriptionFailed(error));
    }
}

export function* buyAddon(action) {
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_ID_BUY_ADDON(subscriptionId),
            {
                addons: [
                    {
                        addon_code: action.payload.data.addonCode,
                        quantity: action.payload.data.userIds.length,
                    },
                ],
            },
        );
        yield all(
            action.payload.data.userIds.map((userId) =>
                call(axios.patch, NEXT_ROUTES.USERS_ID_ADD_MINUTES(userId), {
                    minutes: action.payload.data.minutes,
                }),
            ),
        );
        yield call(action.payload.callbacks.onSuccess);
        yield call(notification.success, {
            message: 'Success',
            description: 'Successfully bought additional minutes',
            placement: 'bottomRight',
        });
        yield put(buyAddonSuccessfully(data));
        yield put(fetchTeamMembers());
    } catch (error) {
        if (error?.response?.data === PAYMENT_FAILURE) {
            yield call(paymentError);
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during buying additional minutes.',
                placement: 'bottomRight',
            });
            yield put(buyAddonFailed(error));
        }
    }
}

export function* changePlan(action) {
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const cardId = yield select(getSubscriptionPaymentCardId);

        const { data } = yield call(
            axios.put,
            NEXT_ROUTES.SUBSCRIPTION_ID(subscriptionId),
            {
                card_id: cardId,
                plan: {
                    plan_code: action.payload.data.planCode,
                    quantity: action.payload.data.numberOfSeats,
                    trial_days: 0,
                },
                end_of_term: action.payload.data.endOfTerm,
            },
        );


        yield call(action.payload.callbacks.onSuccess);
        yield call(notification.success, {
            message: 'Success',
            description: 'Successfully changed subscription plan',
            placement: 'bottomRight',
        });
        yield put(changePlanSuccessfully(data.data.subscription));
    } catch (error) {
        if (error?.response?.data === PAYMENT_FAILURE) {
            yield call(paymentError);
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during changing subscription plan.',
                placement: 'bottomRight',
            });
            yield put(changePlanFailure(error));
        }
    }
}

export function* computePrice(action) {
    try {
        const subscriptionId = yield select(getSubscriptionId);
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.SUBSCRIPTION_ID_COMPUTE(subscriptionId),
            {
                plan_code: action.payload.data.planCode,
                quantity: action.payload.data.quantity,
            },
        );
        yield put(computedPrice(data.subscription));
    } catch (error) {
        yield call(notification.error, {
            message: 'Error',
            description: 'There was error during price computation.',
            placement: 'bottomRight',
        });
        yield put(computePriceFailure(error));
    }
}

export default function* subscriptionSaga() {
    yield takeEvery(SUBSCRIPTION_UPDATE_PAYMENT_START, updatePayment);
    yield takeEvery(SUBSCRIPTION_RENEW_SUBSCRIPTION_START, renewSubscription);
    yield takeEvery(SUBSCRIPTION_BUY_SUBSCRIPTION_START, buySubscription);
    yield takeEvery(
        SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_START,
        updateSubscriptionSeats,
    );

    yield takeEvery(SUBSCRIPTION_BUY_ADDON_START, buyAddon);
    yield takeEvery(SUBSCRIPTION_CHANGE_PLAN_START, changePlan);
    yield takeEvery(SUBSCRIPTION_CANCEL_SUBSCRIPTION_START, cancelSubscription);
    yield takeEvery(
        SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_START,
        reactivateSubscription,
    );
    // GET END OF TERM CHANGES
    yield takeEvery(AUTH_GET_SUBSCRIPTION, getEndOfTermChanges);
    yield takeEvery(
        SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY,
        getEndOfTermChanges,
    );
    yield takeEvery(SUBSCRIPTION_CHANGE_PLAN_SUCCESSFULLY, getEndOfTermChanges);
    yield takeEvery(
        SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESSFULLY,
        getEndOfTermChanges,
    );
    yield takeLatest(SUBSCRIPTION_COMPUTE_PRICE_START, computePrice);
}
