import { put, takeEvery } from 'redux-saga/effects';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchCustomGemsInsightsFailure,
    fetchCustomGemsInsightsSuccess,
    GEMS_INSIGHTS_FETCH_START,
} from '@/redux/actions/gemsInsightsAction';

async function fetchCustomGemsInsights() {
    return axios.get(NEXT_ROUTES.GEMS);
}

function* getCustomGemsInsights() {
    try {
        const { data } = yield fetchCustomGemsInsights();
        yield put(
            fetchCustomGemsInsightsSuccess(
                data.map(({ id, name, description, type }) => ({
                    id,
                    name,
                    description,
                    type: type.name,
                    children: [],
                })),
            ),
        );
    } catch (err) {
        yield put(fetchCustomGemsInsightsFailure(err));
    }
}


export default function* gemInsightsSaga() {
    yield takeEvery(GEMS_INSIGHTS_FETCH_START, getCustomGemsInsights);
}
