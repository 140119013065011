import { notification } from 'antd';
import { ChatTrigger } from '@/components/chatDummy/chatDummy.styles';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';

export default function ChatDummy() {
    const [visible, setVisible] = useState(true);

    const openNotification = () => {
        notification.open({
            message: "Hi, I'm AI-Ally!",
            description:
                "Go to recordings or single conversation to chat with me about your meetings.",
            placement: 'bottomRight',
        });
    };

    const router = useRouter();
    // eslint-disable-next-line prefer-destructuring
    const asPath = router.asPath;

    useEffect(() => {
        const mode = localStorage.getItem('mode');
        if (mode === 'teamsapp') {
            setVisible(false);
        }
    }, []);

    if (!asPath.includes('recordings') && !asPath.includes('conversations/results') && visible) {
        return (
            <ChatTrigger onClick={() => openNotification()}>
                <Image
                    src="/images/woman.png"
                    width={60}
                    height={60}
                />
            </ChatTrigger>
        );
    }
    return <></>
}
