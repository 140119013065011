import { getActionCreator } from '@/redux/actions/utils/actionCreator';
import { fetchFailure, fetchSuccess } from '@/redux/actions/genericActions';

export const AUTH_USER_LOG_IN = 'AUTH/USER_LOG_IN';
export const AUTH_USER_LOG_OUT = 'AUTH/USER_LOG_OUT';
export const AUTH_GUEST_AUTHORIZED = 'AUTH/GUEST_AUTHORIZED';

export const AUTH_USER_FETCH_START = 'AUTH/USER_FETCH_START';
export const AUTH_USER_FETCH_SUCCESS = 'AUTH/USER_FETCH_SUCCESS';
export const AUTH_USER_FETCH_FAILURE = 'AUTH/USER_FETCH_FAILURE';

export const AUTH_GET_SUBSCRIPTION = 'AUTH/GET_SUBSCRIPTION';
export const AUTH_SUBSCRIPTION_FETCH_FAILURE =
    'AUTH/SUBSCRIPTION_FETCH_FAILURE';
export const AUTH_NO_ACTIVE_SUBSCRIPTION = 'AUTH/NO_ACTIVE_SUBSCRIPTION';
export const AUTH_USER_REMOVE_CRM = 'AUTH/REMOVE_CRM';
export const AUTH_USER_ADD_CRM = 'AUTH/ADD_CRM';
export const AUTH_USER_DISCONNECT_CRM = 'AUTH/DISCONNECT_CRM';
export const AUTH_USER_PENDING_CRM = 'AUTH/PENDING_CRM';

export const USER_SURVEY_COMPLETE = 'USER/SURVEY_COMPLETE';

export const USER_PM_ALLY_DISMISSED = 'USER/PM_ALLY_DISMISSED';

export const USER_ONBOARDING_UPDATE = 'USER/ONBOARDING_UPDATE';

export const USER_SET_USER_META = 'USER/SET_USER_META';

const creator = getActionCreator('AUTH');

export const AUTH = {
    EMAIL_CHANGE: {
        ...creator.custom('EMAIL_CHANGE'),
    },
    PASSWORD_CHANGE: {
        ...creator.custom('PASSWORD_CHANGE'),
    },
};

export const fetchUserStart = () => ({
    type: AUTH_USER_FETCH_START,
});

export const fetchUserSuccess = (user) => ({
    type: AUTH_USER_FETCH_SUCCESS,
    payload: {
        ...user,
    },
});

export const fetchUserFailure = () => ({
    type: AUTH_USER_FETCH_FAILURE,
});

export const logIn = () => ({
    type: AUTH_USER_LOG_IN,
});

export const logOut = (destination) => ({
    type: AUTH_USER_LOG_OUT,
    payload: {
        destination,
    },
});

export const authorizeGuest = () => ({
    type: AUTH_GUEST_AUTHORIZED,
});

export const getSubscription = (data) => ({
    type: AUTH_GET_SUBSCRIPTION,
    payload: { data },
});

export const noActiveSubscription = () => ({
    type: AUTH_NO_ACTIVE_SUBSCRIPTION,
});

export const subscriptionFetchFailure = (error) => ({
    type: AUTH_SUBSCRIPTION_FETCH_FAILURE,
    payload: { error },
});

export const removeCrmIntegration = () => ({
    type: AUTH_USER_REMOVE_CRM,
});

export const addPendingCrmIntegration = () => ({
    type: AUTH_USER_PENDING_CRM,
});

export const addCrmIntegration = (crmName) => ({
    type: AUTH_USER_ADD_CRM,
    payload: {
        crmName,
    },
});

export const changeEmailStart = (email, onSuccess, onFailure) => ({
    type: AUTH.EMAIL_CHANGE.START,
    payload: {
        email,
        onSuccess,
        onFailure,
    },
});

export const changeEmailSuccess = (email) =>
    fetchSuccess(AUTH.EMAIL_CHANGE.SUCCESS, { email });

export const changeEmailFailure = () => fetchFailure(AUTH.EMAIL_CHANGE.FAILURE);

export const changePasswordStart = (
    currentPassword,
    password,
    onSuccess,
    onFailure,
) => ({
    type: AUTH.PASSWORD_CHANGE.START,
    payload: {
        currentPassword,
        password,
        onSuccess,
        onFailure,
    },
});

export const changePasswordSuccess = () =>
    fetchSuccess(AUTH.PASSWORD_CHANGE.SUCCESS);

export const changePasswordFailure = () =>
    fetchFailure(AUTH.PASSWORD_CHANGE.FAILURE);

export const userSurveyComplete = () => ({
    type: USER_SURVEY_COMPLETE,
});

export const userPMAllyDismissed = () => ({
    type: USER_PM_ALLY_DISMISSED,
});

export const updateOnboarding = (data) => ({
    type: USER_ONBOARDING_UPDATE,
    payload: data,
});

export const setUserMeta = (data) => ({
    type: USER_SET_USER_META,
    payload: data,
});