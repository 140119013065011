/* eslint-disable indent,no-shadow */
import {
    ADD_COMMENT,
    ADD_CUSTOM_TAG,
    ADD_FOLDER_ACTION,
    ADD_PLAYLIST_ACTION,
    ARCHIVE_FOLDER_ACTION,
    DELETE_QUOTE,
    EDIT_PLAYLIST,
    EDIT_QUOTE,
    EDIT_QUOTE_GEM,
    MOVE_PLAYLIST_ACTION,
    REMOVE_PLAYLIST,
    SET_ERROR_ACTION,
    SET_LOADING_ACTION,
    TOGGLE_PLAYLIST_PER_QUOTE,
    UPDATE_FOLDER_ACTION,
    UPDATE_FOLDERS_ACTION,
    UPDATE_PLAYLIST_ACTION,
} from '@/redux/actions/playlistsActions';
import { FETCH_STATUS } from '@/constants/fetchStatus';

export const playlistsInitialState = {
    data: null,
    speakers: null,
    status: FETCH_STATUS.INITIAL_LOADING,
    error: null,
    quotePerPlaylist: [],
};

function playlistsReducer(state = playlistsInitialState, action) {
    switch (action.type) {
        case TOGGLE_PLAYLIST_PER_QUOTE:
            const { quoteId, playlistId } = action.payload;

            return {
                ...state,
                quotePerPlaylist: state.quotePerPlaylist.map((v) => {
                    if (v.playlistId !== playlistId) return v;
                    const containQuote = v.quotesIds.includes(quoteId);

                    return {
                        playlistId,
                        quotesIds: containQuote
                            ? v.quotesIds.filter((id) => id !== quoteId)
                            : [...v.quotesIds, quoteId],
                    };
                }),
            };
        case UPDATE_FOLDERS_ACTION:
            const folders = action.payload.map((folder) => ({
                ...folder,
                playlists: folder.playlists.map((playlist) => ({
                    ...playlist,
                    quotes: playlist.quotes?.length ? playlist.quotes : [],
                })),
            }));

            return {
                ...state,
                status:
                    state.status === FETCH_STATUS.INITIAL_LOADING
                        ? FETCH_STATUS.INITIAL_LOADED
                        : FETCH_STATUS.UPDATE_SUCCESS,
                data: folders,
                speakers: [],
                quotePerPlaylist: [],
            };

        case UPDATE_PLAYLIST_ACTION:
            const { id, ...rest } = action.payload;
        
            return {
                ...state,
                data: state.data.map(item => ({
                    ...item,
                    playlists: item.playlists.map(playlist => {
                        if (playlist.id === id) {
                            const updatedPlaylist = { ...playlist, ...rest };
                            
                            updatedPlaylist.quotesIdx = updatedPlaylist?.quotes.map(quote => quote.id);
        
                            return updatedPlaylist;
                        }
        
                        return playlist;
                    }),
                })),
            };

        case ADD_FOLDER_ACTION:
            const folderData = {
                ...action.payload,
                playlists: [],
            };

            return {
                ...state,
                data: state.data ? [folderData, ...state.data] : [folderData],
            };
        case UPDATE_FOLDER_ACTION: {
            const updatedFolderId = state.data.findIndex(
                (f) => f.id === action.payload.id,
            );
            const newData = JSON.parse(JSON.stringify(state.data));
            newData[updatedFolderId] = {
                ...newData[updatedFolderId],
                ...action.payload,
            };
            return { ...state, data: newData };
        }
        case ADD_PLAYLIST_ACTION: {
            return {
                ...state,
                data: state.data.map((folder) => {
                    if (folder.id !== action.payload.folderId) return folder;
                    return {
                        ...folder,
                        playlists: [
                            ...folder.playlists,
                            action.payload.playlist,
                        ],
                    };
                }),
                quotePerPlaylist: [
                    ...state.quotePerPlaylist,
                    { playlistId: action.payload.playlist.id, quotesIds: [] },
                ],
            };
        }
        case MOVE_PLAYLIST_ACTION: {
            const { sourceFolder, destinationFolder } = action.payload;

            return {
                ...state,
                data: state.data.map((folder) => {
                    if (folder.id === sourceFolder.id) return sourceFolder;
                    if (folder.id === destinationFolder.id)
                        return destinationFolder;
                    return folder;
                }),
            };
        }
        case ARCHIVE_FOLDER_ACTION: {
            const archivedFolderId = state.data.findIndex(
                (f) => f.id === action.payload.id,
            );
            const newData = JSON.parse(JSON.stringify(state.data));
            newData[archivedFolderId].is_archived = action.payload.is_archived;

            return { ...state, data: [...newData] };
        }
        case EDIT_PLAYLIST: {
            const { folderId, playlist } = action.payload;
            const folderToEditIndex = state.data.findIndex(
                (f) => f.id === folderId,
            );
            const playlistToEditIndex = state.data[
                folderToEditIndex
            ].playlists.findIndex((p) => p.id === playlist.id);
            const newData = JSON.parse(JSON.stringify(state.data));
            newData[folderToEditIndex].playlists[playlistToEditIndex] =
                playlist;

            return { ...state, data: newData };
        }
        case REMOVE_PLAYLIST: {
            const { fromFolderId, playlistId } = action.payload;
            const fromFolderIndex = state.data.findIndex(
                (f) => f.id === fromFolderId,
            );
            const playlistToRemoveIndex = state.data[
                fromFolderIndex
            ].playlists.findIndex((p) => p.id === playlistId);
            const newData = JSON.parse(JSON.stringify(state.data));
            newData[fromFolderIndex].playlists.splice(playlistToRemoveIndex, 1);

            return { ...state, data: newData };
        }
        case EDIT_QUOTE: {
            const { folderId, playlistId, quoteId, editedQuote } =
                action.payload;

            const targetFolderIndex = state.data.findIndex(
                (f) => f.id === folderId,
            );
            const targetPlaylistIndex = state.data[
                targetFolderIndex
            ].playlists.findIndex((p) => p.id === playlistId);
            const targetQuoteIndex = state.data[targetFolderIndex].playlists[
                targetPlaylistIndex
            ].quotes.findIndex((q) => q.id === quoteId);
            const newData = JSON.parse(JSON.stringify(state.data));
            newData[targetFolderIndex].playlists[targetPlaylistIndex].quotes[
                targetQuoteIndex
            ] = {
                ...newData[targetFolderIndex].playlists[targetPlaylistIndex]
                    .quotes[targetQuoteIndex],
                ...editedQuote,
            };

            return { ...state, data: [...newData] };
        }
        case DELETE_QUOTE: {
            const { folderId, playlistId, quoteId } = action.payload;
            const targetFolderIndex = state.data.findIndex(
                (f) => f.id === folderId,
            );
            const targetPlaylistIndex = state.data[
                targetFolderIndex
            ].playlists.findIndex((p) => p.id === playlistId);
            const targetQuoteIndex = state.data[targetFolderIndex].playlists[
                targetPlaylistIndex
            ].quotes.findIndex((q) => q.id === quoteId);
            const newData = JSON.parse(JSON.stringify(state.data));
            newData[targetFolderIndex].playlists[
                targetPlaylistIndex
            ].quotes.splice(targetQuoteIndex, 1);

            return { ...state, data: [...newData] };
        }
        case EDIT_QUOTE_GEM: {
            const { quoteId, gem } = action.payload;

            return {
                ...state,
                data: state.data?.map((folder) => ({
                    ...folder,
                    playlists: folder.playlists.map((playlist) => ({
                        ...playlist,
                        quotes: playlist.quotes.map((quote) => {
                            if (quote.id !== quoteId) return quote;

                            const containGem = quote.gems.some(
                                ({ id }) => id === gem.id,
                            );

                            return {
                                ...quote,
                                gems: containGem
                                    ? quote.gems.filter(
                                          ({ id }) => id !== gem.id,
                                      )
                                    : [...quote.gems, gem],
                            };
                        }),
                    })),
                })),
            };
        }
        case ADD_COMMENT: {
            const { quoteId, comment } = action.payload;
            return {
                ...state,
                data: state.data?.map((folder) => ({
                    ...folder,
                    playlists: folder.playlists.map((playlist) => ({
                        ...playlist,
                        quotes: playlist.quotes.map((quote) => {
                            if (quote.id !== quoteId) return quote;

                            return {
                                ...quote,
                                comments: [...quote.comments, comment],
                            };
                        }),
                    })),
                })),
            };
        }
        case ADD_CUSTOM_TAG: {
            const { quoteId, customTag } = action.payload;

            return {
                ...state,
                data: state.data?.map((folder) => ({
                    ...folder,
                    playlists: folder.playlists.map((playlist) => ({
                        ...playlist,
                        quotes: playlist.quotes.map((quote) => {
                            if (quote.id !== quoteId) return quote;

                            const containTag = quote.customTags.some(
                                ({ id }) => id === customTag.id,
                            );

                            return {
                                ...quote,
                                customTags: containTag
                                    ? quote.customTags.filter(
                                          ({ id }) => id !== customTag.id,
                                      )
                                    : [
                                          ...quote.customTags,
                                          {
                                              ...customTag,
                                              name: customTag.name,
                                              description:
                                                  customTag.description,
                                          },
                                      ],
                            };
                        }),
                    })),
                })),
            };
        }
        case SET_ERROR_ACTION:
            return { ...state, error: action.payload };
        case SET_LOADING_ACTION:
            const isLoading = action.payload;

            let status;
            if (isLoading) {
                if (
                    [
                        FETCH_STATUS.INITIAL_LOADING,
                        FETCH_STATUS.INITIAL_LOADED,
                        FETCH_STATUS.INITIAL_ERROR,
                    ].includes(state.status)
                ) {
                    status = FETCH_STATUS.INITIAL_LOADING;
                } else {
                    status = FETCH_STATUS.UPDATE_LOADING;
                }
            } else {
                status = FETCH_STATUS.UPDATE_SUCCESS;
            }

            return {
                ...state,
                status,
            };
        default:
            return state;
    }
}

export default playlistsReducer;
