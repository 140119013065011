import { upload as uploadCall } from '@/components/conversations/conversations/headerActionsButton/api';
import { useEffect, useState } from 'react';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';
import {
    useConversationContext,
    useTranscriptionStatusContext,
} from '@/components/conversations/conversations/context/context';
import { useTimeContext } from '@/components/timer/timerContext';
import useHandleStatusChange from '@/components/insights/recordings/upload/useHandleStatusChange';
import { shouldLaunchConversationStatusPolling } from '@/components/insights/recordings/upload/utils';
import { POLL_STATE } from '@/constants/poll/pollState';
import { clearUserData } from '@/utils/recording/recovery/saveUtils';
import { useDispatch, useSelector } from 'react-redux';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { clearRecordingsRecoveryData } from '@/redux/actions/recordings/recordingRecoveryActions';

export default function useStatusPoll(clearTempRecoveryData) {
    const dispatch = useDispatch();
    const userId = useSelector(getUserIdSelector);
    const { mediaBlobs } = useMediaRecorderContext();
    const {
        title,
        conversationId,
        conversationData,
        resetConversationDataError,
        resetPollingState,
    } = useConversationContext();

    // Used to reflect upload changes immediately
    const [fakeUploading, setFakeUploading] = useState(false);
    const [uploadPostLoading, setUploadPostLoading] = useState(false);
    const [progress, setProgress] = useState(0);

    const {
        percentage,
        status,
        isError,
        startPolling,
        stopPolling,
        polledConversation,
        originalPollStatus,
    } = useTranscriptionStatusContext();

    const seconds = useTimeContext();

    useHandleStatusChange(
        originalPollStatus,
        conversationData,
        polledConversation,
    );

    const upload = uploadCall(
        title,
        conversationId,
        mediaBlobs,
        () => {
            resetPollingState();
            resetConversationDataError();
            setFakeUploading(true);
            clearTempRecoveryData();
        },
        () => null,
        () => {
            startPolling(conversationId);
        },
        () => {
            clearUserData(userId);
            dispatch(clearRecordingsRecoveryData());
        },
        () => {
            setUploadPostLoading(true);
        },
        () => {
            setUploadPostLoading(false);
        },
        (_progress) => {
            setProgress(_progress);
        },
    );

    useEffect(() => {
        if (fakeUploading && status) {
            setFakeUploading(false);
        }
    }, [status, fakeUploading]);

    useEffect(() => {
        (async () => {
            if (seconds && mediaBlobs) {
                upload();
            }
        })();

        // it should be call once only when mediaBlobs are present - memo of seconds or upload would call unnecessary function triggers
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mediaBlobs]);

    useEffect(() => {
        if (shouldLaunchConversationStatusPolling(conversationData, status)) {
            startPolling(conversationData);
        }
    }, [conversationData, startPolling, status]);

    useEffect(
        () => () => {
            stopPolling();
        },
        [stopPolling],
    );

    return {
        upload,
        percentage: fakeUploading ? progress : percentage,
        status:
            uploadPostLoading || fakeUploading ? POLL_STATE.UPLOADING : status,
        isError,
        polledConversation,
    };
}
