export const msToHoursMinutesSeconds = (ms) => {
    const msInMinute = 60000;
    const msInHour = msInMinute * 60;

    const hours = Math.floor(ms / msInHour);
    let minutes = Math.floor(ms / msInMinute);
    const seconds = Math.floor((ms % msInMinute) / 1000).toFixed(0);

    if (hours > 0) {
        minutes = `0${minutes - hours * 60}`;
    }

    return `${hours !== 0 ? `${hours}:` : ''}${minutes}:${
        seconds < 10 ? '0' : ''
    }${seconds}`;
};
