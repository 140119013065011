import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { CONVERSATION_KEY } from '@/constants/reduxKeys';
import conversationSaga from '@/redux/sagas/conversation/conversationSaga';
import conversationReducer from '@/redux/reducers/conversation/conversationReducer';

export default function useConversationInjectors() {
    useInjectSaga({
        key: CONVERSATION_KEY,
        saga: conversationSaga,
    });

    useInjectReducer({
        key: CONVERSATION_KEY,
        reducer: conversationReducer,
    });
}
