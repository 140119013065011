import { analyticsItems } from '@/components/conversations/results/analytics/analyticsItems';
import { CONVERSATIONS_TABS } from '@/constants/conversations/conversationsTabs';
import { longestMonologueItems } from '@/constants/conversations/longestMonologueItems';
import { UserTypes } from '@/constants/conversations/userTypes';
import { ViewModes } from '@/constants/conversations/viewModes';
import { CONVERSATION_RESULT_VIEW } from '@/redux/actions/conversation/conversationResultView';
import { keywordOption } from '@/utils/conversations/search/autoCompleteOptions/getAutoCompleteOptions';
import { SET_SEARCH_VALUE } from '@/redux/actions/conversation/searchAction';

export const initialState = {
    leftTabPanelKey: null,
    rightTabPanelKey: CONVERSATIONS_TABS.TRANSCRIPT,
    selectedAnalyticsTile: null,
    columnView: true,
    viewMode: ViewModes.default,
    selectedUsers: UserTypes.everyone,
    transcriptSelectedUsers: UserTypes.everyone,
};

function conversationResultViewReducer(state = initialState, action) {
    switch (action.type) {
        case CONVERSATION_RESULT_VIEW.RESET_CONVERSATION_RESULT_VIEW_STATE: {
            return initialState;
        }
        case CONVERSATION_RESULT_VIEW.SET_VIEW_MODE: {
            return {
                ...state,
                viewMode: action.payload.viewMode,
            };
        }
        case CONVERSATION_RESULT_VIEW.SET_USERS_TYPE: {
            return {
                ...state,
                selectedUsers: action.payload.selectedUsers,
            };
        }
        case CONVERSATION_RESULT_VIEW.SET_TRANSCRIPT_USERS_TYPE: {
            return {
                ...state,
                transcriptSelectedUsers: action.payload.selectedUsers,
            };
        }
        case CONVERSATION_RESULT_VIEW.SET_LEFT_TAB_PANEL_KEY: {
            return {
                ...state,
                leftTabPanelKey: action.payload.data,
            };
        }
        case CONVERSATION_RESULT_VIEW.SET_RIGHT_TAB_PANEL_KEY: {
            return {
                ...state,
                rightTabPanelKey: action.payload.data,
            };
        }
        case CONVERSATION_RESULT_VIEW.SET_SELECTED_ANALYTICS_TILE: {
            return {
                ...state,
                selectedAnalyticsTile: action.payload.data,
            };
        }
        case CONVERSATION_RESULT_VIEW.SWITCH_TO_SINGLE_COLUMN: {
            return {
                ...state,
                columnView: false,
            };
        }
        case CONVERSATION_RESULT_VIEW.SWITCH_TO_TWO_COLUMNS: {
            if (
                ![
                    CONVERSATIONS_TABS.ANALYTICS,
                    CONVERSATIONS_TABS.VIDEO,
                ].includes(state.leftTabPanelKey)
            )
                return {
                    ...state,
                    columnView: true,
                    leftTabPanelKey: action.payload.isGuest
                        ? CONVERSATIONS_TABS.VIDEO
                        : CONVERSATIONS_TABS.INSIGHTS,
                };
            return { ...state, columnView: true };
        }
        case SET_SEARCH_VALUE: {
            const { value: searchValue, topics } = action.payload.data;
            if (
                Object.keys(longestMonologueItems).some(
                    (v) => longestMonologueItems[v] === searchValue,
                )
            ) {
                let selectedUsers;
                if (searchValue === longestMonologueItems.top5Owners) {
                    selectedUsers = UserTypes.owners;
                } else if (searchValue === longestMonologueItems.top5Contacts) {
                    selectedUsers = UserTypes.contacts;
                } else {
                    selectedUsers = UserTypes.everyone;
                }
                return {
                    ...state,
                    selectedUsers,
                    leftTabPanelKey: CONVERSATIONS_TABS.ANALYTICS,
                    selectedAnalyticsTile: analyticsItems.longestMonologue.key,
                };
            }

            if (searchValue.startsWith(keywordOption.value)) {
                return {
                    ...state,
                    leftTabPanelKey: CONVERSATIONS_TABS.ANALYTICS,
                    selectedAnalyticsTile: topics ? analyticsItems.topics.key : analyticsItems.keywords.key,
                };
            }
            return state;
        }
        default:
            return state;
    }
}

export default conversationResultViewReducer;
