import { FETCH_STATUS } from '@/constants/fetchStatus';

export const getTranscriptWords = (state) =>
    state.assemblyTranscript?.data?.words ?? [];

export const getAssemblyTranscriptLoading = (state) =>
    state.assemblyTranscript?.status !== FETCH_STATUS.INITIAL_LOADED;

export const getAssemblyTranscriptAudioDuration = (state) =>
    (state.assemblyTranscript?.data?.audio_duration ?? 0) * 1000;
