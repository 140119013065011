import { any, arrayOf, func, oneOfType, shape, string } from 'prop-types';
import { Button, Modal } from 'antd';
import { ROUTES } from '@/constants/routes';
import { formatWithZone } from '@/utils/dates/timeZones';
import { getRecordedChunksMeta } from '@/utils/recording/recovery/readUtils';
import { getRecoveryDurationFormat } from '@/utils/recording/recovery/uiUtils';
import { getZoneSelector } from '@/redux/selectors/calendarSelector';
import { useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';

/**
 * @param {Array.<Blob>} chunks - recording recovery chunks
 * @return {[number, number]} - tuple with duration and number of recovered files
 * */
const useChunksMeta = (chunks) =>
    useMemo(
        () => (chunks?.length ? getRecordedChunksMeta(chunks) : [0, 0]),
        [chunks],
    );

function RecoveryModal({ onClear, processedData, hide }) {
    const router = useRouter();
    const zone = useSelector(getZoneSelector);
    const [recoveredAudioDuration] = useChunksMeta(processedData?.chunks);
    if (!processedData) return null;

    return (
        <Modal
            title="Unprocessed Recording Recovered"
            visible
            onCancel={() => {
                hide();
            }}
            footer={[
                <Button
                    key="danger"
                    danger
                    onClick={() => {
                        onClear();
                        hide();
                    }}
                >
                    Delete Recording
                </Button>,
                <Button
                    key="primary"
                    type="primary"
                    onClick={async () => {
                        await router.replace(
                            `${ROUTES.CONVERSATIONS_NEW}?id=${processedData.meetingExternalId}`,
                        );
                        router.reload();
                    }}
                >
                    Go to Recording
                </Button>,
            ]}
        >
            <p>
                <span>
                    Your recent recording for {`"${processedData.title}"`} may
                    have been interrupted on{' '}
                    {formatWithZone(
                        processedData.date,
                        zone,
                        'MMMM DD hh:mm A',
                    )}
                    {', '}
                    at {getRecoveryDurationFormat(recoveredAudioDuration)}.
                </span>
            </p>
            <p>
                Before recording again, please process this recording or it will
                be deleted.
            </p>
        </Modal>
    );
}

RecoveryModal.propTypes = {
    onClear: func.isRequired,
    processedData: shape({
        id: oneOfType([string, null]),
        title: oneOfType([string, null]),
        meetingExternalId: oneOfType([string, null]),
        date: oneOfType([string, null]),
        // eslint-disable-next-line react/forbid-prop-types
        chunks: arrayOf(any),
    }).isRequired,
    hide: func.isRequired,
};

export default RecoveryModal;
