import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';
import { useTimerContext } from '@/components/timer/timerContext';
import { useSelector } from 'react-redux';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { useConversationContext } from '@/components/conversations/conversations/context/context';

export default function useHandleStartQuery(recoveryData) {
    const { query } = useRouter();
    const userId = useSelector(getUserIdSelector);
    const { conversationId } = useConversationContext();

    const { startRecording } = useMediaRecorderContext();
    const { toggle: toggleTimer } = useTimerContext();

    useEffect(() => {
        if (
            query.startRecording &&
            userId &&
            conversationId &&
            recoveryData?.conversationId === conversationId
        ) {
            startRecording();
            toggleTimer();
        }
        // rule is disabled because of possibility of references changes in startRecording and toggleTimer functions with bigger contexts
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [conversationId, query, recoveryData?.conversationId, userId]);
}
