import SelectableItem from '@/components/conversations/attendeesChooser/selectableItem/selectableItem';
import { GRAY_2 } from '@/constants/colors';
import { arrayOf, func, shape } from 'prop-types';

function TeamMembers({ teamMembers, onSelect }) {
    return (
        <>
            {teamMembers.map(({ firstName, lastName, photo, id: userId }) => (
                <SelectableItem
                    color={GRAY_2}
                    name={`${firstName} ${lastName.replace(/[0-9#]/g, '')}`}
                    key={userId}
                    photo={photo}
                    onSelect={(name) => {
                        onSelect(userId, name);
                    }}
                />
            ))}
        </>
    );
}

TeamMembers.propTypes = {
    teamMembers: arrayOf(shape({})).isRequired,
    onSelect: func.isRequired,
};

export default TeamMembers;
