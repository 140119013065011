import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { nanoid } from 'nanoid';
import { groupBy } from 'lodash';
import { ERROR_STATUS, MEDIA_STATUS } from '@/constants/media/mediaRecording';
import isSafari from '@/utils/deviceDetect/isSafari';
import { callOnDev } from '@/utils/environment/callOnDev';
import { settingsRecordingsIsMicCurrentlyRecording } from '@/redux/actions/settings/recordingsAction';

export default function useReactMediaRecorder({
    audio = true,
    video = false,
    screen = false,
    mediaRecorderOptions = null,
    askPermissionOnMount = false,
}) {
    const dispatch = useDispatch();
    const mediaRecorder = useRef(null);
    const mediaChunks = useRef([]);
    const preloadedChunksTimingEnd = useRef(0);
    const mediaStream = useRef(null);
    const recordingId = useRef(null);
    // used in order to provide knowledge about timestamps on pause/resume

    const group = useRef(1);
    const preventChunksAddFlag = useRef(false);

    const [status, setStatus] = useState(MEDIA_STATUS.IDLE);
    const [isAudioMuted, setIsAudioMuted] = useState(false);
    const [mediaBlobs, setMediaBlobs] = useState(null);
    const [error, setError] = useState(ERROR_STATUS.NONE);

    const newDataAvailableListener = useRef(null);

    // Used in order to workaround Safari browser issue with not stopping recording on pause
    const pauseRef = useRef(false);
    useEffect(() => {
        pauseRef.current = status === MEDIA_STATUS.PAUSED;
    }, [status]);

    const pauseRecording = () => {
        if (
            mediaRecorder.current &&
            mediaRecorder.current.state === MEDIA_STATUS.RECORDING
        ) {
            setStatus(MEDIA_STATUS.PAUSED);
            mediaRecorder.current.pause();
            dispatch(settingsRecordingsIsMicCurrentlyRecording(false));
        }
    };

    const resumeRecording = () => {
        if (
            mediaRecorder.current &&
            mediaRecorder.current.state === MEDIA_STATUS.PAUSED
        ) {
            group.current += 1;
            setStatus(MEDIA_STATUS.RECORDING);
            mediaRecorder.current.resume();
            dispatch(settingsRecordingsIsMicCurrentlyRecording(true));
        }
    };

    const stopRecording = () => {
        // Prevents unnecessary chunks generation during tracks stop
        preventChunksAddFlag.current = true;
        preloadedChunksTimingEnd.current = 0;
        if (mediaRecorder.current) {
            if (mediaRecorder.current.state !== 'inactive') {
                setStatus(MEDIA_STATUS.STOPPING);
                mediaRecorder.current.stop();
                dispatch(settingsRecordingsIsMicCurrentlyRecording(false));
                if (mediaStream.current) {
                    mediaStream.current
                        .getTracks()
                        .forEach((track) => track.stop());
                }
            }
        }
    };

    const getMediaStream = useCallback(async () => {
        setStatus(MEDIA_STATUS.ACQUIRING_MEDIA);
        const requiredMedia = {
            audio: typeof audio === 'boolean' ? !!audio : audio,
            video: typeof video === 'boolean' ? !!video : video,
        };
        try {
            if (screen) {
                const stream =
                    await window.navigator.mediaDevices.getDisplayMedia({
                        video: video || true,
                    });
                stream.getVideoTracks()[0].addEventListener('ended', () => {
                    stopRecording();
                });
                if (audio) {
                    const audioStream =
                        await window.navigator.mediaDevices.getUserMedia({
                            audio,
                        });

                    audioStream
                        .getAudioTracks()
                        .forEach((audioTrack) => stream.addTrack(audioTrack));
                }
                mediaStream.current = stream;
            } else {
                mediaStream.current =
                    await window.navigator.mediaDevices.getUserMedia(
                        requiredMedia,
                    );
            }
            setStatus(MEDIA_STATUS.IDLE);
        } catch (err) {
            setError(err?.name);
            setStatus(MEDIA_STATUS.IDLE);
        }
    }, [audio, video, screen]);

    useEffect(() => {
        if (!window.MediaRecorder) {
            setError('Unsupported browser');
        }

        if (screen) {
            if (!window.navigator.mediaDevices.getDisplayMedia) {
                setError("This browser doesn't support screen capturing");
            }
        }

        const checkConstraints = (mediaType) => {
            const supportedMediaConstraints =
                navigator.mediaDevices.getSupportedConstraints();
            const unSupportedConstraints = Object.keys(mediaType).filter(
                (constraint) => !supportedMediaConstraints[constraint],
            );

            if (unSupportedConstraints.length > 0) {
                // eslint-disable-next-line no-console
                console.error(
                    `The constraints ${unSupportedConstraints.join(
                        ',',
                    )} doesn't support on this browser. Please check your ReactMediaRecorder component.`,
                );
            }
        };

        if (typeof audio === 'object') {
            checkConstraints(audio);
        }
        if (typeof video === 'object') {
            checkConstraints(video);
        }

        if (mediaRecorderOptions && mediaRecorderOptions.mimeType) {
            if (!MediaRecorder.isTypeSupported(mediaRecorderOptions.mimeType)) {
                console.error(
                    `The specified MIME type you supplied for MediaRecorder doesn't support this browser`,
                );
            }
        }

        if (!mediaStream.current && askPermissionOnMount) {
            getMediaStream();
        }

        return () => {
            if (mediaStream.current) {
                const tracks = mediaStream.current.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [
        audio,
        screen,
        video,
        getMediaStream,
        mediaRecorderOptions,
        askPermissionOnMount,
    ]);

    // Media Recorder Handlers
    const onRecordingActive = ({ data, timeStamp }) => {
        if (
            preventChunksAddFlag.current === false &&
            pauseRef.current !== true
        ) {
            if (data?.size) {
                mediaChunks.current.push({
                    data,
                    timeStamp: timeStamp + preloadedChunksTimingEnd.current,
                    id: recordingId.current,
                    group: group.current,
                });
            } else {
                callOnDev(() => {
                    console.debug('Empty voice recording chunk detected.');
                });
            }
            if (typeof newDataAvailableListener.current === 'function') {
                newDataAvailableListener.current(mediaChunks.current);
            }
        }
    };

    const onRecordingStop = () => {
        const type = (() => {
            if (mediaChunks.current.length === 0) return null;
            // Sometimes first values on chunks array are return without proper type value
            // there is no need to lookup more than 50 first values
            const typedChunk = mediaChunks.current
                .slice(0, 50)
                .find((v) => v?.data?.type);
            const chunkType = typedChunk?.data?.type;
            if (chunkType) return chunkType;
            if (video) return 'video/mp4';
            if (isSafari()) return 'audio/mp4';
            return 'audio/ogg';
        })();

        const groupedChunks = groupBy(mediaChunks.current, 'id');
        const blobs = Object.values(groupedChunks).map(
            (chunksArr) =>
                new Blob(
                    chunksArr.map(({ data }) => data),
                    { type },
                ),
        );

        setMediaBlobs(blobs);
        preventChunksAddFlag.current = false;
        mediaChunks.current = [];
        setStatus(MEDIA_STATUS.STOPPED);
    };

    const startRecording = async () => {
        recordingId.current = nanoid();
        setError(ERROR_STATUS.NONE);
        if (!mediaStream.current) {
            await getMediaStream();
        }
        if (mediaStream.current) {
            const isStreamEnded = mediaStream.current
                .getTracks()
                .some((track) => track.readyState === 'ended');
            if (isStreamEnded) {
                await getMediaStream();
            }

            // User blocked the permissions (getMediaStream errored out)
            if (!mediaStream.current.active) {
                return;
            }
            mediaRecorder.current = new MediaRecorder(mediaStream.current, {
                mimeType: !isSafari() ? 'audio/webm' : 'audio/mp4',
            });
            mediaRecorder.current.ondataavailable = onRecordingActive;
            mediaRecorder.current.onstop = onRecordingStop;
            mediaRecorder.current.onerror = () => {
                setError(ERROR_STATUS.NO_RECORDER);
                setStatus(MEDIA_STATUS.IDLE);
            };
            mediaRecorder.current.start(500);
            setStatus(MEDIA_STATUS.RECORDING);
            dispatch(settingsRecordingsIsMicCurrentlyRecording(true));
        }
    };

    const muteAudio = (mute) => {
        setIsAudioMuted(mute);
        if (mediaStream.current) {
            mediaStream.current.getAudioTracks().forEach((audioTrack) => {
                // eslint-disable-next-line no-param-reassign
                audioTrack.enabled = !mute;
            });
        }
    };

    const startOnPause = async () => {
        if (status !== MEDIA_STATUS.IDLE) {
            throw new Error(
                'Method cannot be called for given recording status',
            );
        } else {
            preventChunksAddFlag.current = true;
            await startRecording();

            // Safari needs it. It has not been found why.
            if (isSafari()) {
                setTimeout(async () => {
                    await pauseRecording();
                }, 2000);
            } else {
                await pauseRecording();
            }
            preventChunksAddFlag.current = false;
        }
    };

    const onNewDataAvailable = (callback) => {
        if (typeof callback === 'function' || !callback) {
            newDataAvailableListener.current = callback;
        }
    };

    const setInitialRecordingData = (chunks) => {
        if (!Array.isArray(chunks))
            throw new Error('Illegal arguments provided');
        if (status !== MEDIA_STATUS.IDLE)
            throw new Error(
                'Cannot set recording chunks when it is not in idle state',
            );

        preloadedChunksTimingEnd.current =
            Math.max(...chunks.map((v) => v.timeStamp)) || 0;

        mediaChunks.current = chunks;
    };

    return {
        error,
        muteAudio: () => muteAudio(true),
        unMuteAudio: () => muteAudio(false),
        startRecording,
        startOnPause,
        pauseRecording,
        resumeRecording,
        stopRecording,
        mediaBlobs,
        status,
        isAudioMuted,
        setIdle: () => {
            // TODO Check if needs handling
            setStatus(MEDIA_STATUS.IDLE);
        },
        onNewDataAvailable,
        preloadAudioData: setInitialRecordingData,
    };
}
