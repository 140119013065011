export const getMicRecordingPanelVisible = (state) =>
    state?.settings?.recordings?.micRecordingPanelVisible || false;

export const getMicRecordingPanelActive = (state) =>
    state?.settings?.recordings?.micRecordingPanelActive || false;

export const getIsMicCurrentlyRecording = (state) =>
    state?.settings?.recordings?.isMicCurrentlyRecording || false;

export const getMicRecordingProgress = (state) =>
    state?.settings?.recordings?.micRecordingProgress || 0;

export const getMicRecordingStatus = (state) =>
    state?.settings?.recordings?.micRecordingStatus || '';
