import { Table, Input } from 'antd';
import { capitalize } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo, useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import {
    ColoredAvatar,
    ColoredDivColumn,
    ColoredDivNameWrapper,
    ColoredTable,
    ColoredTableTextWrapper,
    CustomSwitch,
    SwitchWrapper,
} from '@/components/settings/team/allTeamMembers/allTeamMembers.style';
import UserRoleSelect from '@/components/settings/team/userRoleSelect/userRoleSelect';
import TeamMemberTableMenu from '@/components/settings/team/allTeamMembers/tableComponents/teamMemberTableMenu';
import {
    getTeamMembersRefreshLoading,
    getTeamMembersWithYouAsCurrentUser,
    getHideArchivedTeamMembers,
} from '@/redux/selectors/teamMembersSelector';
import { fetchTeamMembers, toggleToggleArchivedTeamMembers } from '@/redux/actions/teamMembersAction';
import {
    getIsAdminSelector,
    getIsManagerSelector,
    getUserInfoLoadingSelector,
    getUserSelector,
} from '@/redux/selectors/authSelector';
import { USER_ROLES } from '@/constants/userRoles';
import {
    userArchiveStart,
    userReactivateStart,
    userUpdateStart,
} from '@/redux/actions/usersAction';
import { useInterval } from '@/utils/useInterval/useInterval';
import { bool } from 'prop-types';

import {
    getInvitesForTable,
    getInvitesRefetchLoading,
    getInvitesUpdateLoading,
} from '@/redux/selectors/invitesSelector';
import { fetchInvites } from '@/redux/actions/invitesAction';
import InviteTableMenu from '@/components/settings/team/allTeamMembers/tableComponents/inviteTableMenu';
import { AvatarChildren } from '@/components/settings/team/allTeamMembers/tableComponents/avatarChildren';
import { Ellipsis } from '@/components/settings/team/allTeamMembers/tableComponents/ellipsis';
import { TableTitle } from '@/components/settings/team/allTeamMembers/tableComponents/tableTitle';
import attendeesReducer from '@/redux/reducers/attendeesReducer';
import attendeesSaga from '@/redux/sagas/attendeesSaga';
import { ATTENDEES_KEY } from '@/constants/reduxKeys';
import {
    fetchAttendeesDetailsStart,
} from '@/redux/actions/attendeesAction';
import {SearchOutlined} from '@ant-design/icons';

const isUserActive = (record) => record.deleted_at == null && !record.owner_id;
const sortMembers = (a, b) => (isUserActive(a) && !isUserActive(b) ? -1 : 1);

function AllTeamMembers({ withoutTitle, simple }) {
    useInjectReducer({
        key: ATTENDEES_KEY,
        reducer: attendeesReducer,
    });
    useInjectSaga({ key: ATTENDEES_KEY, saga: attendeesSaga });

    const user = useSelector(getUserSelector);
    const userLoading = useSelector(getUserInfoLoadingSelector);
    const isAdmin = useSelector(getIsAdminSelector);
    const isManager = useSelector(getIsManagerSelector)

    const teamMembers = useSelector(getTeamMembersWithYouAsCurrentUser);
    const invites = useSelector(getInvitesForTable);

    const invitesInitialLoading = useSelector(getInvitesRefetchLoading);
    const invitesUpdateLoading = useSelector(getInvitesUpdateLoading);

    const teamMembersRefreshLoading = useSelector(getTeamMembersRefreshLoading);
    const hideArchivedTeamMembers = useSelector(getHideArchivedTeamMembers);

    const dispatch = useDispatch();

    const isCurrentUser = useCallback(
        (record) => record.id === user.id,
        [user.id],
    );

    useInterval(() => {
        dispatch(fetchTeamMembers());
        dispatch(fetchInvites());
    }, [10000]);

    
    useEffect(() => {
        dispatch(
            fetchAttendeesDetailsStart(),
        );
    }, [dispatch]);


    const sortedData = useMemo(() => {
        let tableData = [];
        if (teamMembers) tableData = [...teamMembers.sort(sortMembers)];
        if (invites) tableData = [...invites, ...tableData];

        if (hideArchivedTeamMembers) {
            tableData = tableData.filter((item) => item.deleted_at == null);
        }
        return tableData;
    }, [invites, teamMembers, hideArchivedTeamMembers]);

    const loading = (() => {
        if (userLoading || invitesUpdateLoading || teamMembersRefreshLoading)
            return true;
        return invitesInitialLoading && invites.length === 0;
    })();

    const canUserEdit = (userRole) => {
        if (isAdmin) return true;
        if (!isManager) return false;
        if (userRole === USER_ROLES.ADMIN) return false;
        return true;
    }

    return (
        <ColoredDivColumn>
            {!simple ? <SwitchWrapper>
                <span>Hide archived team members</span>
                <CustomSwitch checked={hideArchivedTeamMembers} defaultChecked onChange={() => dispatch(toggleToggleArchivedTeamMembers())} />
            </SwitchWrapper> : ''}
            <ColoredTable
                loading={loading}
                dataSource={sortedData}
                pagination={false}
            >
                <Table.Column
                    title="Name"
                    dataIndex="name"
                    sorter={(a, b) => {
                        if (!a.firstName && !b.firstName) return 0;
                        if (!a.firstName) return -1;
                        if (!b.firstName) return 1;
                        return a.firstName.localeCompare(b.firstName);
                    }}
                    filterDropdown={({setSelectedKeys, selectedKeys, confirm}) => <Input 
                        autoFocus
                        prefix={<SearchOutlined />}
                        placeholder='Search for Team Member' 
                        value={selectedKeys[0]} 
                        onChange={(e) => {
                            setSelectedKeys(e.target.value?[e.target.value]:[])
                            confirm({ closeDropdown: false })
                        }}
                        onPressEnter={() => {confirm()}}
                        allowClear/>
                    }
                    filterIcon={() => <SearchOutlined/>}
                    onFilter={(value, record) => record.firstName ? record.firstName.toLowerCase().startsWith(value.toLowerCase()) : false}
                    render={(name, record) => (
                        <ColoredDivNameWrapper $active={isUserActive(record)}>
                            <ColoredAvatar src={record.photo} size={30}>
                                <AvatarChildren
                                    isInvite={record.isInvite}
                                    firstName={record.firstName}
                                    lastName={record.lastName}
                                />
                            </ColoredAvatar>
                            {record.firstName} {record?.lastName?.length && record?.lastName?.replace(/[0-9#]/g, '')}
                        </ColoredDivNameWrapper>
                    )}
                />
                <Table.Column
                    title="Email"
                    dataIndex="email"
                    sorter={(a, b) => {
                        if (!a.email && !b.email) return 0;
                        if (!a.email) return -1;
                        if (!b.email) return 1;
                        return a.email.localeCompare(b.email);
                    }}
                    render={(name, record) => (
                        <ColoredTableTextWrapper $active={isUserActive(record)}>
                            {record.email}
                        </ColoredTableTextWrapper>
                    )}
                />
                <Table.Column
                    title="Role"
                    dataIndex="permission_role"
                    sorter={(a, b) => {
                        if (!a.permission_role && !b.permission_role) return 0;
                        if (!a.permission_role) return -1;
                        if (!b.permission_role) return 1;
                        return a.permission_role.localeCompare(b.permission_role);
                    }}
                    render={(permissionRole, record) => {
                        if (record.isInvite) {
                            return (
                                <ColoredTableTextWrapper
                                    $active={isUserActive(record)}
                                >
                                    {capitalize(record.permission_role)}
                                </ColoredTableTextWrapper>
                            );
                        }
                        return (
                            <UserRoleSelect
                                currentUserRole={user.permission_role}
                                value={
                                    record.deleted_at == null
                                        ? permissionRole
                                        : USER_ROLES.ARCHIVED
                                }
                                disabled={!canUserEdit(permissionRole) || !isUserActive(record)}
                                onChange={(role) => {
                                    dispatch(
                                        userUpdateStart({
                                            id: record.id,
                                            permission_role: role,
                                        }),
                                    );
                                }}
                                bordered={false}
                            />
                        );
                    }}
                />
                <Table.Column
                    title="Title"
                    dataIndex="title"
                    sorter={(a, b) => {
                        if (!a.title && !b.title) return 0;
                        if (!a.title) return -1;
                        if (!b.title) return 1;
                        return a.title.localeCompare(b.title);
                    }}
                    render={(name, record) => (
                        <ColoredTableTextWrapper $active={isUserActive(record)}>
                            {record.title}
                        </ColoredTableTextWrapper>
                    )}
                />
                {isAdmin && (
                    <Table.Column
                        title={!withoutTitle ? TableTitle : undefined}
                        render={(_, record) => {
                            if (record.isInvite)
                                return (
                                    <InviteTableMenu id={record.id}>
                                        <Ellipsis />
                                    </InviteTableMenu>
                                );
                            return (
                                <TeamMemberTableMenu
                                    disabled={isCurrentUser(record)}
                                    active={isUserActive(record)}
                                    onConfirm={async () => {
                                        if (isUserActive(record)) {
                                            dispatch(
                                                userArchiveStart(record.id),
                                            );
                                        } else {
                                            dispatch(
                                                userReactivateStart(record.id),
                                            );
                                        }
                                    }}
                                >
                                    <Ellipsis />
                                </TeamMemberTableMenu>
                            );
                        }}
                    />
                )}
            </ColoredTable>
        </ColoredDivColumn>
    );
}

export default AllTeamMembers;

AllTeamMembers.defaultProps = {
    withoutTitle: false,
    simple: false,
};
AllTeamMembers.propTypes = {
    withoutTitle: bool,
    simple: bool,
};
