import { LinkWrapper } from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import { ROUTES } from '@/constants/routes';
import { notification } from 'antd';
import React from 'react';

export const getConversationReadyNotificationObject = (
    conversationId,
    title,
) => ({
    message: 'Your conversation is ready',
    description: (
        <LinkWrapper href={ROUTES.CONVERSATIONS_RESULTS(conversationId)}>
            {title}
        </LinkWrapper>
    ),
    duration: 10,
});

const getTimeKey = (userId, convId) => `time||${userId}||${convId}`;

export const saveCurrentConvAndUserTime = (userId, convId, time) => {
    window.localStorage.setItem(getTimeKey(userId, convId), time);
};

export const loadCurrentConvAndUserTime = (userId, convId) =>
    window.localStorage.getItem(getTimeKey(userId, convId));

export const clearCurrentConvAndUserTime = (userId, convId) =>
    window.localStorage.removeItem(getTimeKey(userId, convId));

export const showError = (message) =>
    notification.error({
        message: 'Error',
        description: message,
        duration: 10,
        placement: 'bottomRight',
    });

export const showSuccess = (message) =>
    notification.success({
        message: 'Success',
        description: message,
        duration: 5,
        placement: 'bottomRight',
    });
