import {
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';

export const GEMS_INSIGHTS_FETCH_START = 'GEMS_INSIGHTS/FETCH_START';
export const GEMS_INSIGHTS_FETCH_SUCCESS = 'GEMS_INSIGHTS/FETCH_SUCCESS';
export const GEMS_INSIGHTS_FETCH_FAILURE = 'GEMS_INSIGHTS/FETCH_FAILURE';

export const fetchCustomGemsInsights = () => fetchData(GEMS_INSIGHTS_FETCH_START);
export const fetchCustomGemsInsightsSuccess = (data) =>
    fetchSuccess(GEMS_INSIGHTS_FETCH_SUCCESS, data);
export const fetchCustomGemsInsightsFailure = (error) =>
    fetchFailure(GEMS_INSIGHTS_FETCH_FAILURE, error);
