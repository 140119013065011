import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';

export const SETTINGS_NOTIFICATIONS_FETCH_START =
    'SETTINGS_NOTIFICATIONS/FETCH_START';
export const SETTINGS_NOTIFICATIONS_FETCH_SUCCESS =
    'SETTINGS_NOTIFICATIONS/FETCH_SUCCESS';
export const SETTINGS_NOTIFICATIONS_FETCH_FAILURE =
    'SETTINGS_NOTIFICATIONS/FETCH_FAILURE';

export const SETTINGS_NOTIFICATIONS_UPDATE_START =
    'SETTINGS_NOTIFICATIONS/UPDATE_START';
export const SETTINGS_NOTIFICATIONS_UPDATE_SUCCESS =
    'SETTINGS_NOTIFICATIONS/UPDATE_SUCCESS';
export const SETTINGS_NOTIFICATIONS_UPDATE_FAILURE =
    'SETTINGS_NOTIFICATIONS/UPDATE_FAILURE';

export const SET_LAST_NOTIFICATION_TIME = 'SET_LAST_NOTIFICATION_TIME';

export const fetchSettingsNotifications = () =>
    fetchData(SETTINGS_NOTIFICATIONS_FETCH_START);
export const fetchSettingsNotificationsSuccess = (data) =>
    fetchSuccess(SETTINGS_NOTIFICATIONS_FETCH_SUCCESS, data);
export const fetchSettingsNotificationsFailure = (error) =>
    fetchFailure(SETTINGS_NOTIFICATIONS_FETCH_FAILURE, error);
export const settingsNotificationsUpdateStart = (data) =>
    updateStart(SETTINGS_NOTIFICATIONS_UPDATE_START, data);
export const settingsNotificationsUpdateFailure = (error) =>
    updateFailure(SETTINGS_NOTIFICATIONS_UPDATE_FAILURE, error);
export const settingsNotificationsUpdateSuccess = (data) =>
    updateSuccess(SETTINGS_NOTIFICATIONS_UPDATE_SUCCESS, data);
export const settingsNotificationTimeUpdateStart = (data) =>
    updateStart(SET_LAST_NOTIFICATION_TIME, data);
