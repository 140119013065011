/* eslint-disable indent */
import styled from 'styled-components';
import { Select } from 'antd';

const ColoredSelect = styled(Select)`
    background: transparent;

    &&& {
        text-transform: lowercase;
        *::first-letter {
            text-transform: uppercase;
        }
    }

    .ant-select-selector {
        padding: ${({ bordered }) =>
            bordered === false && '0 11px 0 0 !important'};
        border: ${({ bordered }) => bordered === false && 'none'};
        background: ${({ bordered }) => bordered === false && 'transparent'};
    }

    &&& {
        .ant-select-selection-item {
            padding-right: ${({ bordered }) => bordered === false && '2rem'};
        }
    }
`;

export { ColoredSelect };
