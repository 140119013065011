import {
    countOccurrences,
    filterOut,
    reducePlurals,
    removeDuplicates,
    sortBySalience,
} from '@/utils/conversations/analytics/keywords/keywords';

export const getSelectedEntities = (content, speakers, sentimentEntities) => {
    if (!content || !speakers || !sentimentEntities) return [];

    const onlySelectedSpeakers = ({ speakerId }) =>
        speakers.some(({ id }) => speakerId === id);

    const filteredContent = content.filter(onlySelectedSpeakers);

    const filteredEntities = sentimentEntities
        .filter(filterOut(filteredContent))
        .sort(sortBySalience)
        .reduce(reducePlurals, []);

    if (filteredEntities.length === 0) return [];

    const allWords = filteredContent.map(({ words }) => words);

    return filteredEntities
        .reduce(removeDuplicates, [])
        .map(countOccurrences(allWords))
        .sort((a, b) => b.count - a.count)
        .slice(0, 20);
};
