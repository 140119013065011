import { put, select, take, takeEvery } from 'redux-saga/effects';
import {
    RECORDINGS_RECOVERY_READ_START,
    recordingsRecoveryReadFailure,
    recordingsRecoveryReadSuccess,
    recordingsRecoveryReadSuccessNoData,
} from '@/redux/actions/recordings/recordingRecoveryActions';
import {
    getAudioRecoveryData,
    isDataForCurrentUserPresent,
} from '@/utils/recording/recovery/readUtils';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { AUTH_USER_FETCH_SUCCESS } from '@/redux/actions/authAction';

export function* getRecordingsRecoveryData() {
    try {
        if (process.env.NODE_ENV !== 'test') {
            yield take([AUTH_USER_FETCH_SUCCESS]);
        }
        const userId = yield select(getUserIdSelector);
        const isDataPresent = yield isDataForCurrentUserPresent(userId);

        if (!isDataPresent) {
            yield put(recordingsRecoveryReadSuccessNoData());
        } else {
            const { title, date, meetingExternalId, conversationId } =
                yield getAudioRecoveryData(userId);
            yield put(
                recordingsRecoveryReadSuccess({
                    conversationId,
                    title,
                    date,
                    meetingExternalId,
                }),
            );
        }
    } catch (error) {
        yield put(recordingsRecoveryReadFailure(error));
    }
}

export default function* recordingsRecoverySaga() {
    yield takeEvery(RECORDINGS_RECOVERY_READ_START, getRecordingsRecoveryData);
}
