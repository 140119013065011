import { getAlreadyUsedBrowserDbStoragePercentage } from '@/utils/storage/indexedDb';
import { audioRecoveryStorage } from '@/utils/recording/recovery/chunksUtils';
import isSafari from '@/utils/deviceDetect/isSafari';
import { blobToArrayBuffer } from '@/utils/storage/blob';

export const TAKEN_STORAGE_THRESHOLD = 90;
export const META_DATA_POSTFIX = 'meta';
export const PART_SIZE = 100;

export const getDataRecoveryKey = (userId, conversationId, index) =>
    `${userId}||${conversationId}||${index}`;

export const getMetaDataKey = (userId, conversationId) =>
    `${userId}||${conversationId}||${META_DATA_POSTFIX}`;

const convertToArrBufferChunk = async (chunk) => ({
    ...chunk,
    data: await blobToArrayBuffer(chunk.data),
    type: chunk.data.type,
});

const saveChunks = async (
    userId,
    conversationId,
    meetingExternalId,
    title,
    chunks,
) => {
    if (chunks.length) {
        const index = Math.floor(chunks.length / PART_SIZE);

        const storageObject = {
            title,
            date: new Date().toISOString(),
            meetingExternalId,
            length: chunks.length,
        };

        await audioRecoveryStorage.setItem(
            getMetaDataKey(userId, conversationId),
            JSON.stringify(storageObject),
        );

        if (!isSafari()) {
            await audioRecoveryStorage.setItem(
                `${getDataRecoveryKey(userId, conversationId, index)}`,
                chunks.slice(index * PART_SIZE, index * PART_SIZE + PART_SIZE),
            );
        } else {
            const convertedChunks = await Promise.all(
                chunks.map(convertToArrBufferChunk),
            );

            const orderedChunks = convertedChunks.sort(
                (a, b) => a.timeStamp - b.timeStamp,
            );

            await audioRecoveryStorage.setItem(
                `${getDataRecoveryKey(userId, conversationId, index)}`,
                orderedChunks.slice(
                    index * PART_SIZE,
                    index * PART_SIZE + PART_SIZE,
                ),
            );
        }
    }
};

export const clearUserData = async (userId) => {
    const keys = await audioRecoveryStorage.keys();
    if (!keys) return;
    const userKeys = keys.filter((v) => v.includes(userId));
    await Promise.all(
        userKeys.map((key) => audioRecoveryStorage.removeItem(key)),
    );
};

export const cacheAudioChunksOnLocalDb = async (
    userId,
    conversationId,
    meetingExternalId,
    title,
    chunks,
) => {
    let takenStoragePercentage;

    try {
        takenStoragePercentage =
            await getAlreadyUsedBrowserDbStoragePercentage();
    } catch (e) {
        // eslint-disable-next-line no-console
        console.warn('IndexedDb taken storage estimation is not available');
    }

    const isTakenStorageEstimationAvailable = takenStoragePercentage;

    if (takenStoragePercentage >= TAKEN_STORAGE_THRESHOLD) {
        // eslint-disable-next-line no-console
        console.log('Taken IndexedDb storage is near the browser limit');
    }

    let saveError;
    if (
        takenStoragePercentage < TAKEN_STORAGE_THRESHOLD ||
        !isTakenStorageEstimationAvailable
    ) {
        try {
            await saveChunks(
                userId,
                conversationId,
                meetingExternalId,
                title,
                chunks,
            );
        } catch (e) {
            saveError = e;

            if (e?.name === 'QuotaExceededError') {
                // eslint-disable-next-line no-console
                console.error(
                    'Recording cache save failed because of exceeded amount of space',
                    e,
                );
            } else {
                // eslint-disable-next-line no-console
                console.error('Recording cache save failed', e);
            }
        }
    }

    return {
        isSuccess: !saveError,
        takenStorage: takenStoragePercentage,
    };
};
