import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { POLL_STATE, TS_ERRORS } from '@/constants/poll/pollState';

export const callGetConversationData = async (conversationID) => {
    const { data } = await axios.get(
        NEXT_ROUTES.CONVERSATIONS_ID(conversationID),
    );
    return data;
};

export const callGetRecordingStatus = async (conversationID) => {
    const { data } = await axios.get(
        NEXT_ROUTES.RECORDINGS_STATUS(conversationID),
    );
    if (data.error) throw new Error(data.message);
    return data;
};

export const isConversationProcessingCompleted = (conversation) =>
    conversation.transcription_status === 'completed' &&
    conversation.question_model_status;

export const isError = (status, tsStatus) => {
    if (status === null)
        return (
            tsStatus === TS_ERRORS.DEFAULT || tsStatus === TS_ERRORS.NO_AUDIO
        );
    return POLL_STATE.ERRORS_ARRAY().includes(status);
};

export const shouldLaunchConversationStatusPolling = (
    conv,
    status,
    launchAlsoOnEmptyTsStatus = false,
) => {
    const hasId = conv?.id;
    if (!hasId) return false;

    const isCompleted = isConversationProcessingCompleted(conv);
    const hasError = isError(status, conv?.transcription_status);
    const hasNonEmptyTsStatus = !!conv.transcription_status;
    const allowedRunForStatus = launchAlsoOnEmptyTsStatus
        ? true
        : hasNonEmptyTsStatus;

    return hasId && !isCompleted && !hasError && allowedRunForStatus;
};

export const getTsStatus = async (conversationId) => {
    const statusData = await callGetRecordingStatus(conversationId);

    return {
        finishedInitialProcessing: statusData?.processing,
        percentage: statusData.percent,
        location: statusData?.location,
        error: statusData.error,
    };
};
