import styled, { css } from 'styled-components';
import { Avatar, Button, Table, Switch } from 'antd';
import {
    VIVIDORANGE,
    GRAY_6,
    GRAY_3,
    GRAY_4,
    GRAY_1,
} from '@/constants/colors';

const ColoredDivColumn = styled.div`
    flex: 0 1 80%;
`;

const ColoredTable = styled(Table)`
    border: 1px solid ${GRAY_4};
    .ant-table-thead {
        .ant-table-cell {
            background-color: ${GRAY_6};
            :before {
                width: 0 !important;
            }
        }
    }
`;

const tableTextProps = css`
    color: ${({ $active }) => ($active ? GRAY_1 : GRAY_4)};
`;

const ColoredDivNameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.75rem;
    ${tableTextProps};
`;

const ColoredTableTextWrapper = styled.div`
    ${tableTextProps};
`;

const ColoredDivTableHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ColoredAvatar = styled(Avatar)`
    flex: 0 0 auto;
    background: ${GRAY_6};
    color: ${GRAY_3};
    font-size: 14px !important;
`;

const ColoredTableActionButton = styled(Button)`
    border-color: ${VIVIDORANGE};
    color: ${VIVIDORANGE};
`;

const ColoredRowButton = styled(Button)`
    &:hover {
        color: ${VIVIDORANGE};
    }
`;

const CustomSwitch = styled(Switch)`
    margin-left: 8px;
`;

const SwitchWrapper = styled.div`
    display: flex;
    align-items: center;
    padding: 1rem 0;
    justify-content: flex-end;
`;

export {
    ColoredDivColumn,
    ColoredTable,
    ColoredDivNameWrapper,
    ColoredDivTableHeader,
    ColoredAvatar,
    ColoredRowButton,
    ColoredTableActionButton,
    ColoredTableTextWrapper,
    CustomSwitch,
    SwitchWrapper,
};
