import { Fragment, useEffect, useState } from 'react';
import { Col, Dropdown, Menu, notification, Row, Spin, Typography } from 'antd';
import { bool } from 'prop-types';
import ConfirmDelete from '@/components/integrations/zoom/confirmDeleteModal';
import { CHECK_CIRCLE_TWO_TONE_COLOR } from '@/constants/colors';
import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';
import Ellipsis from '@/svg/ellipsis/ellipsis';
import Logo from '@/public/logos/Versational-Logo-R.svg';
import LogoWrapper from '@/components/logoWrapper';

import {
    EllipsisWrap,
    ConnectButton,
    InnerCard,
    LargeCheckCircle,
    LinkingText,
    LogoRow,
    MainLayout,
    LayoutContent,
    PaddedCol,
    PageWrapper,
    SpinRow,
    ZoomImage,
    StyledButton,
} from './zoom.styles';

const { Title } = Typography;


function OAuth({ simple }) {
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [zoomID, setZoomID] = useState(null);

    const showError = (message = 'Something went wrong', duration = 5) => {
        notification.error({
            message,
            duration,
        });
    };

    const showInfo = (message, duration = 5) => {
        notification.info({
            message,
            duration,
        });
    };

    const showSuccess = (message = 'Success!', duration = 5) => {
        notification.success({
            message,
            duration,
        });
    };

    const sendRequest = async () => {
        if (zoomID) return;

        const { data: redir } = await axios.get(NEXT_ROUTES.ZOOM);

        if (redir) {
            window.location = redir;
        } else {
            showError();
        }
    };

    const clearData = async () => {
        try {
            await axios.delete(NEXT_ROUTES.ZOOM_DATA);
            showInfo('Data deletion in progress');
        } catch (e) {
            showError('Data deletion failed, please try again');
        }
    };

    const removeIntegration = async () => {
        setLoading(true);
        try {
            await axios.delete(NEXT_ROUTES.INTEGRATIONS_ZOOM_ID(zoomID));

            setZoomID(null);
            setEmail('');
        } catch (e) {
            showError();
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(NEXT_ROUTES.INTEGRATIONS_ZOOM);

                if (data.length > 0) {
                    setZoomID(data[0].account_id);
                    const { data: meData } = await axios.get(
                        NEXT_ROUTES.ZOOM_ME,
                    );
                    if (meData.email) {
                        setEmail(meData.email);
                    }
                    if (window.location.search.indexOf('success') !== -1) {
                        showSuccess();
                    }
                } else if (window.location.search.indexOf('error') !== -1) {
                    if (window.location.search.indexOf('duplicate') !== -1) {
                        showError(
                            "This Zoom account is already associated with a Versational® account. If you're using an enterprise Zoom account, only one account may be linked at a time.",
                            15,
                        );
                    } else {
                        showError();
                    }
                }
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    const menu = (
        <Menu>
            <Menu.Item onClick={() => ConfirmDelete(clearData)}>
                Manage Existing Data
            </Menu.Item>
        </Menu>
    );

    return !loading ? (
        <PageWrapper>
            <MainLayout>
                <LayoutContent>
                    {!simple && <LogoRow>
                        <Col span={10} offset={6}>
                            <LogoWrapper>
                                <Logo />
                            </LogoWrapper>
                        </Col>
                    </LogoRow>}
                    {!simple && <Title level={2}>Welcome</Title>}
                    <p>
                        Get started by connecting your Zoom account.
                    </p>
                    <InnerCard
                        hasToken={!!zoomID}
                        hoverable={!zoomID}
                        onClick={sendRequest}>
                        <div>
                            <Row align="middle">
                                <Col span={3}>
                                    <ZoomImage src="/logos/zoom-icon.png" />
                                </Col>
                                <PaddedCol span={16}>
                                    {zoomID ? (
                                        <Fragment>
                                            <strong>Zoom</strong>
                                            <LargeCheckCircle
                                                twoToneColor={
                                                    CHECK_CIRCLE_TWO_TONE_COLOR
                                                }
                                            />
                                            <Dropdown
                                                overlay={menu}
                                                placement="bottomLeft"
                                                trigger={['hover']}
                                            >
                                                <EllipsisWrap>
                                                    <Ellipsis />
                                                </EllipsisWrap>
                                            </Dropdown>
                                            <p>
                                                Versational® is currently using{' '}
                                                <strong>{email}</strong> to discover
                                                events.
                                            </p>
                                        </Fragment>
                                    ) : (
                                        <LinkingText>
                                            Link your Zoom account
                                        </LinkingText>
                                    )}
                                </PaddedCol>
                                <Col span={5} style={{ textAlign: 'right' }}>
                                    {zoomID ? (
                                        <StyledButton onClick={removeIntegration}>
                                            Disconnect
                                        </StyledButton>
                                    ) : (
                                        <ConnectButton type="primary">
                                            Connect
                                        </ConnectButton>
                                    )}
                                </Col>
                            </Row>
                        </div>
                    </InnerCard>
                </LayoutContent>
            </MainLayout>
        </PageWrapper>
    ) : (
        <SpinRow justify="center" align="middle">
            <Spin size="large" />
        </SpinRow>
    );
}

OAuth.propTypes = {
    simple: bool,
};

OAuth.defaultProps = {
    simple: false,
};

export default OAuth;