import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HeaderActionsButton from '@/components/conversations/conversations/headerActionsButton/headerActionsButton';
import PageTitle from '@/components/conversations/conversations/pageTitle/newPageTitle';
import {
    useConversationContext,
    useMeetingContext,
} from '@/components/conversations/conversations/context/context';
import { ConversationContextProvider } from '@/components/conversations/conversations/context/conversationContext';
import { TimeProvider } from '@/components/timer/timerContext';
import { getMicRecordingPanelVisible, getMicRecordingPanelActive } from '@/redux/selectors/settings/settingsRecordingsSelector';
import { settingsRecordingsMicRecordingPanelVisibleToggle } from '@/redux/actions/settings/recordingsAction';
import { getIsAuthorized } from '@/redux/selectors/authSelector';
import {
    MicRecorderPanel,
    MicRecordingClose,
} from '@/components/micRecorder/micRecorder.styles';
import { func } from 'prop-types';
import { useRouter } from 'next/router';

function Covnersation({reloadComponent}) {
    const { title } = useConversationContext();
    const { meetingExternalId } = useMeetingContext();

    console.log(title)
    console.log(meetingExternalId)

    return (
        <>
            <HeaderActionsButton key="actions" reloadComponent={reloadComponent} />
            <PageTitle minimal />
        </>
    );
}

Covnersation.propTypes = {
    reloadComponent: func.isRequired,
};

function MicRecorder() {
    const dispatch = useDispatch();
    const micRecordingPanelVisible = useSelector(getMicRecordingPanelVisible);
    const micRecordingPanelActive = useSelector(getMicRecordingPanelActive);
    const isAuthorized = useSelector(getIsAuthorized);
    const [key, setKey] = useState(Math.random());
    const router = useRouter();
    const isNewRecordingPage = (router?.asPath?.startsWith('/calendar/new') && router.query.id !== undefined);

    const reloadComponent = () => {
        setKey(Math.random());
    };

    if ((isAuthorized && micRecordingPanelActive) && !isNewRecordingPage) {
        return (
            <MicRecorderPanel style={{bottom: `${micRecordingPanelVisible || isNewRecordingPage ? '0' : '-100%'}`}}>
                <MicRecordingClose onClick={() => dispatch(settingsRecordingsMicRecordingPanelVisibleToggle())} />
                <ConversationContextProvider key={key}>
                    <TimeProvider>
                        <Covnersation reloadComponent={reloadComponent} />
                    </TimeProvider>
                </ConversationContextProvider>            
            </MicRecorderPanel>
        );
    }
    return <></>;
}

export default MicRecorder;
