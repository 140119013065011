export const getRecordingsFilteredBySearchData = (recordings, searchData) => {
    if (!searchData) return [];
    const conversationIds = [
        ...new Set(
            searchData.comments
                .map((v) => v.conversation_id)
                .concat(searchData.conversations.map((v) => v.conversation_id)),
        ),
    ];

    return recordings.filter((v) =>
        conversationIds.some((id) => id === v?.conversation?.id),
    );
};

export const attachFullSpeakerInformation = (
    searchConversationItem,
    recording,
) => {
    // eslint-disable-next-line camelcase
    const { speaker_id, ...rest } = searchConversationItem;
    const speakers = recording?.attendees;
    return {
        ...rest,
        speaker: speakers.find(
            ({ id }) => id === searchConversationItem.speaker_id,
        ),
    };
};

export const attachInsights = (searchConversationItem) => {
    const gems = searchConversationItem.quotes
        .flatMap((v) => v.gems)
        .map((v) => v.name)
        .filter((v, i, a) => a.indexOf(v) === i);

    const tags = searchConversationItem.quotes
        .flatMap((v) => v.customTags)
        .map((v) => v.name)
        .filter((v, i, a) => a.indexOf(v) === i);

    return { ...searchConversationItem, insights: { gems, tags } };
};

export const mapSearchResultsToRecordings = (searchData) =>
    searchData.recordings.map((recording) => {
        const conversationSearch = searchData.conversations
            .filter((v) => v.conversation_id === recording?.conversation?.id)
            .map((v) => attachFullSpeakerInformation(v, recording))
            .map((v) => attachInsights(v));

        const commentsSearch = searchData.comments.filter(
            (v) => v.conversation_id === recording?.conversation?.id,
        );

        const guidesSearch = [];

        return {
            ...recording,
            conversationSearch,
            commentsSearch,
            guidesSearch,
        };
    });
