import { bool, func } from 'prop-types';
import { Modal } from 'antd';
import Dragger from '@/components/uploadRecordingsModal/dragger/dragger';

function UploadFileModal({ isModalVisible, setIsModalVisible, onUpload }) {
    return (
        <Modal
            closable={false}
            visible={isModalVisible}
            onCancel={() => setIsModalVisible(false)}
            footer={null}
            width={600}
        >
            <Dragger
                accept="audio/*,video/*"
                customRequest={({ file }) => {
                    onUpload(file);
                }}
            />
        </Modal>
    );
}

UploadFileModal.propTypes = {
    isModalVisible: bool.isRequired,
    setIsModalVisible: func.isRequired,
    onUpload: func.isRequired,
};

export default UploadFileModal;
