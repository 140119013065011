export const attendeeFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_ATTENDEE === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_ATTENDEE
        : process.env.NEXT_PUBLIC_FEATURE_ATTENDEE === 'true';

export const crmFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_CRM === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_CRM
        : process.env.NEXT_PUBLIC_FEATURE_CRM === 'true';

export const googleCalendarFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_GOOGLE_CALENDAR === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_GOOGLE_CALENDAR
        : process.env.NEXT_PUBLIC_FEATURE_GOOGLE_CALENDAR === 'true';

export const googleMeetFeatureFlag =
        typeof process.env.NEXT_PUBLIC_FEATURE_GOOGLE_MEET === 'boolean'
            ? process.env.NEXT_PUBLIC_FEATURE_GOOGLE_MEET
            : process.env.NEXT_PUBLIC_FEATURE_GOOGLE_MEET === 'true';

export const playlistFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_PLAYLIST === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_PLAYLIST
        : process.env.NEXT_PUBLIC_FEATURE_PLAYLIST === 'true';

export const subscriptionFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_PAYMENT === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_PAYMENT
        : process.env.NEXT_PUBLIC_FEATURE_PAYMENT === 'true';

export const zoomFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_ZOOM === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_ZOOM
        : process.env.NEXT_PUBLIC_FEATURE_ZOOM === 'true';

export const teamsFeatureFlag =
    typeof process.env.NEXT_PUBLIC_FEATURE_TEAMS === 'boolean'
        ? process.env.NEXT_PUBLIC_FEATURE_TEAMS
        : process.env.NEXT_PUBLIC_FEATURE_TEAMS === 'true';

export const testEnvironment = process.env.NEXT_PUBLIC_ENV === 'test';
