import { Avatar, Divider, Space } from 'antd';
import styled from 'styled-components';
import { GRAY_6, WHITE } from '@/constants/colors';

const AVATAR_SIZE = '1.75rem';

const AvatarsContainer = styled(Space).attrs(() => ({
    type: 'vertical',
    split: (
        <Divider
            type="vertical"
            style={{ color: GRAY_6, height: '1.5rem', margin: '0 0.25rem' }}
        />
    ),
}))`
    ${({ onClick }) => onClick && 'cursor: pointer'}
`;

const AvatarsGroup = styled.div`
    display: flex;
    flex-direction: row;

    .ant-avatar:not(:first-child) {
        margin-left: ${({ avatarMarginLeft = '-0.75rem' }) => avatarMarginLeft};
    }
`;

const AvatarWrapper = styled(Avatar)`
    width: ${AVATAR_SIZE};
    height: ${AVATAR_SIZE};
    border: 1px solid ${WHITE};

    .ant-avatar-string {
        top: 50%;
        transform: translate(-50%, -50%) !important;
    }
`;

export { AvatarsContainer, AvatarWrapper, AvatarsGroup };
