import { createSelector } from 'reselect';
import { GEM_SETTINGS } from '@/constants/reduxKeys';
import { FETCH_STATUS } from '@/constants/fetchStatus';
import { MANAGEMENT_TYPES } from '@/constants/settings/gemSettingsTypes';

export const getSelectedGemSettingsTab = (state) =>
    state.settings?.[GEM_SETTINGS]?.selectedTab;

export const getAllGemSettingsSelector = createSelector(
    [(state) => state.settings?.[GEM_SETTINGS]?.data],
    (gems) => gems ?? [],
);

export const getAllGemFilteredSettingsSelector = createSelector(
    [(state) => state.settings?.[GEM_SETTINGS]?.data],
    (gems) =>
        gems?.map((gem) => {
            if (MANAGEMENT_TYPES.includes(gem.gem.name)) {
                return {
                    ...gem,
                    gem: {
                        ...gem.gem,
                        type: {
                            ...gem.gem.type,
                            name: 'Management',
                        },
                    }
                    
                };
            }
            return gem;
        }) ?? []
);

export const getInitialLoadingGemSettingsSelector = (state) =>
    [FETCH_STATUS.IDLE, FETCH_STATUS.INITIAL_LOADING].includes(
        state.settings?.[GEM_SETTINGS]?.status,
    );
export const getUpdateLoadingGemSettingsSelector = (state) =>
    [FETCH_STATUS.UPDATE_LOADING].includes(
        state.settings?.[GEM_SETTINGS]?.status,
    );

export const getGemSettingsSelector = createSelector(
    [getAllGemFilteredSettingsSelector, getSelectedGemSettingsTab],
    (allGemSettings, selectedTab) =>
        allGemSettings.filter(
            (setting) => setting.gem.type.name.toLowerCase() === selectedTab,
        ),
);

export const getAreAllGemSettingsDisabled = createSelector(
    [getGemSettingsSelector],
    (gemSettings) => gemSettings.every((item) => !item.enabled),
);
