import { put, takeLatest } from 'redux-saga/effects';
import axios from '@/utils/api/axios';

import {
    CALENDAR_PROVIDER_FETCH_FAILURE,
    CALENDAR_PROVIDER_FETCH_START,
    CALENDAR_PROVIDER_FETCH_SUCCESS,
} from '@/redux/actions/_exports';
import { NEXT_ROUTES } from '@/constants/routes';

const getCalendarSettings = async() => 
    axios.get(NEXT_ROUTES.INTEGRATIONS_CALENDAR);


export function* getCalendarProvider() {
    try {
        const { data } = yield getCalendarSettings();

        yield put({
            type: CALENDAR_PROVIDER_FETCH_SUCCESS,
            payload: {
                provider: data.provider,
            },
        });
    } catch (error) {
        yield put({ type: CALENDAR_PROVIDER_FETCH_FAILURE });
    }
}


export default function* calendarsProviderSaga() {
    yield takeLatest(CALENDAR_PROVIDER_FETCH_START, getCalendarProvider);
}
