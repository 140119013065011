import {
    callGetConversationData,
    getTsStatus,
    isConversationProcessingCompleted,
} from '@/components/insights/recordings/upload/utils';
import { POLL_STATE, TS_ERRORS } from '@/constants/poll/pollState';
import { callSetTranscriptionStatusToError } from '@/components/insights/recordings/header/upload/uploadProps';

const defaultErrorMessage = 'Conversation must be included on to poll status';

const updateStateAndPercentage = (update) => (state, percentage) =>
    update((prev) =>
        percentage !== undefined
            ? { ...prev, state, percentage }
            : { ...prev, state },
    );

export const poll = async (conversation, pollStatus, update) => {
    if (!pollStatus) return;
    let nextState = null;
    let newConversationData;

    const updateState = updateStateAndPercentage(update);

    try {
        if (!conversation) throw new Error(defaultErrorMessage);
        const isConversationAsString = typeof conversation === 'string';

        if (isConversationAsString) {
            newConversationData = await callGetConversationData(conversation);
            updateState(POLL_STATE.INITIAL, 0);
            nextState = POLL_STATE.CONVERSATION_REFETCH;
        } else {
            const tsStatus = conversation?.transcription_status;
            const isStatusError = tsStatus === TS_ERRORS.DEFAULT;
            const isStatusNoAudio = tsStatus === TS_ERRORS.NO_AUDIO;
            const isTsQueued = tsStatus === 'queued';
            const isTsPending = tsStatus === 'pending';

            if (pollStatus === POLL_STATE.INITIAL) {
                updateState(POLL_STATE.INITIAL, 0);

                if (isConversationProcessingCompleted(conversation)) {
                    updateState(POLL_STATE.COMPLETED, 100);
                } else if (isStatusError) {
                    updateState(POLL_STATE.ERRORS.TS_DEFAULT, 0);
                } else if (isStatusNoAudio) {
                    updateState(POLL_STATE.ERRORS.NO_AUDIO, 0);
                } else if (isTsQueued || isTsPending) {
                    nextState = POLL_STATE.TS_FETCHING;
                } else if (!tsStatus) {
                    nextState = POLL_STATE.CONVERSATION_REFETCH;
                }
            } else if (pollStatus === POLL_STATE.CONVERSATION_REFETCH) {
                newConversationData = await callGetConversationData(
                    isConversationAsString ? conversation : conversation.id,
                );
                nextState = POLL_STATE.INITIAL;
            } else if (pollStatus === POLL_STATE.TS_FETCHING) {
                const {
                    finishedInitialProcessing,
                    percentage,
                    location,
                    error,
                } = await getTsStatus(conversation.id);

                if (error) {
                    updateState(POLL_STATE.ERRORS.TS_DEFAULT, 0);
                    await callSetTranscriptionStatusToError(
                        isConversationAsString ? conversation : conversation.id,
                    );
                } else {
                    updateState(
                        location
                            ? POLL_STATE.TS_FETCHING
                            : POLL_STATE.TS_PRE_PROCESSING,
                        percentage,
                    );

                    nextState = finishedInitialProcessing
                        ? POLL_STATE.CLASSIFIERS_FETCHING
                        : POLL_STATE.TS_FETCHING;
                }
            } else if (pollStatus === POLL_STATE.CLASSIFIERS_FETCHING) {
                updateState(POLL_STATE.CLASSIFIERS_FETCHING, 75);
                const convData = await callGetConversationData(conversation.id);
                if (isConversationProcessingCompleted(convData)) {
                    updateState(POLL_STATE.COMPLETED, 100);
                } else {
                    nextState = POLL_STATE.CLASSIFIERS_FETCHING;
                }
            }
        }
    } catch (e) {
        if (e?.message?.indexOf('Invalid data') !== -1) {
            updateState(POLL_STATE.ERRORS.INVALID_DATA, 0);
        } else {
            updateState(POLL_STATE.ERRORS.POLLING, 0);
        }
    }

    update((prev) => ({
        ...prev,
        nextState,
        ...(newConversationData && {
            conversation: newConversationData,
        }),
    }));
};
