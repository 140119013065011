import React from 'react';
import { string } from 'prop-types';

function VideoRegularIcon({ height }) {
    return (
        <svg
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            className='svg-inline--fa fa-video fa-w-18'
            data-icon='video'
            data-prefix='far'
            viewBox='0 0 576 512'
            style={{ height: height ?? 'unset', width: height ?? 'unset' }}
        >
            <path
                fill='currentColor'
                d='M543.9 96c-6.2 0-12.5 1.8-18.2 5.7L416 170.1v-58.3c0-26.4-23.2-47.8-51.8-47.8H51.8C23.2 64 0 85.4 0 111.8v288.4C0 426.6 23.2 448 51.8 448h312.4c28.6 0 51.8-21.4 51.8-47.8v-58.3l109.7 68.3c5.7 4 12.1 5.7 18.2 5.7 16.6 0 32.1-13 32.1-31.5V127.5C576 109 560.5 96 543.9 96zM368 200v198.9c-.6.4-1.8 1.1-3.8 1.1H51.8c-2 0-3.2-.6-3.8-1.1V113.1c.6-.4 1.8-1.1 3.8-1.1h312.4c2 0 3.2.6 3.8 1.1V200zm160 155.2l-112-69.8v-58.7l112-69.8v198.3z'
            />
        </svg>
    );
}


VideoRegularIcon.propTypes = {
    height: string,
};

VideoRegularIcon.defaultProps = {
    height: undefined,
};

export default VideoRegularIcon;
