import {
    fetchRecordingsFailure,
    fetchRecordingsSuccess,
    fetchRecordingsSimplifiedFailure,
    fetchRecordingsSimplifiedSuccess,
    fetchSearchRecordingsFailure,
    fetchSearchRecordingsSuccess,
    RECORDINGS,
    removeRecordingFailure,
    removeRecordingSuccess,
    updateConversationTitleFailure,
    updateConversationTitleSuccess,
    updateDateFailure,
    updateDateSuccess,
    updateTagsFailure,
    updateTagsSuccess,
} from '@/redux/actions/recordings/recordingAction';
import { updatePaginationTotal } from '@/redux/actions/recordings/recordingAction';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import {
    getOrderQueries,
    getPaginationQueries,
    NEXT_ROUTES,
} from '@/constants/routes';
import axios from '@/utils/api/axios';
import {
    getRecordings,
} from '@/redux/selectors/recordingsSelector';
import { message } from 'antd';
import {
    mapSearchResultsToRecordings,
} from '@/utils/recordings/search';

const attachInsights = (data) =>
    data.map((rec) => ({
        ...rec,
        insights: rec?.conversation?.insights || 0,
        action_items: rec?.conversation?.action_items || 0,
        comments: rec?.conversation?.comments || 0,
        questions: rec?.conversation?.questions || 0,
    }));

export function* fetchRecordings(action) {
    try {
        const { pageSize, currentPage, order, filter } = action.payload;
        const filterString = filter ? `&${filter}` : '';

        const {
            data: { data, count },
        } = yield call(
            axios,
            `${NEXT_ROUTES.RECORDINGS}?${getPaginationQueries(
                currentPage,
                pageSize,
            )}&${getOrderQueries(order)}${encodeURI(filterString)}`,
        );

        const uploadedRecordings = data.filter(
            (rec) =>
                rec.conversation.transcription_status !== 'pending' &&
                rec.conversation.transcription_status,
        );
        const unUploadedRecordings = data.filter(
            (rec) =>
                rec.conversation.transcription_status === 'pending' ||
                !rec.conversation.transcription_status,
        );

        // If user navigates away from page before upload completes,
        // this removes intermediate data
        for (let i = 0; i < unUploadedRecordings.length; i += 1) {
            yield call(
                axios.delete,
                NEXT_ROUTES.CONVERSATIONS_ID(
                    unUploadedRecordings[i].conversation.id,
                ),
            );
        }

        yield put(
            fetchRecordingsSuccess(
                uploadedRecordings ? attachInsights(uploadedRecordings) : [],
                count,
            ),
        );
    } catch (error) {
        yield put(fetchRecordingsFailure(error));
    }
}

export function* fetchRecordingsSimplified(action) {
    try {
        const { pageSize, currentPage, order, filter } = action.payload;
        const filterString = filter ? `&${filter}` : '';

        const {
            data: { data, count },
        } = yield call(
            axios,
            `${NEXT_ROUTES.RECORDINGS_SIMPLIFIED}?${getPaginationQueries(
                currentPage,
                pageSize,
            )}&${getOrderQueries(order)}${encodeURI(filterString)}`,
        );

        const uploadedRecordings = data.filter(
            (rec) =>
                rec.conversation.transcription_status !== 'pending' &&
                rec.conversation.transcription_status,
        );

        yield put(
            fetchRecordingsSimplifiedSuccess(
                uploadedRecordings ? attachInsights(uploadedRecordings) : [],
                count,
            ),
        );
    } catch (error) {
        yield put(fetchRecordingsSimplifiedFailure(error));
    }
}

export function* fetchSearchRecordings(action) {
    try {
        const { currentPage, pageSize, order, filter } = action.payload;
        const filterString = filter ? `&${filter}` : '';
        const { data } = yield call(
            axios,
            `${NEXT_ROUTES.CONVERSATIONS_SEARCH(
                encodeURIComponent(action.payload.searchValue),
            )}&${getPaginationQueries(
                currentPage,
                pageSize,
            )}&${getOrderQueries(order)}${encodeURI(filterString)}`,
        );

        const mapSpeakers = (v) => {
            const { attendees, ...rest } = v.conversation;

            return {
                ...v,
                conversation: { ...rest },
                attendees,
            };
        };

        const newData = data;
        newData.recordings = newData.recordings.map(mapSpeakers)
        
        const mappedSearchResultsToRecordings = mapSearchResultsToRecordings(
            data,
        );
        yield put(
            fetchSearchRecordingsSuccess(data, mappedSearchResultsToRecordings),
        );
    } catch (error) {
        yield put(fetchSearchRecordingsFailure(error));
    }
}

export function* deleteRecording(action) {
    let conversationId = '';
    let recordingId = '';

    if (action.payload.recording.length > 1) {
        const conversations = action.payload.recording.map((rec) => rec.conversation.id);
        const recordings = action.payload.recording.map((rec) => rec.id);
        conversationId = conversations.join(',');
        recordingId = recordings.join(',');
    } else {
        conversationId = action.payload.recording.conversation.id;
        recordingId = action.payload.recording.id;
    }

    try {
        yield call(
            axios.delete,
            NEXT_ROUTES.CONVERSATIONS_ID(
                conversationId
            ),
        );

        yield put(removeRecordingSuccess(recordingId));
        yield put(
            updatePaginationTotal(action.payload.recording.length > 1 ? -(action.payload.recording.length) : -1),
        );
    } catch (error) {
        yield put(removeRecordingFailure(error));
    }
}

export function* updateConversationTitle(action) {
    if (action.payload.title.length === 0) {
        yield put(updateConversationTitleFailure('Invalid title length'));
        message.error('Invalid conversation title provided');
        return;
    }

    const recordings = yield select(getRecordings);
    const modifiedRecording = recordings.find(
        (row) => row.id === action.payload.recordingId,
    );
    const conversationId = modifiedRecording.conversation.id;

    try {
        yield call(axios.patch, NEXT_ROUTES.CONVERSATIONS_ID(conversationId), {
            title: action.payload.title,
        });

        yield put(
            updateConversationTitleSuccess(
                action.payload.recordingId,
                action.payload.title,
            ),
        );
    } catch (error) {
        message.error('Conversation title modification failed.');
        yield put(updateConversationTitleFailure(error));
    }
}

export function* updateDate(action) {
    try {
        yield call(
            axios.patch,
            NEXT_ROUTES.RECORDINGS_ID(action.payload.recordingId),
            { date: action.payload.date },
        );
        yield put(
            updateDateSuccess(action.payload.recordingId, action.payload.date),
        );
    } catch (error) {
        yield put(updateDateFailure(error));
        message.error('Date modification failed.');
    }
}

export function* updateTags(action) {
    try {
        const {
            data: { tags },
        } = yield call(
            axios.patch,
            NEXT_ROUTES.RECORDINGS_ID(action.payload.recordingId),
            {
                tags: action.payload.tags,
            },
        );
        yield put(updateTagsSuccess(action.payload.recordingId, tags));
    } catch (error) {
        yield put(updateTagsFailure(error));
        message.error('Tags modification failed.');
    }
}

export default function* recordingSaga() {
    yield takeLatest([RECORDINGS.FETCH_START], fetchRecordings);
    yield takeLatest([RECORDINGS.SIMPLIFIED.START], fetchRecordingsSimplified);
    yield takeLatest(RECORDINGS.SEARCH_FETCH.START, fetchSearchRecordings);
    yield takeEvery(RECORDINGS.REMOVE.START, deleteRecording);
    yield takeEvery(
        RECORDINGS.CONVERSATION_TITLE_UPDATE.START,
        updateConversationTitle,
    );
    yield takeEvery(RECORDINGS.DATE_UPDATE.START, updateDate);
    yield takeEvery(RECORDINGS.TAGS_UPDATE.START, updateTags);
}
