/* eslint-disable indent */
import { getTranscriptWithUserColors } from '@/utils/recordings/getTranscript';
import { AttendeeTypes } from '@/constants/conversations/attendeeTypes';

export const getUpdatedTranscript = (
    transcript,
    toReplaceSpeakerId,
    attendeeType,
    name,
    userId,
) => {
    const { content, speakers } = transcript;

    const speakersWithExtendedData = speakers.map((speaker) =>
        speaker.id !== toReplaceSpeakerId
            ? speaker
            : {
                  ...speaker,
                  type:
                      attendeeType === AttendeeTypes.attendee
                          ? null
                          : attendeeType,
                  name,
                  externalId: userId,
              },
    );

    const duplicatedIdForExternalId = speakersWithExtendedData.find(
        (speaker) =>
            speaker.externalId === userId && speaker.id !== toReplaceSpeakerId,
    )?.id;

    const newSpeakers = speakersWithExtendedData.filter(
        ({ id }) => id !== duplicatedIdForExternalId,
    );

    const newContent = duplicatedIdForExternalId
        ? content.map((item) =>
              item.speakerId !== duplicatedIdForExternalId
                  ? item
                  : { ...item, speakerId: toReplaceSpeakerId },
          )
        : content;

    const newTranscript = {
        ...transcript,
        speakers: newSpeakers,
        content: newContent,
    };

    return getTranscriptWithUserColors(newTranscript);
};

export const getUpdatedTranscriptForPatch = (transcript) => ({
    speakers: transcript.speakers.map((v) => ({
        ...v,
        external_id: v.type !== AttendeeTypes.guest ? v.externalId : null,
    })),
    content: transcript.content.map((v) => ({
        ...v,
        speaker_id: v.speakerId,
    })),
});

export const getUpdatedTranscriptSingle = (
    transcript,
    speakerId, 
    userId, 
    contentId,
) => {
    const { content } = transcript;

    const newContent = content.map((item) => {
        if (item.id === contentId) {
            return {
                ...item,
                speaker_id: userId,
                speakerId: userId,
            };
        }
        return {
            ...item
        };
    });

    const newTranscript = {
        ...transcript,
        content: newContent,
    };

    return getTranscriptWithUserColors(newTranscript);
};
