import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { DATE_KEY } from '@/constants/reduxKeys';
import dateReducer from '@/redux/reducers/dateReducers';
import datesSaga from '@/redux/sagas/dateSagas';
import { fetchDateZoneStart } from '@/redux/actions/dateActions';

export default function DateInjector() {
    useInjectReducer({ key: DATE_KEY, reducer: dateReducer });
    useInjectSaga({ key: DATE_KEY, saga: datesSaga });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchDateZoneStart());
    }, [dispatch]);

    return null;
}
