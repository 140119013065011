import styled, { keyframes, css } from 'styled-components';
import { Space, Divider, Input, Typography, Alert, Button } from 'antd';
import {
    faUpload,
    faLink,
    faChevronDown,
    faStopwatch,
    faStop,
    faRetweet,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GRAY_0, GRAY_3, GRAY_4, RED, VIVIDORANGE, VERSATIONAL } from '@/constants/colors';

const blinker = keyframes`
0% {
    opacity: 1;
}
50% {
    opacity: 0.4;
}
100% {
    opacity: 1;
}
`;

export const ReloadButton = styled(Button)`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    border: 1px solid #fff;
`;

const AlertMessage = styled(Alert)`
    margin-top: 1rem;
    margin-bottom: 0.5rem;
`;

const Container = styled(Space).attrs(() => ({
    size: 32,
    split: <Divider type="vertical" />,
    style: { height: '100%' },
}))``;

const SpaceDivider = styled(Divider).attrs(() => ({
    type: 'vertical',
}))`
    margin: 0 !important;
    color: ${GRAY_0};
    width: 1px;
    height: 34px !important;
    top: 0;
`;

const ButtonsContainer = styled.div.attrs(() => ({
    align: 'center',
}))`
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    height: 2.25rem;
    align-self: center;
    border: 1px solid ${({ result }) => (result ? VIVIDORANGE : GRAY_0)};
    border-radius: 1.5rem;
    gap: 0 !important;
    margin-bottom: 0.5rem;
    & > * {
        padding: 0.3rem 0.75rem;
        cursor: pointer;
    }

    ${({ analyze }) =>
        analyze &&
        `
        border: unset;

        & > :first-child {
        border: 1px solid ${VIVIDORANGE};
        border-top-left-radius: 1.5rem;
        border-bottom-left-radius: 1.5rem;
    }
    
    & > :nth-child(2) {
        border: 1px solid ${GRAY_0};
        border-top-right-radius: 1.5rem;
        border-bottom-right-radius: 1.5rem;
    }`}
`;

const RecordIconContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-self: stretch;
    align-items: center;
`;

const UploadIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faUpload,
}))`
    font-size: 1rem !important;
    color: ${GRAY_3};
`;

const LinkIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faLink,
}))`
    font-size: 1rem !important;
    color: ${({ active }) => (active ? VIVIDORANGE : GRAY_3)};
`;

const RecordIcon = styled.div`
    background: ${({ recording, time }) => {
        if (recording && time) return RED;
        if (time) return GRAY_4;
        return VIVIDORANGE;
    }};
    ${({ recording, time }) =>
        recording &&
        time &&
        css`
            animation: ${blinker} 1.2s linear infinite;
        `}
    width: 1rem;
    height: 1rem;
    border-radius: 50%;

    ${({ small }) => small && `width: 0.875rem; height: 0.875rem`}
`;

const ChevronDownIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faChevronDown,
}))`
    color: ${GRAY_3};
    margin-left: 0.5rem;
    vertical-align: -0.15rem;
    font-size: 0.75rem !important;

    :hover {
        color: ${VIVIDORANGE};
    }
`;

const timerIconsStyle = `    
    color: ${VIVIDORANGE};
    margin-right: 0.5rem;
    vertical-align: -0.15rem;
    font-size: 0.875rem !important;
`;

const TimerIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faStopwatch,
}))`
    ${timerIconsStyle}
    ${({ large }) => large && `font-size: 1rem !important; margin-right: 0`}
`;

const InputWrapper = styled(Input).attrs(() => ({
    placeholder: 'Enter Meeting Link to Join Later',
    bordered: false,
}))`
    border: none;
    width: 300px;
`;

const TimeWrapper = styled(Typography.Text)`
    font-size: 0.75rem;
    color: ${GRAY_3};
    font-weight: 500;
    margin-left: 0.5rem;
`;

const StopTimerIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faStop,
}))`
    ${timerIconsStyle}
`;

const ResetTimerIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faRetweet,
}))`
    ${timerIconsStyle}
`;

const ResultsText = styled(Typography.Text)`
    font-size: 1rem;
    padding: 0 0.5rem;
`;

const AnalyzeButton = styled(Typography.Text)`
    font-size: 1rem;
    padding: 0 1rem;
`;

const LinkWrapper = styled.a`
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ProgressBarContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const BottomBar = styled.div`
  background-color: ${VERSATIONAL};
  position: fixed;
  bottom: 0;
  left: 80px;
  right: 0;
  display: flex;  justify-content: space-between;
  align-items: center;
  padding: 10px;
  font-size: 18px;
  z-index: 999;
  color: #fff;
  text-align: center;
  box-shadow: 0px -3px 10px 0px rgba(0,0,0,0.15);
`;

const Message = styled.p`
  margin: 0 auto;
`;

export {
    Container,
    ButtonsContainer,
    UploadIcon,
    LinkIcon,
    RecordIcon,
    ChevronDownIcon,
    TimerIcon,
    InputWrapper,
    TimeWrapper,
    StopTimerIcon,
    ResetTimerIcon,
    ResultsText,
    AnalyzeButton,
    LinkWrapper,
    ProgressBarContainer,
    SpaceDivider,
    RecordIconContainer,
    BottomBar,
    Message,
    AlertMessage,
};
