import { put, takeEvery, call, select } from 'redux-saga/effects';
import { message, notification } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    bulkDisableGemSettingsFailure,
    bulkDisableGemSettingsSuccess,
    bulkEnableGemSettingsFailure,
    bulkEnableGemSettingsSuccess,
    fetchGemSettingsFailure,
    fetchGemSettingsSuccess,
    GEM_SETTINGS,
    updateGemSettingFailure,
    updateGemSettingSuccess,
} from '@/redux/actions/settings/gemSettingsAction';
import {
    gemSettingsTrackedSelector,
    isGemTrackedSelector,
} from '@/redux/selectors/settings/topicTrackerSelector';
import { fetchCustomGemsInsights } from '@/redux/actions/gemsInsightsAction';
import { resetConversationResult } from '@/redux/actions/conversation/conversationResult';
import { getGemSettingsSelector } from '@/redux/selectors/settings/gemSettingsSelector';
import { errorToString } from '@/utils/error/errorToString';

export function* fetchGemSettingsSaga() {
    try {
        const { data = [] } = yield call(axios, NEXT_ROUTES.GEM_SETTINGS);
        yield put(fetchGemSettingsSuccess(data));
    } catch (err) {
        yield put(fetchGemSettingsFailure(err));
    }
}

export function* updateGemSettingSaga(action) {
    try {
        const {
            data: {
                id,
                gem: { id: gemId, name },
                enabled,
            },
        } = action.payload;
        const isGemTracked = yield select((state) =>
            isGemTrackedSelector(state, gemId),
        );

        if (isGemTracked) {
            throw new Error(
                `Gem ${name} currently has an active topic tracker. Please disable the tracker first to hide insights.`,
            );
        }
        try {
            const { data: updatedGem } = yield call(
                axios.patch,
                NEXT_ROUTES.GEM_SETTINGS_ID(id),
                { enabled },
            );

            yield put(updateGemSettingSuccess(updatedGem));
            yield put(fetchCustomGemsInsights());
            yield put(resetConversationResult());
        } catch (e) {
            message.error('Unable to update gem setting');
            yield put(updateGemSettingFailure(e.toString()));
        }
    } catch (e) {
        notification.error({
            message: 'Error',
            description: e.message,
            placement: 'bottomRight',
        });
        yield put(updateGemSettingFailure(e.message));
    }
}

function* bulkPatchGemSetting(gemSettingsIds, enabled) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.GEM_SETTINGS,
        gemSettingsIds.map((id) => ({ id, enabled })),
    );
}

export function* bulkEnableGemSettingsSaga() {
    try {
        const gemSettings = yield select(getGemSettingsSelector);
        yield call(
            bulkPatchGemSetting,
            gemSettings.map((q) => q.id),
            true,
        );

        yield put(bulkEnableGemSettingsSuccess());
        yield put(fetchCustomGemsInsights());

        yield put(resetConversationResult());

        message.success({
            content: 'Successfully enabled all gems.',
        });
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during enabling gems. Please try again.',
            placement: 'bottomRight',
        });
        yield put(bulkEnableGemSettingsFailure(errorToString(e)));
    }
}
export function* bulkDisableGemSettingsSaga() {
    try {
        const gemSettingsTracked = yield select(gemSettingsTrackedSelector);
        if (gemSettingsTracked.length) {
            throw new Error(
                `Gem${
                    gemSettingsTracked.length > 1 ? 's' : ''
                } (${gemSettingsTracked
                    .map((g) => g.gem.name)
                    .join(', ')}) currently ${
                    gemSettingsTracked.length > 1 ? 'have' : 'has'
                } an active topic tracker. Please disable the trackers first to hide insights.`,
            );
        }
        try {
            const gemSettings = yield select(getGemSettingsSelector);
            yield call(
                bulkPatchGemSetting,
                gemSettings.map((q) => q.id),
                false,
            );

            yield put(bulkDisableGemSettingsSuccess());
            yield put(fetchCustomGemsInsights());

            yield put(resetConversationResult());

            message.success({
                content: 'Successfully disabled all gems.',
            });
        } catch (e) {
            notification.error({
                message: 'Error',
                description:
                    'There was error during disabling gems. Please try again.',
                placement: 'bottomRight',
            });
            yield put(bulkDisableGemSettingsFailure(errorToString(e)));
        }
    } catch (e) {
        notification.error({
            message: 'Error',
            description: e.message,
            placement: 'bottomRight',
        });
        yield put(bulkDisableGemSettingsFailure(e.message));
    }
}

export default function* gemSettingsSaga() {
    yield takeEvery(GEM_SETTINGS.FETCH_START, fetchGemSettingsSaga);
    yield takeEvery(GEM_SETTINGS.UPDATE_START, updateGemSettingSaga);
    yield takeEvery(GEM_SETTINGS.BULK_ENABLE.START, bulkEnableGemSettingsSaga);
    yield takeEvery(
        GEM_SETTINGS.BULK_DISABLE.START,
        bulkDisableGemSettingsSaga,
    );
}
