export const AUTH_KEY = 'user';
export const ASSEMBLY_TRANSCRIPT = 'assemblyTranscript';
export const SIGNUP_PROCESS_KEY = 'signup';
export const CHANGE_SIGN_IN_PROCESS_KEY = 'changeSignIn';
export const LOGIN_PROCESS_KEY = 'login';
export const CALENDAR_PROVIDER_KEY = 'calendarProvider';
export const COMPANY_KEY = 'company';
export const CUSTOM_TAGS = 'customTags';
export const DATE_KEY = 'date';
export const DIGEST_KEY = 'digest';
export const GEMS_INSIGHTS_KEY = 'gems';
export const INSIGHTS_KEY = 'insights';
export const PLAYLISTS_KEY = 'playlists';
export const INSIGHTS_VIEW_KEY = 'insightsView';
export const INTEGRATION_APP_KEY = 'integrationApp';
export const EVENTS_KEY = 'events';
export const PREPARATION_QUESTIONS_KEY = 'questions';
export const PREPARATION_GUIDES_KEY = 'guideEdit';
export const INVITES_KEY = 'invites';
export const RECORDS_LEADS_KEY = 'leads';
export const RECORDS_ACCOUNTS_KEY = 'companies';
export const RECORDS_CONTACTS_KEY = 'contacts';
export const RECORDINGS_KEY = 'recordings';
export const RECORDINGS_RECOVERY_KEY = 'recordingsRecovery';
export const RECORDS_USERS_KEY = 'users';
export const SETTINGS_KEY = 'settings';
export const SUBSCRIPTION_KEY = 'subscription';
export const SUBSCRIPTION_PLANS_KEY = 'subscriptionPlans';
export const TEAM_MEMBERS_KEY = 'teamMembers';
export const ZOHO_ADDONS_KEY = 'zohoAddons';
export const USERS_KEY = 'users';
export const GEM_SETTINGS = 'gemSettings';
export const ATTENDEES_KEY = 'attendees';
export const MEETING_ID_KEY = 'meetingId';
export const CONVERSATION_RESULT_KEY = 'conversationResult';
export const CONVERSATION_QUESTIONS_KEY = 'conversationQuestions';
export const CONVERSATION_KEY = 'conversation';
export const TRANSCRIPT_KEY = 'transcript';
export const TRANSCRIPT_CONTENT_VIEW_KEY = 'transcriptContentView';
export const CONVERSATION_RESULT_VIEW_KEY = 'conversationResultView';
export const COMMENTS_VIEW_KEY = 'commentsView';
export const TRANSCRIPT_SEARCH_KEY = 'transcriptSearch';
