/* eslint-disable indent */
import { TimerState } from '@/components/timer/useTimer';
import LinkDropdown from '@/components/conversations/conversations/headerActionsButton/linkDropdown/linkDropdown';
import {
    AnalyzeButton,
    ButtonsContainer,
    RecordIcon,
    RecordIconContainer,
    SpaceDivider,
    UploadIcon,
} from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import { Tooltip } from 'antd';
import { MEDIA_STATUS } from '@/constants/media/mediaRecording';
import Time from '@/components/conversations/conversations/headerActionsButton/time/time';
import React, { useEffect } from 'react';
import {
    useTimeContext,
    useTimerContext,
} from '@/components/timer/timerContext';
import { useSelector } from 'react-redux';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import { useConversationContext } from '@/components/conversations/conversations/context/context';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';
import { bool, func, node } from 'prop-types';
import { useRouter } from 'next/router'

function DefaultActions({
    setUploadModalVisible,
    clearCurrentConvAndUserTime,
    recordingDropdown,
    isAutoRecoveryPending,
    recoveryDataFound,
    launchExternalRecoveryModal,
}) {
    const router = useRouter()
    const { timerState, toggle: toggleTimer } = useTimerContext();
    const seconds = useTimeContext();

    const userId = useSelector(getUserIdSelector);
    const { conversationId } = useConversationContext();

    const {
        status,
        startRecording,
        stopRecording,
        resumeRecording,
        pauseRecording,
    } = useMediaRecorderContext();

    useEffect(() => {
        if (router.query.startRecording && (status === MEDIA_STATUS.IDLE || status === MEDIA_STATUS.STOPPED)) {
            startRecording();
            toggleTimer();
        }
    }, [router.query.startRecording]);

    return (
        <ButtonsContainer
            analyze={timerState === TimerState.paused}
            split={timerState !== TimerState.paused && <SpaceDivider />}
        >
            {timerState === TimerState.idle && (
                <UploadIcon
                    onClick={() => {
                        setUploadModalVisible(true);
                    }}
                />
            )}
            {timerState === TimerState.idle && <LinkDropdown />}
            {timerState === TimerState.paused && (
                <Tooltip
                    title={
                        seconds < 120
                            ? 'Recording must be at least 2 minutes long.'
                            : ''
                    }
                >
                    <div
                        role="none"
                        onClick={() => {
                            if (seconds >= 120) {
                                stopRecording();
                            }
                        }}
                        data-testid="analyze-button"
                    >
                        <AnalyzeButton disabled={seconds < 120}>
                            Analyze
                        </AnalyzeButton>
                    </div>
                </Tooltip>
            )}
            <RecordIconContainer
                onClick={() => {
                    clearCurrentConvAndUserTime(userId, conversationId);
                    if (isAutoRecoveryPending || recoveryDataFound) {
                        launchExternalRecoveryModal(() => {
                            startRecording();
                            toggleTimer();
                        });
                        return;
                    }

                    if (
                        status === MEDIA_STATUS.IDLE ||
                        status === MEDIA_STATUS.STOPPED
                    ) {
                        startRecording();
                        toggleTimer();
                    } else if (status === MEDIA_STATUS.PAUSED) {
                        resumeRecording();
                        toggleTimer();
                    } else {
                        pauseRecording();
                        toggleTimer();
                    }
                }}
            >
                <RecordIcon
                    recording={timerState === TimerState.running}
                    time={!!seconds}
                    data-testid="start-record-icon"
                />
                {(!!seconds || timerState === TimerState.running) && <Time />}
                {recordingDropdown}
            </RecordIconContainer>
        </ButtonsContainer>
    );
}

DefaultActions.propTypes = {
    setUploadModalVisible: func.isRequired,
    isAutoRecoveryPending: bool.isRequired,
    recoveryDataFound: bool.isRequired,
    clearCurrentConvAndUserTime: func.isRequired,
    recordingDropdown: node.isRequired,
    launchExternalRecoveryModal: func.isRequired,
};

export default DefaultActions;
