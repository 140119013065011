import { COMMENTS_SORT_VARIANTS } from '@/components/conversations/results/analytics/summaries/comments/constants/commentsFilter';

export const getTranscriptContentWithNewComment = (
    content,
    contentIndex,
    quoteIndex,
    data,
) => {
    const newContentQuote = [...content];
    const newContentItem = {
        ...newContentQuote[contentIndex],
    };
    const newQuotes = [...newContentItem.quotes];
    const newQuote = {
        ...newQuotes[quoteIndex],
    };
    const newComments = newQuote.comments ? [...newQuote.comments] : [];

    newComments.push(data);
    newQuotes[quoteIndex].comments = newComments;
    newContentItem.quotes = newQuotes;
    return newContentQuote;
};

const filterArrayByTopics = (quotes, selectedGemTopic, selectedTopic) => {
    let quotesToFilter = quotes;

    if (selectedGemTopic) {
        quotesToFilter = quotesToFilter.filter((item) =>
            item.gems.map((i) => i.name).includes(selectedGemTopic),
        );
    }

    if (selectedTopic) {
        quotesToFilter = quotesToFilter.filter((item) =>
            item.customTags.map((i) => i.name).includes(selectedTopic),
        );
    }

    return quotesToFilter;
};

export const getFilteredQuotes = (
    groupedComments,
    transcriptSpeakers,
    commentsListQuery,
    selectedSpeakers,
    selectedGemTopic,
    selectedTopic,
) => {
    if (!groupedComments?.comments?.length) return;

    let filteredComments = groupedComments.comments.map((comment) => {
        const speakerInfo = transcriptSpeakers.find(
            (item) => item.id === comment.authorId,
        );
        return {
            ...comment,
            authorId: speakerInfo?.id,
            authorType: speakerInfo?.type,
            authorName: speakerInfo?.name ?? null,
        };
    });

    if (commentsListQuery.sortBy) {
        switch (commentsListQuery.sortBy) {
            case COMMENTS_SORT_VARIANTS.time:
                break;
            case COMMENTS_SORT_VARIANTS.commentsAmount:
                filteredComments = filteredComments.sort(
                    (a, b) =>
                        parseInt(b.comments?.length ?? 0, 10) -
                        parseInt(a.comments?.length ?? 0, 10),
                );
                break;
            case COMMENTS_SORT_VARIANTS.topics:
                filteredComments = filteredComments.sort(
                    (a, b) =>
                        (b.customTags?.length ?? 0) +
                        (b.gems?.length ?? 0) -
                        ((a.customTags?.length ?? 0) + (a.gems?.length ?? 0)),
                );
                break;
            case COMMENTS_SORT_VARIANTS.speakers:
                filteredComments = filteredComments.sort((a, b) =>
                    a.authorName > b.authorName && a.timeInMs > b.timeInMs
                        ? 1
                        : -1,
                );
                break;
            default:
                break;
        }
    }

    filteredComments = filteredComments.filter(({ authorId }) =>
        selectedSpeakers.some(({ id }) => id === authorId),
    );

    filteredComments = filterArrayByTopics(
        filteredComments,
        selectedGemTopic,
        selectedTopic,
    );

    return {
        ...groupedComments,
        comments: filteredComments,
    };
};
