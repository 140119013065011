import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import transcriptSaga from '@/redux/sagas/transcript/transcriptSaga';
import { TRANSCRIPT_KEY } from '@/constants/reduxKeys';
import transcriptReducer from '@/redux/reducers/conversation/transcriptReducer/transcriptReducer';

export default function useTranscriptInjectors() {
    useInjectReducer({
        key: TRANSCRIPT_KEY,
        reducer: transcriptReducer,
    });

    useInjectSaga({
        key: TRANSCRIPT_KEY,
        saga: transcriptSaga,
    });
}
