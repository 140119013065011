import { call, put, take } from 'redux-saga/effects';
import {
    fetchGuestTranscript,
    fetchTranscript,
    TRANSCRIPT,
    updateMediaUrl,
    updateMediaUrlFailure,
    updateMediaUrlSuccess,
} from '@/redux/actions/conversation/transcript';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';

function* getMediaUrl(src) {
    const {
        data: { url },
    } = yield call(axios, NEXT_ROUTES.AWS(src));
    return url;
}

export function* getTranscript(id) {
    yield put(fetchTranscript(id));
    const conversationFetchAction = yield take([
        TRANSCRIPT.FETCH_SUCCESS,
        TRANSCRIPT.FETCH_FAILURE,
    ]);
    if (conversationFetchAction.type === TRANSCRIPT.FETCH_FAILURE)
        throw new Error('Conversation result failed on transcription fetch');

    try {
        yield put(updateMediaUrl());
        const src = conversationFetchAction.payload.data.mediaUrl;
        const url = yield getMediaUrl(src);
        yield put(updateMediaUrlSuccess(url));
    } catch (e) {
        yield put(updateMediaUrlFailure(e.toString()));
    }

    return conversationFetchAction.payload.data;
}
export function* getGuestTranscript(id) {
    yield put(fetchGuestTranscript(id));
    const conversationFetchAction = yield take([
        TRANSCRIPT.GUEST_FETCH_SUCCESS,
        TRANSCRIPT.FETCH_FAILURE,
    ]);
    if (conversationFetchAction.type === TRANSCRIPT.FETCH_FAILURE)
        throw new Error('Conversation result failed on transcription fetch');

    try {
        yield put(updateMediaUrl());
        const src = conversationFetchAction.payload.data.mediaUrl;
        const url = yield getMediaUrl(src);
        yield put(updateMediaUrlSuccess(url));
    } catch (e) {
        yield put(updateMediaUrlFailure(e.toString()));
    }

    return conversationFetchAction.payload.data;
}
