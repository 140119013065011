import { formatDuration } from '@/utils/dates/getDuration';

/* eslint-disable indent */
export function getTalkToListenRatio(transcriptContent, allSpeakers, speakers) {
    if (!transcriptContent) return 0;

    const contentWithoutExcludes = transcriptContent.filter((item) =>
        allSpeakers.some(({ id }) => id === item.speakerId),
    );

    if (contentWithoutExcludes.length === 0) return 0;

    const value = contentWithoutExcludes
        .reduce(
            (acc, current) => {
                const currTime =
                    (current.endTimeMs - current.startTimeMs) / 1000;

                return speakers.some(({ id }) => id === current.speakerId)
                    ? [acc[0] + currTime, acc[1]]
                    : [acc[0], acc[1] + currTime];
            },
            [0, 0],
        )
        .reduce((acc, current) => (acc / (acc + current)) * 100);
    return Math.round(value / 0.5) * 0.5;
}

export const getTopSpeaker = (speakers) =>
    speakers.reduce((topSpeaker, speaker) => {
        if (
            !topSpeaker ||
            (topSpeaker && speaker.talkRatio > topSpeaker.talkRatio)
        ) {
            return speaker;
        }

        return topSpeaker;
    }, null);

export function getTalkTime(transcriptContent, allSpeakers, speakers) {
    if (!transcriptContent) return 0;

    const contentWithoutExcludes = transcriptContent.filter((item) =>
        allSpeakers.some(({ id }) => id === item.speakerId),
    );

    if (contentWithoutExcludes.length === 0) return 0;

    const value = contentWithoutExcludes.reduce((acc, current) => {
        const currTime = current.endTimeMs - current.startTimeMs;

        if (speakers.some(({ id }) => id === current.speakerId))
            return acc + currTime;
        return acc;
    }, 0);
    return formatDuration(value);
}

export const getDataFromSpeakers = (speakers, transcriptContent) =>
    !speakers
        ? []
        : speakers
              .map(({ name, color, id, type, externalId }) => {
                  const ratio = getTalkToListenRatio(
                      transcriptContent,
                      speakers,
                      [speakers.find((speaker) => speaker.id === id)],
                  );

                  return {
                      name,
                      type,
                      value: ratio,
                      color,
                      id,
                      externalId,
                  };
              })
              .sort((a, b) => b.value - a.value)
              .map((v, index) => ({ ...v, index: index + 1 }));
