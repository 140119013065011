/* eslint-disable indent */
const storePersistMiddleware =
    ({ getState }) =>
    (next) =>
    (action) => {
        const result = next(action);
        if (action.persisted) {
            localStorage.setItem(
                action.subReducer,
                JSON.stringify(
                    action.subReducer
                        ? getState()[action.reducer][action.subReducer]
                        : getState()[action.reducer],
                ),
            );
        }
        return result;
    };

export default storePersistMiddleware;
