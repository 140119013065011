import { longestMonologueItems } from '@/constants/conversations/longestMonologueItems';

export const createOptionForName = (name, label) => ({
    label: label || name,
    value: name,
});

export const keywordOption = createOptionForName('Keyword:');
export const createKeywordOptionValue = ([fL, ...rest]) =>
    `${keywordOption.value} ${fL.toUpperCase() + rest.join('')}`;

export const longestMonologueOptions = Object.keys(longestMonologueItems).map(
    (key) => createOptionForName(longestMonologueItems[key]),
);

export const getGlobalOptions = () => [
    ...longestMonologueOptions,
    keywordOption,
];
