export const AI_TOPICS_TYPE = {
    SALES: 'sales',
    MEETINGS: 'meetings',
};

export const aiTopicsFilterOptions = [
    { value: 'sales', title: 'Sales Gems' },
    { value: 'meetings', title: 'Meetings Gems' },
    { value: 'management', title: 'Project Gems' },
];

export const enabledGemsSettings = [
    "Buyer Signals",
    "Pain Points",
    "Value",
    "Objections",
    "Identified Needs",
    "Kudos",
    "Issue",
    "Pricing",
    "Goal",
    "Action Item",
    "Decision",
];

export const enabledGemsBeta = [
    "Kudos",
    "Issue",
    "Goal",
    "Action Item",
    "Decision",
];

export const MANAGEMENT_TYPES = ["Identified Needs", "Objections", "Goal", "Issue", "Action Item"];
