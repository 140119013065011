import { call, put, takeEvery, delay, select } from 'redux-saga/effects';
import axios from '@/utils/api/axios';
import { message } from 'antd';

import {
    addContactFailure,
    addAssigneeFailure,
    RECORDS_CONTACT_ADD_START,
    RECORDS_CONTACTS_FETCH_FAILURE,
    RECORDS_CONTACTS_FETCH_START,
    RECORDS_CONTACTS_FETCH_SUCCESS,
    RECORDS_CONTACT_EDIT_START,
    RECORDS_CONTACT_EDIT_FAILURE,
    RECORDS_CONTACT_FETCH_SUCCESS,
    RECORDS_CONTACT_FETCH_FAILURE,
    RECORDS_CONTACT_FETCH_START,
    RECORDS_ASSIGNEE_ADD_START,
    RECORDS_ASSIGNEES_FETCH_START,
    RECORDS_ASSIGNEES_FETCH_SUCCESS,
    RECORDS_ASSIGNEES_FETCH_FAILURE,
} from '@/redux/actions/_exports';
import { 
    fetchContactsStart,
    fetchAssigneesStart,
} from '@/redux/actions/recordsContactsAction';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    getCompanyIdSelector,
} from '@/redux/selectors/companySelector';

export function* getRecordsContacts({ payload }) {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.RECORDS_CONTACTS, {
            params: payload,
        });

        yield put({
            type: RECORDS_CONTACTS_FETCH_SUCCESS,
            payload: { contacts: data },
        });
    } catch (error) {
        yield put({ type: RECORDS_CONTACTS_FETCH_FAILURE });
    }
}

export function* getRecordsAssignees() {
    const companyIdSelector = yield select(getCompanyIdSelector);

    try {
        const { data } = yield call(axios, NEXT_ROUTES.RECORDS_ASSIGNEES, {
            params: {
                organization_id: companyIdSelector,
            },
        });

        yield put({
            type: RECORDS_ASSIGNEES_FETCH_SUCCESS,
            payload: { assignees: data },
        });
    } catch (error) {
        yield put({ type: RECORDS_ASSIGNEES_FETCH_FAILURE });
    }
}

export function* getRecordsContact({ payload }) {
    try {
        const { data } = yield call(
            axios,
            NEXT_ROUTES.RECORDS_CONTACT(payload.id),
        );
        yield put({
            type: RECORDS_CONTACT_FETCH_SUCCESS,
            payload: { contact: data },
        });
    } catch (error) {
        yield put({ type: RECORDS_CONTACT_FETCH_FAILURE });
    }
}

const createContact = async (data) =>
    axios.post(NEXT_ROUTES.RECORDS_CONTACTS, data);

export function* addRecordContact(action) {
    try {
        const { data } = yield call(createContact, action.payload.data);

        data.forEach(({ url, crmName }) => {
            message.success(
                <span>
                    Record successfully created{' '}
                    <a target="_blank" href={url} rel="noopener noreferrer">
                        view in {crmName}
                    </a>
                </span>,
                7,
            );
        });

        yield delay(3000);

        yield put(fetchContactsStart(10, 1));

        localStorage.setItem("contactName", `${action.payload.data.first_name} ${action.payload.data.last_name}`);
    } catch (error) {
        yield put(addContactFailure(
            error?.response?.data || 'An Error has Occurred',
            action.payload.data
        ));
    }
}

const createAssignee = async (data) =>
    axios.post(NEXT_ROUTES.RECORDS_ASSIGNEES, data);

export function* addRecordAssignee(action) {
    const companyIdSelector = yield select(getCompanyIdSelector);
    const assignee = action.payload.data;
    assignee.organization_id = companyIdSelector;

    try {
        yield call(createAssignee, assignee);

        yield delay(3000);

        yield put(fetchAssigneesStart());
    } catch (error) {
        yield put(addAssigneeFailure(
            error,
            action.payload.data
        ));
    }
}

function* updateContact({ payload }) {
    try {
        yield call(axios.patch, NEXT_ROUTES.RECORDS_CONTACTS, payload);
        message.success('Field successfully updated');
    } catch (error) {
        message.error('Field update failed');
        yield put({ type: RECORDS_CONTACT_EDIT_FAILURE });
    }
}

export default function* recordsSaga() {
    yield takeEvery(RECORDS_CONTACT_ADD_START, addRecordContact);
    yield takeEvery(RECORDS_CONTACTS_FETCH_START, getRecordsContacts);
    yield takeEvery(RECORDS_CONTACT_EDIT_START, updateContact);
    yield takeEvery(RECORDS_CONTACT_FETCH_START, getRecordsContact);
    yield takeEvery(RECORDS_ASSIGNEE_ADD_START, addRecordAssignee);
    yield takeEvery(RECORDS_ASSIGNEES_FETCH_START, getRecordsAssignees);
}
