/* eslint-disable indent */
import { nanoid } from 'nanoid';
import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';
import {
    callSetNullifyTranscriptionStatus,
    callSetTranscriptionStatusToError,
} from '@/components/insights/recordings/header/upload/uploadProps';
import { callOnDev } from '@/utils/environment/callOnDev';

export const getRecordingData = (duration, files, conversationId) => ({
    duration,
    account: '-',
    opportunity: '-',
    stage: '-',
    action_items: 0,
    insights: 0,
    topics_covered: 0,
    buyer_questions: 0,
    comments: 0,
    is_versational: false,
    is_result: false,
    file_type: files[0].type,
    created_at: new Date(),
    updated_at: new Date(),
    conversation_id: conversationId,
    attendees: [],
    tags: [],
});

/* Before any further changes conversationContext refactor is highly advised  */
export const upload =
    (
        title,
        conversationId,
        mediaBlobs,
        onStart,
        onFailure,
        onEnd,
        onFinally,
        onUploadPostStart,
        onUploadPostEnd,
        onProgressChange,
    ) =>
    async (uploadFile) => {
        try {
            onStart();
            const files = [];
            if (!uploadFile) {
                const name = nanoid();

                // fileName is equal to "file" because of need of unification on api
                const fileName = (index) => {
                    if (mediaBlobs.length === 1) return 'file';
                    return `${title ?? name}-${index}`;
                };

                mediaBlobs.forEach((v, index) => {
                    files.push({
                        file: new File([v], `${name}-${index}`, {
                            type: v.type,
                        }),
                        fileName: fileName(index),
                        type: v.type,
                    });
                });
            } else {
                files.push({
                    file: uploadFile,
                    fileName: 'file',
                    type: uploadFile.type,
                });
            }

            const formData = new FormData();
            files.forEach(({ file, fileName }) => {
                formData.append(fileName, file);
            });

            const resultName = uploadFile
                ? encodeURIComponent(uploadFile?.name || title)
                : title;

            // Reset of ts state on repeated upload call (after error)
            await callSetNullifyTranscriptionStatus(conversationId);

            // Even if recorded chunk is equal to 1 send it with files parameter to make proper time conversion
            const url = !uploadFile
                ? `${NEXT_ROUTES.RECORDINGS_UPLOAD}?name=${resultName}&id=${conversationId}&files=true&type=${files[0].type}`
                : `${NEXT_ROUTES.RECORDINGS_UPLOAD}?name=${resultName}&id=${conversationId}&type=${files[0].type}`;

            onUploadPostStart();
            const {
                data: { duration },
            } = await axios.post(url, formData, {
                onUploadProgress: (e) => {
                    onProgressChange(Math.round((e.loaded / e.total) * 100));
                },
            });
            onUploadPostEnd();

            await axios.post(
                NEXT_ROUTES.RECORDINGS,
                getRecordingData(duration, files, conversationId),
            );

            onEnd();
        } catch (e) {
            onFailure();
            if (conversationId) {
                try {
                    await callSetTranscriptionStatusToError(conversationId);
                } catch (err) {
                    callOnDev(() => {
                        console.debug(
                            `Conversation status error update failed: ${err.message}`,
                        );
                    });
                }
            }

            callOnDev(() => {
                console.debug(e);
            });
        } finally {
            onFinally();
        }
    };
