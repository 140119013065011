import { useEffect, useState } from 'react';

export default function useResize() {
    const [height, setHeight] = useState(0);

    useEffect(() => {
        const onResize = (e) => {
            setHeight(e.currentTarget.innerHeight - 160);
        };
        setHeight(window.innerHeight - 160);
        window.addEventListener('resize', onResize);

        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    return height;
}
