import { call, put, takeEvery } from 'redux-saga/effects';
import axios from '@/utils/api/axios';
import { message } from 'antd';

import { NEXT_ROUTES } from '@/constants/routes';
import {
    addAttendeeFailure,
    addAttendeeSuccess,
    ATTENDEES,
    fetchAttendeeFailure,
    fetchAttendeesFailure,
    fetchAttendeesDetailsFailure,
    fetchAttendeesSuccess,
    fetchAttendeesDetailsSuccess,
    fetchAttendeeSuccess,
    removeAttendeeFailure,
    removeAttendeeSuccess,
} from '@/redux/actions/attendeesAction';

export function* getAttendees({ payload }) {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.ATTENDEES, {
            params: payload,
        });

        yield put(fetchAttendeesSuccess(data));
    } catch (error) {
        yield put(fetchAttendeesFailure(error));
    }
}

export function* getAttendeesDetails({ payload }) {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.ATTENDEES_DETAILS, {
            params: payload,
        });

        yield put(fetchAttendeesDetailsSuccess(data));
    } catch (error) {
        yield put(fetchAttendeesDetailsFailure(error));
    }
}

export function* getAttendee({ payload }) {
    try {
        const { data } = yield call(axios, NEXT_ROUTES.ATTENDEE(payload.id));
        yield put(fetchAttendeeSuccess(data));
    } catch (error) {
        yield put(fetchAttendeeFailure());
    }
}

export function* addAttendee(action) {
    try {
        const { data } = yield call(
            axios.post,
            NEXT_ROUTES.ATTENDEES,
            action.payload.data,
        );

        yield put(addAttendeeSuccess(data));

        const onFinish = action.payload?.onFinish;
        if (onFinish) {
            onFinish(data);
        }
        message.success('Attendee created');
    } catch (error) {
        yield put(addAttendeeFailure(error));
    }
}

export function* removeAttendee(action) {
    try {
        yield call(axios.delete, NEXT_ROUTES.ATTENDEE(action.payload.id));
        yield put(removeAttendeeSuccess(action.payload.id));
        message.success('Attendee removed.');
    } catch (error) {
        yield put(removeAttendeeFailure(error));
        message.success('Attendee removal failed.');
    }
}

export default function* recordsSaga() {
    yield takeEvery(ATTENDEES.FETCH_START, getAttendees);
    yield takeEvery(ATTENDEES.DETAILS_FETCH.START, getAttendeesDetails);
    yield takeEvery(ATTENDEES.ATTENDEE_FETCH.START, getAttendee);
    yield takeEvery(ATTENDEES.ATTENDEE_ADD.START, addAttendee);
    yield takeEvery(ATTENDEES.ATTENDEE_REMOVE.START, removeAttendee);
}
