import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { CALENDAR_PROVIDER_KEY } from '@/constants/reduxKeys';
import calendarProviderReducer from '@/redux/reducers/calendarProviderReducer';
import calendarProviderSaga from '@/redux/sagas/calendarProviderSaga';
import { fetchCalenderProvider } from '@/redux/actions/calendarProviderAction';
import { getHasActiveSubscription } from '@/redux/selectors/subscriptionSelector';

export default function CalendarProviderInjector() {
    useInjectReducer({
        key: CALENDAR_PROVIDER_KEY,
        reducer: calendarProviderReducer,
    });
    useInjectSaga({ key: CALENDAR_PROVIDER_KEY, saga: calendarProviderSaga });

    const dispatch = useDispatch();
    const hasActiveSubscription = useSelector(getHasActiveSubscription);

    useEffect(() => {
        if (hasActiveSubscription) dispatch(fetchCalenderProvider());
    }, [dispatch, hasActiveSubscription]);

    return null;
}
