/* eslint-disable react/prop-types,react/destructuring-assignment */
import 'antd/dist/antd.less';
import 'simplebar/dist/simplebar.min.css';
import '../styles/globals.css';

import { config, dom } from '@fortawesome/fontawesome-svg-core';

import ActivityTracker from '@/components/activityTracker/activityTracker';
import { CookiesProvider } from 'react-cookie';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Head from 'next/head';
import { Provider as NextProvider } from 'next-auth/client';
import NoSubscriptionModal from '@/components/noSubscriptionModal/noSubscriptionModal';
import { Provider } from 'react-redux';
import React from 'react';
import SalesIQ from '@/components/meta/salesiq';
import UTHParams from '@/components/meta/uthParams';
import UserGuide from '@/components/meta/userGuide';
import UserGuideUserIdentification from '@/components/meta/userGuideUserIdentification';
import Onboarding from '@/components/onboarding/onboarding';
import { createGlobalStyle } from 'styled-components';
import createStoreForInjectors from '@/redux/store';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import GlobalStateInjector from '@/redux/sagas/sagaInjectors/globalStateInjector';
import MicRecorder from '@/components/micRecorder/micRecorder';
import ChatDummy from '@/components/chatDummy/chatDummy';
import { GoogleAnalytics } from "nextjs-google-analytics";

dayjs.extend(utc);
dayjs.extend(timezone);

config.autoAddCss = false;
const GlobalStyles = createGlobalStyle`
  ${dom.css()}
    // Insert any other global styles you want here
  .grecaptcha-badge {
    visibility: hidden;
  }
`;

export const appStore = createStoreForInjectors({});

function App({ Component, pageProps, err }) {
    const PageLayout = Component.PageLayout || React.Fragment;

    return (
        <GoogleReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
            useRecaptchaNet={false}
            useEnterprise
            scriptProps={{
                async: true, // optional, default to false,
                defer: false, // optional, default to false
                appendTo: 'head', // optional, default to "head", can be "head" or "body",
                nonce: undefined, // optional, default undefined
            }}
        >
            <NextProvider session={pageProps.session}>
                <Provider store={appStore}>
                    <Head>
                        <title>Versational</title>
                        <link
                            rel="icon"
                            href="/logos/Versational-Logo-Icon.svg"
                        />
                    </Head>
                    <GlobalStateInjector pageProps={pageProps} />
                    <SalesIQ />
                    <UTHParams />
                    <UserGuide />
                    <Onboarding />
                    <UserGuideUserIdentification />
                    <GoogleAnalytics trackPageViews />
                    <CookiesProvider>
                        {/* <!-- Google Tag Manager (noscript) --> */}
                        <noscript>
                            <iframe
                                title="googleTagManager"
                                src="https://www.googletagmanager.com/ns.html?id=GTM-NKGHV9N"
                                height="0"
                                width="0"
                                style={{
                                    display: 'none',
                                    visibility: 'hidden',
                                }}
                            />
                        </noscript>
                        {/* <!-- End Google Tag Manager (noscript) --> */}
                        <GlobalStyles />
                        {process.env.NEXT_PUBLIC_SESSION_TRACKING && (
                            <ActivityTracker />
                        )}
                        <PageLayout>
                            <Component {...pageProps} err={err} />
                        </PageLayout>

                        <MicRecorder />

                        <ChatDummy />

                        <NoSubscriptionModal />
                    </CookiesProvider>
                </Provider>
            </NextProvider>
        </GoogleReCaptchaProvider>
    );
}

export default App;
