import {
    createKeywordOptionValue,
    createOptionForName,
    keywordOption,
} from '@/utils/conversations/search/autoCompleteOptions/getAutoCompleteOptions';

export const keyWordAutocompleteValue = keywordOption.value;

export const getKeywordOptions = (
    selectedEntities,
    speakerRangeIdentificator,
) =>
    selectedEntities
        .map(({ name: entityName }) => entityName)
        .map(([fL, ...rest]) => fL.toUpperCase() + rest.join(''))
        .map((entityName) =>
            createOptionForName(
                createKeywordOptionValue(entityName),
                `Keyword (${speakerRangeIdentificator}): ${entityName}`,
            ),
        );
