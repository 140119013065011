/* eslint-disable react/no-unstable-nested-components */
import { InboxOutlined } from '@ant-design/icons';
import { UploadListItem } from '@/components/uploadRecordingsModal/uploadingItem';
import {
    StyledDragger,
    UploadListContainer,
} from '@/components/uploadRecordingsModal/uploadRecordingsModal.styles';

function Dragger(props) {
    return (
        <StyledDragger
            {...props}
            itemRender={(originNode, file, currFileList) => (
                <UploadListContainer>
                    {file.status === 'completed' && (
                        <UploadListItem
                            originNode={originNode}
                            file={file}
                            fileList={currFileList}
                        />
                    )}
                </UploadListContainer>
            )}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Drag files here or <span>browse</span>
            </p>
            <p className="ant-upload-hint">
                Support for single or bulk upload.
            </p>
        </StyledDragger>
    );
}

export default Dragger;
