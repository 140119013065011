import { FETCH_STATUS } from '@/constants/fetchStatus';
import { MEETING } from '@/redux/actions/conversation/meeting';

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
};

// TODO Verify if it is not better to parse data at this level
function meetingIdReducer(state = initialState, action) {
    switch (action.type) {
        case MEETING.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case MEETING.FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload.data.meeting,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case MEETING.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default meetingIdReducer;
