/* eslint-disable react/prop-types */
import CalendarProviderInjector from '@/redux/sagas/sagaInjectors/calendarProviderInjector';
import DateInjector from '@/redux/sagas/sagaInjectors/dateInjector';
import SettingsInjector from '@/redux/sagas/sagaInjectors/settingsInjector';
import AuthInjector from '@/redux/sagas/sagaInjectors/authInjector';
import SignUpProcessInjector from '@/redux/sagas/sagaInjectors/signupProcessInjector';
import LogInProcessInjector from '@/redux/sagas/sagaInjectors/loginProcessInjector';
import ChangeSignInProcessInjector from '@/redux/sagas/sagaInjectors/changeSignInInjector';
import CompanyInjector from '@/redux/sagas/sagaInjectors/companyInjector';
import CustomTagsInjector from '@/redux/sagas/sagaInjectors/customTagsInjector';
import GemsInjector from '@/redux/sagas/sagaInjectors/gemsInjector';
import TeamMembersInjector from '@/redux/sagas/sagaInjectors/teamMembersInjector';
import SubscriptionInjector from '@/redux/sagas/sagaInjectors/subscriptionInjector';
import AssemblyTranscriptInjector from '@/redux/sagas/sagaInjectors/assemblyTranscriptInjector';
import InvitesInjector from '@/redux/sagas/sagaInjectors/invitesInjector';
import RecordingsRecoveryInjector from '@/redux/sagas/sagaInjectors/recordingsRecoveryInjectors';
import PlaylistsInjector from '@/redux/sagas/sagaInjectors/playlistsInjector';
import RecordsContactInjector from '@/redux/sagas/sagaInjectors/recordsContactInjector';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logIn } from '@/redux/actions/authAction';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { AUTH_KEY } from '@/constants/reduxKeys';
import authReducer from '@/redux/reducers/authReducer';
import authSaga from '@/redux/sagas/authSaga';
import RecordingsInjector from '@/redux/sagas/sagaInjectors/recordingsInjector';

// eslint-disable-next-line react/prop-types
function GlobalStateInjector({ pageProps }) {
    const dispatch = useDispatch();

    useInjectReducer({ key: AUTH_KEY, reducer: authReducer });
    useInjectSaga({ key: AUTH_KEY, saga: authSaga });

    const { isAuthorized } = pageProps;

    useEffect(() => {
        if (isAuthorized) {
            dispatch(logIn());
        }
    }, [dispatch, isAuthorized]);

    return (
        <>
            <SignUpProcessInjector isAuthorized={isAuthorized} />
            <LogInProcessInjector isAuthorized={isAuthorized} />
            <ChangeSignInProcessInjector isAuthorized={isAuthorized} />
            <AssemblyTranscriptInjector />
            <RecordingsInjector />
            {isAuthorized && (
                <>
                    <AuthInjector />
                    <RecordingsInjector />
                    <DateInjector />
                    <SettingsInjector />
                    <CustomTagsInjector />
                    <GemsInjector gems={pageProps.data?.gems} />
                    <CalendarProviderInjector />
                    <CompanyInjector company={pageProps.data?.company} />

                    {/* Lazy loading */}
                    <SubscriptionInjector />
                    <PlaylistsInjector />
                    <TeamMembersInjector
                        teamMembers={pageProps.data?.teamMembers}
                    />
                    <InvitesInjector />
                    <RecordingsRecoveryInjector />
                    <RecordsContactInjector />
                </>
            )}
        </>
    );
}

export default GlobalStateInjector;
