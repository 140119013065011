import { AUTH_PROVIDERS } from '@/constants/authProviders';
import {
    LOGIN_PROCESS_RESET_TO_CHOOSE_PROVIDER,
    LOGIN_PROCESS_CHOOSE_PROVIDER,
    LOGIN_PROCESS_CHOOSE_PROVIDER_FAILED,
    LOGIN_PROCESS_THROUGH_EMAIL,
    LOGIN_PROCESS_THROUGH_EMAIL_DOES_EXIST,
    LOGIN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST,
    LOGIN_PROCESS_SIGN_IN,
    LOGIN_PROCESS_SIGN_IN_FAILED,
    LOGIN_PROCESS_SIGN_IN_SUCCESSFUL,
    LOGIN_PROCESS_GET_PROVIDER_INFORMATION,
    LOGIN_PROCESS_FORGOT_PASSWORD,
    LOGIN_PROCESS_FORGOT_SEND_LINK,
    LOGIN_PROCESS_FORGOT_SEND_LINK_FAILED,
    LOGIN_PROCESS_FORGOT_SEND_LINK_SUCCESSFUL,
    LOGIN_PROCESS_VERIFY_RESET_TOKEN,
    LOGIN_PROCESS_VERIFY_RESET_TOKEN_SUCCESSFUL,
    LOGIN_PROCESS_VERIFY_RESET_TOKEN_FAILED,
    LOGIN_PROCESS_RESET_PASSWORD,
    LOGIN_PROCESS_RESET_PASSWORD_FAILED,
    LOGIN_PROCESS_RESET_PASSWORD_SUCCESSFUL,
    LOGIN_PROCESS_GOOGLE_CHOSEN,
    LOGIN_PROCESS_GOOGLE_SUCCESSFUL,
} from '@/redux/actions/loginProcessAction';

export const initialState = {
    type: 'CHOOSE_PROVIDER',
    provider: null,
    error: null,
    loading: false,
};

function LoginProcessReducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case LOGIN_PROCESS_RESET_TO_CHOOSE_PROVIDER:
            return initialState;

        case LOGIN_PROCESS_CHOOSE_PROVIDER:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case LOGIN_PROCESS_CHOOSE_PROVIDER_FAILED:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'CHOOSE_PROVIDER_FAILED',
                },
            };
        case LOGIN_PROCESS_THROUGH_EMAIL:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_PROCESS_THROUGH_EMAIL_DOES_EXIST:
            const {
                payload: { email },
            } = action;
            return {
                ...state,
                loading: false,
                type: 'ENTER_PASSWORD',
                provider: {
                    name: AUTH_PROVIDERS.VERSATIONAL,
                    email,
                },
            };
        case LOGIN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'EMAIL_DOES_NOT_EXIST',
                },
            };
        case LOGIN_PROCESS_SIGN_IN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_PROCESS_SIGN_IN_SUCCESSFUL:
            return initialState;
        case LOGIN_PROCESS_SIGN_IN_FAILED:
            return {
                ...state,
                loading: false,
                error: { type: 'WRONG_PASSWORD' },
            };
        case LOGIN_PROCESS_GET_PROVIDER_INFORMATION:
            const { payload } = action;
            return {
                ...state,
                provider: payload,
                loading: true,
                error: null,
            };

        case LOGIN_PROCESS_GOOGLE_CHOSEN:
            return {
                ...state,
                provider: {
                    name: AUTH_PROVIDERS.GOOGLE,
                },
                loading: true,
                error: null,
            };
        case LOGIN_PROCESS_GOOGLE_SUCCESSFUL:
            const { payload: payloadGoogleSuccessful } = action;
            return {
                ...state,
                provider: {
                    ...state.provider,
                    ...payloadGoogleSuccessful,
                },
                error: null,
            };

        case LOGIN_PROCESS_FORGOT_PASSWORD:
            return {
                ...state,
                type: 'FORGOT_PASSWORD',
                loading: false,
                error: null,
            };
        case LOGIN_PROCESS_FORGOT_SEND_LINK:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_PROCESS_FORGOT_SEND_LINK_FAILED:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'SEND_LINK_FAILED',
                },
            };
        case LOGIN_PROCESS_FORGOT_SEND_LINK_SUCCESSFUL:
            return {
                ...state,
                type: 'CHECK_EMAIL',
                loading: false,
            };
        case LOGIN_PROCESS_VERIFY_RESET_TOKEN:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_PROCESS_VERIFY_RESET_TOKEN_SUCCESSFUL:
            const {
                payload: { email: emailProvider, tokenId },
            } = action;
            return {
                ...state,
                type: 'CUSTOM_LINK_CLICKED',
                provider: {
                    email: emailProvider,
                    tokenId,
                },
                loading: false,
            };
        case LOGIN_PROCESS_VERIFY_RESET_TOKEN_FAILED:
            return {
                ...state,
                error: { type: 'RESET_TOKEN_NOT_VERIFIED' },
                loading: false,
            };
        case LOGIN_PROCESS_RESET_PASSWORD:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case LOGIN_PROCESS_RESET_PASSWORD_FAILED:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'RESET_PASSWORD_FAILED',
                },
            };
        case LOGIN_PROCESS_RESET_PASSWORD_SUCCESSFUL:
            return { ...state, loading: false, type: 'SUCCESSFULLY_RESET' };
        default:
            return state;
    }
}

export default LoginProcessReducer;
