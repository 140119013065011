export const getTranscriptWithPinnedQuote = (prevTranscript, action) => {
    const {
        payload: { contentIndex, quoteIndex, status },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (qI !== quoteIndex) return quote;
                    return {
                        ...quote,
                        pinned: status,
                        confirmed: true,
                    };
                }),
            };
        }),
    };
};

export const getTranscriptWithConfirmStatus = (prevTranscript, action) => {
    const {
        payload: { contentIndex, quoteIndex },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (qI !== quoteIndex) return quote;
                    return {
                        ...quote,
                        confirmed: true,
                    };
                }),
            };
        }),
    };
};

export const confirmQuotes = (contentItem, quoteIds) => ({
    ...contentItem,
    quotes: contentItem.quotes.map((q) => {
        if (quoteIds.some((v) => v === q.id)) {
            return {
                ...q,
                confirmed: true,
            };
        }
        return q;
    }),
});

export const getTranscriptBulkConfirmed = (transcript, action) => {
    const {
        payload: { quotes },
    } = action;

    const contentIdsConfirmedFrom = quotes.map(({ contentId }) => contentId);

    return {
        ...transcript,
        content: transcript.content.map((contentItem) => {
            if (contentIdsConfirmedFrom.includes(contentItem.id)) {
                return confirmQuotes(
                    contentItem,
                    quotes.map(({ id }) => id),
                );
            }
            return contentItem;
        }),
    };
};
