// action types should be defined at the top.
export const LOGIN_PROCESS_RESET_TO_CHOOSE_PROVIDER =
    'LOGIN_PROCESS/RESET_TO_CHOOSE_PROVIDER';
export const LOGIN_PROCESS_CHOOSE_PROVIDER = 'LOGIN_PROCESS/CHOOSE_PROVIDER';
export const LOGIN_PROCESS_CHOOSE_PROVIDER_FAILED =
    'LOGIN_PROCESS/CHOOSE_PROVIDER_FAILED';
export const LOGIN_PROCESS_THROUGH_EMAIL = 'LOGIN_PROCESS/THROUGH_EMAIL';
export const LOGIN_PROCESS_THROUGH_EMAIL_DOES_EXIST =
    'LOGIN_PROCESS/THROUGH_EMAIL_DOES_EXIST';
export const LOGIN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST =
    'LOGIN_PROCESS/THROUGH_EMAIL_DOES_NOT_EXIST';
export const LOGIN_PROCESS_SIGN_IN = 'LOGIN_PROCESS/SIGN_IN';
export const LOGIN_PROCESS_SIGN_IN_FAILED = 'LOGIN_PROCESS/SIGN_IN_FAILED';
export const LOGIN_PROCESS_SIGN_IN_SUCCESSFUL =
    'LOGIN_PROCESS/SIGN_IN_SUCCESSFUL';
export const LOGIN_PROCESS_GET_PROVIDER_INFORMATION =
    'LOGIN_PROCESS/GET_PROVIDER_INFORMATION';
export const LOGIN_PROCESS_FORGOT_PASSWORD = 'LOGIN_PROCESS/FORGOT_PASSWORD';
export const LOGIN_PROCESS_FORGOT_SEND_LINK = 'LOGIN_PROCESS/SEND_LINK';
export const LOGIN_PROCESS_FORGOT_SEND_LINK_FAILED =
    'LOGIN_PROCESS/SEND_LINK_FAILED';
export const LOGIN_PROCESS_FORGOT_SEND_LINK_SUCCESSFUL =
    'LOGIN_PROCESS/SEND_LINK_SUCCESSFUL';
export const LOGIN_PROCESS_VERIFY_RESET_TOKEN =
    'LOGIN_PROCESS/VERIFY_RESET_TOKEN';
export const LOGIN_PROCESS_VERIFY_RESET_TOKEN_SUCCESSFUL =
    'LOGIN_PROCESS/VERIFY_RESET_TOKEN_SUCCESSFUL';
export const LOGIN_PROCESS_VERIFY_RESET_TOKEN_FAILED =
    'LOGIN_PROCESS/VERIFY_RESET_TOKEN_FAILED';
export const LOGIN_PROCESS_RESET_PASSWORD = 'LOGIN_PROCESS/RESET_PASSWORD';
export const LOGIN_PROCESS_RESET_PASSWORD_FAILED =
    'LOGIN_PROCESS/RESET_PASSWORD_FAILED';
export const LOGIN_PROCESS_RESET_PASSWORD_SUCCESSFUL =
    'LOGIN_PROCESS/RESET_PASSWORD_SUCCESSFUL';

export const LOGIN_PROCESS_GOOGLE_CHOSEN = 'LOGIN_PROCESS/GOOGLE_CHOSEN';
export const LOGIN_PROCESS_GOOGLE_SUCCESSFUL =
    'LOGIN_PROCESS/GOOGLE_SUCCESSFUL';

// Actions should be defined below.

export function resetToSignInPage() {
    return {
        type: LOGIN_PROCESS_RESET_TO_CHOOSE_PROVIDER,
    };
}

export function chooseProvider(provider) {
    return {
        type: LOGIN_PROCESS_CHOOSE_PROVIDER,
        payload: {
            provider,
        },
    };
}

export function chooseProviderFailed() {
    return {
        type: LOGIN_PROCESS_CHOOSE_PROVIDER_FAILED,
    };
}

export function throughEmail(email, onError) {
    return {
        type: LOGIN_PROCESS_THROUGH_EMAIL,
        payload: {
            email,
            onError,
        },
    };
}

export function throughEmailDoesExist(email) {
    return {
        type: LOGIN_PROCESS_THROUGH_EMAIL_DOES_EXIST,
        payload: { email },
    };
}

export function throughEmailDoesNotExist() {
    return {
        type: LOGIN_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST,
    };
}

export function googleChosen() {
    return {
        type: LOGIN_PROCESS_GOOGLE_CHOSEN,
    };
}

export function googleSuccessful(googleResponse, origin, mode, callback, displayName, objectId, tenantId, preferredUserName, conversationId) {
    return {
        type: LOGIN_PROCESS_GOOGLE_SUCCESSFUL,
        payload: {
            response: googleResponse,
            origin,
            mode,
            callback,
            displayName,
            objectId,
            tenantId,
            preferredUserName,
            conversationId,
        },
    };
}

export function getProviderInformation(
    provider,
    email,
    accessToken,
    refreshToken,
) {
    return {
        type: LOGIN_PROCESS_GET_PROVIDER_INFORMATION,
        payload: {
            name: provider,
            email,
            accessToken,
            refreshToken,
        },
    };
}

export function signIn(password, reCaptchaToken, onError, origin, mode, callback, displayName, objectId, tenantId, preferredUserName, conversationId) {
    return {
        type: LOGIN_PROCESS_SIGN_IN,
        payload: {
            password,
            reCaptchaToken,
            onError,
            origin,
            mode,
            callback,
            displayName,
            objectId,
            tenantId,
            preferredUserName,
            conversationId,
        },
    };
}

export function signInFailed() {
    return {
        type: LOGIN_PROCESS_SIGN_IN_FAILED,
    };
}

export function signInSuccessful() {
    return {
        type: LOGIN_PROCESS_SIGN_IN_SUCCESSFUL,
    };
}

export function forgotPassword() {
    return {
        type: LOGIN_PROCESS_FORGOT_PASSWORD,
    };
}

export function sendLink(email, reCaptchaToken, onError) {
    return {
        type: LOGIN_PROCESS_FORGOT_SEND_LINK,
        payload: {
            email,
            reCaptchaToken,
            onError,
        },
    };
}
export function sendLinkSuccess() {
    return {
        type: LOGIN_PROCESS_FORGOT_SEND_LINK_SUCCESSFUL,
    };
}
export function sendLinkFailed() {
    return {
        type: LOGIN_PROCESS_FORGOT_SEND_LINK_FAILED,
    };
}

export function moveToResetPasswordStep(tokenId, email) {
    return {
        type: LOGIN_PROCESS_VERIFY_RESET_TOKEN,
        payload: {
            tokenId,
            email,
        },
    };
}

export function verifiedResetToken(tokenId, email) {
    return {
        type: LOGIN_PROCESS_VERIFY_RESET_TOKEN_SUCCESSFUL,
        payload: {
            tokenId,
            email,
        },
    };
}

export function resetTokenNotVerified() {
    return {
        type: LOGIN_PROCESS_VERIFY_RESET_TOKEN_FAILED,
    };
}

export function resetPassword(password) {
    return {
        type: LOGIN_PROCESS_RESET_PASSWORD,
        payload: {
            password,
        },
    };
}

export function resetPasswordSuccessful() {
    return {
        type: LOGIN_PROCESS_RESET_PASSWORD_SUCCESSFUL,
    };
}

export function resetPasswordFailed() {
    return {
        type: LOGIN_PROCESS_RESET_PASSWORD_FAILED,
    };
}
