import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { CUSTOM_TAGS } from '@/constants/reduxKeys';
import customTagsReducer from '@/redux/reducers/customTagsReducer';
import customTagsSaga from '@/redux/sagas/customTagsSaga';
import { fetchCustomTags } from '@/redux/actions/customTagsAction';
import { getCompanyIsActiveSubscription } from '@/redux/selectors/companySelector';

export default function CustomTagsInjector() {
    useInjectReducer({ key: CUSTOM_TAGS, reducer: customTagsReducer });
    useInjectSaga({ key: CUSTOM_TAGS, saga: customTagsSaga });

    const dispatch = useDispatch();

    const isSubscribed = useSelector(getCompanyIsActiveSubscription);

    useEffect(() => {
        if (isSubscribed) dispatch(fetchCustomTags());
    }, [dispatch, isSubscribed]);

    return null;
}
