/* eslint-disable indent */
import { createSelector } from 'reselect';
import createCachedSelector from 're-reselect';
import { TRANSCRIPT_SEARCH_KEY } from '@/constants/reduxKeys';
import {
    getTranscriptContentSelector,
    getTranscriptSelector,
    getTranscriptSpeakersSelector,
    getWordsSelector,
} from '@/redux/selectors/transcript/transcriptSelector';
import { getSelectedEntitiesSelector } from '@/redux/selectors/conversation/analyticsSelector';
import {
    getSelectedSpeakersSelector,
    getSelectedUsers,
} from '@/redux/selectors/conversation/conversationResultViewSelector';
import {
    getKeywordOptions,
    keyWordAutocompleteValue,
} from '@/utils/conversations/search/autoCompleteOptions/createKeywordOptions';
import { getSearchRegExpExtensions } from '@/utils/conversations/search/getSearchRegExpExtensions';
import { getGlobalOptions } from '@/utils/conversations/search/autoCompleteOptions/getAutoCompleteOptions';
import { getContentsWithMatchByWordsStartIndex } from '@/utils/conversation/transcript/search';

const prefixOptionsValues = [keyWordAutocompleteValue];

export const getIsSearchOpened = (state) =>
    state?.[TRANSCRIPT_SEARCH_KEY]?.isOpen;
export const getSearchValue = (state) =>
    state?.[TRANSCRIPT_SEARCH_KEY]?.searchTranscriptValue ?? '';

const getInternalSearchOccurrence = (state) =>
    state?.[TRANSCRIPT_SEARCH_KEY]?.occurrence;

export const getIsAutoCompleteOpen = (state) =>
    state?.[TRANSCRIPT_SEARCH_KEY]?.isAutoCompleteOpen;

export const getSearchRegExp = createSelector(
    [getTranscriptSelector, getSearchValue],
    (transcript, searchValue) => {
        const regExpBuilder = getSearchRegExpExtensions(transcript);

        return regExpBuilder(searchValue);
    },
);

export const getIsPrefixOptionSelected = createSelector(
    [getSearchValue],
    (searchTranscriptValue) =>
        prefixOptionsValues.some((v) => v === searchTranscriptValue),
);

// Determined by usage of global options - if any options from outside analytics scope
// will be added to global options this flag should be changed
export const getIsUserTypeScopeSearch = createSelector(
    [getSearchValue],
    (searchTranscriptValue) =>
        getGlobalOptions().some(({ value }) =>
            searchTranscriptValue.startsWith(value),
        ),
);

export const getAutoCompleteOptions = createSelector(
    [
        getSearchValue,
        getSelectedEntitiesSelector,
        getSelectedUsers,
        getTranscriptSpeakersSelector,
    ],
    (searchTranscriptValue, selectedEntities, usersType) => {
        let options = [];
        if (searchTranscriptValue.startsWith(keyWordAutocompleteValue)) {
            options = getKeywordOptions(
                selectedEntities,
                Array.isArray(usersType) ? 'Custom' : usersType,
            );
        } else if (searchTranscriptValue.length >= 3) {
            const globalOptions = getGlobalOptions();
            const filteredOptions = globalOptions.filter(({ value }) =>
                value
                    .toLowerCase()
                    .startsWith(searchTranscriptValue.toLowerCase()),
            );
            options = [...filteredOptions];
        }
        return options;
    },
);

/** [ [{word}], [{word}], ... [{word}] ]
 * [ [{word}, {word}], [{word}, {word}], ... [{word}, {word}] ] if need to find more than word in one occurrence etc.
 */
export const getFoundItems = createSelector(
    [
        getWordsSelector,
        getTranscriptContentSelector,
        getSelectedSpeakersSelector,
        getSearchRegExp,
        getIsUserTypeScopeSearch,
    ],
    (
        words,
        transcriptContent,
        selectedSpeakers,
        searchRegExp,
        isUserTypeScopeSearch,
    ) => {
        const searchableContent = !isUserTypeScopeSearch
            ? transcriptContent
            : transcriptContent.filter(({ speakerId }) =>
                  selectedSpeakers.some(({ id }) => id === speakerId),
              );

        const contentsWithMatch = getContentsWithMatchByWordsStartIndex(
            searchableContent,
            searchRegExp,
        );

        return contentsWithMatch.map((matchedContent) =>
            matchedContent.words.map(({ startIndex }) =>
                words.find(
                    (transcriptWord) =>
                        startIndex >= transcriptWord.startIndex &&
                        startIndex <= transcriptWord.endIndex &&
                        transcriptWord.contentId === matchedContent.contentId,
                ),
            ),
        );
    },
);

export const getAllSearchTranscriptOccurrences = createSelector(
    [getFoundItems],
    (foundItems) => foundItems?.length ?? 0,
);

export const getSearchOccurrence = createSelector(
    [getAllSearchTranscriptOccurrences, getInternalSearchOccurrence],
    (allOccurrences, occurrence) => {
        if (allOccurrences < occurrence) {
            return 0;
        }
        if (allOccurrences > 0 && occurrence === 0) {
            return 1;
        }
        return occurrence;
    },
);
export const getCurrentSearchOccurrence = createSelector(
    [getFoundItems, getSearchOccurrence],
    (foundItems, occurrence) => foundItems?.[occurrence - 1],
);
export const getCurrentSearchOccurrenceFirstWord = createSelector(
    [getCurrentSearchOccurrence],
    (currentSearchOccurrence) => currentSearchOccurrence?.[0],
);

export const getIsWordInSearch = createCachedSelector(
    getFoundItems,
    (state, wordIndex) => wordIndex,
    (foundItems, wordIndex) =>
        foundItems?.some((item) =>
            item.find((w) => w?.wordIndex === wordIndex),
        ),
)((state, wordIndex) => `${wordIndex}`);

export const getIsWordInCurrentSearchOccurrent = createCachedSelector(
    getCurrentSearchOccurrence,
    (state, wordIndex) => wordIndex,
    (currentSearchOccurrence, wordIndex) =>
        currentSearchOccurrence?.some((w) => w?.wordIndex === wordIndex),
)((state, wordIndex) => `${wordIndex}`);
