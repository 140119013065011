import { useEffect, useState } from 'react';
import { Col, notification, Row } from 'antd';
import { useRouter } from 'next/router';
import { bool } from 'prop-types';
import { CHECK_CIRCLE_TWO_TONE_COLOR } from '@/constants/colors';
import { NEXT_ROUTES } from '@/constants/routes';
import axios from '@/utils/api/axios';
import Logo from '@/public/logos/Versational-Logo-R.svg';
import LogoWrapper from '@/components/logoWrapper';

import {
    ColoredDivA,
    GoogleImage,
    InnerCard,
    LargeArrowRight,
    LargeCheckCircle,
    LayoutContent,
    LogoRow,
    MainLayout,
    MeetTextCenter,
    OuterCard,
    PaddedCol,
    PageWrapper,
} from './google.styles';

function OAuth({ simple }) {
    const router = useRouter();
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);
    const [googleID, setGoogleID] = useState(null);

    const showError = (message = 'Something went wrong', duration = 5) => {
        notification.error({
            message,
            duration,
        });
    };

    const showSuccess = (message = 'Success!', duration = 5) => {
        notification.success({
            message,
            duration,
        });
    };

    const sendRequest = async () => {
        if (hasToken) return;

        const { data: redir } = await axios.get(NEXT_ROUTES.GOOGLE_MEET);
        if (redir) {
            window.location = redir;
        } else {
            showError();
        }
    };

    const removeIntegration = async () => {
        setLoading(true);
        try {
            await axios.delete(
                NEXT_ROUTES.GOOGLE_MEET_INTEGRATIONS_ID(googleID),
            );

            setHasToken(false);
            setGoogleID(null);
        } catch (e) {
            showError();
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    NEXT_ROUTES.GOOGLE_MEET_INTEGRATIONS,
                );

                if (data.length > 0) {
                    setGoogleID(data[0].id);
                    setHasToken(true);

                    if (window.location.search.indexOf('success') !== -1) {
                        showSuccess();
                    }
                } else if (window.location.search.indexOf('error') !== -1) {
                    if (window.location.search.indexOf('scopeAccess') !== -1) {
                        showError(
                            `Versational® requires full access to all scopes listed,
                            please reauthenticate and select all scopes.`,
                            15,
                        );
                    } else if (
                        window.location.search.indexOf('duplicate') !== -1
                    ) {
                        showError(
                            `This google account is already integrated with another Versational® Account.`,
                            5,
                        );
                    } else {
                        notification.error({
                            message: 'Error',
                            description:
                                'Error while authenticating through your portal. Please try again',
                        });
                        await router.push('/settings/integrations');
                    }
                }
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        })();
    }, [router]);

    return (
        <PageWrapper>
            {!loading && (
                <MainLayout>
                    <LayoutContent>
                        <OuterCard>
                            {!simple && <LogoRow>
                                <Col span={12} offset={6}>
                                    <LogoWrapper>
                                        <Logo />
                                    </LogoWrapper>
                                </Col>
                            </LogoRow>}
                            {!simple && <h2>Welcome</h2>}
                            {!hasToken ? <p>
                                Get started by syncing Google Meet with
                                Versational®.
                            </p> : <p>
                                Your Google Meet account is connected with
                                Versational®.</p>}
                            <InnerCard
                                hasToken={hasToken}
                                hoverable={!hasToken}
                                onClick={sendRequest}
                            >
                                <Row>
                                    <Col span={2}>
                                        <GoogleImage src="/images/google-general.png" />
                                    </Col>
                                    <PaddedCol span={17}>
                                        {hasToken ? (
                                            <MeetTextCenter>
                                                Google Meet
                                                <LargeCheckCircle
                                                    twoToneColor={
                                                        CHECK_CIRCLE_TWO_TONE_COLOR
                                                    }
                                                />
                                            </MeetTextCenter>
                                        ) : (
                                            <>
                                                <strong>
                                                    Authenicate with Google
                                                </strong>
                                                <p>
                                                    Google meetings
                                                    will automatically
                                                    upload to Versational®.
                                                </p>
                                            </>
                                        )}
                                    </PaddedCol>
                                    <Col
                                        span={5}
                                        style={{ textAlign: 'right' }}
                                    >
                                        {hasToken && (
                                            <ColoredDivA
                                                onClick={removeIntegration}
                                            >
                                                Remove
                                            </ColoredDivA>
                                        )}
                                        {!hasToken && <LargeArrowRight />}
                                    </Col>
                                </Row>
                            </InnerCard>
                        </OuterCard>
                        {!simple && <div>
                            By using Versational® you agree to the{' '}
                            <a
                                href="https://www.versational.ai/agreements/terms-of-service"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Service
                            </a>
                            . We know that information is at the heart of your
                            business, which is why security is our top priority.
                        </div>}
                    </LayoutContent>
                </MainLayout>
            )}
        </PageWrapper>
    );
}

OAuth.propTypes = {
    simple: bool,
};

OAuth.defaultProps = {
    simple: false,
};

export default OAuth;
