import { call, put, takeEvery, select } from 'redux-saga/effects';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES, ROUTES } from '@/constants/routes';
import {
    fetchInvitesFailure,
    fetchInvitesSuccess,
    fetchInvites as fetchInvitesAction,
    INVITES_FETCH_START,
    INVITES_SEND_BULK,
    INVITE_DELETE_START,
    inviteDeleteSuccess,
    inviteDeleteFailure,
    INVITE_RESEND,
} from '@/redux/actions/invitesAction';
import { notification } from 'antd';
import {
    getNumberOfInvitedAndActiveUsers,
    getSubscriptionSeats,
} from '@/redux/selectors/subscriptionSelector';
import Router from 'next/router';
import { nanoid } from 'nanoid';

function InvitesErrorComponent() {
    return (
        <p>
            Looks like you need more seats. <br />Please&nbsp;
            <a
                role="link"
                tabIndex={0}
                onKeyDown={(e) => {
                    if (e.key === 'Space') {
                        Router.push(
                            `${
                                ROUTES.SETTINGS
                            }?organizationSettings=${nanoid()}&addSeats=${nanoid()}`,
                        );
                    }
                }}
                onClick={() => {
                    Router.push(
                        `${
                            ROUTES.SETTINGS
                        }?organizationSettings=${nanoid()}&addSeats=${nanoid()}`,
                    );
                }}
            >
                add seats here
            </a>.
        </p>
    );
}

async function fetchInvites() {
    return axios.get(NEXT_ROUTES.INVITES_COMPANY);
}

function* getInvitesSaga() {
    try {
        const { data } = yield fetchInvites();
        yield put(fetchInvitesSuccess(data));
    } catch (err) {
        yield put(fetchInvitesFailure(err));
    }
}

function* deleteInviteSaga(action) {
    try {
        yield call(axios.delete, NEXT_ROUTES.INVITES_ID(action.payload.data));
        yield call(notification.success, {
            message: 'Success',
            description: 'Invite successfully revoked',
            placement: 'bottomRight',
        });
        yield put(inviteDeleteSuccess(action.payload.data));
    } catch (e) {
        yield call(notification.error, {
            message: 'Error',
            description: 'Failed to revoke the invitation. Please try again.',
            placement: 'bottomRight',
        });
        yield put(inviteDeleteFailure(e, action.payload.data));
    }
}

function* reSendInvitesSaga(action) {
    try {
        yield call(
            axios.post,
            NEXT_ROUTES.INVITES_ID_RESEND(action.payload.data),
        );

        yield call(notification.success, {
            message: 'Success',
            description: 'Invite successfully resent',
        });
    } catch (error) {
        yield call(notification.error, {
            message: 'Error',
            description: 'Resending invite has failed',
        });
    }
}

function* sendInvitesSaga(action) {
    try {
        const numberOfInvitedAndActiveUsers = yield select(
            getNumberOfInvitedAndActiveUsers,
        );
        const subscriptionSeats = yield select(getSubscriptionSeats);
        const numberOfInvites = action.payload.data.length;
        if (
            numberOfInvitedAndActiveUsers + numberOfInvites <=
            subscriptionSeats
        ) {
            yield call(axios.post, NEXT_ROUTES.INVITES, action.payload.data);

            action.payload.callbacks.onSuccess();
            yield call(notification.success, {
                message: 'Success',
                description: 'Invites successfully sent',
            });
        } else {
            yield call(notification.info, {
                message: 'Add more seats',
                description: <InvitesErrorComponent />,
                duration: 10,
            });
        }
    } catch (error) {
        if (error?.response?.data === 'NOT_UNIQUE_EMAIL') {
            yield call(notification.error, {
                message: 'Error',
                description: 'You cannot invite same email twice',
            });
        }
        else if (error?.response?.data === 'DUPLICATE_EMAIL') {
            yield call(notification.error, {
                message: 'Error',
                description: `The user with this email already exists.`,
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description: 'Sending invites failed',
            });
        }
    } finally {
        yield put(fetchInvitesAction());
    }
}

export default function* InvitesSaga() {
    yield takeEvery(INVITES_FETCH_START, getInvitesSaga);
    yield takeEvery(INVITES_SEND_BULK, sendInvitesSaga);
    yield takeEvery(INVITE_RESEND, reSendInvitesSaga);
    yield takeEvery(INVITE_DELETE_START, deleteInviteSaga);
}
