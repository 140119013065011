export const PREPARATION_QUESTIONS_FETCH_START =
    'PREPARATION/QUESTIONS_FETCH_START';
export const PREPARATION_QUESTIONS_FETCH_SUCCESS =
    'PREPARATION/QUESTIONS_FETCH_SUCCESS';
export const PREPARATION_QUESTIONS_FETCH_FAILURE =
    'PREPARATION/QUESTIONS_FETCH_FAILURE';
export const PREPARATION_QUESTION_ADD = 'PREPARATION/QUESTION_ADD';
export const PREPARATION_QUESTION_EDIT = 'PREPARATION/QUESTION_EDIT';
export const PREPARATION_QUESTION_ADD_ASYNC = 'PREPARATION/QUESTION_ADD_ASYNC';
export const PREPARATION_QUESTION_EDIT_ASYNC =
    'PREPARATION/QUESTION_EDIT_ASYNC';
export const PREPARATION_QUESTION_DELETE = 'PREPARATION/QUESTION_DELETE';
export const PREPARATION_QUESTION_DELETE_ASYNC =
    'PREPARATION/QUESTION_DELETE_ASYNC';
export const PREPARATION_QUESTION_TAG_ADD = 'PREPARATION/QUESTION_TAG_ADD';
export const PREPARATION_QUESTION_TAG_ADD_ASYNC =
    'PREPARATION/QUESTION_TAG_ADD_ASYNC';
export const PREPARATION_QUESTION_VARIATION_ADD =
    'PREPARATION/QUESTION_VARIATION_ADD';
export const PREPARATION_QUESTION_VARIATION_DELETE =
    'PREPARATION/QUESTION_VARIATION_DELETE';
export const PREPARATION_QUESTION_VARIATION_EDIT =
    'PREPARATION/QUESTION_VARIATION_EDIT';
export const PREPARATION_QUESTION_VARIATION_ADD_ASYNC =
    'PREPARATION/QUESTION_VARIATION_ADD_ASYNC';
export const PREPARATION_QUESTION_VARIATION_DELETE_ASYNC =
    'PREPARATION/QUESTION_VARIATION_DELETE_ASYNC';
export const PREPARATION_QUESTION_VARIATION_EDIT_ASYNC =
    'PREPARATION/QUESTION_VARIATION_EDIT_ASYNC';
export const PREPARATION_QUESTION_TOGGLE_ALL_QUESTIONS =
    'PREPARATION/QUESTION_TOGGLE_ALL_QUESTIONS';

export const fetchQuestionsStart = () => ({
    type: PREPARATION_QUESTIONS_FETCH_START,
});

export const fetchQuestionsSuccess = (questions) => ({
    type: PREPARATION_QUESTIONS_FETCH_SUCCESS,
    payload: {
        questions,
    },
});

export const fetchQuestionsFailure = () => ({
    type: PREPARATION_QUESTIONS_FETCH_FAILURE,
});

export const deleteQuestion = (id) => ({
    type: PREPARATION_QUESTION_DELETE,
    payload: {
        id,
    },
});

export const deleteQuestionAsync = (questions) => ({
    type: PREPARATION_QUESTION_DELETE_ASYNC,
    payload: {
        questions,
    },
});

export const editQuestion = (id, type, value) => ({
    type: PREPARATION_QUESTION_EDIT,
    payload: {
        id,
        type,
        value,
    },
});

export const editQuestionAsync = (questions) => ({
    type: PREPARATION_QUESTION_EDIT_ASYNC,
    payload: {
        questions,
    },
});

export const editQuestionVariation = (parentId, childId, type, value) => ({
    type: PREPARATION_QUESTION_VARIATION_EDIT,
    payload: {
        parentId,
        childId,
        type,
        value,
    },
});

export const editQuestionVariationAsync = (questions) => ({
    type: PREPARATION_QUESTION_VARIATION_EDIT_ASYNC,
    payload: {
        questions,
    },
});

export const deleteQuestionVariation = (parentId, childId) => ({
    type: PREPARATION_QUESTION_VARIATION_DELETE,
    payload: {
        parentId,
        childId,
    },
});

export const deleteQuestionVariationAsync = (questions) => ({
    type: PREPARATION_QUESTION_VARIATION_DELETE_ASYNC,
    payload: {
        questions,
    },
});

export const addQuestion = () => ({
    type: PREPARATION_QUESTION_ADD,
});

export const addQuestionAsync = (questions) => ({
    type: PREPARATION_QUESTION_ADD_ASYNC,
    payload: {
        questions,
    },
});

export const addQuestionTag = (id, type, value) => ({
    type: PREPARATION_QUESTION_TAG_ADD,
    payload: {
        id,
        type,
        value,
    },
});

export const addQuestionTagAsync = (questions, tags) => ({
    type: PREPARATION_QUESTION_TAG_ADD_ASYNC,
    payload: {
        questions,
        tags,
    },
});

export const addQuestionVariation = (id) => ({
    type: PREPARATION_QUESTION_VARIATION_ADD,
    payload: {
        id,
    },
});

export const addQuestionVariationAsync = (questions) => ({
    type: PREPARATION_QUESTION_VARIATION_ADD_ASYNC,
    payload: {
        questions,
    },
});

export const toggleAllQuestions = () => ({
    type: PREPARATION_QUESTION_TOGGLE_ALL_QUESTIONS,
});
