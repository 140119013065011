import dayjs from 'dayjs';
import moment from 'moment';

export const utcToIsoDayjs = (utcString) => dayjs(`${utcString}Z`);

export const googleUtcToIsoDayjs = (utcString) => dayjs(`${utcString}`);

export const formatDatePickerValueIncludingTimezone = (momentObject) => {
    // TODO Date picker should use ant d datePicker component
    if (!momentObject) return dayjs.tz().toDate();

    const day = `${momentObject.date()}`.padStart(2, '0');
    const month = `${momentObject.month() + 1}`.padStart(2, '0');
    const year = `${momentObject.year()}`.padStart(2, '0');

    /**
     * Date should be selected in defined timezone so it is used for creation of object in that timezone and converted back to unified format
     * */
    return dayjs.tz(`${year}-${month}-${day} 12:00`).toDate();
};

export const dayjsToMoment = (dayjsObject) => {
    const year = dayjsObject.year();
    const month = dayjsObject.month() + 1;
    const day = dayjsObject.date();
    const hour = dayjsObject.hour();
    const minutes = dayjsObject.minute();

    return moment(`${year}-${month}-${day} ${hour}:${minutes}`, 'YYYY-M-D H:m');
};

export const momentToDayjs = (momentObject) => {
    const day = momentObject.date();
    const month = momentObject.month();
    const year = momentObject.year();
    const hour = momentObject.hour();
    const minute = momentObject.minute();

    return dayjs({ year, month, day, hour, minute });
};
