import { Input } from 'antd';
import { func, string } from 'prop-types';
import { ColoredDivSearchWrapper } from '@/components/conversations/attendeesChooser/attendeesChooser.styles';

const { Search: AntdSearch } = Input;

function Search({ searchValue, setSearchValue }) {
    return (
        <ColoredDivSearchWrapper>
            <AntdSearch
                value={searchValue}
                onChange={(e) => {
                    setSearchValue(e.target.value);
                }}
                placeholder="Search attendees..."
            />
        </ColoredDivSearchWrapper>
    );
}

Search.propTypes = {
    searchValue: string.isRequired,
    setSearchValue: func.isRequired,
};

export default Search;
