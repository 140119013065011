import dayjs from 'dayjs';
import durationPlugin from 'dayjs/plugin/duration';

dayjs.extend(durationPlugin);

export const getRecoveryDurationFormat = (duration) => {
    const durationDayjs = dayjs.duration(duration);
    const format = [
        { format: 'D [d]', time: durationDayjs.days() },
        { format: 'H [h]', time: durationDayjs.hours() },
        { format: 'm [m]', time: durationDayjs.minutes() },
        { format: 's [s]', time: durationDayjs.seconds() },
    ]
        .filter(({ time }) => time !== 0)
        .map(({ format: _format }) => _format)
        .join(' ');

    return durationDayjs.format(format);
};
