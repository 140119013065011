export const SUBSCRIPTION_RENEW_SUBSCRIPTION_START =
    'SUBSCRIPTION/RENEW_SUBSCRIPTION_START';
export const SUBSCRIPTION_RENEW_SUBSCRIPTION_FAILED =
    'SUBSCRIPTION/RENEW_SUBSCRIPTION_FAILED';
export const SUBSCRIPTION_BUY_SUBSCRIPTION_START =
    'SUBSCRIPTION/BUY_SUBSCRIPTION_START';
export const SUBSCRIPTION_BUY_SUBSCRIPTION_FAILED =
    'SUBSCRIPTION/BUY_SUBSCRIPTION_FAILED';
export const SUBSCRIPTION_UPDATE_PAYMENT_START =
    'SUBSCRIPTION/UPDATE_PAYMENT_START';
export const SUBSCRIPTION_CANCEL_HOSTED_PAGE =
    'SUBSCRIPTION/CANCEL_HOSTED_PAGE';
export const SUBSCRIPTION_GOT_HOSTED_PAGE = 'SUBSCRIPTION/GOT_HOSTED_PAGE';
export const SUBSCRIPTION_UPDATE_PAYMENT_FAILED =
    'SUBSCRIPTION/UPDATE_PAYMENT_FAILED';
export const SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_START =
    'SUBSCRIPTION/UPDATE_SUBSCRIPTION_SEATS_START';
export const SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY =
    'SUBSCRIPTION/UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY';
export const SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_FAILED =
    'SUBSCRIPTION/UPDATE_SUBSCRIPTION_SEATS_START_FAILED';
export const SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_CANCELLED_SUBSCRIPTION =
    'SUBSCRIPTION/SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_CANCELLED_SUBSCRIPTION';
export const SUBSCRIPTION_GET_SUBSCRIPTION_FAILED =
    'SUBSCRIPTION/GET_SUBSCRIPTION_FAILED';
export const SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_START =
    'SUBSCRIPTION/GET_SUBSCRIPTION_SCHEDULED_CHANGES_START';
export const SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_SUCCESSFUL =
    'SUBSCRIPTION/GET_SUBSCRIPTION_SCHEDULED_CHANGES_SUCCESSFUL';
export const SUBSCRIPTION_RESET_SCHEDULED_CHANGES =
    'SUBSCRIPTION/RESET_SCHEDULED_CHANGES';

export const SUBSCRIPTION_BUY_ADDON_START = 'SUBSCRIPTION/BUY_ADDON_START';
export const SUBSCRIPTION_BUY_ADDON_FAILED = 'SUBSCRIPTION/BUY_ADDON_FAILED';
export const SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY =
    'SUBSCRIPTION/BUY_ADDON_SUCCESSFULLY';

export const SUBSCRIPTION_CHANGE_PLAN_START = 'SUBSCRIPTION/CHANGE_PLAN_START';
export const SUBSCRIPTION_CHANGE_PLAN_FAILED =
    'SUBSCRIPTION/CHANGE_PLAN_FAILED';
export const SUBSCRIPTION_CHANGE_PLAN_SUCCESSFULLY =
    'SUBSCRIPTION/CHANGE_PLAN_SUCCESSFULLY';

export const SUBSCRIPTION_CANCEL_SUBSCRIPTION_START =
    'SUBSCRIPTION/CANCEL_SUBSCRIPTION_START';
export const SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILURE =
    'SUBSCRIPTION/CANCEL_SUBSCRIPTION_FAILURE';
export const SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESSFULLY =
    'SUBSCRIPTION/CANCEL_SUBSCRIPTION_SUCCESSFULLY';

export const SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_START =
    'SUBSCRIPTION/REACTIVATE_SUBSCRIPTION_START';
export const SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_FAILURE =
    'SUBSCRIPTION/REACTIVATE_SUBSCRIPTION_FAILURE';
export const SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_SUCCESSFULLY =
    'SUBSCRIPTION/REACTIVATE_SUBSCRIPTION_SUCCESSFULLY';

export const SUBSCRIPTION_PAYMENT_FAILURE = 'SUBSCRIPTION/PAYMENT_FAILURE';

export const SUBSCRIPTION_ADD_SEATS_PROCESS_SET_SEATS =
    'SUBSCRIPTION/ADD_SEATS_PROCESS_SET_SEATS';
export const SUBSCRIPTION_ADD_SEATS_RESET_PROCESS =
    'SUBSCRIPTION/ADD_SEATS_RESET_PROCESS';

export const SUBSCRIPTION_COMPUTE_PRICE_START =
    'SUBSCRIPTION/COMPUTE_PRICE_START';
export const SUBSCRIPTION_COMPUTED_PRICE =
    'SUBSCRIPTION/ADD_SEATS_COMPUTED_PRICE';
export const SUBSCRIPTION_COMPUTE_PRICE_FAILURE =
    'SUBSCRIPTION/ADD_SEATS_COMPUTE_PRICE_FAILURE';

export const updatePaymentStart = () => ({
    type: SUBSCRIPTION_UPDATE_PAYMENT_START,
});

export const renewSubscriptionStart = (data, callbacks) => ({
    type: SUBSCRIPTION_RENEW_SUBSCRIPTION_START,
    payload: { data, callbacks },
});
export const buySubscriptionStart = (data) => ({
    type: SUBSCRIPTION_BUY_SUBSCRIPTION_START,
    payload: { data },
});

export const cancelHostedPage = () => ({
    type: SUBSCRIPTION_CANCEL_HOSTED_PAGE,
});

export const gotHostedPage = (hostedPage) => ({
    type: SUBSCRIPTION_GOT_HOSTED_PAGE,
    payload: hostedPage,
});

export const updatePaymentFailure = (error) => ({
    type: SUBSCRIPTION_UPDATE_PAYMENT_FAILED,
    payload: error,
});
export const renewSubscriptionFailure = (error) => ({
    type: SUBSCRIPTION_RENEW_SUBSCRIPTION_FAILED,
    payload: error,
});
export const buySubscriptionFailure = (error) => ({
    type: SUBSCRIPTION_BUY_SUBSCRIPTION_FAILED,
    payload: error,
});

export const updateSubscriptionSeatsStart = (data, callbacks) => ({
    type: SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_START,
    payload: { data, callbacks },
});

export const updateSubscriptionSeatsSuccessful = (data) => ({
    type: SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_SUCCESSFULLY,
    payload: { data },
});

export const updateSubscriptionSeatsFailed = (error) => ({
    type: SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_FAILED,
    payload: error,
});

export const updateSubscriptionSeatsCancelledSubscription = (error) => ({
    type: SUBSCRIPTION_UPDATE_SUBSCRIPTION_SEATS_CANCELLED_SUBSCRIPTION,
    payload: error,
});

export const getSubscriptionFailed = (error) => ({
    type: SUBSCRIPTION_GET_SUBSCRIPTION_FAILED,
    payload: error,
});

export const getSubscriptionScheduledChangesStart = () => ({
    type: SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_START,
});

export const getSubscriptionScheduledChangesSuccessful = (data) => ({
    type: SUBSCRIPTION_GET_SUBSCRIPTION_SCHEDULED_CHANGES_SUCCESSFUL,
    payload: { data },
});

export const resetSubscriptionScheduledChanges = () => ({
    type: SUBSCRIPTION_RESET_SCHEDULED_CHANGES,
});

export const buyAddonStart = (data, callbacks) => ({
    type: SUBSCRIPTION_BUY_ADDON_START,
    payload: { data, callbacks },
});
export const buyAddonFailed = (error) => ({
    type: SUBSCRIPTION_BUY_ADDON_FAILED,
    payload: error,
});
export const buyAddonSuccessfully = (data) => ({
    type: SUBSCRIPTION_BUY_ADDON_SUCCESSFULLY,
    payload: { data },
});

export const paymentFailure = () => ({
    type: SUBSCRIPTION_PAYMENT_FAILURE,
});

export const reactivateSubscriptionStart = () => ({
    type: SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_START,
});

export const reactivateSubscriptionSuccessfully = (data) => ({
    type: SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_SUCCESSFULLY,
    payload: { data },
});
export const reactivateSubscriptionFailure = (error) => ({
    type: SUBSCRIPTION_REACTIVATE_SUBSCRIPTION_FAILURE,
    payload: error,
});

export const addSeatsProcessSetSeats = (data) => ({
    type: SUBSCRIPTION_ADD_SEATS_PROCESS_SET_SEATS,
    payload: { data },
});

export const computePriceStart = (data) => ({
    type: SUBSCRIPTION_COMPUTE_PRICE_START,
    payload: { data },
});

export const computedPrice = (data) => ({
    type: SUBSCRIPTION_COMPUTED_PRICE,
    payload: { data },
});

export const computePriceFailure = (error) => ({
    type: SUBSCRIPTION_COMPUTE_PRICE_FAILURE,
    payload: error,
});

export const resetAddSeatsProcess = () => ({
    type: SUBSCRIPTION_ADD_SEATS_RESET_PROCESS,
});

export const cancelSubscriptionStart = () => ({
    type: SUBSCRIPTION_CANCEL_SUBSCRIPTION_START,
});
export const cancelSubscriptionFailure = (error) => ({
    type: SUBSCRIPTION_CANCEL_SUBSCRIPTION_FAILURE,
    payload: error,
});
export const cancelSubscriptionSuccessfully = (data) => ({
    type: SUBSCRIPTION_CANCEL_SUBSCRIPTION_SUCCESSFULLY,
    payload: { data },
});

export const changePlanStart = (data, callbacks) => ({
    type: SUBSCRIPTION_CHANGE_PLAN_START,
    payload: { data, callbacks },
});
export const changePlanFailure = (error) => ({
    type: SUBSCRIPTION_CHANGE_PLAN_FAILED,
    payload: error,
});
export const changePlanSuccessfully = (data) => ({
    type: SUBSCRIPTION_CHANGE_PLAN_SUCCESSFULLY,
    payload: { data },
});
