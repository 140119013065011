import styled from 'styled-components';
import { Card } from 'antd';
import { ColoredDivColumn as ParentColoredDivColumn } from '@/components/settings/tabs/shared.styles';

const ColoredDivColumn = styled(ParentColoredDivColumn)`
    min-width: 50%;

    .ant-spin-nested-loading {
        width: 100%;
    }

    .ant-spin-nested-loading,
    .ant-spin-container {
        width: 100%;
    }
`;

const ColoredCard = styled(Card)`
    width: 100%;

    .ant-card-body {
        width: 100%;
        padding: 1rem;
        text-align: end;
    }
`;

const ColoredDivFormRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0.5rem;

    & > * {
        flex: 1;
    }
`;

export { ColoredDivColumn, ColoredCard, ColoredDivFormRow };
