import { FETCH_STATUS } from '@/constants/fetchStatus';
import { CONVERSATION_RESULT } from '@/redux/actions/conversation/conversationResult';

export const initialState = {
    status: FETCH_STATUS.IDLE,
    error: null,
};

function conversationResultReducer(state = initialState, action) {
    switch (action.type) {
        case CONVERSATION_RESULT.RESET:
            return initialState;
        case CONVERSATION_RESULT.GUEST_FETCH_START:
        case CONVERSATION_RESULT.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case CONVERSATION_RESULT.FETCH_SUCCESS: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case CONVERSATION_RESULT.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default conversationResultReducer;
