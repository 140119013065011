import { calculateLongestMonologuesSearchPattern } from '@/utils/conversations/search/calculateLongestMonologuesSearchPattern';
import { calculateKeywordSearchPattern } from '@/utils/conversations/search/calculateKeywordSearchPattern';
import { SEARCH_LENGTH_THRESHOLD_TRANSCRIPT } from '@/constants/config/searchLengthThreshold';

function getSearchOptionsRegExpExtensions(searchOptionsExtensionsBuilder) {
    const getSearchOptionsRegExpPattern = (value) => {
        const extendedPattern = [...searchOptionsExtensionsBuilder(value)].find(
            (v) => !!v,
        );
        return extendedPattern || value;
    };

    return (value) =>
        value.length >= SEARCH_LENGTH_THRESHOLD_TRANSCRIPT
            ? new RegExp(getSearchOptionsRegExpPattern(value), 'gmi')
            : null;
}

export function getSearchRegExpExtensions(transcript) {
    const searchRegExpExtensionsBuilder = (value) => [
        calculateLongestMonologuesSearchPattern(transcript, value),
        calculateKeywordSearchPattern(value),
    ];

    return getSearchOptionsRegExpExtensions(searchRegExpExtensionsBuilder);
}
