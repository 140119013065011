import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    SETTINGS_CALENDAR_FETCH_FAILURE,
    SETTINGS_CALENDAR_FETCH_START,
    SETTINGS_CALENDAR_FETCH_SUCCESS,
    SETTINGS_CALENDAR_UPDATE_FAILURE,
    SETTINGS_CALENDAR_UPDATE_START,
    SETTINGS_CALENDAR_UPDATE_SUCCESS,
} from '@/redux/actions/settings/calendarAction';

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
};

function settingsCalendarReducer(state = initialState, action) {
    switch (action.type) {
        case SETTINGS_CALENDAR_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case SETTINGS_CALENDAR_FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case SETTINGS_CALENDAR_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_CALENDAR_UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case SETTINGS_CALENDAR_UPDATE_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case SETTINGS_CALENDAR_UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default settingsCalendarReducer;
