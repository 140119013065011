import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ATTENDEES_KEY } from '@/constants/reduxKeys';
import attendeesReducer from '@/redux/reducers/attendeesReducer';
import attendeesSaga from '@/redux/sagas/attendeesSaga';
import {
    addAttendeeStart,
    fetchAttendeesStart,
} from '@/redux/actions/attendeesAction';
import { getAttendees } from '@/redux/selectors/attendeesSelector';
import { getIsCrmActive } from '@/redux/selectors/authSelector';

export default function useAttendees() {
    const dispatch = useDispatch();
    const isCRMAvailable = useSelector(getIsCrmActive);

    useInjectReducer({
        key: ATTENDEES_KEY,
        reducer: attendeesReducer,
    });

    useInjectSaga({ key: ATTENDEES_KEY, saga: attendeesSaga });

    const attendees = useSelector(getAttendees);
    const loading = attendees?.loading;
    const data = attendees?.attendees;
    const error = attendees?.error;
    const isIdle = attendees?.isIdle;

    useEffect(() => {
        if (isIdle && !isCRMAvailable && data.length === 0) {
            dispatch(fetchAttendeesStart());
        }
    }, [data?.length, dispatch, isCRMAvailable, isIdle]);

    const addAttendee = useCallback(
        (name, onFinish) => {
            dispatch(
                addAttendeeStart(
                    {
                        name,
                    },
                    onFinish,
                ),
            );
        },
        [dispatch],
    );

    return { loading, data, error, addAttendee };
}
