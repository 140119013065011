import SimpleBar from 'simplebar-react';
import styled, { keyframes, css } from 'styled-components';
import { Button, Input, Typography } from 'antd';
import {
    CHECK_CIRCLE_TWO_TONE_COLOR,
    GRAY_4,
    GRAY_6,
    GREEN_2,
    RED,
    VIVIDORANGE,
} from '@/constants/colors';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StyledTd = styled.td`
    width: 120px !important;
    background: transparent !important;

    &&&& {
        padding: ${({ smallPadding }) => smallPadding && '4px 1rem'};
    }
`;

const ColoredAEllipsisWrapper = styled(Typography.Paragraph)`
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    color: inherit;
    text-overflow: ellipsis;
    margin: 0 !important;
`;

const ColoredText = styled.p`
    margin: 0;
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
`;

const ColoredButton = styled(Button)`
    padding-right: 0;
    padding-left: 0;
    border: none;
    text-align: inherit;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    overflow: hidden;

    span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 100%;
    }

    &:not([disabled]) {
        :hover {
            background-color: transparent;
        }
    }
`;

const ColoredInput = styled(Input)`
    padding-right: 0;
    padding-left: 0;
`;

const StyledConversationCell = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    overflow: hidden;
    max-width: 430px;

    gap: 0.5rem;

    .fa-video,
    .fa-microphone {
        width: 20px !important;
        height: 20px !important;
        color: ${GRAY_4};
    }

    .fa-check-circle {
        color: ${(props) => (props.isResult ? GREEN_2 : GRAY_4)};
        width: 24px !important;
        height: 24px !important;
    }

    .fa-circle-xmark {
        width: 24px !important;
        height: 24px !important;
    }

    .versational--icon {
        width: 24px !important;
        height: 24px !important;
        color: ${VIVIDORANGE};
    }
`;

export const StyledConversationInfo = styled.div`
    height: 100%;
    width: 90%;
`;
export const StyledConversationHeader = styled.div`
    display: flex;
    gap: 0.5rem;
    flex-direction: row;
    white-space: nowrap;
`;

export const StyledConversationGemsWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
`;

export const StyledUploadWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const SuccessTextWrapper = styled.div`
    max-width: 21rem;
`;

export const ProgressTextWrapper = styled.div`
    display: flex;
    font-size: 12px;
    flex-direction: column;
    justify-content: flex-start;
    text-align: start;
    align-items: center;
`;

export const StyledPLoadingInfo = styled.div`
    color: ${({ $error }) => $error && RED};
    font-size: 12px;
    margin-bottom: 0;
    width: 100%;
    word-wrap: break-word;
    margin-top: 0.25rem;
    text-align: left;
`;

export const StyledLoadingInfo = styled.div`
    display: flex;
    flex-direction: column;
    font-size: 12px;
    width: 100%;
    word-wrap: break-word;
    padding-top: 0.25rem;
    gap: 0.25rem;
    text-align: left;
`;

export const StyledImageWrapperNotCompleted = styled.div`
    img {
        border: ${props => props.isLoading ? '2px solid orange !important' : '2px solid red !important'};
        border-radius: 50% !important;
        min-width: 24px;
        animation: ${props => props.isLoading ? css`${spin} 3s linear infinite` : 'none'};
    }
`;

export const StyledImageWrapperCompleted = styled.div`
    img {
        border: 2px solid green !important;
        border-radius: 50% !important;
        min-width: 24px;
    }
`;

const StyledDivTag = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${GRAY_6};
    border-radius: 2px;
    padding: 1px 8px;
    height: 1.5rem;
`;

const StyledOwner = styled.div`
    display: flex;
    align-items: center;

    .owner-name {
        margin-left: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
`;

const StyledEllipsisChildren = styled.p`
    margin: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
`;

const CheckContainer = styled.span`
    > svg {
        color: ${CHECK_CIRCLE_TWO_TONE_COLOR} !important;
    }
`;

const CrossContainer = styled.span`
    > svg {
        color: ${CHECK_CIRCLE_TWO_TONE_COLOR} !important;
    }

    cursor: pointer;
`;

const ColoredSimpleBarReact = styled(SimpleBar)`
    height: 100%;
    overflow: auto;
`;

const ColoredDivSharedBy = styled.div`
    padding-top: 0.125rem;
    font-size: 12px;
    text-align: start;
`;

export {
    ColoredAEllipsisWrapper,
    ColoredButton,
    ColoredInput,
    CrossContainer,
    StyledConversationCell,
    StyledTd,
    StyledDivTag,
    StyledOwner,
    StyledEllipsisChildren,
    ColoredSimpleBarReact,
    ColoredText,
    CheckContainer,
    ColoredDivSharedBy,
};
