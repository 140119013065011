import { Select } from 'antd';
import { func, oneOf } from 'prop-types';
import { USER_ROLES } from '@/constants/userRoles';
import { ColoredSelect } from '@/components/settings/team/userRoleSelect/userRoleSelect.styles';

export const getUserRoleDisplayName = (role) => {
    if (role === USER_ROLES.USER) return 'User';
    if (role === USER_ROLES.MANAGER) return 'Manager';
    if (role === USER_ROLES.ADMIN) return 'Admin';
    if (role === USER_ROLES.ARCHIVED) return 'Archived';
    throw new Error('Illegal argument provided');
};

function UserRoleSelect({ value, onChange, currentUserRole, ...rest }) {
    const availableRoles = (() => {
        if (currentUserRole === USER_ROLES.ADMIN)
            return [USER_ROLES.ADMIN, USER_ROLES.USER, USER_ROLES.MANAGER];
        if (currentUserRole === USER_ROLES.MANAGER)
            return [USER_ROLES.MANAGER, USER_ROLES.USER];
        if (currentUserRole === USER_ROLES.USER) return [USER_ROLES.USER];
        return [];
    })();

    return (
        <ColoredSelect
            value={value}
            onChange={onChange}
            dropdownMatchSelectWidth={false}
            {...rest}
            aria-label="Select user permission role"
            data-testid="teamSelect"
        >
            {value === USER_ROLES.ARCHIVED && (
                <Select.Option
                    value={USER_ROLES.ARCHIVED}
                    data-testid="teamSelectOption"
                >
                    Archived
                </Select.Option>
            )}
            {value !== USER_ROLES.ARCHIVED &&
                availableRoles.map((v) => (
                    <Select.Option
                        key={v}
                        value={v}
                        data-testid="teamSelectOption"
                    >
                        {getUserRoleDisplayName(v)}
                    </Select.Option>
                ))}
        </ColoredSelect>
    );
}

UserRoleSelect.defaultProps = {
    currentUserRole: null,
};

UserRoleSelect.propTypes = {
    value: oneOf(Object.values(USER_ROLES)).isRequired,
    currentUserRole: oneOf(Object.values(USER_ROLES)),
    onChange: func.isRequired,
};

export default UserRoleSelect;
