/* eslint-disable indent */
import {
    KEYWORD_BLUE,
    KEYWORD_BLUE_ACTIVE,
    TRANSPARENT_DODGER_BLUE,
    WHITE,
} from '@/constants/colors';

export const transcriptItemSelector = 'scrollTo-transcriptItem-active';

export const getSearchOccurrences = (words, searchRegExp) => {
    const indexesOfSearchOccurrences = [];
    let result;

    // eslint-disable-next-line no-cond-assign
    while ((result = searchRegExp.exec(words))) {
        indexesOfSearchOccurrences.push(result.index);
    }

    return indexesOfSearchOccurrences;
};

export const getSearchElement = (text, active) =>
    `<span style='background: ${WHITE};'><span ${
        active ? `class="${transcriptItemSelector}" ` : ''
    } style='background: ${
        active ? KEYWORD_BLUE_ACTIVE : KEYWORD_BLUE
    };'>${text}</span></span>`;

export const getQuerySearchElement = (text) =>
    `<span class='${transcriptItemSelector}' style='background: ${TRANSPARENT_DODGER_BLUE}'>${text}</span>`;

export const getSelectionCharacterOffsetWithin = (element) => {
    const doc = element.ownerDocument || element.document;
    const win = doc.defaultView || doc.parentWindow;
    let startsAt;
    let endsAt;

    const selection = win.getSelection();
    if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0);
        const preCaretRange = new Range();

        preCaretRange.selectNodeContents(element);

        preCaretRange.setEnd(range.startContainer, range.startOffset);
        startsAt = preCaretRange.toString().length;
        preCaretRange.setEnd(range.endContainer, range.endOffset);
        endsAt = preCaretRange.toString().length;
    }
    win.getSelection().removeAllRanges();

    return { startsAt, endsAt };
};

function getPosition(string, subString, index) {
    return string
        .toLowerCase()
        .split(subString.toLowerCase(), index)
        .join(subString).length;
}

export const getContentsWithMatchByWordsStartIndex = (
    searchableContent,
    searchRegExp,
) =>
    searchableContent.reduce((acc, current) => {
        const match = current.words.match(searchRegExp);
        return !match
            ? acc
            : [
                  ...acc,
                  ...match.map((m, i) => ({
                      contentId: current.id,
                      words: m.split(' ').reduce((matchedWords, w) => {
                          const lastElement =
                              matchedWords[matchedWords.length - 1];
                          if (lastElement) {
                              const startIndex = lastElement.endIndex + 1;
                              return [
                                  ...matchedWords,
                                  {
                                      startIndex,
                                      endIndex: startIndex + w.length,
                                  },
                              ];
                          }
                          const startIndex = getPosition(
                              current.words,
                              m,
                              i + 1,
                          );
                          return [
                              ...matchedWords,
                              {
                                  startIndex,
                                  endIndex: startIndex + w.length,
                              },
                          ];
                      }, []),
                  })),
              ];
    }, []);
