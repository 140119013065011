import { keywordOption } from '@/utils/conversations/search/autoCompleteOptions/getAutoCompleteOptions';

export function calculateKeywordSearchPattern(value) {
    const prefix = `${keywordOption.value} `;

    if (!value.toLowerCase().startsWith(prefix.toLowerCase())) {
        return null;
    }

    return value.substring(prefix.length, value.length);
}
