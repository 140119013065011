import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
    createFailure,
    createStart,
    createSuccess,
    deleteFailure,
    deleteStart,
    deleteSuccess,
} from '@/redux/actions/genericActions';

export const SETTINGS_TOPIC_TRACKER_FETCH_START =
    'SETTINGS_TOPIC_TRACKER/FETCH_START';
export const SETTINGS_TOPIC_TRACKER_FETCH_SUCCESS =
    'SETTINGS_TOPIC_TRACKER/FETCH_SUCCESS';
export const SETTINGS_TOPIC_TRACKER_FETCH_FAILURE =
    'SETTINGS_TOPIC_TRACKER/FETCH_FAILURE';

export const SETTINGS_TOPIC_TRACKER_UPDATE_START =
    'SETTINGS_TOPIC_TRACKER/UPDATE_START';
export const SETTINGS_TOPIC_TRACKER_UPDATE_SUCCESS =
    'SETTINGS_TOPIC_TRACKER/UPDATE_SUCCESS';
export const SETTINGS_TOPIC_TRACKER_UPDATE_FAILURE =
    'SETTINGS_TOPIC_TRACKER/UPDATE_FAILURE';

export const SETTINGS_TOPIC_TRACKER_CREATE_START =
    'SETTINGS_TOPIC_TRACKER/CREATE_START';
export const SETTINGS_TOPIC_TRACKER_CREATE_SUCCESS =
    'SETTINGS_TOPIC_TRACKER/CREATE_SUCCESS';
export const SETTINGS_TOPIC_TRACKER_CREATE_FAILURE =
    'SETTINGS_TOPIC_TRACKER/CREATE_FAILURE';

export const SETTINGS_TOPIC_TRACKER_DELETE_START =
    'SETTINGS_TOPIC_TRACKER/DELETE_START';
export const SETTINGS_TOPIC_TRACKER_DELETE_SUCCESS =
    'SETTINGS_TOPIC_TRACKER/DELETE_SUCCESS';
export const SETTINGS_TOPIC_TRACKER_DELETE_FAILURE =
    'SETTINGS_TOPIC_TRACKER/DELETE_FAILURE';

export const fetchSettingsTopicTracker = () =>
    fetchData(SETTINGS_TOPIC_TRACKER_FETCH_START);
export const fetchSettingsTopicTrackerSuccess = (data) =>
    fetchSuccess(SETTINGS_TOPIC_TRACKER_FETCH_SUCCESS, data);
export const fetchSettingsTopicTrackerFailure = (error) =>
    fetchFailure(SETTINGS_TOPIC_TRACKER_FETCH_FAILURE, error);
export const settingsTopicTrackerUpdateStart = (data) =>
    updateStart(SETTINGS_TOPIC_TRACKER_UPDATE_START, data);
export const settingsTopicTrackerUpdateFailure = (error) =>
    updateFailure(SETTINGS_TOPIC_TRACKER_UPDATE_FAILURE, error);
export const settingsTopicTrackerUpdateSuccess = (data) =>
    updateSuccess(SETTINGS_TOPIC_TRACKER_UPDATE_SUCCESS, data);
export const settingsTopicTrackerDeleteStart = (data) =>
    deleteStart(SETTINGS_TOPIC_TRACKER_DELETE_START, data);
export const settingsTopicTrackerDeleteFailure = (error) =>
    deleteFailure(SETTINGS_TOPIC_TRACKER_DELETE_FAILURE, error);
export const settingsTopicTrackerDeleteSuccess = (data) =>
    deleteSuccess(SETTINGS_TOPIC_TRACKER_DELETE_SUCCESS, data);
export const settingsTopicTrackerCreateStart = (data, onSuccess) =>
    createStart(SETTINGS_TOPIC_TRACKER_CREATE_START, data, onSuccess);
export const settingsTopicTrackerCreateFailure = (error) =>
    createFailure(SETTINGS_TOPIC_TRACKER_CREATE_FAILURE, error);
export const settingsTopicTrackerCreateSuccess = (data) =>
    createSuccess(SETTINGS_TOPIC_TRACKER_CREATE_SUCCESS, data);
