import {
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('CONVERSATION');

export const CONVERSATION = {
    ...creator.fetch(),
    GUEST_FETCH_START: creator.rawCustom('GUEST_FETCH_START'),
    UPDATE_PASSWORD: creator.custom('UPDATE_PASSWORD'),
    UPDATE_TITLE: creator.custom('UPDATE_TITLE'),
    UPDATE_IS_DOWNLOADABLE: creator.custom('UPDATE_IS_DOWNLOADABLE'),
    UPDATE_SHARED_TO: creator.custom('UPDATE_SHARED_TO'),
    DELETED_CONVERSATION_SHARING_FOR_USER: creator.custom(
        'DELETED_CONVERSATION_SHARING_FOR_USER',
    ),
    GENERATE_SUMMARIZATION: creator.custom('GENERATE_SUMMARIZATION'),
    UPDATE_SHARED_TO_ALL: creator.custom('UPDATE_SHARED_TO_ALL'),
    UPDATE_MEEETING_NOTE: creator.rawCustom('UPDATE_MEEETING_NOTE'),
    UPDATE_IS_MODAL_VISIBLE: creator.rawCustom('UPDATE_IS_MODAL_VISIBLE'),
};

export const fetchConversation = (id) =>
    fetchData(CONVERSATION.FETCH_START, { id });
export const fetchGuestConversation = (id) =>
    fetchData(CONVERSATION.GUEST_FETCH_START, { id });
export const fetchConversationSuccess = (data) =>
    fetchSuccess(CONVERSATION.FETCH_SUCCESS, data);
export const fetchConversationFailure = (error) =>
    fetchFailure(CONVERSATION.FETCH_FAILURE, error);

export const updateConversationPassword = (
    password,
    onSuccess,
    onFailure,
    onFinally,
) => ({
    type: CONVERSATION.UPDATE_PASSWORD.START,
    payload: {
        password,
        onSuccess,
        onFailure,
        onFinally,
    },
});

export const updateConversationPasswordSuccess = (password) => ({
    type: CONVERSATION.UPDATE_PASSWORD.SUCCESS,
    payload: {
        password,
    },
});

export const updateConversationPasswordFailure = (error) => ({
    type: CONVERSATION.UPDATE_PASSWORD.FAILURE,
    payload: {
        error,
    },
});

export const updateConversationIsDownloadable = (
    isDownloadable,
    onSuccess,
    onFailure,
    onFinally,
) => ({
    type: CONVERSATION.UPDATE_IS_DOWNLOADABLE.START,
    payload: {
        isDownloadable,
        onSuccess,
        onFailure,
        onFinally,
    },
});

export const updateConversationIsDownloadableSuccess = (isDownloadable) => ({
    type: CONVERSATION.UPDATE_IS_DOWNLOADABLE.SUCCESS,
    payload: {
        isDownloadable,
    },
});

export const updateConversationIsDownloadableFailure = (error) => ({
    type: CONVERSATION.UPDATE_IS_DOWNLOADABLE.FAILURE,
    payload: {
        error,
    },
});

export const updateConversationTitle = (title, noUpdate) => ({
    type: CONVERSATION.UPDATE_TITLE.START,
    payload: {
        title,
        noUpdate,
    },
});

export const updateConversationTitleSuccess = (title) => ({
    type: CONVERSATION.UPDATE_TITLE.SUCCESS,
    payload: {
        title,
    },
});

export const updateConversationTitleFailure = (error) => ({
    type: CONVERSATION.UPDATE_TITLE.FAILURE,
    payload: {
        error,
    },
});

export const updateSharedToStart = (
    conversationId,
    userIds,
    message,
    onSuccess,
    onFailure,
    teamMembers,
) => ({
    type: CONVERSATION.UPDATE_SHARED_TO.START,
    payload: {
        conversationId,
        userIds,
        message,
        onSuccess,
        onFailure,
        teamMembers,
    },
});

export const updateSharedToSuccess = (ids) => ({
    type: CONVERSATION.UPDATE_SHARED_TO.SUCCESS,
    payload: {
        ids,
    },
});

export const updateSharedToFailure = (error) => ({
    type: CONVERSATION.UPDATE_SHARED_TO.FAILURE,
    payload: {
        error,
    },
});

export const updateSharedToAllSuccess = (ids) => ({
    type: CONVERSATION.UPDATE_SHARED_TO_ALL.SUCCESS,
    payload: {
        ids,
    },
});

export const deleteConversationSharingForUser = (
    conversationId,
    userId,
    onSuccess,
    onFailure,
) => ({
    type: CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.START,
    payload: {
        conversationId,
        userId,
        onSuccess,
        onFailure,
    },
});

export const deleteConversationSharingForUserSuccess = (id) => ({
    type: CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.SUCCESS,
    payload: {
        id,
    },
});

export const deleteConversationSharingForUserFailure = (error) => ({
    type: CONVERSATION.DELETED_CONVERSATION_SHARING_FOR_USER.FAILURE,
    payload: {
        error,
    },
});

export const generateSummarization = (
    onSuccess,
    onFailure,
    onFinally,
) => ({
    type: CONVERSATION.GENERATE_SUMMARIZATION.START,
    payload: {
        onSuccess,
        onFailure,
        onFinally,
    },
});

export const generateSummarizationSuccess = (summarization) => ({
    type: CONVERSATION.GENERATE_SUMMARIZATION.SUCCESS,
    payload: {
        summarization,
    },
});

export const generateSummarizationFailure = (error) => ({
    type: CONVERSATION.GENERATE_SUMMARIZATION.FAILURE,
    payload: {
        error,
    },
});

export const updateMeetingNote = (meetingNote) => ({
    type: CONVERSATION.UPDATE_MEEETING_NOTE,
    payload: {
        meetingNote,
    },
});

export const updateIsModalVisible = (isModalVisible) => ({
    type: CONVERSATION.UPDATE_IS_MODAL_VISIBLE,
    payload: {
        isModalVisible,
    },
});