/* eslint-disable indent */
import { useCallback, useEffect, useState } from 'react';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { useSelector } from 'react-redux';
import { getZoneSelector } from '@/redux/selectors/calendarSelector';
import dayjs from 'dayjs';

const getDefaultDate = (zone) =>
    dayjs()
        .add(1, 'hour')
        .startOf('hour')
        .tz(zone ?? undefined);

// Created because of need of safe transition from previous state of component
export default function useMeetingInformation(
    conversationId,
    conversationData,
    setConversationTitle,
    conversationTitle,
    event,
    queryDate,
) {
    const zone = useSelector(getZoneSelector);

    // Meeting data
    const meetingId = conversationData?.meeting_id;
    const meetingExternalId = conversationData?.meeting?.external_id;

    const [meetingLink, _setMeetingLink] = useState('');

    // Conversation data

    // Date with zone information included
    const [conversationDate, setConversationDate] = useState(null);
    const [contacts, setContacts] = useState([]);
    const [attendees, setAttendees] = useState([]);
    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [guests, setGuests] = useState([]);

    const setIsoConversationDate = useCallback(
        (isoString) =>
            setConversationDate(
                isoString
                    ? dayjs(isoString).tz(zone ?? undefined)
                    : getDefaultDate(zone),
            ),
        [zone],
    );

    useEffect(() => {
        if (event?.subject) {
            setConversationTitle(event?.subject);
        }

        if (event?.start?.dateTime) {
            setIsoConversationDate(event?.start?.dateTime);
        }

        if (queryDate) {
            let defaultDate = getDefaultDate(zone);

            const [day, month, year] = queryDate.split('-').map((v) => +v);
            if (day && month && year) {
                defaultDate = defaultDate.set('date', day);
                defaultDate = defaultDate.set('month', month - 1);
                defaultDate = defaultDate.set('year', year);
                setConversationDate(defaultDate);
            }
        }
    }, [
        event?.start?.dateTime,
        event?.subject,
        queryDate,
        setConversationDate,
        setConversationTitle,
        setIsoConversationDate,
        zone,
    ]);

    useEffect(() => {
        if (zone && conversationData) {
            const { date, meeting } = conversationData;

            if (!event) {
                setConversationTitle(conversationData.title);
                if (!conversationDate) {
                    setIsoConversationDate(date);
                }
            }

            if (meeting) {
                _setMeetingLink(meeting.url);
                setGuests(meeting.guests);
                setSelectedTeamMembers(
                    meeting.users.map((user) => ({
                        ...user,
                        userId: user.id,
                        src: user.photo,
                    })),
                );
                setAttendees(
                    meeting.attendees.map((attendee) => {
                        const [firstName, lastName] =
                            attendee.attendee_name.split(' ');
                        return {
                            ...attendee,
                            userId: attendee.id,
                            firstName,
                            lastName,
                        };
                    }),
                );
                setContacts(
                    meeting.contacts.map((contact) => ({
                        ...contact,
                        userId: contact.id,
                        firstName: contact.first_name,
                        lastName: contact.last_name,
                    })),
                );
            }
        }
    }, [
        zone,
        conversationData,
        setConversationTitle,
        setIsoConversationDate,
        event,
    ]);

    const setMeetingLink = useCallback(
        async (url) => {
            _setMeetingLink(url);
            await axios.patch(NEXT_ROUTES.MEETING_ID(meetingId), { url });
        },
        [meetingId],
    );

    const storeConversationTitle = useCallback(() => {
        if (conversationTitle) {
            axios.patch(NEXT_ROUTES.CONVERSATIONS_ID(conversationId), {
                title: conversationTitle,
            });
        }
    }, [conversationTitle, conversationId]);

    return {
        meetingId,
        meetingLink,
        meetingExternalId,
        setMeetingLink,
        conversationDate,
        setConversationDate,
        contacts,
        setContacts,
        attendees,
        setAttendees,
        selectedTeamMembers,
        setSelectedTeamMembers,
        guests,
        setGuests,
        storeConversationTitle,
    };
}
