export const RECORDS_ACCOUNTS_FETCH_START = 'RECORDS/COMPANIES_FETCH_START';
export const RECORDS_ACCOUNTS_FETCH_SUCCESS = 'RECORDS/COMPANIES_FETCH_SUCCESS';
export const RECORDS_ACCOUNTS_FETCH_FAILURE = 'RECORDS/COMPANIES_FETCH_FAILURE';
export const RECORDS_COMPANY_EDIT_START = 'RECORDS/COMPANY_EDIT_START';
export const RECORDS_COMPANY_EDIT_SUCCESS = 'RECORDS/COMPANY_EDIT_SUCCESS';
export const RECORDS_COMPANY_EDIT_FAILURE = 'RECORDS/COMPANY_EDIT_FAILURE';
export const RECORDS_COMPANY_ADD_START = 'RECORDS/COMPANY_ADD_START';
export const RECORDS_COMPANY_ADD_SUCCESS = 'RECORDS/COMPANY_ADD_SUCCESS';
export const RECORDS_COMPANY_ADD_FAILURE = 'RECORDS/COMPANY_ADD_FAILURE';

export const fetchCompaniesStart = (options) => ({
    type: RECORDS_ACCOUNTS_FETCH_START,
    payload: {
        options,
    },
});

export const fetchCompaniesSuccess = (companies) => ({
    type: RECORDS_ACCOUNTS_FETCH_SUCCESS,
    payload: {
        companies,
    },
});

export const fetchCompaniesFailure = () => ({
    type: RECORDS_ACCOUNTS_FETCH_FAILURE,
});

export const editCompanyStart = (crmId, id, type, value) => ({
    type: RECORDS_COMPANY_EDIT_START,
    payload: {
        crmId,
        id,
        type,
        value,
    },
});

export const editCompanySuccess = (companies) => ({
    type: RECORDS_COMPANY_EDIT_SUCCESS,
    payload: {
        companies,
    },
});

export const editCompanyFailure = () => ({
    type: RECORDS_COMPANY_EDIT_FAILURE,
});

export const addCompanyStart = (data) => ({
    type: RECORDS_COMPANY_ADD_START,
    payload: {
        data,
    },
});

export const addCompanySuccess = (createdResource) => ({
    type: RECORDS_COMPANY_ADD_SUCCESS,
    payload: {
        data: createdResource,
    },
});

export const addCompanyFailure = (error) => ({
    type: RECORDS_COMPANY_ADD_FAILURE,
    payload: {
        error,
    },
});
