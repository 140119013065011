import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    ASSEMBLY_TRANSCRIPT_FETCH_FAILURE,
    ASSEMBLY_TRANSCRIPT_FETCH_START,
    ASSEMBLY_TRANSCRIPT_FETCH_SUCCESS,
} from '@/redux/actions/assemblyTranscriptAction';

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
};

function teamMembersReducer(state = initialState, action) {
    switch (action.type) {
        case ASSEMBLY_TRANSCRIPT_FETCH_START: {
            return {
                ...state,
                data: null,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case ASSEMBLY_TRANSCRIPT_FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case ASSEMBLY_TRANSCRIPT_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default teamMembersReducer;
