import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, notification, Row, Tooltip } from 'antd';
import { useRouter } from 'next/router';
import { bool } from 'prop-types';
import { CHECK_CIRCLE_TWO_TONE_COLOR, DUSTYGRAY } from '@/constants/colors';
import { NEXT_ROUTES } from '@/constants/routes';
import { providerUpdate } from '@/redux/actions/calendarProviderAction';
import { getCalendarProviderSelector } from '@/redux/selectors/calendarSelector';
import axios from '@/utils/api/axios';
import { AUTH_PROVIDERS } from '@/constants/authProviders';
import { getIsUserCurrentProvider } from '@/redux/selectors/authSelector';
import Logo from '@/public/logos/Versational-Logo-R.svg';
import LogoWrapper from '@/components/logoWrapper';

import {
    ColoredDivA,
    InnerCard,
    LargeArrowRight,
    LargeCheckCircle,
    LayoutContent,
    LogoRow,
    MainLayout,
    OuterCard,
    OutlookImage,
    PaddedCol,
    PageWrapper,
    SmallArrowRight,
} from './calendar.styles';

function OAuth({ simple }) {
    const dispatch = useDispatch();
    const router = useRouter();
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);
    const [email, setEmail] = useState('');
    const [outlookID, setOutlookID] = useState(null);
    const provider = useSelector(getCalendarProviderSelector);
    const isCurrentUserProvider = useSelector((state) =>
        getIsUserCurrentProvider(state, AUTH_PROVIDERS.MICROSOFT),
    );
    const disabled = !!provider && provider !== 'outlook';

    const showError = (message = 'Something went wrong', duration = 5) => {
        notification.error({
            message,
            duration,
        });
    };

    const showSuccess = (message = 'Success!', duration = 5) => {
        notification.success({
            message,
            duration,
        });
    };

    const sendRequest = async () => {
        if (hasToken || disabled) return;

        const { data: redir } = await axios.get(NEXT_ROUTES.MICROSOFT);

        if (redir) {
            window.location = redir;
        } else {
            showError();
        }
    };

    const removeIntegration = async () => {
        if (!isCurrentUserProvider) {
            setLoading(true);
            try {
                await axios.delete(
                    NEXT_ROUTES.OUTLOOK_INTEGRATIONS_ID(outlookID),
                );

                dispatch(providerUpdate(null));
                setHasToken(false);
                setOutlookID(null);
                setEmail('');
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        } else {
            notification.error({
                message: 'Error',
                description:
                    'This calendar integration is associated with your connected account. Please change your connected account to disable this.',
            });
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    NEXT_ROUTES.OUTLOOK_INTEGRATIONS,
                );

                if (data.length > 0) {
                    setOutlookID(data[0].id);
                    setHasToken(true);
                    const { data: meData } = await axios.get(
                        NEXT_ROUTES.OUTLOOK_ME,
                    );

                    if (meData.userPrincipalName) {
                        setEmail(meData.userPrincipalName);
                    }

                    if (window.location.search.indexOf('success') !== -1) {
                        showSuccess();
                        dispatch(providerUpdate('outlook'));
                    }
                } else if (window.location.search.indexOf('error') !== -1) {
                    notification.error({
                        message: 'Error',
                        description:
                            'Error while authenticating through your portal. Please try again',
                    });
                    await router.push('/settings/integrations');
                }
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch, router]);

    return (
        <PageWrapper>
            {!loading && (
                <MainLayout>
                    <LayoutContent>
                        <OuterCard>
                            {!simple && <LogoRow>
                                <Col span={12} offset={6}>
                                    <LogoWrapper>
                                        <Logo />
                                    </LogoWrapper>
                                </Col>
                            </LogoRow>}
                            {!simple && <h2>Welcome</h2>}
                            <p>
                                Get started by syncing your Outlook calendar
                                with Versational®.
                            </p>
                            <Tooltip
                                title={
                                    disabled &&
                                    `Only one calendar can be synced at a time.
                                        Remove your Google integration before adding Outlook.`
                                }
                                color={DUSTYGRAY}
                            >
                                <InnerCard
                                    hasToken={hasToken}
                                    hoverable={!hasToken && !disabled}
                                    onClick={sendRequest}
                                    disabled={disabled}
                                >
                                    <Row>
                                        <Col span={2}>
                                            <OutlookImage src="/images/outlook.png" />
                                        </Col>
                                        <PaddedCol span={17}>
                                            {hasToken ? (
                                                <Fragment>
                                                    <strong>
                                                        Outlook Calendar
                                                    </strong>
                                                    <LargeCheckCircle
                                                        twoToneColor={
                                                            CHECK_CIRCLE_TWO_TONE_COLOR
                                                        }
                                                    />
                                                    <p>
                                                        Versational® is currently
                                                        using{' '}
                                                        <strong>{email}</strong>{' '}
                                                        to discover events.
                                                    </p>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <strong>
                                                        Outlook Calendar
                                                    </strong>
                                                    <p>
                                                        Outlook calendar events
                                                        will automatically
                                                        display on your
                                                        conversations page.
                                                    </p>
                                                </Fragment>
                                            )}
                                        </PaddedCol>
                                        <Col
                                            span={5}
                                            style={{ textAlign: 'right' }}
                                        >
                                            {hasToken && (
                                                <ColoredDivA
                                                    onClick={removeIntegration}
                                                >
                                                    Remove
                                                </ColoredDivA>
                                            )}
                                            {!disabled && !hasToken && (
                                                simple ? <SmallArrowRight /> : <LargeArrowRight />
                                            )}
                                        </Col>
                                    </Row>
                                </InnerCard>
                            </Tooltip>
                        </OuterCard>
                        {!simple && <div>
                            By using Versational® you agree to the{' '}
                            <a
                                href="https://www.versational.ai/agreements/terms-of-service"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Service
                            </a>
                            . We know that information is at the heart of your
                            business, which is why security is our top priority.
                        </div>}
                    </LayoutContent>
                </MainLayout>
            )}
        </PageWrapper>
    );
}

OAuth.propTypes = {
    simple: bool,
};

OAuth.defaultProps = {
    simple: false,
};

export default OAuth;
