import { SPEAKERS_FILTER_VARIANTS } from '@/components/conversations/results/insights/insightsFilter/insightsFiltersData';
import { filterSpeakersByType } from '@/utils/conversations/speakers/filterSpeakers';
import { UserTypes } from '@/constants/conversations/userTypes';

export const getQuoteAuthor = (tsSpeakers, quote) =>
    tsSpeakers.find((s) => s.id === quote?.authorId);

export const filterQuotesByGivenSpeakersRange = (
    quote,
    allSpeakers,
    selectedSpeakersIds,
) => selectedSpeakersIds.includes(getQuoteAuthor(allSpeakers, quote)?.id);

export const filterSpeakers = (insightsFilter, tsSpeakers) => {
    const { speakers: speakersRange } = insightsFilter || {};

    if (Array.isArray(speakersRange)) {
        const matchingSpeakers = ({ id }) =>
            speakersRange.some((v) => v === id);
        return tsSpeakers.filter(matchingSpeakers).map(({ id }) => id);
    }

    const typeForFilter = (() => {
        if (speakersRange === SPEAKERS_FILTER_VARIANTS.everyone)
            return UserTypes.everyone;
        if (speakersRange === SPEAKERS_FILTER_VARIANTS.team)
            return UserTypes.owners;
        if (speakersRange === SPEAKERS_FILTER_VARIANTS.contacts)
            return UserTypes.contacts;
    })();

    return filterSpeakersByType(typeForFilter, tsSpeakers).map(({ id }) => id);
};
