/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/media-has-caption */
import Image from 'next/image';
import { useEffect, useState } from 'react';
import Link from 'next/link';
import { Col, Modal, notification, Spin, Typography, Tooltip } from 'antd';
import { bool } from 'prop-types';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import {
    CenteredDiv,
    FlexDiv,
    InnerCard,
    IntegrationText,
    LargeCheckCircle,
    LayoutContent,
    LogoRow,
    MainLayout,
    PageWrapper,
    StyledButton,
    TeamsAppConnected,
    CancelStyledButton,
    StyledLink,
} from '@/components/integrations/teams/teams.styles';
import axios from '@/utils/api/axios';
import { CHECK_CIRCLE_TWO_TONE_COLOR } from '@/constants/colors';
import { NEXT_ROUTES } from '@/constants/routes';
import Logo from '@/public/logos/Versational-Logo-R.svg';
import LogoWrapper from '@/components/logoWrapper';
import { getUserIdSelector } from '@/redux/selectors/authSelector';
import InfoIcon from '@/components/icons/infoIcon/infoIcon';
import { CheckCircleOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { confirm } = Modal;

function getLoginData() {
    if (localStorage) {
        const callback = localStorage.getItem('callback');

        if (!callback || callback === 'undefined') {
            return false;
        }

        return {
            mode: localStorage.getItem('mode'),
            callback,
            displayName: localStorage.getItem('displayName'),
            objectId: localStorage.getItem('objectId'),
            tenantId: localStorage.getItem('tenantId'),
            preferredUserName: localStorage.getItem('preferredUserName'),
        };
    }
    
    return false;
}

const clearLocalStorage = () => {
    localStorage.removeItem('mode');
    localStorage.removeItem('callback');
    localStorage.removeItem('displayName');
    localStorage.removeItem('objectId');
    localStorage.removeItem('tenantId');
    localStorage.removeItem('preferredUserName');
}

function OAuth({ simple, teamsAppCampaign }) {
    const [loading, setLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [teamsID, setTeamsID] = useState(null);
    const [teamsData, setTeamsData] = useState(null);
    const [outdatedRemoved, setOutdatedRemoved] = useState(false);
    const [loginData, setLoginData] = useState(null);
    const userId = useSelector(getUserIdSelector);
    const [isTeamsAppCalled, setIsTeamsAppCalled] = useState(false);
    const [isTeamsAppIntegrated, setIsTeamsAppIntegrated] = useState(false);

    const handleOk = async () => {
        if (!isTeamsAppCalled && userId) {
            setIsTeamsAppCalled(true);
            try {
                await axios.get(`${NEXT_ROUTES.TEAMS_APP_TENANT}?tenant_id=${loginData.tenantId}`);
                window.location.href = `${loginData.callback}?status=success`;
            } catch (e) {
                try {
                    await axios.post(`${NEXT_ROUTES.TEAMS_APP}`, {
                        owner_id: userId,
                        display_name: loginData.displayName,
                        user_id: loginData.objectId,
                        tenant_id: loginData.tenantId,
                        user_name: loginData.preferredUserName,
                    });

                    window.location.href = `${loginData.callback}?status=success`;
                } catch (err) {
                    window.location.href = `${loginData.callback}?status=error`;
                }
            }
            clearLocalStorage();
        }
    };

    const router = useRouter();
    const teamsApp = router?.query?.teamsApp;
    const outdated = router?.query?.outdated;

    const showError = (message = 'Something went wrong', duration = 5) => {
        notification.error({
            message,
            duration,
        });
    };

    const showSuccess = (message = 'Success!', duration = 5) => {
        notification.success({
            message,
            duration,
        });
    };

    const sendRequest = async () => {
        if (teamsID) return;

        const { data: redir } = await axios.get(NEXT_ROUTES.MICROSOFT_TEAMS);

        if (redir) {
            window.location = redir;
        } else {
            showError();
        }
    };

    const removeIntegration = async () => {
        setLoading(true);
        try {
            await axios.delete(NEXT_ROUTES.INTEGRATIONS_TEAMS_ID(teamsID));

            setTeamsID(null);
            setEmail('');
        } catch (e) {
            showError();
        } finally {
            setLoading(false);
        }
    };

    function showConfirm() {
        confirm({
            title: 'Uninstall Microsoft Teams integration',
            onOk() {
                removeIntegration();
            },
        });
    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    NEXT_ROUTES.INTEGRATIONS_TEAMS,
                );

                if (data.length > 0) {
                    setTeamsID(data[0].id);
                    setTeamsData(data[0]);
                    const { data: meData } = await axios.get(
                        NEXT_ROUTES.MICROSOFT_TEAMS_ME,
                    );

                    if (meData.userPrincipalName) {
                        setEmail(meData.userPrincipalName);
                    }
                    if (window.location.search.indexOf('success') !== -1) {
                        showSuccess();
                    }
                } else if (window.location.search.indexOf('error') !== -1) {
                    if (window.location.search.indexOf('duplicate') !== -1) {
                        showError(
                            'This Microsoft Teams account is already associated with a Versational® account.',
                            15,
                        );
                    } else {
                        showError();
                    }
                }
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {

        if (outdated && teamsID && !outdatedRemoved && loginData?.callback) {
            removeIntegration();
            setOutdatedRemoved(true);
        }

    }, [teamsApp, outdated, teamsID, loginData])

    useEffect(() => {
    
        if (typeof window !== "undefined") {
            const getData = getLoginData();
    
            // Only update the state if the data has actually changed
            if (getData && JSON.stringify(getData) !== JSON.stringify(loginData)) {
                setLoginData(getData);
            }
        }
    }, [userId, loginData]); // Dependency on userId and loginData
    
    useEffect(() => {
    
        if (loginData && teamsData && userId && teamsData.created_at > '2023-12-17T16:00:12.502Z') {
            handleOk();
        }
    }, [teamsData, handleOk, loginData, userId]); // Include loginData in the dependency array
    

    useEffect(() => {
        const getTeamsApp = async () => {
            try {
                const { data } = await axios.get(NEXT_ROUTES.TEAMS_APP);

                if (data) {
                    setIsTeamsAppIntegrated(true);
                } else {
                    setIsTeamsAppIntegrated(false);
                }
            } catch (e) {
                setIsTeamsAppIntegrated(false);
            }
        }
        
        getTeamsApp();
    }, [])

    if (loading) {
        return (
            <CenteredDiv>
                <Spin size="large" data-testid="spinner" />
            </CenteredDiv>
        );
    }

    return (
        <PageWrapper>
            <MainLayout style={{margin: loginData?.callback ? '10% 0 0 0' : '10% 10% 0 10%' }}>
                <LayoutContent style={{width: loginData?.callback ? '100%' : '80%' }}>
                    {!simple && <LogoRow>
                        <Col span={10} offset={6}>
                            <LogoWrapper>
                                <Logo />
                            </LogoWrapper>
                        </Col>
                    </LogoRow>}
                    {!simple && <Title level={2}>{loginData?.callback ? 'Link accounts' : 'Welcome'}</Title>}
                    <p>
                        {teamsID ? 'Your account is connected to Microsoft Teams.' : 'Get started by connecting your Microsoft Teams account.'}
                    </p>
                    
                    <InnerCard hoverable>
                        <div>
                            <FlexDiv>
                                <Image
                                    src="/logos/microsoft-teams-logo.svg"
                                    width="73"
                                    height="50"
                                    alt="Microsoft Teams logo"
                                />
                                <IntegrationText>
                                    <strong>
                                        {teamsAppCampaign && simple ? 'Microsoft Teams App' : 'Microsoft Teams Integration'}{' '}
                                        {teamsID && (
                                            <LargeCheckCircle
                                                data-testid="check-circle"
                                                twoToneColor={
                                                    CHECK_CIRCLE_TWO_TONE_COLOR
                                                }
                                            />
                                        )}
                                    </strong>
                                    {teamsID && !isTeamsAppIntegrated ? (
                                        <div data-testid="connected-message" style={{paddingRight: '1rem'}}>
                                            Your Microsoft account{' '}
                                            <Link href="/">{email}</Link> is
                                            connected.
                                            {!loginData?.callback && <div><StyledLink href="https://appsource.microsoft.com/en-US/product/office/WA200006631?exp=ubp8&tab=Overview" title="Go to the link and click 'Get it now'" target="_blank" rel="noreferrer" style={{ marginLeft: '15px' }}>
                                                <TeamsAppConnected>
                                                    Connect Teams App
                                                </TeamsAppConnected> 
                                            </StyledLink></div>}
                                        </div>
                                    ) : null}
                                    {teamsID && isTeamsAppIntegrated ? (
                                        <div data-testid="connected-message" style={{paddingRight: '1rem'}}>
                                            Your Microsoft account{' '}
                                            <Link href="/">{email}</Link> is
                                            connected.
                                            <TeamsAppConnected>
                                                Teams App Connected
                                                <CheckCircleOutlined />
                                            </TeamsAppConnected>
                                        </div>
                                    ) : null}
                                </IntegrationText>
                            </FlexDiv>
                        </div>
                        <div>
                            {teamsID ? (
                                <StyledButton
                                    type="secondary"
                                    size="large"
                                    onClick={showConfirm}
                                    data-testid="disconnect"
                                >
                                    Disconnect
                                </StyledButton>
                            ) : (
                                teamsAppCampaign && simple ? <StyledButton
                                    onClick={() => {
                                        localStorage.removeItem('teamsAppCampaign');
                                        window.location.href = `https://appsource.microsoft.com/en-US/product/office/WA200006631?exp=ubp8&tab=Overview`;
                                    }}
                                    type="primary"
                                    size="large"
                                >
                                    Get the app
                                </StyledButton> : <StyledButton
                                    onClick={sendRequest}
                                    type="primary"
                                    size="large"
                                >
                                    {loginData?.callback ? 'Link accounts' : 'Connect'}
                                </StyledButton>
                            )}
                        </div>
                    </InnerCard>

                    {!teamsID && !loginData?.callback ? <p style={{fontSize: '14px', marginTop: '2rem'}}>
                        <span>You&apos;ll need an <Tooltip title="Office 365 Enterprise E1, E3, E5, F3, A1, A3, A5, M365 Business, Business Premium, or Business Essentials"><b style={{marginRight: '0.25rem'}}>eligible Microsoft subscription</b><span style={{position: 'relative', top: '1px'}}><InfoIcon /></span></Tooltip> to use Teams integration.</span>
                    </p> : null}

                    {loginData?.callback ? 
                        <p style={{fontSize: '12px', marginTop: '3rem'}}>You&apos;re in the process of connecting Microsoft Teams App. <CancelStyledButton type="text" onClick={() => {
                            clearLocalStorage();
                            window.location.reload();
                        }}>Cancel the process</CancelStyledButton>?</p> : 
                        null}
                </LayoutContent>
            </MainLayout>
        </PageWrapper>
    );
}

OAuth.propTypes = {
    simple: bool,
    teamsAppCampaign: bool,
};

OAuth.defaultProps = {
    simple: false,
    teamsAppCampaign: false,
};

export default OAuth;
