import { FETCH_STATUS } from '@/constants/fetchStatus';
import { COMPANY } from '@/redux/actions/companyAction';

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
};

function companyReducer(state = initialState, action) {
    switch (action.type) {
        case COMPANY.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case COMPANY.FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case COMPANY.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case COMPANY.UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case COMPANY.UPDATE_SUCCESS: {
            return {
                data: { ...state.data, ...action.payload.data },
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case COMPANY.UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case COMPANY.UPDATE_PHOTO.START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
            };
        }
        case COMPANY.UPDATE_PHOTO.SUCCESS: {
            return {
                ...state,
                data: { ...state.data, photo: action.payload.photoUrl },
                status: FETCH_STATUS.UPDATE_SUCCESS,
            };
        }
        case COMPANY.UPDATE_PHOTO.FAILURE: {
            return {
                ...state,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default companyReducer;
