import {
    SET_SEARCH_VALUE,
    RESET_SEARCH,
    SET_IS_OPEN,
    SET_IS_AUTOCOMPLETE_OPEN,
    SET_OCCURRENCE,
} from '@/redux/actions/conversation/searchAction';
import { INSIGHTS_SET_QUOTE_ACTIVE } from '@/redux/actions/conversation/insightsViewAction';

const initialState = {
    isOpen: false,
    searchTranscriptValue: '',
    isAutoCompleteOpen: false,
    occurrence: 0,
};

function searchReducer(state = initialState, action) {
    switch (action.type) {
        case SET_SEARCH_VALUE: {
            const { value } = action.payload.data;
            return {
                ...state,
                searchTranscriptValue: value,
                isOpen: true,
                occurrence: 0,
            };
        }
        case SET_IS_OPEN: {
            if (state.searchTranscriptValue) {
                return {
                    ...state,
                    isOpen: true,
                };
            }
            return {
                ...state,
                isOpen: action.payload.data,
            };
        }
        case SET_IS_AUTOCOMPLETE_OPEN: {
            return {
                ...state,
                isAutoCompleteOpen: action.payload.data,
            };
        }
        case SET_OCCURRENCE: {
            return {
                ...state,
                occurrence: action.payload.data,
            };
        }
        case RESET_SEARCH:
        case INSIGHTS_SET_QUOTE_ACTIVE: {
            return initialState;
        }
        default:
            return state;
    }
}

export default searchReducer;
