import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { COMPANY_KEY } from '@/constants/reduxKeys';
import companyReducer from '@/redux/reducers/companyReducer';
import companySaga from '@/redux/sagas/companySaga';
import { useDispatch } from 'react-redux';
import { useEffect, useRef } from 'react';
import { fetchCompanySuccess } from '@/redux/actions/companyAction';

// eslint-disable-next-line react/prop-types
export default function CompanyInjector({ company }) {
    useInjectReducer({ key: COMPANY_KEY, reducer: companyReducer });
    useInjectSaga({ key: COMPANY_KEY, saga: companySaga });
    const callRef = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (company && !callRef.current) {
            dispatch(fetchCompanySuccess(company));
            callRef.current = true;
        }
    }, [dispatch, company]);

    return null;
}
