import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('RECORDINGS');

export const RECORDINGS = {
    ...creator.fetch(),
    SEARCH: creator.rawCustom('SEARCH'),
    SEARCH_FETCH: creator.custom('SEARCH_FETCH'),
    UPDATE: creator.rawCustom('UPDATE'),
    SET_ERROR: creator.rawCustom('SET_ERROR'),
    SET_LOADING: creator.rawCustom('SET_LOADING'),
    TAGS_UPDATE: creator.custom('TAGS_UPDATE'),
    DATE_UPDATE: creator.custom('DATE_UPDATE'),
    CONVERSATION_TITLE_UPDATE: creator.custom('CONVERSATION_TITLE_UPDATE'),
    REMOVE: creator.custom('REMOVE'),
    UPDATE_PAGINATION: creator.rawCustom('UPDATE_PAGINATION'),
    UPDATE_PAGINATION_TOTAL: creator.rawCustom('UPDATE_PAGINATION_TOTAL'),
    UPDATE_SHARED_TO: creator.rawCustom('UPDATE_SHARED_TO'),
    UPDATE_REMOVE_SHARED_TO: creator.rawCustom('UPDATE_REMOVE_SHARED_TO'),
    SIMPLIFIED: creator.custom('SIMPLIFIED'),
    UPDATE_MEETING_NOTE: creator.rawCustom('UPDATE_MEETING_NOTE'),
};

export const setLoadingTrue = () => ({
    type: RECORDINGS.SET_LOADING, 
    payload: true,
})

export const setLoadingFalse = () => ({
    type: RECORDINGS.SET_LOADING, 
    payload: false,
})

export const fetchRecordingsStart = (
    currentPage,
    pageSize,
    order,
    filter,
    shouldStartFromFirstPage,
) => ({
    type: RECORDINGS.FETCH_START,
    payload: {
        currentPage,
        pageSize,
        order,
        filter,
        shouldStartFromFirstPage,
    },
});

export const fetchRecordingsSuccess = (recordings, count) => ({
    type: RECORDINGS.FETCH_SUCCESS,
    payload: { recordings, count },
});

export const fetchRecordingsFailure = (error) => ({
    type: RECORDINGS.FETCH_FAILURE,
    payload: { error },
});

export const fetchSearchRecordingsStart = (
    searchValue,
    currentPage,
    pageSize,
    order,
    filter,
) => ({
    type: RECORDINGS.SEARCH_FETCH.START,
    payload: {
        searchValue,
        currentPage,
        pageSize,
        order,
        filter,
    },
});

export const fetchSearchRecordingsSuccess = (searchData, recordings) => ({
    type: RECORDINGS.SEARCH_FETCH.SUCCESS,
    payload: { searchData, recordings },
});

export const fetchSearchRecordingsFailure = (error) => ({
    type: RECORDINGS.SEARCH_FETCH.FAILURE,
    payload: { error },
});

export const updateRecording = (recording) => ({
    type: RECORDINGS.UPDATE,
    payload: { recording },
});

export const updateTags = (recordingId, tags) => ({
    type: RECORDINGS.TAGS_UPDATE.START,
    payload: { recordingId, tags },
});

export const updateTagsSuccess = (recordingId, tags) => ({
    type: RECORDINGS.TAGS_UPDATE.SUCCESS,
    payload: { recordingId, tags },
});

export const updateTagsFailure = (recordingId, tags) => ({
    type: RECORDINGS.TAGS_UPDATE.FAILURE,
    payload: { recordingId, tags },
});

export const updateDate = (recordingId, date) => ({
    type: RECORDINGS.DATE_UPDATE.START,
    payload: { recordingId, date },
});

export const updateDateSuccess = (recordingId, date) => ({
    type: RECORDINGS.DATE_UPDATE.SUCCESS,
    payload: { recordingId, date },
});

export const updateDateFailure = (error) => ({
    type: RECORDINGS.DATE_UPDATE.SUCCESS,
    payload: { error },
});

export const updateConversationTitle = (recordingId, title) => ({
    type: RECORDINGS.CONVERSATION_TITLE_UPDATE.START,
    payload: { recordingId, title },
});

export const updateConversationTitleSuccess = (recordingId, title) => ({
    type: RECORDINGS.CONVERSATION_TITLE_UPDATE.SUCCESS,
    payload: { recordingId, title },
});

export const updateConversationTitleFailure = (error) => ({
    type: RECORDINGS.CONVERSATION_TITLE_UPDATE.SUCCESS,
    payload: { error },
});

export const removeRecordingStart = (recording) => ({
    type: RECORDINGS.REMOVE.START,
    payload: { recording },
});

export const removeRecordingSuccess = (id) => ({
    type: RECORDINGS.REMOVE.SUCCESS,
    payload: { id },
});

export const removeRecordingFailure = (error) => ({
    type: RECORDINGS.REMOVE.FAILURE,
    payload: { error },
});

export const setError = (error) => ({
    type: RECORDINGS.SET_ERROR,
    payload: error,
});

export const updatePagination = (currentPage, pageSize) => ({
    type: RECORDINGS.UPDATE_PAGINATION,
    payload: {
        currentPage,
        pageSize,
    },
});

export const updatePaginationTotal = (addItems) => ({
    type: RECORDINGS.UPDATE_PAGINATION_TOTAL,
    payload: {
        addItems,
    },
});

export const setSearchValue = (searchValue) => ({
    type: RECORDINGS.SEARCH,
    payload: {
        searchValue,
    },
});

export const updateRecordingSharedTo = (conversationId, sharedTo) => ({
    type: RECORDINGS.UPDATE_SHARED_TO,
    payload: {
        conversationId,
        sharedTo,
    },
});

export const updateRecordingRemoveSharedTo = (conversationId, removeSharedTo) => ({
    type: RECORDINGS.UPDATE_REMOVE_SHARED_TO,
    payload: {
        conversationId,
        removeSharedTo,
    },
});

export const fetchRecordingsSimplifiedStart = (
    currentPage,
    pageSize,
    order,
    filter,
    shouldStartFromFirstPage,
) => ({
    type: RECORDINGS.SIMPLIFIED.START,
    payload: {
        currentPage,
        pageSize,
        order,
        filter,
        shouldStartFromFirstPage,
    },
});

export const fetchRecordingsSimplifiedSuccess = (recordings, count) => ({
    type: RECORDINGS.SIMPLIFIED.SUCCESS,
    payload: { recordings, count },
});

export const fetchRecordingsSimplifiedFailure = (error) => ({
    type: RECORDINGS.SIMPLIFIED.FAILURE,
    payload: { error },
});

export const updateRecordingMeetingNote = (conversationId, meetingNote) => ({
    type: RECORDINGS.UPDATE_MEETING_NOTE,
    payload: {
        conversationId,
        meetingNote,
    },
});