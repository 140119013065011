export const FETCH_STATUS = Object.freeze({
    IDLE: 'IDLE',
    INITIAL_LOADING: 'INITIAL_LOADING',
    INITIAL_ERROR: 'INITIAL_ERROR',
    INITIAL_LOADED: 'INITIAL_LOADED',
    REFETCH_LOADING: 'REFETCH_LOADING',
    REFETCH_ERROR: 'REFETCH_ERROR',
    REFETCH_SUCCESS: 'REFETCH_SUCCESS',
    UPDATE_LOADING: 'UPDATE_LOADING',
    UPDATE_ERROR: 'UPDATE_ERROR',
    UPDATE_SUCCESS: 'UPDATE_SUCCESS',
    CREATE_LOADING: 'CREATE_LOADING',
    CREATE_ERROR: 'CREATE_ERROR',
    CREATE_SUCCESS: 'CREATE_SUCCESS',
    DELETE_LOADING: 'DELETE_LOADING',
    DELETE_ERROR: 'DELETE_ERROR',
    DELETE_SUCCESS: 'DELETE_SUCCESS',
});
