import { useSelector } from 'react-redux';
import { Avatar, Skeleton } from 'antd';
import {
    getUserInfoLoadingSelector,
    getUserPhoto,
    getUserInitials,
    getUserFullName,
    getUserTitle,
} from '@/redux/selectors/authSelector';
import {
    ProfileContainer,
    InfoContainer,
    Name,
    Position,
} from '@/components/settings/layout/profile.styles';
import { number } from 'prop-types';

function Profile() {
    const loading = useSelector(getUserInfoLoadingSelector);

    return (
        <Skeleton
            loading={loading}
            active
            avatar={{ size: 66, shape: 'circle' }}
            paragraph={false}
        >
            <ProfileContainer>
                <ProfilePhoto />
                <ProfileDescription />
            </ProfileContainer>
        </Skeleton>
    );
}

function ProfileDescription() {
    const fullName = useSelector(getUserFullName);
    const title = useSelector(getUserTitle);
    return (
        <InfoContainer>
            <Name>{fullName}</Name>
            <Position>{title}</Position>
        </InfoContainer>
    );
}

export function ProfilePhoto({ size }) {
    const photo = useSelector(getUserPhoto);
    const initials = useSelector(getUserInitials);
    return (
        <Avatar size={size} src={photo} alt="Profile photo">
            {initials}
        </Avatar>
    );
}

ProfilePhoto.defaultProps = {
    size: 66,
};

ProfilePhoto.propTypes = {
    size: number,
};

export default Profile;
