import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LOGIN_PROCESS_KEY } from '@/constants/reduxKeys';
import loginProcessReducer from '@/redux/reducers/loginProcessReducer';
import loginProcessSaga from '@/redux/sagas/loginProcessSaga';
import { resetToSignInPage } from '@/redux/actions/loginProcessAction';

// eslint-disable-next-line react/prop-types
export default function LoginProcessInjector({ isAuthorized }) {
    useInjectReducer({
        key: LOGIN_PROCESS_KEY,
        reducer: loginProcessReducer,
    });
    useInjectSaga({ key: LOGIN_PROCESS_KEY, saga: loginProcessSaga });

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isAuthorized) {
            dispatch(resetToSignInPage());
        }
    }, [dispatch, isAuthorized]);

    return null;
}
