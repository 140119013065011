import { getNumbersRangeAsText } from '@/utils/numbers/inWords';

// Range should be extended if any higher value would be seen on keywords results
const numbersAsText = getNumbersRangeAsText(10);

export const filterOutNumbers = ({ name }) =>
    !Number.isInteger(parseInt(name, 10)) &&
    !numbersAsText.some((v) => name.toLowerCase() === v.toLowerCase());

export const sortBySalience = (a, b) => b.salience - a.salience;

export const filterOutCommonAndOther = ({ type }) =>
    type !== 'COMMON' && type !== 'OTHER';

export const filterOutUnexpectedChars = ({ name }) => {
    // Convert to regexp negative lookahead if there will be any performance issues
    const unexpectedChars = [',', '.', '-', "'"];
    return !unexpectedChars.some((v) => name.includes(v));
};

export const filterOutSingleAndDoubleLetters = ({ name }) => name.length > 2;

export const reducePlurals = (acc, curr) => {
    const pluralReplacedArray = acc.map((v) =>
        v.name.toLowerCase() === `${curr.name.toLowerCase()}s` ? curr : v,
    );

    return pluralReplacedArray.some(
        ({ name }) => name.toLowerCase() === `${curr.name.toLowerCase()}s`,
    )
        ? [...pluralReplacedArray]
        : [...pluralReplacedArray, curr];
};

const getHeighestSalienceItem = (item1, item2) =>
    (item1?.salience || -9999) < item2.salience ? item2 : item1;

export const reduceDistinctEntitiesAddCount = (acc, curr, index, array) => {
    const lowercaseName = curr.name.toLowerCase();

    const prevForName = acc[lowercaseName];

    acc[lowercaseName] = {
        data: getHeighestSalienceItem(prevForName, curr),
        count: (prevForName?.count || 0) + 1,
    };

    if (index === array.length - 1) {
        return Object.keys(acc)
            .map((key) => acc[key])
            .map(({ data, count }) => ({ ...data, count }));
    }

    return acc;
};

const wordsContainName = (name) => (item) => item.words.includes(name);

export const referencedInContent = (content) => (entity) =>
    content.some(wordsContainName(entity.name));

export const removeDuplicates = (acc, curr) => {
    const accContainItemWithGivenName = acc.some(
        ({ name }) => name.toLowerCase() === curr.name.toLowerCase(),
    );
    if (!accContainItemWithGivenName) {
        acc.push(curr);
    }
    return acc;
};

const getNumberOfOccurrences = (toSearch, word) =>
    (toSearch.match(new RegExp(word, 'gi')) || []).length;

export const countOccurrences = (allWords) => (curr) => {
    const count = allWords.reduce(
        (acc, word) => acc + getNumberOfOccurrences(word, curr.name),
        0,
    );

    return { ...curr, count };
};

export const filterOut = (content) => (entity) =>
    filterOutNumbers(entity) &&
    filterOutCommonAndOther(entity) &&
    filterOutUnexpectedChars(entity) &&
    filterOutSingleAndDoubleLetters(entity) &&
    referencedInContent(content)(entity);
