import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getIsAuthorized } from '@/redux/selectors/authSelector';
import {
    getIsTeamMembersInIdle,
    getTeamMembers,
    getTeamMembersInitialRefreshLoading,
    getTeamMembersLoadingError,
} from '@/redux/selectors/teamMembersSelector';
import { fetchTeamMembers } from '@/redux/actions/teamMembersAction';

export default function useTeamMembers() {
    const dispatch = useDispatch();

    const data = useSelector(getTeamMembers) ?? [];
    const loading = useSelector(getTeamMembersInitialRefreshLoading);
    const idle = useSelector(getIsTeamMembersInIdle);
    const error = useSelector(getTeamMembersLoadingError);

    const isAuthorized = useSelector(getIsAuthorized);

    useEffect(() => {
        if (isAuthorized && idle) {
            dispatch(fetchTeamMembers());
        }
    }, [dispatch, isAuthorized, idle]);

    return {
        loading,
        data,
        error,
    };
}
