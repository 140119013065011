/* eslint-disable no-lonely-if */
import { NEXT_ROUTES, ROUTES } from '@/constants/routes';
import {
    SIGNUP_PROCESS_CHOOSE_PROVIDER,
    SIGNUP_PROCESS_FAILED,
    SIGNUP_PROCESS_GET_PROVIDER_INFORMATION,
    SIGNUP_PROCESS_GOOGLE_SUCCESSFUL,
    SIGNUP_PROCESS_REGISTER,
    SIGNUP_PROCESS_REGISTER_BETA,
    SIGNUP_PROCESS_REGISTER_INVITED,
    SIGNUP_PROCESS_SUCCESSFUL,
    SIGNUP_PROCESS_THROUGH_EMAIL,
    chooseProviderFailed,
    onCreateAccountDoesExist,
    onCreateAccountDoesNotExist,
} from '@/redux/actions/signupProcessAction';
import {
    SUBSCRIPTION_PLANS_FETCH_FAILURE,
    SUBSCRIPTION_PLANS_FETCH_SUCCESS,
    fetchSubscriptionPlans,
} from '@/redux/actions/subscriptionPlansAction';
import { call, put, select, take, takeEvery } from 'redux-saga/effects';

import { AUTH_PROVIDERS } from '@/constants/authProviders';
import Router from 'next/router';
import axios from '@/utils/api/axios';
import { getTrialPlan, getTrialPlanBeta, getTrialPlanPMAlly, getTrialPlanPMAllyFree, getTrialPlanPMAllyIndividual, getTrialPlanPMAllyTeams, getTrialPlanPMAllyIndividualAnnual, getTrialPlanPMAllyTeamsAnnual } from '@/redux/selectors/subscriptionPlansSelector';
import { logIn } from '@/redux/actions/authAction';
import { notification, Button } from 'antd';
import { v4 as uuidv4 } from 'uuid';

function getPlanCodeFromLocalStorage() {
    return localStorage.getItem('planCode');
}

function getTeamsAppCampaignFromLocalStorage() {
    return localStorage.getItem('teamsAppCampaign');
}

export function* signUpWithProvider(action) {
    try {
        const { data: redirectionUrl } = yield call(
            axios,
            NEXT_ROUTES.SIGNUP_PROVIDER(
                action.payload.provider,
                action.payload.inviteId,
            ),
        );
        yield call([window.location, window.location.assign], redirectionUrl);
    } catch (error) {
        yield put(chooseProviderFailed());
        yield call(notification.error, {
            message: 'Error',
            description: 'Whoops! Could not connect to the identity provider!',
        });
    }
}

export function* invitesErrorHandling(error) {
    if (error?.response?.data === 'INVALID_INVITE_ID') {
        yield call(notification.error, {
            message: 'Error',
            description: 'Please make sure that you have used correct url',
        });
    } else if (error?.response?.data === 'INVALID_EMAIL') {
        yield call(notification.error, {
            message: 'Error',
            description:
                'Please sign up with the same email used for your Versational invite.',
        });
    } else if (error?.response?.data === 'INVITE_REVOKED') {
        yield call(notification.error, {
            message: 'Error',
            description:
                'Your invite has been revoked. Please contact your team admin.',
        });
    } else if (error?.response?.data === 'ALREADY_SIGNED_UP') {
        yield call(notification.error, {
            message: 'Error',
            description: 'User already exists in the system.',
        });
    } else {
        if (error?.message?.includes('WRONG_PROVIDER')) {
            let providerName = '';
            if (error?.message?.includes('versational')) {
                providerName = ' (email)';
            }

            yield call(notification.error, {
                message: 'Wrong provider',
                description: `Please use the same provider that you used for the sign up${providerName}.`,
                duration: 8,
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during sign up process. Please try again.',
            });
        }
    }
}

export function* invitesVerify(email, inviteId) {
    yield call(axios, NEXT_ROUTES.INVITES_VERIFY(email, inviteId));
}

export function* checkIfUserExists(action) {
    try {
        yield call(axios, NEXT_ROUTES.SIGNUP_USER_EXIST(action.payload.email));
        yield put(onCreateAccountDoesNotExist(action.payload.email));
    } catch (error) {
        if (error.response?.status === 400) {
            const key = `open${Date.now()}`;
            const btn = (
                <Button type="primary" size="small" href="https://www.versational.ai/gpt-conversation-intelligence-project-meetings">
                    Try Again
                </Button>
            );

            yield call(
                action.payload.onError,
                'User already exists in the system',
            );
            yield call(notification.error, {
                message: 'Error',
                description: <>This email is already registered. Please try again with a different email address. <br /><br />Already signed up? <Button onClick={() => {
                    notification.close(key);
                    Router.push('/login');
                }} style={{padding: '0'}} type="text">Sign in</Button>.</>,
                btn: window.location.href.includes('landing') ? btn : null,
                key,
                duration: 0,
            });
        } else if (error.response?.status === 418) {
            yield call(action.payload.onError, error.response.data.error);
            yield call(notification.error, {
                message: 'Error',
                description:
                    'This email is not valid. Please go back and try again.',
            });
        } else {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during sign up process. Please try again.',
            });
        }
        yield put(onCreateAccountDoesExist());
    }
}

export function* getTrialPlanCode() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlan);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodeBeta() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanBeta);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodePMAlly() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanPMAlly);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodePMAllyFree() {
    yield put(fetchSubscriptionPlans());

    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);

    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanPMAllyFree);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodePMAllyIndividual() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanPMAllyIndividual);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodePMAllyTeams() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanPMAllyTeams);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodePMAllyIndividualAnnual() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanPMAllyIndividualAnnual);
    }
    throw new Error('Could not get trial plan code');
}

export function* getTrialPlanCodePMAllyTeamsAnnual() {
    yield put(fetchSubscriptionPlans());
    const yieldAction = yield take([
        SUBSCRIPTION_PLANS_FETCH_SUCCESS,
        SUBSCRIPTION_PLANS_FETCH_FAILURE,
    ]);
    if (yieldAction.type === SUBSCRIPTION_PLANS_FETCH_SUCCESS) {
        return yield select(getTrialPlanPMAllyTeamsAnnual);
    }
    throw new Error('Could not get trial plan code');
}

export function* getOnRegisterExternal(action) {
    const provider = yield select((state) => state.signup.provider);

    try {
        let selectedPlan = yield select((state) => state.signup.selectedPlan);
        const teamsAppCampaign = yield call(getTeamsAppCampaignFromLocalStorage);
        let planCode = '';

        if (!selectedPlan) {
            selectedPlan = yield call(getPlanCodeFromLocalStorage);
        }

        if (selectedPlan?.includes('VT') && selectedPlan?.includes('ANNUAL')) {
            planCode = yield call(getTrialPlanCodePMAllyTeamsAnnual);
        } else if (selectedPlan?.includes('VT')) {
            planCode = yield call(getTrialPlanCodePMAllyTeams);
        } else if (selectedPlan?.includes('VI') && selectedPlan?.includes('ANNUAL')) {
            planCode = yield call(getTrialPlanCodePMAllyIndividualAnnual);
        } else if (selectedPlan?.includes('VI')) {
            planCode = yield call(getTrialPlanCodePMAllyIndividual);
        } else {
            planCode = yield call(getTrialPlanCodePMAllyFree);
        }

        switch (provider.name) {
            case AUTH_PROVIDERS.GOOGLE:
                yield call(
                    axios.post,
                    NEXT_ROUTES.SIGNUP_EXTERNAL_PROVIDER(AUTH_PROVIDERS.GOOGLE),
                    {
                        code: provider.response.code,
                        invite_id: action.payload.inviteId,
                        planCode,
                        conversationId: action.payload.conversationId,
                    },
                );
                break;
            case AUTH_PROVIDERS.MICROSOFT:
                yield call(
                    axios.post,
                    NEXT_ROUTES.SIGNUP_EXTERNAL_PROVIDER(
                        AUTH_PROVIDERS.MICROSOFT,
                    ),
                    {
                        invite_id: action.payload.inviteId,
                        planCode,
                        conversationId: action.payload.conversationId,
                        ...provider,
                    },
                );
                break;
            default:
                break;
        }
        yield put(logIn());

        if (action.payload.conversationId) {
            yield call(
                Router.replace,
                ROUTES.CONVERSATIONS_RESULTS(action.payload.conversationId),
            );
        } else {
            yield call(Router.replace, `${ROUTES.HOME}?first_session=${uuidv4()}${action.payload.company ? ' &pmally=true' : ''}${teamsAppCampaign ? ' &teamsAppCampaign=true' : ''}`);
        }

        yield call(notification.success, {
            message: 'Success',
            description: 'Authenticated successfully',
        });

        yield put({
            type: SIGNUP_PROCESS_SUCCESSFUL,
        });
    } catch (error) {
        yield call(invitesErrorHandling, error);
        yield put({
            type: SIGNUP_PROCESS_FAILED,
        });
    }
}

export function* getOnRegisterInvited(action) {
    try {
        yield call(axios, NEXT_ROUTES.SIGNUP_USER_EXIST(action.payload.email));

        yield call(
            invitesVerify,
            action.payload.email,
            action.payload.inviteId,
        );

        yield call(axios.post, NEXT_ROUTES.SIGNUP, {
            email: action.payload.email,
            password: action.payload.password,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            invite_id: action.payload.inviteId,
            reCaptchaToken: action.payload.reCaptchaToken,
        });

        yield put(logIn());

        yield call(Router.replace, `${ROUTES.HOME}?first_session=${uuidv4()}`);

        yield call(notification.success, {
            message: 'Success',
            description: 'Registered successfully',
        });

        yield put({
            type: SIGNUP_PROCESS_SUCCESSFUL,
        });
    } catch (error) {
        yield call(invitesErrorHandling, error);
        yield put({
            type: SIGNUP_PROCESS_FAILED,
        });
    }
}

export function* getOnRegisterInternal(action) {
    try {
        const provider = yield select((state) => state.signup.provider);

        const planCode = yield call(getTrialPlanCode);
        yield call(axios.post, NEXT_ROUTES.SIGNUP, {
            email: provider.email,
            password: action.payload.password,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            companyName: action.payload.company,
            planCode,
            reCaptchaToken: action.payload.reCaptchaToken,
            conversationId: action.payload.conversationId,
        });
        yield put(logIn());

        yield call(Router.replace, `${ROUTES.HOME}?first_session=${uuidv4()}`);

        yield call(notification.success, {
            message: 'Success',
            description: 'Registered successfully',
        });

        yield put({
            type: SIGNUP_PROCESS_SUCCESSFUL,
        });
    } catch (error) {
        yield call(notification.error, {
            message: 'Error',
            description:
                'There was error during sign up process. Please try again.',
        });
        yield put({
            type: SIGNUP_PROCESS_FAILED,
        });
    }
}

export function* getOnRegisterInternalBeta(action) {
    try {
        const provider = yield select((state) => state.signup.provider);
        const teamsAppCampaign = yield call(getTeamsAppCampaignFromLocalStorage);

        let planCode = yield call(getTrialPlanCodeBeta);

        if (action.payload.company) {
            if (action.payload.selectedPlan.includes('VT') && action.payload.selectedPlan.includes('ANNUAL')) {
                planCode = yield call(getTrialPlanCodePMAllyTeamsAnnual);
            } else if (action.payload.selectedPlan.includes('VT')) {
                planCode = yield call(getTrialPlanCodePMAllyTeams);
            } else if (action.payload.selectedPlan.includes('VI') && action.payload.selectedPlan.includes('ANNUAL')) {
                planCode = yield call(getTrialPlanCodePMAllyIndividualAnnual);
            } else if (action.payload.selectedPlan.includes('VI')) {
                planCode = yield call(getTrialPlanCodePMAllyIndividual);
            } else {
                planCode = yield call(getTrialPlanCodePMAllyFree);
            }
        }

        yield call(axios.post, NEXT_ROUTES.SIGNUP, {
            email: provider.email,
            password: action.payload.password,
            firstName: action.payload.firstName,
            lastName: action.payload.lastName,
            planCode,
            reCaptchaToken: action.payload.reCaptchaToken,
            company: action.payload.company,
            teamsize: action.payload.teamsize,
            conversationId: action.payload.conversationId,
        });

        yield put(logIn());

        if (action.payload.conversationId) {
            yield call(
                Router.replace,
                ROUTES.CONVERSATIONS_RESULTS(action.payload.conversationId),
            );
        } else {
            yield call(Router.replace, `${ROUTES.HOME}?first_session=${uuidv4()}${action.payload.company ? ' &pmally=true' : ''}${teamsAppCampaign ? ' &teamsAppCampaign=true' : ''}`);
        }

        if (!action.payload.company) {
            yield call(notification.success, {
                message: 'Success',
                description: 'Registered successfully',
            });
        }

        yield put({
            type: SIGNUP_PROCESS_SUCCESSFUL,
        });
    } catch (error) {
        if (!action.payload.company) {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during sign up process. Please try again.',
            });
        } else {
            const btn = (
                <Button type="text" size="small" onClick={() => window.location.reload()}>
                    Try again
                </Button>
            );

            yield call(notification.error, {
                message: 'Error',
                description:
                    'There was error during the process. Please try again.',
                duration: 0,
                btn,
                
            });
        }
        yield put({
            type: SIGNUP_PROCESS_FAILED,
        });
    }
}

export default function* signUpProcessSaga() {
    yield takeEvery(SIGNUP_PROCESS_CHOOSE_PROVIDER, signUpWithProvider);
    yield takeEvery(SIGNUP_PROCESS_THROUGH_EMAIL, checkIfUserExists);
    yield takeEvery(SIGNUP_PROCESS_REGISTER, getOnRegisterInternal);
    yield takeEvery(SIGNUP_PROCESS_REGISTER_BETA, getOnRegisterInternalBeta);
    yield takeEvery(SIGNUP_PROCESS_REGISTER_INVITED, getOnRegisterInvited);
    yield takeEvery(
        SIGNUP_PROCESS_GET_PROVIDER_INFORMATION,
        getOnRegisterExternal,
    );
    yield takeEvery(SIGNUP_PROCESS_GOOGLE_SUCCESSFUL, getOnRegisterExternal);
}
