import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
    createFailure,
    createStart,
    createSuccess,
    deleteFailure,
    deleteStart,
    deleteSuccess,
} from '@/redux/actions/genericActions';

export const CUSTOM_TAGS_FETCH_START = 'CUSTOM_TAGS/FETCH_START';
export const CUSTOM_TAGS_FETCH_SUCCESS = 'CUSTOM_TAGS/FETCH_SUCCESS';
export const CUSTOM_TAGS_FETCH_FAILURE = 'CUSTOM_TAGS/FETCH_FAILURE';

export const CUSTOM_TAGS_UPDATE_START = 'CUSTOM_TAGS/UPDATE_START';
export const CUSTOM_TAGS_UPDATE_SUCCESS = 'CUSTOM_TAGS/UPDATE_SUCCESS';
export const CUSTOM_TAGS_UPDATE_FAILURE = 'CUSTOM_TAGS/UPDATE_FAILURE';

export const CUSTOM_TAGS_CREATE_START = 'CUSTOM_TAGS/CREATE_START';
export const CUSTOM_TAGS_CREATE_SUCCESS = 'CUSTOM_TAGS/CREATE_SUCCESS';
export const CUSTOM_TAGS_CREATE_FAILURE = 'CUSTOM_TAGS/CREATE_FAILURE';

export const CUSTOM_TAGS_DELETE_START = 'CUSTOM_TAGS/DELETE_START';
export const CUSTOM_TAGS_DELETE_SUCCESS = 'CUSTOM_TAGS/DELETE_SUCCESS';
export const CUSTOM_TAGS_DELETE_FAILURE = 'CUSTOM_TAGS/DELETE_FAILURE';

export const CUSTOM_TAGS_RESET_STATUS = 'CUSTOM_TAGS/RESET_STATUS';

export const fetchCustomTags = () => fetchData(CUSTOM_TAGS_FETCH_START);
export const fetchCustomTagsSuccess = (data) =>
    fetchSuccess(CUSTOM_TAGS_FETCH_SUCCESS, data);
export const fetchCustomTagsFailure = (error) =>
    fetchFailure(CUSTOM_TAGS_FETCH_FAILURE, error);
export const customTagsUpdateStart = (data, onSuccess) =>
    updateStart(CUSTOM_TAGS_UPDATE_START, data, onSuccess);
export const customTagsUpdateFailure = (error) =>
    updateFailure(CUSTOM_TAGS_UPDATE_FAILURE, error);
export const customTagsUpdateSuccess = (data) =>
    updateSuccess(CUSTOM_TAGS_UPDATE_SUCCESS, data);
export const customTagsDeleteStart = (data, onSuccess) =>
    deleteStart(CUSTOM_TAGS_DELETE_START, data, onSuccess);
export const customTagsDeleteFailure = (error) =>
    deleteFailure(CUSTOM_TAGS_DELETE_FAILURE, error);
export const customTagsDeleteSuccess = (data) =>
    deleteSuccess(CUSTOM_TAGS_DELETE_SUCCESS, data);
export const customTagsCreateStart = (data, onSuccess) =>
    createStart(CUSTOM_TAGS_CREATE_START, data, onSuccess);
export const customTagsCreateFailure = (error) =>
    createFailure(CUSTOM_TAGS_CREATE_FAILURE, error);
export const customTagsCreateSuccess = (data) =>
    createSuccess(CUSTOM_TAGS_CREATE_SUCCESS, data);

export const customTagResetStatus = () => ({
    type: CUSTOM_TAGS_RESET_STATUS,
});
