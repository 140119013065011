import {
    CONVERSATION_QUESTIONS,
    fetchConversationQuestions,
} from '@/redux/actions/conversation/questionsAction';
import { put, take } from 'redux-saga/effects';

export function* getQuestions(id) {
    yield put(fetchConversationQuestions(id));
    const questionsFetchAction = yield take([
        CONVERSATION_QUESTIONS.FETCH_SUCCESS,
        CONVERSATION_QUESTIONS.FETCH_FAILURE,
    ]);

    if (questionsFetchAction.type === CONVERSATION_QUESTIONS.FETCH_FAILURE)
        throw new Error(
            'Conversation results fetch failed during questions fetching',
        );

    return questionsFetchAction.payload.data;
}
