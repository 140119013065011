export const RECORDS_LEADS_FETCH_START = 'RECORDS/LEADS_FETCH_START';
export const RECORDS_LEADS_FETCH_SUCCESS = 'RECORDS/LEADS_FETCH_SUCCESS';
export const RECORDS_LEADS_FETCH_FAILURE = 'RECORDS/LEADS_FETCH_FAILURE';
export const RECORDS_LEAD_EDIT_START = 'RECORDS/LEAD_EDIT_START';
export const RECORDS_LEAD_EDIT_SUCCESS = 'RECORDS/LEAD_EDIT_SUCCESS';
export const RECORDS_LEAD_EDIT_FAILURE = 'RECORDS/LEAD_EDIT_FAILURE';
export const RECORDS_LEAD_ADD_START = 'RECORDS/LEAD_ADD_START';
export const RECORDS_LEAD_ADD_SUCCESS = 'RECORDS/LEAD_ADD_SUCCESS';
export const RECORDS_LEAD_ADD_FAILURE = 'RECORDS/LEAD_ADD_FAILURE';

export const fetchLeadsStart = () => ({
    type: RECORDS_LEADS_FETCH_START,
});

export const fetchLeadsSuccess = (leads) => ({
    type: RECORDS_LEADS_FETCH_SUCCESS,
    payload: {
        leads,
    },
});

export const fetchLeadsFailure = () => ({
    type: RECORDS_LEADS_FETCH_FAILURE,
});

export const editLeadStart = (crmId, id, type, value) => ({
    type: RECORDS_LEAD_EDIT_START,
    payload: {
        crmId,
        id,
        type,
        value,
    },
});

export const editLeadSuccess = (leads) => ({
    type: RECORDS_LEAD_EDIT_SUCCESS,
    payload: {
        leads,
    },
});

export const editLeadFailure = () => ({
    type: RECORDS_LEAD_EDIT_FAILURE,
});

export const addLeadStart = (data) => ({
    type: RECORDS_LEAD_ADD_START,
    payload: {
        data,
    },
});

export const addLeadSuccess = (createdResource) => ({
    type: RECORDS_LEAD_ADD_SUCCESS,
    payload: {
        data: createdResource,
    },
});

export const addLeadFailure = (error) => ({
    type: RECORDS_LEAD_ADD_FAILURE,
    payload: {
        error,
    },
});
