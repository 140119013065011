import styled from 'styled-components';
import { Popover } from 'antd';
import { BLUE_1, BACKGROUND_GRAY } from '@/constants/colors';

const AttendeesContainer = styled.div`
    max-width: 300px;
    display: flex;
    flex-direction: column;
`;

const SelectAttendeesButton = styled.p`
    color: ${BLUE_1};
    text-decoration: underline;
    cursor: pointer;
    margin: 0 6px;
    width: fit-content;
`;

const AttendeesChooserPopover = styled(Popover).attrs(() => ({
    trigger: 'click',
    placement: 'topLeft',
    overlayInnerStyle: {
        backgroundColor: BACKGROUND_GRAY,
        padding: 0,
    },
}))``;

export { AttendeesContainer, SelectAttendeesButton, AttendeesChooserPopover };
