import {
    SIGNUP_PROCESS_CHOOSE_PROVIDER,
    SIGNUP_PROCESS_CHOOSE_PROVIDER_FAILED,
    SIGNUP_PROCESS_FAILED,
    SIGNUP_PROCESS_FIX_TYPO,
    SIGNUP_PROCESS_GET_PROVIDER_INFORMATION,
    SIGNUP_PROCESS_GOOGLE_CHOSEN,
    SIGNUP_PROCESS_GOOGLE_SUCCESSFUL,
    SIGNUP_PROCESS_REGISTER,
    SIGNUP_PROCESS_REGISTER_INVITED,
    SIGNUP_PROCESS_RESET_TO_CHOOSE_PROVIDER,
    SIGNUP_PROCESS_SUCCESSFUL,
    SIGNUP_PROCESS_THROUGH_EMAIL,
    SIGNUP_PROCESS_THROUGH_EMAIL_DOES_EXIST,
    SIGNUP_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST,
    SIGNUP_PROCESS_REGISTER_BETA,
    SIGNUP_PROCESS_PLAN_SELECTED,
} from '@/redux/actions/signupProcessAction';

import { AUTH_PROVIDERS } from '@/constants/authProviders';

export const initialState = {
    type: 'CHOOSE_PROVIDER',
    provider: null,
    error: null,
    loading: false,
    providerInformationLoading: null,
    selectedPlan: null,
};

function SignUpProcessReducer(state = initialState, action) {
    const { type } = action;
    switch (type) {
        case SIGNUP_PROCESS_CHOOSE_PROVIDER:
            return {
                ...state,
                loading: true,
                error: null,
            };

        case SIGNUP_PROCESS_CHOOSE_PROVIDER_FAILED:
            return {
                ...state,
                loading: false,
                providerInformationLoading: null,
                error: {
                    type: 'CHOOSE_PROVIDER_FAILED',
                },
            };
        case SIGNUP_PROCESS_THROUGH_EMAIL:
            return {
                ...state,
                loading: true,
                error: null,
            };
        case SIGNUP_PROCESS_THROUGH_EMAIL_DOES_EXIST:
            return {
                ...state,
                loading: false,
                error: {
                    type: 'USER_DOES_EXIST',
                },
            };
        case SIGNUP_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST:
            const {
                payload: { email },
            } = action;
            return {
                ...state,
                loading: false,
                type: 'ENTER_NAME',
                provider: {
                    name: AUTH_PROVIDERS.VERSATIONAL,
                    email,
                },
            };
        case SIGNUP_PROCESS_REGISTER:
            const { payload } = action;
            return {
                ...state,
                loading: true,
                error: null,
                provider: {
                    ...state.provider,
                    ...payload,
                },
            };
        case SIGNUP_PROCESS_REGISTER_BETA:
            const { payload: payloadRegisterBeta } = action;
            return {
                ...state,
                loading: true,
                error: null,
                provider: {
                    ...state.provider,
                    ...payloadRegisterBeta,
                },
            };
        case SIGNUP_PROCESS_REGISTER_INVITED:
            const { payload: payloadRegisterInvited } = action;
            return {
                ...state,
                loading: true,
                error: null,
                provider: {
                    ...state.provider,
                    ...payloadRegisterInvited,
                },
            };
        case SIGNUP_PROCESS_GET_PROVIDER_INFORMATION:
            const { payload: payloadGetProviderInformation } = action;
            return {
                ...state,
                provider: payloadGetProviderInformation,
                providerInformationLoading: AUTH_PROVIDERS.MICROSOFT,
                error: null,
            };
        case SIGNUP_PROCESS_GOOGLE_CHOSEN:
            return {
                ...state,
                provider: {
                    name: AUTH_PROVIDERS.GOOGLE,
                },
                loading: true,
                error: null,
            };
        case SIGNUP_PROCESS_GOOGLE_SUCCESSFUL:
            const { payload: payloadGoogleSuccessful } = action;
            return {
                ...state,
                provider: {
                    ...state.provider,
                    ...payloadGoogleSuccessful,
                },
                loading: false,
                error: null,
                providerInformationLoading: AUTH_PROVIDERS.GOOGLE,
            };

        case SIGNUP_PROCESS_FIX_TYPO:
            return initialState;
        case SIGNUP_PROCESS_RESET_TO_CHOOSE_PROVIDER: {
            return initialState;
        }
        case SIGNUP_PROCESS_SUCCESSFUL: {
            return initialState;
        }
        case SIGNUP_PROCESS_FAILED: {
            return {
                ...state,
                loading: false,
                error: {
                    type: 'SIGNUP_PROCESS_FAILED',
                },
                providerInformationLoading: null,
            };
        }
        case SIGNUP_PROCESS_PLAN_SELECTED:
            return {
                ...state,
                selectedPlan: action.payload,
            };
        default:
            return state;
    }
}

export default SignUpProcessReducer;
