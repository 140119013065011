import { getSentimentScoreArray } from '@/utils/conversations/analytics/sentiment/sentimentScore';
import { mean } from '@/utils/math/mean';

export const getAvgTranscriptSentimentForSelectedSpeakers = (
    speakers,
    transcriptContent,
    sentimentData,
) => {
    if (!speakers || speakers.length === 0 || transcriptContent === null)
        return 0;

    const scoresArray = transcriptContent
        .filter(({ speakerId }) =>
            speakers.some((speaker) => speakerId === speaker.id),
        )
        .map(({ words }) => getSentimentScoreArray(words, sentimentData))
        .map((arr) => mean(arr));

    const sum = scoresArray.reduce((prev, curr) => prev + curr, 0);

    const { length } = scoresArray;
    const result = length === 0 ? 0 : sum / length;

    return Math.round(result * 100) / 100;
};
