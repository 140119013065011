import SelectableItem from '@/components/conversations/attendeesChooser/selectableItem/selectableItem';
import { arrayOf, func, shape } from 'prop-types';

function NotCrmAttendees({ attendees, onSelect }) {
    return (
        <>
            {attendees.map(
                ({ attendee_name: attendeeName, email, title, id: userId }) => (
                    <SelectableItem
                        name={attendeeName}
                        userId={userId}
                        key={userId}
                        email={email}
                        title={title}
                        isClickable
                        onSelect={(name) => {
                            onSelect(userId, name);
                        }}
                    />
                ),
            )}
        </>
    );
}

NotCrmAttendees.propTypes = {
    attendees: arrayOf(shape({})).isRequired,
    onSelect: func.isRequired,
};

export default NotCrmAttendees;
