/* eslint-disable react/prop-types */
import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { GEMS_INSIGHTS_KEY } from '@/constants/reduxKeys';
import gemsInsightsReducer from '@/redux/reducers/gemsInsightsReducer';
import gemsInsightsSaga from '@/redux/sagas/gemsInsightsSaga';
import { fetchCustomGemsInsightsSuccess } from '@/redux/actions/gemsInsightsAction';

export default function GemsInjector({ gems }) {
    useInjectReducer({ key: GEMS_INSIGHTS_KEY, reducer: gemsInsightsReducer });
    useInjectSaga({ key: GEMS_INSIGHTS_KEY, saga: gemsInsightsSaga });
    const callRef = useRef(false);

    const dispatch = useDispatch();

    useEffect(() => {
        if (gems && !callRef.current) {
            dispatch(fetchCustomGemsInsightsSuccess(
                gems.map(({ id, name, description, type }) => ({
                    id,
                    name,
                    description,
                    type: type.name,
                    children: [],
                })),
            ));
            callRef.current = true;
        }
    }, [dispatch, gems]);

    return null;
}
