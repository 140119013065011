/* eslint-disable indent */
import { USER_ROLES } from '@/constants/userRoles';

export const changeRole = (id, role) => ({
    type: 'changeRole',
    payload: { id, role },
});

export const valueChange = (id, value) => ({
    type: 'valueChange',
    payload: { id, value },
});

export const clean = () => ({
    type: 'clean',
});

export const teamMembersReducer = (state, action) => {
    const { id, role, value } = action.payload || {};
    switch (action.type) {
        case 'clean':
            return [
                {
                    // Used in order to avoid caching data by ant
                    id: Math.max(...state.map((v) => v.id)),
                    value: '',
                    role: USER_ROLES.USER,
                },
            ];
        case 'changeRole':
            return state.map((v) => ({
                ...v,
                role: v.id === id ? role : v.role,
            }));
        case 'valueChange':
            return state
                .reduce((acc, curr, index, arr) => {
                    const newValue = {
                        ...curr,
                        value: curr.id === id ? value : curr.value,
                    };

                    const isLastItemAndNonEmpty =
                        index === state.length - 1 &&
                        newValue.value.length !== 0;

                    return [
                        ...acc,
                        newValue,
                        ...(isLastItemAndNonEmpty
                            ? [
                                  {
                                      id:
                                          (Math.max(...arr.map((v) => v.id)) ||
                                              0) + 1,
                                      value: '',
                                      role: USER_ROLES.USER,
                                  },
                              ]
                            : []),
                    ];
                }, [])
                .filter(
                    ({ value: _value }, index, arr) =>
                        _value.length > 0 || index === arr.length - 1,
                );
        case 'SET_INITIAL_TEAM_MEMBERS':
            return action.payload;
        default:
            throw new Error('Invalid action called');
    }
};
