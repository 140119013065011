import { Col, Row, Space } from 'antd';
import { bool, string, func } from 'prop-types';
import React, { useState } from 'react';
import {
    NameWrapper,
    ButtonWrapper,
    SelectableItemContainer,
    ChevronRightWrapper,
    DetailsWrapper,
    LinkNameWrapper,
} from '@/components/conversations/attendeesChooser/selectableItem/selectableItem.styles';
import Avatars from '@/components/avatars/avatars';
import { BLACK } from '@/constants/colors';
import Link from 'next/link';
import { ROUTES } from '@/constants/routes';

function SelectableItem({
    name,
    expandable,
    email,
    title,
    photo,
    onSelect,
    color = BLACK,
    isClickable,
    isNew,
    userId,
    newItemLink,
    toCrmNew,
}) {
    const [expanded, setExpanded] = useState(false);
    const [hovered, setHovered] = useState(false);

    return (
        <SelectableItemContainer
            onMouseOver={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <Row>
                {expandable ? (
                    <Col
                        span={3}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    >
                        <ChevronRightWrapper
                            isExpanded={expanded}
                            data-testid="expandIcon"
                        />
                    </Col>
                ) : (
                    <Col span={3}>
                        <Avatars
                            users={[
                                {
                                    firstName: name.split(' ')[0],
                                    lastName: name.split(' ')[1],
                                    src: photo,
                                },
                            ]}
                            size={22}
                        />
                    </Col>
                )}
                <Col span={16}>
                    {isClickable ? (
                        <Link
                            href={`${ROUTES.CRM_RECORDS_CONTACTS}?id=${userId}`}
                            target="_blank"
                            passHref
                        >
                            <a
                                target="_blank"
                                rel="noreferrer"
                                data-testId="clickableLink"
                            >
                                <LinkNameWrapper>{name}</LinkNameWrapper>
                            </a>
                        </Link>
                    ) : (
                        <NameWrapper color={color}>{name}</NameWrapper>
                    )}
                </Col>
                {isNew && !toCrmNew && !newItemLink && (
                    <Col
                        span={4}
                        style={{ position: 'relative', left: '-15px' }}
                    >
                        <ButtonWrapper
                            onClick={() => {
                                onSelect(name);
                            }}
                        >
                            Add New
                        </ButtonWrapper>
                    </Col>
                )}
                {isNew && toCrmNew && !newItemLink && (
                    <Col
                        span={4}
                        style={{ position: 'relative', left: '-15px' }}
                    >
                        <Link
                            href={`${ROUTES.RECORDS_CONTACTS_NEW}?contactName=${name}`}
                            target="_blank"
                            passHref
                        >
                            <a
                                target="_blank"
                                rel="noreferrer"
                                data-testId="clickableLink"
                            >
                                <ButtonWrapper>Add New</ButtonWrapper>
                            </a>
                        </Link>
                    </Col>
                )}

                {hovered && !newItemLink && !isNew && (
                    <Col span={4}>
                        <ButtonWrapper
                            onClick={() => {
                                onSelect(name);
                            }}
                        >
                            {isNew ? 'Add New' : 'Select'}
                        </ButtonWrapper>
                    </Col>
                )}

                {newItemLink && (
                    <Col
                        span={4}
                        style={{ position: 'relative', left: '-15px' }}
                    >
                        <Link href={newItemLink} passHref target="_blank">
                            <a target="_blank" rel="noreferrer">
                                <ButtonWrapper>Add New</ButtonWrapper>
                            </a>
                        </Link>
                    </Col>
                )}
            </Row>
            {expanded && (
                <Space direction="vertical" size={0}>
                    {title && (
                        <Space>
                            <DetailsWrapper title>Title</DetailsWrapper>
                            <DetailsWrapper>{title}</DetailsWrapper>
                        </Space>
                    )}
                    <Space>
                        <DetailsWrapper title>Email</DetailsWrapper>
                        <DetailsWrapper>{email}</DetailsWrapper>
                    </Space>
                </Space>
            )}
        </SelectableItemContainer>
    );
}

SelectableItem.defaultProps = {
    expandable: false,
    email: '',
    title: '',
    photo: '',
    color: BLACK,
    isClickable: false,
    onSelect: () => {},
    newItemLink: '',
    userId: '',
    isNew: false,
    toCrmNew: false,
};

SelectableItem.propTypes = {
    name: string.isRequired,
    expandable: bool,
    email: string,
    title: string,
    photo: string,
    onSelect: func,
    color: string,
    isClickable: bool,
    userId: string,
    isNew: bool,
    toCrmNew: bool,
    newItemLink: string,
};

export default SelectableItem;
