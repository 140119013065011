import { createContext, useContext } from 'react';
import { node } from 'prop-types';

import useTimer from '@/components/timer/useTimer';

const TimerContext = createContext({});
const TimeContext = createContext({});

export const useTimerContext = () => useContext(TimerContext);
export const useTimeContext = () => useContext(TimeContext);

export function TimeProvider({ children }) {
    const { timerProps, seconds } = useTimer();

    return (
        <TimerContext.Provider value={timerProps}>
            <TimeContext.Provider value={seconds}>
                {children}
            </TimeContext.Provider>
        </TimerContext.Provider>
    );
}

TimeProvider.propTypes = {
    children: node.isRequired,
};
