import React, { useEffect, useState } from 'react';
import axios from 'axios';
import AttendeesChooser from '@/components/conversations/attendeesChooser/attendeesChooser';
import useTeamMembers from '@/components/conversations/results/attendees/hooks/useOwners';
import AvatarsWithOwner from '@/components/avatarsWithOwner/avatarsWithOwner';
import {
    useAttendeesContext,
    useMeetingContext,
} from '@/components/conversations/conversations/context/context';
import { NEXT_ROUTES } from '@/constants/routes';
import { AttendeeTypes } from '@/constants/conversations/attendeeTypes';
import {
    AttendeesChooserPopover,
    AttendeesContainer,
} from './attendees.styles';

const updateMeetingContacts = async (meetingId, contactsIds) =>
    axios.patch(NEXT_ROUTES.MEETING_ID(meetingId), {
        contacts: contactsIds,
    });

const updateMeetingAttendees = async (meetingId, attendeesIds) =>
    axios.patch(NEXT_ROUTES.MEETING_ID(meetingId), {
        attendees: attendeesIds,
    });

const updateMeetingGuests = async (meetingId, guestsNames) =>
    axios.patch(NEXT_ROUTES.MEETING_ID(meetingId), {
        guests: guestsNames,
    });

const updateMeetingUsers = async (meetingId, teamMembersIds) =>
    axios.patch(NEXT_ROUTES.MEETING_ID(meetingId), {
        users: teamMembersIds,
    });

function Attendees() {
    const [popover, setPopover] = useState(false);
    const { data: teamMembers } = useTeamMembers();

    const { meetingId } = useMeetingContext();

    const {
        attendees,
        setAttendees,
        contacts,
        setContacts,
        selectedTeamMembers,
        setSelectedTeamMembers,
        guests,
        setGuests,
    } = useAttendeesContext();

    useEffect(() => {
        if (contacts.length && meetingId) {
            updateMeetingContacts(
                meetingId,
                contacts.map(({ userId }) => userId),
            );
        }
    }, [contacts, meetingId]);

    useEffect(() => {
        if (attendees.length && meetingId) {
            updateMeetingAttendees(
                meetingId,
                attendees.map(({ userId }) => userId),
            );
        }
    }, [attendees, meetingId]);

    useEffect(() => {
        if (guests.length && meetingId) {
            updateMeetingGuests(
                meetingId,
                guests.map(({ name }) => ({ name })),
            );
        }
    }, [guests, meetingId]);

    useEffect(() => {
        if (selectedTeamMembers.length && meetingId) {
            updateMeetingUsers(
                meetingId,
                selectedTeamMembers.map(({ userId }) => userId),
            );
        }
    }, [selectedTeamMembers, meetingId]);

    const updateAttendee = (userId, selectedName, attendeeType) => {
        const [firstName, lastName] = selectedName.split(' ');
        if (attendeeType === AttendeeTypes.contact) {
            const contactExist = contacts.some(
                (contact) => contact.userId === userId,
            );
            if (!contactExist) {
                setContacts((prev) => [
                    ...prev,
                    { firstName, lastName, userId },
                ]);
            }
        } else if (attendeeType === AttendeeTypes.attendee) {
            const attendeeExist = attendees.some(
                (attendee) => attendee.userId === userId,
            );
            if (!attendeeExist) {
                setAttendees((prev) => [
                    ...prev,
                    { firstName, lastName, userId },
                ]);
            }
        } else if (attendeeType === AttendeeTypes.teamMember) {
            const teamMemberSelected = selectedTeamMembers.some(
                (member) => member.userId === userId,
            );

            if (!teamMemberSelected) {
                const teamMember = teamMembers.find(
                    ({ id: teamMemberId }) => teamMemberId === userId,
                );

                setSelectedTeamMembers((prev) => [
                    ...prev,
                    { firstName, lastName, userId, src: teamMember?.photo },
                ]);
            }
        } else if (!guests.find(({ name }) => name === selectedName)) {
            setGuests((prev) => [
                ...prev,
                { name: selectedName, firstName, lastName },
            ]);
        }

        setPopover(false);
    };

    const users = [
        ...selectedTeamMembers,
        ...contacts,
        ...guests,
        ...attendees,
    ];

    return (
        <AttendeesContainer>
            <AttendeesChooserPopover
                content={
                    <AttendeesChooser
                        onCancel={() => {
                            setPopover(false);
                        }}
                        hidden={!popover}
                        updateAttendee={updateAttendee}
                        selectedUsers={users}
                    />
                }
                visible={popover}
                onVisibleChange={(visible) => {
                    if (!visible) setPopover(false);
                }}
            >
                <AvatarsWithOwner
                    users={users}
                    avatarMarginLeft="-1.1rem"
                    size={40}
                    onClick={() => setPopover(!popover)}
                />
            </AttendeesChooserPopover>
        </AttendeesContainer>
    );
}

export default Attendees;
