import {
    Divider,
    StyledCollapse,
    StyledPanel,
    StyledTabs,
} from '@/components/settings/tabs/shared.styles';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { SettingsContainerCustomChildren } from '@/components/settings/container/SettingsContainer';
import AllTeamMembers from '@/components/settings/team/allTeamMembers/allTeamMembers';
import ExpandIcon from '@/components/settings/tabs/expandIcon';
import InviteTeamMembers from '@/components/settings/team/inviteTeamMembers/inviteTeamMembers';
import PanelHeader from '@/components/settings/tabs/panelHeader/panelHeader';
import { TEAM_SETTINGS_TABS } from '@/components/settings/tabs/teamSettings/subTabs';
import { Tabs, Alert, Button } from 'antd';
import { TeamContextProvider } from '@/components/settings/team/teamContext';
import { getIsAdminSelector } from '@/redux/selectors/authSelector';
import { getExternalInvites } from '@/redux/selectors/invitesSelector';
import { getIsSoloSubscription } from '@/redux/selectors/subscriptionSelector';
import { useSelector } from 'react-redux';
import { NEXT_ROUTES } from '@/constants/routes';
import Router from 'next/router';
import axios from '@/utils/api/axios';
import { bool } from 'prop-types';

function TeamSettings({ simple }) {
    const [activeKeys, setActiveKeys] = useState([
        TEAM_SETTINGS_TABS.ALL_TEAM_MEMBERS,
        TEAM_SETTINGS_TABS.INVITE_TEAM_MEMBERS,
    ]);
    const lastInviteItemRef = useRef();

    const isInviteTeamMembersPanelVisible = activeKeys.includes(
        TEAM_SETTINGS_TABS.INVITE_TEAM_MEMBERS,
    );

    const isAdmin = useSelector(getIsAdminSelector);
    const externalInvites = useSelector(getExternalInvites);
    const isSolo = useSelector(getIsSoloSubscription);

    useEffect(() => {
        if (isSolo) {
            Router.push('/recordings');
        }
    }, [isSolo]);

    const acceptInvite = async (invite) => {
        try {
            await axios(
                NEXT_ROUTES.ACCEPT_INVITE(invite),
            );
            window.location.reload();
        } catch (e) {
            console.log(e)
        }
    }

    const rejectInvite = async (invite) => {
        try {
            await axios(
                NEXT_ROUTES.REJECT_INVITE(invite),
            );
        } catch (e) {
            console.log(e)
        }
    }

    useEffect(() => {
        if (isInviteTeamMembersPanelVisible) {
            setTimeout(() => {
                lastInviteItemRef?.current?.focus();
            }, 200);
        }
    }, [isInviteTeamMembersPanelVisible]);

    if (simple) {
        return (
            <div>
                <div style={{textAlign: 'center', marginTop: '2rem'}}>
                    <p>
                        Invite people from your team to join Versational®.
                    </p>
                </div>
                <div style={{display: 'flex', 'justify-content': 'center'}}>
                    <InviteTeamMembers
                        ref={lastInviteItemRef}
                    />
                </div>
            </div>
        )
    }

    return (
        <TeamContextProvider
            onUserAdd={() => {
                if (isInviteTeamMembersPanelVisible) {
                    lastInviteItemRef?.current?.focus();
                } else {
                    setActiveKeys((prev) => [
                        ...prev,
                        TEAM_SETTINGS_TABS.INVITE_TEAM_MEMBERS,
                    ]);
                }
            }}
        >
            <SettingsContainerCustomChildren pageTitle="Team Settings">
                {externalInvites.length ? externalInvites.map((invite) => (<Alert 
                    style={{maxWidth: '540px'}}
                    key={invite.id}
                    message={
                        <div>
                            You&apos;ve been invited to join {invite.company_name} organization.
                            <Button onClick={() => acceptInvite(invite.id)} type="primary" style={{marginLeft: '1rem', marginRight: '0.5rem'}}>Accept</Button>
                            <Button onClick={() => rejectInvite(invite.id)}>Reject</Button>
                        </div>
                    } 
                    type="info" />)) : ''}
                <StyledTabs>
                    <Tabs.TabPane key="Team Members" tab="Team Members">
                        <StyledCollapse
                            activeKey={activeKeys}
                            onChange={setActiveKeys}
                            expandIconPosition="left"
                            ghost
                            expandIcon={ExpandIcon}
                        >
                            {isAdmin && (
                                <Fragment>
                                    <StyledPanel
                                        header={
                                            <PanelHeader title="Invite Team Members" />
                                        }
                                        key={
                                            TEAM_SETTINGS_TABS.INVITE_TEAM_MEMBERS
                                        }
                                    >
                                        <InviteTeamMembers
                                            ref={lastInviteItemRef}
                                        />
                                    </StyledPanel>
                                    <Divider marginY="2rem" />
                                </Fragment>
                            )}
                            <StyledPanel
                                header={
                                    <PanelHeader title="All Team Members" />
                                }
                                key={TEAM_SETTINGS_TABS.ALL_TEAM_MEMBERS}
                            >
                                <AllTeamMembers simple={simple} />
                            </StyledPanel>
                        </StyledCollapse>
                    </Tabs.TabPane>
                </StyledTabs>
            </SettingsContainerCustomChildren>
        </TeamContextProvider>
    );
}

TeamSettings.propTypes = {
    simple: bool,
};

TeamSettings.defaultProps = {
    simple: false,
};

export default TeamSettings;
