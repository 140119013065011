import { useSelector } from 'react-redux';
import { cloneElement } from 'react';
import { oneOf, node, arrayOf } from 'prop-types';
import { getUserPermissionRole } from '@/redux/selectors/authSelector';
import { USER_PERMISSION_TYPE } from '@/constants/userPermissionTypes';

/**
 * @component
 * Component which check if user is authorized to see children
 * @param  props
 * @param  props.children - component which requires specific permission or permissions
 * @param  props.permissionsNeeded - required permissions to get access to children
 */
export function IfAuthorized({ children, permissionsNeeded, ...props }) {
    const userPermission = useSelector(getUserPermissionRole);

    if (
        permissionsNeeded.length &&
        !permissionsNeeded.includes(userPermission)
    ) {
        return null;
    }

    return cloneElement(children, props);
}

IfAuthorized.defaultProps = {
    permissionsNeeded: [],
};

IfAuthorized.propTypes = {
    children: node.isRequired,
    permissionsNeeded: arrayOf(
        oneOf([
            USER_PERMISSION_TYPE.ADMIN,
            USER_PERMISSION_TYPE.USER,
            USER_PERMISSION_TYPE.MANAGER,
        ]),
    ),
};
