import { Dropdown, Menu } from 'antd';
import { node, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import { inviteDeleteStart, inviteResend } from '@/redux/actions/invitesAction';

function InviteTableMenu({ children, id }) {
    const dispatch = useDispatch();
    const menu = (
        <Menu>
            <Menu.Item
                key="resend"
                onClick={() => {
                    dispatch(inviteResend(id));
                }}
            >
                Resend
            </Menu.Item>
            <Menu.Item
                key="revoke"
                onClick={() => {
                    dispatch(inviteDeleteStart(id));
                }}
            >
                Revoke
            </Menu.Item>
        </Menu>
    );

    return (
        <Dropdown overlay={menu} trigger="click" placement="bottomRight">
            {children}
        </Dropdown>
    );
}

InviteTableMenu.propTypes = {
    children: node.isRequired,
    id: string.isRequired,
};

export default InviteTableMenu;
