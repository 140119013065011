export const CONVERSATIONS_TABS = Object.freeze({
    INSIGHTS: 'insights',
    ANALYTICS: 'analytics',
    VIDEO: 'video',
    TRANSCRIPT: 'transcript',
    NOTES: 'notes',
    COMMENTS: 'comments',
    QUESTIONS: 'questions',
    SUMMARIZATION: 'summarization',
});
