import { RECORDS_CONTACTS_KEY } from '@/constants/reduxKeys';
import recordsContactsReducer from '@/redux/reducers/recordsContactsReducer';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import recordsContactsSaga from '@/redux/sagas/recordsContactsSaga';
import { useEffect } from 'react';
import { fetchContactsStart } from '@/redux/actions/recordsContactsAction';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCrmName } from '@/redux/selectors/authSelector';
import { getCompanyIsActiveSubscription } from '@/redux/selectors/companySelector';

function RecordsContactInjector() {
    useInjectReducer({
        key: RECORDS_CONTACTS_KEY,
        reducer: recordsContactsReducer,
    });

    useInjectSaga({ key: RECORDS_CONTACTS_KEY, saga: recordsContactsSaga });
    const dispatch = useDispatch();

    const isSubscribed = useSelector(getCompanyIsActiveSubscription);
    const isCRMAvailable = !!useSelector(getUserCrmName);

    useEffect(() => {
        if (isSubscribed && isCRMAvailable) {
            dispatch(fetchContactsStart(10, 1));
        }
    }, [dispatch, isCRMAvailable, isSubscribed]);

    return null;
}

export default RecordsContactInjector;
