import { Dropdown, Menu, notification } from 'antd';
import React, { useState, useEffect } from 'react';
import copy from 'clipboard-copy';
import {
    InputWrapper,
    LinkIcon,
} from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import { useMeetingContext } from '@/components/conversations/conversations/context/context';

function LinkDropdown() {
    const [linkVisible, setLinkVisible] = useState(false);
    const [link, setLink] = useState('');
    const { setMeetingLink, meetingLink } = useMeetingContext();

    useEffect(() => {
        setLink(meetingLink);
    }, [meetingLink]);

    const copyLink = () => {
        if (link) {
            setLinkVisible(false);
            copy(link);
            notification.success({
                message: 'Success',
                description: 'Meeting link copied to clipboard.',
                placement: 'bottomRight',
            });
        }
    };

    return (
        <Dropdown
            trigger="click"
            overlay={
                <Menu>
                    <Menu.Item>
                        <InputWrapper
                            onPressEnter={(e) => {
                                setMeetingLink(e.target.value);
                                setLinkVisible(false);
                            }}
                            onDoubleClick={() => {}}
                            suffix={
                                <LinkIcon
                                    onClick={copyLink}
                                    data-testid="copy-link"
                                />
                            }
                            onChange={(e) => {
                                setLink(e.target.value);
                            }}
                            value={link}
                            data-testid="link-input"
                        />
                    </Menu.Item>
                </Menu>
            }
            onVisibleChange={(visible) => {
                if (!visible) {
                    setMeetingLink(link);
                }
                setLinkVisible(visible);
            }}
            onClick={() => {
                setLinkVisible(!linkVisible);
            }}
            visible={linkVisible}
            placement="bottomCenter"
            arrow
        >
            <LinkIcon active={!!link} data-testid="link-dropdown-button" />
        </Dropdown>
    );
}

export default LinkDropdown;
