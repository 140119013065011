import { Tooltip } from 'antd';
import { node, oneOfType, string } from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const InformationTooltip = ({ text, color }) => (
    <Tooltip title={text}>
        <FontAwesomeIcon
            data-testid="informationTooltipIcon"
            icon={faInfoCircle}
            color={color}
        />
    </Tooltip>
);

InformationTooltip.defaultProps = {
    color: undefined,
};

InformationTooltip.propTypes = {
    text: oneOfType([string, node]).isRequired,
    color: string,
};

export default InformationTooltip;
