import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    addCommentFailure,
    addCommentSuccess,
    bulkConfirmQuotesFailure,
    bulkConfirmQuotesSuccess,
    confirmQuoteAction,
    createQuoteFailure,
    deleteBatchQuotesFailure,
    deleteQuoteFailure,
    deleteQuoteSuccess,
    fetchGuestTranscriptSuccess,
    fetchTranscriptFailure,
    fetchTranscriptSuccess,
    pinQuoteAction,
    resetTranscriptSpeakersFailure,
    resetTranscriptSpeakersSuccess,
    TRANSCRIPT,
    updateGemAction,
    updateQuoteFailure,
    updateQuoteSuccess,
    updateTagAction,
    updateTranscriptAttendeeFailure,
    updateTranscriptAttendeeSuccess,
    updateTranscriptSingleAttendeeFailure,
    updateTranscriptSingleAttendeeSuccess,
    updateTranscriptContentItemFailure,
    updateTranscriptContentItemSuccess,
    updateTranscriptFailure,
    updateTranscriptSuccess,
} from '@/redux/actions/conversation/transcript';
import axios from '@/utils/api/axios';
import { getConversationIdSelector } from '@/redux/selectors/conversation/conversationSelector';
import {
    getTranscriptContentSelector,
    getTranscriptSelector,
} from '@/redux/selectors/transcript/transcriptSelector';
import {
    getUpdatedTranscript,
    getUpdatedTranscriptForPatch,
    getUpdatedTranscriptSingle,
} from '@/utils/conversation/transcript/attendees';
import { getContentWithLikeModified } from '@/utils/conversation/transcript/like';
import { getTranscriptContentWithNewComment } from '@/utils/conversation/transcript/comments';
import { getIndexes } from '@/components/conversations/results/hooks/useInsightsUtils';
import { getEditedContent } from '@/utils/conversation/transcript/edition';
import {
    addQuote,
    bulkRemoveQuotes,
    removeQuotes,
} from '@/utils/conversation/transcript/quotes';
import { errorToString } from '@/utils/error/errorToString';
import { message, notification } from 'antd';

function* fetchNluTranscription(conversationId) {
    return yield call(axios, NEXT_ROUTES.NLU_TRANSCRIPTION(conversationId));
}

function* fetchGuestNluTranscription(conversationId) {
    return yield call(
        axios,
        NEXT_ROUTES.GUEST_TRANSCRIPTION_ID(conversationId),
    );
}

export function* getTranscript(action) {
    try {
        const { id } = action.payload.data;
        const { data } = yield fetchNluTranscription(id);
        yield put(fetchTranscriptSuccess(data));
    } catch (error) {
        yield put(fetchTranscriptFailure(error));
    }
}

export function* getGuestTranscript(action) {
    try {
        const { id } = action.payload.data;
        const { data } = yield fetchGuestNluTranscription(id);

        yield put(fetchGuestTranscriptSuccess(data));
    } catch (error) {
        yield put(fetchTranscriptFailure(error));
    }
}

function* updateContentItem(conversationId, item) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.NLU_TRANSCRIPTION(conversationId),
        { content: [item], speakers: [] },
    );
}

export function* getUpdateContentItem(action) {
    try {
        const conversationId = yield select(getConversationIdSelector);
        yield updateContentItem(conversationId, action.payload.item);
        yield put(updateTranscriptContentItemSuccess(action.payload.item));
    } catch (e) {
        yield put(
            updateTranscriptContentItemFailure(
                errorToString(e),
                action.payload.item.id,
            ),
        );
    }
}

function* updateTranscript(conversationId, transcript) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.NLU_TRANSCRIPTION(conversationId),
        transcript,
    );
}

export function* getUpdateAttendees(action) {
    try {
        const { userId, toReplaceSpeakerId, name, attendeeType, contentId } =
            action.payload.attendee;

        const conversationId = yield select(getConversationIdSelector);
        const transcript = yield select(getTranscriptSelector);

        const newTranscript = getUpdatedTranscript(
            transcript,
            toReplaceSpeakerId,
            attendeeType,
            name,
            userId,
        );

        const patchTranscript = getUpdatedTranscriptForPatch(newTranscript, contentId);

        yield updateTranscript(conversationId, patchTranscript);
        yield put(updateTranscriptAttendeeSuccess(newTranscript));
    } catch (e) {
        yield put(updateTranscriptAttendeeFailure(e));
    }
}

export function* getUpdateSingleAttendees(action) {
    try {
        const { speakerId, userId, contentId } =
            action.payload.attendee;

        const conversationId = yield select(getConversationIdSelector);
        const transcript = yield select(getTranscriptSelector);

        const newTranscript = getUpdatedTranscriptSingle(
            transcript,
            speakerId, 
            userId, 
            contentId,
        );

        yield updateTranscript(conversationId, newTranscript);
        yield put(updateTranscriptSingleAttendeeSuccess(newTranscript));
    } catch (e) {
        yield put(updateTranscriptSingleAttendeeFailure(e));
    }
}

export function* getUpdateTranscript(action) {
    try {
        const { updater } = action.payload;
        let newTranscript;

        if (typeof updater === 'function') {
            const ts = yield select(getTranscriptSelector);
            newTranscript = action.payload.updater(ts);
        } else {
            newTranscript = updater;
        }

        yield put(updateTranscriptSuccess(newTranscript));
    } catch (e) {
        yield put(updateTranscriptFailure(errorToString(e)));
    }
}

function* getResetSpeakers() {
    try {
        const transcript = yield select(getTranscriptSelector);
        const conversationId = yield select(getConversationIdSelector);

        const patchTranscript = {
            ...transcript,
            content: transcript.content.map((v) => ({
                ...v,
                speakerId: v.initial_speaker_id,
                speaker_id: v.initial_speaker_id,
            })),
            speakers: transcript.initialSpeakers,
        };

        yield updateTranscript(conversationId, patchTranscript);
        yield put(resetTranscriptSpeakersSuccess(patchTranscript));
    } catch (e) {
        yield put(resetTranscriptSpeakersFailure);
    }
}

function* commentLike(id) {
    return yield call(axios.post, NEXT_ROUTES.COMMENTS_LIKE(id));
}

function* getCommentLike(action) {
    const { id, userId } = action.payload;

    try {
        yield commentLike(id);
        const transcript = yield select(getTranscriptSelector);

        const contentIndex = transcript.content.findIndex((cont) =>
            cont.quotes.some((quote) =>
                quote.comments.some((c) => c.id === id),
            ),
        );

        const quoteIndex = transcript.content[contentIndex].quotes.findIndex(
            (quote) => quote.comments.some((c) => c.id === id),
        );

        const updatedTranscript = {
            ...transcript,
            content: getContentWithLikeModified(
                transcript.content,
                contentIndex,
                quoteIndex,
                id,
                userId,
            ),
        };

        yield getUpdateTranscript({ payload: { updater: updatedTranscript } });
    } catch (e) {
        yield put(updateTranscriptFailure());
    }
}

function* addComment(id, comment) {
    return yield call(axios.post, NEXT_ROUTES.QUOTE_ID_COMMENTS(id), {
        value: comment,
        date: new Date(Date.now()).toISOString(),
        votes: 0,
        voted: false,
    });
}

function* getAddComment(action) {
    const { id, comment } = action.payload;
    try {
        const { data } = yield addComment(id, comment);
        let tsContent = yield select(getTranscriptContentSelector);
        const { contentIndex, quoteIndex } = getIndexes(tsContent, id);

        tsContent = getTranscriptContentWithNewComment(
            tsContent,
            contentIndex,
            quoteIndex,
            data,
        );

        yield put(updateTranscriptContentItemSuccess(tsContent[contentIndex]));
        yield put(addCommentSuccess(id));
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during posting the comment. Please try again later.',
            placement: 'bottomRight',
        });
        yield put(addCommentFailure(errorToString(e), id));
    }
}

function* confirmQuote(confirmed, quoteId) {
    return yield call(axios.patch, NEXT_ROUTES.QUOTE_ID(quoteId), {
        confirmed,
    });
}

function* updateGems(transcriptContent, contentIndex, quoteIndex, key) {
    const quoteId = transcriptContent[contentIndex].quotes[quoteIndex].id;
    yield call(axios.post, NEXT_ROUTES.QUOTE_ID_GEMS(quoteId), {
        key,
    });
    yield call(confirmQuote, true, quoteId);
}

function* updateCrm(transcriptContent, contentIndex, quoteIndex, key) {
    const quoteId = transcriptContent[contentIndex].quotes[quoteIndex].id;
    yield call(axios.post, NEXT_ROUTES.QUOTE_ID_CUSTOM_TAG(quoteId), { key });
    yield call(confirmQuote, true, quoteId);
}

function* patchQuote(quote) {
    return yield call(axios.patch, NEXT_ROUTES.QUOTE_ID(quote.id), quote);
}

function* pinQuote(pinned, quoteId) {
    return yield call(axios.patch, NEXT_ROUTES.QUOTE_ID(quoteId), {
        pinned,
        confirmed: true,
    });
}

function* getUpdateQuote(action) {
    const { id, gem, customTag, content, confirm, pin } = action.payload;
    try {
        const tsContent = yield select(getTranscriptContentSelector);
        const { contentIndex, quoteIndex } = getIndexes(tsContent, id);
        const conversationId = yield select(getConversationIdSelector);

        if (pin != null) {
            yield pinQuote(pin, tsContent[contentIndex].quotes[quoteIndex].id);
            yield put(pinQuoteAction(contentIndex, quoteIndex, pin));
        }

        if (confirm) {
            yield confirmQuote(
                confirm,
                tsContent[contentIndex].quotes[quoteIndex].id,
            );

            yield put(confirmQuoteAction(contentIndex, quoteIndex, confirm));
            message.success({
                content: `Created insight`,
            });
        }

        if (gem) {
            const { key, name } = gem;
            yield updateGems(tsContent, contentIndex, quoteIndex, key);
            yield put(updateGemAction(contentIndex, quoteIndex, key, name));
        }

        if (customTag) {
            const { key, name } = customTag;
            yield updateCrm(tsContent, contentIndex, quoteIndex, key);
            yield put(updateTagAction(contentIndex, quoteIndex, key, name));
        }

        if (content) {
            const { value, startsAt, endsAt } = content;

            const newContentItem = getEditedContent(
                tsContent,
                contentIndex,
                quoteIndex,
                value,
                tsContent[contentIndex].words,
                startsAt,
                endsAt,
            )[contentIndex];

            const quote = newContentItem.quotes[quoteIndex];
            yield patchQuote(quote);
            yield updateContentItem(conversationId, newContentItem);
            yield put(updateTranscriptContentItemSuccess(newContentItem));
        }
        yield put(updateQuoteSuccess(id));
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during updating the quote. Please try again.',
            placement: 'bottomRight',
        });
        yield put(updateQuoteFailure(errorToString(e), id));
    }
}

function* createQuote(contentId, startsAt, endsAt) {
    const { data } = yield call(
        axios.post,
        NEXT_ROUTES.CONVERSATIONS_CONTENT_QUOTE(contentId),
        {
            startsAt,
            endsAt,
            confirmed: false,
        },
    );

    data.gems = [];
    data.customTags = [];
    data.pinned = false;
    data.comments = [];
    return data;
}

function* getCreateQuote(action) {
    const { contentId, startsAt, endsAt, onSuccess } = action.payload;
    try {
        const transcript = yield select(getTranscriptSelector);
        const newQuote = yield call(createQuote, contentId, startsAt, endsAt);
        const newContentItem = addQuote(
            transcript.content.find((c) => c.id === contentId),
            newQuote,
        );
        yield put(updateTranscriptContentItemSuccess(newContentItem));
        yield call(onSuccess, newQuote.id);
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during creating the quote. Please try again.',
            placement: 'bottomRight',
        });
        yield put(createQuoteFailure(errorToString(e), contentId));
    }
}

function* deleteQuote(id) {
    yield call(axios.delete, NEXT_ROUTES.QUOTE_ID(id));
}
function* batchDeleteQuote(config) {
    yield call(axios.delete, NEXT_ROUTES.QUOTE, config);
}

function* getDeleteBatchQuotes(action) {
    const { quotes, onSuccess } = action.payload;
    try {
        const transcript = yield select(getTranscriptSelector);

        yield call(batchDeleteQuote, {
            data: quotes.map((q) => ({
                id: q.id,
            })),
        });

        const newTranscript = bulkRemoveQuotes(transcript, quotes);

        yield put(updateTranscriptSuccess(newTranscript));

        if (onSuccess) yield call(onSuccess);
        message.success({
            content: 'Successfully removed quotes.',
        });
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during removing quotes. Please try again.',
            placement: 'bottomRight',
        });
        yield put(deleteBatchQuotesFailure(errorToString(e), quotes));
    }
}

function* getDeleteQuote(action) {
    const { id, contentId } = action.payload;
    try {
        const transcript = yield select(getTranscriptSelector);

        yield call(deleteQuote, id);

        yield put(deleteQuoteSuccess(id));

        const newContentItem = removeQuotes(
            transcript.content.find((c) => c.id === contentId),
            [id],
        );

        yield put(updateTranscriptContentItemSuccess(newContentItem));
        message.success({
            content: 'Successfully removed quote.',
        });
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during removing quote. Please try again.',
            placement: 'bottomRight',
        });
        yield put(deleteQuoteFailure(errorToString(e), contentId, id));
    }
}

function* bulkConfirmQuotes(quoteIds) {
    return yield call(
        axios.patch,
        NEXT_ROUTES.QUOTE,
        quoteIds.map((id) => ({ id, confirmed: true })),
    );
}

function* getBulkConfirmQuotes(action) {
    const { quotes, onSuccess } = action.payload;
    try {
        yield call(
            bulkConfirmQuotes,
            quotes.map((q) => q.id),
        );

        yield put(bulkConfirmQuotesSuccess(quotes));

        if (onSuccess) yield call(onSuccess);
        message.success({
            content: 'Successfully confirmed quotes.',
        });
    } catch (e) {
        notification.error({
            message: 'Error',
            description:
                'There was error during confirming quotes. Please try again.',
            placement: 'bottomRight',
        });
        yield put(bulkConfirmQuotesFailure(errorToString(e), quotes));
    }
}

export default function* transcriptSaga() {
    yield takeLatest(TRANSCRIPT.FETCH_START, getTranscript);
    yield takeLatest(TRANSCRIPT.GUEST_FETCH_START, getGuestTranscript);
    yield takeEvery(TRANSCRIPT.UPDATE_START, getUpdateTranscript);
    yield takeEvery(TRANSCRIPT.UPDATE_CONTENT_ITEM.START, getUpdateContentItem);
    yield takeEvery(TRANSCRIPT.UPDATE_ATTENDEE.START, getUpdateAttendees);
    yield takeEvery(TRANSCRIPT.UPDATE_SINGLE_ATTENDEE.START, getUpdateSingleAttendees);
    yield takeLatest(TRANSCRIPT.RESET_SPEAKERS.START, getResetSpeakers);
    yield takeEvery(TRANSCRIPT.COMMENT_LIKE.START, getCommentLike);
    yield takeEvery(TRANSCRIPT.ADD_COMMENT.START, getAddComment);
    yield takeEvery(TRANSCRIPT.UPDATE_QUOTE.START, getUpdateQuote);
    yield takeEvery(TRANSCRIPT.CREATE_QUOTE.START, getCreateQuote);
    yield takeEvery(TRANSCRIPT.DELETE_BATCH_QUOTES.START, getDeleteBatchQuotes);
    yield takeEvery(TRANSCRIPT.BULK_CONFIRM.START, getBulkConfirmQuotes);
    yield takeEvery(TRANSCRIPT.DELETE_QUOTE.START, getDeleteQuote);
}
