import { put, select, takeEvery } from 'redux-saga/effects';
import {
    bulkCheckQuote,
    INSIGHTS_CHECK_ALL_QUOTES,
} from '@/redux/actions/conversation/insightsViewAction';
import {
    getConfirmedFilteredQuotesSelector,
    getUnconfirmedFilteredQuotesSelector,
    isActiveConfirmedTab,
} from '@/redux/selectors/conversation/insightsViewSelector';

export function* checkAllQuotes() {
    const isConfirmedTabActive = yield select(isActiveConfirmedTab);
    const unconfirmedFilteredQuotes = yield select(
        getUnconfirmedFilteredQuotesSelector,
    );
    const confirmedFilteredQuotes = yield select(
        getConfirmedFilteredQuotesSelector,
    );

    if (isConfirmedTabActive) {
        yield put(bulkCheckQuote(confirmedFilteredQuotes.map((q) => q.id)));
    } else {
        yield put(bulkCheckQuote(unconfirmedFilteredQuotes.map((q) => q.id)));
    }
}

export default function* insightsViewSaga() {
    yield takeEvery(INSIGHTS_CHECK_ALL_QUOTES, checkAllQuotes);
}
