import {
    DATE_ZONE_EVENT_FETCH_FAILURE,
    DATE_ZONE_EVENT_FETCH_START,
    DATE_ZONE_EVENT_FETCH_SUCCESS,
    DATE_ZONE_UPDATE_FAILURE,
    DATE_ZONE_UPDATE_START,
    DATE_ZONE_UPDATE_SUCCESS,
} from '@/redux/actions/_exports';
import { DATE_ZONE_PROCESS_STATUS } from '@/constants/dateZoneProcessStatus';

export const initialState = {
    zone: null,
    status: DATE_ZONE_PROCESS_STATUS.IDLE,
};

function dateReducer(state = initialState, action) {
    switch (action.type) {
        case DATE_ZONE_EVENT_FETCH_START: {
            return {
                ...state,
                status: DATE_ZONE_PROCESS_STATUS.INITIAL_ZONE_LOADING,
            };
        }
        case DATE_ZONE_EVENT_FETCH_SUCCESS: {
            return {
                ...state,
                status: DATE_ZONE_PROCESS_STATUS.INITIAL_ZONE_LOADED,
                zone: action.payload.zone,
            };
        }
        case DATE_ZONE_EVENT_FETCH_FAILURE: {
            return {
                ...state,
                status: DATE_ZONE_PROCESS_STATUS.INITIAL_ZONE_ERROR,
                zone: action.payload.zone,
            };
        }
        case DATE_ZONE_UPDATE_START: {
            return {
                ...state,
                status: DATE_ZONE_PROCESS_STATUS.ZONE_UPDATE_LOADING,
            };
        }
        case DATE_ZONE_UPDATE_SUCCESS: {
            return {
                ...state,
                status: DATE_ZONE_PROCESS_STATUS.ZONE_UPDATE_SUCCESS,
                zone: action.payload.zone,
            };
        }
        case DATE_ZONE_UPDATE_FAILURE: {
            return {
                ...state,
                status: DATE_ZONE_PROCESS_STATUS.ZONE_UPDATE_ERROR,
            };
        }
        default:
            return state;
    }
}

export default dateReducer;
