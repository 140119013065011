import styled from 'styled-components';

export const HoverWrapper = styled.div`
    max-height: 300px;
    overflow-y: auto;

    p {
        margin: 0;
    }
    
    p + p {
        margin-top: 1rem;
    }
`;

export const StyledA = styled.a`
    max-width: calc(100% - 25px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    vertical-align: middle;
`;

export const StyledB = styled.b`
    vertical-align: middle;
`;