import styled, { css } from 'styled-components';
import { Divider, Typography } from 'antd';
import SimpleBarReact from 'simplebar-react';
import { RedoOutlined } from '@ant-design/icons';
import {
    SECONDARY_COLOR,
    BACKGROUND_GRAY,
    GRAY_3,
    VIVIDORANGE,
    DUSTYGRAY,
    SILVER,
} from '@/constants/colors';

const ColoredDivWrapper = styled.div`
    display: flex;
    flex-direction: column;
    // because we cannot redefine inner class of antd popover
    margin: -4px -8px;
    background: ${BACKGROUND_GRAY};
    width: 230px;

    & > *:not(:first-child) {
        margin-top: 0.5rem;
    }

    &&& > label {
        margin: 0;
    }
`;

const CleanDivider = styled(Divider)`
    && {
        padding: 0;
        margin: 0;
    }
`;

const ColoredSpanTitleWrapper = styled.span`
    text-align: start;
    font-weight: 400;
    font-size: 0.8125rem;
    padding-bottom: 6px;
    line-height: 16px;
`;

const ColoredDivSearchWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    .ant-input {
        font-size: 0.75rem;
        border-bottom-left-radius: 3px;
        border-top-left-radius: 3px;
        box-shadow: none;
    }

    .ant-input-search-button {
        height: 28.85px;
    }

    .ant-btn:not(:hover) {
        border-left: none;
    }
`;

const ColoredDivTeamSectionWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const ColoredDivContactsWrapper = styled(SimpleBarReact)`
    display: flex;
    flex-direction: column;
    max-height: 384px;
    overflow: auto;
`;

const ColoredDivRowWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-top: 0.2rem;
`;

const ColoredSpanSectionTitle = styled.div`
    font-size: 0.875rem;
    font-weight: 400;
    color: ${SECONDARY_COLOR};
`;

const informationTitleCss = css`
    font-size: 12px;
    color: ${SECONDARY_COLOR};
    margin-top: 0.25rem;
`;

const ColoredDivHeaderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const SearchContainer = styled.div`
    height: 160px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 8px;

    &::-webkit-scrollbar {
        height: 0;
    }

    &:hover {
        &::-webkit-scrollbar {
            height: 14px;
            background: ${`${SILVER}21`};
            border-radius: 6px;
            z-index: -1;
        }

        &::-webkit-scrollbar-thumb {
            width: 12px;
            background-color: ${`${DUSTYGRAY}4d`};
            border: 4px solid transparent;
            border-radius: 9px;
            background-clip: content-box;
            z-index: -1;
        }
    }

    &&& {
        margin: 0;
    }
`;

const SearchMembersTitle = styled(Typography.Text)`
    &&& {
        font-size: 0.625rem;
        color: ${GRAY_3};
        line-height: 15px;
    }
`;

const RedoIcon = styled(RedoOutlined)`
    cursor: pointer;
    transform: rotate(-60deg);
    transition: transform 0.2s ease-in-out;

    &:hover {
        color: ${VIVIDORANGE};
        transform: rotate(20deg);
    }
`;

const TooltipContainer = styled.div`
    display: flex;
    flex-direction: column;

    > p {
        margin: 0;
    }
`;

const InfoWrapper = styled.div`
    position: absolute;
    top: 0;
    right: 0.5rem;
`;

const SingleEditParapgrah = styled.div`
    font-size: 13px;
    margin-bottom: 0.25rem;
`;

export {
    ColoredDivWrapper,
    ColoredSpanTitleWrapper,
    CleanDivider,
    ColoredDivTeamSectionWrapper,
    ColoredDivSearchWrapper,
    ColoredSpanSectionTitle,
    informationTitleCss,
    ColoredDivContactsWrapper,
    ColoredDivHeaderWrapper,
    ColoredDivRowWrapper,
    SearchContainer,
    SearchMembersTitle,
    RedoIcon,
    TooltipContainer,
    InfoWrapper,
    SingleEditParapgrah,
};
