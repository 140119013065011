export const getIsInitialStep = (state) =>
    state.signup?.type === 'CHOOSE_PROVIDER' &&
    !state.signup?.loading &&
    !state.signup?.error &&
    !state.signup?.provider;

export const getIsLoading = (state) => state.signup?.loading;

export const getIsLoadingDuringSignUp = (state) =>
    state.signup?.loading && state.signup?.type === 'ENTER_NAME';

export const getIsLoadingDuringSignUpInvited = (state) =>
    state.signup?.loading && state.signup?.provider?.inviteId;
