export const SUBSCRIPTION_STATUS = {
    IDLE: 'IDLE',
    SUBSCRIPTION_SCHEDULED_CHANGES_LOADING:
        'SUBSCRIPTION_SCHEDULED_CHANGES_LOADING',
    SUBSCRIPTION_INFO_LOADED: 'SUBSCRIPTION_INFO_LOADED',
    UPDATE_PAYMENT_START: 'UPDATE_PAYMENT_START',
    RENEW_SUBSCRIPTION_START: 'RENEW_SUBSCRIPTION_START',
    BUY_SUBSCRIPTION_START: 'BUY_SUBSCRIPTION_START',
    UPDATE_SUBSCRIPTION_SEATS_START: 'UPDATE_SUBSCRIPTION_SEATS_START',
    COMPUTE_PRICE_START: 'COMPUTE_PRICE_START',
    START_SUBSCRIPTION_START: 'START_SUBSCRIPTION_START',
    CANCEL_SUBSCRIPTION_START: 'CANCEL_SUBSCRIPTION_START',
    CANCEL_SUBSCRIPTION_FAILURE: 'CANCEL_SUBSCRIPTION_FAILURE',
    REACTIVATE_SUBSCRIPTION_START: 'REACTIVATE_SUBSCRIPTION_START',
    CHANGE_PLAN_START: 'CHANGE_PLAN_START',
    CHANGE_PLAN_FAILURE: 'CHANGE_PLAN_FAILURE',
    GOT_HOSTED_PAGE: 'GOT_HOSTED_PAGE',
    FAILURE: 'FAILURE',
    PAYMENT_FAILURE: 'PAYMENT_FAILURE',
    UPDATE_SEATS_FAILURE: 'UPDATE_SEATS_FAILURE',
    COMPUTE_FAILURE: 'COMPUTE_FAILURE',
    BUY_ADDON_START: 'BUY_ADDON_START',
};

export const PAYMENT_FAILURE = 'PAYMENT_FAILURE';

export const RENEW_PLAN_TABS = Object.freeze({
    REMOVE_SEATS: 'REMOVE_SEATS',
    MODIFY_TEAM_MEMBERS: 'MODIFY_TEAM_MEMBERS',
    MODIFY_PAYMENT_METHOD: 'MODIFY_PAYMENT_METHOD',
    PLAN_DETAILS: 'PLAN_DETAILS',
});

export const DEFAULT_TRIAL_PLAN_CODE = '7dnCC';
