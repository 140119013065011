import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { RECORDINGS_RECOVERY_KEY } from '@/constants/reduxKeys';
import recordingsRecoveryReducer from '@/redux/reducers/recordings/recordingsRecoveryReducer';
import recordingsRecoverySaga from '@/redux/sagas/recordings/recordingsRecoverySaga';
import { readRecordingsRecoveryData } from '@/redux/actions/recordings/recordingRecoveryActions';

export default function RecordingsRecoveryInjector() {
    const dispatch = useDispatch();

    useInjectReducer({
        key: RECORDINGS_RECOVERY_KEY,
        reducer: recordingsRecoveryReducer,
    });

    useInjectSaga({
        key: RECORDINGS_RECOVERY_KEY,
        saga: recordingsRecoverySaga,
    });

    useEffect(() => {
        dispatch(readRecordingsRecoveryData());
    }, [dispatch]);

    return null;
}
