import { Fragment } from 'react';
import { number, oneOfType, string } from 'prop-types';
import { Tooltip } from 'antd';
import {
    StyledPLoadingInfo,
    SuccessTextWrapper,
} from '@/components/table/tableCell/tableCell.styles';

function SuccessResult({ insightCount, summarization }) {
    const unconfirmedInt = +`${parseInt(insightCount, 10)}` || 0;
    const insightForm = unconfirmedInt === 1 ? 'insight' : 'insights';

    const shouldDisplayTooltip = summarization?.length > 60;

    const displayedSummarization = shouldDisplayTooltip 
        ? `${summarization.substring(0, 115)}...` 
        : summarization;

    return (
        <SuccessTextWrapper>
            <StyledPLoadingInfo data-testid="p-loading-info">
                <Fragment>
                    {shouldDisplayTooltip ? (
                        <Tooltip title={summarization} placement="bottom">
                            <span 
                                style={{
                                    'marginBottom': summarization ? '0.5rem' : '0',
                                    'display': 'inline-block'
                                }}>
                                {displayedSummarization}
                            </span>
                        </Tooltip>
                    ) : (
                        <span 
                            style={{
                                'marginBottom': summarization ? '0.5rem' : '0',
                                'display': 'inline-block'
                            }}>
                            {displayedSummarization || 'Analysis complete.'}
                        </span>
                    )}
                    <br />
                    {`${unconfirmedInt} ${insightForm} discovered`}
                </Fragment>
            </StyledPLoadingInfo>
        </SuccessTextWrapper>
    );
}

SuccessResult.propTypes = {
    insightCount: oneOfType([string, number]).isRequired,
    summarization: string,
};

SuccessResult.defaultProps = {
    summarization: null,
};

export default SuccessResult;
