import { Skeleton } from 'antd';
import React from 'react';
import { string } from 'prop-types';

function ActionsPlaceholder({ text }) {
    return (
        <span style={{ display: 'flex', flexDirection: 'row', gap: '1rem' }}>
            <Skeleton.Button
                active
                shape="round"
                size="small"
                style={{ width: '4rem' }}
            />
            <span>{text}</span>
        </span>
    );
}

ActionsPlaceholder.defaultProps = {
    text: undefined,
};

ActionsPlaceholder.propTypes = {
    text: string,
};

export default ActionsPlaceholder;
