import { CenterContent } from '@/styles/globals.styles';
import { ColoredRowButton } from '@/components/settings/team/allTeamMembers/allTeamMembers.style';

export function Ellipsis(props) {
    return (
        <CenterContent {...props}>
            <ColoredRowButton shape="circle" type="text">
                ...
            </ColoredRowButton>
        </CenterContent>
    );
}
