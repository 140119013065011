export const LOCAL_API_BASE_URL = '/api';
export const API_BASE_URL = process.env.NEXT_PUBLIC_INTELIGEMS_API_BASE_URL;

export const ROUTES = {
    ASSUMPTION_DETAILS: `/assumption/details`,
    ASSUMPTION: `/assumption`,
    CALENDAR_DASHBOARD: `/calendar`,
    CHANGE_SIGN_IN: `/change-sign-in`,
    CONTACT_SUPPORT: `/contactsupport`,
    CONVERSATIONS_ID: (id = `:id`) => `/calendar/${id}`,
    CONVERSATIONS_NEW: `/calendar/new`,
    CONVERSATIONS_BOARD_MEETINGS: (id = `:id`) => `/conversations/${id}`,
    CONVERSATIONS_CONTENT: '/conversations',
    CONVERSATIONS_RESULTS: (id = `:id`) => `/conversations/results/${id}`,
    CRM_RECORDS_ACCOUNTS: `/records/accounts`,
    CRM_RECORDS_CONTACTS: `/records/contacts`,
    CRM_RECORDS_LEADS: `/records/leads`,
    GUIDES: `/preparation/guides`,
    HELP_CENTER: `https://help.versational.ai/`,
    HOME: '/recordings',
    INTEGRATIONS: `/settings/integrations`,
    INTEGRATIONS_APP: (id = ':id') => `/settings/integrations/${id}`,
    INTEGRATIONS_APPS_ID_INITIAL: (name = ':name') =>
        `/api/integrations/initial?name=${name}`,
    INTEGRATIONS_CALENDAR: `/integrations/calendar`,
    INTEGRATIONS_MICROSOFT_TEAMS: `/integrations/microsoft-teams`,
    INTEGRATIONS_GOOGLE: `/integrations/google`,
    INTEGRATIONS_GOOGLE_MEET: '/integrations/google-meet',
    INTEGRATIONS_OUTLOOK: `/integrations/outlook`,
    INTEGRATIONS_ZOOM: `/integrations/zoom`,
    INTEGRATIONS_SALESFORCE: `/integrations/1?name=Salesforce`,
    INTEGRATIONS_HUBSPOT: `/integrations/3?name=HubSpot`,
    KNOWLEDGE_CARDS: `/knowledgecards`,
    LOGIN: '/login',
    TEAMS_LOGIN: '/auth',
    MAIN: `/`,
    MOBILE: '/mobile',
    PLAYBOOKS: `/playbooks`,
    PLAYLISTS: `/playlists`,
    PREPARATION_GUIDES: `/preparation/guides`,
    PREPARATION_GUIDES_EDIT_ID: (id = `:id`) =>
        `/preparation/guides/edit/${id}`,
    PREPARATION_GUIDES_ID: (id = `:id`) => `/preparation/guides/${id}`,
    PREPARATION_GUIDES_NEW: `/preparation/guides/new`,
    PREPARATION_QUESTIONS: `/preparation/questions`,
    PREPARATION_QUESTION_NEW: `/preparation/questions/new`,
    PROGRESS: `/progress`,
    PMALLY: `/ai-ally`,
    QUESTIONS: `/preparation/questions`,
    RECORDINGS: `/recordings`,
    RECORDINGS_BLOCK_REFETCH: `/recordings?blockRefetch=true`,
    RECORDS_ACCOUNTS: '/records/accounts',
    RECORDS_ACCOUNTS_API: '/api/records/accounts',
    RECORDS_ACCOUNTS_ID: (id = ':id') => `/records/accounts/${id}`,
    RECORDS_ACCOUNT_NEW: '/records/accounts/new',
    RECORDS_CONTACTS: `/records/contacts`,
    RECORDS_CONTACTS_API: `/api/records/contacts`,
    RECORDS_CONTACTS_ID: (id = `:id`) => `/records/contacts/${id}`,
    RECORDS_CONTACTS_NEW: `/records/contacts/new`,
    RECORDS_LEADS: '/records/leads',
    RECORDS_LEADS_API: '/api/records/leads',
    RECORDS_LEADS_ID: (id = ':id') => `/records/leads/${id}`,
    RECORDS_LEADS_NEW: '/records/leads/new',
    SETTINGS: `/settings`,
    SIGNUP: '/signup',
    SUBSCRIPTION_BUY_PROCESS: `/subscription`,
    SURVEYS: `/surveys`,
    VERSATIONAL_AI: 'https://www.versational.ai/',
    STATS: '/stats',
    L2_STATS: (id = `:id`) => `/stats/${id}`
};

export const NEXT_ROUTES = {
    ASSEMBLY_AI_TRANSCRIPT: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/assemblyAi/${id}`,
    AUTH_ID_PHOTO: (id = ':id', uri = ':uri') =>
        `${LOCAL_API_BASE_URL}/auth/${id}/photo?uri=${uri}`,
    AUTH_LOGIN: `${LOCAL_API_BASE_URL}/auth/login`,
    AUTH_RESET_CRM: `${LOCAL_API_BASE_URL}/auth/resetCrm`,
    AWS: (uri = ':uri') => `${LOCAL_API_BASE_URL}/aws?uri=${uri}`,
    AWS_DOWNLOAD: (uri = ':uri') =>
        `${LOCAL_API_BASE_URL}/aws/download?uri=${uri}`,
    AWS_POST: `${LOCAL_API_BASE_URL}/aws`,
    ATTENDEE: (id = ':id') => `${LOCAL_API_BASE_URL}/attendees/${id}`,
    ATTENDEES: `${LOCAL_API_BASE_URL}/attendees`,
    ATTENDEES_DETAILS: `${LOCAL_API_BASE_URL}/attendees/details`,
    CHANGE_EMAIL: `${LOCAL_API_BASE_URL}/auth/change-email`,
    CHANGE_PASSWORD: `${LOCAL_API_BASE_URL}/auth/change-password`,
    CHANGE_SIGN_IN: `${LOCAL_API_BASE_URL}/change-signin`,
    CHANGE_SIGN_IN_EXTERNAL_PROVIDER: (provider) =>
        `${LOCAL_API_BASE_URL}/change-signin/external/${provider}`,
    CHANGE_SIGN_IN_PROVIDER: (provider) =>
        `${LOCAL_API_BASE_URL}/change-signin/${provider}`,
    COMMENTS_LIKE: (id = ':id') => `${LOCAL_API_BASE_URL}/comments/${id}/like`,
    COMPANY: `${LOCAL_API_BASE_URL}/company`,
    COMPANY_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/company/${id}`,
    COMPANY_ID_PHOTO: (id = ':id', uri = ':uri') =>
        `${LOCAL_API_BASE_URL}/company/${id}/photo?uri=${uri}`,
    COMPANY_USERS: `${LOCAL_API_BASE_URL}/company/users`,
    CONVERSATION_WITH_MEETING: `${LOCAL_API_BASE_URL}/conversations/withMeeting`,
    CONVERSATIONS: `${LOCAL_API_BASE_URL}/conversations`,
    CONVERSATIONS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}`,
    CONVERSATIONS_ID_ASSEMBLY: (id = ':id', url = ':url') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/assembly?url=${url}`,
    CONVERSATION_ID_VIEW: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}?view`,
    CONVERSATIONS_ID_DIGEST: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/digest`,
    CONVERSATIONS_ID_MEETING: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/meeting`,
    CONVERSATIONS_ID_PASS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/pass`,
    CONVERSATIONS_ID_SUMMARIZATION: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/summarization`,
    CONVERSATIONS_ID_SHARE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/share`,
    CONVERSATIONS_ID_EXTERNAL_SHARE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/external-share`,
    CONVERSATIONS_ID_EXTERNAL_SHARE_TEAMS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/external-share-teams`,
    CONVERSATIONS_ID_UNSHARE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/unshare`,
    CONVERSATIONS_ID_TAKEAWAY: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/takeaways`,
    CONVERSATIONS_ID_QUESTIONS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/questions`,
    CONVERSATIONS_ID_QUESTIONS_GENERAL: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/questions/general`,
    CONVERSATIONS_CONTENT_QUOTE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/quotes`,
    CONVERSATIONS_NOTES: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/notes`,
    CONVERSATIONS_REFERENCES: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/references`,
    // return conversation by event id
    CONVERSATIONS_MEETING_ID: (eventId = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/meetings/${eventId}`,
    CONVERSATIONS_TAKEAWAYS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/takeaways/${id}`,
    CONVERSATIONS_INCREMENT_VIEW: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/${id}/increment-view`,
    CONVERSATIONS_SEARCH: (q = ':q') =>
        `${LOCAL_API_BASE_URL}/conversations/search?q=${q}`,
    CONVERSATIONS_QUESTIONS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/questions/${id}`,
    CONVERSATIONS_QUESTIONS_ID_COMMENTS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/questions/${id}/comments`,
    CRM: `${LOCAL_API_BASE_URL}/crm/`,
    CRM_ZOHO: `${LOCAL_API_BASE_URL}/crm/zoho`,
    CRM_GET_OWNED: `${LOCAL_API_BASE_URL}/crm/owned`,
    CRM_OWNED_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/crm/${id}`,
    CUSTOM_TAGS: `${LOCAL_API_BASE_URL}/custom-tags/`,
    CUSTOM_TAGS_GET_OWNED: `${LOCAL_API_BASE_URL}/custom-tags/owned`,
    CUSTOM_TAGS_OWNED_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/custom-tags/${id}`,
    CUSTOM_VOCABULARY: `${LOCAL_API_BASE_URL}/custom-vocabulary/`,
    DOCX_TEMPLATE: `${LOCAL_API_BASE_URL}/docxtemplate`,
    FOLDERS: `${LOCAL_API_BASE_URL}/folders`,
    FOLDERS_WITH_QUOTE: `${LOCAL_API_BASE_URL}/folders?withQuote=true`,
    FOLDERS_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/folders/${id}`,
    FOLDERS_ID_ADD_PLAYLISTS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/folders/${id}/add-playlists`,
    GEMS: `${LOCAL_API_BASE_URL}/gems`,
    GEMS_CLASSIFIER_CONVERSATION: `${LOCAL_API_BASE_URL}/gems/classifier/conversation`,
    GEMS_CLASSIFIER_QUOTE: `${LOCAL_API_BASE_URL}/gems/classifier/quote`,
    GEM_SETTINGS: `${LOCAL_API_BASE_URL}/settings/gem-settings`,
    GEM_SETTINGS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/settings/gem-settings/${id}`,
    GUEST: (id = ':id') => `${LOCAL_API_BASE_URL}/guest/${id}`,
    GUEST_VERIFY_DOWNLOAD: (id = ':id', downloadId) =>
        `${LOCAL_API_BASE_URL}/guest/${id}/verify-download?downloadId=${downloadId}`,
    GUEST_CONVERSATION_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guest/conversation/${id}`,
    GUEST_TRANSCRIPTION_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guest/transcription/${id}`,
    GOOGLE: `${LOCAL_API_BASE_URL}/google`,
    GOOGLE_CALENDARS: `${LOCAL_API_BASE_URL}/google/calendars`,
    GOOGLE_EVENTS: (dateStart = `:dateStart`, dateEnd = `:dateEnd`) =>
        `${LOCAL_API_BASE_URL}/google/events?dateStart=${dateStart}&dateEnd=${dateEnd}`,
    GOOGLE_EVENTS_ID: (id = `:id`) =>
        `${LOCAL_API_BASE_URL}/google/events/${id}`,
    GOOGLE_INTEGRATIONS: `${LOCAL_API_BASE_URL}/google/integrations`,
    GOOGLE_INTEGRATIONS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/google/integrations/${id}`,
    GOOGLE_ME: `${LOCAL_API_BASE_URL}/google/me`,
    GOOGLE_MEET: `${LOCAL_API_BASE_URL}/google-meet`,
    GOOGLE_MEET_INTEGRATIONS: `${LOCAL_API_BASE_URL}/google-meet/integrations`,
    GTP_EMAIL: `${LOCAL_API_BASE_URL}/gpt/email`,
    GOOGLE_MEET_INTEGRATIONS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/google-meet/integrations/${id}`,
    INTEGRATIONS_APP: (name = ':name') =>
        `${LOCAL_API_BASE_URL}/integrations/apps/${name}`,
    INTEGRATIONS_APP_CONNECTION: (app = ':app') =>
        `${LOCAL_API_BASE_URL}/integrations/apps/${app}/connection`,
    INTEGRATIONS_CALENDAR: `${LOCAL_API_BASE_URL}/integrations/calendar`,
    INTEGRATIONS_OUTLOOK: `${LOCAL_API_BASE_URL}/outlook/integrations`,
    INTEGRATIONS_TEAMS: `${LOCAL_API_BASE_URL}/microsoft-teams/integrations`,
    INTEGRATIONS_TEAMS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/microsoft-teams/integrations/${id}`,
    TEAMS_APP: `${LOCAL_API_BASE_URL}/teams-app`,
    TEAMS_APP_TENANT: `${LOCAL_API_BASE_URL}/teams-app/tenant`,
    INTEGRATIONS_ZOOM: `${LOCAL_API_BASE_URL}/zoom/integrations`,
    INTEGRATIONS_ZOOM_DEAUTH: `${LOCAL_API_BASE_URL}/zoom/integrations/deauth`,
    INTEGRATIONS_ZOOM_ID: (accountId = ':account_id') =>
        `${LOCAL_API_BASE_URL}/zoom/integrations?account_id=${accountId}`,
    INVITES: `${LOCAL_API_BASE_URL}/invites`,
    INVITES_COMPANY: `${LOCAL_API_BASE_URL}/invites/company`,
    INVITES_ID: (id = `:id`) => `${LOCAL_API_BASE_URL}/invites/${id}`,
    INVITES_ID_RESEND: (id = `:id`) =>
        `${LOCAL_API_BASE_URL}/invites/${id}/resend`,
    INVITES_VERIFY: (email, inviteId) =>
        `${LOCAL_API_BASE_URL}/invites/verify${
            email ? `?email=${email}&id=${inviteId}` : ''
        }`,
    ACCEPT_INVITE: (inviteId) =>
        `${LOCAL_API_BASE_URL}/invites/accept-invite?id=${inviteId}`,
    REJECT_INVITE: (inviteId) =>
        `${LOCAL_API_BASE_URL}/invites/reject-invite?id=${inviteId}`,
    LOGIN: `${LOCAL_API_BASE_URL}/signin`,
    LOGIN_EXTERNAL_PROVIDER: (provider) =>
        `${LOCAL_API_BASE_URL}/signin/external/${provider}`,
    LOGIN_EXIST: (email) => `${LOCAL_API_BASE_URL}/signin/exist?email=${email}`,
    LOGIN_RESET_PASSWORD: `${LOCAL_API_BASE_URL}/signin/resetpassword`,
    LOGIN_RESET_PASSWORD_TOKEN: `${LOCAL_API_BASE_URL}/signin/resetpassword/token`,
    LOGIN_VERIFY_RESET_PASSWORD: (email, token) =>
        `${LOCAL_API_BASE_URL}/signin/resetpassword/verify?email=${email}&token=${token}`,
    LOGIN_PROVIDER: (provider) => `${LOCAL_API_BASE_URL}/signin/${provider}`,
    LOGOUT: `${LOCAL_API_BASE_URL}/auth/logout`,
    ME: `${LOCAL_API_BASE_URL}/auth/me`,
    MEETING: `${LOCAL_API_BASE_URL}/meetings`,
    MEETING_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/meetings?id=${id}`,
    MEETING_EXTERNAL_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/meetings?externalId=${id}`,
    MICROSOFT: `${LOCAL_API_BASE_URL}/microsoft`,
    MICROSOFT_TEAMS: `${LOCAL_API_BASE_URL}/microsoft-teams`,
    MICROSOFT_TEAMS_ME: `${LOCAL_API_BASE_URL}/microsoft-teams/me`,
    MICROSOFT_TEAMS_SEND_MESSAGE: `${LOCAL_API_BASE_URL}/microsoft-teams/send-message`,
    MOCK_RECORDINGS: `${LOCAL_API_BASE_URL}/mocks/recordings`,
    NLU: (id = ':id') => `${LOCAL_API_BASE_URL}/nlu?id=${id}`,
    NLU_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/nlu/${id}`,
    NLU_TRANSCRIPTION: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/nlu/transcription?id=${id}`,
    OUTLOOK_INTEGRATIONS: `${LOCAL_API_BASE_URL}/outlook/integrations`,
    OUTLOOK_INTEGRATIONS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/outlook/integrations/${id}`,
    HUBSPOT_AUTH: `${LOCAL_API_BASE_URL}/auth/hubspot`,
    OUTLOOK_EVENTS: (dateStart = `:dateStart`, dateEnd = `:dateEnd`) =>
        `${LOCAL_API_BASE_URL}/outlook/events?dateStart=${dateStart}&dateEnd=${dateEnd}`,
    OUTLOOK_EVENTS_ID: (id = `:id`) =>
        `${LOCAL_API_BASE_URL}/outlook/events/${id}`,
    OUTLOOK_ME: `${LOCAL_API_BASE_URL}/outlook/me`,
    PLAYLISTS: `${LOCAL_API_BASE_URL}/playlists`,
    PLAYLIST_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/playlists/${id}`,
    PLAYLIST_ID_SYNC: (id = ':id') => `${LOCAL_API_BASE_URL}/playlists/${id}/sync-playlist`,
    PLAYLIST_ID_MOVE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/playlists/${id}/move`,
    PLAYLISTS_TO_INSIGHT: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/playlists/${id}/add-quotes`,
    PLAYLISTS_REMOVE_INSIGHT: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/playlists/${id}/remove-quotes`,
    PREPARATION_ADD_TAG: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guides/questions/${id}/addTag`,
    PREPARATION_GUIDES: `${LOCAL_API_BASE_URL}/guides`,
    PREPARATION_GUIDES_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/guides/${id}`,
    PREPARATION_GUIDES_ID_TOPICS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guides/${id}/topics`,
    PREPARATION_GUIDES_ID_REFERENCES: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guides/${id}/references`,
    PREPARATION_QUESTIONS: `${LOCAL_API_BASE_URL}/guides/questions`,
    PREPARATION_QUESTIONS_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guides/questions/${id}`,
    PREPARATION_QUESTIONS_TABLE: `${LOCAL_API_BASE_URL}/guides/questions/table`,
    PREPARATION_TAGS: `${LOCAL_API_BASE_URL}/guides/questions/tags`,
    PREPARATION_VARIATIONS: `${LOCAL_API_BASE_URL}/guides/questions/variations`,
    PREPARATION_VARIATIONS_EDIT: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/guides/questions/variations/${id}`,
    PROJECTS: `${LOCAL_API_BASE_URL}/projects`,
    ONBOARDING_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/onboarding/${id}`,
    RAW_COMPANY_USERS: `${LOCAL_API_BASE_URL}/company/rawCompanyUsers`,
    RECORDINGS: `${LOCAL_API_BASE_URL}/recordings`,
    RECORDINGS_SIMPLIFIED: `${LOCAL_API_BASE_URL}/recordings/simplified`,
    RECORDINGS_ID_ASSEMBLY: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/recordings/${id}/assembly`,
    RECORDINGS_STATUS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/recordings/status?id=${id}`,
    RECORDS_ASSIGNEES: `${LOCAL_API_BASE_URL}/records/assignees`,
    RECORDS_CONTACTS: `${LOCAL_API_BASE_URL}/records/contacts`,
    RECORDS_CONTACT: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/records/contacts/${id}`,
    RECORDS_OPPORTUNITIES: `${LOCAL_API_BASE_URL}/records/opportunities`,
    RECORDS_CRM_USERS: `${LOCAL_API_BASE_URL}/records/users`,
    RECORDS_ACCOUNTS: `${LOCAL_API_BASE_URL}/records/accounts`,
    RECORDS_LEADS: `${LOCAL_API_BASE_URL}/records/leads`,
    RECORDINGS_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/recordings/${id}`,
    RECORDING_TAGS: `${LOCAL_API_BASE_URL}/recording-tags`,
    RECORDINGS_UPLOAD: `${LOCAL_API_BASE_URL}/recordings/upload`,
    QUOTE: `${LOCAL_API_BASE_URL}/conversations/quotes`,
    QUOTE_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}`,
    QUOTE_ID_COMMENTS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}/comments`,
    QUOTE_ID_CUSTOM_TAG: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}/custom-tag`,
    QUOTE_ID_GEMS: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}/gems`,
    QUOTE_TASK: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}/tasks`,
    QUOTE_EVENT: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}/events`,
    QUOTE_NOTE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/conversations/quotes/${id}/notes`,
    RECAPTCHA_VERIFY: `${LOCAL_API_BASE_URL}/recaptcha/verify`,
    SALESFORCE_AUTH: `${LOCAL_API_BASE_URL}/auth/salesforce`,
    SETTINGS_RECORDINGS: `${LOCAL_API_BASE_URL}/settings/recordings`,
    SETTINGS_CALENDAR: `${LOCAL_API_BASE_URL}/settings/calendar`,
    SETTINGS_NOTIFICATIONS: `${LOCAL_API_BASE_URL}/settings/notifications`,
    SESSION: `${LOCAL_API_BASE_URL}/session`,
    SESSION_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/session/${id}`,
    SIGNUP: `${LOCAL_API_BASE_URL}/signup`,
    SIGNUP_EXTERNAL_PROVIDER: (provider) =>
        `${LOCAL_API_BASE_URL}/signup/external/${provider}`,
    SIGNUP_PROVIDER: (provider, inviteId) =>
        `${LOCAL_API_BASE_URL}/signup/${provider}${
            inviteId ? `?inviteId=${inviteId}` : ''
        }`,
    SIGNUP_TOKEN: `${LOCAL_API_BASE_URL}/signup/token`,
    SIGNUP_USER_EXIST: (email) =>
        `${LOCAL_API_BASE_URL}/signup/exist${email ? `?email=${email}` : ''}`,
    SETTINGS_TOPIC_TRACKERS: `${LOCAL_API_BASE_URL}/settings/trackers`,
    SETTINGS_TOPIC_TRACKER_ID: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/settings/trackers/${id}`,
    SUBSCRIPTION_BUY_SUBSCRIPTION: `${LOCAL_API_BASE_URL}/subscription/buy`,
    SUBSCRIPTION_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/subscription/${id}`,
    SUBSCRIPTION_ID_BUY_ADDON: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/buy-addon`,
    SUBSCRIPTION_ID_CANCEL: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/cancel`,
    SUBSCRIPTION_ID_COMPUTE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/compute`,
    SUBSCRIPTION_ID_REACTIVATE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/reactivate`,
    SUBSCRIPTION_ID_RENEW_SUBSCRIPTION: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/renew-plan`,
    SUBSCRIPTION_ID_SCHEDULED_CHANGES: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/scheduledChanges`,
    SUBSCRIPTION_ID_UPDATE_CARD: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/${id}/updateCard`,
    SUBSCRIPTION_PLANS: `${LOCAL_API_BASE_URL}/subscription/plans`,
    SUBSCRIPTION_VERIFY_HOSTED_PAGE: (hostedPageId = ':id') =>
        `${LOCAL_API_BASE_URL}/subscription/verify?hostedPageId=${hostedPageId}`,
    SPEAKER_CONVERSATION_METRICS: (id = ':id') => `${LOCAL_API_BASE_URL}/conversations/metrics/${id}`,
    TEAMS: `${LOCAL_API_BASE_URL}/teams`,
    TEAM_MEMBERS: `${LOCAL_API_BASE_URL}/users/team-members`,
    TOPICS: `${LOCAL_API_BASE_URL}/topics`,
    USERS: `${LOCAL_API_BASE_URL}/users`,
    USERS_ID: (id = ':id') => `${LOCAL_API_BASE_URL}/users/${id}`,
    USERS_ID_ADD_MINUTES: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/users/${id}/add-minutes`,
    USERS_ID_ARCHIVE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/users/${id}/archive`,
    USERS_ID_REACTIVATE: (id = ':id') =>
        `${LOCAL_API_BASE_URL}/users/${id}/reactivate`,
    USERS_ME: `${LOCAL_API_BASE_URL}/users/me`,
    ZOHO_ADDONS: `${LOCAL_API_BASE_URL}/zoho-addons`,
    ZOOM: `${LOCAL_API_BASE_URL}/zoom`,
    ZOOM_DATA: `${LOCAL_API_BASE_URL}/zoom/data`,
    ZOOM_ME: `${LOCAL_API_BASE_URL}/zoom/me`,
    USERS_ID_UPGRADE_PLAN: (id = ':id') => `${LOCAL_API_BASE_URL}/users/${id}/upgrade-plan`,
    USERS_ID_FILL_SURVEY: (id = ':id') => `${LOCAL_API_BASE_URL}/users/${id}/fill-survey`,
    ZOHO_INTEGRATIONS_ZOHO_CONTACT: (email) =>
        `${LOCAL_API_BASE_URL}/users/zoho-contact?email=${email}`,
    AI_ALLY_INDEX: (id = ':id') => `${LOCAL_API_BASE_URL}/conversations/${id}/ai-ally-index`,
    AI_ALLY_NOTES: (id = ':id') => `${LOCAL_API_BASE_URL}/ai-ally/${id}`,
};

export const API_ROUTES = {
    ATTENDEES: `${API_BASE_URL}/attendees`,
    ATTENDEE: (id = ':id') => `${API_BASE_URL}/attendees/${id}`,
    CHANGE_EMAIL: `${API_BASE_URL}/users/change-email`,
    CHANGE_PASSWORD: `${API_BASE_URL}/users/change-password`,
    CHANGE_SIGN_IN: `${API_BASE_URL}/users/change-sign-in`,
    CHANGE_SIGN_IN_EXTERNAL: `${API_BASE_URL}/users/change-sign-in/external`,
    COMMENTS_LIKE: (id = ':id') => `${API_BASE_URL}/comments/${id}/like`,
    COMPANY: `${API_BASE_URL}/company`,
    COMPANY_ID: (id = ':id') => `${API_BASE_URL}/company/${id}`,
    COMPANY_USERS: `${API_BASE_URL}/company/users?perPage=100`,
    CONTACTS: `${API_BASE_URL}/contacts`,
    CONVERSATION_DATE: (date = ':date') =>
        `${API_BASE_URL}/conversations/date/${date}`,
    CONVERSATIONS: `${API_BASE_URL}/conversations`,
    CONVERSATIONS_ID: (id = ':id') => `${API_BASE_URL}/conversations/${id}`,
    CONVERSATION_ID_VIEW: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}?view`,
    CONVERSATIONS_ID_ACCESS: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/guest-access`,
    CONVERSATIONS_ID_ACCESS_VERIFY: (id = ':id', downloadId = ':downloadId') =>
        `${API_BASE_URL}/conversations/${id}/guest-access/verify?download_id=${downloadId}`,
    CONVERSATIONS_ID_INCREMENT_VIEW: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/view`,
    CONVERSATIONS_ID_NAME: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/name`,
    CONVERSATIONS_ID_MEETING: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/meeting`,
    CONVERSATIONS_ID_SHARE: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/share`,
    CONVERSATIONS_ID_EXTERNAL_SHARE: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/external-share`,
    CONVERSATIONS_ID_EXTERNAL_SHARE_TEAMS: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/external-share-teams`,
    CONVERSATIONS_ID_UNSHARE: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/unshare`,
    CONVERSATIONS_ID_PASS: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/share-pass`,
    CONVERSATIONS_ID_SUMMARIZATION: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/summarization`,
    CONVERSATION_ID_SENTIMENT: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/sentiment`,
    CONVERSATION_ID_SENTIMENT_ENTITY: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/entity-sentiment`,
    CONVERSATIONS_ID_TAKEAWAY: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/takeaways`,
    CONVERSATIONS_ID_TRANSCRIPT: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/transcript`,
    CONVERSATIONS_ID_SENTIMENT: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/sentiment`,
    CONVERSATIONS_ID_ENTITY_SENTIMENT: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/entity-sentiment`,
    CONVERSATIONS_ID_QUESTIONS: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/questions`,
    CONVERSATIONS_ID_QUESTIONS_GENERAL: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/questions/general`,
    CONVERSATIONS_CONTENT_QUOTE: (id = ':id') =>
        `${API_BASE_URL}/conversations/content/${id}/quotes`,
    CONVERSATIONS_MEETING_ID: (eventId = ':id') =>
        `${API_BASE_URL}/conversations/meetings/${eventId}/`,
    CONVERSATIONS_TAKEAWAYS_ID: (id = ':id') =>
        `${API_BASE_URL}/conversation-takeaways/${id}/`,
    CONVERSATIONS_QUESTIONS_ID: (id = ':id') =>
        `${API_BASE_URL}/conversations/questions/${id}`,
    CONVERSATIONS_QUESTIONS_ID_COMMENTS: (id = ':id') =>
        `${API_BASE_URL}/conversations/questions/${id}/comments`,
    CRM: `${API_BASE_URL}/crm`,
    CRM_ZOHO: `${API_BASE_URL}/crm/zoho`,
    CONVERSATIONS_SEARCH: (q = ':q') =>
        `${API_BASE_URL}/conversations/search?q=${q}`,
    CONVERSATIONS_ID_MEETING_NOTES: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/meeting-notes`,
    CRM_GET_OWNED: `${API_BASE_URL}/crm/get/`,
    CRM_OWNED_ID: (id = ':id') => `${API_BASE_URL}/crm/${id}`,
    FOLDERS: `${API_BASE_URL}/folders`,
    // TODO: TO REMOVE - Temporary fix
    FOLDERS_INCLUDE: `${API_BASE_URL}/folders?include=playlists.quotes.content`,
    FOLDERS_WITH_QUOTE: `${API_BASE_URL}/folders`,
    FOLDERS_ID: (id = ':id') => `${API_BASE_URL}/folders/${id}`,
    FOLDERS_ID_ADD_PLAYLIST: (id = ':id') =>
        `${API_BASE_URL}/folders/${id}/add-playlist`,
    CUSTOM_TAGS: `${API_BASE_URL}/custom-tags/`,
    CUSTOM_TAGS_GET_OWNED: `${API_BASE_URL}/custom-tags/getOwned/get`,
    CUSTOM_TAGS_OWNED_ID: (id = ':id') => `${API_BASE_URL}/custom-tags/${id}`,
    CUSTOM_VOCABULARY: `${API_BASE_URL}/custom-vocabulary/`,
    GEM_SETTINGS: `${API_BASE_URL}/gem-settings`,
    GEM_SETTINGS_ID: (id = ':id') => `${API_BASE_URL}/gem-settings/${id}`,
    GEMS: `${API_BASE_URL}/gems`,
    GEMS_CLASSIFIER: `${API_BASE_URL}/gems-classifier`,
    GEMS_CLASSIFIER_QUOTE: `${API_BASE_URL}/gems-classifier/quote`,
    GEMS_CLASSIFIER_CONVERSATION: `${API_BASE_URL}/gems-classifier/conversation`,
    GOOGLE_INTEGRATIONS: `${API_BASE_URL}/google-calendar-integrations`,
    GOOGLE_INTEGRATIONS_ID: (id = ':id') =>
        `${API_BASE_URL}/google-calendar-integrations/${id}`,
    GOOGLE_MEET_INTEGRATIONS: `${API_BASE_URL}/meet-integrations`,
    GOOGLE_MEET_INTEGRATIONS_ID: (id = ':id') =>
        `${API_BASE_URL}/meet-integrations/${id}`,
    GOOGLE_MEET_TOKEN_ID: (id = ':id') =>
        `${API_BASE_URL}/meet-integrations/token/${id}`,
    GUEST_CONVERSATION_ID: (id = ':id') =>
        `${API_BASE_URL}/conversations/guest/${id}`,
    GUEST_TRANSCRIPTION_ID: (id = ':id') =>
        `${API_BASE_URL}/conversations/guest/${id}/transcript`,
    GTP_EMAIL: `${API_BASE_URL}/gpt/email`,
    INTEGRATIONS: `${API_BASE_URL}/integrations`,
    INTEGRATIONS_APPS: `${API_BASE_URL}/integrations/apps`,
    INTEGRATIONS_APPS_TYPES: `${API_BASE_URL}/integrations/apps/types`,
    INTEGRATIONS_APPS_ID: (name = ':name') =>
        `${API_BASE_URL}/api/crm/integrations/apps/${name}`,
    INTEGRATIONS_APPS_ID_CONNECTION: (id = ':id') =>
        `${API_BASE_URL}/integrations/apps/${id}/connection`,
    INTEGRATIONS_OUTLOOK: `${API_BASE_URL}/outlook-integrations`,
    INTEGRATIONS_TEAMS: `${API_BASE_URL}/teams-integrations`,
    INTEGRATIONS_TEAMS_TOKEN: (id = ':id') =>
        `${API_BASE_URL}/teams-integrations/token/${id}`,
    INTEGRATIONS_TEAMS_ID: (id = ':id') =>
        `${API_BASE_URL}/teams-integrations/${id}`,
    TEAMS_APP: `${API_BASE_URL}/teams-app`,
    TEAMS_APP_TENANT: `${API_BASE_URL}/teams-app/tenant`,
    TEAMS_APP_OWNER: `${API_BASE_URL}/teams-app/owner`,
    TEAMS_APP_CHAT: `${API_BASE_URL}/teams-app-chat`,
    TEAMS_APP_CHAT_ID: (id = ':id') =>
        `${API_BASE_URL}/teams-app-chat/${id}`,
    OUTLOOK_INTEGRATIONS: `${API_BASE_URL}/outlook-integrations`,
    OUTLOOK_INTEGRATIONS_ID: (id = ':id') =>
        `${API_BASE_URL}/outlook-integrations/${id}`,
    INTEGRATIONS_ZOOM: `${API_BASE_URL}/zoom-integrations`,
    INTEGRATIONS_ZOOM_DEAUTHORIZE: (accountId = ':account_id') =>
        `${API_BASE_URL}/zoom-integrations/deauth/${accountId}`,
    INTEGRATIONS_ZOOM_ID: (id = ':id') =>
        `${API_BASE_URL}/zoom-integrations/${id}`,
    INTEGRATIONS_ZOOM_ACCOUNTID: (accountId = ':account_id') =>
        `${API_BASE_URL}/zoom-integrations/${accountId}`,
    INTEGRATIONS_ZOOM_LOGIN_USER_BY_ZOOM_ACCOUNTID: `${API_BASE_URL}/zoom-integrations/loginuserbyzoomaccountid`,
    INVITES: `${API_BASE_URL}/invites`,
    INVITES_VERIFY: (email, inviteId) =>
        `${API_BASE_URL}/invites/verify?email=${email}&id=${inviteId}`,
    ACCEPT_INVITE: (inviteId) =>
        `${API_BASE_URL}/invites/accept-invite?id=${inviteId}`,
    REJECT_INVITE: (inviteId) =>
        `${API_BASE_URL}/invites/reject-invite?id=${inviteId}`,
    INVITES_ID: (id = ':id') => `${API_BASE_URL}/invites/${id}`,
    INVITES_ID_GUEST_ACCESS: (id = ':id') =>
        `${API_BASE_URL}/invites/guest-access/${id}`,
    INVITES_ID_RESEND: (id = ':id') => `${API_BASE_URL}/invites/${id}/resend`,
    INVITES_COMPANY: `${API_BASE_URL}/invites/company`,
    INVITES_COMPANY_EXTERNAL: `${API_BASE_URL}/invites/external`,
    LOGIN: `${API_BASE_URL}/users/login`,
    LOGIN_EXTERNAL: `${API_BASE_URL}/users/login/external`,
    LOGIN_RESET_PASSWORD: `${API_BASE_URL}/users/reset-password`,
    LOGIN_RESET_PASSWORD_TOKEN: `${API_BASE_URL}/users/reset-password/token`,
    LOGIN_VERIFY_RESET_PASSWORD: (email, token) =>
        `${API_BASE_URL}/users/reset-password/verify-token?email=${email}&token=${token}`,
    ME: `${API_BASE_URL}/users/me`,
    MEETING: `${API_BASE_URL}/meetings`,
    MEETING_ADD_CONTACTS: (id = ':id') =>
        `${API_BASE_URL}/meetings/${id}/add-contacts`,
    MEETING_ADD_ATTENDEES: (id = ':id') =>
        `${API_BASE_URL}/meetings/${id}/add-attendees`,
    MEETING_ADD_GUESTS: (id = ':id') =>
        `${API_BASE_URL}/meetings/${id}/add-guests`,
    MEETING_ADD_USERS: (id = ':id') =>
        `${API_BASE_URL}/meetings/${id}/add-users`,
    MEETING_ID: (meetingId = ':id') => `${API_BASE_URL}/meetings/${meetingId}`,
    MEETING_EXTERNAL_ID: (id = ':id') =>
        `${API_BASE_URL}/meetings/findByExternal/${id}`,
    RECORDS_COMPANIES: `${API_BASE_URL}/records/companies`,
    RECORDS_CONTACTS_ID: (id = ':id') =>
        `${API_BASE_URL}/api/crm/records/contacts/${id}`,
    RECORDS_CONTACTS_MAIL: (mail = ':mail') =>
        `${API_BASE_URL}/records/contacts/mail/${mail}`,
    RECORDS_VERSATIONAL: `${API_BASE_URL}/records/versational`,
    RECORDS_VERSATIONAL_ID: (id = ':id') =>
        `${API_BASE_URL}/records/versational/${id}`,
    RECORDS_VERSATIONAL_MAIL: (mail = ':mail') =>
        `${API_BASE_URL}/records/versational/mail/${mail}`,
    PREPARATION_QUESTIONS: `${API_BASE_URL}/questions`,
    PREPARATION_QUESTIONS_TABLE: `${API_BASE_URL}/questions/table`,
    PREPARATION_QUESTIONS_ID: (id = ':id') => `${API_BASE_URL}/questions/${id}`,
    PREPARATION_GUIDES: `${API_BASE_URL}/guides`,
    PREPARATION_GUIDES_ID: (id = ':id') => `${API_BASE_URL}/guides/${id}`,
    PREPARATION_GUIDES_ID_TOPICS: (id = ':id') =>
        `${API_BASE_URL}/guides/${id}/topics`,
    PREPARATION_ADD_TAG: (id = ':id') =>
        `${API_BASE_URL}/questions/${id}/add-tag`,
    PREPARATION_TAGS: `${API_BASE_URL}/question-tags`,
    PREPARATION_VARIATIONS: `${API_BASE_URL}/question-variations`,
    PREPARATION_VARIATIONS_EDIT: (id = ':id') =>
        `${API_BASE_URL}/question-variations/${id}`,
    QUOTE: `${API_BASE_URL}/conversations/quotes`,
    QUOTE_ID: (id = ':id') => `${API_BASE_URL}/conversations/quotes/${id}`,
    QUOTE_ID_COMMENTS: (id = ':id') =>
        `${API_BASE_URL}/conversations/quotes/${id}/comments`,
    QUOTE_ID_CUSTOM_TAG: (id = ':id') =>
        `${API_BASE_URL}/conversations/quotes/${id}/custom-tag`,
    QUOTE_ID_GEMS: (id = ':id') =>
        `${API_BASE_URL}/conversations/quotes/${id}/gems`,
    RECORDINGS: `${API_BASE_URL}/recordings`,
    RECORDINGS_SIMPLIFIED: `${API_BASE_URL}/recordings/simplified`,
    RECORDINGS_ID: (id = ':id') => `${API_BASE_URL}/recordings/${id}`,
    RECORDINGS_ADD_TAGS: (id = ':id') =>
        `${API_BASE_URL}/recordings/${id}/add-tags`,
    RECORDINGS_GOOGLE_MEET_ID: (id = ':id') =>
        `${API_BASE_URL}/recordings/meet-id/${id}`,
    RECORDING_TAGS: `${API_BASE_URL}/recording-tags`,
    RECORDINGS_TEAMS_ID: (id = ':id') =>
        `${API_BASE_URL}/recordings/teams-id/${id}`,
    RECORDS_LEADS: `${API_BASE_URL}/api/crm/records/leads`,
    RECORDS_LEADS_EDIT: `${API_BASE_URL}/api/crm/recordsEdit/leads`,
    RECORDS_ASSIGNEES: `${API_BASE_URL}/assignees`,
    RECORDS_CONTACTS: `${API_BASE_URL}/api/crm/records/contacts`,
    RECORDS_CONTACTS_EDIT: `${API_BASE_URL}/api/crm/recordsEdit/contacts`,
    RECORDS_ACCOUNTS: `${API_BASE_URL}/api/crm/records/accounts`,
    RECORDS_ACCOUNTS_EDIT: `${API_BASE_URL}/api/crm/recordsEdit/accounts`,
    RECORDS_OPPORTUNITIES: `${API_BASE_URL}/api/crm/opportunities`,
    RECORDS_CRM_USERS: `${API_BASE_URL}/api/crm/organization-users`,
    RECORDS_ACCOUNTS_ID: (id = ':id') =>
        `${API_BASE_URL}/records/accounts/${id}`,
    RECORDS_LEADS_ID: (id = ':id') => `${API_BASE_URL}/records/leads/${id}`,
    CRM_TASKS: `${API_BASE_URL}/api/crm/quoteAction/task`,
    CRM_NOTES: `${API_BASE_URL}/api/crm/quoteAction/note`,
    CRM_EVENTS: `${API_BASE_URL}/api/crm/quoteAction/event`,
    INTEGRATION_APP_CONNECTION: (app = ':app') =>
        `${API_BASE_URL}/api/crm/apps/${app}/connection`,
    PROJECTS: `${API_BASE_URL}/projects`,
    ONBOARDING_ID: (id = ':id') => `${API_BASE_URL}/onboarding/${id}`,
    SALESFORCE_INTEGRATION: `${API_BASE_URL}/salesforce-integrations`,
    HUBSPOT_INTEGRATION: `${API_BASE_URL}/hubspot-integrations`,
    SIGNUP: `${API_BASE_URL}/users/sign-up`,
    SIGNUP_EXTERNAL: `${API_BASE_URL}/users/sign-up/external`,
    SIGNUP_TOKEN: `${API_BASE_URL}/users/sign-up/token`,
    SESSION: `${API_BASE_URL}/session`,
    SESSION_ID: (id = ':id') => `${API_BASE_URL}/session/${id}/log/`,
    SETTINGS_RECORDINGS: `${API_BASE_URL}/settings/recording`,
    SETTINGS_CALENDAR: `${API_BASE_URL}/settings/calendar`,
    SETTINGS_NOTIFICATIONS: `${API_BASE_URL}/settings/`,
    SETTINGS_TOPIC_TRACKER: `${API_BASE_URL}/subjects`,
    SETTINGS_TOPIC_TRACKER_ID: (id = ':id') => `${API_BASE_URL}/subjects/${id}`,
    SUBSCRIPTION_COMPUTE: `${API_BASE_URL}/proxy/zoho-subscriptions/v1/subscriptions/compute`,
    SUBSCRIPTION_ID: (id = ':id') =>
        `${API_BASE_URL}/proxy/zoho-subscriptions/v1/subscriptions/${id}`,
    SUBSCRIPTION_ID_BUY_ADDON: (id = ':id') =>
        `${API_BASE_URL}/proxy/zoho-subscriptions/v1/subscriptions/${id}/buyonetimeaddon`,
    SUBSCRIPTION_ID_CANCEL: (id = ':id') =>
        `${API_BASE_URL}/proxy/zoho-subscriptions/v1/subscriptions/${id}/cancel?cancel_at_end=true`,
    SUBSCRIPTION_ID_REACTIVATE: (id = ':id') =>
        `${API_BASE_URL}/proxy/zoho-subscriptions/v1/subscriptions/${id}/reactivate`,
    SUBSCRIPTION_ID_SCHEDULED_CHANGES: (id = ':id') =>
        `${API_BASE_URL}/proxy/zoho-subscriptions/v1/subscriptions/${id}/scheduledchanges`,
    SUBSCRIPTION_PLANS: `${API_BASE_URL}/proxy/zoho-subscriptions/v1/plans`,
    SUBSCRIPTION_UPDATE_CARD: `${API_BASE_URL}/proxy/zoho-subscriptions/v1/hostedpages/updatecard`,
    SUBSCRIPTION_UPDATE_SUBSCRIPTION: `${API_BASE_URL}/proxy/zoho-subscriptions/v1/hostedpages/updatesubscription`,
    SUBSCRIPTION_BUY_SUBSCRIPTION: `${API_BASE_URL}/proxy/zoho-subscriptions/v1/hostedpages/newsubscription`,
    SPEAKER_CONVERSATION_METRICS: (id = ':id') => `${API_BASE_URL}/conversation-metrics/speaker/${id}`,
    ATTENDEES_CONVERSATION_METRICS: (id = ':id') => `${API_BASE_URL}/conversation-metrics/attendees/users/${id}`,
    TEAMS: `${API_BASE_URL}/teams`,
    TEAM_ID: (id = ':id') => `${API_BASE_URL}/teams/${id}`,
    TOPICS: `${API_BASE_URL}/topics`,
    TOPICS_LIBRARY: `${API_BASE_URL}/topics/library`,
    PLAYLISTS: `${API_BASE_URL}/playlists`,
    PLAYLIST_ID: (id = ':id') => `${API_BASE_URL}/playlists/${id}`,
    PLAYLIST_ID_SYNC: (id = ':id') => `${API_BASE_URL}/playlists/${id}/sync-playlist`,
    PLAYLIST_ID_MOVE: (id = ':id') => `${API_BASE_URL}/playlists/${id}/move`,
    PLAYLISTS_TO_INSIGHT: (id = ':id') =>
        `${API_BASE_URL}/playlists/${id}/add-quotes`,
    PLAYLISTS_REMOVE_INSIGHT: (id = ':id') =>
        `${API_BASE_URL}/playlists/${id}/remove-quotes`,
    USERS: `${API_BASE_URL}/users`,
    USERS_ID: (id = ':id') => `${API_BASE_URL}/users/${id}`,
    USERS_ID_ADD_MINUTES: (id = ':id') =>
        `${API_BASE_URL}/users/${id}/add-minutes`,
    USERS_ID_ARCHIVE: (id = ':id') => `${API_BASE_URL}/users/${id}/archive`,
    USERS_ID_REACTIVATE: (id = ':id') =>
        `${API_BASE_URL}/users/${id}/reactivate`,
    USERS_ID_UPGRADE_PLAN: (id = ':id') =>
        `${API_BASE_URL}/users/${id}/upgrade-plan`,
    USERS_ID_FILL_SURVEY: (id = ':id') =>
        `${API_BASE_URL}/users/${id}/fill-survey`,
    USERS_EXIST_BY_EMAIL: (email) =>
        `${API_BASE_URL}/users/emailExists?email=${email}`,
    VERIFY_HOSTED_PAGE_ID: (hostedPageId) =>
        `${API_BASE_URL}/proxy/zoho-subscriptions/v1/hostedpages/${hostedPageId}`,
    ZOHO_ADDONS: `${API_BASE_URL}/proxy/zoho-subscriptions/v1/addons`,
    ZOHO_INTEGRATIONS_SET_STARTED_ACTIVATION: `${API_BASE_URL}/zoho-integrations/zoho-crm/set-started-activation`,
    ZOHO_INTEGRATIONS_ZOHO_CONTACT: (email) =>
        `${API_BASE_URL}/zoho-integrations/zoho-crm/contact?email=${email}`,
    AI_ALLY_INDEX: (id = ':id') =>
        `${API_BASE_URL}/conversations/${id}/ai-ally-indexed`,
};

export const getPaginationQueries = (currentPage, pageSize) =>
    `page=${currentPage}&per_page=${pageSize}`;
export const getServerPaginationQueries = (req) =>
    `page=${req.query.page}&per_page=${req.query.per_page}`;

export const getOrderQueries = (order) => `order=${order}`;
