export function fetchData(type, data) {
    return {
        type,
        payload: { data },
    };
}

export function fetchSuccess(type, data) {
    return {
        type,
        payload: {
            data,
        },
    };
}

export function fetchFailure(type, error) {
    return {
        type,
        payload: {
            error,
        },
    };
}
export const updateStart = (type, data, onSuccess) => ({
    type,
    payload: {
        data,
        onSuccess,
    },
});

export const updateFailure = (type, err) => ({
    type,
    payload: {
        error: err,
    },
});

export const updateSuccess = (type, data) => ({
    type,
    payload: {
        data,
    },
});

export const createStart = (type, data, onSuccess) => ({
    type,
    payload: {
        data,
        onSuccess,
    },
});

export const createFailure = (type, err) => ({
    type,
    payload: {
        error: err,
    },
});

export const createSuccess = (type, data) => ({
    type,
    payload: {
        data,
    },
});

export const deleteStart = (type, data, onSuccess) => ({
    type,
    payload: {
        data,
        onSuccess,
    },
});

export const deleteFailure = (type, err, id) => ({
    type,
    payload: {
        id,
        error: err,
    },
});

export const deleteSuccess = (type, data) => ({
    type,
    payload: {
        data,
    },
});

export const genericAction = (type, data, onSuccess, onFailure) => ({
    type,
    payload: {
        data,
        onSuccess,
        onFailure,
    },
});
