/* eslint-disable indent */
import { useCallback, useEffect, useState } from 'react';
import { normalizeMinMax } from '@/utils/math/normalize';

import { POLL_STATE } from '@/constants/poll/pollState';
import { poll } from '@/components/insights/recordings/upload/poll';

const initialState = {
    state: null,
    nextState: null,
    percentage: null,
    conversation: null,
};

export default function useUploadPoll() {
    const [pollingState, setPollingState] = useState(initialState);

    useEffect(() => {
        const canPoll = pollingState.nextState !== null;
        let id;

        if (canPoll) {
            (async () => {
                await poll(
                    pollingState.conversation,
                    pollingState.nextState,
                    setPollingState,
                );
            })();

            id = setInterval(async () => {
                await poll(
                    pollingState.conversation,
                    pollingState.nextState,
                    setPollingState,
                );
            }, 10 * 1000);
        }

        return () => {
            clearInterval(id);
        };
    }, [pollingState.conversation, pollingState.nextState]);

    const startPolling = useCallback((conversation) => {
        const tsStatus = conversation?.transcription_status;
        let nextState = tsStatus === 'queued' && POLL_STATE.TS_FETCHING;
        if (!nextState) {
            nextState =
                tsStatus === 'completed' && POLL_STATE.CLASSIFIERS_FETCHING;
        }

        setPollingState((prev) =>
            !prev.nextState && prev.state !== POLL_STATE.COMPLETED
                ? {
                      ...prev,
                      conversation,
                      nextState: nextState || POLL_STATE.INITIAL,
                  }
                : prev,
        );
    }, []);

    const stopPolling = useCallback(() => {
        setPollingState((prev) =>
            prev.state !== POLL_STATE.COMPLETED ? initialState : prev,
        );
    }, []);

    const percentage = (() => {
        const status = pollingState.state;
        if (status === POLL_STATE.INITIAL) return 15;
        if (status === POLL_STATE.TS_PRE_PROCESSING)
            return pollingState.percentage;
        if (status === POLL_STATE.TS_FETCHING)
            return Math.round(
                normalizeMinMax(pollingState.percentage, 0, 100, 30, 75),
            );
        if (status === POLL_STATE.CLASSIFIERS_FETCHING) return 90;
        if (status === POLL_STATE.COMPLETED) return 100;
        return null;
    })();

    const resetPollingState = useCallback(() => {
        setPollingState(initialState);
    }, []);

    return {
        setPollingState,
        startPolling,
        stopPolling,
        conversation: pollingState.conversation,
        status: pollingState.state,
        percentage,
        resetPollingState,
    };
}
