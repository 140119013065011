import { useEffect, useState } from 'react';
import { MEDIA_STATUS } from '@/constants/media/mediaRecording';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';

export function useAutoDataRestore(
    conversationId,
    userId,
    restoreRecoveryData,
    loadRecoveryData,
) {
    const [isAutoRecoveryPending, setAutoRecoveryPending] = useState(true);
    const { status } = useMediaRecorderContext();

    useEffect(() => {
        if (
            conversationId &&
            userId &&
            status === MEDIA_STATUS.IDLE &&
            isAutoRecoveryPending === true
        ) {
            (async () => {
                await restoreRecoveryData();
                setAutoRecoveryPending(false);
            })();
        }
    }, [
        conversationId,
        isAutoRecoveryPending,
        loadRecoveryData,
        restoreRecoveryData,
        status,
        userId,
    ]);

    return isAutoRecoveryPending;
}
