import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { fileToRecording } from '@/utils/recordings/fileToRecording';
import { message, notification } from 'antd';
import { MAX_FILE_SIZE } from '@/constants/files/maxSize';
import { isS3FileNameValid } from '@/utils/aws/fileNamesValidation';

export const callSetTranscriptionStatusToError = async (conversationID) => {
    const { data } = await axios.patch(
        NEXT_ROUTES.CONVERSATIONS_ID(conversationID),
        {
            transcription_status: 'error',
        },
    );
    return data;
};

export const callSetNullifyTranscriptionStatus = async (conversationID) => {
    const { data } = await axios.patch(
        NEXT_ROUTES.CONVERSATIONS_ID(conversationID),
        {
            transcription_status: null,
        },
    );
    return data;
};

const safeCall = (fn, args) => {
    if (typeof fn === 'function') fn(...args);
    return null;
};

export const getUploadProps = (config) => {
    const {
        isAdmin,
        multiple,
        onConversationCreated,
        onRecordingCreated,
        onUploadProgressChanged,
        onFinish,
        onRemoval,
        onFinally,
        beforeUpload,
        afterUpload,
    } = config;

    return {
        accept: 'audio/*,video/*',
        multiple: true,
        customRequest: async ({ file }) => {
            let newRecording;
            let conData;
            try {
                const formData = new FormData();
                formData.append('file', file);
                const fileName = encodeURIComponent(file.name);
                const conversationTitle = (() => {
                    if (file.name.includes('.')) {
                        // name without file extension
                        return file.name.match(/.*\./)[0].slice(0, -1);
                    }
                    return file.name;
                })();

                const { data: _conData } = await axios.post(
                    NEXT_ROUTES.CONVERSATIONS,
                    { title: conversationTitle },
                );
                conData = _conData;

                safeCall(onConversationCreated, [conData]);

                const postData = fileToRecording(file);
                postData.conversation_id = conData.id;
                postData.duration = 0;
                const { data: recData } = await axios.post(
                    NEXT_ROUTES.RECORDINGS,
                    postData,
                );

                newRecording = recData;
                newRecording.percent = 0;

                safeCall(onRecordingCreated, [newRecording]);

                const {
                    data: { duration },
                } = await axios.post(
                    `${NEXT_ROUTES.RECORDINGS_UPLOAD}?name=${fileName}&type=${file.type}&id=${conData.id}`,
                    formData,
                    {
                        onUploadProgress: (event) => {
                            const percent = Math.floor(
                                (event.loaded / event.total) * 100,
                            );
                            if (percent !== 100) {
                                recData.percent = percent;
                                safeCall(onUploadProgressChanged, [recData]);
                            }
                        },
                    },
                );

                const { data: finishedRec } = await axios.patch(
                    NEXT_ROUTES.RECORDINGS_ID(recData.id),
                    { duration },
                );

                const maxMinutes = finishedRec?.owner?.max_minutes ?? 6000;
                const usedMinutes = finishedRec?.owner?.used_minutes ?? 0;

                if (maxMinutes - usedMinutes <= 180) {
                    notification.warning({
                        message: 'Warning',
                        description: `You have less than 180 upload minutes for the month. Please ${
                            isAdmin
                                ? 'visit the billing page'
                                : 'contact your admin'
                        } to add more minutes.`,
                        placement: 'bottomRight',
                    });
                }
                if (!multiple) {
                    message.success({
                        content: `${finishedRec.conversation?.title} has been uploaded.
                            Feel free to leave the page, we will email you once the analysis is complete.`,
                        duration: 10,
                    });
                }

                finishedRec.percent = 100;
                safeCall(onFinish, [finishedRec]);
            } catch (e) {
                console.log(e);

                if (conData) {
                    try {
                        await callSetTranscriptionStatusToError(conData.id);
                    } catch (err) {
                        // eslint-disable-next-line no-console
                        console.debug(
                            `Conversation status error update failed: ${err.message}`,
                        );
                    }
                }

                if (e?.response?.status === 418) {
                    notification.error({
                        message: 'Error',
                        description: `You have run out of upload minutes for the month. Please ${
                            isAdmin
                                ? 'visit the billing page'
                                : 'contact your admin'
                        } to add more minutes.`,
                        placement: 'bottomRight',
                    });
                }

                if (
                    e?.response?.data?.message &&
                    e?.response?.data?.message?.indexOf('Invalid data') !== -1
                ) {
                    notification.error({
                        message: 'Error',
                        description: `Error uploading ${
                            newRecording?.conversation?.title || 'file'
                        }, your file may be contaminated. Please try a different file.`,
                        duration: 10,
                        placement: 'bottomRight',
                    });
                } else {
                    notification.error({
                        message: 'Error',
                        description: `Error uploading ${
                            newRecording?.conversation?.title || 'file'
                        }. Please try again.`,
                        duration: 5,
                        placement: 'bottomRight',
                    });
                }

                if (newRecording) {
                    safeCall(onRemoval, [newRecording]);
                }
            } finally {
                safeCall(onFinally, []);
            }
        },
        action: NEXT_ROUTES.RECORDINGS_UPLOAD,
        beforeUpload: (file) => {
            safeCall(beforeUpload, []);
            const withinMaxFileSize = file.size <= MAX_FILE_SIZE;
            const hasCorrectType =
                file?.type.startsWith('video') ||
                file?.type.startsWith('audio');

            const hasValidName = isS3FileNameValid(file?.name);

            const shouldUpload = [
                withinMaxFileSize,
                hasCorrectType,
                hasValidName,
            ].every((v) => !!v);

            if (!shouldUpload) {
                let messageProps;
                if (!withinMaxFileSize) {
                    messageProps = {
                        message: `${file?.name || 'File'} too large`,
                        description: `File size must be under 1GB`,
                        duration: 5,
                    };
                } else if (!hasCorrectType) {
                    messageProps = {
                        message: `${file?.name || 'File'} type is invalid`,
                        description: `File must be audio or video`,
                        duration: 5,
                    };
                } else if (!hasValidName) {
                    messageProps = {
                        message: `${file?.name || 'File'} has invalid name`,
                        description: `Allowed characters:  "a-z A-Z 0-9 . / ! - _ * ' , ( )".`,
                        duration: 10,
                    };
                }

                notification.error({ ...messageProps, placement: 'bottomRight', });
                safeCall(afterUpload, []);
                return false;
            }
        },
        onChange: async (info) => {
            const { status } = info.file;
            if (status === 'error') {
                notification.error(`${info.file.name} file upload failed.`);
            }
        },
        showUploadList: false,
    };
};
