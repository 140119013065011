export const getTranscriptWithAssigneeUpdate = (prevTranscript, action) => {
    const {
        payload: { contentIndex },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex.contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (qI !== contentIndex.quoteIndex) return quote;
                    return {
                        ...quote,
                        assignee: contentIndex.assigneeId,
                    };
                }),
            };
        }),
    };
};

export const getTranscriptWithDueDateUpdate = (prevTranscript, action) => {
    const {
        payload: { contentIndex },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex.contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (qI !== contentIndex.quoteIndex) return quote;
                    return {
                        ...quote,
                        due_date: contentIndex.dueDate,
                    };
                }),
            };
        }),
    };
};

export const getTranscriptWithIsDoneUpdate = (prevTranscript, action) => {
    const {
        payload: { contentIndex },
    } = action;

    return {
        ...prevTranscript,
        content: prevTranscript.content.map((item, i) => {
            if (i !== contentIndex.contentIndex) return item;
            return {
                ...item,
                quotes: item.quotes.map((quote, qI) => {
                    if (qI !== contentIndex.quoteIndex) return quote;
                    return {
                        ...quote,
                        is_done: contentIndex.isDone,
                    };
                }),
            };
        }),
    };
};
