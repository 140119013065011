import {
    SETTINGS_RECORDINGS_FETCH_FAILURE,
    SETTINGS_RECORDINGS_FETCH_START,
    SETTINGS_RECORDINGS_FETCH_SUCCESS,
    SETTINGS_RECORDINGS_UPDATE_FAILURE,
    SETTINGS_RECORDINGS_UPDATE_START,
    SETTINGS_RECORDINGS_UPDATE_SUCCESS,
    SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_TOGGLE,
    SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_ACTIVE,
    SETTINGS_RECORDINGS_IS_MIC_CURRENTLY_RECORDING,
    SETTINGS_RECORDINGS_MIC_RECORDING_PROGRESS,
    SETTINGS_RECORDINGS_MIC_RECORDING_STATUS,
} from '@/redux/actions/settings/recordingsAction';
import { FETCH_STATUS } from '@/constants/fetchStatus';

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
    micRecordingPanelVisible: false,
    micRecordingPanelActive: false,
    isMicCurrentlyRecording: false,
};

function settingsRecordingsReducer(state = initialState, action) {
    switch (action.type) {
        case SETTINGS_RECORDINGS_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case SETTINGS_RECORDINGS_FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case SETTINGS_RECORDINGS_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_RECORDINGS_UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case SETTINGS_RECORDINGS_UPDATE_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case SETTINGS_RECORDINGS_UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_TOGGLE: {
            return {
                ...state,
                micRecordingPanelVisible: !state.micRecordingPanelVisible,
            };
        }
        case SETTINGS_RECORDINGS_MIC_RECORDING_PANEL_VISIBLE_ACTIVE: {
            return {
                ...state,
                micRecordingPanelActive: true,
            };
        }
        case SETTINGS_RECORDINGS_IS_MIC_CURRENTLY_RECORDING: {
            return {
                ...state,
                isMicCurrentlyRecording: action.payload.data,
            };
        }
        case SETTINGS_RECORDINGS_MIC_RECORDING_PROGRESS: {
            return {
                ...state,
                micRecordingProgress: action.payload.data,
            };
        }
        case SETTINGS_RECORDINGS_MIC_RECORDING_STATUS: {
            return {
                ...state,
                micRecordingStatus: action.payload.data,
            };
        }
        default:
            return state;
    }
}

export default settingsRecordingsReducer;
