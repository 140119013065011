import { Typography, Button } from 'antd';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GRAY_4, GRAY_3, GRAY_1, BLACK } from '@/constants/colors';

const SelectableItemContainer = styled.div`
    .ant-avatar-string {
        font-size: 0.5rem;
    }
`;

const NameWrapper = styled(Typography.Paragraph)`
    &&& {
        margin: 0;
    }
    width: 130px;
    font-size: 0.875rem;
    color: ${({ color }) => color ?? BLACK};
`;

const LinkNameWrapper = styled(Typography.Paragraph)`
    &&& {
        margin: 0;
    }
    cursor: pointer;
    font-size: 0.875rem;
    color: DODGERBLUE;
`;

const ButtonWrapper = styled(Button).attrs(() => ({
    size: 'small',
    type: 'primary',
    ghost: true,
}))`
    height: 20px;
    font-size: 0.75rem;
    background: #ffffff;
    border: 0.5px solid #f8b200;
    box-sizing: border-box;
    border-radius: 5px;
    color: BLACK !important;
`;

const ChevronRightWrapper = styled(FontAwesomeIcon).attrs(() => ({
    icon: faChevronRight,
    color: GRAY_4,
}))`
    &&& {
        width: 0.5rem;
    }

    transition: 0.15s ease-in-out;
    margin-left: 6px;
    ${({ isExpanded }) => isExpanded && 'transform: rotate(90deg)'}
`;

const DetailsWrapper = styled(Typography.Text)`
    font-size: 0.75rem;
    color: ${({ title }) => (title ? GRAY_3 : GRAY_1)};
    margin-left: ${({ title }) => (title ? '24px' : undefined)};
`;

export {
    NameWrapper,
    ButtonWrapper,
    SelectableItemContainer,
    ChevronRightWrapper,
    DetailsWrapper,
    LinkNameWrapper,
};
