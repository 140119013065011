import React from 'react';
import { arrayOf, string, shape, bool, number, func } from 'prop-types';
import {
    AvatarsContainer,
    AvatarsGroup,
    AvatarWrapper,
} from './avatarsWithOwner.styles';

function AvatarsWithOwner({ users, avatarMarginLeft, size, onClick }) {
    const owner = users?.find(({ isOwner }) => isOwner);
    return users?.length ? (
        <AvatarsContainer noDivider={users.length <= 1} onClick={onClick}>
            {owner?.src ? (
                <AvatarWrapper src={owner.src} size={size} />
            ) : (
                owner && <AvatarWrapper size={size} />
            )}
            <AvatarsGroup avatarMarginLeft={avatarMarginLeft}>
                {users.map(({ src, firstName, lastName, email }) =>
                    src ? (
                        <AvatarWrapper src={src} key={`${email}`} size={size} />
                    ) : (
                        <AvatarWrapper key={`${email}`} size={size}>
                            {firstName[0]}
                            {lastName?.[0] ?? ''}
                        </AvatarWrapper>
                    ),
                )}
            </AvatarsGroup>
        </AvatarsContainer>
    ) : null;
}

AvatarsWithOwner.defaultProps = {
    avatarMarginLeft: undefined,
    size: undefined,
    onClick: () => {},
};

AvatarsWithOwner.propTypes = {
    users: arrayOf(
        shape({
            src: string,
            email: string,
            firstName: string,
            lastName: string,
            isOwner: bool,
        }),
    ).isRequired,
    avatarMarginLeft: string,
    size: number,
    onClick: func,
};

export default AvatarsWithOwner;
