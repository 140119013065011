import { put, takeEvery } from 'redux-saga/effects';
import { message } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchSettingsCalendarFailure,
    fetchSettingsCalendarSuccess,
    SETTINGS_CALENDAR_FETCH_START,
    SETTINGS_CALENDAR_UPDATE_START,
    settingsCalendarUpdateFailure,
    settingsCalendarUpdateSuccess,
} from '@/redux/actions/settings/calendarAction';

async function fetchCalendarSettings() {
    return axios.get(NEXT_ROUTES.SETTINGS_CALENDAR);
}

async function updateCalendarSetting(data) {
    return axios.patch(NEXT_ROUTES.SETTINGS_CALENDAR, data);
}

function* getCalendarSettings() {
    try {
        const { data } = yield fetchCalendarSettings();
        yield put(fetchSettingsCalendarSuccess(data));
    } catch (err) {
        yield put(fetchSettingsCalendarFailure(err));
    }
}

const getModifiedData = (data, conditionCheck) =>
    Object.keys(data).reduce(
        (acc, key) => ({
            ...acc,
            [key]: conditionCheck(key),
        }),
        {},
    );

function* updateCalendarSettings(action) {
    const { data } = action.payload;
    // eslint-disable-next-line camelcase
    const { id, updatedField, created_at, updated_at, owner_id, ...rest } =
        data;

    const finalData = (() => {
        if (updatedField === 'all_calendar_events')
            return getModifiedData(rest, (key) =>
                key === 'all_calendar_events' ? data[key] : false,
            );
        if (updatedField === 'internal_events')
            return getModifiedData(rest, (key) => {
                if (key === 'all_calendar_events') return false;
                if (key === 'external_events') return false;
                return data[key];
            });
        if (updatedField === 'external_events')
            return getModifiedData(rest, (key) => {
                if (key === 'all_calendar_events') return false;
                if (key === 'internal_events') return false;
                return data[key];
            });
        return getModifiedData(rest, (key) =>
            key === 'all_calendar_events' ? false : data[key],
        );
    })();

    try {
        yield updateCalendarSetting(finalData);
        yield put(settingsCalendarUpdateSuccess(finalData));
    } catch (e) {
        message.error('Settings update failed');
        yield put(settingsCalendarUpdateFailure(e));
    }
}

export default function* settingsCalendarSaga() {
    yield takeEvery(SETTINGS_CALENDAR_FETCH_START, getCalendarSettings);
    yield takeEvery(SETTINGS_CALENDAR_UPDATE_START, updateCalendarSettings);
}
