export const SIGNUP_PROCESS_CHOOSE_PROVIDER = 'SIGNUP_PROCESS/CHOOSE_PROVIDER';
export const SIGNUP_PROCESS_CHOOSE_PROVIDER_FAILED =
    'SIGNUP_PROCESS/CHOOSE_PROVIDER_FAILED';
export const SIGNUP_PROCESS_GOOGLE_CHOSEN = 'SIGNUP_PROCESS/GOOGLE_CHOSEN';
export const SIGNUP_PROCESS_GOOGLE_SUCCESSFUL =
    'SIGNUP_PROCESS/GOOGLE_SUCCESSFUL';
export const SIGNUP_PROCESS_FAILED = 'SIGNUP_PROCESS/FAILED';
export const SIGNUP_PROCESS_SUCCESSFUL = 'SIGNUP_PROCESS/SUCCESSFUL';
export const SIGNUP_PROCESS_THROUGH_EMAIL = 'SIGNUP_PROCESS/THROUGH_EMAIL';
export const SIGNUP_PROCESS_THROUGH_EMAIL_DOES_EXIST =
    'SIGNUP_PROCESS/THROUGH_EMAIL_DOES_EXIST';
export const SIGNUP_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST =
    'SIGNUP_PROCESS/THROUGH_EMAIL_DOES_NOT_EXIST';
export const SIGNUP_PROCESS_REGISTER = 'SIGNUP_PROCESS/REGISTER';
export const SIGNUP_PROCESS_REGISTER_BETA = 'SIGNUP_PROCESS/REGISTER/BETA';
export const SIGNUP_PROCESS_REGISTER_INVITED =
    'SIGNUP_PROCESS/REGISTER_INVITED';
export const SIGNUP_PROCESS_GET_PROVIDER_INFORMATION =
    'SIGNUP_PROCESS/GET_PROVIDER_INFORMATION';
export const SIGNUP_PROCESS_FIX_TYPO = 'SIGNUP_PROCESS/FIX_TYPO';
export const SIGNUP_PROCESS_RESET_TO_CHOOSE_PROVIDER =
    'SIGNUP_PROCESS/RESET_TO_CHOOSE_PROVIDER';
export const SIGNUP_PROCESS_PLAN_SELECTED =
    'SIGNUP_PROCESS/PLAN_SELECTED';

export function googleChosen() {
    return {
        type: SIGNUP_PROCESS_GOOGLE_CHOSEN,
    };
}

export function googleSuccessful(googleResponse, inviteId, conversationId) {
    return {
        type: SIGNUP_PROCESS_GOOGLE_SUCCESSFUL,
        payload: {
            response: googleResponse,
            inviteId,
            conversationId,
        },
    };
}

// Actions should be defined below.
export function chooseProvider(provider, inviteId, conversationId) {
    return {
        type: SIGNUP_PROCESS_CHOOSE_PROVIDER,
        payload: {
            provider,
            inviteId,
            conversationId,
        },
    };
}

export function chooseProviderFailed() {
    return {
        type: SIGNUP_PROCESS_CHOOSE_PROVIDER_FAILED,
    };
}

export function onCreateAccount(email, onError, fullname, company, teamsize) {
    return {
        type: SIGNUP_PROCESS_THROUGH_EMAIL,
        payload: {
            email,
            onError,
            fullname,
            company,
            teamsize,
        },
    };
}

export function onCreateAccountDoesExist() {
    return {
        type: SIGNUP_PROCESS_THROUGH_EMAIL_DOES_EXIST,
    };
}

export function onCreateAccountDoesNotExist(email) {
    return {
        type: SIGNUP_PROCESS_THROUGH_EMAIL_DOES_NOT_EXIST,
        payload: { email },
    };
}

export function onRegister(firstName, lastName, password, reCaptchaToken, conversationId) {
    return {
        type: SIGNUP_PROCESS_REGISTER,
        payload: {
            firstName,
            lastName,
            password,
            reCaptchaToken,
            conversationId,
        },
    };
}
export function onRegisterBeta(firstName, lastName, password, reCaptchaToken, company, teamsize, conversationId, selectedPlan) {
    return {
        type: SIGNUP_PROCESS_REGISTER_BETA,
        payload: {
            firstName,
            lastName,
            password,
            reCaptchaToken,
            company,
            teamsize,
            conversationId,
            selectedPlan,
        },
    };
}
export function onRegisterInvited(
    firstName,
    lastName,
    password,
    inviteId,
    reCaptchaToken,
    email,
) {
    return {
        type: SIGNUP_PROCESS_REGISTER_INVITED,
        payload: {
            firstName,
            lastName,
            password,
            inviteId,
            reCaptchaToken,
            email,
        },
    };
}

export function getProviderInformation(
    provider,
    email,
    accessToken,
    refreshToken,
    inviteId,
    conversationId,
) {
    return {
        type: SIGNUP_PROCESS_GET_PROVIDER_INFORMATION,
        payload: {
            name: provider,
            email,
            accessToken,
            refreshToken,
            inviteId,
            conversationId,
        },
    };
}

export function fixTypo() {
    return {
        type: SIGNUP_PROCESS_FIX_TYPO,
    };
}

export function resetToSignUpPage() {
    return {
        type: SIGNUP_PROCESS_RESET_TO_CHOOSE_PROVIDER,
    };
}

export function selectPlanCode(
    planCode,
) {
    return {
        type: SIGNUP_PROCESS_PLAN_SELECTED,
        payload: planCode,
    };
}
