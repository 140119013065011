import styled from 'styled-components';
import { Modal, Button } from 'antd';

export const StyledModal = styled(Modal)`
    width: 1000px !important;
`;

export const StyledCloseButton = styled(Button)`
    z-index: 9999;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
`;

export const StyledOpenButton = styled(Button)`
    margin-top: 0.5rem;
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: center;
    padding: 2rem 0 1rem 0;
`;

export const StyledWrapper = styled.div`
    width: 100%;
    height: 100%;
    z-index: 999;
    padding: 5rem;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12), 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    background: #fff;

    @media only screen and (max-height: 800px) {
        padding: 3rem;
    }

    @media only screen and (max-width: 700px) {
        padding: 1rem;
    }
`;

export const StyledIframeWrapper = styled.div`
    text-align: center;

    img {
        max-height: 80vh !important;
    }
`;

export const StyledHelpWrapper = styled.div`
    position: fixed;
    left: 50%;
    bottom: 2rem;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
`;