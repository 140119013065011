import {
    RECORDS_CONTACTS_KEY,
    RECORDS_ACCOUNTS_KEY,
} from '@/constants/reduxKeys';

export const getCrmContacts = (state) => state?.[RECORDS_CONTACTS_KEY];

export const getCrmContactsTotal = (state) =>
    state?.[RECORDS_CONTACTS_KEY].total;

export const getCrmContactsData = (state) =>
    state?.[RECORDS_CONTACTS_KEY]?.contacts ?? [];

export const getCrmContactsLoading = (state) =>
    state?.[RECORDS_CONTACTS_KEY]?.loading;

export const getCrmContactsError = (state) =>
    state?.[RECORDS_CONTACTS_KEY]?.error;

export const getCrmContact = (state) => state?.[RECORDS_CONTACTS_KEY]?.contact;

export const getCrmCompanies = (state) => state?.[RECORDS_ACCOUNTS_KEY];

export const getAssignees = (state) =>
    Array.isArray(state?.[RECORDS_CONTACTS_KEY]?.assignees) ? state?.[RECORDS_CONTACTS_KEY]?.assignees : [];
