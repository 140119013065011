import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    updateFailure,
    updateStart,
    updateSuccess,
} from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('COMPANY');

export const COMPANY = {
    ...creator.fetch(),
    ...creator.update(),
    UPDATE_PHOTO: creator.custom('UPDATE_PHOTO'),
};

export const fetchCompany = () => fetchData(COMPANY.FETCH_START);
export const fetchCompanySuccess = (data) =>
    fetchSuccess(COMPANY.FETCH_SUCCESS, data);
export const fetchCompanyFailure = (error) =>
    fetchFailure(COMPANY.FETCH_FAILURE, error);
export const companyUpdateStart = (data) =>
    updateStart(COMPANY.UPDATE_START, data);
export const companyUpdateFailure = (error) =>
    updateFailure(COMPANY.UPDATE_FAILURE, error);
export const companyUpdateSuccess = (data) =>
    updateSuccess(COMPANY.UPDATE_SUCCESS, data);
export const companyUploadPhoto = (fileObj) => ({
    type: COMPANY.UPDATE_PHOTO.START,
    payload: {
        fileObj,
    },
});
export const companyUploadPhotoSuccess = (photoUrl) => ({
    type: COMPANY.UPDATE_PHOTO.SUCCESS,
    payload: {
        photoUrl,
    },
});
export const companyUpdatePhotoFailed = (error) => ({
    type: COMPANY.UPDATE_PHOTO.FAILURE,
    payload: {
        error,
    },
});
