import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Steps, Progress } from 'antd';
import GoogleCalendar from '@/components/integrations/google/calendar'
import OutlookCalendar from '@/components/integrations/outlook/calendar'
import Zoom from '@/components/integrations/zoom/installme'
import Teams from '@/components/integrations/teams/teams'
import GoogleMeet from '@/components/integrations/google/meet'
import { VERSATIONAL } from '@/constants/colors';
import TeamSettings from '@/components/settings/tabs/teamSettings/teamSettings';
import { getOnboarding, getIsPMAllyDismissed, getIsAuthorized, getIsAdminSelector }  from '@/redux/selectors/authSelector';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { updateOnboarding } from '@/redux/actions/authAction';
import { getCalendarProviderSelector } from '@/redux/selectors/calendarSelector';
import { getIsFree, getIsIndividual } from '@/redux/selectors/subscriptionSelector';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import { CloseOutlined } from '@ant-design/icons';
import Image from 'next/image';
import { StyledModal, ButtonsWrapper, StyledIframeWrapper, StyledWrapper, StyledCloseButton, StyledOpenButton } from './onboarding.styles';

const { Step } = Steps;

function CalendarIntegration () {
    const [selectedCalendar, setSelectedCalendar] = useState(null);
    const calendarProviderSelector = useSelector(getCalendarProviderSelector);
    const [isAnyCalendarConnected, setIsAnyCalendarConnected] = useState(false);
    const handleCalendarChange = (calendar) => {
        setSelectedCalendar(calendar);
    }

    useEffect(() => {
        if (calendarProviderSelector) {
            setSelectedCalendar(calendarProviderSelector);
            setIsAnyCalendarConnected(true);
        }
    }, [calendarProviderSelector]);

    return (
        <div>
            <h3 style={{textAlign: 'center', marginTop: '1.75rem', padding: '0 2rem'}}>
                {isAnyCalendarConnected ? 'Great - your calendar has now been connected. The next stage is to invite your team members.' : 'The second stage is to connect your calendar. Below you have a choice - simply click on your preferred platform and the next page will guide you through the steps.'}
            </h3>

            <ButtonsWrapper>
                <Button type={selectedCalendar === 'google' ? 'primary' : 'default'} onClick={() => handleCalendarChange('google')}>Google Calendar</Button>
                <Button type={selectedCalendar === 'outlook' ? 'primary' : 'default'} onClick={() => handleCalendarChange('outlook')}>Outlook Calendar</Button>
            </ButtonsWrapper>

            {selectedCalendar === 'google' && <GoogleCalendar simple />}
            {selectedCalendar === 'outlook' && <OutlookCalendar simple />}
        </div>
    );
}

function VCToolsIntegration () {
    const [selectedVCTool, setVCTool] = useState(null);
    const router = useRouter();
    const teamsAppCampaign = router?.query?.teamsAppCampaign;
    const [isAnyVCToolConnected, setIsAnyVCToolConnected] = useState(false);

    const handleVCToolChange = (calendar) => {
        setVCTool(calendar);
    }

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    NEXT_ROUTES.INTEGRATIONS_TEAMS,
                );

                if (data.length > 0) {
                    setVCTool('teams');
                    setIsAnyVCToolConnected(true);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    useEffect(() => {
        if (teamsAppCampaign) {
            setVCTool('teams');
        }
    }, [teamsAppCampaign]);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    NEXT_ROUTES.GOOGLE_MEET_INTEGRATIONS,
                );

                if (data.length > 0) {
                    setVCTool('meet');
                    setIsAnyVCToolConnected(true);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(NEXT_ROUTES.INTEGRATIONS_ZOOM);

                if (data.length > 0) {
                    setVCTool('zoom');
                    setIsAnyVCToolConnected(true);
                }
            } catch (e) {
                console.log(e);
            }
        })();
    }, []);

    return (
        <div>
            <h3 style={{textAlign: 'center', marginTop: '1.75rem', padding: '0 2rem'}}>
                {isAnyVCToolConnected ? 'Brilliant - your meeting tool has now been connected. Your first step has been completed - great news! The next stage will connect your calendar.' : 'The first stage is to connect your Video Call tool of choice. Below you have a choice - simply click on your preferred platform and the next page will guide you through the steps.'}
            </h3>
            <ButtonsWrapper>
                <Button type={selectedVCTool === 'teams' ? 'primary' : 'default'} onClick={() => handleVCToolChange('teams')}>Microsoft Teams</Button>
                <Button type={selectedVCTool === 'zoom' ? 'primary' : 'default'} onClick={() => handleVCToolChange('zoom')}>Zoom</Button>
                <Button type={selectedVCTool === 'meet' ? 'primary' : 'default'} onClick={() => handleVCToolChange('meet')}>Google Meet</Button>
            </ButtonsWrapper>

            {selectedVCTool === 'zoom' && <Zoom simple />}
            {selectedVCTool === 'meet' && <GoogleMeet simple />}
            {selectedVCTool === 'teams' && <Teams simple teamsAppCampaign={teamsAppCampaign} />}
        </div>
    );
}

function TeamMembersInvite () {
    return <>
        <h3 style={{textAlign: 'center', marginTop: '1.75rem', padding: '0 2rem'}}>
            Brilliant - stage two of your onboarding is complete.<br/><br/>
            The final stage is to invite your colleagues so they too can enjoy the benefits of Versational and together you can gather collective insight.
        </h3>
        <TeamSettings simple />
    </>
}

const steps = [
    {
        title: 'Connect Video Call Tool',
        component: VCToolsIntegration,
    },
    {
        title: 'Connect Calendar',
        component: CalendarIntegration,
    },
    {
        title: 'Invite team members',
        component: TeamMembersInvite,
    }
];

function Onboarding () {
    const dispatch = useDispatch();
    const [visible, setVisible] = useState(false);
    const [current, setCurrent] = useState(0);
    const [aiAllyDisabled, setAiAllyDisabled] = useState(false);
    const [teamsAppConnecting, setTeamsAppConnecting] = useState(false);
    const [isVideoOpened, setIsVideoOpened] = useState(true);


    const isPMAllyDismissed = useSelector(getIsPMAllyDismissed);
    const onboarding = useSelector(getOnboarding);
    const isAuthorized = useSelector(getIsAuthorized);
    const isFree = useSelector(getIsFree);
    const isIndividual = useSelector(getIsIndividual);

    const isAdmin = useSelector(getIsAdminSelector);

    const [cookies] = useCookies(['AI_ALLY_RECORDING_UPLOADED']);

    const showModal = () => {
        setVisible(true);
    };

    const handleCancel = () => {
        setVisible(false);
        setCurrent(0);
    };

    const closeModal = () => {
        setIsVideoOpened(false);
        const date = new Date();
        date.setTime(date.getTime() + (1 * 60 * 60 * 1000));
        document.cookie = `onboardingVideoModalClosed=true; expires=${date.toUTCString()}; path=/`;
    }

    const nextStep = async () => {
        const updateField = {};

        if (current === 1) {
            updateField.calendar = true;
        } else if(current === 0) {
            updateField.vc_tool = true;
        } else if(current === 2) {
            updateField.team_members = true;
        }

        const { data } = await axios.patch(
            NEXT_ROUTES.ONBOARDING_ID(onboarding.id),
            updateField,
        );

        dispatch(updateOnboarding(data));
    };

    const skipOnboarding = async () => {
        const { data } = await axios.patch(
            NEXT_ROUTES.ONBOARDING_ID(onboarding.id),
            {
                calendar: true,
                vc_tool: true,
                team_members: true,
            }
        );
        dispatch(updateOnboarding(data));
        handleCancel();
    }

    const StepComponent = steps[current].component;

    useEffect(() => {
        if ((!onboarding.calendar || !onboarding.vc_tool || !onboarding.team_members) && isAuthorized) {

            if (onboarding.calendar && !onboarding.vc_tool) {
                setCurrent(0);
            } else if (!onboarding.calendar && onboarding.vc_tool) {
                setCurrent(1);
            } else if (onboarding.calendar && onboarding.vc_tool && !onboarding.team_members) {
                setCurrent(2);
            } else {
                setCurrent(0);
            }

            showModal();
        } else {
            handleCancel();
        }
    }, [onboarding]);

    useEffect(() => {
        const pmAllyDismissed = localStorage.getItem('PM_ALLY_DISMISSED');
        const getTeamsAppConnecting = localStorage.getItem('callback');
        setTeamsAppConnecting(getTeamsAppConnecting);
        setAiAllyDisabled(pmAllyDismissed === 'true');
    }, []);

    useEffect(() => {
        if (isFree) {
            skipOnboarding();
        }
    }, [isFree]);

    useEffect(() => {
        const fetchData = async () => {
            if (isIndividual && !onboarding.team_members || (!isAdmin && !onboarding.team_members)) {
                const { data } = await axios.patch(
                    NEXT_ROUTES.ONBOARDING_ID(onboarding.id),
                    {
                        calendar: false,
                        vc_tool: false,
                        team_members: true,
                    }
                );
                dispatch(updateOnboarding(data));
            }
        };
    
        fetchData();
    }, [isIndividual, isAdmin, onboarding]);

    useEffect(() => {
        if (document.cookie.split(';').some((item) => item.trim().startsWith('onboardingVideoModalClosed='))) {
            setIsVideoOpened(false);
        }
    }, []);
    
    if ((isPMAllyDismissed || aiAllyDisabled || cookies.AI_ALLY_RECORDING_UPLOADED) && !isFree && !teamsAppConnecting) {
        return (
            <StyledModal
                visible={visible}
                title={steps[current].title}
                footer={[
                    <Button key="submit" type="default" style={{borderColor: '#f8b200', color: '#f8b200'}} onClick={nextStep}>
                        {onboarding.calendar && onboarding.vc_tool ? 'Finish' : 'Next Step'}
                    </Button>,
                ]}
                closable={false}
                wrapClassName="onboarding-modal"
            >
                <Steps current={current}>
                    {steps.map(item => (
                        <Step key={item.title} title={item.title} />
                    ))}
                </Steps>
                <Progress strokeColor={VERSATIONAL} percent={((current + 1) / steps.length) * 100} showInfo={false} />
                <StepComponent />

                {isVideoOpened && visible ? <StyledWrapper>
                    <StyledIframeWrapper>
                        <Image src="/images/onboarding-steps.png" width={1024} height={768} />
                        <div style={{marginTop: '0.5rem'}}>
                            <StyledOpenButton type="primary" onClick={() => closeModal()}>Continue</StyledOpenButton>
                        </div>
                    </StyledIframeWrapper>
                    <StyledCloseButton type="text" icon={<CloseOutlined />} size="large" onClick={() => closeModal()} />
                </StyledWrapper> : null}

            </StyledModal>
        );
    }

    return <></>;
};

export default Onboarding;
