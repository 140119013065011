import { useCallback, useEffect, useState } from 'react';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import { v4 as uuidv4 } from 'uuid';

/**
 * Return meeting data if available
 * if not, creates new meeting data reference in db and then returning it - it is used for new conversations
 * */
export default function useMeetingData(eventId, title, isOutlook) {
    const [conversationId, setConversationId] = useState();
    const [conversationData, setConversationData] = useState();

    useEffect(() => {
        (async () => {
            if (!conversationId) {
                if (eventId) {
                    const { data } = await axios.get(
                        NEXT_ROUTES.CONVERSATIONS_MEETING_ID(eventId),
                    );
                    if (data) {
                        const { id } = data;
                        setConversationId(id);
                    }
                } else if (!eventId) {
                    // generate meeting id
                    const externalId = uuidv4();

                    const { data } = await axios.post(
                        NEXT_ROUTES.CONVERSATIONS,
                        {
                            meeting_id: externalId,
                            title,
                        },
                    );
                    if (data) {
                        const { id } = data;
                        setConversationId(id);
                    }
                }
            }
        })();
    }, [eventId, conversationId, title]);

    useEffect(() => {
        (async () => {
            if (isOutlook) return;

            if (conversationId) {
                const { data } = await axios.get(
                    NEXT_ROUTES.CONVERSATIONS_ID(conversationId),
                );
                if (data) {
                    setConversationData(data);
                }
            }
        })();
    }, [isOutlook, conversationId, eventId]);

    useEffect(() => {
        if (title && conversationData) {
            setConversationData({ ...conversationData, title });
        }
    }, [title]);

    const resetConversationDataError = useCallback(() => {
        setConversationData({ ...conversationData, transcript_status: null });
    }, [conversationData]);

    return [conversationId, conversationData, resetConversationDataError];
}
