export const getConfirmedQuotes = (quotes) =>
    quotes.filter(({ confirmed }) => confirmed);

export const getUnconfirmedQuotes = (quotes) =>
    quotes.filter(({ confirmed }) => !confirmed);

export const getQuotesToTagCount = (quotes) =>
    Array.from(
        quotes
            .reduce(
                (accumulator, currentValue) => [
                    ...accumulator,
                    ...currentValue.customTags,
                ],
                [],
            )
            .reduce((accumulator, currentValue) => {
                if (accumulator.get(currentValue.name)) {
                    accumulator.set(
                        currentValue.name,
                        accumulator.get(currentValue.name) + 1,
                    );
                } else {
                    accumulator.set(currentValue.name, 1);
                }
                return accumulator;
            }, new Map()),
    );

export const getQuotesToGemCount = (quotes) => 
    Array.from(
        quotes
            .reduce(
                (accumulator, currentValue) => [
                    ...accumulator,
                    ...currentValue.gems,
                ],
                [],
            )
            .reduce((accumulator, currentValue) => {
                if (accumulator.get(currentValue.name)) {
                    accumulator.set(
                        currentValue.name,
                        accumulator.get(currentValue.name) + 1,
                    );
                } else {
                    accumulator.set(currentValue.name, 1);
                }
                return accumulator;
            }, new Map()),
    );

export const getIndexes = (tsContent, id) => {
    const contentIndex = tsContent.findIndex((cont) =>
        cont.quotes.some((quote) => quote.id === id),
    );
    const quoteIndex = tsContent[contentIndex].quotes.findIndex(
        (quote) => quote.id === id,
    );

    return { contentIndex, quoteIndex };
};
