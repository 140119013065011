import {
    SETTINGS_TOPIC_TRACKER_FETCH_START,
    SETTINGS_TOPIC_TRACKER_FETCH_SUCCESS,
    SETTINGS_TOPIC_TRACKER_FETCH_FAILURE,
    SETTINGS_TOPIC_TRACKER_UPDATE_START,
    SETTINGS_TOPIC_TRACKER_UPDATE_SUCCESS,
    SETTINGS_TOPIC_TRACKER_UPDATE_FAILURE,
    SETTINGS_TOPIC_TRACKER_CREATE_START,
    SETTINGS_TOPIC_TRACKER_CREATE_SUCCESS,
    SETTINGS_TOPIC_TRACKER_CREATE_FAILURE,
    SETTINGS_TOPIC_TRACKER_DELETE_START,
    SETTINGS_TOPIC_TRACKER_DELETE_SUCCESS,
    SETTINGS_TOPIC_TRACKER_DELETE_FAILURE,
} from '@/redux/actions/settings/topicTrackerAction';
import { FETCH_STATUS } from '@/constants/fetchStatus';
import { CUSTOM_TAGS_DELETE_SUCCESS } from '@/redux/actions/customTagsAction';

export const initialState = {
    data: [],
    status: FETCH_STATUS.IDLE,
    error: null,
};

function settingsTopicTrackerReducer(state = initialState, action) {
    switch (action.type) {
        case SETTINGS_TOPIC_TRACKER_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_TOPIC_TRACKER_UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_UPDATE_SUCCESS: {
            const { id } = action.payload.data;
            return {
                data: state.data.map((topic) => {
                    if (topic.id === id) {
                        return { ...topic, ...action.payload.data };
                    }
                    return topic;
                }),
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_TOPIC_TRACKER_CREATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.CREATE_LOADING,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_CREATE_SUCCESS: {
            return {
                data: [...state.data, action.payload.data],
                status: FETCH_STATUS.CREATE_SUCCESS,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_CREATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.CREATE_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_TOPIC_TRACKER_DELETE_START: {
            return {
                ...state,
                status: FETCH_STATUS.DELETE_LOADING,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_DELETE_SUCCESS: {
            const { id } = action.payload.data;
            return {
                data: state.data.filter((topic) => topic.id !== id),
                status: FETCH_STATUS.DELETE_SUCCESS,
                error: null,
            };
        }
        case SETTINGS_TOPIC_TRACKER_DELETE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.DELETE_ERROR,
                error: action.payload.error,
            };
        }
        case CUSTOM_TAGS_DELETE_SUCCESS: {
            const { id } = action.payload.data;
            return {
                data: state.data.filter((topic) => topic.custom_tag_id !== id),
                status: FETCH_STATUS.DELETE_SUCCESS,
                error: null,
            };
        }
        default:
            return state;
    }
}

export default settingsTopicTrackerReducer;
