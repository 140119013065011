import { cacheAudioChunksOnLocalDb } from '@/utils/recording/recovery/saveUtils';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';

export function useDataSave(conversationId, meetingExternalId, userId, title) {
    const { onNewDataAvailable } = useMediaRecorderContext();

    onNewDataAvailable(async (chunks) => {
        if (conversationId && meetingExternalId && userId) {
            await cacheAudioChunksOnLocalDb(
                userId,
                conversationId,
                meetingExternalId,
                title,
                chunks,
            );
        }
    });
}
