import React from 'react';

function Icon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="19"
            height="5"
            fill="none"
            viewBox="0 0 19 5"
        >
            <path
                fill="currentColor"
                d="M14.844 2.375c0 1.002.779 1.781 1.781 1.781.965 0 1.781-.779 1.781-1.781 0-.965-.816-1.781-1.781-1.781a1.782 1.782 0 00-1.781 1.781zm-10.688 0c0-.965-.816-1.781-1.781-1.781A1.782 1.782 0 00.594 2.375c0 1.002.78 1.781 1.781 1.781.965 0 1.781-.779 1.781-1.781zm7.125 0c0-.965-.816-1.781-1.781-1.781a1.782 1.782 0 00-1.781 1.781c0 1.002.779 1.781 1.781 1.781.965 0 1.781-.779 1.781-1.781z"
            />
        </svg>
    );
}

export default Icon;
