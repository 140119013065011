import React from 'react';
import { number, string } from 'prop-types';
import {
    Container,
    Progress,
} from '@/components/uploadProgressBar/uploadProgressBar.styles';
import { VIVIDORANGE } from '@/constants/colors';

function UploadProgressBar({ percentage, height, width, color, transition }) {
    return (
        <Container $height={height} $width={width}>
            <Progress
                $progress={percentage}
                $bg={color}
                $transition={transition}
            />
        </Container>
    );
}

UploadProgressBar.defaultProps = {
    height: 2.25,
    color: VIVIDORANGE,
    transition: 2,
    width: 200,
};

UploadProgressBar.propTypes = {
    percentage: number.isRequired,
    height: number,
    color: string,
    transition: number,
    width: number,
};

export default UploadProgressBar;
