import { createContext, useContext } from 'react';

export const ConversationsContext = createContext({});
export const AttendeesContext = createContext({});
export const TranscriptionStatusContext = createContext({});
export const MeetingContext = createContext({});

export const useAttendeesContext = () => useContext(AttendeesContext);
export const useConversationContext = () => useContext(ConversationsContext);
export const useTranscriptionStatusContext = () =>
    useContext(TranscriptionStatusContext);
export const useMeetingContext = () => useContext(MeetingContext);
