import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { INVITES_KEY } from '@/constants/reduxKeys';
import invitesReducer from '@/redux/reducers/invitesReducer';
import InvitesSaga from '@/redux/sagas/invitesSaga';
import { fetchInvites } from '@/redux/actions/invitesAction';

export default function InvitesInjector() {
    useInjectReducer({ key: INVITES_KEY, reducer: invitesReducer });
    useInjectSaga({ key: INVITES_KEY, saga: InvitesSaga });

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchInvites());
    }, [dispatch]);

    return null;
}
