import { FETCH_STATUS } from '@/constants/fetchStatus';

export const getInvitesForTable = (state) =>
    state.invites?.data?.companyInvites?.map((invite) => ({
        ...invite,
        firstName: invite.email.split('@')[0],
        isInvite: true,
    })) ?? [];

export const getExternalInvites = (state) =>
    state.invites?.data?.externalInvites?.map((invite) => ({
        ...invite,
        firstName: invite.email.split('@')[0],
        isInvite: true,
    })) ?? [];

export const getNumberOfInvites = (state) => state.invites?.data?.companyInvites?.length ?? 0;

export const getInvitesLoading = (state) =>
    [
        'SEND_START',
        FETCH_STATUS.INITIAL_LOADING,
        FETCH_STATUS.REFETCH_LOADING,
        FETCH_STATUS.DELETE_LOADING,
    ].includes(state.invites?.status);

export const getInvitesRefetchLoading = (state) =>
    [FETCH_STATUS.INITIAL_LOADING, FETCH_STATUS.REFETCH_LOADING].includes(
        state.invites?.status,
    );

export const getInvitesUpdateLoading = (state) =>
    ['SEND_START', FETCH_STATUS.DELETE_LOADING].includes(state.invites?.status);
