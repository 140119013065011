import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    SETTINGS_NOTIFICATIONS_FETCH_START,
    SETTINGS_NOTIFICATIONS_UPDATE_FAILURE,
    SETTINGS_NOTIFICATIONS_UPDATE_START,
    SETTINGS_NOTIFICATIONS_FETCH_SUCCESS,
    SETTINGS_NOTIFICATIONS_FETCH_FAILURE,
    SETTINGS_NOTIFICATIONS_UPDATE_SUCCESS,
    SET_LAST_NOTIFICATION_TIME,
} from '@/redux/actions/settings/notificationsAction';

export const SETTINGS_PARTS_KEYS = {
    conversationNotification: 'notify_on_recording_upload',
    timeLimitNotification: 'notify_on_low_minutes',
    tagInCommentNotification: 'notify_on_comment_tag',
    repliesInCommentNotification: 'notify_on_comment_reply',
    pastConversationsNotification: 'notify_on_conversation_reply',
};

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
    lastNotificationTimestamp: 0,
};

function notificationsReducer(state = initialState, action) {
    switch (action.type) {
        case SETTINGS_NOTIFICATIONS_FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }

        case SETTINGS_NOTIFICATIONS_FETCH_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case SETTINGS_NOTIFICATIONS_FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case SETTINGS_NOTIFICATIONS_UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case SETTINGS_NOTIFICATIONS_UPDATE_SUCCESS: {
            return {
                data: action.payload.data,
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case SETTINGS_NOTIFICATIONS_UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case SET_LAST_NOTIFICATION_TIME: {
            return {
                ...state,
                lastNotificationTimestamp: action.payload.data,
            };
        }

        default:
            return state;
    }
}

export default notificationsReducer;
