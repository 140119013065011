import styled from 'styled-components';

export const Wrapper = styled.div`
    .ant-avatar {
        margin: 0;
    }

    .ant-typography,
    .ant-avatar-group {
        margin-bottom: 0;
    }
`;

export const BubbleAvatarWrapper = styled.div`
    position: relative;
    width: 50px;
    height: 50px;
    background: #c6c6c6;
    border-radius: 100px;
`;

export const BubbleAvatarContent = styled.span`
    position: absolute;
    inset: 0;
    margin: auto;
    height: fit-content;
    color: white;
    font-size: 16px;
`;
