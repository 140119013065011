const COMMENTS_BASE = 'COMMENTS_VIEW';

export const SET_SELECTED_QUOTE_COMMENT_INDEX = `${COMMENTS_BASE}/SET_SELECTED_QUOTE_COMMENT_INDEX`;
export const SET_COMMENTS_QUERY = `${COMMENTS_BASE}/SET_COMMENTS_QUERY`;
export const SET_SELECTED_GEM_TOPIC = `${COMMENTS_BASE}/SET_SELECTED_GEM_TOPIC`;
export const SET_SELECTED_TOPIC = `${COMMENTS_BASE}/SET_SELECTED_TOPIC`;

export const setSelectedQuoteCommentIndex = (quoteIndex) => ({
    type: SET_SELECTED_QUOTE_COMMENT_INDEX,
    payload: { data: quoteIndex },
});

export const setCommentsQuery = (commentsQuery) => ({
    type: SET_COMMENTS_QUERY,
    payload: { data: commentsQuery },
});
export const setSelectedGemTopic = (selectedGemTopic) => ({
    type: SET_SELECTED_GEM_TOPIC,
    payload: { data: selectedGemTopic },
});
export const setSelectedTopic = (selectedTopic) => ({
    type: SET_SELECTED_TOPIC,
    payload: { data: selectedTopic },
});
