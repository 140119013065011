/* eslint-disable indent */
import { FETCH_STATUS } from '@/constants/fetchStatus';
import { createSelector } from 'reselect';
import { getQuotesFromTranscriptInDisplayFormat } from '@/utils/conversation/transcript/quotes';
import { getIsAuthorized } from '@/redux/selectors/authSelector';
import { getTranscriptWords } from '@/redux/selectors/assemblyTranscriptSelector';
import { getWordsInDisplayFormat } from '@/utils/conversation/transcript/words';
import { getTeamMembers } from '@/redux/selectors/teamMembersSelector';
import { getTranscriptSelectedSpeakersSelector } from '@/redux/selectors/conversation/conversationResultViewSelector';

export const getTranscriptSelector = (state) =>
    state.transcript?.data?.transcript;

const emptyArray = [];

export const getTranscriptContentSelector = createSelector(
    [getTranscriptSelector],
    (transcript) => transcript?.content ?? emptyArray,
);

export const getTranscriptSpeakersSelector = createSelector(
    [getTranscriptSelector],
    (transcript) => transcript?.speakers ?? emptyArray,
);

const getPhoto = (teamMembers, speaker) =>
    teamMembers?.find(
        ({ id: teamMemberId }) => teamMemberId === speaker?.externalId,
    )?.photo;

export const getHiddenSpeakers = (state) =>
    state?.transcript?.hiddenSpeakers;

export const getTranscriptContentWithTimelineMetadata = createSelector(
    [
        getTranscriptContentSelector,
        getTeamMembers,
        getTranscriptSpeakersSelector,
        getTranscriptSelectedSpeakersSelector,
        getHiddenSpeakers,
    ],
    (tsContent, teamMembers, speakers, selectedTsSpeakers, hiddenSpeakers) =>
        tsContent.filter((v) => !hiddenSpeakers.includes(v.speakerId)).map((v) => {
            const speaker = speakers?.find((item) => item.id === v.speakerId);
            return {
                ...v,
                speaker,
                speakerPhoto: getPhoto(teamMembers, speaker),
                enabled: selectedTsSpeakers.some(
                    ({ id }) => id === v.speakerId,
                ),
            };
        }),
);

export const getTranscriptSelectorInitialSpeakersSelector = createSelector(
    [getTranscriptSelector],
    (transcript) => transcript?.initialSpeakers ?? emptyArray,
);

export const getTranscriptMediaSelector = (state) =>
    state.transcript?.data?.media;

export const getIsTranscriptLoaded = (state) =>
    [FETCH_STATUS.INITIAL_LOADED, FETCH_STATUS.UPDATE_SUCCESS].includes(
        state?.transcript?.status,
    );

export const getSentimentDataSelector = (state) =>
    state?.transcript?.data?.sentiment;

export const getEntitySentimentDataSelector = (state) =>
    state?.transcript?.data?.entitySentiment;

export const getAnySpeakersChangedSelector = createSelector(
    [
        getTranscriptSpeakersSelector,
        getTranscriptSelectorInitialSpeakersSelector,
    ],
    (speakers, initialSpeakers) =>
        speakers.reduce((prev, curr) => {
            if (prev === true) return true;
            const initialSpeakerName = initialSpeakers?.find(
                (v) => curr.id === v.id,
            )?.name;
            return initialSpeakerName !== curr.name;
        }, false),
);

export const getWordsSelector = createSelector(
    [getTranscriptContentSelector, getTranscriptWords],
    (transcriptContent, assemblyAiWord) =>
        getWordsInDisplayFormat(transcriptContent, assemblyAiWord),
);

export const getQuotesInDisplayFormat = createSelector(
    [getTranscriptSelector, getWordsSelector, getIsAuthorized, getHiddenSpeakers],
    (transcript, transcriptWords, isAuthorized, hiddenSpeakers) => {
        if (!transcriptWords || !isAuthorized) return [];

        return getQuotesFromTranscriptInDisplayFormat(
            transcript,
            transcriptWords,
            hiddenSpeakers,
        );
    },
);
export const getMediaUrlSelector = (state) =>
    state?.transcript?.data?.media?.mediaUrl;
