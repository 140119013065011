import { useCallback, useEffect, useMemo, useState } from 'react';

export const TimerState = Object.freeze({
    idle: 'idle',
    running: 'running',
    paused: 'paused',
});

export default function useTimer() {
    const [state, setState] = useState(TimerState.idle);
    const [seconds, setSeconds] = useState(0);

    useEffect(() => {
        let interval = null;

        if (state === TimerState.running) {
            interval = setInterval(() => {
                setSeconds((pSeconds) => pSeconds + 1);
            }, 1000);
        } else {
            clearInterval(interval);

            if (state === TimerState.idle) {
                setSeconds(0);
            }
        }
        return () => clearInterval(interval);
    }, [state, seconds]);

    const pause = useCallback(() => {
        setState(TimerState.paused);
    }, []);

    const start = useCallback(() => {
        setState(TimerState.running);
    }, []);

    const stop = useCallback(() => {
        setState(TimerState.idle);
        setSeconds(0);
    }, []);

    const toggle = useCallback(() => {
        setState((prev) =>
            prev === TimerState.running
                ? TimerState.paused
                : TimerState.running,
        );
    }, []);

    const updateState = useCallback((stateParam, secondsParam) => {
        setState(stateParam);
        setSeconds(secondsParam);
    }, []);

    const timerProps = useMemo(
        () => ({
            timerState: state,
            start,
            pause,
            stop,
            toggle,
            updateState,
        }),
        [pause, start, state, stop, toggle, updateState],
    );

    return {
        seconds,
        timerProps,
    };
}
