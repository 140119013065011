import { RECORDINGS_KEY } from '@/constants/reduxKeys';
import { createSelector } from 'reselect';
import { SEARCH_LENGTH_THRESHOLD } from '@/constants/config/searchLengthThreshold';

const getRecordingsState = (state) => state?.[RECORDINGS_KEY];

export const getRecordings = createSelector(
    [getRecordingsState],
    (state) => state?.recordings ?? [],
);

export const getRecordingsSimplified = createSelector(
    [getRecordingsState],
    (state) => state?.recordingsSimplified ?? [],
);

export const getAllRecordings = createSelector(
    [getRecordingsState],
    (state) => state?.allRecordings ?? [],
);

export const getAllRecordingsLength = (state) => getAllRecordings(state).length;
export const getAllRecordingsLoading = (state) =>
    getRecordingsState(state)?.allLoading;
export const getAllRecordingsFetchError = (state) =>
    getRecordingsState(state)?.allError;

export const getIsLoading = createSelector(
    [getRecordingsState],
    (state) => state?.loading,
);
export const getIsError = createSelector(
    [getRecordingsState],
    (state) => state?.error,
);

export const getPagination = createSelector(
    [getRecordingsState],
    (state) => state?.pagination,
);

export const getIsSearchEnabled = (state) =>
    getRecordingsState(state)?.searchValue?.length >= SEARCH_LENGTH_THRESHOLD;
export const getIsAnySearchDataPut = (state) =>
    getRecordingsState(state)?.searchValue !== '';
export const getSearchValue = (state) => getRecordingsState(state)?.searchValue;
export const getIsSearchNarrowed = (state) => {
    const searchValueLength = getRecordingsState(state)?.searchValue?.length;
    return searchValueLength < SEARCH_LENGTH_THRESHOLD && searchValueLength > 0;
};
export const getSearchTotalCommentsCount = (state) =>
    getRecordingsState(state)?.searchData?.commentCount;
export const getSearchTotalConversationsCount = (state) =>
    getRecordingsState(state)?.searchData?.conversationCount;