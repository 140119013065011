import styled from 'styled-components';
import { Collapse, Tabs } from 'antd';
import { GRAY_1, GRAY_5 } from '@/constants/colors';
import { InfoCircleFilled } from '@ant-design/icons';

const { Panel } = Collapse;

const SettingWrapper = styled.div`
    &.hide-tabs {
        .ant-tabs-nav {
            display: none;
        }
    }
`;

const InfoCircleIcon = styled(InfoCircleFilled)`
    color: #BDBDBD;
    
    svg {
        width: 1.25rem;
        margin-left: 0.5rem
    }
`;

const TabHeader = styled.h2`
    margin: 0;
    font-size: 1.5rem;
    line-height: 2rem;
    color: ${GRAY_1};
`;

const ColoredDivColumn = styled.div`
    display: inline-flex;
    min-width: 75%;
`;

const Divider = styled.div`
    border-top: 1px solid ${GRAY_5};
    margin: ${({ marginY }) => marginY} 0;
`;

const StyledCollapse = styled(Collapse)`
    .ant-collapse-content-box {
        padding: 0 !important;
        display: flex;
        justify-content: flex-start;
    }
`;

const StyledTabs = styled(Tabs)`
    .ant-tabs-content {
        margin-top: 1rem;
    }

    .ant-tabs-nav-list {
        width: 100%;
        gap: 4rem;
        text-transform: uppercase;
    }

    .ant-tabs-tab {
        justify-content: center;
        min-width: 5rem;
    }
`;

const StyledTabsEqualDistr = styled(StyledTabs)`
    .ant-tabs-nav-list {
        width: 100%;
        max-width: 90%;
        gap: 4rem;
        text-transform: uppercase;
    }

    .ant-tabs-tab {
        flex: 1;
        justify-content: center;
    }
`;

const StyledPanel = styled(Panel)`
    &&& .ant-collapse-header {
        padding-left: 1px;
    }
`;

export {
    TabHeader,
    Divider,
    StyledCollapse,
    StyledTabs,
    ColoredDivColumn,
    StyledTabsEqualDistr,
    StyledPanel,
    InfoCircleIcon,
    SettingWrapper,
};
