import { Card, Layout, Row, Col } from 'antd';
import styled from 'styled-components';
import { ArrowRightOutlined, CheckCircleTwoTone } from '@ant-design/icons';

const ColoredDivA = styled.div`
    margin-right: 10px;
    color: #1890ff;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    padding-top: 1rem;

    &:hover {
        text-decoration: underline;
    }
`;

const PageWrapper = styled(Layout)`
    height: 100%;
`;

const MainLayout = styled(Layout)`
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 5%;
`;

const LayoutContent = styled(Layout.Content)`
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
`;

const OuterCard = styled(Card)`
    padding: 5%;
`;

const LogoRow = styled(Row)`
    margin-bottom: 5%;
`;

const InnerCard = styled(Card)`
    text-align: left;
    cursor: ${(props) => (!props.hasToken && !props.disabled ? 'pointer' : 'auto')};
    margin-top: 5%;
`;

const OutlookImage = styled.img`
    width: 85%;
`;

const PaddedCol = styled(Col)`
    padding-left: 10px;
`;

const LargeCheckCircle = styled(CheckCircleTwoTone)`
    font-size: 20px;
    margin-left: 5px;
`;

const LargeArrowRight = styled(ArrowRightOutlined)`
    font-size: 50px;
`;

const SmallArrowRight = styled(ArrowRightOutlined)`
    font-size: 20px;
`;

export {
    ColoredDivA,
    InnerCard,
    LargeArrowRight,
    LargeCheckCircle,
    LayoutContent,
    LogoRow,
    MainLayout,
    OutlookImage,
    OuterCard,
    PaddedCol,
    PageWrapper,
    SmallArrowRight,
};
