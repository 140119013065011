import { put, takeEvery, select, call } from 'redux-saga/effects';
import { message, notification } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    USER_ARCHIVE_START,
    USER_DELETE_START,
    USER_REACTIVATE_START,
    USER_UPDATE_START,
    userArchiveFailure,
    userArchiveSuccess,
    userDeleteFailure,
    userDeleteSuccess,
    userReactivateFailure,
    userReactivateSuccess,
    userUpdateFailure,
    userUpdateSuccess,
} from '@/redux/actions/usersAction';
import { getCanReactivatePerson } from '@/redux/selectors/subscriptionSelector';
import waitFor from '@/utils/waitForStateGenerator/waitFor';
import { getTeamMembersInitialOrUpdateLoading } from '@/redux/selectors/teamMembersSelector';

async function updateUser(id, data) {
    const _data = { ...data };
    if (Object.hasOwn(_data, 'id')) {
        delete _data.id;
    }
    return axios.patch(NEXT_ROUTES.USERS_ID(id), _data);
}

async function deleteUser(id) {
    return axios.delete(NEXT_ROUTES.USERS_ID(id));
}

async function archiveUser(id) {
    return axios.post(NEXT_ROUTES.USERS_ID_ARCHIVE(id));
}

async function reactivateUser(id) {
    return axios.post(NEXT_ROUTES.USERS_ID_REACTIVATE(id));
}

function* updateUserSaga(action) {
    const { data } = action.payload;

    try {
        yield updateUser(data.id, data);
        yield put(userUpdateSuccess(data));
    } catch (e) {
        message.error('User update failed');
        yield put(userUpdateFailure(e));
    }
}

function* deleteUserSaga(action) {
    const id = action.payload.data;

    try {
        yield deleteUser(id);
        yield put(userDeleteSuccess(id));
    } catch (e) {
        message.error('User deletion failed');
        yield put(userDeleteFailure(id, e));
    }
}

function* archiveUserSaga(action) {
    const { id } = action.payload;

    try {
        yield archiveUser(id);
        yield put(userArchiveSuccess(id));
    } catch (e) {
        message.error('User archiving failed');
        yield put(userArchiveFailure(id, e));
    }
}

function* reactivateUserSaga(action) {
    const { id } = action.payload;

    try {
        if (yield select(getCanReactivatePerson)) {
            yield reactivateUser(id);
            yield put(userReactivateSuccess(id));
        } else {
            throw new Error('NOT_ENOUGH_SEATS');
        }
    } catch (e) {
        if (e.message === 'NOT_ENOUGH_SEATS') {
            yield call(notification.error, {
                message: 'Error',
                description:
                    'You cannot reactivate this user since you do not have enough seats',
                placement: 'bottomRight',
            });
        } else {
            message.error('User reactivation failed');
        }
        // WAIT FOR FIXES RACE CONDITION WITH TEAM MEMBERS SAGA WHEN THERE IS NO API CALL
        yield call(waitFor, getTeamMembersInitialOrUpdateLoading);
        yield put(userReactivateFailure(id, e));
    }
}

export default function* usersSaga() {
    yield takeEvery(USER_UPDATE_START, updateUserSaga);
    yield takeEvery(USER_DELETE_START, deleteUserSaga);
    yield takeEvery(USER_REACTIVATE_START, reactivateUserSaga);
    yield takeEvery(USER_ARCHIVE_START, archiveUserSaga);
}
