import { FETCH_STATUS } from '@/constants/fetchStatus';
import { DEFAULT_TRIAL_PLAN_CODE } from '@/constants/subscriptionStatus';
import { getHasSubscription } from '@/redux/selectors/authSelector';
import createCachedSelector from 're-reselect';
import { createSelector } from 'reselect';

const getSubscriptionPlansData = (state) => state.subscriptionPlans?.data ?? [];

export const getSubscriptionPlans = createSelector(
    [getSubscriptionPlansData, getHasSubscription],
    (plans, hasSubscription) => {
        if (hasSubscription) {
            return plans.filter(
                (plan) => plan.status === 'active' && (!plan.trial_period || plan.plan_code.includes('VB')) && (!plan.plan_code.includes('BS') && !plan.plan_code.includes('BFM') && !plan.plan_code.includes('BEM') && !plan.name.includes('Individual (Monthly)') && !plan.name.includes('Individual (Annual)')),
            );
        }
        return plans.filter((plan) => plan.status === 'active');
    },
);

export const getSubscriptionBetaPlans = createSelector(
    [getSubscriptionPlansData, getHasSubscription],
    (plans, hasSubscription) => {
        if (hasSubscription) {
            return plans.filter(
                (plan) => plan.status === 'active' && !plan.trial_period && (plan.plan_code.includes('BS') || plan.plan_code.includes('BFM') || plan.plan_code.includes('BEM')),
            );
        }
        return plans.filter((plan) => plan.status === 'active');
    },
);

export const getIsSubscriptionPlansLoading = (state) =>
    state.subscriptionPlans?.status === FETCH_STATUS.INITIAL_LOADING;

export const getIsSubscriptionPlansIdleState = (state) =>
    state.subscriptionPlans?.status === FETCH_STATUS.IDLE;

export const getTrialPlan = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => !(plan.plan_code.includes('BS') || plan.plan_code.includes('BFM') || plan.plan_code.includes('BEM')))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanBeta = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('BS'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanPMAlly = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('BFM'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanPMAllyFree = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('VB'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanPMAllyIndividual = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('VI') && !plan.plan_code.includes('ANNUAL'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanPMAllyTeams = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('VT') && !plan.plan_code.includes('ANNUAL'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanPMAllyIndividualAnnual = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('VI') && plan.plan_code.includes('ANNUAL'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getTrialPlanPMAllyTeamsAnnual = createSelector(
    [getSubscriptionPlansData],
    (plans) =>
        plans
            .filter((plan) => plan.status === 'active' && plan.trial_period)
            .filter((plan) => plan.plan_code.includes('VT') && plan.plan_code.includes('ANNUAL'))
            .find((p) => p.trial_period > 0)?.plan_code ??
        DEFAULT_TRIAL_PLAN_CODE,
);

export const getSubscriptionPlansError = (state) =>
    state.subscriptionPlans?.error;

export const getPlanChosenInformation = createCachedSelector(
    getSubscriptionPlansData,
    (state, planChosen) => planChosen,
    (plans, planChosen) => plans.find((p) => p.plan_code === planChosen),
)((state, planChosen) => planChosen);
