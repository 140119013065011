import {
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('CONVERSATION_RESULT');

export const CONVERSATION_RESULT = {
    ...creator.fetch(),
    GUEST_FETCH_START: creator.rawCustom('GUEST_FETCH_START'),
    RESET: creator.rawCustom('RESET'),
};

export const fetchConversationResult = (conversationId) =>
    fetchData(CONVERSATION_RESULT.FETCH_START, {
        id: conversationId,
    });

export const fetchGuestConversationResult = (conversationId) =>
    fetchData(CONVERSATION_RESULT.GUEST_FETCH_START, {
        id: conversationId,
    });

export const fetchConversationResultSuccess = (data) =>
    fetchSuccess(CONVERSATION_RESULT.FETCH_SUCCESS, data);
export const fetchConversationResultFailure = (error) =>
    fetchFailure(CONVERSATION_RESULT.FETCH_FAILURE, error);

export const resetConversationResult = () => ({
    type: CONVERSATION_RESULT.RESET,
});
