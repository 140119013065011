import { useSelector } from 'react-redux';
import PanelHeader from '@/components/settings/tabs/panelHeader/panelHeader';
import { arrayOf, node, shape, string } from 'prop-types';
import { Fragment, useMemo } from 'react';
import { getIsFree } from '@/redux/selectors/subscriptionSelector';
import {
    Divider,
    StyledTabs,
    StyledTabsEqualDistr,
    TabHeader,
    InfoCircleIcon,
    SettingWrapper,
} from '@/components/settings/tabs/shared.styles';
import { Tabs, Tooltip } from 'antd';

export function TitleWrapper({ title, desc, children }) {
    return (
        <div>
            <PanelHeader title={title} tooltip={desc} />
            <div style={{ height: '1rem' }} />
            {children}
        </div>
    );
}

TitleWrapper.defaultProps = {
    desc: undefined,
};

TitleWrapper.propTypes = {
    title: string.isRequired,
    desc: string,
    children: node.isRequired,
};

function SettingsContainer({ pageTitle, items, pageTitleTooltip }) {
    const isFree = useSelector(getIsFree);

    const content = useMemo(
        () => (
            <Fragment>
                {items.map(({ title, desc, Component, disabledInFree }) => (
                    <Tabs.TabPane key={title} tab={title} disabled={disabledInFree && isFree}>
                        {disabledInFree && isFree ? '' : <TitleWrapper title={title} desc={desc}>
                            <Component />
                        </TitleWrapper>}
                    </Tabs.TabPane>
                ))}
            </Fragment>
        ),
        [items, isFree],
    );

    return (
        <Fragment>
            <TabHeader>
                {pageTitle}
                {pageTitleTooltip && <Tooltip
                    placement="left"
                    color="#FFFFFF"
                    overlayInnerStyle={{
                        color: 'gray',
                        width: '500px',
                        padding: '1rem',
                    }}
                    title={pageTitleTooltip}
                >
                    <InfoCircleIcon />
                </Tooltip>}
            </TabHeader>
            <Divider />
            <SettingWrapper className={pageTitleTooltip && "hide-tabs"}>
                {items.length > 5 ? (
                    <StyledTabsEqualDistr>{content}</StyledTabsEqualDistr>
                ) : (
                    <StyledTabs>{content}</StyledTabs>
                )}
            </SettingWrapper>
        </Fragment>
    );
}

SettingsContainer.defaultProps = {
    pageTitleTooltip: undefined,
};

SettingsContainer.propTypes = {
    pageTitle: string.isRequired,
    items: arrayOf(
        shape({
            title: string.isRequired,
            desc: string,
            Component: node.isRequired,
        }),
    ).isRequired,
    pageTitleTooltip: node,
};

export function SettingsContainerCustomChildren({ pageTitle, children }) {
    return (
        <Fragment>
            <TabHeader>{pageTitle}</TabHeader>
            <Divider />
            {children}
        </Fragment>
    );
}

SettingsContainerCustomChildren.propTypes = {
    pageTitle: string.isRequired,
    children: node.isRequired
};


export default SettingsContainer;
