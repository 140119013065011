export const RECORDINGS_RECOVERY_READ_START = 'RECORDINGS_RECOVERY/READ_START';
export const RECORDINGS_RECOVERY_READ_SUCCESS =
    'RECORDINGS_RECOVERY/READ_SUCCESS';
export const RECORDINGS_RECOVERY_READ_FAILURE =
    'RECORDINGS_RECOVERY/READ_FAILURE';
export const RECORDINGS_RECOVERY_NO_DATA = 'RECORDINGS_RECOVERY/NO_DATA';

export const readRecordingsRecoveryData = () => ({
    type: RECORDINGS_RECOVERY_READ_START,
});

export const recordingsRecoveryReadSuccess = (data) => ({
    type: RECORDINGS_RECOVERY_READ_SUCCESS,
    payload: { data },
});

export const recordingsRecoveryReadSuccessNoData = () => ({
    type: RECORDINGS_RECOVERY_NO_DATA,
});

export const clearRecordingsRecoveryData = () => ({
    type: RECORDINGS_RECOVERY_NO_DATA,
});

export const recordingsRecoveryReadFailure = (error) => ({
    type: RECORDINGS_RECOVERY_READ_FAILURE,
    payload: { error },
});
