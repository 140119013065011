import { put, takeLatest, call } from 'redux-saga/effects';

import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchAssemblyTranscriptFailure,
    fetchAssemblyTranscriptSuccess,
    ASSEMBLY_TRANSCRIPT_FETCH_START,
} from '@/redux/actions/assemblyTranscriptAction';
import { fetchAPISaga } from '@/utils/api/axios';

function* fetchAssemblyTranscript(action) {
    try {
        const { data } = yield call(
            fetchAPISaga,
            NEXT_ROUTES.ASSEMBLY_AI_TRANSCRIPT(action.payload.data),
        );
        yield put(fetchAssemblyTranscriptSuccess(data));
    } catch (err) {
        yield put(fetchAssemblyTranscriptFailure(err));
    }
}

export default function* assemblyTranscriptSaga() {
    yield takeLatest(ASSEMBLY_TRANSCRIPT_FETCH_START, fetchAssemblyTranscript);
}
