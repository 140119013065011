import React from 'react';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { TimeWrapper } from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import { useTimeContext } from '@/components/timer/timerContext';

dayjs.extend(duration);

function Time() {
    const seconds = useTimeContext();

    return (
        <TimeWrapper>
            {dayjs.duration(seconds * 1000).format('HH:mm:ss')}
        </TimeWrapper>
    );
}

export default Time;
