import { Button, Space } from 'antd';
import styled from 'styled-components';
import { faArrowLeft, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { GRAY_1, GRAY_3 } from '@/constants/colors';

const TitleContainer = styled(Space).attrs(() => ({ align: 'center' }))`
    min-width: 250px;
    padding: 0.125rem 0;
`;

const BackButton = styled(Button).attrs(() => ({
    icon: <FontAwesomeIcon color={GRAY_3} icon={faArrowLeft} />,
}))`
    padding: 0.5rem 0.5rem;
    align-self: center;
    height: 100%;
    width: 100%;
`;

const TitleWrapper = styled.input`
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1.75rem;
    color: ${GRAY_1};
    border: none;
    background: transparent;
    margin-right: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    :focus-visible {
        outline-width: 0;
    }
`;

const HeaderTime = styled.div`
    font-family: Roboto, monospace;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.125rem;
    color: ${GRAY_3};
    display: inline-block;
    margin-right: 0.5rem;
`;

const PencilIcon = styled(FontAwesomeIcon).attrs(() => ({
    icon: faPencilAlt,
    className: 'icon',
}))`
    font-size: 1rem !important;
    color: ${GRAY_3};
    margin-top: 0.25rem;
    opacity: 0;
    cursor: pointer;
`;

const EditableContainer = styled.span`
    display: block;
    font-size: 0;
    cursor: pointer;

    &:hover {
        ${PencilIcon} {
            opacity: 1;
        }
    }

    .ant-picker {
        padding: 0 2px;
        background: transparent;
        border: none;

        input {
            font-family: Roboto, monospace;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.125rem;
            color: ${GRAY_3};
            min-width: 240px;
        }
    }

    .anticon {
        display: none;
    }
`;

const HeaderTitle = styled.h1`
    font-size: 24px;
    font-weight: 600;
    margin: 0;
`;

export {
    TitleContainer,
    BackButton,
    TitleWrapper,
    HeaderTime,
    PencilIcon,
    EditableContainer,
    HeaderTitle,
};
