import { put, takeEvery, select, call } from 'redux-saga/effects';
import { message } from 'antd';
import axios from '@/utils/api/axios';
import { NEXT_ROUTES } from '@/constants/routes';
import {
    fetchSettingsNotificationsFailure,
    fetchSettingsNotificationsSuccess,
    SETTINGS_NOTIFICATIONS_FETCH_START,
    SETTINGS_NOTIFICATIONS_UPDATE_START,
    settingsNotificationsUpdateFailure,
    settingsNotificationsUpdateSuccess,
} from '@/redux/actions/settings/notificationsAction';
import { getNotificationsData } from '@/redux/selectors/notificationsSelector';

async function fetchNotificationsSettings() {
    return axios.get(NEXT_ROUTES.SETTINGS_NOTIFICATIONS);
}

export function* getNotificationsSettings() {
    try {
        const { data } = yield call(fetchNotificationsSettings);
        yield put(fetchSettingsNotificationsSuccess(data));
    } catch (err) {
        yield put(fetchSettingsNotificationsFailure(err));
    }
}

async function updateNotificationsSetting(data) {
    return axios.patch(NEXT_ROUTES.SETTINGS_NOTIFICATIONS, data);
}

export function* updateCalendarSettings(action) {
    const { data } = action.payload;
    const existingData = yield select(getNotificationsData);

    try {
        yield updateNotificationsSetting({
            ...existingData,
            ...data,
        });
        yield put(
            settingsNotificationsUpdateSuccess({
                ...existingData,
                ...data,
            }),
        );
    } catch (e) {
        message.error('Settings update failed');
        yield put(settingsNotificationsUpdateFailure(e));
    }
}

export default function* settingsNotificationsSaga() {
    yield takeEvery(
        SETTINGS_NOTIFICATIONS_FETCH_START,
        getNotificationsSettings,
    );
    yield takeEvery(
        SETTINGS_NOTIFICATIONS_UPDATE_START,
        updateCalendarSettings,
    );
}
