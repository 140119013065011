import { AUTH_STATUS } from '@/constants/authStatus';
import { subscriptionFeatureFlag } from '@/constants/featureFlags';
import { USER_PERMISSION_TYPE } from '@/constants/userPermissionTypes';
import { capitalize } from 'lodash/string';
import { createSelector } from 'reselect';

export const getUserSelector = (state) => state?.user?.user;
export const getUserPhoto = (state) => state?.user?.user?.photo;
export const getUserTitle = (state) =>
    capitalize(state?.user?.user?.title ?? '');
export const getUserInitials = (state) =>
    `${state?.user?.user?.firstName?.[0]}${state?.user?.user?.lastName?.[0]}`;
export const getUserFullName = (state) =>
    `${state?.user?.user?.firstName} ${state?.user?.user?.lastName}`;

export const getUserFirstName = (state) => state?.user?.user?.firstName;

export const getUserEmail = (state) => state?.user?.user?.email;

export const getUserCrmOrganization = (state) =>
    state?.user?.user?.crm_organization_id;

export const getIsUserCrmAdmin = (state) => state?.user?.user?.is_crm_admin;

export const getUserCrmName = (state) => state?.user?.user?.crm_name;

export const getUserTimezone = (state) => state?.user?.user?.timezone;

export const getIsCrmMapped = (state) => state?.user?.user?.crm_mapped;

export const getIsCrmPending = (state) => state?.user?.user?.crm_pending;

export const getIsCrmActive = (state) => state?.user?.user?.crm_active;

export const getUserIdSelector = (state) => state?.user?.user?.id;

export const getCurrentProvider = (state) =>
    state?.user?.user?.current_provider;

export const getIsVersationalUser = (state) =>
    getCurrentProvider(state) === 'versational';

export const getIsAdminSelector = (state) =>
    state?.user?.user?.permission_role === USER_PERMISSION_TYPE.ADMIN;

export const getIsManagerSelector = (state) =>
    state?.user?.user?.permission_role === USER_PERMISSION_TYPE.MANAGER;

export const getUserPermissionRole = (state) =>
    state?.user?.user?.permission_role;

export const getIsAdminOrManagerSelector = (state) =>
    getIsAdminSelector(state) || getIsManagerSelector(state);

export const getUserInfoLoadingSelector = (state) =>
    state.user?.status === AUTH_STATUS.USER_INFO_LOADING ||
    state.user?.status === AUTH_STATUS.IDLE;

export const getIsAuthorized = (state) => state?.user?.isAuthorized;
export const getIsGuestAuthorized = (state) => state?.user?.isGuestAuthorized;

export const getHasSubscription = (state) =>
    subscriptionFeatureFlag
        ? state.user?.status !== AUTH_STATUS.NO_SUBSCRIPTION
        : true;

export const getMaxMinutes = (state) => state.user?.user?.max_minutes ?? 1;
export const getUsedMinutes = (state) => state.user?.user?.used_minutes ?? 0;

export const getMinutesRatio = (state) =>
    getUsedMinutes(state) / getMaxMinutes(state);
export const getMinutesLeft = (state) =>
    getMaxMinutes(state) - getUsedMinutes(state);

export const getUserCurrentProvider = createSelector(
    [getUserSelector],
    (user) => user?.current_provider,
);

export const getIsUserCurrentProvider = createSelector(
    [getUserSelector, (state, provider) => provider],
    (user, provider) => user?.current_provider === provider,
);

export const emailChangeLoading = (state) => !!state.user?.emailChangeLoading;
export const passwordChangeLoading = (state) =>
    !!state.user?.passwordChangeLoading;

export const getIsBetaUser = (state) => state?.user?.user?.account_type === "BETA";

export const getUserCompanyId = (state) =>
    state?.user?.user?.company_id;

export const getIsPMAllyDismissed = (state) => state?.user?.pm_ally_dismissed ?? false;

export const getOnboarding = (state) => state?.user?.user?.onboarding;

export const getUserMeta = (state) => state?.user?.user?.user_meta;

export const getUserMetaByKey = (state, key) => {
    if (!state?.user?.user?.user_meta) return null;

    return state?.user?.user?.user_meta?.find((meta) => meta.meta_key === key);
}