import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { EVENTS_KEY } from '@/constants/reduxKeys';
import calendarEventsReducer from '@/redux/reducers/calendarEventsReducer';
import calendarsEventsSaga from '@/redux/sagas/calendarEventsSaga';
import {
    clearEventData,
    fetchEventsIdStart,
} from '@/redux/actions/calendarAction';

export default function useEventId(id) {
    const state = useSelector((_state) => _state.events) || {};

    useInjectReducer({
        key: EVENTS_KEY,
        reducer: calendarEventsReducer,
    });

    useInjectSaga({ key: EVENTS_KEY, saga: calendarsEventsSaga });
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(fetchEventsIdStart(id));
        }

        return () => {
            dispatch(clearEventData());
        };
    }, [dispatch, id]);

    return state;
}
