export const getEditedContent = (
    content,
    contentIndex,
    quoteIndex,
    value,
    words,
    startsAt,
    endsAt,
) =>
    content.map((v, i) => {
        if (i !== contentIndex) return v;

        let newWords = [...words];
        newWords.splice(startsAt, endsAt - startsAt, value);
        newWords = newWords.join('');

        return {
            ...v,
            words: newWords,
            quotes: v.quotes.map((quote, qi) => {
                if (quoteIndex !== qi) return quote;
                return {
                    ...quote,
                    endsAt: startsAt + value.length,
                };
            }),
        };
    });
