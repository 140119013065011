const emptySentiment = { score: 0, magnitude: 0 };

export const getSentenceSentiment = (sentence, sentimentData) => {
    const sentimentItem = sentimentData?.sentences.find(
        ({ text: { content } }) => content.includes(sentence),
    );
    return sentimentItem ? sentimentItem.sentiment : emptySentiment;
};

export const getSentencesSentiments = (sentences, sentimentData) =>
    sentences.map((sentence) => ({
        sentence,
        sentiment: getSentenceSentiment(sentence, sentimentData),
    }));
