import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { SUBSCRIPTION_KEY } from '@/constants/reduxKeys';
import subscriptionReducer from '@/redux/reducers/subscriptionReducer';
import subscriptionSaga from '@/redux/sagas/subscriptionSaga';

export default function SubscriptionInjector() {
    useInjectReducer({ key: SUBSCRIPTION_KEY, reducer: subscriptionReducer });
    useInjectSaga({ key: SUBSCRIPTION_KEY, saga: subscriptionSaga });
    return null;
}
