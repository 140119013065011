export const formatDuration = (duration, withSeconds = true) => {
    const date = new Date(0);
    date.setSeconds(duration / 1000);
    let tempDate = date.toISOString().substr(11, withSeconds ? 8 : 5);
    if (tempDate.substr(0, 3) === '00:') {
        tempDate = tempDate.substr(3);
    }
    return tempDate;
};

export const formatVideoDuration = (duration) => {
    const date = new Date(0);
    date.setSeconds(duration / 1000);
    let tempDate = date.toISOString().substr(11, 8);
    if (tempDate.match(/^0[0-9]{1}:[0-9]{2}:[0-9]{2}/)) {
        tempDate = tempDate.substr(1);
    }
    return tempDate;
};
