import { useCallback } from 'react';
import { getRecordedChunksMeta } from '@/utils/recording/recovery/readUtils';
import { TimerState } from '@/components/timer/useTimer';
import { useMediaRecorderContext } from '@/components/conversations/conversations/headerActionsButton/recordingDropdown/mediaRecorderContext';
import { useTimerContext } from '@/components/timer/timerContext';

export function useLoadRecoveryData(recoveryData) {
    const { startOnPause, preloadAudioData } = useMediaRecorderContext();
    const { updateState } = useTimerContext();

    return useCallback(async () => {
        const { chunks } = recoveryData;
        if (chunks.length > 0) {
            preloadAudioData(chunks);
            await startOnPause();
            const [audioDuration] = getRecordedChunksMeta(chunks);
            updateState(TimerState.paused, Math.floor(audioDuration / 1000));
        } else {
            console.debug(
                'No recovery data found or recovery method has not been called yet.',
            );
        }
    }, [preloadAudioData, recoveryData, startOnPause, updateState]);
}
