import { UserTypes } from '@/constants/conversations/userTypes';
import { AttendeeTypes } from '@/constants/conversations/attendeeTypes';

export function filterSpeakersByType(usersType, speakers) {
    if (!speakers || speakers.length === 0) return [];
    switch (usersType) {
        case UserTypes.everyone:
            return speakers;
        case UserTypes.owners:
            return speakers.filter(
                (speaker) => speaker.type === AttendeeTypes.teamMember,
            );
        case UserTypes.contacts:
            return speakers.filter(
                (speaker) => speaker.type === AttendeeTypes.contact,
            );
        default:
            return [];
    }
}
