import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import durationPlugin from 'dayjs/plugin/duration';
import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(relativeTime);
dayjs.extend(durationPlugin);
dayjs.extend(advancedFormat);

export default function fromNow(isoString) {
    const refObj = dayjs(isoString);
    const _diff = refObj.diff(dayjs());

    const absMinutesDiff = Math.abs(Math.round(_diff / 1000 / 60));

    // Created for specific purpose of view - should be replaced if needed
    if (absMinutesDiff < 10) return '';

    const result = _diff > 0 ? refObj.fromNow(true) : refObj.fromNow();

    if (result === 'a day ago') return 'yesterday';
    if (result === 'a day') return 'tomorrow';

    return result;
}
