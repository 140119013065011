import styled from 'styled-components';
import { GRAY_6 } from '@/constants/colors';

const Container = styled.div`
    position: relative;
    ${({ $height }) => `height: ${$height}rem;`};
    ${({ $width }) => `width: ${$width}px`};
    display: flex;
    background-color: ${GRAY_6};
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    overflow: hidden;
`;

const Progress = styled.div`
    position: absolute;
    left: 0;
    ${({ $bg }) => `background-color: ${$bg};`};
    ${({ $progress }) => `width: ${$progress}%`};
    height: 100%;
    ${({ $transition }) => `transition: width ${$transition}s;`};
`;

const TextWrapper = styled.div`
    font-size: 1rem;
    line-height: 1.25rem;
    position: absolute;
    z-index: 10;
    align-self: center;
`;

export { Container, TextWrapper, Progress };
