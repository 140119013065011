import { IfAuthorized } from '@/components/ifAuthorized/ifAuthorized';
import { USER_PERMISSION_TYPE } from '@/constants/userPermissionTypes';
import { ColoredDivTableHeader } from '@/components/settings/team/allTeamMembers/allTeamMembers.style';
import { TableButton } from '@/components/settings/team/allTeamMembers/tableComponents/tableButton';

export function TableTitle() {
    return (
        <ColoredDivTableHeader>
            <IfAuthorized permissionsNeeded={[USER_PERMISSION_TYPE.ADMIN]}>
                <TableButton />
            </IfAuthorized>
        </ColoredDivTableHeader>
    );
}
