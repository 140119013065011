import LinkDropdown from '@/components/conversations/conversations/headerActionsButton/linkDropdown/linkDropdown';
import {
    ButtonsContainer,
    TimerIcon,
    UploadIcon,
} from '@/components/conversations/conversations/headerActionsButton/headerActionsButton.styles';
import { TimerState } from '@/components/timer/useTimer';
import { Space } from 'antd';
import Time from '@/components/conversations/conversations/headerActionsButton/time/time';
import React from 'react';
import {
    useTimeContext,
    useTimerContext,
} from '@/components/timer/timerContext';
import { func, node } from 'prop-types';

function TimerActions({ setUploadModalVisible, recordingDropdown }) {
    const seconds = useTimeContext();

    const { timerState, toggle: toggleTimer } = useTimerContext();

    return (
        <ButtonsContainer>
            <UploadIcon
                onClick={() => {
                    setUploadModalVisible(true);
                }}
            />
            <LinkDropdown />
            {(!!seconds || timerState === TimerState.running) && (
                <Space
                    size={0}
                    onClick={() => {
                        toggleTimer();
                    }}
                >
                    <TimerIcon large data-testid="start-timer-icon" />
                    <Time />
                    {recordingDropdown}
                </Space>
            )}
        </ButtonsContainer>
    );
}

TimerActions.propTypes = {
    setUploadModalVisible: func.isRequired,
    recordingDropdown: node.isRequired,
};

export default TimerActions;
