import { AI_TOPICS_TYPE, MANAGEMENT_TYPES } from '@/constants/settings/gemSettingsTypes';
import { FETCH_STATUS } from '@/constants/fetchStatus';
import { GEM_SETTINGS } from '@/redux/actions/settings/gemSettingsAction';

const initialState = {
    data: [],
    status: FETCH_STATUS.IDLE,
    error: null,
    selectedTab: AI_TOPICS_TYPE.SALES,
};

function aiTopicsReduce(state = initialState, action) {
    switch (action.type) {
        case GEM_SETTINGS.SET_SELECTED_TAB: {
            return {
                ...state,
                selectedTab: action.payload.data,
            };
        }
        case GEM_SETTINGS.FETCH_START: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_LOADING,
                error: null,
            };
        }
        case GEM_SETTINGS.FETCH_SUCCESS: {
            return {
                ...state,
                data: action.payload.data,
                status: FETCH_STATUS.INITIAL_LOADED,
                error: null,
            };
        }
        case GEM_SETTINGS.FETCH_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.INITIAL_ERROR,
                error: action.payload.error,
            };
        }
        case GEM_SETTINGS.UPDATE_START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case GEM_SETTINGS.UPDATE_SUCCESS: {
            return {
                ...state,
                data: state.data.map((gem) => {
                    if (gem.id === action.payload.data.id) {
                        return action.payload.data;
                    }
                    return gem;
                }),
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case GEM_SETTINGS.UPDATE_FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case GEM_SETTINGS.BULK_ENABLE.START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case GEM_SETTINGS.BULK_ENABLE.SUCCESS: {
            return {
                ...state,
                data: state.data
                    .map((gem) => {
                        if (MANAGEMENT_TYPES.includes(gem.gem.name)) {
                            return {
                                ...gem,
                                gem: {
                                    ...gem.gem,
                                    type: {
                                        ...gem.gem.type,
                                        name: 'Management',
                                    }
                                },
                            };
                        }
                        return gem;
                    })
                    .map((gem) => {
                        if (gem.gem.type.name.toLowerCase() === state.selectedTab) {

                            return {
                                ...gem,
                                enabled: true,
                            };
                        }
                        return gem;
                    }),
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case GEM_SETTINGS.BULK_ENABLE.FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        case GEM_SETTINGS.BULK_DISABLE.START: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_LOADING,
                error: null,
            };
        }
        case GEM_SETTINGS.BULK_DISABLE.SUCCESS: {
            return {
                ...state,
                data: state.data
                    .map((gem) => {
                        if (MANAGEMENT_TYPES.includes(gem.gem.name)) {
                            return {
                                ...gem,
                                gem: {
                                    ...gem.gem,
                                    type: {
                                        ...gem.gem.type,
                                        name: 'Management',
                                    }
                                },
                            };
                        }
                        return gem;
                    })    
                    .map((gem) => {
                        if (gem.gem.type.name.toLowerCase() === state.selectedTab)
                            return {
                                ...gem,
                                enabled: false,
                            };
                        return gem;
                    }),
                status: FETCH_STATUS.UPDATE_SUCCESS,
                error: null,
            };
        }
        case GEM_SETTINGS.BULK_DISABLE.FAILURE: {
            return {
                ...state,
                status: FETCH_STATUS.UPDATE_ERROR,
                error: action.payload.error,
            };
        }
        default:
            return state;
    }
}

export default aiTopicsReduce;
