import {
    fetchData,
    fetchFailure,
    fetchSuccess,
} from '@/redux/actions/genericActions';

export const ASSEMBLY_TRANSCRIPT_FETCH_START =
    'ASSEMBLY_TRANSCRIPT/FETCH_START';
export const ASSEMBLY_TRANSCRIPT_FETCH_SUCCESS =
    'ASSEMBLY_TRANSCRIPT/FETCH_SUCCESS';
export const ASSEMBLY_TRANSCRIPT_FETCH_FAILURE =
    'ASSEMBLY_TRANSCRIPT/FETCH_FAILURE';

export const fetchAssemblyTranscript = (id) =>
    fetchData(ASSEMBLY_TRANSCRIPT_FETCH_START, id);
export const fetchAssemblyTranscriptSuccess = (data) =>
    fetchSuccess(ASSEMBLY_TRANSCRIPT_FETCH_SUCCESS, data);
export const fetchAssemblyTranscriptFailure = (error) =>
    fetchFailure(ASSEMBLY_TRANSCRIPT_FETCH_FAILURE, error);
