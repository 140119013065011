import { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, notification, Row, Select, Tooltip } from 'antd';
import { useRouter } from 'next/router';
import { bool } from 'prop-types';
import { CHECK_CIRCLE_TWO_TONE_COLOR, DUSTYGRAY } from '@/constants/colors';
import { NEXT_ROUTES } from '@/constants/routes';
import { providerUpdate } from '@/redux/actions/calendarProviderAction';
import { getCalendarProviderSelector } from '@/redux/selectors/calendarSelector';
import axios from '@/utils/api/axios';
import { AUTH_PROVIDERS } from '@/constants/authProviders';
import { getIsUserCurrentProvider } from '@/redux/selectors/authSelector';
import Logo from '@/public/logos/Versational-Logo-R.svg';
import LogoWrapper from '@/components/logoWrapper';
import {
    ColoredDivA,
    GoogleImage,
    InnerCard,
    LargeArrowRight,
    LargeCheckCircle,
    LayoutContent,
    LogoRow,
    MainLayout,
    OuterCard,
    PaddedCol,
    PageWrapper,
    StyledSelect,
    SmallArrowRight,
} from './google.styles';

const { Option } = Select;

function OAuth({ simple }) {
    const dispatch = useDispatch();
    const router = useRouter();
    const [loading, setLoading] = useState(true);
    const [hasToken, setHasToken] = useState(false);
    const [googleID, setGoogleID] = useState(null);
    const [calendarOptions, setCalendarOptions] = useState([]);
    const [currentCalendar, setCurrentCalendar] = useState(null);
    const provider = useSelector(getCalendarProviderSelector);
    const isCurrentUserProvider = useSelector((state) =>
        getIsUserCurrentProvider(state, AUTH_PROVIDERS.GOOGLE),
    );
    const disabled = !!provider && provider !== AUTH_PROVIDERS.GOOGLE;

    const showError = (message = 'Something went wrong', duration = 5) => {
        notification.error({
            message,
            duration,
        });
    };

    const showSuccess = (message = 'Success!', duration = 5) => {
        notification.success({
            message,
            duration,
        });
    };

    const sendRequest = async () => {
        if (hasToken || disabled) return;

        const { data: redir } = await axios.get(NEXT_ROUTES.GOOGLE);
        if (redir) {
            window.location = redir;
        } else {
            showError();
        }
    };

    const removeIntegration = async () => {
        if (!isCurrentUserProvider) {
            setLoading(true);
            try {
                await axios.delete(
                    NEXT_ROUTES.GOOGLE_INTEGRATIONS_ID(googleID),
                );

                dispatch(providerUpdate(null));
                setHasToken(false);
                setGoogleID(null);
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        } else {
            notification.error({
                message: 'Error',
                description:
                    'This calendar integration is associated with your connected account. Please change your connected account to disable this.',
            });
        }
    };

    const handleChange = async (val) => {
        const newCalendar = calendarOptions.find((cal) => cal.summary === val);
        if (currentCalendar !== newCalendar.summary) {
            await axios.patch(NEXT_ROUTES.GOOGLE_INTEGRATIONS_ID(googleID), {
                calendar_choice: newCalendar.id,
            });
            setCurrentCalendar(newCalendar.summary);
        }
    };

    useEffect(() => {
        (async () => {
            try {
                const { data } = await axios.get(
                    NEXT_ROUTES.GOOGLE_INTEGRATIONS,
                );

                if (data.length > 0) {
                    setGoogleID(data[0].id);
                    setHasToken(true);
                    const { data: meData } = await axios.get(
                        NEXT_ROUTES.GOOGLE_ME,
                    );

                    const { data: calendars } = await axios.get(
                        NEXT_ROUTES.GOOGLE_CALENDARS,
                    );

                    if (calendars?.length) {
                        setCalendarOptions(calendars);
                    }

                    if (calendars?.length && meData?.email) {
                        if (data[0].calendar_choice === 'primary') {
                            const primary = calendars.find(
                                (cal) => cal.summary === meData.email,
                            );
                            setCurrentCalendar(primary.summary);
                        } else {
                            const curCal = calendars.find(
                                (cal) => cal.id === data[0].calendar_choice,
                            );
                            setCurrentCalendar(curCal.summary);
                        }
                    }

                    if (window.location.search.indexOf('success') !== -1) {
                        if (!simple) {
                            showSuccess();
                        }
                        dispatch(providerUpdate('google'));
                    }
                } else if (window.location.search.indexOf('error') !== -1) {
                    if (window.location.search.indexOf('scopeAccess') !== -1) {
                        showError(
                            `Versational® requires full access to all scopes listed,
                            please reauthenticate and select all scopes.`,
                            15,
                        );
                    } else if (
                        window.location.search.indexOf('duplicate') !== -1
                    ) {
                        showError(
                            `This email is already integrated with another Versational® Account.`,
                            5,
                        );
                    } else {
                        notification.error({
                            message: 'Error',
                            description:
                                'Error while authenticating through your portal. Please try again',
                        });
                        await router.push('/settings/integrations');
                    }
                }
            } catch (e) {
                showError();
            } finally {
                setLoading(false);
            }
        })();
    }, [dispatch, router]);

    return (
        <PageWrapper>
            {!loading && (
                <MainLayout>
                    <LayoutContent>
                        <OuterCard>
                            {!simple && <LogoRow>
                                <Col span={12} offset={6}>
                                    <LogoWrapper>
                                        <Logo />
                                    </LogoWrapper>
                                </Col>
                            </LogoRow>}
                            {!simple && <h2>Welcome</h2>}
                            {!hasToken ? <p>
                                Get started by syncing Google Calendar with
                                Versational®.
                            </p> : <p>
                                Your Google Calendar account is connected with
                                Versational®.</p>}
                            <Tooltip
                                title={
                                    disabled &&
                                    `Only one calendar can be synced at a time.
                                        Remove your Outlook integration before adding Google.`
                                }
                                color={DUSTYGRAY}
                            >
                                <InnerCard
                                    hasToken={hasToken}
                                    hoverable={!hasToken && !disabled}
                                    onClick={sendRequest}
                                    disabled={disabled}
                                >
                                    <Row>
                                        <Col span={2}>
                                            <GoogleImage src="/images/google-general.png" />
                                        </Col>
                                        <PaddedCol span={17}>
                                            {hasToken ? (
                                                <Fragment>
                                                    <strong>
                                                        Google Calendar
                                                    </strong>
                                                    <LargeCheckCircle
                                                        twoToneColor={
                                                            CHECK_CIRCLE_TWO_TONE_COLOR
                                                        }
                                                    />
                                                    <p>
                                                        Versational® is currently
                                                        using your{' '}
                                                        <strong>
                                                            {currentCalendar}
                                                        </strong>{' '}
                                                        calendar to discover
                                                        events.
                                                    </p>
                                                </Fragment>
                                            ) : (
                                                <Fragment>
                                                    <strong>
                                                        Authenticate with Google
                                                    </strong>
                                                    <p>
                                                        Google calendar events
                                                        will automatically
                                                        display on your
                                                        conversations page.
                                                    </p>
                                                </Fragment>
                                            )}
                                        </PaddedCol>
                                        <Col
                                            span={5}
                                            style={{ textAlign: 'right' }}
                                        >
                                            {hasToken && (
                                                <ColoredDivA
                                                    onClick={removeIntegration}
                                                >
                                                    Remove
                                                </ColoredDivA>
                                            )}
                                            {!disabled && !hasToken && (
                                                simple ? <SmallArrowRight /> : <LargeArrowRight />
                                            )}
                                        </Col>
                                    </Row>
                                </InnerCard>
                            </Tooltip>
                            {!simple && <>
                                <label style={{'display': 'block', 'fontWeight': 'bold', 'marginTop': '2rem'}}>Select Calendar</label>
                                <StyledSelect
                                    onChange={handleChange}
                                    placeholder="Change Calendar"
                                    defaultValue={currentCalendar}
                                    status={currentCalendar ? null : 'error'}
                                >
                                    {calendarOptions.map((cal) => (
                                        <Option
                                            key={cal.summary}
                                            value={cal.summary}
                                        >
                                            {cal.summary}
                                        </Option>
                                    ))}
                                </StyledSelect>
                            </>}
                        </OuterCard>
                        {!simple && <div style={{'marginTop': '1rem'}}>
                            By using Versational® you agree to the{' '}
                            <a
                                href="https://www.versational.ai/agreements/terms-of-service"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms of Service
                            </a>
                            . <br />We know that information is at the heart of your
                            business, which is why security is our top priority.
                        </div>}
                    </LayoutContent>
                </MainLayout>
            )}
        </PageWrapper>
    );
}

OAuth.propTypes = {
    simple: bool,
};

OAuth.defaultProps = {
    simple: false,
};

export default OAuth;
