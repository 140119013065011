import styled from 'styled-components';
import { string, func } from 'prop-types';
import { useState } from 'react';
import { Tooltip, Button } from 'antd';
import { ROUTES } from '@/constants/routes';
import Link from 'next/link';
import { ColoredAEllipsisWrapper } from '@/components/table/tableCell/tableCell.styles';
import { useRouter } from 'next/router'

const StyledLink = styled.a`
    overflow: hidden;
    text-align: start;
    white-space: nowrap;
    width: 100%;
    text-overflow: ellipsis;
`;

const StyledButton = styled(Button)`
    width: 100%;
    height: auto;
    padding: 0;
`;

function ConversationLink({ title, conversationId, setEllipsis }) {
    return (
        <Link
            href={ROUTES.CONVERSATIONS_RESULTS(conversationId)}
            passHref
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <StyledLink>
                <ColoredAEllipsisWrapper ellipsis={{ onEllipsis: setEllipsis }}>
                    {title}
                </ColoredAEllipsisWrapper>
            </StyledLink>
        </Link>
    );
}

ConversationLink.propTypes = {
    title: string.isRequired,
    conversationId: string.isRequired,
    setEllipsis: func.isRequired,
};

function ConversationButtonLink({ conversationId, title }) {
    const [isEllipsis, setEllipsis] = useState();
    const router = useRouter();

    if (!conversationId || !title) return null;

    return (
        <Tooltip title={isEllipsis ? title : ''} placement="rightTop">
            <StyledButton
                type="link"
                onClick={() => {
                    router.push(ROUTES.CONVERSATIONS_RESULTS(conversationId))
                }}
            >
                <StyledLink onClick={() => {
                    router.push(ROUTES.CONVERSATIONS_RESULTS(conversationId))
                }}>
                    <ColoredAEllipsisWrapper ellipsis={{ onEllipsis: setEllipsis }}>
                        {title}
                    </ColoredAEllipsisWrapper>
                </StyledLink>
            </StyledButton>
        </Tooltip>
    );
}

ConversationButtonLink.defaultProps = {
    conversationId: null,
    title: null,
};

ConversationButtonLink.propTypes = {
    conversationId: string,
    title: string,
};

export default ConversationButtonLink;
