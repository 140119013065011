import {
    fetchData,
    fetchFailure,
    fetchSuccess,
    genericAction,
} from '@/redux/actions/genericActions';
import { getActionCreator } from '@/redux/actions/utils/actionCreator';

const creator = getActionCreator('CONVERSATION_QUESTIONS');

export const CONVERSATION_QUESTIONS = {
    ...creator.fetch(),
    SET_QUESTIONS_QUERY: creator.rawCustom('SET_QUESTIONS_QUERY'),
    SET_SELECTED_QUESTION_INDEX: creator.rawCustom(
        'SET_SELECTED_QUESTION_INDEX',
    ),
    PIN_QUESTION: creator.custom('PIN_QUESTION'),
    LIKE_QUESTION: creator.custom('LIKE_QUESTION'),
    REMOVE_QUESTION: creator.custom('REMOVE_QUESTION'),
    COMMENT_ON_QUESTION: creator.custom('COMMENT_ON_QUESTION'),
    CHECK_QUESTION: `CONVERSATION_QUESTIONS_CHECK_QUESTION`,
    UNCHECK_QUESTION: `CONVERSATION_QUESTIONS_UNCHECK_QUESTION`,
    CHECK_ALL_QUESTIONS: `CONVERSATION_QUESTIONS_CHECK_ALL_QUESTIONS`,
    UNCHECK_ALL_QUESTIONS: `CONVERSATION_QUESTIONS_UNCHECK_ALL_QUESTIONS`,
    SET_QUESTIONS_FILTERS: `CONVERSATION_QUESTIONS_SET_QUESTIONS_FILTER`,
};

export const fetchConversationQuestions = (id) =>
    fetchData(CONVERSATION_QUESTIONS.FETCH_START, { id });
export const fetchConversationQuestionsSuccess = (data) =>
    fetchSuccess(CONVERSATION_QUESTIONS.FETCH_SUCCESS, data);
export const fetchConversationQuestionsFailure = (error) =>
    fetchFailure(CONVERSATION_QUESTIONS.FETCH_FAILURE, error);

export const setQuestionsQuery = (query) =>
    genericAction(CONVERSATION_QUESTIONS.SET_QUESTIONS_QUERY, query);
export const setSelectedQuestionIndex = (index) =>
    genericAction(CONVERSATION_QUESTIONS.SET_SELECTED_QUESTION_INDEX, index);

export const pinQuestion = (id, pinned) =>
    genericAction(CONVERSATION_QUESTIONS.PIN_QUESTION.START, { id, pinned });
export const pinQuestionSuccess = (data) =>
    genericAction(CONVERSATION_QUESTIONS.PIN_QUESTION.SUCCESS, data);
export const pinQuestionFailure = (id, error) =>
    genericAction(CONVERSATION_QUESTIONS.PIN_QUESTION.FAILURE, { id, error });

export const likeQuestion = (id, liked) =>
    genericAction(CONVERSATION_QUESTIONS.LIKE_QUESTION.START, { id, liked });
export const likeQuestionSuccess = (data) =>
    genericAction(CONVERSATION_QUESTIONS.LIKE_QUESTION.SUCCESS, data);
export const likeQuestionFailure = (id, error) =>
    genericAction(CONVERSATION_QUESTIONS.LIKE_QUESTION.FAILURE, { id, error });

export const removeQuestion = (id) =>
    genericAction(CONVERSATION_QUESTIONS.REMOVE_QUESTION.START, { id });
export const removeQuestionSuccess = (data) =>
    genericAction(CONVERSATION_QUESTIONS.REMOVE_QUESTION.SUCCESS, data);
export const removeQuestionFailure = (id, error) =>
    genericAction(CONVERSATION_QUESTIONS.REMOVE_QUESTION.FAILURE, {
        id,
        error,
    });
export const commentOnQuestion = (id, value) =>
    genericAction(CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.START, {
        id,
        value,
    });
export const commentOnQuestionSuccess = (data) =>
    genericAction(CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.SUCCESS, data);
export const commentOnQuestionFailure = (id, error) =>
    genericAction(CONVERSATION_QUESTIONS.COMMENT_ON_QUESTION.FAILURE, {
        id,
        error,
    });
export const checkQuestion = (id) => 
    genericAction(CONVERSATION_QUESTIONS.CHECK_QUESTION, {
        id,
    });

export const uncheckQuestion = (id) => 
    genericAction(CONVERSATION_QUESTIONS.UNCHECK_QUESTION, {
        id,
    });

export const checkAllQuestions = () => 
    genericAction(CONVERSATION_QUESTIONS.CHECK_ALL_QUESTIONS);

export const uncheckAllQuestions = () => 
    genericAction(CONVERSATION_QUESTIONS.UNCHECK_ALL_QUESTIONS);

export const setQuestionsFilter = (questionsFilter) => 
    genericAction(CONVERSATION_QUESTIONS.SET_QUESTIONS_FILTERS,
        questionsFilter,
    );