/* eslint-disable react/no-array-index-key */
import { arrayOf, bool, number, shape, string } from 'prop-types';
import { Avatar, Space } from 'antd';
import { Wrapper } from '@/components/avatars/avatars.styles';
import Emails from '@/components/avatars/emails/emails';


function Avatars({ showEmails, users, limit, size = 40 }) {
    if (!users?.length) {
        return null;
    }

    return (
        <Wrapper>
            <Space direction="horizontal" size={0}>
                <Avatar.Group onHover maxCount={limit} size={size}>
                    {users.map((user, index) => {
                        if (user.src || user.photo) {
                            return (
                                <Avatar
                                    key={user.src || user.photo}
                                    gap={0}
                                    src={user.src || user.photo}
                                    size={size}
                                />
                            );
                        }
                        let name;
                        if (!user.firstName && !user.lastName && user.email) {
                            name = user.email.slice(0, 2);
                        } else if (!user.firstName && !user.lastName && user.emailAddress.address) {
                            name = user.emailAddress.address.slice(0, 2);
                        } else {
                            name = user.firstName
                                ? user.firstName[0] +
                                  (user.lastName?.[0] ? user.lastName[0] : '')
                                : user.name?.[0] ?? '';
                        }
                        return (
                            <Avatar
                                // eslint-disable-next-line react/no-array-index-key
                                key={name + index}
                                gap={0}
                                size={size}
                            >
                                {name}
                            </Avatar>
                        );
                    })}
                </Avatar.Group>
                {showEmails && <Emails users={users} />}
            </Space>
        </Wrapper>
    );
}

Avatars.propTypes = {
    showEmails: bool,
    limit: number,
    users: arrayOf(
        shape({
            src: string,
            email: string,
            firstName: string,
            lastName: string,
        }),
    ).isRequired,
    size: number,
};

Avatars.defaultProps = {
    showEmails: false,
    limit: 5,
    size: 40,
};

export default Avatars;
