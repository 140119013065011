import {
    getHasSubscription,
    getUserInfoLoadingSelector,
} from '@/redux/selectors/authSelector';

import { SUBSCRIPTION_STATUS } from '@/constants/subscriptionStatus';
import { createSelector } from 'reselect';
import dayjs from 'dayjs';
import { getNumberOfActiveTeamMembers } from '@/redux/selectors/teamMembersSelector';
import { getNumberOfInvites } from '@/redux/selectors/invitesSelector';
import { subscriptionFeatureFlag } from '@/constants/featureFlags';
import { getIsBetaUser } from '@/redux/selectors/authSelector';

// #region STATUS
export const getSubscriptionInitialLoadingSelector = (state) =>
    state.subscription?.status === SUBSCRIPTION_STATUS.IDLE;

export const getSubscriptionActionLoadingSelector = (state) =>
    [
        SUBSCRIPTION_STATUS.UPDATE_PAYMENT_START,
        SUBSCRIPTION_STATUS.RENEW_SUBSCRIPTION_START,
        SUBSCRIPTION_STATUS.BUY_SUBSCRIPTION_START,
        SUBSCRIPTION_STATUS.UPDATE_SUBSCRIPTION_SEATS_START,
        SUBSCRIPTION_STATUS.START_SUBSCRIPTION_START,
        SUBSCRIPTION_STATUS.CANCEL_SUBSCRIPTION_START,
        SUBSCRIPTION_STATUS.REACTIVATE_SUBSCRIPTION_START,
        SUBSCRIPTION_STATUS.COMPUTE_PRICE_START,
        SUBSCRIPTION_STATUS.CHANGE_PLAN_START,
    ].includes(state.subscription?.status);

export const getSubscriptionStatusSelector = (state) =>
    state.subscription?.subscription?.status;

export const getScheduledChangesLoading = (state) =>
    state.subscription?.status ===
    SUBSCRIPTION_STATUS.SUBSCRIPTION_SCHEDULED_CHANGES_LOADING;

export const getSubscriptionErrorStatus = (state) =>
    subscriptionFeatureFlag
        ? state.subscription?.status === SUBSCRIPTION_STATUS.FAILURE
        : false;

export const geyBuyAddonLoading = (state) =>
    state.subscription?.status === SUBSCRIPTION_STATUS.BUY_ADDON_START;
export const getChangePlanLoading = (state) =>
    state.subscription?.status === SUBSCRIPTION_STATUS.CHANGE_PLAN_START;

// #endregion

export const getSubscriptionId = (state) =>
    state.subscription?.subscription?.subscription_id;

export const getSubscriptionPaymentCardId = (state) =>
    state.subscription?.subscription?.card?.card_id;

export const getSubscriptionHostedPageUrl = (state) =>
    state.subscription?.hostedPage?.url;

export const getSubscriptionPlanName = (state) =>
    state.subscription?.subscription?.plan?.name ?? '';
export const getSubscriptionPlanCode = (state) =>
    state.subscription?.subscription?.plan?.plan_code ?? '';

export const getSubscriptionPrice = (state) =>
    state.subscription?.subscription?.plan?.total;

export const getSubscriptionCurrencySymbol = (state) =>
    state.subscription?.subscription?.currency_symbol ?? '$';

export const getSubscriptionPricePerSeat = (state) =>
    state.subscription?.subscription?.plan?.price;

export const getSubscriptionInterval = (state) => {
    if (state.subscription?.subscription?.interval_unit === 'years')
        return 'year';
    return 'month';
};

export const getSubscriptionReferanceId = (state) =>
    state.subscription?.subscription?.reference_id;

export const getSubscriptionCurrentTermsAt = (state) =>
    dayjs(state.subscription?.subscription?.current_term_ends_at).format(
        'D MMMM YYYY',
    );
export const getSubscriptionNextBillingAt = (state) =>
    dayjs(state.subscription?.subscription?.next_billing_at).format(
        'D MMMM YYYY',
    );

export const getSubscriptionSeats = (state) =>
    state.subscription?.subscription?.plan?.quantity ?? 1;
export const getSubscriptionCardEndingNumber = (state) =>
    state.subscription?.subscription?.card?.last_four_digits;

export const getIsTrial = (state) =>
    state.subscription?.subscription?.status === 'trial';

export const getIsLive = (state) =>
    state.subscription?.subscription?.status === 'live' ||
    (state.subscription?.subscription?.status === 'cancelled' &&
        dayjs(state.subscription?.subscription?.current_term_ends_at) >
            dayjs());

export const getIsCancelled = (state) =>
    state.subscription?.subscription?.status === 'cancelled' ||
    state.subscription?.subscription?.status === 'trial_expired' ||
    state.subscription?.subscription?.status === 'expired' ||
    state.subscription?.subscription?.status === 'dunning' ||
    state.subscription?.subscription?.status === 'cancelled_from_dunning';

export const getIsNoCardAdded = (state) =>
    state.subscription?.subscription?.card == null;

export const getHasActiveSubscription = createSelector(
    [getUserInfoLoadingSelector, getHasSubscription, getIsCancelled],
    (userInfoLoading, hasSubscription, isCancelled) =>
        !userInfoLoading && hasSubscription && !isCancelled,
);

export const getIsNonRenewing = (state) =>
    state.subscription?.subscription?.status === 'non_renewing';
const getIsNonRenewingTrial = (state) =>
    state.subscription?.subscription?.status === 'non_renewing' &&
    state.subscription?.subscription?.trial_remaining_days;

export const getIsNonRenewingTrialOrTrial = createSelector(
    [getIsNonRenewingTrial, getIsTrial, getIsNoCardAdded],
    (isNonRenewingTrial, isTrial, noCardAdded) => isNonRenewingTrial || isTrial || noCardAdded,
);

export const getIsNonRenewingAndNotTrial = createSelector(
    [getIsNonRenewingTrial, getIsNonRenewing],
    (isNonRenewingTrial, isNonRenewing) => !isNonRenewingTrial && isNonRenewing,
);

export const getSubscriptionDaysRemaining = (state) =>
    dayjs
        .utc(state.subscription?.subscription?.current_term_ends_at)
        .diff(dayjs.utc(), 'days') + 1;

export const getRemainingTrialDays = (state) =>
    state.subscription?.subscription?.trial_remaining_days;

// #region SCHEDULED CHANGES
export const getScheduledChangesExist = (state) =>
    state.subscription?.scheduledChanges != null;
export const getScheduledChangesSeats = (state) =>
    state.subscription?.scheduledChanges?.plan?.quantity;
export const getScheduledChangesBillingAt = (state) =>
    dayjs(state.subscription?.scheduledChanges?.current_term_ends_at).format(
        'D MMMM YYYY',
    );
export const getScheduledPlanCode = (state) =>
    state.subscription?.scheduledChanges?.plan?.plan_code;
export const getScheduledPlanName = (state) =>
    state.subscription?.scheduledChanges?.plan?.name;
export const getScheduledPrice = (state) =>
    state.subscription?.scheduledChanges?.plan?.total;
export const getScheduledPricePerSeat = (state) =>
    state.subscription?.scheduledChanges?.plan?.price;
export const getScheduledInterval = (state) => {
    if (state.subscription?.scheduledChanges?.interval_unit === 'years')
        return 'year';
    return 'month';
};

export const getCanReactivatePerson = (state) =>
    !getHasSubscription ||
    getNumberOfInvites(state) + getNumberOfActiveTeamMembers(state) <
        getSubscriptionSeats(state);

export const getNumberOfInvitedAndActiveUsers = (state) =>
    getNumberOfInvites(state) + getNumberOfActiveTeamMembers(state);
// #endregion

export const getCurrentNumberOfSeats = (state) =>
    getScheduledChangesSeats(state) ?? getSubscriptionSeats(state);

export const getCurrentPricePerSeat = (state) =>
    getScheduledPricePerSeat(state) ?? getSubscriptionPricePerSeat(state);
// #region ADD SEATS PROCESS
export const getAdditionalSeats = (state) =>
    state.subscription?.addSeatsProcess?.additionalSeats;
export const getTotalSubscriptionSeats = (state) =>
    getSubscriptionSeats(state) + getAdditionalSeats(state);
// #endregion
// #region COMPUTED PRICE
export const getRecurringCharge = (state) =>
    state.subscription?.computedPrice?.recurring_charge_amount?.toFixed(2) ??
    '0.00';
export const getInitialCharge = (state) =>
    state.subscription?.computedPrice?.initial_charge_amount.toFixed(2);
export const getInitialChargeWithoutCredits = (state) =>
    state.subscription?.computedPrice?.initial_charge_total.toFixed(2);
// #endregion

export const getIsBetaStarter = createSelector(
    [getIsBetaUser, getSubscriptionPlanCode],
    (isBetaUser, subscriptionPlanName) => isBetaUser && subscriptionPlanName?.includes("BS")
);

export const getIsFreePro = createSelector(
    [getIsBetaUser, getSubscriptionPlanCode],
    (isBetaUser, subscriptionPlanName) => isBetaUser && subscriptionPlanName?.includes("BFM")
);

export const getIsBeta = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("BS") || subscriptionPlanName?.includes("BFM") || subscriptionPlanName?.includes("BEM")
);

export const getSubscriptionCustomer = (state) =>
    state.subscription?.subscription?.customer;

export const getIsSoloSubscription = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("VI") || subscriptionPlanName?.includes("VB")
);

export const getIsFree = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("VB")
);

export const getIsIndividual = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("VI")
);

export const getIsIndividualAnnual = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("VI-ANNUAL")
);

export const getIsTeams = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("VT")
);

export const getIsTeamsAnnual = createSelector(
    [getSubscriptionPlanCode],
    (subscriptionPlanName) => subscriptionPlanName?.includes("VT-ANNUAL")
);

export const getSubscriptionDaysRemainingOrScheduled = (state) => {
    const scheduledCancellationDate = state.subscription?.subscription?.scheduled_cancellation_date;
    if (scheduledCancellationDate) {
        return dayjs.utc(scheduledCancellationDate).diff(dayjs.utc(), 'days') + 1;
    }
    return getSubscriptionDaysRemaining(state);
};