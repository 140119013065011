import styled from 'styled-components';
import { GRAY_5 } from '@/constants/colors';

const Flex = styled.div`
    align-items: ${({ alignItems }) => alignItems || 'unset'};
    display: flex;
`;

const FlexColumn = styled.span`
    display: flex;
    flex-direction: column;
`;

const CenterContent = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const LinkUnderline = styled.a`
    text-decoration: underline;
`;

// 2px height
const HorizontalDivider = styled.div`
    border: 1px solid ${GRAY_5};
`;

const ThinTextLine = styled.p`
    font-weight: 400;
    margin-bottom: 0;
    margin-right: ${({ marginRight }) => marginRight || 0};
    margin-left: ${({ marginLeft }) => marginLeft || 0};
`;

export {
    Flex,
    FlexColumn,
    LinkUnderline,
    CenterContent,
    HorizontalDivider,
    ThinTextLine,
};
