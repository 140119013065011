import { useCallback, useEffect, useRef } from 'react';
import { useRestoreRecoveryData } from '@/utils/recording/recovery/hooks/useRestoreRecoveryData';
import { useLoadRecoveryData } from '@/utils/recording/recovery/hooks/useLoadRecoveryData';
import { useAutoDataRestore } from '@/utils/recording/recovery/hooks/useAutoDataRestore';
import { useDataSave } from '@/utils/recording/recovery/hooks/useDataSave';
import { clearUserData } from '@/utils/recording/recovery/saveUtils';
import { clearRecordingsRecoveryData } from '@/redux/actions/recordings/recordingRecoveryActions';
import { useDispatch } from 'react-redux';
import { emptyRecoveryData } from '@/utils/recording/recovery/readUtils';

export function useMediaRecovery(
    conversationId,
    userId,
    meetingExternalId,
    title,
) {
    const dispatch = useDispatch();

    const [recoveryData, setRecoveryData, restoreRecoveryData] =
        useRestoreRecoveryData(userId);

    const loadRecoveryData = useLoadRecoveryData(recoveryData);

    const isAutoRecoveryPending = useAutoDataRestore(
        conversationId,
        userId,
        restoreRecoveryData,
        loadRecoveryData,
    );

    const clearAllRecoveryData = useCallback(async () => {
        await clearUserData(userId);
        dispatch(clearRecordingsRecoveryData());
        setRecoveryData(emptyRecoveryData);
    }, [dispatch, setRecoveryData, userId]);

    useDataSave(conversationId, meetingExternalId, userId, title);

    const loadGuard = useRef(false);
    useEffect(() => {
        if (
            recoveryData.conversationId === conversationId &&
            !!conversationId &&
            loadGuard.current === false
        ) {
            (async () => {
                loadGuard.current = true;
                await loadRecoveryData();
                setRecoveryData(emptyRecoveryData);
            })();
        }
    }, [
        conversationId,
        loadRecoveryData,
        recoveryData.conversationId,
        restoreRecoveryData,
        setRecoveryData,
    ]);

    return {
        isAutoRecoveryPending,
        recoveryData,
        clearAllRecoveryData,
        clearTempRecoveryData: () => setRecoveryData(emptyRecoveryData),
    };
}
