import { Layout, Row, Button, Typography, Alert } from 'antd';
import styled from 'styled-components';
import { CheckCircleTwoTone } from '@ant-design/icons';
import { VERSATIONAL } from '@/constants/colors';

const { Text } = Typography;

const PageWrapper = styled(Layout)`
    position: relative;
    height: 100%;
`;

const MainLayout = styled(Layout)`
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
`;

const LayoutContent = styled(Layout.Content)`
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
`;

const LogoRow = styled(Row)`
    margin-bottom: 5%;
`;

const InnerCard = styled.div`
    margin-top: 10%;
    display: flex;
    background: white;
    align-items: center;
    border: 1px solid #f2f2f2;
    cursor: pointer;
    justify-content: space-between;
    padding: 30px;
    position: relative;

    &:hover {
        box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
    }
`;

const LargeCheckCircle = styled(CheckCircleTwoTone)`
    font-size: 20px;
    margin-left: 5px;
`;

const StyledButton = styled(Button)`
    border-radius: 5px;
    font-weight: normal;
    padding: 0.5em 2em;
    height: max-content;
`;

const StyledText = styled(Text)`
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
`;

const FlexDiv = styled.div`
    display: flex;
    text-align: left;
`;

const IntegrationText = styled.div`
    margin-left: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-self: center;
`;

const StyledAlert = styled(Alert)`
    width: 400px;
    position: absolute;
    top: 0;
    right: 0;
`;

const CenteredDiv = styled.div`
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const TeamsAppConnected = styled.div`
    width: 160px;
    height: 25px;
    margin-top: 1rem;
    background: #464EB8;
    color: #fff;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    line-height: 1;

    svg {
        margin-left: 5px;
    }
`;

const CancelStyledButton = styled(Button)`
    margin: 0;
    padding: 0;
    display: inline;
    font-size: 12px;
    color: ${VERSATIONAL};
`;

const StyledLink = styled.a`
    display: inline-block;
    margin-left: 0 !important;
`;

export {
    InnerCard,
    LargeCheckCircle,
    LayoutContent,
    LogoRow,
    MainLayout,
    PageWrapper,
    StyledButton,
    StyledText,
    FlexDiv,
    StyledAlert,
    CenteredDiv,
    IntegrationText,
    TeamsAppConnected,
    CancelStyledButton,
    StyledLink,
};
