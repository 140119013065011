import { takeEvery, select, put } from 'redux-saga/effects';
import {
    PREV_OCCURRENCE,
    NEXT_OCCURRENCE,
    setOccurrence,
} from '@/redux/actions/conversation/searchAction';
import {
    getAllSearchTranscriptOccurrences,
    getSearchOccurrence,
} from '@/redux/selectors/conversation/searchSelector';

export function* getPrevOccurrence() {
    const allOccurrences = yield select(getAllSearchTranscriptOccurrences);
    const currentOccurrence = yield select(getSearchOccurrence);
    if (allOccurrences === 0) {
        yield put(setOccurrence(0));
    } else if (currentOccurrence === 1)
        yield put(setOccurrence(allOccurrences));
    else
        yield put(
            setOccurrence(
                currentOccurrence - 1 > 0 ? currentOccurrence - 1 : 1,
            ),
        );
}
export function* getNextOccurrence() {
    const allOccurrences = yield select(getAllSearchTranscriptOccurrences);
    const currentOccurrence = yield select(getSearchOccurrence);

    if (allOccurrences === 0) {
        yield put(setOccurrence(0));
    } else if (currentOccurrence === allOccurrences) {
        yield put(setOccurrence(1));
    } else yield put(setOccurrence(currentOccurrence + 1));
}

export default function* searchSaga() {
    yield takeEvery(PREV_OCCURRENCE, getPrevOccurrence);
    yield takeEvery(NEXT_OCCURRENCE, getNextOccurrence);
}
