import { Button, Card, Layout, Typography, Row, Col } from 'antd';
import styled from 'styled-components';
import { CheckCircleTwoTone } from '@ant-design/icons';

const { Text } = Typography;

const ConnectButton = styled(Button)`
    border-radius: 5px;
    font-weight: normal;
    padding: 0.5em 2em;
    height: max-content;
`;

const YoutubeVideo = styled.div`
    text-align: center;
    margin-top: 80px;
    margin-bottom: 80px;
`

const MainLayout = styled(Layout)`
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 10%;
`;

const LayoutContent = styled(Layout.Content)`
    width: 80%;
    margin: auto;
    margin-bottom: 5%;
`;

const EllipsisWrap = styled.div`
    display: inline;
    padding-left: .5em;
    cursor: pointer;
`

// const PageWrapper = styled(Layout)`
//     text-align: center;
//     padding: 0 2% 0;
// `;

const PageWrapper = styled(Layout)`
    position: relative;
    height: 100%;
    padding: 32px;
`;

const IntegrationText = styled.span`
    font-size: 32px;
    weight: 400;
`

const LinkingText = styled.span`
    display: table-cell;
    vertical-align: middle;
    font-size: 24px;
    font-weight: 500;
`;

const OuterCard = styled(Card)`
    padding: 2%;
    margin-top: 16px;
`;

const LogoRow = styled(Row)`
    margin-bottom: 32px;
    justify: center;
`;

const InnerCard = styled(Card)`
    margin-top: 10%;
    height: 115px;
    background: white;
    border: 1px solid #f2f2f2;
    cursor: pointer;
    justify-content: space-between;
    padding: 0 30px;
    text-align: left;
    cursor: ${(props) => (!props.hasToken ? 'pointer' : 'auto')};
    margin-top: 32px;
`;

const TextHeader = styled.div`
    font-size: 44px;
    font-weight: 700;
    margin-top: 32px;
    margin-bottom: 32px;
`;

const ZoomImage = styled.img`
    width: 65px;
`;

const PaddedCol = styled(Col)`
    padding-left: 16px;
    display: table;
`;

const LargeCheckCircle = styled(CheckCircleTwoTone)`
    font-size: 20px;
    margin-left: 5px;
`;

const SpinRow = styled(Row)`
    height: 50%;
`;


const StyledButton = styled(Button)`
    border-radius: 5px;
    font-weight: normal;
    padding: 0.5em 2em;
    height: max-content;
`;

const StyledText = styled(Text)`
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
`;

export {
    ConnectButton,
    EllipsisWrap,
    MainLayout,
    LayoutContent,
    InnerCard,
    IntegrationText,
    LargeCheckCircle,
    LinkingText,
    LogoRow,
    OuterCard,
    PaddedCol,
    PageWrapper,
    SpinRow,
    TextHeader,
    YoutubeVideo,
    ZoomImage,
    StyledButton,
    StyledText
};
