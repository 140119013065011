import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { MEETING_ID_KEY } from '@/constants/reduxKeys';
import meetingIdReducer from '@/redux/reducers/conversation/meetingIdReducer';
import meetingIdSaga from '@/redux/sagas/meeting/meetingSaga';

export default function useMeetingInjectors() {
    useInjectReducer({
        key: MEETING_ID_KEY,
        reducer: meetingIdReducer,
    });

    useInjectSaga({
        key: MEETING_ID_KEY,
        saga: meetingIdSaga,
    });
}
