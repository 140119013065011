import { FETCH_STATUS } from '@/constants/fetchStatus';
import {
    INVITES_FETCH_FAILURE,
    INVITES_FETCH_START,
    INVITES_FETCH_SUCCESS,
    INVITE_RESEND,
    INVITE_DELETE_START,
    INVITE_DELETE_SUCCESS,
    INVITE_DELETE_FAILURE,
    INVITES_SEND_BULK,
} from '@/redux/actions/invitesAction';

export const initialState = {
    data: null,
    status: FETCH_STATUS.IDLE,
    error: null,
};

function invitesReducer(state = initialState, action) {
    switch (action.type) {
        case INVITES_FETCH_START: {
            if (state.status === FETCH_STATUS.IDLE)
                return {
                    ...state,
                    status: FETCH_STATUS.INITIAL_LOADING,
                    error: null,
                };
            return {
                ...state,
                status: FETCH_STATUS.REFETCH_LOADING,
                error: null,
            };
        }
        case INVITES_FETCH_SUCCESS: {
            if (state.status === FETCH_STATUS.INITIAL_LOADING)
                return {
                    data: action.payload.data,
                    status: FETCH_STATUS.INITIAL_LOADED,
                    error: null,
                };
            return {
                data: action.payload.data,
                status: FETCH_STATUS.REFETCH_SUCCESS,
                error: null,
            };
        }
        case INVITES_FETCH_FAILURE: {
            if (state.status === FETCH_STATUS.INITIAL_LOADING)
                return {
                    ...state,
                    status: FETCH_STATUS.INITIAL_ERROR,
                    error: action.payload.error,
                };
            return {
                ...state,
                status: FETCH_STATUS.REFETCH_ERROR,
                error: action.payload.error,
            };
        }
        case INVITES_SEND_BULK: {
            return {
                ...state,
                status: 'SEND_START',
                error: null,
            };
        }
        case INVITE_RESEND: {
            return {
                ...state,
                status: 'RESEND_START',
                error: null,
            };
        }
        case INVITE_DELETE_START: {
            return {
                ...state,
                status: FETCH_STATUS.DELETE_LOADING,
            };
        }
        case INVITE_DELETE_SUCCESS: {
            const deletedMemberId = action.payload.data;

            return {
                ...state,
                status: FETCH_STATUS.DELETE_SUCCESS,
                data: {
                    companyInvites: state.data?.companyInvites?.filter((v) => v.id !== deletedMemberId),
                }
            };
        }
        case INVITE_DELETE_FAILURE: {
            return {
                ...state,
                error: action.payload.error,
                status: FETCH_STATUS.DELETE_ERROR,
            };
        }
        default:
            return state;
    }
}

export default invitesReducer;
