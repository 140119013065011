/* eslint-disable indent */
import { RECORDINGS } from '@/redux/actions/recordings/recordingAction';

export const initialState = {
    recordings: [],
    recordingsSimplified: [],
    loading: false,
    error: null,
    searchData: null,
    searchValue: '',
    pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
    },
};

const mapFetchedRecordings = (recordings) => {
    const recordingsWithoutFullExt = recordings.map((v) => {
        const type = (() => {
            const inputType = v.file_type;
            if (inputType.startsWith('audio')) return 'audio';
            if (inputType.startsWith('video')) return 'video';
            return inputType;
        })();
        const externalViews = v.conversation.share_views;

        return {
            ...v,
            file_type: type,
            external_views: externalViews,
        };
    });

    return recordingsWithoutFullExt.map((v, i) =>
        i === 0
            ? {
                  ...v,
                  conversation: {
                      ...v.conversation,
                      shared_by: null,
                  },
              }
            : v,
    );
};

function recordingReducer(state = initialState, action) {
    switch (action.type) {
        case RECORDINGS.FETCH_START: {
            return {
                ...state,
                loading: true,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload.shouldStartFromFirstPage
                        ? 1
                        : state.pagination.currentPage,
                },
            };
        }
        case RECORDINGS.FETCH_SUCCESS: {
            return {
                ...state,
                recordings: mapFetchedRecordings(action.payload.recordings),
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.payload.count,
                },
            };
        }
        case RECORDINGS.FETCH_FAILURE: {
            return {
                ...state,
                recordings: [],
                loading: false,
                error: action.payload.error,
            };
        }
        case RECORDINGS.SEARCH_FETCH.START: {
            return {
                ...state,
                loading: true,
            };
        }
        case RECORDINGS.SEARCH_FETCH.SUCCESS: {
            return {
                ...state,
                searchData: action.payload.searchData,
                recordings: action.payload.recordings,
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.payload.searchData.recordingCount,
                },
            };
        }
        case RECORDINGS.SEARCH_FETCH.FAILURE: {
            return {
                ...state,
                recordings: [],
                searchData: null,
                loading: false,
                error: action.payload.error,
            };
        }
        case RECORDINGS.UPDATE:
            const index = state.recordings.findIndex(
                (rec) => rec.id === action.payload.recording.id,
            );

            const newRecording = action.payload.recording;
            const inputType = newRecording.file_type;
            if (inputType.startsWith('audio')) {
                newRecording.file_type = 'audio';
            }
            if (inputType.startsWith('video')) {
                newRecording.file_type = 'video';
            }
            const updateRecordings = [...state.recordings];

            if (index === -1) {
                updateRecordings.unshift(newRecording);
            } else {
                updateRecordings[index] = newRecording;
            }

            return {
                ...state,
                recordings: updateRecordings,
            };
        case RECORDINGS.REMOVE.START:
            return {
                ...state,
                loading: true,
            };
        case RECORDINGS.REMOVE.SUCCESS:
            let conversationIds = [];

            if (action.payload.id.indexOf(',') > -1) {
                conversationIds = action.payload.id.split(',');
            } else {
                conversationIds = [action.payload.id];
            }

            return {
                ...state,
                loading: false,
                recordings: state.recordings.filter(
                    (recording) => !conversationIds.includes(recording.id),
                ),
            };
        case RECORDINGS.REMOVE.FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload.error,
            };
        case RECORDINGS.SET_ERROR:
            return { ...state, error: action.payload };
        case RECORDINGS.SET_LOADING:
            return { ...state, loading: action.payload };
        case RECORDINGS.DATE_UPDATE.SUCCESS:
            return {
                ...state,
                loading: false,
                recordings: state.recordings.map((v) =>
                    v.id !== action.payload.recordingId
                        ? v
                        : { ...v, date: action.payload.date },
                ),
            };
        case RECORDINGS.CONVERSATION_TITLE_UPDATE.START:
        case RECORDINGS.DATE_UPDATE.START:
        case RECORDINGS.TAGS_UPDATE.START:
            return {
                ...state,
                loading: true,
            };
        case RECORDINGS.CONVERSATION_TITLE_UPDATE.FAILURE:
        case RECORDINGS.DATE_UPDATE.FAILURE:
        case RECORDINGS.TAGS_UPDATE.FAILURE:
            return {
                ...state,
                loading: false,
            };
        case RECORDINGS.TAGS_UPDATE.SUCCESS:
            return {
                ...state,
                loading: false,
                recordings: state.recordings.map((v) =>
                    v.id !== action.payload.recordingId
                        ? v
                        : { ...v, tags: action.payload.tags },
                ),
            };
        case RECORDINGS.CONVERSATION_TITLE_UPDATE.SUCCESS:
            return {
                ...state,
                loading: false,
                recordings: state.recordings.map((v) =>
                    v.id !== action.payload.recordingId
                        ? v
                        : {
                              ...v,
                              conversation: {
                                  ...v.conversation,
                                  title: action.payload.title,
                              },
                          },
                ),
            };
        case RECORDINGS.UPDATE_PAGINATION:
            const page = action.payload.currentPage;
            const size = action.payload.pageSize;

            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    ...(!!size && { pageSize: size }),
                    ...(!!page && { currentPage: page }),
                },
            };
        case RECORDINGS.UPDATE_PAGINATION_TOTAL:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    total: (state.pagination.total + action.payload.addItems),
                },
            };
        case RECORDINGS.SEARCH:
            return {
                ...state,
                searchValue: action.payload.searchValue,
                loading: true,
            };
        case RECORDINGS.UPDATE_SHARED_TO:
            const recording = state.recordings.find(
                (rec) => rec.conversation?.id === action.payload.conversationId,
            );
            if (recording) {
                recording.conversation.shared_to = action.payload.sharedTo;
            }
            return {
                ...state,
                recordings: [...state.recordings],
            };
        case RECORDINGS.UPDATE_REMOVE_SHARED_TO:
            const recordingRemove = state.recordings.find(
                (rec) => rec.conversation?.id === action.payload.conversationId,
            );

            if (recordingRemove) {
                recordingRemove.conversation.shared_to = recordingRemove.conversation.shared_to.filter(
                    ({id}) => id !== action.payload.removeSharedTo,
                );
            }
            return {
                ...state,
                recordings: [...state.recordings],
            };
        case RECORDINGS.SIMPLIFIED.START: {
            return {
                ...state,
                loading: true,
                pagination: {
                    ...state.pagination,
                    currentPage: action.payload.shouldStartFromFirstPage
                        ? 1
                        : state.pagination.currentPage,
                },
            };
        }
        case RECORDINGS.SIMPLIFIED.SUCCESS: {
            return {
                ...state,
                recordingsSimplified: mapFetchedRecordings(action.payload.recordings),
                loading: false,
                error: null,
                pagination: {
                    ...state.pagination,
                    total: action.payload.count,
                },
            };
        }
        case RECORDINGS.SIMPLIFIED.FAILURE: {
            return {
                ...state,
                recordingsSimplified: [],
                loading: false,
                error: action.payload.error,
            };
        }
        case RECORDINGS.UPDATE_MEETING_NOTE:
            const findRecording = state.recordings.find(
                (rec) => rec.conversation?.id === action.payload.conversationId,
            );

            if (findRecording) {
                findRecording.conversation.meeting_note = action.payload.meetingNote;
            }
            return {
                ...state,
                recordings: [...state.recordings],
            };
        default:
            return state;
    }
}

export default recordingReducer;
